import { isEmpty } from "lodash";
import queryString from "query-string";
import toastr from "src/components/Common/toastr";
import { verifyWebhookURL } from "src/modules/workspace/helpers";
import { fetchTemplatesData } from "../../actions/RolesActions";
import api from "../../api";
import { API_CALL_STATE } from "../../constants";
import { history } from "../../helpers";
import { AppAnalytics } from "../../helpers/analytics_helper";
import {
  currentUser,
  getAPIURL,
  getCurrentWorkspaceID,
  isAdmin,
  saveCurrentUser,
  setLastAccessedWorkspaceID,
} from "../../utils";

export const FETCH_WORKSPACES_INIT = "FETCH_WORKSPACES_INIT";
export const FETCH_WORKSPACES_DONE = "FETCH_WORKSPACES_DONE";
export const FETCH_WORKSPACES_FAIL = "FETCH_WORKSPACES_FAIL";
export const FETCH_WORKSPACES_RESET = "FETCH_WORKSPACES_RESET";
export const SET_WORKSPACE_DETAILS = "SET_WORKSPACE_DETAILS";

export const VERIFY_WEBHOOK_URL_INIT = "VERIFY_WEBHOOK_URL_INIT";
export const VERIFY_WEBHOOK_URL_DONE = "VERIFY_WEBHOOK_URL_DONE";
export const VERIFY_WEBHOOK_URL_FAIL = "VERIFY_WEBHOOK_URL_FAIL";
export const FETCH_WORKSPACE_DETAILS_INIT = "FETCH_WORKSPACE_DETAILS_INIT";
export const FETCH_WORKSPACE_DETAILS_DONE = "FETCH_WORKSPACE_DETAILS_DONE";
export const FETCH_WORKSPACE_DETAILS_FAIL = "FETCH_WORKSPACE_DETAILS_FAIL";
export const UPDATE_WORKSPACE_DETAILS_INIT = "UPDATE_WORKSPACE_DETAILS_INIT";
export const UPDATE_WORKSPACE_DETAILS_DONE = "UPDATE_WORKSPACE_DETAILS_DONE";
export const UPDATE_WORKSPACE_DETAILS_FAIL = "UPDATE_WORKSPACE_DETAILS_FAIL";
export const GENERATE_JWT_TOKEN_INIT = "GENERATE_JWT_TOKEN_INIT";
export const GENERATE_JWT_TOKEN_DONE = "GENERATE_JWT_TOKEN_DONE";
export const GENERATE_JWT_TOKEN_FAIL = "GENERATE_JWT_TOKEN_FAIL";
export const SWITCH_WORKSPACE_INIT = "SWITCH_WORKSPACE_INIT";
export const SWITCH_WORKSPACE_DONE = "SWITCH_WORKSPACE_DONE";
export const SWITCH_WORKSPACE_FAIL = "SWITCH_WORKSPACE_FAIL";
export const SWITCH_WORKSPACE_RESET = "SWITCH_WORKSPACE_RESET";
export const CREATE_WORKSPACE_INIT = "CREATE_WORKSPACE_INIT";
export const CREATE_WORKSPACE_DONE = "CREATE_WORKSPACE_DONE";
export const CREATE_WORKSPACE_FAIL = "CREATE_WORKSPACE_FAIL";
export const DELETE_WORKSPACE_MEMBER_DONE = "DELETE_WORKSPACE_MEMBER_DONE";
export const DELETE_WORKSPACE_MEMBER_INIT = "DELETE_WORKSPACE_MEMBER_INIT";
export const DELETE_WORKSPACE_MEMBER_FAIL = "DELETE_WORKSPACE_MEMBER_FAIL";
export const UPDATE_WORKSPACE_MEMBER_ROLE_DONE =
  "UPDATE_WORKSPACE_MEMBER_ROLE_DONE";
export const UPDATE_WORKSPACE_MEMBER_ROLE_INIT =
  "UPDATE_WORKSPACE_MEMBER_ROLE_INIT";
export const UPDATE_WORKSPACE_MEMBER_ROLE_FAIL =
  "UPDATE_WORKSPACE_MEMBER_ROLE_FAIL";
export const INVITE_TO_WORKSPACE_FAIL = "INVITE_TO_WORKSPACE_FAIL";
export const INVITE_TO_WORKSPACE_INIT = "INVITE_TO_WORKSPACE_INIT";
export const INVITE_TO_WORKSPACE_DONE = "INVITE_TO_WORKSPACE_DONE";
export const INVITE_TO_WORKSPACE_RESET = "INVITE_TO_WORKSPACE_RESET";
export const ACCEPT_INVITE_TO_WORKSPACE_FAIL =
  "ACCEPT_INVITE_TO_WORKSPACE_FAIL";
export const ACCEPT_INVITE_TO_WORKSPACE_INIT =
  "ACCEPT_INVITE_TO_WORKSPACE_INIT";
export const ACCEPT_INVITE_TO_WORKSPACE_DONE =
  "ACCEPT_INVITE_TO_WORKSPACE_DONE";
export const ACCEPT_INVITE_TO_WORKSPACE_RESET =
  "ACCEPT_INVITE_TO_WORKSPACE_RESET";
export const SET_WORKSPACE_ID = "SET_WORKSPACE_ID";
export const UPDATE_ROOM_ROLE_LINK_FORMAT_DONE =
  "UPDATE_ROOM_ROLE_LINK_FORMAT_DONE";
export const UPDATE_ROOM_ROLE_LINK_FORMAT_FAIL =
  "UPDATE_ROOM_ROLE_LINK_FORMAT_FAIL";
export const UPDATE_ROOM_ROLE_LINK_FORMAT_INIT =
  "UPDATE_ROOM_ROLE_LINK_FORMAT_INIT";
export const UPDATE_DEFAULT_TEMPLATE_DONE = "UPDATE_DEFAULT_TEMPLATE_DONE";
export const UPDATE_DEFAULT_TEMPLATE_INPROGRESS =
  "UPDATE_DEFAULT_TEMPLATE_INPROGRESS";
export const UPDATE_DEFAULT_TEMPLATE_FAIL = "UPDATE_DEFAULT_TEMPLATE_FAIL";

export function fetchWorkspaces() {
  return async (dispatch, getState) => {
    const { status, current } = getState().workspace;
    if (status.fetchAllWorkspaces === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_WORKSPACES_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .get(getAPIURL(`workspaces/all`));
      if (resp.data.success) {
        const cookie = currentUser();
        const workspaceID = getCurrentWorkspaceID();
        cookie.isHipaa = resp.data.workspaces[workspaceID].hipaa;
        saveCurrentUser(cookie);
        dispatch({
          type: FETCH_WORKSPACES_DONE,
          payload: resp.data.workspaces,
        });
        if (isEmpty(current)) {
          dispatch(fetchWorkspaceDetails());
        }
      } else {
        throw Error(resp);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_WORKSPACES_FAIL });
      toastr.error(e.message);
    }
  };
}

export function fetchAdminWorkspaces() {
  return async (dispatch, getState) => {
    const status = getState().workspace.status.fetchAllWorkspaces;
    if (status === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_WORKSPACES_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .get(getAPIURL(`workspaces/all`));
      if (resp.data.success) {
        const workspace_id = Object.keys(resp.data.workspaces)[0];
        const cookie = currentUser();
        setLastAccessedWorkspaceID(cookie.email, workspace_id);
        cookie.customer.workspace_id = workspace_id;
        saveCurrentUser(cookie);
        dispatch({
          type: FETCH_WORKSPACES_DONE,
          payload: resp.data.workspaces,
        });
        dispatch(fetchTemplatesData());
        history.push("/dashboard");
      } else {
        throw Error(resp?.data?.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_WORKSPACES_FAIL });
      toastr.error(e.message);
    }
  };
}

export function fetchWorkspaceDetails() {
  return async (dispatch, getState) => {
    const { status } = getState().workspace;
    if (status.fetchWorkspaceInfo === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_WORKSPACE_DETAILS_INIT });
    const workspaceID = getCurrentWorkspaceID();
    if (!workspaceID) {
      return;
    }
    try {
      const resp = await api
        .service("dashboard")
        .get(getAPIURL(`workspaces/${workspaceID}`));
      if (resp.data.success) {
        dispatch({
          type: FETCH_WORKSPACE_DETAILS_DONE,
          payload: resp.data.data,
        });
      } else {
        throw Error(resp?.data?.msg || resp?.data?.message);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_WORKSPACE_DETAILS_FAIL });
      toastr.error(e.message);
    }
  };
}

export function verifyWebhookURLData({ url, headers }) {
  return async (dispatch, getState) => {
    const { status } = getState().workspace;
    if (status.fetchWorkspaceInfo === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: VERIFY_WEBHOOK_URL_INIT });

    try {
      const resp = await verifyWebhookURL({ url, headers });
      if (resp.data.success) {
        dispatch({
          type: VERIFY_WEBHOOK_URL_DONE,
        });
        toastr.success("Webhook Tested Successfully");
      } else {
        throw Error(resp?.data?.msg || resp?.data?.message);
      }
    } catch (e) {
      console.error(e);
      dispatch({
        type: VERIFY_WEBHOOK_URL_FAIL,
        payload:
          e.response?.data?.msg ||
          e.message ||
          "Please verify the webhook provided",
      });
    }
  };
}

export function updateWorkspaceDetails(data) {
  return async (dispatch, getState) => {
    const updateWorkspaceInfo = getState().workspace.status.updateWorkspaceInfo;
    const workspaceID = getCurrentWorkspaceID();
    if (updateWorkspaceInfo === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: UPDATE_WORKSPACE_DETAILS_INIT });

    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL(`workspaces/${workspaceID}`), {
          ...data,
        });
      if (resp.data.success) {
        dispatch({
          type: UPDATE_WORKSPACE_DETAILS_DONE,
          payload: resp.data,
        });
      } else {
        throw Error(resp?.data?.msg || resp?.data?.message);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: UPDATE_WORKSPACE_DETAILS_FAIL });
      toastr.error(e.message);
    }
  };
}

export function updateWebhookDetails(data) {
  //eslint-disable-next-line complexity
  return async (dispatch, getState) => {
    const updateWorkspaceInfo = getState().workspace.status.updateWorkspaceInfo;
    const current = getState().workspace.current;
    const workspaceID = getCurrentWorkspaceID();
    if (updateWorkspaceInfo === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: UPDATE_WORKSPACE_DETAILS_INIT });
    if (current.Webhook.URL !== data.Webhook.URL) {
      try {
        AppAnalytics.track("webhook_url.clicked", {
          webhook_url: data.Webhook.URL,
          webhook_headers: JSON.stringify(data.Webhook.Headers),
          active: data.Webhook.active,
        });
      } catch (e) {
        console.error(e);
      }
    }
    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL(`workspaces/${workspaceID}`), {
          ...data,
        });
      if (resp.data.success) {
        dispatch({
          type: UPDATE_WORKSPACE_DETAILS_DONE,
          payload: resp.data?.data,
        });
        if (!isEmpty(data.Webhook.URL)) {
          toastr.success("Webhooks Updated");
        } else {
          toastr.success("Webhooks Removed");
        }
        if (current.Webhook.URL !== data.Webhook.URL) {
          try {
            AppAnalytics.track("webhook_url.success", {
              webhook_url: data.Webhook.URL,
              webhook_headers: JSON.stringify(data.Webhook.Headers),
              active: data.Webhook.active,
            });
          } catch (e) {
            console.error(e);
          }
        }
      } else {
        throw Error(resp?.data?.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: UPDATE_WORKSPACE_DETAILS_FAIL });
      toastr.error(e?.response?.data?.msg || e.message);
      if (current.Webhook.URL !== data.Webhook.URL) {
        try {
          AppAnalytics.track("webhook_url.failed", {
            webhook_url: data.Webhook.URL,
            webhook_headers: JSON.stringify(data.Webhook.Headers),
            active: data.Webhook.active,
          });
        } catch (e) {
          console.error(e);
        }
      }
    }
  };
}

export function generateJWTToken(days) {
  //eslint-disable-next-line complexity
  return async (dispatch, getState) => {
    const generateManagementToken =
      getState().workspace.status.generateManagementToken;
    if (generateManagementToken === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: GENERATE_JWT_TOKEN_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL(`workspaces/management-token`), {
          days,
        });
      if (resp.data.success) {
        dispatch({
          type: GENERATE_JWT_TOKEN_DONE,
          payload: resp.data,
        });
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: GENERATE_JWT_TOKEN_FAIL });
    }
  };
}

export function deleteWorkspaceMember(user_id) {
  return async dispatch => {
    const workspaceID = getCurrentWorkspaceID();
    dispatch({ type: DELETE_WORKSPACE_MEMBER_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL("workspaces/remove-workspace-member"), {
          workspace_id: workspaceID,
          user_id,
        });
      if (resp.data.success) {
        dispatch(fetchWorkspaceDetails());
        dispatch({
          type: DELETE_WORKSPACE_MEMBER_DONE,
          payload: {
            user_id,
          },
        });
        toastr.success("user deleted from workspace");
      } else {
        throw Error("Error deleting the template!");
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: DELETE_WORKSPACE_MEMBER_FAIL });
      toastr.error(e.message);
    }
  };
}

export function updateWorkspaceMemberRole(userId, role) {
  return async dispatch => {
    dispatch({ type: UPDATE_WORKSPACE_MEMBER_ROLE_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL("workspaces/update-role"), {
          new_role: role,
          user_id: userId,
        });
      if (resp.data.success) {
        dispatch(fetchWorkspaceDetails());
        dispatch({
          type: UPDATE_WORKSPACE_MEMBER_ROLE_DONE,
          payload: {
            user_id: userId,
            role: role,
          },
        });
        toastr.success("User role updated successfully");
      } else {
        throw Error("Error updating the User role!");
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: UPDATE_WORKSPACE_MEMBER_ROLE_FAIL });
      toastr.error(e.message);
    }
  };
}

export function inviteToWorkspace(emails) {
  return async (dispatch, getState) => {
    const { status, current } = getState().workspace;
    const workspaceID = getCurrentWorkspaceID();
    if (status.inviteEmails === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: INVITE_TO_WORKSPACE_INIT });
    try {
      const resp = await api.service("dashboard").post(getAPIURL("invite"), {
        workspace_id: workspaceID,
        emails,
        workspace_name: current.name,
      });
      if (resp.data.success) {
        dispatch({
          type: INVITE_TO_WORKSPACE_DONE,
          payload: {
            emails,
          },
        });
        toastr.success(resp.data.message || resp.data.msg);
      } else {
        throw Error(resp.data.message);
      }
    } catch (e) {
      dispatch({ type: INVITE_TO_WORKSPACE_FAIL });
      console.error(e);
    }
  };
}

export function switchWorkspace(workspaceID) {
  return async (dispatch, getState) => {
    const switchWorkspace = getState().workspace.status.switchWorkspace;
    if (switchWorkspace === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: SWITCH_WORKSPACE_INIT });
    try {
      if (isAdmin()) {
        const cookie = currentUser();
        setLastAccessedWorkspaceID(cookie.email, workspaceID);
        cookie.customer.workspace_id = workspaceID;
        saveCurrentUser(cookie);

        dispatch({
          type: SWITCH_WORKSPACE_DONE,
        });
        setTimeout(() => {
          window.location.pathname = "/dashboard";
        }, 200);
        dispatch(fetchWorkspaceDetails());
      } else {
        const resp = await api
          .service("dashboard")
          .post(getAPIURL(`workspaces/switch`), {
            workspace_id: workspaceID,
          });
        if (resp.data.success) {
          const userCookie = currentUser();
          userCookie.token = resp.data.data.Token;
          userCookie.workspaceID = workspaceID;
          setLastAccessedWorkspaceID(userCookie.email, workspaceID);
          saveCurrentUser(userCookie);
          dispatch({
            type: SWITCH_WORKSPACE_DONE,
          });
          setTimeout(() => {
            window.location.search = "";
            window.location.pathname = "/dashboard";
          }, 200);
          dispatch(fetchWorkspaceDetails());
        } else {
          throw Error(resp?.data?.msg);
        }
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: SWITCH_WORKSPACE_FAIL });
      toastr.error(e.message);
    }
  };
}

export function createWorkspace(data) {
  return async (dispatch, getState) => {
    const { status } = getState().workspace;
    if (status.createWorkspace === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: CREATE_WORKSPACE_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL("workspaces/create"), {
          name: data?.name?.trim(),
          description: data.name,
          hipaa: !!data?.hipaa,
          currentRegion: data?.currentRegion || "in",
        });
      if (resp.data.success) {
        const workspaceID = resp.data.data.WorkspaceID;
        dispatch({
          type: CREATE_WORKSPACE_DONE,
        });
        if (isAdmin()) {
          dispatch(fetchAdminWorkspaces());
        } else {
          dispatch(fetchWorkspaces());
        }
        dispatch(switchWorkspace(workspaceID));
      } else {
        throw Error(resp?.data?.msg);
      }
    } catch (e) {
      toastr.error(e?.response?.data?.msg);
      console.error(e);
      dispatch({ type: CREATE_WORKSPACE_FAIL });
    }
  };
}

export function updateRoomRoleLinkFormat(disable) {
  return async (dispatch, getState) => {
    const updateRoomRoleLinkFormat =
      getState().workspace.status.updateRoomRoleLinkFormat;
    if (updateRoomRoleLinkFormat === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: UPDATE_ROOM_ROLE_LINK_FORMAT_INIT });
    try {
      const resp = await api.service("dashboard").post(getAPIURL("room-role"), {
        disable,
      });
      if (resp.data.success) {
        dispatch({
          type: UPDATE_ROOM_ROLE_LINK_FORMAT_DONE,
          payload: resp.data,
        });
        toastr.success(resp?.data?.msg);
      } else {
        throw Error(resp?.data?.msg || resp?.data?.message);
      }
    } catch (e) {
      console.error(e);
      toastr.error(e.message);
      dispatch({ type: UPDATE_ROOM_ROLE_LINK_FORMAT_FAIL });
    }
  };
}

export function acceptWorkspaceInvite(data) {
  return async (dispatch, getState) => {
    const searchParams = queryString.parse(window.location.search);
    const { status } = getState().workspace;
    if (status.acceptInviteWorkspace.state === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: ACCEPT_INVITE_TO_WORKSPACE_INIT });
    try {
      const resp = await api.service("dashboard").post("accept-invite", data);
      if (resp.data.success) {
        dispatch(switchWorkspace(searchParams.resourceId));
        dispatch({
          type: ACCEPT_INVITE_TO_WORKSPACE_DONE,
        });
        toastr.success(resp?.data?.msg || '"Invitation Accepted"');
      } else {
        throw Error(resp?.data?.msg);
      }
    } catch (e) {
      dispatch({ type: ACCEPT_INVITE_TO_WORKSPACE_FAIL, payload: e.message });
      console.error(e);
    }
  };
}

export function updateDefaultTemplateForWorkspace(templateId) {
  return async (dispatch, getState) => {
    if (!templateId) {
      return;
    }
    const { status } = getState().workspace;
    if (status.updateDefaultTemplate === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: UPDATE_DEFAULT_TEMPLATE_INPROGRESS });
    try {
      const data = {
        DefaultTemplateID: templateId,
      };
      const resp = await api
        .service("dashboard")
        .post("workspaces/update-default-template", data);
      if (resp.data.success) {
        dispatch({
          type: UPDATE_DEFAULT_TEMPLATE_DONE,
          payload: {
            templateId,
          },
        });
        toastr.success("Default template updated successfully");
      } else {
        throw Error(resp?.data?.msg);
      }
    } catch (e) {
      toastr.error(e.message);
      dispatch({ type: UPDATE_DEFAULT_TEMPLATE_FAIL });
    }
  };
}
