import React from "react";
import { currentWorkspaceHipaa } from "src/utils";
import { Text } from "@100mslive/react-ui";

const HipaaComplianceNotice = () => {
  return currentWorkspaceHipaa() ? (
    <>
      <Text
        variant="caption"
        css={{
          mt: "$4",
          color: "$textMedEmp",
          display: "inline",
        }}
      >
        Disabled for HIPAA compliant workspaces.&nbsp;
        <a
          href={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/security-and-privacy/HIPAA%20compliance/HIPAA-workspace`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Text
            variant="caption"
            css={{ c: "$primaryLight", fontWeight: "$regular" }}
            as="span"
          >
            Learn more
          </Text>
        </a>
      </Text>
    </>
  ) : null;
};

export default HipaaComplianceNotice;
