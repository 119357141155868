import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LayoutIcon, PeopleIcon } from "@100mslive/react-icons";
import { Button, Flex, Loading, Text } from "@100mslive/react-ui";
import Members from "./members";
import WorskpaceDetailsForm from "./workspaceDetailsForm";
import { InviteModal } from "../../components";
import TabGroup from "../../components/Common/TabGroup";
import { API_CALL_STATE } from "../../constants";
import { handleNonAdminWorkspaceRoute } from "../../helpers";
import { AppAnalytics } from "../../helpers/analytics_helper";
import {
  fetchWorkspaceDetails,
  fetchWorkspaces,
} from "../../store/workspace/actions";
import { currentUser, getCurrentWorkspaceID } from "../../utils";

const tabs = [
  { name: "Workspace Details", id: 0, icon: <LayoutIcon />, title: "details" },
  { name: "Members", id: 1, icon: <PeopleIcon />, title: "members" },
];

function Workspace() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(tabs[0]?.title);
  const { current: workspace, workspaces } = useSelector(
    state => state.workspace
  );
  const workspaceID = getCurrentWorkspaceID();
  const fetchWorkspaceInfo = useSelector(
    state => state.workspace?.status?.fetchWorkspaceInfo
  );
  const fetchAllWorkspaces = useSelector(
    state => state.workspace?.status?.fetchAllWorkspaces
  );
  const updateWorkspaceInfo = useSelector(
    state => state.workspace?.status?.updateWorkspaceInfo
  );
  if (
    !workspaces[workspaceID]?.is_admin &&
    fetchAllWorkspaces === API_CALL_STATE.DONE
  ) {
    handleNonAdminWorkspaceRoute();
  }
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (updateWorkspaceInfo === API_CALL_STATE.DONE) {
      dispatch(fetchWorkspaceDetails());
      dispatch(fetchWorkspaces());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateWorkspaceInfo]);

  useEffect(() => {
    dispatch(fetchWorkspaceDetails());
  }, [dispatch]);

  const currentMember = workspace?.members?.find(
    member => member.email === currentUser()?.email
  );

  return (
    <>
      <Flex justify="between">
        <Flex align="center" gap="4">
          <Text variant="h5" css={{ c: "$textHighEmp" }}>
            Workspace Settings
          </Text>
          {!!workspace?.name && (
            <Text
              variant="body1"
              css={{
                color: "$textMedEmp",
                fontWeight: "medium",
                "&::before": {
                  content: "• ",
                },
              }}
            >
              <Text css={{ fontWeight: "$regular" }} as="span">
                {workspace?.name}
              </Text>
            </Text>
          )}
        </Flex>

        {currentMember?.isAdmin && (
          <Button
            variant="standard"
            css={{ h: "$14", position: "absolute", right: "$12" }}
            className="justify-end"
            onClick={() => {
              AppAnalytics.track("btn.clicked", {
                btnId: "member.invited.workspace",
                componentId: "heading",
                tab: activeTab,
              });
              setDialogOpen(state => !state);
            }}
          >
            Invite Members
          </Button>
        )}
        <InviteModal dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
      </Flex>
      <Flex className="mt-6" gap="2">
        <TabGroup
          activeTab={activeTab}
          setActiveTab={value => {
            setActiveTab(value);
          }}
          tabs={tabs}
          btnId={`${activeTab}.tab`}
          componentId="workspaces"
        />
      </Flex>
      {activeTab === tabs[0].title ? (
        <Flex css={{ mb: "$12", mt: "$13", bg: "$borderDefault", h: "1px" }} />
      ) : null}
      {fetchWorkspaceInfo === API_CALL_STATE.IN_PROGRESS ? (
        <Flex css={{ h: "$40", w: "100%" }} justify="center" align="center">
          <Loading />
        </Flex>
      ) : (
        <>
          {activeTab === tabs[0].title ? <WorskpaceDetailsForm /> : <Members />}
        </>
      )}
    </>
  );
}

export default Workspace;
