import React from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import TitleValue from "src/components/Common/TitleValue";
import { Stats } from "src/types/sessions";
import { formattedDate, getLocalZone } from "src/utils";

type Props = { sessionStatsData: Stats };

const sessionStatsInfoObject = {
  "Start Time": "startTime",
  "End Time": "endTime",
  "Session Duration": "duration",
  "Combined Peer Duration": "participantMinutes",
  "Peer Count": "participants",
};

const SessionStatsInformation = ({ sessionStatsData }: Props) => {
  const listOfInfo = Object.keys(sessionStatsInfoObject);
  if (isEmpty(sessionStatsData)) {
    return <></>;
  }

  const zoneAbbr = getLocalZone();

  return (
    <>
      {listOfInfo.map((key: string) => {
        let modifiedKey = "";
        let val =
          sessionStatsData?.[
            sessionStatsInfoObject[
              key as keyof typeof sessionStatsInfoObject
            ] as keyof Stats
          ];
        if (!val) {
          return <> </>;
        }
        if (key === "Start Time" || key === "End Time") {
          const date = new Date(val as string);
          val = formattedDate(date);
          modifiedKey = `${key} (${zoneAbbr})`;
        }
        if (key === "Session Duration" || key === "Combined Peer Duration") {
          const duration = moment
            .duration(val as number, "seconds")
            .format("hh:mm:ss", { trim: false });

          // Calculate hours, minutes, and seconds
          val = duration;
        }

        return (
          <TitleValue
            title={modifiedKey ? modifiedKey : key}
            value={val.toString()}
            btnId={
              sessionStatsInfoObject[key as keyof typeof sessionStatsInfoObject]
            }
            componentId={
              sessionStatsInfoObject[key as keyof typeof sessionStatsInfoObject]
            }
          />
        );
      })}
    </>
  );
};

export default SessionStatsInformation;
