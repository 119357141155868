import React, { FC, useState } from "react";
import InfoCard from "src/components/Cards/InfoCard";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { DoorIcon, LinkIcon, SpotlightIcon } from "@100mslive/react-icons";
import { Box, Flex, Text, Tooltip } from "@100mslive/react-ui";

interface formSidebarProps {
  steps: Record<string, { key: string; name: string }>;
  activeStepIndex: number;
  infoTitle?: string;
  infoBody?: string;
  infoLink?: string;
  roomName?: string;
  templateName?: string;
  subdomain?: string;
}

const iconStyle = { minWidth: "20px", minHeight: "20px" };

const FormSidebar: FC<formSidebarProps> = ({
  steps,
  activeStepIndex,
  infoTitle,
  infoBody,
  infoLink,
  roomName,
  templateName,
  subdomain,
}) => {
  const [showInfoCard, setShowInfoCard] = useState(true);
  return (
    <Box>
      {activeStepIndex !== 0 ? (
        <Box
          css={{
            backgroundColor: "$surfaceDefault",
            borderRadius: "$1",
            p: "$10",
            border: "1px solid $borderLight",
            mb: "$14",
            w: "min(20vw, 320px)",
          }}
        >
          <Tooltip
            title={<Text variant="caption">{templateName}</Text>}
            side="bottom"
            align="center"
            disabled={!templateName}
          >
            {templateName ? (
              <Text
                variant="h6"
                css={{
                  color: "$textHighEmp",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "290px",
                  whiteSpace: "nowrap",
                }}
              >
                {templateName}
              </Text>
            ) : null}
          </Tooltip>
          {roomName ? (
            <Flex
              align="center"
              gap="2"
              css={{ color: "$textDisabled", mt: "$sm" }}
            >
              <DoorIcon style={iconStyle} />
              <Text variant="sm" css={{ color: "$textMedEmp" }}>
                {`Default Room: ${roomName}`}
              </Text>
            </Flex>
          ) : null}
          {subdomain ? (
            <Flex
              align="center"
              css={{ color: "$textDisabled", mt: "$sm", gap: "$5" }}
            >
              <LinkIcon style={iconStyle} />
              <Text variant="sm" css={{ color: "$textMedEmp" }}>
                {subdomain.includes(
                  `${process.env.REACT_APP_VERCEL_BASE_DOMAIN}`
                )
                  ? `Link: ${subdomain}`
                  : `Link: ${subdomain.concat(
                      `${process.env.REACT_APP_VERCEL_BASE_DOMAIN}`
                    )}`}
              </Text>
            </Flex>
          ) : null}
          <Flex css={{ h: "1px", w: "100%", bg: "$borderDefault", my: "$8" }} />
          <Flex css={{ c: "$primaryLight" }} align="center" justify="start">
            <SmallIcon>
              <SpotlightIcon />
            </SmallIcon>
            <Text
              css={{ ml: "$2", fontWeight: "$regular", c: "$primaryLight" }}
              variant="caption"
            >
              You can edit this later
            </Text>
          </Flex>
        </Box>
      ) : null}

      <Box
        css={{
          fontSize: "$h5",
          c: "$textHighEmp",
        }}
      >
        <ul className="parallel-line">
          {Object.values(steps).map((step, index: number) => (
            <Box
              key={step.key}
              css={{
                color:
                  activeStepIndex > index
                    ? "$primaryLight"
                    : activeStepIndex === index
                    ? "$textHighEmp"
                    : "$textDisabled",
              }}
            >
              <li>
                <Text
                  variant="md"
                  css={{
                    color:
                      activeStepIndex > index
                        ? "$primaryLight"
                        : activeStepIndex === index
                        ? "$textHighEmp"
                        : "$textDisabled",
                  }}
                >
                  {step.name}
                </Text>
              </li>
            </Box>
          ))}
        </ul>
      </Box>
      <InfoCard
        open={showInfoCard}
        setOpen={setShowInfoCard}
        title={infoTitle}
        body={infoBody}
        link={infoLink}
        componentId="guided.onboarding"
        hideCross
      />
    </Box>
  );
};

export default FormSidebar;
