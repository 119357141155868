import axios, { AxiosError } from "axios";
import { isEmpty } from "lodash";
import { Dispatch } from "redux";
import api from "src/api";
import { getPresignedUrl } from "src/services/sessions/recordings";
import { checkValidPresignedUrl } from "src/services/sessions/recordings/helpers";
import { getSessionDetails } from "src/services/sessions/services";
import { DashboardBackendAxiosError } from "src/types/customTypes";
import {
  Peer,
  SessionIssueData,
  SessionPeersWithIssues,
} from "src/types/sessions";
import { getAPIURL } from "src/utils";
import toastr from "toastr";
import { API_CALL_STATE } from "../../constants";

export const FETCH_SESSIONS_DETAILS_INIT = "FETCH_SESSIONS_DETAILS_INIT";
export const FETCH_SESSIONS_DETAILS_DONE = "FETCH_SESSIONS_DETAILS_DONE";
export const FETCH_SESSIONS_DETAILS_FAIL = "FETCH_SESSIONS_DETAILS_FAIL";

export const FETCH_SESSIONS_TIMELINE_DETAILS_INIT =
  "FETCH_SESSIONS_TIMELINE_DETAILS_INIT";
export const FETCH_SESSIONS_TIMELINE_DETAILS_DONE =
  "FETCH_SESSIONS_TIMELINE_DETAILS_DONE";
export const FETCH_SESSIONS_TIMELINE_DETAILS_FAIL =
  "FETCH_SESSIONS_TIMELINE_DETAILS_FAIL";

export const FETCH_SESSION_HEALTH_INIT = "FETCH_SESSION_HEALTH_INIT";
export const FETCH_SESSION_HEALTH_DONE = "FETCH_SESSION_HEALTH_DONE";
export const FETCH_SESSION_HEALTH_FAIL = "FETCH_SESSION_HEALTH_FAIL";

export const APPLY_SESSION_FILTER = "APPLY_SESSION_FILTER_DONE";
export const SET_FILTERED_PEERS = "SET_FILTERED_PEERS";
export const SORT_FILTERED_PEERS = "SORT_FILTERED_PEERS";
export const RESET_SESSION_DETAILS_PAGE = "RESET_SESSION_DETAILS_PAGE";

export const FETCH_SESSION_RECORDINGS_DETAILS_INIT =
  "FETCH_SESSION_RECORDINGS_DETAILS_INIT";
export const FETCH_SESSION_RECORDINGS_DETAILS_DONE =
  "FETCH_SESSION_RECORDINGS_DETAILS_DONE";
export const FETCH_SESSION_RECORDINGS_DETAILS_FAIL =
  "FETCH_SESSION_RECORDINGS_DETAILS_FAIL";
export const FETCH_SESSION_RECORDINGS_DETAILS_RESET =
  "FETCH_SESSION_RECORDINGS_DETAILS_RESET";
export const SET_DOWNLOAD_ASSET_LABEL = "SET_DOWNLOAD_ASSET_LABEL";
export const SET_SESSION_DETAILS_ACTIVE_TAB = "SET_SESSION_DETAILS_ACTIVE_TAB";

export const setDownloadAssetLabel = (label: string) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_DOWNLOAD_ASSET_LABEL, payload: label });
  };
};

export const fetchPresignedUrl = ({
  roomId,
  assetId,
  data,
  download = false,
  setLoading = () => {},
}: {
  setLoading: (value: boolean) => void;
  roomId: string;
  assetId: string;
  data: string;
  download?: boolean;
}) => {
  return async (
    dispatch: Dispatch,
    getState: () => {
      (): any;
      new (): any;
      sessions: { fetchPresignedUrlStatus: any };
    }
  ) => {
    const { fetchPresignedUrlStatus } = getState().sessions;
    if (fetchPresignedUrlStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    setLoading(true);

    dispatch({ type: FETCH_SESSION_RECORDINGS_DETAILS_INIT });
    try {
      const res = await getPresignedUrl({ roomId, assetId, data });
      if (
        res?.data?.success &&
        res?.data?.data?.url &&
        checkValidPresignedUrl({
          url: res?.data?.data?.url,
          isDownloadable: download,
        })
      ) {
        dispatch({
          type: FETCH_SESSION_RECORDINGS_DETAILS_DONE,
          payload: res?.data?.data,
        });
        setLoading(false);
      } else {
        throw new Error(res.data.msg || "Something went wrong");
      }
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      toastr.error(message);
      dispatch({ type: FETCH_SESSION_RECORDINGS_DETAILS_FAIL });
      setLoading(false);
    }
  };
};

export const fetchSessionInformation = (sessionId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_SESSIONS_DETAILS_INIT });
    try {
      const res = await getSessionDetails({ sessionId });
      if (!isEmpty(res?.data)) {
        const response = res.data;
        response.recording_logs = response?.recording_logs?.map((log: any) => {
          if (typeof {} === typeof log?.URL) {
            const urls = log?.URL?.single_files || [];
            if (log?.URL?.hls_vod?.playlist_location) {
              urls.push({
                recording_path: log?.hls_vod_recording?.path,
                location: log?.URL?.hls_vod?.playlist_location,
                type: "Composite",
              });
            }
            log.URL = urls;
          }
          return log;
        });
        dispatch({
          type: FETCH_SESSIONS_DETAILS_DONE,
          payload: response,
        });
      } else {
        throw new Error(res.data.msg || "Something went wrong");
      }
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      toastr.error(message);
      dispatch({ type: FETCH_SESSIONS_DETAILS_FAIL });
    }
  };
};

export const fetchSessionTimelineDetails = (sessionId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_SESSIONS_TIMELINE_DETAILS_INIT });
    try {
      await api
        .service("dashboard")
        .get(getAPIURL("analytics/sessions/event-logs"), {
          session_id: sessionId,
        })
        .then(res => {
          dispatch({
            type: FETCH_SESSIONS_TIMELINE_DETAILS_DONE,
            payload: res?.data?.data || {},
          });
        })
        .catch(err => {
          throw new Error(err);
        });
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      toastr.error(message);
      dispatch({ type: FETCH_SESSIONS_TIMELINE_DETAILS_FAIL });
    }
  };
};

export const applySessionFilter = (key: string, value: string | boolean) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: APPLY_SESSION_FILTER,
        payload: { key, value },
      });
    } catch (err) {
      console.error(err);
    }
  };
};

export const setFilteredPeers = (
  filteredPeers: Record<string, Peer>,
  sortBy: string,
  direction: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: SET_FILTERED_PEERS,
        payload: filteredPeers,
      });
      dispatch({
        type: SORT_FILTERED_PEERS,
        payload: { sortBy, direction, filteredPeers },
      });
    } catch (err) {
      console.error(err);
    }
  };
};

export const sortFilteredPeers = (
  sortBy: string,
  direction: string,
  filteredPeers: { [key: string]: Peer }
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: SORT_FILTERED_PEERS,
        payload: { sortBy, direction, filteredPeers },
      });
    } catch (err) {
      console.error(err);
    }
  };
};

export const fetchSessionHealth = (sessionId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_SESSION_HEALTH_INIT });
    try {
      await api
        .service("dashboard")
        .get(getAPIURL("analytics/sessions/health"), {
          session_id: sessionId,
        })
        .then(res => {
          const { data } = res?.data ?? {};
          const { mos_score, ...sessionIssuesData } = data ?? {};
          const reversedData: SessionPeersWithIssues = {};

          for (const key in sessionIssuesData) {
            if (key === "peers_affected") {
              continue;
            }
            const values = sessionIssuesData[key as keyof SessionIssueData];
            if (Array.isArray(values)) {
              values.forEach((value: string) => {
                if (!reversedData[value]) {
                  reversedData[value] = [];
                }
                reversedData[value]?.push(key);
              });
            }
          }
          dispatch({
            type: FETCH_SESSION_HEALTH_DONE,
            payload: {
              sessionIssuesData: sessionIssuesData || {},
              sessionPeersWithIssues: reversedData,
              mos_score: mos_score,
            },
          });
        })
        .catch(err => {
          throw new Error(err);
        });
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      toastr.error(message);
      dispatch({ type: FETCH_SESSION_HEALTH_FAIL });
    }
  };
};

export const setSessionDetailsActiveTab = (activeTab: string) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_SESSION_DETAILS_ACTIVE_TAB, payload: activeTab });
  };
};

export const resetSessionDetailsPage = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RESET_SESSION_DETAILS_PAGE,
        payload: {},
      });
    } catch (err) {
      console.error(err);
    }
  };
};
