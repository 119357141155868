import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Button, Dialog, Flex, Input, Select, Text } from "@100mslive/react-ui";
import { HLS_DEFAULT_VIDEO_LAYER_CONFIG, MODAL_Z_INDEX } from "../../constants";
import { calculateLayerDimensions } from "../../utils";
import { validateLayers } from "../../validations/destinations";

export const LayerModal = ({
  open,
  onOpenChange,
  layer,
  aspectRatio,
  setLayer,
  index = false,
}) => {
  const [invalidFields, setInvalidFields] = useState({});
  const [defaultLayer, setDefaultLayer] = useState(layer);
  const [localVideoLayer, setLocalVideoLayer] = useState(
    HLS_DEFAULT_VIDEO_LAYER_CONFIG[0].value
  );
  const [localVideoBitrate, setLocalVideoBitrate] = useState();
  const [validBitrate, setValidBitRate] = useState({});

  useEffect(() => {
    if (!isEmpty(layer) && !isEmpty(layer)) {
      setDefaultLayer(layer);
    }
  }, [layer]);

  useEffect(() => {
    if (localVideoLayer) {
      setValidBitRate({
        min: HLS_DEFAULT_VIDEO_LAYER_CONFIG?.filter(
          hlsLayer => hlsLayer.value === parseInt(localVideoLayer)
        )[0]?.minVideoBitRate,
        max: HLS_DEFAULT_VIDEO_LAYER_CONFIG?.filter(
          hlsLayer => hlsLayer.value === parseInt(localVideoLayer)
        )[0]?.maxVideoBitRate,
      });
    }
  }, [localVideoLayer]);

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Overlay
        css={{
          backgroundColor: "rgba(0, 0, 0, 0.8);",
          inset: 0,
          zIndex: MODAL_Z_INDEX,
        }}
      />
      <Dialog.Content
        css={{
          width: "min(550px,100%)",
          border: "1px solid $surfaceLight",
          px: "$8",
          zIndex: MODAL_Z_INDEX,
          pt: "$10",
          backgroundColor: "#1D2229",
        }}
        className="bg-surface-light"
      >
        <Dialog.Title className="bg-surface-light" css={{ pb: "$10" }}>
          <Text variant="h5">
            {!isEmpty(defaultLayer) ? defaultLayer.title : "Add new layer"}
          </Text>
        </Dialog.Title>
        <Flex direction="column" align="center">
          <Flex direction="row" justify="between" css={{ w: "100%" }}>
            <Flex direction="column">
              <Text variant="md">Video Layer</Text>
              <Text variant="sub2" css={{ c: "$textMedEmp" }}>{`Width: ${
                localVideoLayer
                  ? `${
                      calculateLayerDimensions({
                        aspectRatio: aspectRatio,
                        layerRes: localVideoLayer,
                      })["width"]
                    }p`
                  : null
              } Height: ${
                localVideoLayer
                  ? `${
                      calculateLayerDimensions({
                        aspectRatio: aspectRatio,
                        layerRes: localVideoLayer,
                      })["height"]
                    }p`
                  : null
              }`}</Text>
              <Text variant="sub2" css={{ c: "$textMedEmp" }}>
                Based on selected aspect ratio {aspectRatio}
              </Text>
              {invalidFields?.videoLayer ? (
                <div className="mt-1 left-0 text-danger text-0.8125">
                  {invalidFields["videoLayer"]}
                </div>
              ) : null}
            </Flex>
            <div className="flex justify-end items-center">
              <Select.Root>
                <Select.DefaultDownIcon />
                <Select.Select
                  css={{ h: "100%" }}
                  className="input"
                  onChange={event => {
                    setLocalVideoLayer(event.target.value);
                  }}
                  value={localVideoLayer}
                >
                  {HLS_DEFAULT_VIDEO_LAYER_CONFIG.map(hlsLayer => {
                    return (
                      <option value={hlsLayer.value} key={hlsLayer.key}>
                        {hlsLayer.title}
                      </option>
                    );
                  })}
                </Select.Select>
              </Select.Root>
            </div>
          </Flex>
          {localVideoLayer ? (
            <Flex
              direction="row"
              justify="between"
              css={{ w: "100%", my: "$4" }}
            >
              <Flex direction="column" css={{ position: "relative" }}>
                <Text variant="md">Video Bitrate</Text>
                {!!localVideoLayer && !isEmpty(validBitrate) ? (
                  <Text variant="sub2" css={{ c: "$textMedEmp" }}>
                    {`Between ${validBitrate?.min} to ${validBitrate?.max}`}
                  </Text>
                ) : null}
                {invalidFields?.videoBitrate ? (
                  <div className="mt-1 left-0 text-danger text-0.8125">
                    {invalidFields["videoBitrate"]}
                  </div>
                ) : null}
              </Flex>
              <Flex
                align="center"
                justify="end"
                css={{
                  "input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button":
                    {
                      webkitAppearance: "none",
                      mozAppearance: "none",
                      appearance: "none",
                      margin: 0,
                    },
                }}
              >
                <Input
                  onChange={event => {
                    setLocalVideoBitrate(event.target.value);
                  }}
                  type="number"
                  disabled={!parseInt(localVideoLayer)}
                  max={validBitrate?.max}
                  min={validBitrate?.min}
                  css={{ webkitAppearance: "none", mozAppearance: "textfield" }}
                  value={localVideoBitrate ? localVideoBitrate : ""}
                />
              </Flex>
            </Flex>
          ) : null}
          <Flex
            css={{ w: "100%", mt: "$4" }}
            justify="between"
            align="center"
            direction="rowReverse"
          >
            <Button
              onClick={() => {
                const validationResult = validateLayers({
                  videoBitrate: parseInt(localVideoBitrate),
                  videoLayer: parseInt(localVideoLayer),
                });
                setInvalidFields(validationResult?.invalidFields);
                if (validationResult.isValid) {
                  setLayer({
                    videoBitrate: parseInt(localVideoBitrate),
                    videoLayer: parseInt(localVideoLayer),
                    aspectRatio: aspectRatio,
                    index: index ? defaultLayer.i : undefined,
                  });
                  onOpenChange(!open);
                }
              }}
            >
              Save New Layer
            </Button>
            <Dialog.Close asChild>
              <Button variant="standard" outlined>
                Go Back
              </Button>
            </Dialog.Close>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};
