import { useState } from "react";
import { AppAnalytics } from "src/helpers";
import { FilterIcon, SearchIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, Popover, Text } from "@100mslive/react-ui";
import FilterInput from "./FilterInput";
import Pill from "./Pill";
import { isValidObjectID } from "../../../utils";
import Credential from "../Credential";
import FilterError from "../FilterError";

const buttonStyle = { p: "$3 $md", fontSize: "$sm", borderRadius: "$0" };

const FilterSearch = ({
  componentId = "",
  placeholder = "",
  filters = [],
  resetDisabled,
  noFilters,
  invalidInputErrorString = "",
  applyDisabled,
  alignValue = "end",
  inputValue,
  isIDInput = true,
  filterValues,
  maxWidth = "100%",
  onFilterChange,
  onFilterRemove,
  onInputChange,
  applyFunction = () => {
    return;
  },
  resetFunction = () => {
    return;
  },
  loading,
  error = "",
}) => {
  const validInput = isValidObjectID(inputValue);
  const [showFilters, setShowFilters] = useState(false);
  const filterValuesArray = filters.map(filter => {
    return filterValues[filter._key];
  });
  const isFilterFilled = filterValuesArray.reduce((filter, reducedValue) => {
    return reducedValue || filter;
  }, false);
  return (
    <Flex css={{ w: "100%" }} direction="column">
      <Flex css={{ w: "100%", gap: "$4", h: "42px" }}>
        <Flex css={{ w: "100%", gap: "$4", maxWidth: maxWidth }}>
          <Credential
            readOnly={false}
            leftIcon={
              <Box css={{ c: "$textDisabled" }}>
                <SearchIcon />
              </Box>
            }
            showClipboard={false}
            label="Search"
            onChangeHandler={val => {
              isIDInput ? onInputChange(val.trim()) : onInputChange(val);
            }}
            inputCss={{
              backgroundColor: "$surfaceDefault",
              borderRadius: "$0",
              border: "1px solid $borderLight",
            }}
            onEnterKeyPress={applyFunction}
            hideLabel
            title=""
            placeholder={placeholder}
            outlined
            value={inputValue}
            error={error || (isIDInput && !validInput)}
          />

          {noFilters ? null : (
            <Popover.Root open={showFilters} onOpenChange={setShowFilters}>
              <Popover.Trigger
                asChild
                css={{
                  backgroundColor: "$secondaryDefault !important",
                  borderRadius: "$0",
                  p: "$5 $6",
                  "&:hover": {
                    backgroundColor: "$secondaryLight !important",
                  },
                }}
              >
                <Button
                  variant="standard"
                  css={{ color: "$secondaryDark" }}
                  disabled={showFilters}
                >
                  <Flex
                    align="center"
                    justify="center"
                    gap="1"
                    css={{ color: "$textHighEmp" }}
                  >
                    <FilterIcon />
                    <Text
                      variant="sm"
                      css={{
                        fontWeight: "$semiBold",
                        color: "inherit",
                      }}
                    >
                      Filter
                    </Text>
                  </Flex>
                </Button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  alignOffset={0}
                  sideOffset={8}
                  align={alignValue}
                  css={{
                    p: 0,
                  }}
                >
                  <Box
                    css={{
                      minWidth: 540,
                      backgroundColor: "$surfaceLight",
                      borderRadius: "$1",
                      outline: "none",
                      border: "1px solid",
                      borderColor: "$borderDefault",
                      overflow: "hidden",
                      pt: "$xs",
                    }}
                  >
                    {filters.map(filter => {
                      return (
                        <FilterInput
                          key={filter?._key}
                          text={filter?.label}
                          _key={filter?._key}
                          value={filterValues[filter._key]}
                          options={filter?.options}
                          type={filter.type}
                          disabled={loading}
                          onEnterKeyPress={() => {
                            applyFunction();
                            setShowFilters(false);
                          }}
                          onChange={val =>
                            onFilterChange({ key: filter?._key, value: val })
                          }
                          {...filter}
                        />
                      );
                    })}
                    <Flex
                      gap="2"
                      justify="end"
                      css={{
                        backgroundColor: "$surfaceDefault",
                        p: "$md",
                        w: "100%",
                        mt: "$xs",
                      }}
                    >
                      <Button
                        variant="standard"
                        outlined
                        reset={resetDisabled}
                        css={buttonStyle}
                        onClick={resetFunction}
                      >
                        Reset
                      </Button>
                      <Button
                        css={buttonStyle}
                        disabled={applyDisabled && !isFilterFilled}
                        onClick={() => {
                          applyFunction();
                          setShowFilters(false);
                          AppAnalytics.track("btn.clicked", {
                            btnId: "filter.applied",
                            componentId: componentId,
                            ...filterValues,
                          });
                        }}
                        loading={loading}
                      >
                        Apply
                      </Button>
                    </Flex>
                  </Box>
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          )}
        </Flex>
      </Flex>
      <FilterError errorString={validInput ? "" : invalidInputErrorString} />
      {!showFilters && isFilterFilled ? (
        <Flex css={{ mt: "$md", gap: "$xs" }}>
          {filters.map(filter => (
            <Pill
              key={filter._key}
              text={filter.label}
              value={filterValues[filter._key]}
              options={filter?.options}
              type={filter?.type}
              onRemove={() => {
                onFilterRemove(filter._key);
                applyFunction();
              }}
            />
          ))}
        </Flex>
      ) : null}
    </Flex>
  );
};

export default FilterSearch;
