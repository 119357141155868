import { cloneDeep } from "lodash";
import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import { presignedUrlResponse } from "src/modules/sessions/types";
import {
  Peer,
  Session,
  SessionFilter,
  SessionIssueData,
  SessionPeersWithIssues,
  SessionTimelineData,
} from "src/types/sessions";
import { isValidObjectID } from "src/utils";
import {
  APPLY_SESSION_FILTER,
  FETCH_SESSION_HEALTH_DONE,
  FETCH_SESSION_HEALTH_FAIL,
  FETCH_SESSION_HEALTH_INIT,
  FETCH_SESSION_RECORDINGS_DETAILS_DONE,
  FETCH_SESSION_RECORDINGS_DETAILS_FAIL,
  FETCH_SESSION_RECORDINGS_DETAILS_INIT,
  FETCH_SESSION_RECORDINGS_DETAILS_RESET,
  FETCH_SESSIONS_DETAILS_DONE,
  FETCH_SESSIONS_DETAILS_FAIL,
  FETCH_SESSIONS_DETAILS_INIT,
  FETCH_SESSIONS_TIMELINE_DETAILS_DONE,
  FETCH_SESSIONS_TIMELINE_DETAILS_FAIL,
  FETCH_SESSIONS_TIMELINE_DETAILS_INIT,
  RESET_SESSION_DETAILS_PAGE,
  SET_DOWNLOAD_ASSET_LABEL,
  SET_FILTERED_PEERS,
  SET_SESSION_DETAILS_ACTIVE_TAB,
  SORT_FILTERED_PEERS,
} from "./actions";
import { API_CALL_STATE, SESSION_DETAILS_TABS } from "../../constants";
const initialState = {
  presignedUrlData: {} as presignedUrlResponse,
  fetchSessionDetailsStatus: "",
  sessionDetails: {} as Session,
  fetchPresignedUrlStatus: "",
  downloadAssetLabel: "",
  sessionsStatus: "",
  fetchSessionTimelineDetailsStatus: "",
  sessionTimelineDetails: {} as SessionTimelineData,
  sessionIssues: {} as SessionIssueData,
  fetchSessionHealthStatus: "",
  sessionMosScore: null as number | null,
  derivedStates: {
    isSessionLive: null as boolean | null,
    peers: {} as { [key: string]: Peer },
    filteredPeers: {} as { [key: string]: Peer },
    recording_logs: [] as any[],
    sessionPeersWithIssues: {} as SessionPeersWithIssues,
    isSearch: false,
    isValid: true,
    sessionDetailsActiveTab: SESSION_DETAILS_TABS.PARTICIPANTS,
  },
  sessionFilter: {} as SessionFilter,
  sortPeersBy: {
    by: "",
    direction: "",
  },
};

export const sessions = handleActions(
  {
    [FETCH_SESSION_RECORDINGS_DETAILS_INIT]: state => ({
      ...state,
      fetchPresignedUrlStatus: API_CALL_STATE.IN_PROGRESS,
      presignedUrlData: {} as presignedUrlResponse,
    }),
    [FETCH_SESSION_RECORDINGS_DETAILS_DONE]: (state, action: AnyAction) => ({
      ...state,
      fetchPresignedUrlStatus: API_CALL_STATE.DONE,
      presignedUrlData: action.payload,
    }),
    [FETCH_SESSION_RECORDINGS_DETAILS_FAIL]: state => ({
      ...state,
      fetchPresignedUrlStatus: API_CALL_STATE.FAILED,
      presignedUrlData: {} as presignedUrlResponse,
    }),
    [FETCH_SESSION_RECORDINGS_DETAILS_RESET]: state => ({
      ...state,
      fetchPresignedUrlStatus: "",
      presignedUrlData: {} as presignedUrlResponse,
      downloadAssetLabel: "",
    }),
    [SET_DOWNLOAD_ASSET_LABEL]: (state, action: AnyAction) => ({
      ...state,
      downloadAssetLabel: action.payload,
    }),
    [SET_SESSION_DETAILS_ACTIVE_TAB]: (state, action: AnyAction) => {
      const newState = cloneDeep(state);
      newState.derivedStates.sessionDetailsActiveTab = action.payload;
      return newState;
    },
    [FETCH_SESSIONS_DETAILS_INIT]: state => ({
      ...state,
      sessionDetails: {} as Session,
      fetchSessionDetailsStatus: API_CALL_STATE.IN_PROGRESS,
      derivedStates: {
        ...state.derivedStates,
        isSessionLive: null,
      },
    }),
    [FETCH_SESSIONS_DETAILS_DONE]: (state, action: AnyAction) => ({
      ...state,
      fetchSessionDetailsStatus: API_CALL_STATE.DONE,
      sessionDetails: action.payload,
      derivedStates: {
        ...state.derivedStates,
        isSessionLive: action?.payload?.active,
        filteredPeers: action?.payload?.peers,
        peers: action?.payload?.peers,
        recording_logs: action?.payload?.recording_logs,
      },
    }),
    [FETCH_SESSIONS_DETAILS_FAIL]: state => ({
      ...state,
      fetchSessionDetailsStatus: API_CALL_STATE.FAILED,
      derivedStates: {
        ...state.derivedStates,
        isSessionLive: false,
      },
    }),

    [FETCH_SESSIONS_TIMELINE_DETAILS_DONE]: (state, action: AnyAction) => ({
      ...state,
      fetchSessionTimelineDetailsStatus: API_CALL_STATE.DONE,
      sessionTimelineDetails: action.payload,
    }),
    [FETCH_SESSIONS_TIMELINE_DETAILS_FAIL]: state => ({
      ...state,
      fetchSessionTimelineDetailsStatus: API_CALL_STATE.FAILED,
    }),

    [FETCH_SESSIONS_TIMELINE_DETAILS_INIT]: state => ({
      ...state,
      fetchSessionTimelineDetailsStatus: API_CALL_STATE.IN_PROGRESS,
      sessionTimelineDetails: {} as SessionTimelineData,
    }),

    [FETCH_SESSION_HEALTH_DONE]: (state, action: AnyAction) => ({
      ...state,
      fetchSessionHealthStatus: API_CALL_STATE.DONE,
      sessionIssues: action.payload.sessionIssuesData,
      sessionMosScore: action.payload.mos_score,
      derivedStates: {
        ...state.derivedStates,
        sessionPeersWithIssues: action.payload.sessionPeersWithIssues,
      },
    }),
    [FETCH_SESSION_HEALTH_FAIL]: state => ({
      ...state,
      fetchSessionHealthStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_SESSION_HEALTH_INIT]: state => ({
      ...state,
      fetchSessionHealthStatus: API_CALL_STATE.IN_PROGRESS,
      sessionIssues: {} as SessionIssueData,
      sessionMosScore: null as number | null,
      derivedStates: {
        ...state.derivedStates,
        sessionPeersWithIssues: {} as SessionPeersWithIssues,
      },
    }),

    [APPLY_SESSION_FILTER]: (state, action: AnyAction) => {
      const { key, value } = action.payload;
      const { sessionFilter } = state;

      const updatedSessionFilter = {
        ...sessionFilter,
        [key]: value,
      };

      const isSearch = Object.values(updatedSessionFilter).some(Boolean);

      const isValid = sessionFilter.id
        ? isValidObjectID(sessionFilter.id)
        : isSearch || value;

      return {
        ...state,
        sessionFilter: updatedSessionFilter,
        derivedStates: {
          ...state.derivedStates,
          isSearch,
          isValid,
        },
      };
    },
    [SET_FILTERED_PEERS]: (state, action: AnyAction) => ({
      ...state,
      derivedStates: {
        ...state.derivedStates,
        filteredPeers: action.payload,
      },
    }),
    [SORT_FILTERED_PEERS]: (state, action: AnyAction) => ({
      ...state,
      sortPeersBy: {
        by: action.payload.sortBy,
        direction: action.payload.direction,
      },
      derivedStates: {
        ...state.derivedStates,
        filteredPeers: action.payload.filteredPeers,
      },
    }),
    [RESET_SESSION_DETAILS_PAGE]: (state, action: AnyAction) => ({
      ...state,
      sessionFilter: action.payload,
      sortPeersBy: { by: "", direction: "asc" },
      derivedStates: {
        ...state.derivedStates,
        filteredPeers: {},
      },
    }),
  },
  { ...initialState }
);
