import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import { BrushDesignIcon } from "@100mslive/react-icons";
import { Box, Flex, Text } from "@100mslive/react-ui";

function PrebuiltHeaderTitle() {
  const policyInfo = useSelector((state: RootState) => state.roles.policyInfo);
  return (
    <Flex css={{ c: "$textDisabled" }}>
      <Box css={{ mr: "$4" }}>
        <BrushDesignIcon />
      </Box>
      <Text variant="sub2" css={{ textOverflow: "ellipsis" }}>
        Customise: {policyInfo?.name}
      </Text>
    </Flex>
  );
}

export default PrebuiltHeaderTitle;
