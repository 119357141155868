import {
  amex,
  diners,
  discover,
  jcb,
  mastercard,
  unionpay,
  unknownCard,
  visa,
} from "./assets/images";
import { WorkspaceRole } from "./types/workspaces";

export const DEFAULT_FRAMERATE = parseInt(
  process.env.REACT_APP_DEFAULT_FRAMERATE || "30"
);

export const roomRecordingStateTypes = [
  { value: "auto", name: "Auto" },
  { value: "enabled", name: "Enabled" },
  { value: "disabled", name: "Disabled" },
];
export const paymentsCardImages = {
  amex,
  mastercard,
  visa,
  discover,
  unionpay,
  jcb,
  diners,
  unknown: unknownCard,
};

export const AppType = {
  VIDEOCONF: "VIDEOCONF",
  AUDIOROOM: "AUDIOROOM",
  EDTECH: "EDTECH",
  EVENTAPP: "EVENTAPP",
  CREATEOWN: "CREATEOWN",
};

export const HSL_DESTINATIONS_QUALITIES = ["_720p", "_540p", "_480p", "_360p"];

export const DEFAULT_ASPECT_RATIO = "16:9";

export const HLS_DEFAULT_VIDEO_LAYER_CONFIG = [
  {
    i: 1,
    title: "1080p",
    key: "_1080p",
    value: 1080,
    defaultVideoBitrate: 4000,
    minVideoBitRate: 1500,
    defaultAudioBitrate: 64,
    maxVideoBitRate: 5500,
  },
  {
    i: 2,
    title: "720p",
    key: "_720p",
    value: 720,
    defaultVideoBitrate: 1500,
    minVideoBitRate: 1000,
    defaultAudioBitrate: 64,
    maxVideoBitRate: 3000,
    default: true,
  },
  {
    i: 3,
    title: "540p",
    key: "_540p",
    value: 540,
    defaultVideoBitrate: 1000,
    minVideoBitRate: 500,
    defaultAudioBitrate: 64,
    maxVideoBitRate: 1500,
    default: true,
  },

  {
    i: 4,
    title: "480p",
    key: "_480p",
    value: 480,
    defaultVideoBitrate: 850,
    minVideoBitRate: 300,
    defaultAudioBitrate: 64,
    maxVideoBitRate: 1200,
    default: true,
  },
  {
    i: 5,
    title: "360p",
    key: "_360p",
    value: 360,
    defaultVideoBitrate: 450,
    minVideoBitRate: 150,
    defaultAudioBitrate: 64,
    maxVideoBitRate: 700,
    default: true,
  },
  {
    i: 6,
    title: "240p",
    key: "_240p",
    value: 240,
    defaultVideoBitrate: 300,
    minVideoBitRate: 100,
    defaultAudioBitrate: 64,
    maxVideoBitRate: 450,
  },
  {
    i: 7,
    title: "144p",
    key: "_144p",
    value: 144,
    defaultVideoBitrate: 200,
    minVideoBitRate: 64,
    defaultAudioBitrate: 64,
    maxVideoBitRate: 300,
  },
];

export const RolePriority = {
  1: "1 (High)",
  2: "2",
  3: "3",
  4: "4",
  5: "5 (Low)",
};

export const MaxPeerCount = {
  0: "No Limit",
  100: "Custom",
  "-1": "No Peer",
};

export const WORKSPACE_INSIGHTS_FILTER = {
  daily: "Daily",
  weekly: "Weekly",
};

export const WORKSPACE_CALENDAR_RANGE_FILTER = {
  since: "Since",
  last: "Last",
  between: "Between",
};

export const VIDEO_QUALITY_FOR_POLICY = {
  // 1080: {
  //   "16:9": {
  //     bitRate: 1300,
  //     maxSubsBitrate: 12000,
  //   },
  //   "9:16": {
  //     bitRate: 1300,
  //     maxSubsBitrate: 12000,
  //   },
  //   "4:3": {
  //     bitRate: 1200,
  //     maxSubsBitrate: 12000,
  //   },
  //   "3:4": {
  //     bitRate: 1200,
  //     maxSubsBitrate: 12000,
  //   },
  //   "1:1": {
  //     bitRate: 800,
  //     maxSubsBitrate: 12000,
  //   },
  // },
  720: {
    "16:9": {
      bitRate: 850,
      maxSubsBitrate: 8000,
    },
    "9:16": {
      bitRate: 850,
      maxSubsBitrate: 8000,
    },
    "4:3": {
      bitRate: 700,
      maxSubsBitrate: 8000,
    },
    "3:4": {
      bitRate: 700,
      maxSubsBitrate: 8000,
    },
    "1:1": {
      bitRate: 450,
      maxSubsBitrate: 8000,
    },
  },
  480: {
    "16:9": {
      bitRate: 500,
      maxSubsBitrate: 5200,
    },
    "9:16": {
      bitRate: 500,
      maxSubsBitrate: 5200,
    },
    "4:3": {
      bitRate: 400,
      maxSubsBitrate: 5200,
    },
    "3:4": {
      bitRate: 400,
      maxSubsBitrate: 5200,
    },
    "1:1": {
      bitRate: 250,
      maxSubsBitrate: 5200,
    },
  },
  360: {
    "16:9": {
      bitRate: 400,
      maxSubsBitrate: 3200,
    },
    "9:16": {
      bitRate: 400,
      maxSubsBitrate: 3200,
    },
    "4:3": {
      bitRate: 300,
      maxSubsBitrate: 3200,
    },
    "3:4": {
      bitRate: 300,
      maxSubsBitrate: 3200,
    },
    "1:1": {
      bitRate: 200,
      maxSubsBitrate: 3200,
    },
  },
  240: {
    "16:9": {
      bitRate: 240,
      maxSubsBitrate: 2000,
    },
    "9:16": {
      bitRate: 240,
      maxSubsBitrate: 2000,
    },
    "4:3": {
      bitRate: 200,
      maxSubsBitrate: 2000,
    },
    "3:4": {
      bitRate: 200,
      maxSubsBitrate: 2000,
    },
    "1:1": {
      bitRate: 156,
      maxSubsBitrate: 2000,
    },
  },
  120: {
    "16:9": {
      bitRate: 110,
      maxSubsBitrate: 1500,
    },
    "9:16": {
      bitRate: 110,
      maxSubsBitrate: 1500,
    },
    "4:3": {
      bitRate: 100,
      maxSubsBitrate: 1500,
    },
    "3:4": {
      bitRate: 100,
      maxSubsBitrate: 1500,
    },
    "1:1": {
      bitRate: 60,
      maxSubsBitrate: 1500,
    },
  },
};

export const VideoQuality: Record<
  string,
  {
    [key: string]: {
      width: number;
      height: number;
      frameRate: number;
      bitRate: number;
      maxSubsBitrate?: number;
      codec: string;
    };
  }
> = {
  // _1080p: {
  //   "16:9": {
  //     width: 1920,
  //     height: 1080,
  //     frameRate: 30,
  //     bitRate: 1300,
  //     maxSubsBitrate: 12000,
  //     codec: "vp8",
  //   },
  //   "9:16": {
  //     height: 1920,
  //     width: 1080,
  //     frameRate: 30,
  //     bitRate: 1300,
  //     maxSubsBitrate: 12000,
  //     codec: "vp8",
  //   },
  //   "4:3": {
  //     width: 1440,
  //     height: 1080,
  //     frameRate: 30,
  //     bitRate: 1200,
  //     codec: "vp8",
  //     maxSubsBitrate: 12000,
  //   },
  //   "3:4": {
  //     height: 1440,
  //     width: 1080,
  //     frameRate: 30,
  //     bitRate: 1200,
  //     codec: "vp8",
  //     maxSubsBitrate: 12000,
  //   },
  //   "1:1": {
  //     width: 1080,
  //     height: 1080,
  //     frameRate: 30,
  //     bitRate: 800,
  //     codec: "vp8",
  //     maxSubsBitrate: 12000,
  //   },
  // },
  _720p: {
    "16:9": {
      width: 1280,
      height: 720,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 850,
      codec: "vp8",
      maxSubsBitrate: 8000,
    },
    "9:16": {
      height: 1280,
      width: 720,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 850,
      codec: "vp8",
      maxSubsBitrate: 8000,
    },
    "4:3": {
      width: 960,
      height: 720,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 700,
      codec: "vp8",
      maxSubsBitrate: 8000,
    },
    "3:4": {
      height: 960,
      width: 720,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 700,
      codec: "vp8",
      maxSubsBitrate: 8000,
    },
    "1:1": {
      width: 720,
      height: 720,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 450,
      codec: "vp8",
      maxSubsBitrate: 8000,
    },
  },
  _480p: {
    "16:9": {
      width: 854,
      height: 480,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 500,
      codec: "vp8",
      maxSubsBitrate: 5200,
    },
    "9:16": {
      height: 854,
      width: 480,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 500,
      codec: "vp8",
      maxSubsBitrate: 5200,
    },
    "4:3": {
      width: 640,
      height: 480,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 400,
      codec: "vp8",
      maxSubsBitrate: 5200,
    },
    "3:4": {
      height: 640,
      width: 480,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 400,
      codec: "vp8",
      maxSubsBitrate: 5200,
    },
    "1:1": {
      width: 480,
      height: 480,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 250,
      codec: "vp8",
      maxSubsBitrate: 5200,
    },
  },
  _360p: {
    "16:9": {
      width: 640,
      height: 360,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 400,
      codec: "vp8",
      maxSubsBitrate: 3200,
    },
    "9:16": {
      height: 640,
      width: 360,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 400,
      codec: "vp8",
      maxSubsBitrate: 3200,
    },
    "4:3": {
      width: 480,
      height: 360,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 300,
      codec: "vp8",
      maxSubsBitrate: 3200,
    },
    "3:4": {
      height: 480,
      width: 360,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 300,
      codec: "vp8",
      maxSubsBitrate: 3200,
    },
    "1:1": {
      width: 360,
      height: 360,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 200,
      codec: "vp8",
      maxSubsBitrate: 3200,
    },
  },
  _240p: {
    "16:9": {
      width: 426,
      height: 240,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 240,
      codec: "vp8",
      maxSubsBitrate: 2000,
    },
    "9:16": {
      height: 426,
      width: 240,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 240,
      codec: "vp8",
      maxSubsBitrate: 2000,
    },
    "4:3": {
      width: 320,
      height: 240,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 200,
      codec: "vp8",
      maxSubsBitrate: 2000,
    },
    "3:4": {
      height: 320,
      width: 240,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 200,
      codec: "vp8",
      maxSubsBitrate: 2000,
    },
    "1:1": {
      width: 240,
      height: 240,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 156,
      codec: "vp8",
      maxSubsBitrate: 2000,
    },
  },
  _120p: {
    "16:9": {
      width: 213,
      height: 120,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 110,
      codec: "vp8",
      maxSubsBitrate: 1500,
    },
    "9:16": {
      height: 213,
      width: 120,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 110,
      codec: "vp8",
      maxSubsBitrate: 1500,
    },
    "4:3": {
      width: 160,
      height: 120,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 100,
      codec: "vp8",
      maxSubsBitrate: 1500,
    },
    "3:4": {
      height: 160,
      width: 120,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 100,
      codec: "vp8",
      maxSubsBitrate: 1500,
    },
    "1:1": {
      width: 120,
      height: 120,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 60,
      codec: "vp8",
      maxSubsBitrate: 1500,
    },
  },
};

export const UPLOAD_TYPES = [
  {
    value: "",
    name: "100ms",
  },
  {
    value: "s3",
    name: "Amazon S3",
  },
  {
    value: "gs",
    name: "Google Cloud Storage",
  },
  {
    value: "oss",
    name: "Alibaba OSS",
  },
  {
    value: "r2",
    name: "Cloudflare R2",
  },
];

export const ScreenShareQuality = {
  _1080p: {
    width: 1920,
    height: 1080,
    frameRate: 10,
    bitRate: null,
    codec: "vp8",
  },
  _720p: {
    width: 1280,
    height: 720,
    frameRate: 10,
    bitRate: 1000,
    codec: "vp8",
  },
  _540p: {
    width: 960,
    height: 540,
    frameRate: 10,
    bitRate: 800,
    codec: "vp8",
  },
};

export const UI_VIEW_MODE = [
  { value: "grid", key: "Grid" },
  { value: "activespeaker", key: "Active Speaker" },
];

export const TILE_SHAPE = [
  { type: "16:9", value: "16-9", text: "Wide" },
  { type: "4:3", value: "4-3", text: "Landscape" },
  { type: "1:1", value: "1-1", text: "Square" },
  { type: "3:4", value: "3-4", text: "Portrait" },
  { type: "9:16", value: "9-16", text: "Mobile" },
];

export const HLS_PLAYLIST_TYPE: Record<string, string> = {
  live: "Seek only within rolling window (live)",
  event: "Seek to any point in the past (event)",
  noSeek: "Live without any seek option",
};

export const TILE_OFFSET = [
  { key: "Compact", value: 0 },
  { key: "Spacious", value: 40 },
];

export const API_CALL_STATE = {
  IN_PROGRESS: "processing",
  DONE: "success",
  FAILED: "fail",
  RESET: "reset",
};

export const UNSAVED_CHANGES_TEXT =
  "Changes that you made may not be saved. Continue?";

export const occupations = [
  "Business Owner / Leader",
  "Head of Engineering / VP Engineering",
  "Product Manager",
  "Sr Manager",
  "Software Developer",
  "Student",
  "Other",
];

export const MODAL_Z_INDEX = 1100;

export const recordingTypes = {
  RTMP: "Browser",
  Composite: "VOD",
};

export const INVALID_WEBHOOK_URL_MESSAGE = "Please provide a valid webhook URL";
export const INVALID_WEBHOOK_URL_MESSAGE_HIPAA =
  "Your webhook is using an ‘http’ URL. Please switch to an ‘https’ URL for secure data transmission.";

export const defaultRecordingObject = {
  hlsVod: true,
  layers: [],
  thumbnails: {
    enabled: true,
    offsets: [2],
    fps: 60,
  },
  presignDuration: 604800,
};

export const prebuiltFonts = [
  "Inter",
  "Roboto",
  "Lato",
  "Montserrat",
  "Open Sans",
  "IBM Plex Sans",
];

export const DEFAULT_LOGO =
  "https://storage.googleapis.com/100ms-cms-prod/cms/100ms_18a29f69f2/100ms_18a29f69f2.png";

export const PREBUILT_SIDEBAR_WIDTH = "320px";
export const MEMBER_TYPES = {
  ADMIN: "admin",
  MEMBER: "member",
  OWNER: "owner",
  READ_ONLY: "read_only",
};

export const ROLE_TYPES = {
  READ_ONLY: "ws_read_only",
};

export const WORKSPACE_ROLES: WorkspaceRole = {
  member: {
    name: "Member",
    id: "ws_member",
    canDelete: [],
    canChange: [],
    showSettings: false,
  },
  admin: {
    name: "Admin",
    id: "ws_admin",
    showSettings: true,
    canDelete: ["ws_admin", "ws_member"],
    canChange: ["ws_member"],
  },
  owner: {
    name: "Organisation Owner",
    id: "ws_org_admin",
    showSettings: true,
    canDelete: ["ws_admin", "ws_member"],
    canChange: ["ws_admin", "ws_member"],
  },
  read_only: {
    name: "Read only",
    id: "ws_read_only",
    showSettings: false,
    canDelete: [],
    canChange: [],
  },
};

export const WORKSPACE_ROLE_IDS = ["member", "admin", "owner", "read_only"];

export const DEVREV_KEYS = {
  DEVREV_TOGGLE: "devrev-toggle",
  IS_SUPPORT_LOADING: "isSupportLoading",
  GOT_NEW_SUPPORT_MESSAGE: "gotNewSupportMessage",
};

export const DEVREV_CONSTANTS = {
  closed: {
    devRevKey: "ON_PLUG_WIDGET_CLOSED",
    localStorageKey: DEVREV_KEYS.DEVREV_TOGGLE,
  },
  opened: {
    devRevKey: "ON_PLUG_WIDGET_OPENED",
    localStorageKey: DEVREV_KEYS.DEVREV_TOGGLE,
  },
  ready: {
    devRevKey: "ON_PLUG_WIDGET_READY",
    localStorageKey: DEVREV_KEYS.IS_SUPPORT_LOADING,
  },
  messageCount: {
    devRevKey: "ON_PLUG_WIDGET_UNREAD_COUNT_CHANGE",
    localStorageKey: DEVREV_KEYS.GOT_NEW_SUPPORT_MESSAGE,
  },
};

export const ASSET_TYPES = {
  TRANSCRIPT: "transcript",
  SUMMARY: "summary",
  ROOM_COMPOSITE: "room-composite",
  ROOM_COMPOSITE_LEGACY: "room-composite-legacy",
  ROOM_VOD: "room-vod",
  CHAT: "chat",
  TRACK: "track",
  STREAM: "stream",
};

export const STREAM_TYPES = {
  REGULAR: "regular",
  SCREENSHARE: "screen",
};

export const TRACK_TYPES = {
  AUDIO: "audio",
  VIDEO: "video",
};

export const ASSET_STATUS = {
  PROCESSING: "processing",
  COMPLETED: "completed",
  FAILED: "failed",
};

export const TEMPLATE_TABS = {
  ROLES: "Roles",
  LIVE_STREAMING: "Live Streaming",
  ADVANCED_SETTINGS: "Advanced Settings",
  RECORDING: "Recording",
  TRANSCRIPTION: "Transcription",
};

export const RECORDING_TYPES = {
  COMPOSITE: "Composite",
  HLS: "HLS",
  BROWSER: "Browser",
  CHAT: "Chat",
};

// add other policy types
export const templateTypes = { LIVE_STREAM: "LIVE_STREAM" };

export const regionMap: Record<string, string> = {
  us: "United States",
  eu: "Europe",
  in: "India",
};

export const statusTypeColors: Record<string, string> = {
  error: "$error",
  warning: "$warning",
  success: "$success",
};

export const recordingObjectReceivedWhenDisabled = {
  thumbnails: {
    width: 0,
    height: 0,
  },
};

export const paginationPages = { 5: 5, 10: 10, 20: 20, 50: 50, 100: 100 };
export const RECORDING_LOG_COMPONENT_ID = "recording-logs";
export const SESSION_DETAILS_TABS = {
  PARTICIPANTS: "participants",
  RECORDING_LOG: "recordingLog",
};

export const validationMessagesKeys = {
  validWorkspaceName: "validWorkspaceName",
  validOrganisationName: "validOrganisationName",
  validTemplateName: "validTemplateName",
  validSubdomain: "validSubdomain",
  validRoleName: "validRoleName",
  validRoomName: "validRoomName",
};
export const transcriptionModes = {
  CAPTION: "caption",
};
