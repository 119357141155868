import { isEmpty } from "lodash";
import moment from "moment";
import { echartsDataZoomConfig } from "src/components/Echarts/EchartsConfigs";
import { convertToBitratePerSecond } from "src/helpers/utils";
import {
  BitrateEntry,
  PacketLossEntry,
  peerAnalyticsSubscribeType,
  RTTEntry,
} from "src/types/analytics";
type echartsOptions = echarts.EChartsOption;

//bitrate config
export const getSubscribeBitrateChartConfiguration = (
  subscribeData: peerAnalyticsSubscribeType
) => {
  if (isEmpty(subscribeData)) {
    return {};
  }
  const arrObj = {
    type: "line",
    data: [],
    lineStyle: {
      type: "solid",
    },
    zlevel: 1,
    symbol: "circle",
    markLine: {
      zlevel: 0,
      label: {
        show: false,
      },
      silent: true,
      symbol: ["none", "none"],
      lineStyle: {
        color: "#2C333F",
        type: "solid",
      },
    },
  } as echartsOptions["series"];

  // eslint-disable-next-line complexity
  const getNetworkChartConfiguration = () => {
    if (isEmpty(subscribeData?.bitrate)) {
      return [];
    }
    const subscribeDegradation = [];
    if (subscribeData?.bitrate.length > 1) {
      let startTime = "";
      let endTime = "";
      for (let i = 0; i < subscribeData?.bitrate.length; i++) {
        if (
          (subscribeData?.bitrate[i]?.value?.[1][0] / 1024) * 0.9 >
          subscribeData?.bitrate[i]?.value?.[1][1] / 1024
        ) {
          if (!startTime) {
            //because we want to shade from area before
            startTime = subscribeData?.bitrate[i - 1]?.value?.[0]
              ? subscribeData?.bitrate[i - 1]?.value?.[0]
              : subscribeData?.bitrate[i]?.value?.[0];

            endTime = subscribeData?.bitrate[i + 1]?.value?.[0]
              ? subscribeData?.bitrate[i + 1]?.value?.[0]
              : subscribeData?.bitrate[i]?.value?.[0];
          } else {
            endTime = subscribeData?.bitrate[i + 1]?.value?.[0]
              ? subscribeData?.bitrate[i + 1]?.value?.[0]
              : subscribeData?.bitrate[i]?.value?.[0];
          }
        } else {
          if (startTime && endTime) {
            subscribeDegradation.push([
              {
                xAxis: startTime,
              },
              {
                xAxis: endTime,
              },
            ]);
            startTime = "";
            endTime = "";
          }
        }
      }
    }

    return [
      {
        ...arrObj,
        name: "Expected Bitrate",
        color: "#CB525E",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#CB525E",
        },
        markArea: {
          itemStyle: {
            color: "rgba(179, 71, 82, 0.1)",
          },
          data: [...subscribeDegradation],
        },
        data: subscribeData?.bitrate.map((item: BitrateEntry) => {
          return {
            value: [
              item?.value?.[0],
              Number(item?.value?.[1][0] / 1024).toFixed(2),
            ],
          };
        }),
      },
      {
        ...arrObj,
        name: "Actual Bitrate",
        color: "#913FFC",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#913FFC",
          type: "dashed",
        },

        data: subscribeData?.bitrate.map((item: BitrateEntry) => {
          return {
            value: [
              item?.value?.[0],
              Number(item?.value?.[1][1] / 1024).toFixed(2),
            ],
          };
        }),
      },
      {
        ...arrObj,
        name: "Available Bitrate",
        color: "#EDC602",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#EDC602",
          type: "dashed",
        },
        data: subscribeData?.bitrate.map((item: BitrateEntry) => {
          return {
            value: [item?.value?.[0], (item?.value?.[1][2] / 1024).toFixed(2)],
          };
        }),
      },
    ];
  };

  const networkSeries = getNetworkChartConfiguration();

  const data = [...networkSeries];

  let peerSubscribeBitrateChartoptions: echartsOptions;
  if (data.length === 0) {
    peerSubscribeBitrateChartoptions = {};
  } else {
    peerSubscribeBitrateChartoptions = {
      legend: {
        left: "4%",
        type: "plain",
        orient: "horizontal",
        show: true,
        icon: "path://M 0.0059092,12.043317 -4.5139121e-5,-0.00425602 12.004559,0.00271404 12.003432,11.995198 Z M 11.002035,0.95440869 1.0091504,0.96081353 1.1075267,10.984948 11.124326,11.022966 Z M 4.2153024,9.9284753 9.9624907,2.6594679 9.3041655,2.0786005 4.1297492,8.8221042 2.4751969,7.4195436 1.9393024,8.0391743",
        textStyle: { color: "#FFFFFF" },
        data: [
          { name: "Expected Bitrate", itemStyle: { color: "#CB525E" } },
          { name: "Actual Bitrate", itemStyle: { color: "#913FFC" } },
          { name: "Available Bitrate", itemStyle: { color: "#EDC602" } },
        ],
        selected: {
          "Available Bitrate": false,
        },
      },
      tooltip: {
        axisPointer: {
          animation: false,
        },
        trigger: "axis", // Trigger tooltip on item (icon) hover
        backgroundColor: "#1E232A",
        borderRadius: 4,
        padding: 4,
        borderColor: "#1E232A",
        textStyle: {
          color: "#F5F9FF",
        },
      },
      xAxis: {
        type: "time",
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      toolbox: {
        right: "5%",
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            icon: {
              zoom: `M 5.77783,7.44444 H 9.11116 M 7.44446,5.77777 V 9.1111 M 13,13 10.5834,10.5833 m -3.13896,1.3056 c 2.45459,0 4.44446,-1.98987 4.44446,-4.44446 C 11.8889,4.98984 9.89903,3 7.44444,3 4.98984,3 3,4.98984 3,7.44444 3,9.89903 4.98984,11.8889 7.44444,11.8889 Z`,
              back: `path://m 4,6.70833 h 5.6875 c 0.3912,0 0.7786,0.07706 1.1401,0.22678 0.3614,0.14972 0.6898,0.36916 0.9665,0.6458 0.2766,0.27664 0.4961,0.60506 0.6458,0.96651 0.1497,0.36145 0.2268,0.74885 0.2268,1.14008 0,0.3912 -0.0771,0.7786 -0.2268,1.1401 -0.1497,0.3614 -0.3692,0.6898 -0.6458,0.9665 -0.2767,0.2766 -0.6051,0.4961 -0.9665,0.6458 -0.3615,0.1497 -0.7489,0.2268 -1.1401,0.2268 H 7.79167 M 6.70833,9.41667 4,6.70833 6.70833,4`,
            },
          },
          restore: {
            icon: `M 5.77778,10.2222 H 3 V 13 M 13,8 c 0,1.32608 -0.5268,2.5979 -1.4645,3.5355 C 10.5979,12.4732 9.32608,13 8,13 6.6022,12.9947 5.26054,12.4493 4.25556,11.4778 L 3,10.2222 M 12.9999,3 V 5.77778 H 10.2222 M 3,8 C 3,6.67392 3.52678,5.40215 4.46447,4.46447 5.40215,3.52678 6.67392,3 8,3 9.3978,3.00526 10.7395,3.55068 11.7444,4.52222 L 13,5.77778`,
          },
          saveAsImage: {
            icon: `path://M 8.00012,9.77777 V 2.66666 M 5.03709,6.81481 8.00005,9.77777 10.963,6.81481 m 2.3703,2.96296 v 2.37033 c 0,0.3144 -0.1249,0.6158 -0.3471,0.8381 -0.2223,0.2223 -0.5238,0.3471 -0.8381,0.3471 H 3.85181 c -0.31433,0 -0.61579,-0.1248 -0.83805,-0.3471 C 2.79149,12.7639 2.66663,12.4625 2.66663,12.1481 V 9.77777`,
          },
        },
      },
      grid: {
        top: "20%",
        left: "8%",
        right: "5%",
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          filterMode: "none",
          end: 100,
          xAxisIndex: [0, 1],

          labelFormatter: (_: any, valueStr: string | number | Date) => {
            const val = moment(new Date(valueStr)).format("HH:mm:ss");
            return val;
          },
          textStyle: { width: 70, overflow: "break" },
          ...echartsDataZoomConfig,
        },
        {
          type: "inside",
          realtime: true,
          start: 0,
          end: 100,
          filterMode: "none",
          xAxisIndex: [0, 1],
        },
      ],
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (value: any) => {
            return convertToBitratePerSecond(value);
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      //@ts-ignore
      series: [...data],
    };
  }
  return peerSubscribeBitrateChartoptions;
};

//Packet Loss Config
export const getSubscribePacketLossChartConfiguration = (
  subscribeData: peerAnalyticsSubscribeType
) => {
  if (isEmpty(subscribeData)) {
    return {};
  }
  const arrObj = {
    type: "line",
    data: [],
    lineStyle: {
      type: "solid",
    },
    zlevel: 1,
    symbol: "circle",
    markLine: {
      label: {
        show: false,
        position: "start",
        formatter: `{c} %`,
        color: "#2C333F",
      },
      show: true,
      data: [{ yAxis: 5 }],

      symbol: ["none", "none"],
      lineStyle: {
        color: "#66A1FF",
        type: "dashed",
      },
    },
  } as echartsOptions["series"];

  const getNetworkChartConfiguration = () => {
    if (isEmpty(subscribeData?.packet_loss)) {
      return [];
    }
    return [
      {
        ...arrObj,
        color: "#CB525E",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#CB525E",
        },
        data: subscribeData?.packet_loss.map((item: PacketLossEntry) => {
          return {
            value: [item?.value?.[0], Number(item?.value?.[1]).toFixed(2)],
          };
        }),
      },
    ];
  };

  const networkSeries = getNetworkChartConfiguration();

  const data = [...networkSeries];
  let peerSubscribePacketLossChartoptions: echartsOptions;
  if (data.length === 0) {
    peerSubscribePacketLossChartoptions = {};
  } else {
    peerSubscribePacketLossChartoptions = {
      // legend: {
      //   left: "3%",
      //   z: 2,
      //   type: "plain",
      //   orient: "horizontal",
      //   show: true,
      //   icon: "circle",
      //   textStyle: { color: "#FFFFFF" },
      //   data: [
      //     { name: "Expected PacketLoss", itemStyle: { color: "#CB525E" } },
      //     { name: "Actual PacketLoss", itemStyle: { color: "#913FFC" } },
      //   ],
      // },
      tooltip: {
        axisPointer: {
          animation: false,
        },
        trigger: "axis", // Trigger tooltip on item (icon) hover
        backgroundColor: "#1E232A",
        borderRadius: 4,
        padding: 4,
        borderColor: "#1E232A",
        textStyle: {
          color: "#F5F9FF",
        },
      },
      xAxis: {
        type: "time",
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      toolbox: {
        right: "5%",
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            icon: {
              zoom: `M 5.77783,7.44444 H 9.11116 M 7.44446,5.77777 V 9.1111 M 13,13 10.5834,10.5833 m -3.13896,1.3056 c 2.45459,0 4.44446,-1.98987 4.44446,-4.44446 C 11.8889,4.98984 9.89903,3 7.44444,3 4.98984,3 3,4.98984 3,7.44444 3,9.89903 4.98984,11.8889 7.44444,11.8889 Z`,
              back: `path://m 4,6.70833 h 5.6875 c 0.3912,0 0.7786,0.07706 1.1401,0.22678 0.3614,0.14972 0.6898,0.36916 0.9665,0.6458 0.2766,0.27664 0.4961,0.60506 0.6458,0.96651 0.1497,0.36145 0.2268,0.74885 0.2268,1.14008 0,0.3912 -0.0771,0.7786 -0.2268,1.1401 -0.1497,0.3614 -0.3692,0.6898 -0.6458,0.9665 -0.2767,0.2766 -0.6051,0.4961 -0.9665,0.6458 -0.3615,0.1497 -0.7489,0.2268 -1.1401,0.2268 H 7.79167 M 6.70833,9.41667 4,6.70833 6.70833,4`,
            },
          },
          restore: {
            icon: `M 5.77778,10.2222 H 3 V 13 M 13,8 c 0,1.32608 -0.5268,2.5979 -1.4645,3.5355 C 10.5979,12.4732 9.32608,13 8,13 6.6022,12.9947 5.26054,12.4493 4.25556,11.4778 L 3,10.2222 M 12.9999,3 V 5.77778 H 10.2222 M 3,8 C 3,6.67392 3.52678,5.40215 4.46447,4.46447 5.40215,3.52678 6.67392,3 8,3 9.3978,3.00526 10.7395,3.55068 11.7444,4.52222 L 13,5.77778`,
          },
          saveAsImage: {
            icon: `path://M 8.00012,9.77777 V 2.66666 M 5.03709,6.81481 8.00005,9.77777 10.963,6.81481 m 2.3703,2.96296 v 2.37033 c 0,0.3144 -0.1249,0.6158 -0.3471,0.8381 -0.2223,0.2223 -0.5238,0.3471 -0.8381,0.3471 H 3.85181 c -0.31433,0 -0.61579,-0.1248 -0.83805,-0.3471 C 2.79149,12.7639 2.66663,12.4625 2.66663,12.1481 V 9.77777`,
          },
        },
      },
      grid: {
        top: "20%",
        left: "8%",
        right: "5%",
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          filterMode: "none",
          end: 100,
          xAxisIndex: [0, 1],

          labelFormatter: (_: any, valueStr: string | number | Date) => {
            const val = moment(new Date(valueStr)).format("HH:mm:ss");
            return val;
          },
          textStyle: { width: 70, overflow: "break" },
          ...echartsDataZoomConfig,
        },
        {
          type: "inside",
          realtime: true,
          start: 0,
          end: 100,
          filterMode: "none",
          xAxisIndex: [0, 1],
        },
      ],
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value} %",
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      //@ts-ignore
      series: [...data],
    };
  }
  return peerSubscribePacketLossChartoptions;
};

// RTT Config
export const getSubscribeRTTChartConfiguration = (
  subscribeData: peerAnalyticsSubscribeType
) => {
  if (isEmpty(subscribeData)) {
    return {};
  }
  const arrObj = {
    type: "line",
    data: [],
    lineStyle: {
      type: "solid",
    },
    zlevel: 1,
    symbol: "circle",
    markLine: {
      label: {
        show: false,
        position: "start",
        formatter: `{c} ms`,
        color: "#2C333F",
      },
      show: true,
      data: [{ yAxis: 300 }],

      symbol: ["none", "none"],
      lineStyle: {
        color: "#66A1FF",
        type: "dashed",
      },
    },
  } as echartsOptions["series"];

  const getRTTChartConfiguration = () => {
    if (isEmpty(subscribeData?.rtt)) {
      return [];
    }
    return [
      {
        ...arrObj,
        color: "#CB525E",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#CB525E",
        },
        data: subscribeData?.rtt.map((item: RTTEntry) => {
          return {
            value: [item?.value?.[0], Number(item?.value?.[1]).toFixed(2)],
          };
        }),
      },
    ];
  };

  const rttSeries = getRTTChartConfiguration();

  const data = [...rttSeries];
  let peerSubscribeRTTChartOptions: echartsOptions;
  if (data.length === 0) {
    peerSubscribeRTTChartOptions = {};
  } else {
    peerSubscribeRTTChartOptions = {
      // legend: {
      //   left: "3%",
      //   z: 2,
      //   type: "plain",
      //   orient: "horizontal",
      //   show: true,
      //   icon: "circle",
      //   textStyle: { color: "#FFFFFF" },
      //   data: [
      //     { name: "Expected RTT", itemStyle: { color: "#CB525E" } },
      //     { name: "Actual RTT", itemStyle: { color: "#913FFC" } },
      //   ],
      // },
      tooltip: {
        axisPointer: {
          animation: false,
        },
        trigger: "axis", // Trigger tooltip on item (icon) hover
        backgroundColor: "#1E232A",
        borderRadius: 4,
        padding: 4,
        borderColor: "#1E232A",
        textStyle: {
          color: "#F5F9FF",
        },
      },
      xAxis: {
        type: "time",
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      toolbox: {
        right: "5%",
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            icon: {
              zoom: `M 5.77783,7.44444 H 9.11116 M 7.44446,5.77777 V 9.1111 M 13,13 10.5834,10.5833 m -3.13896,1.3056 c 2.45459,0 4.44446,-1.98987 4.44446,-4.44446 C 11.8889,4.98984 9.89903,3 7.44444,3 4.98984,3 3,4.98984 3,7.44444 3,9.89903 4.98984,11.8889 7.44444,11.8889 Z`,
              back: `path://m 4,6.70833 h 5.6875 c 0.3912,0 0.7786,0.07706 1.1401,0.22678 0.3614,0.14972 0.6898,0.36916 0.9665,0.6458 0.2766,0.27664 0.4961,0.60506 0.6458,0.96651 0.1497,0.36145 0.2268,0.74885 0.2268,1.14008 0,0.3912 -0.0771,0.7786 -0.2268,1.1401 -0.1497,0.3614 -0.3692,0.6898 -0.6458,0.9665 -0.2767,0.2766 -0.6051,0.4961 -0.9665,0.6458 -0.3615,0.1497 -0.7489,0.2268 -1.1401,0.2268 H 7.79167 M 6.70833,9.41667 4,6.70833 6.70833,4`,
            },
          },
          restore: {
            icon: `M 5.77778,10.2222 H 3 V 13 M 13,8 c 0,1.32608 -0.5268,2.5979 -1.4645,3.5355 C 10.5979,12.4732 9.32608,13 8,13 6.6022,12.9947 5.26054,12.4493 4.25556,11.4778 L 3,10.2222 M 12.9999,3 V 5.77778 H 10.2222 M 3,8 C 3,6.67392 3.52678,5.40215 4.46447,4.46447 5.40215,3.52678 6.67392,3 8,3 9.3978,3.00526 10.7395,3.55068 11.7444,4.52222 L 13,5.77778`,
          },
          saveAsImage: {
            icon: `path://M 8.00012,9.77777 V 2.66666 M 5.03709,6.81481 8.00005,9.77777 10.963,6.81481 m 2.3703,2.96296 v 2.37033 c 0,0.3144 -0.1249,0.6158 -0.3471,0.8381 -0.2223,0.2223 -0.5238,0.3471 -0.8381,0.3471 H 3.85181 c -0.31433,0 -0.61579,-0.1248 -0.83805,-0.3471 C 2.79149,12.7639 2.66663,12.4625 2.66663,12.1481 V 9.77777`,
          },
        },
      },
      grid: {
        top: "20%",
        left: "8%",
        right: "5%",
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          filterMode: "none",
          end: 100,
          xAxisIndex: [0, 1],

          labelFormatter: (_: any, valueStr: string | number | Date) => {
            const val = moment(new Date(valueStr)).format("HH:mm:ss");
            return val;
          },
          textStyle: { width: 70, overflow: "break" },
          ...echartsDataZoomConfig,
        },
        {
          type: "inside",
          realtime: true,
          start: 0,
          end: 100,
          filterMode: "none",
          xAxisIndex: [0, 1],
        },
      ],
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value} ms",
        },
        axisPointer: {},
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      //@ts-ignore
      series: [...data],
    };
  }
  return peerSubscribeRTTChartOptions;
};
