import React from "react";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { InfoIcon } from "@100mslive/react-icons";
import { Flex, Text, Tooltip } from "@100mslive/react-ui";

type Props = {
  title: string;
  total: number;
  trend: number;
  showTotal?: boolean;
  tooltip?: string;
  totalLabel?: string;
  showTrend?: boolean;
  makeTotalOpaque?: boolean;
};

function TitleWithTrend({
  title,
  total,
  trend,
  tooltip,
  totalLabel,
  showTotal = true,
  showTrend = true,
  makeTotalOpaque = true,
}: Props) {
  const isNegative = trend < 0;
  function formatNumber(number: number) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(number);
  }

  return (
    <Flex direction="column">
      <Flex align="center">
        <Text
          variant="sub2"
          css={{
            fontWeight: "$semiBold",
            mr: "$4",
          }}
        >
          {title}
        </Text>
        {tooltip ? (
          <Tooltip
            title={tooltip}
            side="top"
            align="center"
            boxCss={{ w: "$80", zIndex: 10, r: "$1" }}
          >
            <Flex
              css={{
                cursor: "pointer",
                "&:hover": { c: "$textDisabled" },
              }}
            >
              <SmallIcon>
                <InfoIcon />
              </SmallIcon>
            </Flex>
          </Tooltip>
        ) : (
          <></>
        )}
      </Flex>
      {showTotal ? (
        <Flex
          align="center"
          css={{ mt: "$4", opacity: makeTotalOpaque ? 100 : 0 }}
        >
          <Text
            variant="h5"
            css={{
              fontWeight: "$semiBold",
            }}
          >
            {formatNumber(total)}
            {totalLabel ? (
              <Text
                as="span"
                variant="caption"
                css={{
                  ml: "$2",
                  c: "$textDisabled",
                  fontWeight: "$regular",
                }}
              >
                {totalLabel}
              </Text>
            ) : (
              <></>
            )}
          </Text>
          {showTrend ? (
            <Text
              variant="caption"
              css={{ ml: "$4", c: isNegative ? "$error" : "$success" }}
            >
              {!isNegative ? formatNumber(trend) : formatNumber(trend * -1)}{" "}
              &#37;
            </Text>
          ) : (
            <></>
          )}
        </Flex>
      ) : (
        <></>
      )}
    </Flex>
  );
}

export default TitleWithTrend;
