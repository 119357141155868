import ReactTooltip from "react-tooltip";

export const RolesTooltip = ({
  children,
  id,
  place,
  className,
  clickable = false,
  delayHide = 500,
}) => {
  return (
    <ReactTooltip
      id={id}
      type="light"
      effect="solid"
      place={place ? place : "right"}
      delayHide={delayHide}
      clickable={clickable}
      className={className ? className : "w-52"}
    >
      {children}
    </ReactTooltip>
  );
};
