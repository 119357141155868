import React, { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { DEVREV_CONSTANTS } from "src/constants";
import {
  setDevRevMessageCount,
  setDevRevReadyState,
  setDevRevToggleState,
} from "src/store/devRevSupport/actions";

function Plug({ currentUser }: { currentUser: any }) {
  const dispatch = useDispatch();

  const handleEventsToStore = useCallback(() => {
    window?.plugSDK?.onEvent((payload: { type: string; value: number }) => {
      if (payload.type === DEVREV_CONSTANTS.closed.devRevKey) {
        dispatch(setDevRevToggleState(true));
      }
      if (payload.type === DEVREV_CONSTANTS.opened.devRevKey) {
        dispatch(setDevRevToggleState(false));
      }
      if (payload.type === DEVREV_CONSTANTS.ready.devRevKey) {
        dispatch(setDevRevReadyState(false));
      }

      if (payload.type === DEVREV_CONSTANTS.messageCount.devRevKey) {
        dispatch(setDevRevMessageCount(payload.value));
      }
    });
  }, [dispatch]);

  // eslint-disable-next-line complexity
  const attachDevrevWidget = useCallback(() => {
    // run only when you session_token is present.

    if (
      window.plugSDK &&
      !isEmpty(currentUser) &&
      currentUser.session_token &&
      !window.plugSDK.isPlugInitialized
    ) {
      try {
        window.plugSDK.init({
          session_token: currentUser.session_token,
          app_id: process.env.REACT_APP_DEVREV_APP_ID,
          custom_launcher_selector: "#custom-plug",
          enable_default_launcher: false,
        });
      } catch (e) {
        console.error(e);
      }
    }

    if (
      window.plugSDK &&
      !isEmpty(currentUser) &&
      currentUser.session_token &&
      window.plugSDK.isPlugInitialized
    ) {
      try {
        handleEventsToStore();
      } catch (e) {
        console.error(e);
      }
    }
  }, [currentUser, handleEventsToStore]);

  const handleScriptInject = useCallback(
    ({ scriptTags }: { scriptTags: any }) => {
      if (scriptTags) {
        const [scriptTag = {}] = scriptTags;
        scriptTag.onload = attachDevrevWidget;
      }
    },
    [attachDevrevWidget]
  );

  if (isEmpty(currentUser)) {
    return null;
  }
  return (
    <Helmet
      script={[{ src: "https://plug-platform.devrev.ai/static/plug.js" }]}
      onChangeClientState={(_newState, addedTags) => {
        handleScriptInject(addedTags);
      }}
    />
  );
}

export default Plug;
