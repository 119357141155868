import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoResults from "src/components/Common/NoResults";
import RecordingLinkComponent from "src/modules/sessions/components/recording/RecordingLinkComponent";
import { recordingLog } from "src/modules/sessions/types";
import { RootState } from "src/store/reducers";
import {
  FETCH_SESSION_RECORDINGS_DETAILS_RESET,
  fetchPresignedUrl,
  SET_DOWNLOAD_ASSET_LABEL,
} from "src/store/sessions/actions";
import StatusTag from "../../components/Common/StatusTag";
import Table from "../../components/Common/TableNew";
import { formattedDate, getLocalZone } from "../../utils";

const zoneAbbr = getLocalZone();

const headers = ["Log Status", "Type", `Timestamp (${zoneAbbr})`, "ASSET URL"];

export default function RecordingLogs({ logs }: { logs: recordingLog[] }) {
  const dispatch = useDispatch();
  const presignedUrlData = useSelector(
    (state: RootState) => state.sessions.presignedUrlData
  );
  const downloadAssetLabel = useSelector(
    (state: RootState) => state.sessions.downloadAssetLabel
  );
  const fetchPresignedUrlStatus = useSelector(
    (state: RootState) => state.sessions.fetchPresignedUrlStatus
  );
  const setAssetLabel = (assetType: string) => {
    dispatch({ type: SET_DOWNLOAD_ASSET_LABEL, payload: assetType });
  };

  useEffect(
    () => () => {
      dispatch({ type: FETCH_SESSION_RECORDINGS_DETAILS_RESET });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // write a custom container for each tab later when we move this to modules folder
  const fetchPresignedUrlForAsset = ({
    roomId,
    assetId,
    data,
    download = false,
    setLoading = () => {},
  }: {
    setLoading: (value: boolean) => void;
    roomId: string;
    assetId: string;
    data: string;
    download?: boolean;
  }) => {
    dispatch(
      fetchPresignedUrl({
        roomId: roomId,
        assetId: assetId,
        data: data,
        download: download,
        setLoading: setLoading,
      })
    );
  };

  if (logs?.length) {
    return (
      <div className="table-responsive rounded-lg border-[1px] border-border-default">
        <Table
          tableHeaders={headers}
          compact
          noHoverEffect
          className="table table-borderless mb-0"
          hideBottomBorders={false}
          centerFinalColumn={false}
          highlightAll={false}
        >
          {logs.map((data, index) => (
            <tr key={index}>
              <td>
                <StatusTag status={data.status} />
              </td>
              <td>{data?.asset_types ? data?.asset_types.join(", ") : "-"}</td>
              <td>{formattedDate(data.updated_at)}</td>
              <td>
                {data?.recording_assets?.length ? (
                  <RecordingLinkComponent
                    data={data?.recording_path as string}
                    roomId={data?.room_id}
                    recordingAssets={data.recording_assets}
                    fetchPresignedUrl={fetchPresignedUrlForAsset}
                    fetchPresignedUrlStatus={fetchPresignedUrlStatus}
                    setAssetLabel={setAssetLabel}
                    presignedUrl={presignedUrlData?.url}
                    assetLabel={downloadAssetLabel}
                    shouldOpenAsset={data.recording_assets.some(
                      asset => asset.id === presignedUrlData.id
                    )}
                  />
                ) : (
                  "-"
                )}
              </td>
            </tr>
          ))}
        </Table>
      </div>
    );
  }
  return (
    <NoResults
      title="No recordings found"
      subtitle="Recent recordings might take some time to load"
    />
  );
}
