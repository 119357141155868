import React, { useMemo } from "react";
import * as echarts from "echarts";
import { default as EchartsWrapper } from "src/components/Echarts";
import { peerAnalyticsPublishType } from "src/types/analytics";
import { getPublisherJitterBufferChartConfiguration } from "../../helpers/getPublishConfigurations";
type echartsOptions = echarts.EChartsOption;

export const PublisherJitterBufferChart = ({
  publishData,
  loading,
}: {
  publishData: peerAnalyticsPublishType;
  loading: boolean;
}) => {
  const peerPublisherBitrateChartoptions: echartsOptions = useMemo(
    () => getPublisherJitterBufferChartConfiguration(publishData),
    [publishData]
  );
  return (
    <>
      {/* @ts-ignore */}
      <EchartsWrapper
        options={{ ...peerPublisherBitrateChartoptions }}
        loading={loading}
        style={{ height: "240px", width: "auto" }}
      />
    </>
  );
};
