import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import {
  SET_DEVREV_MESSAGE_COUNT,
  SET_DEVREV_READY_STATE,
  SET_DEVREV_TOGGLE_STATE,
} from "./actions";

const initialState = {
  toggleState: true,
  isLoading: true,
  messageCount: 0,
  hasMessage: false,
};

export const devRevSupportState = handleActions(
  {
    [SET_DEVREV_READY_STATE]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.isLoading = action.payload;
      return { ...newState };
    },
    [SET_DEVREV_MESSAGE_COUNT]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.messageCount = action.payload;
      newState.hasMessage = action.payload > 0;
      return { ...newState };
    },
    [SET_DEVREV_TOGGLE_STATE]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.toggleState = action.payload;
      return { ...newState };
    },
  },
  initialState
);
