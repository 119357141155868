import api from "src/api";

export async function getWorkspaceSessionInsights({
  start_date,
  end_date,
  filter,
}: {
  start_date: Date;
  end_date: Date;
  filter: string;
}) {
  return await api.service("dashboard").get("insights/sessions", {
    start_date: start_date,
    end_date: end_date,
    filter: filter,
  });
}

export async function getWorkspacePeerInsights({
  start_date,
  end_date,
  filter,
}: {
  start_date: Date;
  end_date: Date;
  filter: string;
}) {
  return await api.service("dashboard").get("insights/peers", {
    start_date: start_date,
    end_date: end_date,
    filter: filter,
  });
}

export async function getWorkspaceIssueInsights({
  start_date,
  end_date,
  filter,
}: {
  start_date: Date;
  end_date: Date;
  filter: string;
}) {
  return await api.service("dashboard").get("insights/issues", {
    start_date: start_date,
    end_date: end_date,
    filter: filter,
  });
}

export async function getWorkspaceRecordingInsights({
  start_date,
  end_date,
  filter,
}: {
  start_date: Date;
  end_date: Date;
  filter: string;
}) {
  return await api.service("dashboard").get("insights/recordings", {
    start_date: start_date,
    end_date: end_date,
    filter: filter,
  });
}
