import api from "src/api";

export const verifyWebhookURL = async ({
  url,
  headers,
}: {
  url: string;
  headers?: {};
}) => {
  return await api
    .service("dashboard")
    .post("workspaces/webhooks/verify", { webhook: url, headers });
};
