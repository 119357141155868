import React, { FC } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@100mslive/react-icons";
import { StyledPagination } from "@100mslive/react-ui";

type PaginationProps = {
  page: number;
  setPage: (pageNumber: number) => unknown;
  numPages: number;
};

export const Pagination: FC<PaginationProps> = ({
  page,
  setPage,
  numPages,
}) => {
  const disableLeft = page === 0;
  const disableRight = page === numPages - 1;
  const nextPage = () => {
    setPage(Math.min(page + 1, numPages - 1));
  };
  const prevPage = () => {
    setPage(Math.max(page - 1, 0));
  };
  const getWholeNumbers = (n: number) => {
    return [...Array(n).keys()];
  };

  const pageSlice = () => {
    if (disableLeft) {
      return [page, page + 3];
    }
    if (disableRight) {
      return [Math.max(0, page - 2)];
    }
    return [page - 1, page + 2];
  };
  return (
    <StyledPagination.Root css={{ justifyContent: "end" }}>
      <StyledPagination.Chevron
        disabled={disableLeft}
        onClick={prevPage}
        css={{ backgroundColor: "$backgroundDefault" }}
      >
        <ChevronLeftIcon
          width={16}
          height={16}
          style={{
            cursor: disableLeft ? "not-allowed" : "pointer",
            backgroundColor: "inherit",
          }}
        />
      </StyledPagination.Chevron>
      <StyledPagination.Dots>
        {getWholeNumbers(numPages)
          .slice(...pageSlice())
          .map(_ => (
            <StyledPagination.Dot
              key={_}
              css={page === _ ? { w: "16px" } : undefined}
              active={page === _}
              onClick={() => setPage(_)}
            />
          ))}
      </StyledPagination.Dots>
      <StyledPagination.Chevron
        disabled={disableRight}
        onClick={nextPage}
        css={{ backgroundColor: "$backgroundDefault" }}
      >
        <ChevronRightIcon
          width={16}
          height={16}
          style={{
            cursor: disableRight ? "not-allowed" : "pointer",
            backgroundColor: "inherit",
          }}
        />
      </StyledPagination.Chevron>
    </StyledPagination.Root>
  );
};
