import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { API_CALL_STATE } from "../../constants";
import {
  fetchWorkspaceDetails,
  updateDefaultTemplateForWorkspace,
} from "../../store/actions";
import { getCurrentWorkspaceID } from "../../utils";

/**
 *
 * @param {string} templateId
 * @param {string} currentWorkspaceID
 * @returns
 */
export function useDefaultTemplate(
  templateId = "",
  currentWorkspaceID = getCurrentWorkspaceID()
) {
  const dispatch = useDispatch();
  const currentWorkspaceData = useSelector(state => state.workspace?.current);
  const defaultTemplateId = useSelector(
    state => state.workspace?.current?.DefaultTemplateID
  );
  const fetchWorkspaceInfoStatus = useSelector(
    state => state.workspace?.status?.fetchWorkspaceInfo
  );
  const isDefaultTemplate = defaultTemplateId === templateId;

  useEffect(() => {
    // fetch workspace details if the workspace is empty and data fetch status is not done
    if (
      isEmpty(currentWorkspaceData) &&
      fetchWorkspaceInfoStatus !== API_CALL_STATE.DONE
    ) {
      dispatch(fetchWorkspaceDetails());
    }
    // fetch workspace details if the current workspace id doesnt match (shouldnt be the case ideally)
    if (
      currentWorkspaceData.id !== currentWorkspaceID &&
      fetchWorkspaceInfoStatus === API_CALL_STATE.DONE
    ) {
      dispatch(fetchWorkspaceDetails());
    }
  }, [
    currentWorkspaceData,
    currentWorkspaceID,
    dispatch,
    fetchWorkspaceInfoStatus,
  ]);

  const updateDefaultTemplate = useCallback(() => {
    if (isDefaultTemplate) {
      return;
    }
    dispatch(updateDefaultTemplateForWorkspace(templateId));
  }, [dispatch, templateId, isDefaultTemplate]);

  return [isDefaultTemplate, updateDefaultTemplate];
}
