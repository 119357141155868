// availity-reactstrap-validation
import React, { Component } from "react";
// ReCAPTCHA
import ReCAPTCHA from "react-google-recaptcha";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Alert, Spinner } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import AuthForm from "../../../components/AuthForm";
import GithubLoginButton from "../../../components/GithubLoginButton";
import GoogleLoginButton from "../../../components/GoogleLoginButton";
import { postLogin } from "../../../helpers/backend_helper";
import { createGetURL } from "../../../helpers/utils";
// actions
import { apiError, loginUser } from "../../../store/actions";

class VercelLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCaptcha: false,
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    // Ref for ReCAPTCHA object
    this.recaptchaRef = React.createRef();
  }

  captchaChangeHandler(captchaToken) {
    this.setState({
      captchaToken,
    });
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    if (this.state.captchaToken) {
      this.props.apiError("");
      console.debug("Captcha Token generated.");
      values["captcha-token"] = this.state.captchaToken;
      postLogin("login", values, this.props, window.location.search);
      this.setState({ ...this.state, captchaToken: null });
    } else {
      console.debug("Captcha Token not generated.");
      this.props.apiError("Please solve the CAPTCHA.");
    }
  }

  componentDidMount() {
    window.utm_params = { ...window.utm_params, ref: "vercel" };
  }

  componentWillUnmount() {
    this.props.apiError("");
  }

  render() {
    if (this.props.loading) {
      return (
        <div className="flex justify-center mt-16">
          <Spinner color="primary" />
        </div>
      );
    }
    return (
      <div>
        <p className="text-center font-extralight font-inter text-[#999A99] text-xl mb-10">
          Add live audio-video to your vercel
          <br />
          project in 5 minutes
        </p>
        <div className="form-group h-16">
          <GoogleLoginButton buttonText="Sign in with Google" />
        </div>
        <div className="form-group h-16">
          <GithubLoginButton buttonText="Sign in with Github" />
        </div>
        <div className="separator font-extralight font-inter">OR</div>
        <p className="text-center font-extralight font-inter text-[#999A99] text-base my-3">
          Login with email
        </p>
        <AuthForm
          footer={
            <p>
              Don't have an account ?{" "}
              <Link
                to={{
                  pathname: `/register`,
                  search: `?redirect_url=${encodeURIComponent(
                    createGetURL(window.location.href, {
                      stepsShown: true,
                    })
                  )}`,
                }}
                className="font-weight-medium text-primary"
              >
                Register now
              </Link>
            </p>
          }
          vercelEmailClassName=""
          routesClassName="hidden"
        >
          <AvForm
            className="form-horizontal"
            onValidSubmit={this.handleValidSubmit}
          >
            {this.props.error ? (
              <Alert color="danger">{this.props.error}</Alert>
            ) : null}
            <div className="form-group h-14 mt-0">
              <AvField
                name="email"
                value=""
                onFocus={() =>
                  this.setState({ ...this.state, showCaptcha: true })
                }
                className="form-control bg-gray-cool2 focus:ring-2 ring-blue-400 border-none"
                placeholder="Enter email"
                type="email"
                required
              />
            </div>
            <div className="form-group h-14">
              <AvField
                name="password"
                value=""
                type="password"
                required
                placeholder="Enter password"
                className="bg-gray-cool2 focus:ring-2 ring-blue-400 border-none"
              />
            </div>
            {this.state.showCaptcha ? (
              <div className="flex justify-center my-2">
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  size="normal"
                  onChange={this.captchaChangeHandler.bind(this)}
                />
              </div>
            ) : null}
            <div className="mt-4 h-14">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
              >
                Log In
              </button>
            </div>
            <div className="mb-3 text-center">
              <Link
                to={{
                  pathname: "/forgot-password",
                  search: `?redirect_url=${encodeURIComponent(
                    createGetURL(window.location.href, {
                      stepsShown: true,
                    })
                  )}`,
                }}
                className="text-muted"
              >
                <i className="mdi mdi-lock mr-1"></i> Forgot your password?
              </Link>
            </div>
          </AvForm>
        </AuthForm>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(VercelLogin)
);
