import * as amplitude from "@amplitude/analytics-browser";
import { isEmpty } from "lodash";
import { hubspotAnalytics } from "src/modules/hubspot/helper/analytics";
import {
  currentUser,
  getCurrentWorkspaceID,
  getUtmParams,
  isAdmin,
} from "../utils";

export const analytics_store = {
  data: { workspaceOwnerEmail: null },
  set: payload => {
    for (const index in payload) {
      analytics_store.data[index] = payload[index];
    }
  },
  get: () => analytics_store?.data,
};

const getCommonOptions = () => ({
  dashboard_version: process.env.REACT_APP_DASHBOARD_VERSION,
  events_protocol: process.env.REACT_APP_EVENTS_PROTOCOL,
  timestamp: new Date().toString(),
  platform: "hms-dashboard",
  ...getUtmParams(),
});

const analyticsTrack = (title, options) => {
  if (!amplitude) {
    return;
  }
  try {
    const user = currentUser();
    const workspaceID = getCurrentWorkspaceID();
    if (isEmpty(user)) {
      amplitude.track({
        event_type: title,
        event_properties: {
          ...getCommonOptions(),
          ...options,
        },
      });
    } else if (!isEmpty(user) && !isAdmin()) {
      amplitude.track({
        event_type: title,
        event_properties: {
          email: user.email,
          customer_id: user.customer_id,
          workspaceID,
          workspaceOwnerEmail: analytics_store.get()?.workspaceOwnerEmail,
          api_version: user.api_version,
          ...getCommonOptions(),
          ...options,
        },
      });
    }
  } catch (e) {
    console.error(e);
  }
};

const analyticsPage = (title, options) => {
  const user = currentUser();
  if (isEmpty(user)) {
    try {
      hubspotAnalytics.pageView({
        properties: {
          ...getCommonOptions(),
          ...options,
        },
      });
    } catch (e) {
      console.error(e);
    }
  } else if (user && !isAdmin()) {
    try {
      hubspotAnalytics.pageView({
        properties: {
          email: user.email,
          refId: user.customer_id,
          ...getCommonOptions(),
          ...options,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }
};

const amplitudeIdentify = (userId, properties = {}) => {
  amplitude.setUserId(userId);
  const identifyEvent = new amplitude.Identify();
  if (!isEmpty(properties)) {
    for (let key in properties) {
      if (
        Object.prototype.hasOwnProperty.call(properties, key) &&
        properties?.[key] !== null &&
        properties?.[key] !== undefined &&
        key !== "user_id"
      ) {
        identifyEvent.set(key, properties?.[key]);
      }
    }
  }
  amplitude.identify(identifyEvent);
};

const analyticsIdentify = (id, options) => {
  const user = currentUser();
  if (!isAdmin()) {
    const finalOptions = {
      ...getCommonOptions(),
      ...options,
    };
    try {
      hubspotAnalytics.identify({
        properties: { ...finalOptions, refId: id, email: user.email, ...user },
      });
    } catch (e) {
      console.error(e);
    }
    try {
      amplitudeIdentify(id, finalOptions);
    } catch (e) {
      console.error(e);
    }
  }
};

export const AppAnalytics = {
  identify: analyticsIdentify,
  track: analyticsTrack,
  page: analyticsPage,
  reset: () => {
    if (!amplitude) {
      return;
    }
    amplitude.reset();
  },
};

export const analyticsCoachmarksDone = () => {
  AppAnalytics.track("coachmark.done.clicked", {
    page: window.location.pathname,
  });
};
