import axios, { AxiosError } from "axios";
import { Dispatch } from "redux";
import toastr from "src/components/Common/toastr";
import { API_CALL_STATE } from "src/constants";
import {
  getWorkspaceIssueInsights,
  getWorkspacePeerInsights,
  getWorkspaceRecordingInsights,
  getWorkspaceSessionInsights,
} from "src/services/insights";
import { DashboardBackendAxiosError } from "src/types/customTypes";
import { RootState } from "../reducers";

export const FETCH_WORKSPACE_SESSION_INSIGHTS_INIT =
  "FETCH_WORKSPACE_SESSION_INSIGHTS_INIT";
export const FETCH_WORKSPACE_SESSION_INSIGHTS_FAIL =
  "FETCH_WORKSPACE_SESSION_INSIGHTS_FAIL";
export const FETCH_WORKSPACE_SESSION_INSIGHTS_DONE =
  "FETCH_WORKSPACE_SESSION_INSIGHTS_DONE";

export const FETCH_WORKSPACE_ISSUES_INSIGHTS_INIT =
  "FETCH_WORKSPACE_ISSUES_INSIGHTS_INIT";
export const FETCH_WORKSPACE_ISSUES_INSIGHTS_FAIL =
  "FETCH_WORKSPACE_ISSUES_INSIGHTS_FAIL";
export const FETCH_WORKSPACE_ISSUES_INSIGHTS_DONE =
  "FETCH_WORKSPACE_ISSUES_INSIGHTS_DONE";

export const FETCH_WORKSPACE_PEER_INSIGHTS_INIT =
  "FETCH_WORKSPACE_PEER_INSIGHTS_INIT";
export const FETCH_WORKSPACE_PEER_INSIGHTS_FAIL =
  "FETCH_WORKSPACE_PEER_INSIGHTS_FAIL";
export const FETCH_WORKSPACE_PEER_INSIGHTS_DONE =
  "FETCH_WORKSPACE_PEER_INSIGHTS_DONE";

export const FETCH_WORKSPACE_RECORDING_INSIGHTS_INIT =
  "FETCH_WORKSPACE_RECORDING_INSIGHTS_INIT";
export const FETCH_WORKSPACE_RECORDING_INSIGHTS_FAIL =
  "FETCH_WORKSPACE_RECORDING_INSIGHTS_FAIL";
export const FETCH_WORKSPACE_RECORDING_INSIGHTS_DONE =
  "FETCH_WORKSPACE_RECORDING_INSIGHTS_DONE";

export const SET_START_DATE_FOR_INSIGHTS = "SET_START_DATE_FOR_INSIGHTS";
export const SET_END_DATE_FOR_INSIGHTS = "SET_END_DATE_FOR_INSIGHTS";
export const SET_FILTER_FOR_INSIGHTS = "SET_FILTER_FOR_INSIGHTS";

export function setStartDateForInsights(start_date: Date) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_START_DATE_FOR_INSIGHTS,
      payload: start_date,
    });
  };
}

export function setEndDateForInsights(end_date: Date) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_END_DATE_FOR_INSIGHTS,
      payload: end_date,
    });
  };
}

export function setFilterForInsights(filter: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_FILTER_FOR_INSIGHTS,
      payload: filter,
    });
  };
}

export function fetchWorkspaceSessionDetails() {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const fetchWorkspaceSessionInsightsStatus =
      getState().insights.fetchWorkspaceSessionInsightsStatus;
    const start_date = getState().insights.startDate;
    const end_date = getState().insights.endDate;
    const filter = getState().insights.filter;
    if (fetchWorkspaceSessionInsightsStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }

    dispatch({ type: FETCH_WORKSPACE_SESSION_INSIGHTS_INIT });
    try {
      await getWorkspaceSessionInsights({
        start_date: start_date as Date,
        end_date: end_date as Date,
        filter: filter,
      }).then(response => {
        dispatch({
          type: FETCH_WORKSPACE_SESSION_INSIGHTS_DONE,
          payload: { trend: response.data.trend, ...response.data.data },
        });
      });
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.message;
      } else {
        message = e.message;
      }
      toastr.error(message);
      dispatch({ type: FETCH_WORKSPACE_SESSION_INSIGHTS_FAIL });
    }
  };
}

export function fetchWorkspacePeerDetails() {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const fetchWorkspacePeerInsightsStatus =
      getState().insights.fetchWorkspacePeerInsightsStatus;
    const start_date = getState().insights.startDate;
    const end_date = getState().insights.endDate;
    const filter = getState().insights.filter;
    if (fetchWorkspacePeerInsightsStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }

    dispatch({ type: FETCH_WORKSPACE_PEER_INSIGHTS_INIT });
    try {
      await getWorkspacePeerInsights({
        start_date: start_date as Date,
        end_date: end_date as Date,
        filter: filter,
      }).then(response => {
        dispatch({
          type: FETCH_WORKSPACE_PEER_INSIGHTS_DONE,
          payload: { data: response.data.data, trend: response.data.trend },
        });
      });
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.message;
      } else {
        message = e.message;
      }
      toastr.error(message);
      dispatch({ type: FETCH_WORKSPACE_PEER_INSIGHTS_FAIL });
    }
  };
}

export function fetchWorkspaceIssueDetails() {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const fetchWorkspaceIssuesInsightsStatus =
      getState().insights.fetchWorkspaceIssuesInsightsStatus;
    const start_date = getState().insights.startDate;
    const end_date = getState().insights.endDate;
    const filter = getState().insights.filter;
    if (fetchWorkspaceIssuesInsightsStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }

    dispatch({ type: FETCH_WORKSPACE_ISSUES_INSIGHTS_INIT });
    try {
      await getWorkspaceIssueInsights({
        start_date: start_date as Date,
        end_date: end_date as Date,
        filter: filter,
      }).then(response => {
        dispatch({
          type: FETCH_WORKSPACE_ISSUES_INSIGHTS_DONE,
          payload: { data: response.data.data, trend: response.data.trend },
        });
      });
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.message;
      } else {
        message = e.message;
      }
      toastr.error(message);
      dispatch({ type: FETCH_WORKSPACE_ISSUES_INSIGHTS_FAIL });
    }
  };
}

export function fetchWorkspaceRecordingsDetails() {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const fetchWorkspaceRecordingsInsightsStatus =
      getState().insights.fetchWorkspaceRecordingsInsightsStatus;
    const start_date = getState().insights.startDate;
    const end_date = getState().insights.endDate;
    const filter = getState().insights.filter;
    if (fetchWorkspaceRecordingsInsightsStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }

    dispatch({ type: FETCH_WORKSPACE_RECORDING_INSIGHTS_INIT });
    try {
      await getWorkspaceRecordingInsights({
        start_date: start_date as Date,
        end_date: end_date as Date,
        filter: filter,
      }).then(response => {
        dispatch({
          type: FETCH_WORKSPACE_RECORDING_INSIGHTS_DONE,
          payload: {
            tat: response.data.data.tat_breakdown,
            recordings: response.data.data.recordings,
            trend: response.data.trend,
          },
        });
      });
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.message;
      } else {
        message = e.message;
      }
      toastr.error(message);
      dispatch({ type: FETCH_WORKSPACE_RECORDING_INSIGHTS_FAIL });
    }
  };
}
