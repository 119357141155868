import React, { ComponentProps, FC } from "react";
import { textVariants } from "src/types/common";
import { InfoIcon } from "@100mslive/react-icons";
import { CSS, Flex, Text, Tooltip } from "@100mslive/react-ui";

const SettingsTitle: FC<
  ComponentProps<typeof Flex & typeof Tooltip> & {
    text: string;
    hideTooltip?: boolean;
    textCSS?: CSS;
    titleVariant?: textVariants;
    css?: CSS;
  }
> = ({
  title = "",
  text,
  titleVariant = "caption",
  disabled = false,
  hideTooltip = false,
  side = "top",
  align = "center",
  textCSS,
  css,
  ...props
}) => {
  return (
    <Flex align="center" css={{ ...css }} {...props}>
      <Text
        variant={titleVariant}
        css={{ c: "$textMedEmp", fontWeight: "$semiBold", ...textCSS }}
      >
        {text}
      </Text>
      {hideTooltip ? null : (
        <Tooltip
          title={<Flex css={{ maxWidth: "$60" }}>{title}</Flex>}
          disabled={disabled}
          align={align}
          side={side}
        >
          <Flex css={{ c: "$textAccentDisabled", ml: "$2" }} align="center">
            <InfoIcon width="16" height="16" />
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
};

export default SettingsTitle;
