import { useState } from "react";
import { isFunction } from "lodash";
import { HorizontalMenuIcon } from "@100mslive/react-icons";
import { Dropdown } from "@100mslive/react-ui";

const Options = ({
  content = [], // {data: , func}
  css = {
    w: "100%",
    textAlign: "center",
    p: 0,
  },
  Icon = <HorizontalMenuIcon />,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Dropdown.Root
      open={open}
      onOpenChange={() => {
        setOpen(!open);
      }}
    >
      <Dropdown.Trigger
        asChild
        css={{
          '&[data-state="open"]': {
            backgroundColor: "$transparent",
            color: "$textDisabled",
          },
          w: "$10",
        }}
      >
        <div>{Icon}</div>
      </Dropdown.Trigger>
      <Dropdown.Content
        side="bottom"
        align="end"
        sideOffset={10}
        css={{ ...css }}
      >
        {content.map((item, id) => (
          <Dropdown.Item
            key={`dropdown.item-${id}`}
            onClick={() => (isFunction(item.func) ? item.func() : null)}
            css={{ p: "0" }}
          >
            {item?.data}
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

export default Options;
