import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import classNames from "classnames";
import { Steps } from "intro.js-react";
import { isEmpty, isEqual } from "lodash";
import FilterSearch from "src/components/Common/FilterSearch";
import NoResults from "src/components/Common/NoResults";
import toastr from "src/components/Common/toastr";
import { API_CALL_STATE, ROLE_TYPES } from "src/constants";
import { resetCreateTemplate } from "src/store/createTemplate/actions";
import { AddCircleIcon } from "@100mslive/react-icons";
import { Button, Flex, Text, Tooltip } from "@100mslive/react-ui";
import RoomsTableContainer from "./TableContainer";
import ZeroState from "./ZeroState";
import { fetchStrapiStarterKits } from "../../actions/CreateAppActions";
import { fetchTemplatesData } from "../../actions/RolesActions";
import api from "../../api";
import LoadingSpinner from "../../components/LoadingSpinner";
import CreateRoomModal from "../../components/Modal/CreateRoomModal";
import {
  analyticsCoachmarksDone,
  AppAnalytics,
} from "../../helpers/analytics_helper";
import storeCoachmarks from "../../helpers/coachmarks";
import jwt_verify from "../../helpers/token_expiry";
import { fetchWorkspaceDetails } from "../../store/workspace/actions";
import {
  currentUser,
  getAPIURL,
  getEmailfromLocal,
  getLocalZone,
  isValidObjectID,
  uniqueObjects,
} from "../../utils";
import JoinRoomModal from "../Dashboard/JoinRoomModal";
import "./dashboard.scss";

const roomsFilters = [
  {
    label: "Template ID",
    _key: "template_id",
  },
  {
    label: "Room Name",
    _key: "room_name",
  },
  {
    label: "Status",
    _key: "active",
    type: "dropdown",
    options: { Enabled: "true", Disabled: "false" },
  },
];

class Rooms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      last: "",
      loading: false,
      paginationEnd: false,
      modalOpen: false,
      roomName: "",
      roomDescription: "",
      stepsEnabled: false,
      joinRoomToggle: false,
      createRoomToggle: false,
      isReadOnlyWorkspaceUser: true,
      filter: {
        template_id: "",
        active: "",
        room_id: "",
        room_name: "",
      },
    };
    this.coachMarkDetails = {
      initialStep: 0,
      steps: [
        {
          element: ".coachmark-room-links",
          intro: "<div class='text-lg'>Click here to see room details</div>",
          highlightClass: "bg-transparent-standard",
        },
        {
          element: ".coachmark-create-room",
          intro: "<div class='text-lg'>Create room here</div>",
          highlightClass: "bg-transparent-standard",
        },
      ],
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleJoinRoomDialog = this.toggleJoinRoomDialog.bind(this);
    this.toggleCreateRoomModal = this.toggleCreateRoomModal.bind(this);
    this.toggleRoomStatus = this.toggleRoomStatus.bind(this);
    this.tableContainerRef = React.createRef();
    this.loadingRef = React.createRef();
    this.openCreateNew = false;
  }

  toggleJoinRoomDialog() {
    if (this.state.joinRoomToggle) {
      this.props.resetCreateTemplate();
      this.getData("", {});
    }
    this.setState(prevState => ({
      ...prevState,
      joinRoomToggle: !prevState.joinRoomToggle,
    }));
  }

  componentDidMount() {
    jwt_verify(this.props.history);

    this.props.fetchStrapiStarterKits();
    this.props.fetchTemplatesData();
    this.props.fetchWorkspaceDetails();
    const params = new URLSearchParams(window.location.search);
    const queryId = params.get("template_id");
    if (queryId) {
      this.setState(
        prevState => ({
          ...prevState,
          filter: {
            template_id: queryId,
            active: "",
            room_id: "",
          },
        }),
        () => this.getData("", this.state.filter)
      );
    } else {
      this.getData("", {});
    }
    this.observer = new IntersectionObserver(
      entities => {
        if (entities[0].isIntersecting) {
          this.getData(this.state.last, this.state.filter);
        }
      },
      {
        root: this.tableContainerRef.current,
        threshold: [0.5, 0.75, 1],
      }
    );
    if (this.loadingRef.current) {
      this.observer.observe(this.loadingRef.current);
    }
  }

  // eslint-disable-next-line complexity
  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(prevState.filter, this.state.filter) &&
      !Object.values(this.state.filter).join("")
    ) {
      this.getData("", {});
    }
    if (
      prevProps.addNewAppStatus === API_CALL_STATE.IN_PROGRESS &&
      this.props.addNewAppStatus === API_CALL_STATE.DONE
    ) {
      this.toggleJoinRoomDialog();
    }
    if (
      (prevProps.createRoomStatus === API_CALL_STATE.IN_PROGRESS &&
        this.props.createRoomStatus === API_CALL_STATE.DONE) ||
      (prevProps.addNewAppStatus === API_CALL_STATE.IN_PROGRESS &&
        this.props.addNewAppStatus === API_CALL_STATE.DONE)
    ) {
      this.setState(
        {
          filter: { template_id: "", active: "", room_id: "" },
          last: "",
          loading: false,
          paginationEnd: false,
          data: [],
        },
        () => {
          this.getData("", {});
        }
      );
    }
    if (
      prevProps.fetchWorkspaceInfoStatus === API_CALL_STATE.IN_PROGRESS &&
      this.props.fetchWorkspaceInfoStatus === API_CALL_STATE.DONE
    ) {
      let isReadOnlyUser = true;
      const currentMembers = this.props.workspace?.current?.members || [];
      const currentUserEmail = getEmailfromLocal();
      for (const member of currentMembers) {
        if (
          currentUserEmail === member?.email &&
          member?.role === ROLE_TYPES.READ_ONLY
        ) {
          isReadOnlyUser = true;
          break;
        } else {
          isReadOnlyUser = false;
        }
      }
      this.setState(preState => ({
        ...preState,
        isReadOnlyWorkspaceUser: isReadOnlyUser,
      }));
    }
  }

  checkCoachmarks = async () => {
    const user_obj = currentUser();
    if (!user_obj) {
      this.props.history.push("/login");
    }
    let stepsEnabled;
    await api
      .service("dashboard")
      .get("coachmarks")
      .then(res => {
        try {
          const coachmarks = res.data.coachmarks;
          if (coachmarks.includes(window.location.pathname)) {
            stepsEnabled = false;
          } else {
            stepsEnabled = true;
          }
        } catch {
          stepsEnabled = true;
        }
      })
      .catch(err => {
        console.error(err);
        stepsEnabled = false;
      });
    this.setState({ stepsEnabled }, () => {
      if (stepsEnabled) {
        storeCoachmarks(window.location.pathname);
      }
    });
  };

  getData(last, filter = {}) {
    if (this.state.loading || this.state.paginationEnd) {
      return;
    }
    this.setState({ loading: true });

    const user_obj = currentUser();
    if (!user_obj) {
      this.props.history.push("/login");
    }

    let params = { limit: 20 };

    if (!isEmpty(filter)) {
      for (const [key, value] of Object.entries(filter)) {
        if (key.includes("_id") && !isValidObjectID(value)) {
          this.setState({ loading: false });
          return;
        }
        if (value) {
          params = { ...params, [key]: value };
        }
      }
    }

    if (last) {
      params["start"] = last;
    }

    api
      .service("dashboard")
      .get(getAPIURL("rooms", params))
      .then(response => {
        const res = response.data;
        if (!isEmpty(res.data)) {
          this.setState({
            ...this.state,
            last: res.last,
            data: uniqueObjects([...this.state.data, ...res.data], "id"),
            loading: false,
          });
        } else {
          this.setState({ ...this.state, loading: false, paginationEnd: true });
        }
      })
      .catch(err => {
        this.setState({
          ...this.state,
          data: [],
          loading: false,
        });
        console.error(err);
      });
  }

  handleSearch = val => {
    this.setState({ data: [], last: "", paginationEnd: false }, () => {
      if (isValidObjectID(val)) {
        this.getData("", this.state.filter);
      }
    });
  };

  checkTemplateExistsForRoom = roomTemplateId => {
    if (!roomTemplateId) {
      return true;
    }
    return this.props?.templates?.find(item => item?.id === roomTemplateId);
  };

  toggleRoomStatus(room_id) {
    const selectedRoomIndex = this.state.data.findIndex(data => {
      return data.id === room_id;
    });
    const params = {
      id: room_id,
      active: !this.state.data[selectedRoomIndex]["active"],
    };

    api
      .service("dashboard")
      .post("update-room", params)
      .then(() => {
        let roomData = [...this.state.data];
        roomData[selectedRoomIndex]["active"] =
          !roomData[selectedRoomIndex]["active"];

        this.setState({ data: roomData });
        AppAnalytics.track("btn.clicked", {
          btnId: "toggle.room.status",
          componentId: "rooms.overview.page",
          value: roomData[selectedRoomIndex]["active"],
        });
      })
      .catch(err => {
        toastr.error(err.message);
        console.error(err);
      });
  }

  toggleModal() {
    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  toggleCreateRoomModal = () => {
    this.setState({
      createRoomToggle: !this.state.createRoomToggle,
    });
  };
  toggleCreateNewDialog = () => {
    this.setState({
      openCreateNew: !this.state.openCreateNew,
    });
  };

  createRoomButton = showCreateRoom => {
    const button = (
      <Button
        disabled={
          !this.props?.templates?.length || this.state.isReadOnlyWorkspaceUser
        }
        className="coachmark-create-room inline-block ml-auto"
        onClick={() => {
          AppAnalytics.track("create.room.clicked", {
            page: "/rooms",
          });
          this.toggleCreateRoomModal();
        }}
        css={{ borderRadius: "$0", fontSize: "$sm" }}
      >
        <AddCircleIcon width={24} height={24} style={{ marginRight: "8px" }} />
        Create Room
      </Button>
    );
    return (
      showCreateRoom &&
      (this.props?.templates?.length ? (
        button
      ) : (
        <Tooltip
          title="Template is required for room creation"
          side="bottom"
          align="center"
        >
          {button}
        </Tooltip>
      ))
    );
  };
  //eslint-disable-next-line complexity
  render() {
    jwt_verify(this.props.history);
    const { stepsEnabled } = this.state;
    const { steps, initialStep } = this.coachMarkDetails;
    const isSearch = Boolean(Object.values(this.state.filter).join(""));
    const showCreateRoom = isSearch || !!this.state.data.length;
    const zoneAbbr = getLocalZone();
    const tableHeaders = [
      "Name",
      "Room ID",
      "Template ID",
      `Created On (${zoneAbbr})`,
      "Status",
    ];

    const validTemplateId = this.state.filter?.template_id
      ? isValidObjectID(this.state.filter?.template_id)
      : false;

    const validStatus = this.state.filter?.active || false;

    const validFilter = validTemplateId && validStatus;

    return (
      <React.Fragment>
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={() => {
            this.setState({ stepsEnabled: false });
          }}
          onComplete={analyticsCoachmarksDone}
        />
        <div className="page-content">
          <Container fluid>
            <div className="d-flex flex-col justify-content-start align-items-start">
              <Flex direction="column" css={{ mb: "$12" }}>
                <Text variant="h5" css={{ color: "$textHighEmp" }}>
                  Your Rooms
                </Text>
                <Flex
                  align="center"
                  css={{ color: "$primaryLight", fontSize: "$xs", mt: "$4" }}
                >
                  <Text variant="caption" css={{ c: "$textMedEmp" }}>
                    Rooms help you interact with others in audio or video call.
                    Each room is associated with a room template.
                  </Text>
                  <a
                    href={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/api-reference/Rooms/overview`}
                    style={{ color: "inherit" }}
                  >
                    &nbsp;Learn more.
                  </a>
                </Flex>
              </Flex>

              <Flex
                css={{ w: "100%", gap: "$space$8", mb: "$12" }}
                justify="between"
                align="start"
              >
                {this.state.data.length === 0 &&
                !isSearch &&
                !this.state.loading ? null : (
                  <div
                    style={{
                      width: "min(100%, 600px)",
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                    }}
                  >
                    <FilterSearch
                      placeholder="Search with Room ID"
                      filters={roomsFilters}
                      resetDisabled={!validFilter}
                      invalidInputErrorString="Please check the room ID entered"
                      componentId="rooms.overview.page"
                      alignValue="start"
                      applyDisabled={!validFilter}
                      inputValue={this.state.filter.room_id}
                      filterValues={this.state.filter}
                      error={
                        !this.state.loading &&
                        this.state.data.length === 0 &&
                        Object.values(this.state.filter).join("") !== ""
                      }
                      onFilterChange={({ key, value }) =>
                        this.setState({
                          filter: { ...this.state.filter, [key]: value },
                        })
                      }
                      onFilterRemove={key =>
                        this.setState({
                          filter: { ...this.state.filter, [key]: "" },
                        })
                      }
                      onInputChange={val =>
                        this.setState(
                          {
                            data: [],
                            paginationEnd: false,
                            last: "",
                            filter: {
                              ...this.state.filter,
                              room_id: val,
                            },
                          },
                          () => {
                            if (val && isValidObjectID(val)) {
                              this.getData("", this.state.filter);
                            }

                            if (!val) {
                              this.getData("", this.state.filter);
                            }
                          }
                        )
                      }
                      applyFunction={() => {
                        this.setState(
                          {
                            data: [],
                            paginationEnd: false,
                            last: "",
                          },
                          () => this.getData("", this.state.filter)
                        );
                      }}
                      resetFunction={() => {
                        this.setState(
                          prevState => ({
                            ...prevState,
                            last: "",
                            data: [],
                            paginationEnd: false,
                            filter: {
                              room_id: "",
                              active: "",
                              template_id: "",
                            },
                          }),
                          () => this.getData("", this.state.filter)
                        );
                      }}
                      loading={this.state.loading}
                    />
                  </div>
                )}

                {showCreateRoom && this.createRoomButton(showCreateRoom)}
              </Flex>
            </div>

            {this.state.createRoomToggle && (
              <CreateRoomModal
                open={this.state.createRoomToggle}
                openChangeHandler={this.toggleCreateRoomModal}
              />
            )}
            {this.state.joinRoomToggle && (
              <JoinRoomModal
                open={this.state.joinRoomToggle}
                setOpen={() => this.toggleJoinRoomDialog()}
                roomId={this.props.roomId}
                subdomain={this.props.createdTemplate.subdomain}
                templateId={this.props.createdTemplate.id}
                componentId="room"
              />
            )}

            {this.state.data.length === 0 &&
              !this.state.loading &&
              !isSearch && (
                <ZeroState
                  subtitle={<></>}
                  componentId="rooms"
                  hasTemplates={this.props.templates.length !== 0}
                />
              )}
            <div
              className={classNames(" table-container ", {
                " overflow-auto border-[1px] border-border-light rounded-lg ":
                  this.state.data.length > 0 ||
                  (!this.state.loading && this.state.data.length),
              })}
            >
              <RoomsTableContainer
                rooms={this.state.data}
                toggleRoomStatus={this.toggleRoomStatus}
                tableHeaders={tableHeaders}
                isSearch={isSearch}
                templates={this.props.templatesObj}
                loading={this.state.loading}
                hasTemplates={this.props?.templates?.length}
                checkTemplateExistsForRoom={this.checkTemplateExistsForRoom}
                ref={this.tableContainerRef}
                isReadOnlyWorkspaceUser={this.state.isReadOnlyWorkspaceUser}
              />

              <div
                ref={this.loadingRef}
                style={{
                  minHeight: "1px",
                  textAlign: "center",
                }}
              >
                {this.state.loading && <LoadingSpinner classes="my-5" />}
              </div>
            </div>
            {this.state.data.length === 0 &&
              !this.state.loading &&
              isSearch && (
                <NoResults
                  title="No results found"
                  subtitle="Please check the input and filter values"
                />
              )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {
    strapiStarterKits: state.createApp.strapiStarterKits,
    templates: state?.roles?.all_templates,
    templatesObj: state?.roles?.templates,
    roomId: state?.createTemplate?.roomId,
    createdTemplate: state?.createTemplate?.createdTemplate,
    addNewAppStatus: state?.createTemplate?.addNewAppStatus,
    createRoomStatus: state?.createRoom?.createRoomStatus,
    fetchWorkspaceInfoStatus: state?.workspace?.status?.fetchWorkspaceInfo,
    workspace: state?.workspace,
  };
};

export default connect(mapStatetoProps, {
  fetchStrapiStarterKits,
  fetchTemplatesData,
  resetCreateTemplate,
  fetchWorkspaceDetails,
})(withNamespaces()(Rooms));
