import { handleActions } from "redux-actions";
import {
  VERIFY_AWS_DONE,
  VERIFY_AWS_FAIL,
  VERIFY_AWS_INIT,
  VERIFY_AWS_RESET,
} from "./actions";
import { API_CALL_STATE } from "../../../constants";

export const verifyAWSDetails = handleActions(
  {
    [VERIFY_AWS_INIT]: state => ({
      ...state,
      awsDetailsStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [VERIFY_AWS_DONE]: (state, action) => {
      return {
        ...state,
        awsDetailsStatus: API_CALL_STATE.DONE,
        awsDetails: action.payload,
      };
    },
    [VERIFY_AWS_FAIL]: state => ({
      ...state,
      awsDetailsStatus: API_CALL_STATE.FAILED,
    }),
    [VERIFY_AWS_RESET]: state => ({
      ...state,
      awsDetailsStatus: API_CALL_STATE.RESET,
      awsDetails: {},
    }),
  },
  {
    awsDetails: {},
    awsDetailsStatus: "",
  }
);
