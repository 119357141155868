import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// @ts-ignore
import { HMSPrebuilt, HMSPrebuiltRefType } from "@100mslive/roomkit-react";
import { ColorPalette, Theme } from "@100mslive/types-prebuilt";
import { cloneDeep, isEmpty, mergeWith } from "lodash";
import { fetchPolicyInfo, fetchTemplatesData } from "src/actions/RolesActions";
import PrebuiltResponsiveViewProvider from "src/components/layout/PrebuiltResponsiveViewProvider";
import { getDefaultPrebuiltProps } from "src/components/Prebuilt/helpers/utils";
import PrebuiltLayout from "src/components/VerticalLayout/PrebuiltLayout";
import { API_CALL_STATE, DEFAULT_LOGO, prebuiltFonts } from "src/constants";
import JoinRoomPopover from "src/containers/popover/JoinRoomPopover";
import { isVisibleRole } from "src/helpers";
import {
  clearAppLayoutStore,
  fetchAppLayout,
} from "src/store/appLayout/actions";
import { RootState } from "src/store/reducers";
import { fetchShortCodeForAll } from "src/store/rooms/actions";
import {
  AlertTriangleIcon,
  LayoutIcon,
  PersonIcon,
} from "@100mslive/react-icons";
import { Flex, Loading, Text } from "@100mslive/react-ui";
import { mergeCustomiser } from "./utils";

type ColorPaletteKeys = keyof ColorPalette;

// eslint-disable-next-line complexity
function Index() {
  const dispatch = useDispatch();

  const { policyId } = useParams<any>();
  const prebuiltRef = useRef<HMSPrebuiltRefType>(null);
  const roleForLayout = useSelector(
    (state: RootState) => state.appLayouts.roleForLayout
  );
  const roomShortCodeForRoles = useSelector(
    (state: RootState) => state.rooms.roomShortCodeForRoles
  );
  const roleLayouts = useSelector(
    (state: RootState) => state.appLayouts.roleLayouts
  );
  const fetchAllRoomCodesStatus = useSelector(
    (state: RootState) => state.rooms.fetchAllRoomCodesStatus
  );
  const screenMode = useSelector(
    (state: RootState) => state.appLayouts.screenMode
  );
  const screenType = useSelector(
    (state: RootState) => state.appLayouts.screenType
  );

  const policyInfo = useSelector((state: RootState) => state.roles.policyInfo);
  const roomCode = roomShortCodeForRoles?.[roleForLayout]?.value;

  const [showPrebuilt, setShowPrebuilt] = useState(!!roomCode);

  const skipPreview =
    !!roleLayouts[roleForLayout]?.screens?.preview?.skip_preview_screen;

  const remountPrebuilt = useCallback(() => {
    setShowPrebuilt(false);
    setTimeout(() => setShowPrebuilt(true), 200);
  }, []);

  useEffect(() => {
    remountPrebuilt();
  }, [remountPrebuilt, roomCode, screenMode]);

  useEffect(() => {
    if (!skipPreview) {
      remountPrebuilt();
    }
  }, [remountPrebuilt, skipPreview]);

  useEffect(() => {
    dispatch(fetchTemplatesData());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (policyId) {
      dispatch(fetchPolicyInfo(policyId));
      dispatch(fetchAppLayout({ templateId: policyId }));
    }
    return () => {
      dispatch(clearAppLayoutStore({ payload: {} }));
    };
  }, [dispatch, policyId]);

  useEffect(() => {
    const roles = Object.keys(policyInfo.roles).filter(role =>
      isVisibleRole(role)
    );
    if (
      !isEmpty(policyInfo) &&
      isEmpty(roomShortCodeForRoles[roles[0]]) &&
      fetchAllRoomCodesStatus !== API_CALL_STATE.FAILED &&
      policyInfo?.room_id
    ) {
      dispatch(fetchShortCodeForAll(policyInfo?.room_id));
    }
  }, [dispatch, policyInfo, fetchAllRoomCodesStatus, roomShortCodeForRoles]);

  const logo = roleLayouts[roleForLayout]?.logo?.url || DEFAULT_LOGO;
  const font =
    roleLayouts[roleForLayout]?.typography?.font_family || prebuiltFonts[0];
  const fetchOrCreateAppLayoutStatus = useSelector(
    (state: RootState) => state.appLayouts.fetchAppLayoutStatus
  );
  const layout = useMemo(
    () => roleLayouts[roleForLayout] || {},
    [roleForLayout, roleLayouts]
  );
  const theme = useMemo(() => {
    return cloneDeep(roleLayouts?.[roleForLayout]?.themes?.[0]);
  }, [roleLayouts, roleForLayout]);
  // const theme: Theme = useMemo(() => cloneDeep(themes[0]), [themes, layout]);
  // TODO: stitches css ordering issue has caused this problem. Keeping this logic till we have a fix inside stitches component
  const importantFlag = " !important";
  if (theme?.palette) {
    const colorKeys = Object.keys(
      theme.palette as ColorPalette
    ) as ColorPaletteKeys[];
    colorKeys.forEach(key => {
      if (theme.palette?.[key]) {
        if (!(theme?.palette?.[key] || "").includes(importantFlag)) {
          theme.palette[key] = theme.palette[key] + importantFlag;
        }
      }
    });
  }

  const screenName = useMemo(() => {
    if (screenMode === "preview") {
      return "Preview Screen";
    }

    return "Room Screen";
  }, [screenMode]);

  return (
    <PrebuiltLayout>
      {fetchOrCreateAppLayoutStatus === API_CALL_STATE.IN_PROGRESS ||
      fetchAllRoomCodesStatus === API_CALL_STATE.IN_PROGRESS ? (
        <Flex css={{ w: "100%", h: "100%" }} justify="center" align="center">
          <Loading size={32} />
        </Flex>
      ) : fetchOrCreateAppLayoutStatus === API_CALL_STATE.DONE ? (
        <>
          <Flex justify="between" align="center">
            <Flex
              gap="3"
              css={{
                paddingLeft: "$10",
              }}
            >
              <Flex
                direction="row"
                align="center"
                css={{
                  borderRight: "1px solid $borderDefault",
                  paddingRight: "$md",
                  c: "$textMedEmp",
                }}
              >
                <PersonIcon width={22} height={22} />
                <Text
                  variant="body2"
                  css={{
                    ml: "$4",
                    fontWeight: "$regular",
                    color: "$textMedEmp",
                  }}
                >
                  {roleForLayout}
                </Text>
              </Flex>
              <Flex direction="row" align="center" css={{ c: "$textMedEmp" }}>
                <LayoutIcon />
                <Text
                  variant="body2"
                  css={{
                    ml: "$4",
                    fontWeight: "$regular",
                    color: "$textMedEmp",
                  }}
                >
                  {screenName}
                </Text>
              </Flex>
            </Flex>
            <Flex
              justify="end"
              align="center"
              css={{
                p: "$8 $10 $8",
              }}
            >
              <JoinRoomPopover />
            </Flex>
          </Flex>
          <PrebuiltResponsiveViewProvider>
            <Flex direction="column" css={{ w: "100%" }}>
              {showPrebuilt && roomCode ? (
                <HMSPrebuilt
                  ref={prebuiltRef}
                  typography={{ font_family: font }}
                  logo={{ url: logo }}
                  options={{
                    endpoints: {
                      tokenByRoomCode:
                        process.env.REACT_APP_ENV !== "prod"
                          ? "https://auth-nonprod.100ms.live/v2/token"
                          : "https://auth.100ms.live/v2/token",
                      init:
                        process.env.REACT_APP_ENV === "prod"
                          ? `https://prod-init.100ms.live/init`
                          : `https://qa-init.100ms.live/init`,
                      roomLayout:
                        process.env.REACT_APP_ENV === "prod"
                          ? "https://api.100ms.live/v2/layouts/ui"
                          : "https://api-nonprod.100ms.live/v2/layouts/ui",
                    },
                    userName: "admin",
                  }}
                  themes={
                    layout.themes ? [theme as unknown as Theme] : undefined
                  }
                  roomCode={roomCode}
                  screens={{
                    // @ts-ignore
                    preview: null,
                    conferencing: undefined,
                    [screenMode]: mergeWith(
                      getDefaultPrebuiltProps(screenMode, screenType),
                      layout.screens?.[screenMode],
                      mergeCustomiser
                    ),
                  }}
                />
              ) : null}
            </Flex>
          </PrebuiltResponsiveViewProvider>
        </>
      ) : (
        <>
          <Flex
            justify="center"
            align="center"
            direction="column"
            css={{ h: "100%" }}
          >
            <Flex css={{ c: "$error" }}>
              <AlertTriangleIcon width={30} height={30} />
            </Flex>
            <Text variant="h5" css={{ c: "$error" }}>
              Couldn't fetch layout data. Try again later!
            </Text>
          </Flex>
        </>
      )}
    </PrebuiltLayout>
  );
}

export default Index;
