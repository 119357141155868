import React, { FC } from "react";
import { PeopleIcon } from "@100mslive/react-icons";
import { Flex, Text } from "@100mslive/react-ui";

interface PresetCardProps {
  title: string;
  img: React.ReactNode;
  subText?: string;
  roles?: string;
  isActive: boolean;
  showRoles?: boolean;
  onClick: () => void;
}

const PresetCard: FC<PresetCardProps> = ({
  title,
  img,
  onClick,
  isActive,
  subText = "",
  roles = "", // < "listener, host" >
  showRoles = true,
}) => (
  <Flex
    direction="column"
    align="center"
    css={{
      backgroundColor: "$surfaceDefault",
      border: "2px solid",
      borderColor: isActive ? "$borderAccent" : "$borderDefault",
      borderRadius: "$0",
      px: "$md",
      py: "$14",
      cursor: "pointer",
      height: "216px",
      "&:hover": {
        backgroundColor: "$surfaceLight",
      },
      "@media (max-width: 1440px)": {
        height: "100%",
      },
    }}
    onClick={onClick}
  >
    {img}
    <Text
      css={{
        mt: "$xs",
        fontWeight: "$semiBold",
        color: "$textHighEmp",
        textAlign: "center",
      }}
    >
      {title}
    </Text>
    {subText && (showRoles ? roles.length > 0 : true) ? (
      <Flex
        direction="column"
        justify="between"
        align="center"
        css={{ h: "100%" }}
      >
        <Text
          variant="xs"
          css={{
            color: "$textMedEmp",
            textAlign: "center",
            mt: "$2",
            mb: showRoles ? "$12" : 0,
          }}
        >
          {subText}
        </Text>
        {showRoles ? (
          <Flex align="center" gap="1" css={{ color: "$textMedEmp" }}>
            <PeopleIcon height={16} width={16} />
            <Text variant="xs" css={{ color: "$textMedEmp" }}>
              {roles}
            </Text>
          </Flex>
        ) : null}
      </Flex>
    ) : null}
  </Flex>
);

export default PresetCard;
