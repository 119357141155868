import React from "react";
import { isEmpty } from "lodash";
import TitleValue from "src/components/Common/TitleValue";
import { peerAnalyticsType } from "src/types/analytics";

type Props = { peerData: peerAnalyticsType };

const participantInfoObject = {
  Name: "peer_name",
  "Peer Id": "peer_id",
  "User Id": "user_id",
  Role: "role",
};

const ParticipantInformation = ({ peerData }: Props) => {
  const listOfInfo = Object.keys(participantInfoObject);
  if (isEmpty(peerData)) {
    return <></>;
  }
  return (
    <>
      {listOfInfo.map((key: string) => {
        return (
          <TitleValue
            title={key}
            value={
              peerData?.[
                participantInfoObject[
                  key as keyof typeof participantInfoObject
                ] as keyof peerAnalyticsType
              ]?.toString() || "-"
            }
            btnId={
              participantInfoObject[key as keyof typeof participantInfoObject]
            }
            componentId={
              participantInfoObject[key as keyof typeof participantInfoObject]
            }
            copyButton={true}
            textCss={{
              fontFamily:
                listOfInfo[0] !== key ? "var(--user-font-roboto)" : "",
            }}
          />
        );
      })}
    </>
  );
};

export default ParticipantInformation;
