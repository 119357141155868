import React, { FC } from "react";
import { Link } from "react-router-dom";
import { AppAnalytics } from "src/helpers";
import { ReportIcon } from "@100mslive/react-icons";
import { Button } from "@100mslive/react-ui";

interface InspectButtonProps {
  link: string;
  hide?: boolean;
  componentId?: string;
  btnId?: string;
}

const InspectButton: FC<InspectButtonProps> = ({
  link,
  hide = false,
  componentId,
  btnId,
}) => {
  if (hide === true) {
    return <></>;
  }
  return (
    <Link to={link}>
      <Button
        icon
        outlined
        variant="standard"
        css={{
          fontSize: "$sm",
          h: "$12",
          r: "$0",
          color: "$textHighEmp",
          pl: "$4",
        }}
        onClick={() => {
          AppAnalytics.track("btn.clicked", {
            btnId: btnId,
            componentId: componentId,
          });
        }}
      >
        <ReportIcon height={20} width={20} /> Inspect
      </Button>
    </Link>
  );
};

export default InspectButton;
