import React from "react";
import { isFunction } from "lodash";
import { DesktopIcon, MobileIcon, TabletIcon } from "@100mslive/react-icons";
import { Flex } from "@100mslive/react-ui";
import { peerPlatformInfo } from "../components/PeerPlatformDetails";

export const operatingSystemIconInfo = {
  tizen: {
    key: "tizen",
    val: "Tizen",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Tizen_b99350214e/Tizen_b99350214e.svg`,
  },
  macos: {
    key: "macos",
    val: "MacOS",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/mac_OS_804456afd8/mac_OS_804456afd8.png?width=96&height=96`,
  },
  windows: {
    key: "windows",
    val: "Windows",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Windows_fdfe6749b6/Windows_fdfe6749b6.svg`,
  },
  linux: {
    key: "linux",
    val: "Linux",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Linux_31f8353a2e/Linux_31f8353a2e.svg`,
  },
  chromium: {
    key: "chromium",
    val: "Chromium",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Chromium_3df17710bd/Chromium_3df17710bd.svg`,
  },
  ubuntu: {
    key: "ubuntu",
    val: "Ubuntu",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Ubuntu_966dd923c5/Ubuntu_966dd923c5.svg`,
  },
  ios: {
    key: "ios",
    val: "iOS",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/i_OS_3cdc9d9b71/i_OS_3cdc9d9b71.svg`,
  },
  android: {
    key: "android",
    val: "Android",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Android_49dcfdc934/Android_49dcfdc934.svg`,
  },
};
export const frameworkTypeIconInfo = {
  "react-native": {
    key: "react-native",
    val: "React-Native",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/React_4693d2ecb5/React_4693d2ecb5.svg`,
  },
  react: {
    key: "react",
    val: "React",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/React_4693d2ecb5/React_4693d2ecb5.svg`,
  },
  javascript: {
    key: "javascript",
    val: "Javascript",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Javascript_82c858f0b8/Javascript_82c858f0b8.svg`,
  },
  swift: {
    key: "swift",
    val: "Swift",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Swift_8d7aa22da5/Swift_8d7aa22da5.svg`,
  },
  flutter: {
    key: "flutter",
    val: "Flutter",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Flutter_87c39906ca/Flutter_87c39906ca.svg`,
  },
};
export const browserTypeIconInfo = {
  brave: {
    key: "brave",
    val: "Brave",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Brave_7e66131f09/Brave_7e66131f09.svg`,
  },
  chrome: {
    key: "chrome",
    val: "Chrome",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Chrome_7bf77fbdd7/Chrome_7bf77fbdd7.svg`,
  },
  firefox: {
    key: "firefox",
    val: "Firefox",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Firefox_30f3976fb8/Firefox_30f3976fb8.svg`,
  },
  safari: {
    key: "safari",
    val: "Safari",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Safari_254e74ed94/Safari_254e74ed94.svg`,
  },
  edge: {
    key: "edge",
    val: "Edge",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Edge_23add4a83f/Edge_23add4a83f.svg`,
  },
  opera: {
    key: "opera",
    val: "Opera",
    icon: `${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Opera_0f3bf4eb19/Opera_0f3bf4eb19.svg`,
  },
};

const platformIconSrc = {
  desktop: (props: any) => <DesktopIcon {...props} />,
  tablet: (props: any) => <TabletIcon {...props} />,
  mobile: (props: any) => <MobileIcon {...props} />,
};
export const getPeerPlatformInfoIcon = ({
  key,
  val,
}: {
  key: keyof typeof peerPlatformInfo;
  val: string;
}) => {
  if (key === "Framework") {
    return getFrameworkIcon({ val });
  }
  if (key === "SDK Version") {
    return (
      <Flex
        css={{ bg: "$white", w: "$12", h: "$12", r: "$round" }}
        justify="center"
        align="center"
      >
        <img
          src="/logo512.png"
          alt="100ms logo"
          style={{ width: "32px", height: "32px" }}
        />
      </Flex>
    );
  }
  if (key === "Operating System") {
    return getOperatingSystemIcon({ val });
  }
  if (key === "Browser") {
    return getBrowserTypeIcon({ val });
  }
  if (key === "Platform") {
    return (
      <Flex
        css={{
          bg: "$secondaryLight",
          w: "$12",
          h: "$12",
          r: "$round",
          p: "$2",
        }}
        justify="center"
        align="center"
      >
        {isFunction(
          platformIconSrc[val.toLowerCase() as keyof typeof platformIconSrc]
        ) ? (
          platformIconSrc[val.toLowerCase() as keyof typeof platformIconSrc]({
            style: { width: "32px", height: "32px" },
          })
        ) : (
          <DesktopIcon style={{ width: "32px", height: "32px" }} />
        )}
      </Flex>
    );
  }

  return null;
};

export const getBrowserTypeIcon = ({ val }: { val: string }) => {
  if (val && Object.keys(browserTypeIconInfo).includes(val.toLowerCase())) {
    return (
      <Flex
        css={{
          bg: "$secondaryLight",
          w: "$12",
          h: "$12",
          r: "$round",
          p: "$2",
          position: "relative",
        }}
        justify="center"
        align="center"
      >
        <img
          src={
            browserTypeIconInfo[
              val.toLowerCase() as keyof typeof browserTypeIconInfo
            ].icon
          }
          alt={
            browserTypeIconInfo[
              val.toLowerCase() as keyof typeof browserTypeIconInfo
            ].val
          }
          style={{
            width: "32px",
            height: "32px",
            position: "absolute",
            zIndex: 1,
          }}
        />
      </Flex>
    );
  } else {
    return (
      <Flex
        css={{
          bg: "$secondaryLight",
          w: "$12",
          h: "$12",
          r: "$round",
          p: "$2",
          position: "relative",
        }}
        justify="center"
        align="center"
      >
        <img
          src={`${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Browser_23cbd0e6bd/Browser_23cbd0e6bd.svg`}
          alt="stock browser logo"
          style={{
            width: "32px",
            height: "32px",
            position: "absolute",
            zIndex: 1,
          }}
        />
      </Flex>
    );
  }
};

export const getOperatingSystemIcon = ({ val }: { val: string }) => {
  if (val && Object.keys(operatingSystemIconInfo).includes(val.toLowerCase())) {
    return (
      <Flex
        css={{
          bg: "$secondaryLight",
          w: "$12",
          h: "$12",
          r: "$round",
          position: "relative",
        }}
        justify="center"
        align="center"
      >
        <img
          src={
            operatingSystemIconInfo[
              val.toLowerCase() as keyof typeof operatingSystemIconInfo
            ].icon
          }
          alt={
            operatingSystemIconInfo[
              val.toLowerCase() as keyof typeof operatingSystemIconInfo
            ].val
          }
          style={{
            width: "32px",
            height: "32px",
            position: "absolute",
            zIndex: 1,
          }}
        />
      </Flex>
    );
  } else {
    return (
      <Flex
        css={{
          bg: "$secondaryLight",
          w: "$12",
          h: "$12",
          r: "$round",
          position: "relative",
        }}
        justify="center"
        align="center"
      >
        <img
          src={`${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/OS_b69cef9c0c/OS_b69cef9c0c.svg`}
          alt="stock os logo"
          style={{
            width: "32px",
            height: "32px",
            position: "absolute",
            zIndex: 1,
          }}
        />
      </Flex>
    );
  }
};

export const getFrameworkIcon = ({ val }: { val: string }) => {
  if (val && Object.keys(frameworkTypeIconInfo).includes(val.toLowerCase())) {
    return (
      <Flex
        css={{
          bg: "$secondaryLight",
          w: "$12",
          h: "$12",
          r: "$round",
          position: "relative",
        }}
        justify="center"
        align="center"
      >
        <img
          src={
            frameworkTypeIconInfo[
              val.toLowerCase() as keyof typeof frameworkTypeIconInfo
            ].icon
          }
          alt={
            frameworkTypeIconInfo[
              val.toLowerCase() as keyof typeof frameworkTypeIconInfo
            ].val
          }
          style={{
            width: "32px",
            height: "32px",
            position: "absolute",
            zIndex: 1,
          }}
        />
      </Flex>
    );
  } else {
    return (
      <Flex
        css={{
          bg: "$white",
          w: "$12",
          h: "$12",
          r: "$round",
          position: "relative",
        }}
        justify="center"
        align="center"
      >
        <img
          src="/logo512.png"
          alt="100ms logo"
          style={{
            width: "32px",
            height: "32px",
            position: "absolute",
            zIndex: 1,
          }}
        />
      </Flex>
    );
  }
};
