import React, { CSSProperties, isValidElement } from "react";
import { Flex } from "@100mslive/react-ui";
import TextAndSubtext from "../Common/TextAndSubtext";
import TwoColumnFlex from "../Common/TwoColumnFlex";

function TemplateConfigCard({
  text = "",
  subtitle = "",
  leftComponent = null,
  rightComponent,
  id = "",
  css = {},
  classNameForText = "",
  cardRef,
  bottomButton = null,
}: {
  id: string;
  text: string;
  subtitle?: string | JSX.Element;
  css?: CSSProperties;
  leftComponent?: JSX.Element | null;
  rightComponent: JSX.Element;
  classNameForText?: string;
  cardRef?: React.RefObject<HTMLDivElement>;
  bottomButton?: JSX.Element | null;
}) {
  return (
    <Flex
      direction="column"
      css={{
        bg: "$surfaceDefault",
        border: "$solid$px $borderDefault solid",
        r: "$1",
        p: "$12",
        pb: bottomButton ? 0 : "$12",
        ...css,
      }}
    >
      <>
        <TwoColumnFlex
          id={id}
          w={30}
          column1Direction="column"
          column2Direction="column"
          columnGap="$20"
          css={{
            alignItems: "start",
            pb: bottomButton ? "$12" : 0,
          }}
        >
          <Flex direction="column" align="start" css={{ h: "100%" }}>
            <TextAndSubtext
              flexGap="$4"
              text={text}
              textVariant="h6"
              subText={subtitle}
              subTextVariant="body2"
              classNameForText={classNameForText}
              setID
              css={{
                alignItems: "start",
                mb: isValidElement(leftComponent) ? "$4" : 0,
              }}
            />
            {leftComponent}
          </Flex>
          <Flex direction="column" align="start" ref={cardRef}>
            {rightComponent}
          </Flex>
        </TwoColumnFlex>
        {bottomButton ? bottomButton : null}
      </>
    </Flex>
  );
}

export default TemplateConfigCard;
