import _ from "lodash";
import { API_CALL_STATE } from "src/constants";
import { currentWorkspaceHipaa } from "src/utils";
import api from "../api";

export const FETCH_PUBLIC_TEMPLATES_INIT = "FETCH_PUBLIC_TEMPLATES_INIT";
export const FETCH_PUBLIC_TEMPLATES_DONE = "FETCH_PUBLIC_TEMPLATES_DONE";
export const FETCH_PUBLIC_TEMPLATES_FAIL = "FETCH_PUBLIC_TEMPLATES_FAIL";
export const FETCH_STRAPI_STARTER_KITS_INIT = "FETCH_STRAPI_STARTER_KITS_INIT";
export const FETCH_STRAPI_STARTER_KITS_DONE = "FETCH_STRAPI_STARTER_KITS_DONE";
export const FETCH_STRAPI_STARTER_KITS_FAIL = "FETCH_STRAPI_STARTER_KITS_FAIL";

export function fetchPublicTemplates() {
  return (dispatch, getState) => {
    const { publicTemplatesStatus } = getState().createApp;
    if (publicTemplatesStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_PUBLIC_TEMPLATES_INIT });
    return api
      .service("cms")
      .get("api/templates", {
        populate:
          "icon,roles,roles.videoPermission,roles.screensharePermission,roles.subscribeTo,bannerImage,destinations.hlsResolutionLayers,layout,settings,whiteboard.admin,whiteboard.writer,whiteboard.reader",
        sort: "sortRank",
        "filters[isHipaa][$eq]": currentWorkspaceHipaa(),
      })
      .then(response => {
        dispatch({
          type: FETCH_PUBLIC_TEMPLATES_DONE,
          payload: response.data.data,
        });
      })
      .catch(error => {
        console.error(error.response);
        dispatch({ type: FETCH_PUBLIC_TEMPLATES_FAIL });
      });
  };
}

export function fetchStrapiStarterKits() {
  const labelOrder = ["recommended", "community"];
  return (dispatch, getState) => {
    const { strapiStarterKits, strapiStarterKitsStatus } = getState().createApp;
    if (
      !_.isEmpty(strapiStarterKits) ||
      strapiStarterKitsStatus === "fetching"
    ) {
      return;
    }
    dispatch({ type: FETCH_STRAPI_STARTER_KITS_INIT });
    return api
      .service("cms")
      .get(
        "api/starter-kits",
        {
          populate: "languageTags,image,languageTags.icon,template.roles",
        },
        {
          headers: null,
        },
        {
          sort: "priority",
        }
      )
      .then(response => {
        const { data } = response;
        const sortedData = data.data.sort((a, b) => {
          return (
            labelOrder.indexOf(
              a.attributes.label
                ? a.attributes.label.toLowerCase()
                : "community"
            ) -
            labelOrder.indexOf(
              b.attributes.label
                ? b.attributes.label.toLowerCase()
                : "community"
            )
          );
        });
        dispatch({ type: FETCH_STRAPI_STARTER_KITS_DONE, payload: sortedData });
      })
      .catch(error => {
        console.error(error.response);
        dispatch({ type: FETCH_STRAPI_STARTER_KITS_FAIL });
      });
  };
}
