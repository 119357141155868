import React from "react";
import * as echarts from "echarts";
import { default as EchartsWrapper } from "src/components/Echarts";
import { echartsDataZoomConfig } from "src/components/Echarts/EchartsConfigs";
import { SessionMinutesBreakdown } from "src/types/insightsTypes";
import { Flex } from "@100mslive/react-ui";

type echartsOptions = echarts.EChartsOption;

export const SessionsBreakdownChart = ({
  data,
  loading,
}: {
  data: SessionMinutesBreakdown;
  loading: boolean;
}) => {
  const maxValue = Object.keys(data).reduce(
    (a, b) => Math.max(a, parseInt(b)),
    0
  );
  const eventLogChartoptions: echartsOptions = {
    tooltip: {
      axisPointer: {
        animation: false,
      },
      trigger: "axis", // Trigger tooltip on item (icon) hover
      backgroundColor: "#1E232A",
      borderRadius: 4,
      padding: 4,
      borderColor: "#1E232A",
      textStyle: {
        color: "#F5F9FF",
      },
      show: true,
    },
    xAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value} mins",
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#2C333F",
          type: "dashed",
        },
      },
      min: 0,
      max: maxValue > 60 ? maxValue : 60,
      boundaryGap: ["0%", "100%"],
    },
    toolbox: {
      right: "5%",
      feature: {
        dataZoom: {
          yAxisIndex: "none",
          icon: {
            zoom: `M 5.77783,7.44444 H 9.11116 M 7.44446,5.77777 V 9.1111 M 13,13 10.5834,10.5833 m -3.13896,1.3056 c 2.45459,0 4.44446,-1.98987 4.44446,-4.44446 C 11.8889,4.98984 9.89903,3 7.44444,3 4.98984,3 3,4.98984 3,7.44444 3,9.89903 4.98984,11.8889 7.44444,11.8889 Z`,
            back: `path://m 4,6.70833 h 5.6875 c 0.3912,0 0.7786,0.07706 1.1401,0.22678 0.3614,0.14972 0.6898,0.36916 0.9665,0.6458 0.2766,0.27664 0.4961,0.60506 0.6458,0.96651 0.1497,0.36145 0.2268,0.74885 0.2268,1.14008 0,0.3912 -0.0771,0.7786 -0.2268,1.1401 -0.1497,0.3614 -0.3692,0.6898 -0.6458,0.9665 -0.2767,0.2766 -0.6051,0.4961 -0.9665,0.6458 -0.3615,0.1497 -0.7489,0.2268 -1.1401,0.2268 H 7.79167 M 6.70833,9.41667 4,6.70833 6.70833,4`,
          },
        },
        restore: {
          icon: `M 5.77778,10.2222 H 3 V 13 M 13,8 c 0,1.32608 -0.5268,2.5979 -1.4645,3.5355 C 10.5979,12.4732 9.32608,13 8,13 6.6022,12.9947 5.26054,12.4493 4.25556,11.4778 L 3,10.2222 M 12.9999,3 V 5.77778 H 10.2222 M 3,8 C 3,6.67392 3.52678,5.40215 4.46447,4.46447 5.40215,3.52678 6.67392,3 8,3 9.3978,3.00526 10.7395,3.55068 11.7444,4.52222 L 13,5.77778`,
        },
        saveAsImage: {
          icon: `path://M 8.00012,9.77777 V 2.66666 M 5.03709,6.81481 8.00005,9.77777 10.963,6.81481 m 2.3703,2.96296 v 2.37033 c 0,0.3144 -0.1249,0.6158 -0.3471,0.8381 -0.2223,0.2223 -0.5238,0.3471 -0.8381,0.3471 H 3.85181 c -0.31433,0 -0.61579,-0.1248 -0.83805,-0.3471 C 2.79149,12.7639 2.66663,12.4625 2.66663,12.1481 V 9.77777`,
        },
      },
    },
    grid: {
      containLabel: true,
      top: "20%",
      left: "2%",
      right: "5%",
    },
    dataZoom: [
      {
        show: true,
        realtime: true,
        start: 0,
        filterMode: "none",
        end: 100,
        xAxisIndex: [0],
        minValueSpan: 1,

        textStyle: { width: 70, overflow: "break" },
        ...echartsDataZoomConfig,
      },
      {
        minValueSpan: 1,
        type: "inside",
        realtime: true,
        start: 0,
        end: 100,
        filterMode: "none",
        xAxisIndex: [0],
      },
    ],
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value}",
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#2C333F",
          type: "dashed",
        },
      },
      min: 0,
    },

    series: [
      {
        type: "bar",
        barWidth: Object.keys(data).length > 100 ? "10%" : "20%",
        data: Object.keys(data).map(item => [parseInt(item), data[item]]),
        itemStyle: {
          color: "#2672ED",
        },
        markLine: {
          zlevel: 0,
          label: {
            show: false,
          },
          silent: true,
          symbol: ["none", "none"],
          lineStyle: {
            color: "#2C333F",
            type: "solid",
          },
        },
      },
    ],
  };

  return (
    <Flex direction="column" css={{ w: "100%" }}>
      {/* @ts-ignore */}
      <EchartsWrapper
        options={{ ...eventLogChartoptions }}
        loading={loading}
        className="mos-chart"
        style={{ height: "240px", width: "auto" }}
      />
    </Flex>
  );
};
