import logo from "../../assets/images/100ms-logo.png";

const Unauthorized = props => {
  return (
    <div className="flex h-screen justify-center items-center">
      <div className="flex flex-col items-center">
        <img
          className="-mt-48 mb-4"
          src={logo}
          alt="100ms logo"
          width={250}
          height={72}
        />
        <div className="flex flex-col items-center">
          <p className="text-2xl leading-9 font-medium">
            You do not have access to this page
          </p>
          <p className="text-2xl leading-9 font-medium">
            Please check that you are logged in with the right account
          </p>
        </div>
        <div className="bg-gray-cool1 mt-12 px-6 py-3 rounded-full">
          <p className="text-xl leading-6 ">
            Logged in as {props.unauthUserEmail}
          </p>
        </div>
        <button
          className="btn mt-12 px-4 py-2 border-1 border-blue-standard rounded-sm"
          onClick={props.unauthUserClickHandler}
        >
          <p className="text-base">Login with a different account</p>
        </button>
      </div>
    </div>
  );
};

export default Unauthorized;
