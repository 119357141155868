import {
  ConferencingScreen,
  LeaveScreen,
  PreviewScreen,
  Screens,
} from "@100mslive/types-prebuilt";
import { isEmpty } from "lodash";
import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import { API_CALL_STATE } from "src/constants";
import { RoleLayouts } from "src/types/prebuilt";
import {
  CLEAR_APP_LAYOUT,
  CREATE_APP_LAYOUT_DONE,
  CREATE_APP_LAYOUT_FAIL,
  CREATE_APP_LAYOUT_INIT,
  CREATE_OR_FETCH_APP_LAYOUT_DONE,
  CREATE_OR_FETCH_APP_LAYOUT_FAIL,
  CREATE_OR_FETCH_APP_LAYOUT_INIT,
  FETCH_APP_LAYOUT_DONE,
  FETCH_APP_LAYOUT_FAIL,
  FETCH_APP_LAYOUT_INIT,
  PATCH_APP_LAYOUT_DONE,
  PATCH_APP_LAYOUT_FAIL,
  PATCH_APP_LAYOUT_INIT,
  SET_HAS_UNSAVED_PREBUILT_CHANGES,
  SET_LAYOUT_FOR_ROLE,
  SET_LAYOUT_SCREEN_MODE,
  SET_LAYOUT_SCREEN_TYPE,
  SET_PREBUILT_LAYOUT_INVALID_FIELDS,
  UPDATE_APP_LAYOUT,
} from "./actions";
export const appLayouts = handleActions(
  {
    [SET_PREBUILT_LAYOUT_INVALID_FIELDS]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.invalidFields = action.payload;
      return { ...newState };
    },
    [SET_LAYOUT_SCREEN_TYPE]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.screenType = action.payload;
      return { ...newState };
    },
    [SET_LAYOUT_SCREEN_MODE]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.screenMode = action.payload;
      return { ...newState };
    },
    [SET_LAYOUT_FOR_ROLE]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.roleForLayout = action.payload;
      return { ...newState };
    },
    [FETCH_APP_LAYOUT_INIT]: state => {
      const newState = { ...state };
      newState.fetchAppLayoutStatus = API_CALL_STATE.IN_PROGRESS;
      return { ...newState };
    },
    [FETCH_APP_LAYOUT_DONE]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.roleLayouts = action.payload;
      newState.fetchAppLayoutStatus = API_CALL_STATE.DONE;
      return { ...newState };
    },
    [FETCH_APP_LAYOUT_FAIL]: state => {
      const newState = { ...state };
      newState.fetchAppLayoutStatus = API_CALL_STATE.FAILED;
      return { ...newState };
    },
    [CREATE_APP_LAYOUT_INIT]: state => {
      const newState = { ...state };
      newState.createAppLayoutStatus = API_CALL_STATE.IN_PROGRESS;
      return { ...newState };
    },
    [CREATE_APP_LAYOUT_DONE]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.roleLayouts = action.payload;
      newState.createAppLayoutStatus = API_CALL_STATE.DONE;
      return { ...newState };
    },
    [CREATE_APP_LAYOUT_FAIL]: state => {
      const newState = { ...state };
      newState.createAppLayoutStatus = API_CALL_STATE.FAILED;
      return { ...newState };
    },
    [PATCH_APP_LAYOUT_INIT]: state => {
      const newState = { ...state };
      newState.patchAppLayoutStatus = API_CALL_STATE.IN_PROGRESS;
      return { ...newState };
    },
    [PATCH_APP_LAYOUT_DONE]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.roleLayouts = action.payload;
      newState.patchAppLayoutStatus = API_CALL_STATE.DONE;
      return { ...newState };
    },
    [PATCH_APP_LAYOUT_FAIL]: state => {
      const newState = { ...state };
      newState.patchAppLayoutStatus = API_CALL_STATE.FAILED;
      return { ...newState };
    },
    [CLEAR_APP_LAYOUT]: (state, action: AnyAction) => {
      const newState = { ...state };
      if (isEmpty(action.payload)) {
        newState.patchAppLayoutStatus = "";
        newState.fetchAppLayoutStatus = "";
        newState.createAppLayoutStatus = "";
        newState.roleLayouts = {} as RoleLayouts;
        newState.fetchOrCreateAppLayoutStatus = "";
        newState.screenType = "default" as keyof Screens[keyof Screens];
        newState.fetchOrCreateAppLayoutStatus = "";
        newState.screenMode = "preview" as keyof Screens;
      } else {
        newState.screenType = action.payload?.screenType || newState.screenType;
        newState.screenMode = action.payload?.screenMode || newState.screenMode;
        newState.fetchOrCreateAppLayoutStatus =
          action.payload?.fetchOrCreateAppLayoutStatus ||
          newState.fetchOrCreateAppLayoutStatus;
        newState.patchAppLayoutStatus =
          action.payload?.patchAppLayoutStatus || newState.patchAppLayoutStatus;
        newState.fetchAppLayoutStatus =
          action.payload?.fetchAppLayoutStatus || newState.fetchAppLayoutStatus;
        newState.createAppLayoutStatus =
          action.payload?.createAppLayoutStatus ||
          newState.createAppLayoutStatus;
        newState.roleLayouts =
          action.payload?.roleLayouts || newState.roleLayouts;
      }
      return { ...newState };
    },
    [UPDATE_APP_LAYOUT]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.roleLayouts = { ...action.payload };
      return { ...newState };
    },
    [SET_HAS_UNSAVED_PREBUILT_CHANGES]: (state, action: AnyAction) => {
      const updatedState = { ...state };
      updatedState.hasUnsavedPrebuiltChanges = action.payload;
      return { ...updatedState };
    },

    [CREATE_OR_FETCH_APP_LAYOUT_INIT]: state => {
      const updatedState = { ...state };
      updatedState.fetchOrCreateAppLayoutStatus = API_CALL_STATE.IN_PROGRESS;
      return { ...updatedState };
    },
    [CREATE_OR_FETCH_APP_LAYOUT_DONE]: (state, action: AnyAction) => {
      const updatedState = { ...state };
      updatedState.fetchOrCreateAppLayoutStatus = API_CALL_STATE.DONE;
      updatedState.roleLayouts = action.payload;
      return { ...updatedState };
    },
    [CREATE_OR_FETCH_APP_LAYOUT_FAIL]: state => {
      const updatedState = { ...state };
      updatedState.fetchOrCreateAppLayoutStatus = API_CALL_STATE.FAILED;
      return { ...updatedState };
    },
  },
  {
    fetchOrCreateAppLayoutStatus: "",
    patchAppLayoutStatus: "",
    fetchAppLayoutStatus: "",
    createAppLayoutStatus: "",
    roleLayouts: {} as RoleLayouts,
    hasUnsavedPrebuiltChanges: false,
    roleForLayout: "",
    invalidFields: {},
    screenType: "default" as
      | keyof PreviewScreen
      | keyof ConferencingScreen
      | keyof LeaveScreen,
    screenMode: "preview" as keyof Screens,
  }
);
