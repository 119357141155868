import React from "react";
import {
  ConferencingScreen,
  PreviewScreen,
  Screens,
} from "@100mslive/types-prebuilt";
import { get, isEmpty, isFunction, isNull, isUndefined } from "lodash";
import { getPriorityRolesArray, isVisibleRole } from "src/helpers";
import { policyType } from "src/types/policyTypes";
import { RoleLayouts } from "src/types/prebuilt";
import { LayoutIcon, PersonIcon } from "@100mslive/react-icons";
import { Dropdown, Flex, Text } from "@100mslive/react-ui";
import PrebuiltNoiseCancellation from "./screens/components/PrebuiltNoiseCancellation";
import { PrebuiltSkipPreviewScreen } from "./screens/components/PrebuiltSkipPreviewScreen";
import getPrebuiltComponentDetails from "./screens/getPrebuiltComponentDetails";
import ScreensConfiguration from "./screens/ScreensConfiguration";
import { usePrebuiltComponentList } from "./screens/usePrebuiltComponentList";
import PrebuiltConfigAccordion from "./PrebuiltConfigAccordion";
import PrebuiltCustomisationMenu from "./PrebuiltCustomisationMenu";
import ConfigSingleSelect from "../Common/ConfigSingleSelect";
import Line from "../Common/Line";

const screenModes = {
  preview: {
    name: "Preview Screen",
    val: "preview",
  },
  conferencing: {
    name: "Room Screen",
    val: "conferencing",
    tabs: {
      default: { name: "Conferencing", val: "default" },
      hls_live_streaming: { name: "Live Streaming", val: "hls_live_streaming" },
    },
  },
} as unknown as Record<
  keyof Screens,
  {
    name: string;
    val: keyof Screens;
    tabs?: Record<
      string,
      {
        name: string;
        val: string;
      }
    >;
  }
>;

function Components({
  setActiveTab,
  title,
  subtitle,
  policyInfo,
  roleLayouts,
  role,
  setRole,
  invalidFields,
  setRoleLayouts,
  screenMode,
  screenType,
  setScreenType,
  setScreenMode,
  ...props
}: {
  screenMode: keyof Screens;
  screenType: keyof ConferencingScreen | keyof PreviewScreen;
  setScreenType: (
    str: string,
    shouldUpdateConferencingDefaults?: boolean
  ) => void;
  setScreenMode: (str: string) => void;
  title: string;
  subtitle: string;
  roleLayouts: RoleLayouts;
  setRoleLayouts: ({ roleLayouts }: { roleLayouts: RoleLayouts }) => void;
  policyInfo: policyType;
  role: string;
  setRole: (role: string) => void;
  invalidFields: {} | undefined;
  setActiveTab: (key: string) => void;
}) {
  const compElementsList = usePrebuiltComponentList({
    screen: screenMode,
    screenType: screenType || "default",
  });

  return (
    <PrebuiltCustomisationMenu
      title={title}
      subtitle={subtitle}
      {...props}
      onBack={() => setActiveTab("default")}
    >
      <Flex
        direction="column"
        css={{
          py: "$10",
          borderBottom: "$space$px solid $borderDefault",
        }}
      >
        <Text variant="body2" css={{ c: "$textHighEmp", pb: "$md" }}>
          Select Role and Screen
        </Text>
        <ConfigSingleSelect
          inputText={role || "Please select Role"}
          contentCSS={{ zIndex: 1100 }}
          inputIcon={<PersonIcon />}
          inputTextCss={{ ml: "$6" }}
          content={
            <>
              {getPriorityRolesArray(
                Object.keys(policyInfo.roles).filter(role =>
                  isVisibleRole(role)
                )
              ).map((role: string) => (
                <Dropdown.Item
                  key={role}
                  css={{ p: "$4 $8" }}
                  onClick={() => {
                    setRole(role);
                    setScreenType(
                      Object.keys(
                        roleLayouts?.[role]?.screens?.[screenMode] || {
                          default: {},
                        }
                      )?.[0] as "default" | "hls_live_streaming"
                    );
                  }}
                >
                  <Text variant="caption">{role}</Text>
                </Dropdown.Item>
              ))}
            </>
          }
        />
        <ConfigSingleSelect
          inputText={screenModes?.[screenMode]?.name || "Please select Screen"}
          triggerCSS={{ mt: "$md" }}
          contentCSS={{ zIndex: 1100 }}
          inputIcon={<LayoutIcon />}
          inputTextCss={{ ml: "$6" }}
          content={
            <>
              {Object.values(screenModes).map(screen => (
                <Dropdown.Item
                  key={screen.val}
                  css={{ p: "$4 $8" }}
                  onClick={() => {
                    setScreenMode(screen.val);
                    setScreenType(
                      Object.keys(
                        roleLayouts?.[role]?.screens?.[screen.val] || {
                          default: {},
                        }
                      )?.[0] as "default" | "hls_live_streaming"
                    );
                  }}
                >
                  <Text variant="caption">{screen.name}</Text>
                </Dropdown.Item>
              ))}
            </>
          }
        />
      </Flex>
      {screenMode === "preview" ? (
        <>
          <PrebuiltSkipPreviewScreen
            role={role}
            roleLayouts={roleLayouts}
            setRoleLayouts={setRoleLayouts}
          />
          <Line />
          <PrebuiltNoiseCancellation
            role={role}
            roleLayouts={roleLayouts}
            setRoleLayouts={setRoleLayouts}
            policyInfo={policyInfo}
          />
          <Line css={{ mb: "0" }} />
        </>
      ) : (
        <></>
      )}

      {
        //eslint-disable-next-line complexity
        compElementsList.map(el => {
          if (isEmpty(ScreensConfiguration[screenMode])) {
            return <></>;
          }
          const config = { ...ScreensConfiguration?.[screenMode]?.[el] };
          const { data, setData, removeElement } = getPrebuiltComponentDetails({
            role: role,
            roleLayouts: roleLayouts,
            screen: screenMode,
            element: el,
            setRoleLayouts: setRoleLayouts,
            screenType: screenType,
          });
          if (isEmpty(config)) {
            return <></>;
          }
          const path = (config.path as string).includes(screenType)
            ? config.path
            : config.path.replaceAll("default", screenType);
          const invalidCompFields = get(invalidFields, path);

          if (
            isUndefined(
              roleLayouts[role]?.screens?.conferencing?.default?.elements
                ?.hand_raise
            ) &&
            el === "on_stage_exp"
          ) {
            return <></>;
          }

          const prebuiltConfigAccordionScreen = !(
            screenMode === "preview" &&
            roleLayouts[role]?.screens?.preview?.skip_preview_screen
          ) ? (
            <PrebuiltConfigAccordion
              title={config.ui.title}
              hasSwitch={config.ui.hasSwitch}
              tooltipText={config.ui.tooltipText || ""}
              key={config.ui.title + el}
              enabled={!isNull(data) && !isUndefined(data)}
              setEnabled={(value: boolean) => {
                if (value) {
                  if (isFunction(setData)) {
                    setData(config.defaultData);
                  }
                } else {
                  if (isFunction(removeElement)) {
                    removeElement();
                  }
                }
              }}
            >
              <config.Component
                data={data ? data : config.defaultData}
                invalidFields={invalidCompFields}
                setData={setData}
                roleLayouts={roleLayouts}
                role={role}
              />
            </PrebuiltConfigAccordion>
          ) : (
            <></>
          );

          return prebuiltConfigAccordionScreen;
        })
      }
    </PrebuiltCustomisationMenu>
  );
}

export default Components;
