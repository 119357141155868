import React, { useEffect, useState } from "react";
import { API_CALL_STATE } from "src/constants";
import { AppAnalytics } from "src/helpers/analytics_helper";
import { checkValidPresignedUrl } from "src/services/sessions/recordings/helpers";
import { ExternalLinkIcon } from "@100mslive/react-icons";
import { Button, Dialog, Flex, Text } from "@100mslive/react-ui";
import AssetTable from "./AssetTable";
import { RecordingLinkButton } from "./RecordingLinkButton";
import { recordingAsset } from "../../types";

const RecordingLinkComponent = ({
  data,
  presignedUrl,
  recordingAssets,
  roomId = "",
  fetchPresignedUrlStatus,
  setAssetLabel,
  assetLabel,
  fetchPresignedUrl,
  shouldOpenAsset,
}: {
  data: string;
  setAssetLabel: (assetType: string) => void;
  fetchPresignedUrlStatus: string;
  presignedUrl: string;
  recordingAssets: recordingAsset[];
  assetLabel: string;
  roomId: string;
  shouldOpenAsset: boolean;
  fetchPresignedUrl: ({
    roomId,
    assetId,
    data,
    download = false,
    setLoading = () => {},
  }: {
    setLoading: (value: boolean) => void;
    roomId: string;
    assetId: string;
    data: string;
    download?: boolean;
  }) => void;
}) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (
      presignedUrl &&
      fetchPresignedUrlStatus === API_CALL_STATE.DONE &&
      checkValidPresignedUrl({
        url: presignedUrl,
        isDownloadable: assetLabel.includes("download"),
      }) &&
      shouldOpenAsset
    ) {
      window.open(
        checkValidPresignedUrl({
          url: presignedUrl,
          isDownloadable: assetLabel.includes("download"),
        }),
        "_blank"
      );
    }
  }, [presignedUrl, fetchPresignedUrlStatus, assetLabel, shouldOpenAsset]);

  return recordingAssets.length > 0 ? (
    <>
      <Button
        variant="standard"
        icon
        onClick={() => {
          setOpen(true);
          try {
            AppAnalytics.track("btn.clicked", {
              platform: "hms-dashboard",
              componentId: "sessions.details.recordingLog",
              btnId: "view.assets",
            });
          } catch (e) {
            console.error(e);
          }
        }}
        css={{ color: "$textHighEmp" }}
      >
        <Text
          variant="sm"
          css={{ color: "$textHighEmp", fontWeight: "$semiBold" }}
        >
          View Assets
        </Text>
        <ExternalLinkIcon
          height={14}
          width={14}
          style={{ color: "inherit", position: "relative", top: "-1px" }}
        />
      </Button>
      <Dialog.Root
        onOpenChange={() => {
          setOpen(state => !state);
        }}
        open={isOpen}
      >
        <Dialog.Portal>
          <Dialog.Overlay />
          <Dialog.Content
            css={{
              width: "70%",
              p: "$10 $10 $8 $10",
              bg: "$surfaceDefault",
              zIndex: "9999 !important",
              maxHeight: "80%",
              overflowY: "auto",
              border: "1px solid $borderLight",
            }}
          >
            <Flex
              css={{
                justifyContent: "space-between",
              }}
            >
              <Dialog.Title>
                <Text variant="h6" css={{ color: "$textHighEmp" }}>
                  Recording Assets
                </Text>
              </Dialog.Title>
              <Dialog.DefaultClose />
            </Flex>
            {/*Asset table*/}
            <AssetTable
              recordingAssets={recordingAssets}
              fetchPresignedUrl={fetchPresignedUrl}
              setAssetLabel={setAssetLabel}
            />
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  ) : (
    <RecordingLinkButton
      data={data}
      assetId=""
      setAssetLabel={setAssetLabel}
      roomId={roomId}
      fetchPresignedUrl={fetchPresignedUrl}
      label=""
      disabled={false}
    />
  );
};

export default RecordingLinkComponent;
