import { handleActions } from "redux-actions";
import { workspaceDetailType } from "src/types/workspaces";
import {
  ACCEPT_INVITE_TO_WORKSPACE_DONE,
  ACCEPT_INVITE_TO_WORKSPACE_FAIL,
  ACCEPT_INVITE_TO_WORKSPACE_INIT,
  CREATE_WORKSPACE_DONE,
  CREATE_WORKSPACE_FAIL,
  CREATE_WORKSPACE_INIT,
  DELETE_WORKSPACE_MEMBER_DONE,
  DELETE_WORKSPACE_MEMBER_FAIL,
  DELETE_WORKSPACE_MEMBER_INIT,
  FETCH_WORKSPACE_DETAILS_DONE,
  FETCH_WORKSPACE_DETAILS_FAIL,
  FETCH_WORKSPACE_DETAILS_INIT,
  FETCH_WORKSPACES_DONE,
  FETCH_WORKSPACES_FAIL,
  FETCH_WORKSPACES_INIT,
  FETCH_WORKSPACES_RESET,
  GENERATE_JWT_TOKEN_DONE,
  GENERATE_JWT_TOKEN_FAIL,
  GENERATE_JWT_TOKEN_INIT,
  INVITE_TO_WORKSPACE_DONE,
  INVITE_TO_WORKSPACE_FAIL,
  INVITE_TO_WORKSPACE_INIT,
  SWITCH_WORKSPACE_DONE,
  SWITCH_WORKSPACE_FAIL,
  SWITCH_WORKSPACE_INIT,
  UPDATE_DEFAULT_TEMPLATE_DONE,
  UPDATE_DEFAULT_TEMPLATE_INPROGRESS,
  UPDATE_ROOM_ROLE_LINK_FORMAT_DONE,
  UPDATE_ROOM_ROLE_LINK_FORMAT_FAIL,
  UPDATE_ROOM_ROLE_LINK_FORMAT_INIT,
  UPDATE_WORKSPACE_DETAILS_DONE,
  UPDATE_WORKSPACE_DETAILS_FAIL,
  UPDATE_WORKSPACE_DETAILS_INIT,
  VERIFY_WEBHOOK_URL_DONE,
  VERIFY_WEBHOOK_URL_FAIL,
  VERIFY_WEBHOOK_URL_INIT,
} from "./actions";
import { API_CALL_STATE } from "../../constants";
import { analytics_store } from "../../helpers/analytics_helper";

const initialWorkspaceState = {
  workspaces: {} as Record<string, workspaceDetailType>,
  current: {} as workspaceDetailType,
  error: { verifyWebhookURL: "" },
  status: {
    fetchAllWorkspaces: "",
    fetchWorkspaceInfo: "",
    verifyWebhookURL: "",
    updateWorkspaceInfo: "",
    generateManagementToken: "",
    inviteEmails: "",
    deleteMember: "",
    createWorkspace: "",
    switchWorkspace: "",
    updateDefaultTemplate: "",
    updateRoomRoleLinkFormat: "",
    acceptInviteWorkspace: {
      msg: "",
      state: "",
    },
  },
};

type workspaceStateType = typeof initialWorkspaceState;

export const workspace = handleActions<workspaceStateType, any>(
  {
    [FETCH_WORKSPACES_INIT]: state => {
      const newState = { ...state };
      newState.status.fetchAllWorkspaces = API_CALL_STATE.IN_PROGRESS;
      return newState;
    },
    [FETCH_WORKSPACES_DONE]: (state, action) => {
      const newState = { ...state };
      newState.status.fetchAllWorkspaces = API_CALL_STATE.DONE;
      newState.workspaces = action.payload;
      return newState;
    },
    [FETCH_WORKSPACES_FAIL]: state => {
      const newState = { ...state };
      newState.status.fetchAllWorkspaces = API_CALL_STATE.FAILED;
      return newState;
    },
    [FETCH_WORKSPACES_RESET]: () => {
      const newState = {} as workspaceStateType;
      newState.status.fetchAllWorkspaces = "";
      return newState;
    },
    [FETCH_WORKSPACE_DETAILS_INIT]: state => {
      const newState = { ...state };
      newState.status.fetchWorkspaceInfo = API_CALL_STATE.IN_PROGRESS;
      return newState;
    },
    [FETCH_WORKSPACE_DETAILS_DONE]: (state, action) => {
      for (const index in action?.payload?.members) {
        const member = action?.payload?.members?.[index];
        if (member?.is_admin) {
          analytics_store.set({ workspaceOwnerEmail: member?.email });
        }
      }
      const newState = { ...state };
      const data = action.payload;
      const { members } = data;
      const membersArray = [];
      for (const member in members) {
        membersArray.push({
          id: member,
          email: members[member].email,
          name: members[member].name,
          isAdmin: members[member].is_admin,
          isOrgAdmin: members[member].is_org_admin,
          role: members[member].role,
        });
      }
      data.members = membersArray;
      newState.current = { ...newState.current, ...data };
      newState.current.Webhook = data.Webhook;
      newState.current.managementTokenExpiration = data.expiry;

      newState.current.room_code_disabled = Boolean(
        newState.workspaces[newState.current.id]?.room_code_disabled
      );

      newState.status.fetchWorkspaceInfo = API_CALL_STATE.DONE;
      return newState;
    },
    [FETCH_WORKSPACE_DETAILS_FAIL]: state => {
      const newState = { ...state };
      newState.status.fetchWorkspaceInfo = API_CALL_STATE.FAILED;
      return newState;
    },
    [UPDATE_WORKSPACE_DETAILS_INIT]: state => {
      const newState = { ...state };
      newState.status.updateWorkspaceInfo = API_CALL_STATE.IN_PROGRESS;
      return newState;
    },
    [UPDATE_WORKSPACE_DETAILS_DONE]: (state, action) => {
      const newState = { ...state };
      const data = action.payload;
      newState.current.Webhook = data.Webhook;
      newState.current = { ...newState.current, ...data.data };
      newState.status.updateWorkspaceInfo = API_CALL_STATE.DONE;
      return newState;
    },
    [UPDATE_WORKSPACE_DETAILS_FAIL]: state => {
      const newState = { ...state };
      newState.status.updateWorkspaceInfo = API_CALL_STATE.FAILED;
      return newState;
    },
    [GENERATE_JWT_TOKEN_INIT]: state => {
      const newState = { ...state };
      newState.status.generateManagementToken = API_CALL_STATE.IN_PROGRESS;
      return newState;
    },
    [GENERATE_JWT_TOKEN_DONE]: (state, action) => {
      const newState = { ...state };
      const data = action.payload;
      newState.current.management_token = data.management_token;
      newState.current.managementTokenExpiration = data.expiry;

      newState.current = { ...newState.current };
      newState.status.generateManagementToken = API_CALL_STATE.DONE;
      return newState;
    },
    [GENERATE_JWT_TOKEN_FAIL]: state => {
      const newState = { ...state };
      newState.status.generateManagementToken = API_CALL_STATE.FAILED;
      return newState;
    },
    [CREATE_WORKSPACE_INIT]: state => {
      const newState = { ...state };
      newState.status.createWorkspace = API_CALL_STATE.IN_PROGRESS;
      return newState;
    },
    [CREATE_WORKSPACE_DONE]: state => {
      const newState = { ...state };
      newState.status.createWorkspace = API_CALL_STATE.DONE;
      return newState;
    },
    [CREATE_WORKSPACE_FAIL]: state => {
      const newState = { ...state };
      newState.status.createWorkspace = API_CALL_STATE.FAILED;
      return newState;
    },
    [SWITCH_WORKSPACE_INIT]: state => {
      const newState = { ...state };
      newState.status.switchWorkspace = API_CALL_STATE.IN_PROGRESS;
      return newState;
    },
    [SWITCH_WORKSPACE_DONE]: state => {
      const newState = { ...state };
      newState.status.switchWorkspace = API_CALL_STATE.DONE;
      return newState;
    },
    [SWITCH_WORKSPACE_FAIL]: state => {
      const newState = { ...state };
      newState.status.switchWorkspace = API_CALL_STATE.FAILED;
      return newState;
    },

    [DELETE_WORKSPACE_MEMBER_INIT]: state => {
      const newState = { ...state };
      newState.status.deleteMember = API_CALL_STATE.IN_PROGRESS;
      return newState;
    },
    [DELETE_WORKSPACE_MEMBER_DONE]: (state, action) => {
      const newState = { ...state };
      const { user_id } = action.payload;
      newState.current.members = state.current.members.filter(
        item => item.id !== user_id
      );
      newState.status.deleteMember = API_CALL_STATE.DONE;
      return newState;
    },
    [DELETE_WORKSPACE_MEMBER_FAIL]: state => {
      const newState = { ...state };
      newState.status.deleteMember = API_CALL_STATE.FAILED;
      return newState;
    },
    [INVITE_TO_WORKSPACE_FAIL]: state => {
      const newState = { ...state };
      newState.status.inviteEmails = API_CALL_STATE.FAILED;
      return newState;
    },
    [INVITE_TO_WORKSPACE_INIT]: state => {
      const newState = { ...state };
      newState.status.inviteEmails = API_CALL_STATE.IN_PROGRESS;
      return newState;
    },
    [INVITE_TO_WORKSPACE_DONE]: state => {
      const newState = { ...state };
      newState.status.inviteEmails = API_CALL_STATE.DONE;
      return newState;
    },
    [ACCEPT_INVITE_TO_WORKSPACE_FAIL]: (state, action) => {
      state.status.acceptInviteWorkspace.state = API_CALL_STATE.FAILED;
      state.status.acceptInviteWorkspace.msg = action.payload;
      return { ...state };
    },
    [ACCEPT_INVITE_TO_WORKSPACE_INIT]: state => {
      state.status.acceptInviteWorkspace.state = API_CALL_STATE.IN_PROGRESS;
      return { ...state };
    },
    [ACCEPT_INVITE_TO_WORKSPACE_DONE]: state => {
      state.status.acceptInviteWorkspace.state = API_CALL_STATE.DONE;
      return { ...state };
    },
    [UPDATE_ROOM_ROLE_LINK_FORMAT_INIT]: state => {
      const newState = { ...state };
      newState.status.updateRoomRoleLinkFormat = API_CALL_STATE.IN_PROGRESS;
      return newState;
    },
    [UPDATE_ROOM_ROLE_LINK_FORMAT_DONE]: (state, action) => {
      const newState = { ...state };
      newState.status.updateRoomRoleLinkFormat = API_CALL_STATE.DONE;
      newState.current.disable_room_role_link = action.payload.disable;
      return { ...state };
    },
    [UPDATE_ROOM_ROLE_LINK_FORMAT_FAIL]: state => {
      const newState = { ...state };
      newState.status.updateRoomRoleLinkFormat = API_CALL_STATE.FAILED;
      return newState;
    },
    [UPDATE_DEFAULT_TEMPLATE_DONE]: (state, action = {} as any) => {
      const newState = { ...state };
      const { payload = {} } = action;
      const { templateId } = payload;
      newState.current.DefaultTemplateID = templateId;
      newState.status.updateDefaultTemplate = API_CALL_STATE.DONE;
      return newState;
    },
    [UPDATE_DEFAULT_TEMPLATE_INPROGRESS]: state => {
      const newState = { ...state };
      newState.status.updateDefaultTemplate = API_CALL_STATE.IN_PROGRESS;
      return newState;
    },
    [VERIFY_WEBHOOK_URL_INIT]: state => {
      const newState = { ...state };
      newState.status.verifyWebhookURL = API_CALL_STATE.IN_PROGRESS;
      return newState;
    },
    [VERIFY_WEBHOOK_URL_DONE]: state => {
      const newState = { ...state };
      newState.status.verifyWebhookURL = API_CALL_STATE.DONE;
      return newState;
    },
    [VERIFY_WEBHOOK_URL_FAIL]: (state, action) => {
      const newState = { ...state };
      newState.status.verifyWebhookURL = API_CALL_STATE.FAILED;
      newState.error.verifyWebhookURL = action.payload;
      return newState;
    },
  },
  initialWorkspaceState
);
