import { mosChartKey } from "src/modules/peerInsights/constants";
import store from "src/store";
import { setPeerInsightsChartEvents } from "src/store/analytics/actions";
type Props = { params: { name: string } };

export const echartsEventsConfig = ({ params }: Props) => {
  if (params.name === mosChartKey) {
    //@ts-ignore
    store.dispatch(setPeerInsightsChartEvents(params));
  }
};
