import { useEffect } from "react";
import queryString from "query-string";
import { createGetURL } from "src/helpers";
import { getUtmParams } from "src/utils";
import { Loading } from "@100mslive/react-ui";

function WebsiteGithubRegistration() {
  const handleGithubLogin = function () {
    const baseURL = "https://github.com/login/oauth/authorize?";
    const params = {
      client_id: process.env.REACT_APP_GITHUB_CLIENT_ID,
      scope: "read:user,user:email",
      redirect_uri: `${process.env.REACT_APP_HOST_URL}register`,
      state: encodeURIComponent(
        JSON.stringify({
          ...getUtmParams(),
          ...queryString.parse(window.location?.search),
        })
      ),
    };

    window.location.href = createGetURL(baseURL, params);
  };
  useEffect(() => {
    handleGithubLogin();
  }, []);
  return <Loading size={48} />;
}

export default WebsiteGithubRegistration;
