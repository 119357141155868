import { ReactNode, useMemo } from "react";
import { cloneDeep, get, isBoolean, isEmpty, isObject, map } from "lodash";
import { VideoQuality } from "src/constants";
import {
  pluginsTemplate,
  policyTemplate,
  roleTemplateForPolicy,
} from "src/store/policyTemplate";
import { getSimulcastConfigForVideoQuality } from "src/store/roleTemplate";
import {
  hlsDestinationsLayerType,
  hlsDestinationsType,
  RecordingConfigType,
  roleTemplatePolicy,
  rtmpDestinationsType,
  TranscriptionType,
} from "src/types/policyTypes";
import { RoleLayouts } from "src/types/prebuilt";
import { haikunateText } from "src/utils";
import { HipaaValidationError } from "./errors";
import {
  cmsDestinations,
  cmsHlsResolutionLayersType,
  cmsRoleType,
  cmsTemplateType,
  cmsWhiteboardType,
} from "../../../../types/cmsTypes";
import { policyType } from "../../../../types/policyTypes";

export const useWhiteboard = ({
  whiteboard,
}: {
  whiteboard: cmsWhiteboardType;
}) => {
  const tempWhiteboard = cloneDeep(pluginsTemplate);
  const permissions = {} as Record<string, string[]>;
  Object.keys(whiteboard).forEach(key => {
    if (key === "id") {
      return;
    }

    permissions[key] =
      whiteboard[key as keyof typeof whiteboard]?.map(
        (r: { roleName: string }) => r.roleName
      ) || [];
  });
  if (!isEmpty(permissions)) {
    return {
      whiteboard: {
        ...tempWhiteboard.whiteboard,
        permissions: {
          ...permissions,
        },
      },
    };
  } else {
    return {};
  }
};

export const createPublishSettings = (
  templateKey: string,
  cmsKey: string,
  cmsRole: Record<string, cmsRoleType>,
  result: typeof roleTemplateForPolicy
) => {
  const isCMStrue =
    isObject(cmsRole[cmsKey]) ||
    (isBoolean(cmsRole[cmsKey]) && Boolean(cmsRole[cmsKey]) === true);
  const allowed = new Set(result.publishParams.allowed);
  if (isCMStrue) {
    allowed.add(templateKey);
  } else {
    allowed.delete(templateKey);
  }
  result.publishParams.allowed = Array.from(allowed);
  return result;
};

export const convertDestinations = (destinations: any) => {
  if (isEmpty(destinations)) {
    return {};
  }

  let newDestinationsObj = policyTemplate.destinations;

  for (const destination of destinations) {
    const destinationDefaultConfig =
      newDestinationsObj?.[
        destination["destinationType"] as keyof typeof newDestinationsObj
      ]?.default || {};

    newDestinationsObj[
      destination["destinationType"] as keyof typeof newDestinationsObj
    ] = {
      default: {
        //@ts-ignore FIXME: simplify the code and types
        ...destinationDefaultConfig,
        name: "default",
        role: destination["role"],
      } as rtmpDestinationsType & hlsDestinationsType & TranscriptionType,
    };

    if (
      destination["hlsResolutionLayers"] &&
      destination["hlsResolutionLayers"].length
    ) {
      newDestinationsObj = {
        ...newDestinationsObj,
        [destination["destinationType"] as keyof typeof newDestinationsObj]: {
          default: {
            ...(
              newDestinationsObj?.[
                destination[
                  "destinationType"
                ] as keyof typeof newDestinationsObj
              ] as Record<string, hlsDestinationsType>
            )?.default,
            layers: destination["hlsResolutionLayers"].map(
              (layer: cmsHlsResolutionLayersType) =>
                ({
                  height: layer.height,
                  width: layer.width,
                  videoBitrate: layer.videoBitrate,
                  audioBitrate: layer.audioBitrate,
                } as hlsDestinationsLayerType)
            ),
          },
        },
      };
    }
  }

  // Clean up destinations which are not present in CMS entry
  const destinationKeys = destinations.map(
    (destination: cmsDestinations) => destination.destinationType
  );

  Object.keys(policyTemplate.destinations).forEach(destination => {
    if (!destinationKeys.includes(destination)) {
      // @ts-ignore
      newDestinationsObj[destination] = {};
    }
  });

  return newDestinationsObj;
};

export const convertRecordings = (cmsDestinations: any) => {
  const recordings: RecordingConfigType[] = [];
  for (const cmsDestination of cmsDestinations) {
    if (cmsDestination.destinationType !== "browserRecordings") {
      continue;
    }
    const recordingsObj: RecordingConfigType = cloneDeep(
      policyTemplate?.recordings?.[0]!
    );

    recordingsObj.name = crypto.randomUUID();
    recordingsObj.role = cmsDestination["role"];
    recordingsObj.compositeRecording = {
      ...recordingsObj.compositeRecording,
      browserComposite: {
        ...recordingsObj.compositeRecording?.browserComposite,
        enabled: true,
        autoStart: true,
      },
    };
    recordings.push(recordingsObj);
    break;
  }
  return recordings;
};

// eslint-disable-next-line complexity
export const convertRole = (role: any) => {
  const result = cloneDeep(roleTemplateForPolicy);
  result.name = role.name;
  result.priority = role.priority;
  const publishSettingKeys = [
    ["videoPermission", "video"],
    ["screensharePermission", "screen"],
    ["canShareAudio", "audio"],
  ];
  let i = 0;
  while (i < publishSettingKeys.length) {
    const tempResult = createPublishSettings(
      publishSettingKeys[i][1],
      publishSettingKeys[i][0],
      role,
      result
    );
    result.publishParams.allowed = tempResult["publishParams"]["allowed"];
    i++;
  }
  const videoAspect = role?.videoPermission?.aspectRatio
    .substring(1)
    .replace("_", ":");
  const videoQuality = role?.videoPermission?.videoQuality;
  //@ts-ignore
  if (!isEmpty(VideoQuality?.[videoQuality]?.[videoAspect])) {
    result.publishParams.video = {
      ...result.publishParams.video,
      //@ts-ignore
      ...VideoQuality?.[videoQuality]?.[videoAspect],
    };
  }
  // eslint-disable-next-line
  const simulcastConfig = getSimulcastConfigForVideoQuality({
    videoQuality: videoQuality || "_720p",
    aspectRatio: videoAspect || "4:3",
  });
  result.publishParams.simulcast = simulcastConfig;
  const screenshareQuality = role?.screensharePermission?.screenshareQuality;
  if (screenshareQuality === "_720p") {
    result.publishParams.screen = {
      ...result.publishParams.screen,
      bitRate: 1000,
      codec: "vp8",
      frameRate: 10,
      height: 720,
      width: 1280,
    };
  } else if (screenshareQuality === "_1080p") {
    result.publishParams.screen = {
      ...result.publishParams.screen,
      codec: "vp8",
      frameRate: 10,
      height: 1080,
      width: 1920,
    };
  }
  result.subscribeParams.subscribeToRoles = map(role.subscribeTo, "roleName");
  if (!role.subscribeDegradation) {
    result.subscribeParams.subscribeDegradation = {};
  }

  result.permissions = {
    ...result.permissions,
    mute: role.canMute,
    unmute: role.canUnmute,
    removeOthers: role.canRemoveOthers,
    endRoom: role.canEndRoom,
    changeRole: role.canChangeRole,
    sendRoomState: role.sendRoomState,
    rtmpStreaming: role.rtmpStreaming ?? true,
    hlsStreaming: role.hlsStreaming ?? true,
    browserRecording: role.browserRecording ?? true,
    pollRead: role.pollRead ?? true,
    pollWrite: role.pollWrite ?? true,
  };

  return result;
};

export const convertRoles = (roles: any) => {
  return roles.map((role: any) => convertRole(role));
};

export function useConvertRole({ cmsRoles = [] }: { cmsRoles: cmsRoleType[] }) {
  const roles = useMemo(() => convertRoles(cmsRoles), [cmsRoles]);
  return [roles];
}
export const LIVE_STREAM_DESTINATIONS_VIDEO_ON_DEMAND = {
  vod: "Video on Demand",
  postProcess: "Post-processing",
  both: "Both",
};

export const BINARY_OPTIONS = {
  yes: "Yes",
  no: "No",
};

export const LIVE_STREAM_RTMP_INGEST_OPTIONS = {
  internal: "Built-in webcam",
  external: "Streaming software (using RTMP-Ingestion)",
};

export const DESTINATIONS_BROADCASTER_ASPECT_RATIO = {
  desktop: {
    "16:9": "Desktop (16:9)",
  },
  mobile: {
    "9:16": "Mobile (Portrait 9:16)",
  },
};

export const VIEWER_ON_STAGE_OPTIONS = {
  Yes: "Yes",
  No: "No",
};

export const DESTINATIONS_RECORDINGS_START_OPTIONS = {
  Manual: "Manual",
  AutoStart: "Auto-start",
  NoRecording: "No Recording",
};

export const insertTimedMetadataForPolls = (policy: policyType) => {
  const newPolicy = cloneDeep(policy);
  const { destinations } = newPolicy;
  if (!isEmpty(destinations?.hlsDestinations)) {
    const hasPolls = Object.keys(newPolicy?.roles).some(
      (r: string) =>
        newPolicy?.roles?.[r]?.permissions?.pollRead ||
        newPolicy?.roles?.[r]?.permissions?.pollWrite
    );
    const hlsDestinationIds = Object.keys(destinations?.hlsDestinations);
    hlsDestinationIds.forEach((id: string) => {
      if (hasPolls && newPolicy?.destinations?.hlsDestinations?.[id]) {
        newPolicy.destinations.hlsDestinations[id].enableMetadataInsertion =
          true;
      }
    });
  }
  return newPolicy;
};

export function useConvertDestinations({
  cmsDestinations = [],
}: {
  cmsDestinations: unknown[];
}) {
  const newDestinations: policyType["destinations"] = useMemo(
    () => convertDestinations(cmsDestinations),
    [cmsDestinations]
  );
  return [newDestinations];
}

export function useConvertRecordings({
  cmsDestinations,
}: {
  cmsDestinations: any;
}) {
  const newRecordings: policyType["recordings"] = useMemo(
    () => convertRecordings(cmsDestinations),
    [cmsDestinations]
  );
  return newRecordings;
}
export const createAbbFromTemplate = (templateName: string) => {
  const name = templateName.split(" ");
  let createName = "";
  if (name.length > 1) {
    createName = `${name[0][0]}${name[1][0]}`.toUpperCase();
  } else {
    if (templateName === "Custom") {
      createName = "CS";
    } else {
      createName = `${templateName[0]}${templateName[1]}`.toUpperCase();
    }
  }
  return createName;
};

export const createTemplateName = (
  templateName: string,
  isOnboarding: boolean,
  isAbbr = false
) => {
  if (!isAbbr) {
    if (!templateName) {
      return "";
    }
    const name = createAbbFromTemplate(templateName);
    return haikunateText(name, isOnboarding ? 6 : 0, "-");
  } else {
    if (!templateName) {
      return "";
    }
    return haikunateText(templateName, isOnboarding ? 6 : 0, "-");
  }
};

export function getPublicTemplateFromFilter({
  filterKey = "id",
  filterValue = 0,
  publicTemplates = [],
}: {
  filterKey: string;
  filterValue: ReactNode;
  publicTemplates: cmsTemplateType[];
}) {
  return (
    {
      ...publicTemplates.find(
        (template: cmsTemplateType) => get(template, filterKey) === filterValue
      ),
    } || {
      policyType: "",
    }
  );
}

export function getImportedTemplate({
  jsonPolicy,
  publicTemplates,
}: {
  jsonPolicy: Record<string, any>;
  publicTemplates: cmsTemplateType[];
}) {
  const cmsTemplate = getPublicTemplateFromFilter({
    filterKey: "id",
    filterValue: jsonPolicy?.cms_id,
    publicTemplates,
  });

  const cmsTemplateName = cmsTemplate?.attributes?.name || "Custom";
  const templateName = createTemplateName(cmsTemplateName, true);
  const roles = {} as Record<string, roleTemplatePolicy>;
  const role_layouts = {} as RoleLayouts;
  if (isEmpty(jsonPolicy?.settings?.recording)) {
    delete jsonPolicy?.settings?.recording;
  }
  for (const role in jsonPolicy?.roles) {
    roles[role] = {
      ...jsonPolicy?.roles[role],
      role_id: "",
      permissions: {
        ...jsonPolicy?.roles[role]?.permissions,
      },
    };
  }
  if (!isEmpty(jsonPolicy?.role_layouts)) {
    for (const role in jsonPolicy?.role_layouts) {
      role_layouts[role] = {
        ...jsonPolicy?.role_layouts[role],
        id: "",
        app_id: "",
        role_id: "",
        template_id: "",
      };
    }
  }
  return {
    roles: roles,
    settings: jsonPolicy?.settings,
    destinations: jsonPolicy?.destinations,
    recordings: jsonPolicy?.recordings || [],
    role_layouts: role_layouts || {},
    name: templateName,
    region: jsonPolicy?.region,
    app_type: jsonPolicy?.app_type,
    ts_template_id: cmsTemplate?.id,
    plugins: jsonPolicy?.plugins,
  };
}

// eslint-disable-next-line complexity
export const checkHipaaCompliantTemplate = (template: any) => {
  // The following check is for those case where closed caption is enabled,
  // so we should not throw error because closed caption is enabled for hippa

  if (!isEmpty(template?.destinations || {})) {
    if (
      !isEmpty(template?.destinations?.hlsDestinations || {}) ||
      !isEmpty(template?.destinations?.rtmpDestinations || {})
    ) {
      throw new HipaaValidationError(
        `Template having ${
          !isEmpty(template?.destinations?.hlsDestinations || {})
            ? "HLS"
            : !isEmpty(template?.destinations?.rtmpDestinations || {})
            ? "RTMP"
            : "transcription"
        } enabled can not be imported in HIPAA compliant workspace`
      );
    }
  }
};
