import React from "react";
import { isEmpty } from "lodash";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { convertSecondsToHumanReadable } from "src/helpers/utils";
import { peerAnalyticsPublishType } from "src/types/analytics";
import { policyTemplateType } from "src/types/policyTypes";
import { InfoIcon } from "@100mslive/react-icons";
import { Flex, Text, Tooltip } from "@100mslive/react-ui";
import EmptyStateForPeerCharts from "../EmptyStateForPeerCharts";

type Props = {
  policy: policyTemplateType;
  role: string;
  publishData: peerAnalyticsPublishType;
};

function TrackMessage({
  text,
  val,
  tooltip = "",
}: {
  text: string;
  val: string;
  tooltip?: string;
}) {
  return (
    <Flex
      css={{
        p: "$8 $10",
      }}
      justify="start"
      direction="column"
    >
      <Flex align="center" css={{ gap: "$2" }}>
        <Text variant="caption" css={{ c: "$textDisabled" }}>
          {text}
        </Text>
        {tooltip ? (
          <Tooltip
            title={tooltip}
            side="top"
            align="center"
            boxCss={{ r: "$0", w: "$80" }}
          >
            <Flex css={{ c: "$textDisabled" }}>
              <SmallIcon>
                <InfoIcon />
              </SmallIcon>
            </Flex>
          </Tooltip>
        ) : (
          <></>
        )}
      </Flex>
      <Text
        variant="body2"
        css={{ c: "$textMedEmp", mt: "$2", fontWeight: "$regular" }}
      >
        {val}
      </Text>
    </Flex>
  );
}
/* eslint-disable-next-line complexity */
export default function PublisherQualityLimitationMetrics({
  publishData,
}: Props) {
  const mergedVideoLayers = (
    videoLayers: {
      timestamp: string;
      quality_limitation: {
        cpu: number;
        network: number;
      };
    }[][]
  ) => {
    if (isEmpty(videoLayers)) {
      return [];
    }
    const result = [];
    for (let i = 0; i < videoLayers[0].length; i++) {
      //calculate max on the same index across all the arrays inside videoLayers
      let maxCpu = 0;
      let maxNetwork = 0;
      for (let j = 0; j < videoLayers.length; j++) {
        if (
          videoLayers?.[j]?.[i] !== undefined &&
          videoLayers?.[j]?.[i] !== null
        ) {
          maxCpu = Math.max(maxCpu, videoLayers[j][i].quality_limitation.cpu);
          maxNetwork = Math.max(
            maxNetwork,
            videoLayers[j][i].quality_limitation.network
          );
        }
      }
      result.push({
        timestamp: videoLayers[0][i].timestamp,
        quality_limitation: {
          cpu: maxCpu,
          network: maxNetwork,
        },
      });
    }
    return result;
  };
  const videoLayer = mergedVideoLayers(
    (Object.values(publishData?.video || {}) || []) as {
      timestamp: string;
      quality_limitation: {
        cpu: number;
        network: number;
      };
    }[][]
  );
  const screenLayer = (publishData?.screen || []) as {
    timestamp: string;
    quality_limitation: {
      cpu: number;
      network: number;
    };
  }[];

  // eslint-disable-next-line complexity
  const mergeVideoAndScreenlayer = (
    screenLayer: {
      timestamp: string;
      quality_limitation: {
        cpu: number;
        network: number;
      };
    }[],
    videoLayer: {
      timestamp: string;
      quality_limitation: {
        cpu: number;
        network: number;
      };
    }[]
  ) => {
    if (isEmpty(screenLayer) && isEmpty(videoLayer)) {
      return [];
    }
    if (isEmpty(screenLayer) || !screenLayer) {
      return videoLayer;
    }
    if (isEmpty(videoLayer) || !videoLayer) {
      return screenLayer;
    }
    const result = [] as {
      timestamp: string;
      quality_limitation: {
        cpu: number;
        network: number;
      };
    }[];
    let screenLayerObj = {} as Record<string, { cpu: number; network: number }>;
    let videoLayerObj = {} as Record<string, { cpu: number; network: number }>;
    // merge the screen and video layer to get the max limitation across all the layers using there timestamps
    screenLayerObj = screenLayer.reduce((acc, curr) => {
      acc[curr.timestamp as keyof typeof acc] = curr.quality_limitation;
      return acc;
    }, {} as Record<string, { cpu: number; network: number }>);

    videoLayerObj = videoLayer.reduce((acc, curr) => {
      acc[curr.timestamp] = curr.quality_limitation;
      return acc;
    }, {} as Record<string, { cpu: number; network: number }>);
    const mergedObj = { ...screenLayerObj, ...videoLayerObj };
    const keys = Object.keys(mergedObj);
    for (let i = 0; i < keys.length; i++) {
      const tempObj = {
        cpu: Math.max(
          screenLayerObj?.[keys[i] as keyof typeof screenLayerObj]?.cpu || 0,
          videoLayerObj?.[keys[i]]?.cpu || 0
        ),
        network: Math.max(
          screenLayerObj?.[keys[i] as keyof typeof screenLayerObj]?.network ||
            0,
          videoLayerObj?.[keys[i]]?.network || 0
        ),
      };
      result.push({
        timestamp: keys[i],
        quality_limitation: tempObj,
      });
    }
    return result;
  };
  // eslint-disable-next-line complexity
  const calulateTracksLimitations = (
    data: {
      timestamp: string;
      quality_limitation: {
        cpu: number;
        network: number;
      };
    }[]
  ) => {
    if (isEmpty(data)) {
      return { cpu: 0, network: 0 };
    }
    let totalDuration = 0;
    let totalCpu = 0;
    let totalNetwork = 0;

    for (let i = 0; i < data.length - 1; i++) {
      const duration =
        (new Date(data[i + 1].timestamp).getTime() -
          new Date(data[i].timestamp).getTime()) /
        1000;
      if (
        Number.isFinite(data[i].quality_limitation.cpu) &&
        data[i].quality_limitation.cpu > 0
      ) {
        totalCpu += (duration * data[i].quality_limitation.cpu) / 1000;
      }
      if (
        Number.isFinite(data[i].quality_limitation.network) &&
        data[i].quality_limitation.network > 0
      ) {
        totalNetwork += (duration * data[i].quality_limitation.network) / 1000;
      }
      totalDuration += duration;
    }
    if (totalDuration === 0) {
      return {
        cpu: Number.isFinite(data?.[0]?.quality_limitation.cpu)
          ? data?.[0]?.quality_limitation.cpu
          : 0,
        network: Number.isFinite(data?.[0]?.quality_limitation.network)
          ? data?.[0]?.quality_limitation.network
          : 0,
      };
    }

    return { cpu: totalCpu, network: totalNetwork };
  };

  const totalLimitation = calulateTracksLimitations(
    mergeVideoAndScreenlayer(screenLayer, videoLayer)
  );
  return (
    <Flex
      css={{
        borderTop: "$space$px solid $borderDefault",
        borderLeft: "$space$px solid $borderDefault",
        w: "30%",
        h: "100%",
      }}
      direction="column"
    >
      {totalLimitation.cpu || totalLimitation.network ? (
        <>
          {!totalLimitation.cpu ? (
            <></>
          ) : (
            <TrackMessage
              text="CPU limited for"
              val={convertSecondsToHumanReadable(totalLimitation.cpu)}
            />
          )}
          {!totalLimitation.network ? (
            <></>
          ) : (
            <TrackMessage
              text="Network limited for"
              val={convertSecondsToHumanReadable(totalLimitation.network)}
            />
          )}
        </>
      ) : (
        <EmptyStateForPeerCharts
          css={{ minHeight: "300px" }}
          title="Quality Limitation"
          subtitle="This peer had no network or CPU limitations throughout the session."
        />
      )}
    </Flex>
  );
}
