import { isEmpty } from "lodash";
import { templatePluginTypes } from "src/types/policyTypes";
import { pluginsInvalidFieldsType } from "src/types/validations/destinations";
// eslint-disable-next-line complexity
export const validatePlugins = (plugins: templatePluginTypes) => {
  let isValid = true;
  const invalidFields = {} as pluginsInvalidFieldsType;

  if (!isEmpty(plugins?.whiteboard?.permissions)) {
    //check if admin is empty
    //check if writer is empty
    //check if reader is empty

    if (
      plugins?.whiteboard?.permissions?.admin &&
      plugins?.whiteboard?.permissions?.admin?.length < 1
    ) {
      isValid = false;
      invalidFields.whiteboardAdmin = "At least one role should be selected.";
    }
    if (
      plugins?.whiteboard?.permissions?.writer &&
      plugins?.whiteboard?.permissions?.writer?.length < 1
    ) {
      isValid = false;
      invalidFields.whiteboardWriter = "At least one role should be selected.";
    }
  }
  if (plugins?.transcriptions) {
    if (
      plugins?.transcriptions.some(
        plugin =>
          plugin.permissions?.admin && plugin.permissions?.admin.length === 0
      )
    ) {
      invalidFields.transcriptions = "At least one role should be selected.";
    }
  }
  return { isValid, invalidFields };
};
