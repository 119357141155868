import React from "react";
import { cloneDeep, isEmpty } from "lodash";
import PrebuiltSidebarItemWrapper from "src/pages/Prebuilt/PrebuiltSidebarItemWrapper";
import { policyType } from "src/types/policyTypes";
import { RoleLayouts } from "src/types/prebuilt";
import { Box, Flex, Switch, Text, Tooltip } from "@100mslive/react-ui";

type Props = {
  policyInfo: policyType;
  roleLayouts: RoleLayouts;
  role: string;
  setRoleLayouts: ({ roleLayouts }: { roleLayouts: RoleLayouts }) => void;
};

const PrebuiltNoiseCancellation = ({
  policyInfo,
  roleLayouts,
  role,
  setRoleLayouts,
}: Props) => {
  const enabledInPolicy =
    policyInfo?.plugins?.noiseCancellation?.enabled || false;

  const enabledInLayout =
    roleLayouts[role]?.screens?.preview?.default?.elements?.noise_cancellation
      ?.enabled_by_default || false;

  const noiseCancellationSwitchHandler = () => {
    const layout = cloneDeep(roleLayouts[role]);

    // toggle in preview screen
    if (
      layout.screens?.preview?.default &&
      !isEmpty(layout?.screens?.preview?.default)
    ) {
      layout.screens.preview.default = {
        ...layout.screens.preview.default,
        elements: {
          ...layout.screens.preview.default?.elements,
          noise_cancellation: {
            enabled_by_default: !enabledInLayout,
          },
        },
      };
    }

    // toggle in conferencing screen
    if (
      layout.screens?.conferencing?.default &&
      !isEmpty(layout?.screens?.conferencing?.default)
    ) {
      layout.screens.conferencing.default = {
        ...layout.screens.conferencing.default,
        elements: {
          ...layout.screens.conferencing.default?.elements,
          noise_cancellation: {
            enabled_by_default: !enabledInLayout,
          },
        },
      };
    }

    setRoleLayouts({
      roleLayouts: {
        ...roleLayouts,
        [role]: layout,
      },
    });
  };

  return (
    <Flex justify="between" align="center" css={{ mt: "$10" }}>
      <PrebuiltSidebarItemWrapper
        css={{ my: 0 }}
        isSwitchTitle={true}
        title="Noise Cancellation State"
        titleVariant="sm"
        tooltipText={
          <Text variant="caption" css={{ color: "$textHighEmp" }}>
            When enabled from Advanced Settings under Template, define the
            initial state for Noise cancellation on Prebuilt Peers can toggle it
            ON/OFF as per their preferences.
          </Text>
        }
      />
      <Tooltip
        disabled={enabledInPolicy}
        title="Noise cancellation should be enabled from 'Advanced Settings' under the Template to enable this setting."
        side="right"
        align="center"
        boxCss={{ maxWidth: "40%" }}
      >
        <Box>
          <Switch
            disabled={!enabledInPolicy}
            checked={enabledInPolicy && enabledInLayout}
            onCheckedChange={noiseCancellationSwitchHandler}
          />
        </Box>
      </Tooltip>
    </Flex>
  );
};

export default PrebuiltNoiseCancellation;
