import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { registerUserFailed, registerUserSuccessful } from "./actions";
//Account Redux states
import { REGISTER_USER } from "./actionTypes";
//Include Both Helper File with needed methods
import { postRegister } from "../../../helpers/backend_helper";

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    console.error("registerUser");

    const response = yield call(postRegister, "register", user);
    if (response.success) {
      //eslint-disable-next-line no-console
      console.log("success");
      yield put(registerUserSuccessful(user));
      localStorage.setItem("user", JSON.stringify(response));
      //eslint-disable-next-line  no-console
      console.log("session stored");
    } else {
      yield put(registerUserFailed(response.msg));
    }
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
