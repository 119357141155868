import { peerMOSScoreKeyValueTypes } from "src/types/analytics";

type mergedMOSreturn = {
  [key: string]: string;
};

const mosKeys = ["subscriber_bitrate", "publisher_bitrate", "subscriber_mos"];

export const getMergedMOS = (mosScoreData: peerMOSScoreKeyValueTypes) => {
  const mosScore = mosScoreData?.mos_score || [];
  const mosIssue: mergedMOSreturn = {};
  for (let key = 0; key < mosScore.length; key++) {
    const bitrate_data = {} as Record<string, number | undefined>;
    mosKeys.forEach(x => {
      bitrate_data[x as keyof typeof bitrate_data] =
        mosScoreData?.[x as keyof typeof mosScoreData]?.[key]?.value[1] !== "-"
          ? parseFloat(
              mosScoreData?.[x as keyof typeof mosScoreData]?.[key]
                ?.value[1] as string
            )
          : undefined;
    });

    const mosIssueKey = getMosIssueKey({
      pub_bit: bitrate_data["publisher_bitrate"],
      sub_bit: bitrate_data["subscriber_bitrate"],
      sub_mos: bitrate_data["subscriber_mos"],
    });

    if (mosIssueKey) {
      mosIssue[mosScore[key].value[0]] = mosIssueKey;
    }
  }
  return mosIssue;
};

// eslint-disable-next-line complexity
const getMosIssueKey = ({
  pub_bit,
  sub_bit,
  sub_mos,
}: {
  pub_bit?: number;
  sub_bit?: number;
  sub_mos?: number;
}) => {
  let minMos = -2;

  // find which value exists and is the lowest in the three values
  if (sub_mos && sub_bit && pub_bit) {
    minMos = Math.min(sub_mos, sub_bit, pub_bit);
  } else if (sub_mos && sub_bit) {
    minMos = Math.min(sub_mos, sub_bit);
  } else if (sub_mos && pub_bit) {
    minMos = Math.min(sub_mos, pub_bit);
  } else if (sub_bit && pub_bit) {
    minMos = Math.min(sub_bit, pub_bit);
  } else if (sub_mos) {
    minMos = sub_mos;
  } else if (sub_bit) {
    minMos = sub_bit;
  } else if (pub_bit) {
    minMos = pub_bit;
  }

  if (minMos < 3.5 && minMos >= 0) {
    if (minMos === sub_mos || minMos === sub_bit) {
      return "Subscriber Network Issues";
    }
    if (minMos === pub_bit) {
      return "Publisher Network Issues";
    }
  }
  return "";

  // find the key for the lowest value
};
