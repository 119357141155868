import echarts from "echarts";
import { isArray, isEmpty } from "lodash";

type echartsSeries = echarts.LineSeriesOption & { data: echartsSeriesData[] };
type echartsSeriesData = {
  value: [number, string];
  symbol: () => string | string;
  symbolSize: number;
  message?: string;
  timestamp?: string;
};
type Event = {
  message: string;
  timestamp: string;
  type: string;
};

const getPRSETypeSymbol = (type: string) => {
  const PRSETypeSymbolMap = {
    PEER_JOIN: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Peer_Join_d90c81f49b/Peer_Join_d90c81f49b.svg?updated_at=2024-03-06T14:30:46.702Z`,
    PEER_LEFT: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Peer_Left_cf08f8b761/Peer_Left_cf08f8b761.svg?updated_at=2024-03-06T14:30:47.068Z`,
    STREAM_START: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Streaming_Start_525c57da57/Streaming_Start_525c57da57.svg?updated_at=2024-03-06T14:30:47.060Z`,
    STREAM_STOP: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Streaming_Stop_208732745f/Streaming_Stop_208732745f.svg?updated_at=2024-03-06T14:30:46.698Z`,
    RECORDING_START: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Recording_Start_c5fc8d2ff7/Recording_Start_c5fc8d2ff7.svg?updated_at=2024-03-06T14:30:47.044Z`,
    RECORDING_STOP: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Recording_Stop_ad4ebf7a45/Recording_Stop_ad4ebf7a45.svg?updated_at=2024-03-06T14:30:47.809Z`,
    ERROR: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/error_dd2a983a32/error_dd2a983a32.svg?updated_at=2024-03-06T14:30:47.794Z`,
  };

  return PRSETypeSymbolMap?.[type as keyof typeof PRSETypeSymbolMap] || "";
};

export function convertFormatToEventLogs(
  data:
    | {
        peer: { message: string; timestamp: string; type: string }[];
        recording: { message: string; timestamp: string; type: string }[];
        streaming: { message: string; timestamp: string; type: string }[];
        errors: { message: string; timestamp: string; type: string }[];
      }
    | {}
): [] {
  if (isEmpty(data)) {
    return [];
  }
  const series = [] as echartsSeries[];
  let dummyStartTimestamp = "";
  let dummyEndTimestamp = "";
  //Process events
  Object.keys(data)
    .sort((a, b) => sortSeriesbyKey(a, b))
    .forEach(key => {
      let tempData;

      if (isArray(data?.[key as keyof typeof data])) {
        tempData = (data?.[key as keyof typeof data] as Event[])?.map(event => {
          if (["dummy_start", "dummy_end"].includes(event.message)) {
            if (event.message === "dummy_start") {
              dummyStartTimestamp = event.timestamp;
            } else if (event.message === "dummy_end") {
              dummyEndTimestamp = event.timestamp;
            }
            return {
              symbolSize: 0,
              symbol: "none",
              value: [new Date(event.timestamp).getTime(), key],
            };
          }

          const symbol = getPRSETypeSymbol(event.type);

          const symbolOffset = [0, 0];

          if (
            Math.floor(
              (Math.abs(
                new Date(dummyStartTimestamp).getTime() -
                  new Date(event.timestamp).getTime()
              ) /
                1000) %
                60
            ) === 0
          ) {
            symbolOffset[0] = 8;
          } else if (
            Math.floor(
              (Math.abs(
                new Date(dummyEndTimestamp).getTime() -
                  new Date(event.timestamp).getTime()
              ) /
                1000) %
                60
            ) === 0
          ) {
            symbolOffset[0] = -8;
          }
          return {
            value: [new Date(event.timestamp).getTime(), key],
            symbol: symbol,
            symbolSize: 16,
            message: event.message,
            timestamp: event.timestamp,
            symbolOffset: symbolOffset,
          };
        });
      }

      series.push({
        //@ts-ignore
        data: tempData,
        lineStyle: {
          width: 16,
          color: key === "errors" ? "rgba(203, 82, 94, 0.2)" : "#1E232A",
        },
        type: "line",
        name: key,
      });
    });

  //@ts-ignore
  return series;
}

const sortSeriesbyKey = (a: string, b: string) => {
  const order = ["peer", "recording", "streaming", "errors"];
  return order.indexOf(b) - order.indexOf(a);
};
