import React from "react";
import { VirtualBackgroundMedia } from "@100mslive/types-prebuilt/elements/virtual_background";
import Credential from "src/components/Common/Credential";
import { isValidImageUrl } from "src/utils";
import { CheckIcon, CrossIcon, LinkIcon } from "@100mslive/react-icons";
import { Box, Flex } from "@100mslive/react-ui";

type PrebuiltBackgroundMediaPreviewProps = {
  media: VirtualBackgroundMedia;
  index: number;
  setMedia: (index: number, url: string, isDefault: boolean) => void;
  removeMedia: (index: number) => void;
  setinvalidErrorMessage: (message: string) => void;
};

function PrebuiltBackgroundMediaPreview({
  media,
  index,
  setMedia,
  removeMedia,
  setinvalidErrorMessage,
}: PrebuiltBackgroundMediaPreviewProps) {
  const credentialsCSS = {
    css: {
      flexDirection: "column",
      maxWidth: "400px",
      mr: "$px",
    },
    inputCss: {
      border: "1px solid $borderDefault",
      bg: "$surfaceDefault",
      r: "$0",
      mr: "$px",
      maxHeight: "$14",
    },
  };

  return (
    <Flex css={{ my: "$4", alignContent: "center" }} justify="between">
      <Flex
        onClick={() => {
          if (isValidImageUrl(media?.url)) {
            setMedia(index, media?.url || "", !media.default);
          }
        }}
        css={{
          minHeight: "$14",
          minWidth: "$14",
          maxHeight: "$14",
          maxWidth: "$14",
          bg: "$surfaceDefault",
          borderRadius: "5px",
          mr: "$2",
          position: "relative",
          cursor: "pointer",
        }}
      >
        {isValidImageUrl(media?.url) ? (
          <img
            alt=""
            src={media.url}
            style={{
              maxHeight: "40px",
              maxWidth: "40px",
              minHeight: "40px",
              minWidth: "40px",
              justifyContent: "center",
              alignItems: "center",
              border: media.default ? "2px solid #2672ED" : "",
              borderRadius: "5px",
              objectFit: "cover",
            }}
          />
        ) : (
          <Flex
            css={{
              border: media.default
                ? "2px solid #2672ED"
                : "1px solid #ffffff33",
              borderRadius: "5px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              color: "$textDisabled",
            }}
          >
            <LinkIcon
              style={{
                minHeight: "24px",
                minWidth: "24px",
              }}
            />
          </Flex>
        )}
        {media.default ? (
          <Box
            css={{
              maxHeight: "$7",
              maxWidth: "$7",
              minHeight: "$7",
              minWidth: "$7",
              right: "0",
              bottom: "0",
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              borderTopLeftRadius: "$0",
              borderBottomRightRadius: "$0",
              background: "$primaryDefault",
            }}
          >
            <CheckIcon
              style={{
                maxHeight: "10px",
                maxWidth: "10px",
                right: "2px",
                bottom: "2px",
                position: "absolute",
                cursor: "pointer",
              }}
            />
          </Box>
        ) : (
          <></>
        )}
      </Flex>

      <Credential
        autoFocus={!media?.url?.length}
        label=""
        {...credentialsCSS}
        readOnly={true}
        showClipboard={false}
        placeholder="Enter Image URL here"
        onChangeHandler={val => {
          setMedia(index, val, !!media?.default);
          setinvalidErrorMessage("");
        }}
        hideLabel
        value={media?.url || ""}
        error={
          !isValidImageUrl(media?.url) ? "Please enter a valid Image URL" : ""
        }
        rightComp={
          <Flex
            onClick={() => {
              removeMedia(index);
              setinvalidErrorMessage("");
            }}
          >
            <CrossIcon
              style={{
                cursor: "pointer",
              }}
            />
          </Flex>
        }
      />
    </Flex>
  );
}

export default PrebuiltBackgroundMediaPreview;
