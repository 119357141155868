import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { fetchPublicTemplates } from "src/actions";
import {
  createAbbFromTemplate,
  createTemplateName,
  insertTimedMetadataForPolls,
  useConvertDestinations,
  useConvertRecordings,
  useConvertRole,
  useWhiteboard,
} from "src/services/cmsModels/Policy/helpers";
import {
  configureLayoutConfig as defaultConfigureLayoutConfig,
  selectAddOnConfig as defaultSelectAddOnConfig,
} from "src/services/cmsModels/Policy/helpers/useCaseConfig";
import policyTemplate from "src/store/policyTemplate";
import { RootState } from "src/store/reducers";
import {
  cmsDestinations,
  cmsRoleType,
  cmsTemplateType,
  cmsWhiteboardType,
} from "src/types/cmsTypes";
import { roleTemplatePolicy, RoomStateType } from "src/types/policyTypes";
import { addonConfig } from "../types/form";

let templateName = "";
const policy: typeof policyTemplate = { ...policyTemplate };

// eslint-disable-next-line complexity
export default function useOnboarding() {
  const dispatch = useDispatch();
  const templateId = useSelector((state: RootState) => state.createTemplate.id);
  const publicTemplates = useSelector(
    (state: RootState) => state.createApp.publicTemplates
  );
  const tempPolicy = policy;
  let config = [] as addonConfig[];
  let addOns = [] as addonConfig[];

  const configureLayoutConfig = {
    ...defaultConfigureLayoutConfig,
  };

  const selectAddOnConfig = {
    ...defaultSelectAddOnConfig,
  };

  useEffect(() => {
    if (isEmpty(publicTemplates)) {
      dispatch(fetchPublicTemplates());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (templateId === null) {
      templateName = "";
    }
  }, [templateId]);

  let cmsTemplate = {} as cmsTemplateType;
  if (templateId) {
    cmsTemplate = publicTemplates.filter(
      (template: any) => template.id === templateId
    )[0];
  }

  const cmsTemplateName = cmsTemplate?.attributes?.name || "Custom";

  if (
    cmsTemplate &&
    !templateName.includes(createAbbFromTemplate(cmsTemplateName))
  ) {
    templateName = createTemplateName(cmsTemplateName, true);
  } else if (!templateName) {
    templateName = createTemplateName(cmsTemplateName, true);
  }

  if (cmsTemplate?.attributes?.type) {
    if (
      !isEmpty(
        configureLayoutConfig[
          cmsTemplate?.attributes?.type as keyof typeof configureLayoutConfig
        ]
      )
    ) {
      config = [
        ...configureLayoutConfig[
          cmsTemplate?.attributes?.type as keyof typeof configureLayoutConfig
        ],
      ];
    }
    if (
      !isEmpty(
        selectAddOnConfig[
          cmsTemplate?.attributes?.type as keyof typeof selectAddOnConfig
        ]
      )
    ) {
      addOns = [
        ...selectAddOnConfig[
          cmsTemplate?.attributes?.type as keyof typeof selectAddOnConfig
        ],
      ];
    }
  }
  const cmsWhiteboard =
    cmsTemplate?.attributes?.whiteboard || ({} as cmsWhiteboardType);

  const cmsSettings = cmsTemplate?.attributes?.settings || {};
  const cmsRoles = cmsTemplate?.attributes?.roles || ([] as cmsRoleType[]);
  const cmsDestinations =
    cmsTemplate?.attributes?.destinations || ([] as cmsDestinations[]);
  const whiteboard = useWhiteboard({ whiteboard: cmsWhiteboard });
  const [policyroles] = useConvertRole({ cmsRoles });
  const [destinations] = useConvertDestinations({ cmsDestinations });
  const recordings = useConvertRecordings({ cmsDestinations });
  const roles: Record<string, roleTemplatePolicy> = {};
  tempPolicy.name = templateName;
  tempPolicy.roles = {};
  if (cmsRoles.length) {
    for (let i = 0; i < policyroles.length; i++) {
      roles[policyroles[i].name] = policyroles[i];
    }
    tempPolicy.roles = roles;
  }
  if (!isEmpty(cmsSettings)) {
    tempPolicy.settings = {
      ...tempPolicy.settings,
      roomState: {
        ...tempPolicy.settings.roomState,
        ...(cmsSettings as RoomStateType),
      },
    };
  }
  if (!isEmpty(destinations)) {
    tempPolicy.destinations = {
      ...destinations,
    };
    if (!isEmpty(tempPolicy.destinations.hlsDestinations)) {
      const updatedPolicy = insertTimedMetadataForPolls(tempPolicy);
      tempPolicy.destinations = { ...updatedPolicy.destinations };
    }
  } else {
    tempPolicy.destinations = {};
  }

  tempPolicy.recordings = recordings;

  if (!isEmpty(whiteboard)) {
    tempPolicy.plugins = {
      ...whiteboard,
    };
  }
  return {
    config,
    addOns,
    policy: tempPolicy,
    roleLayout: cmsTemplate?.attributes?.layout?.roleLayouts,
  };
}
