import { roleType, RoomStateType } from "src/types/policyTypes";

export const validateRoomState = (
  roomStateInfo: RoomStateType | undefined,
  roles: roleType[]
) => {
  let isValid = true;
  const invalidFields: Record<string, unknown> = {};
  // validate messageInterval
  if (
    roomStateInfo &&
    (roomStateInfo?.messageInterval < 5 ||
      roomStateInfo?.messageInterval > 3600 ||
      roomStateInfo?.messageInterval % 5 !== 0)
  ) {
    isValid = false;
    invalidFields["messageInterval"] =
      "Value must be a multiple of 5, between 5 and 3600.";
  }

  const roomStateRoles = roles.filter(
    (role: roleType) => role?.permissions?.sendRoomState
  );
  if (roomStateInfo?.enabled && roomStateRoles.length === 0) {
    invalidFields["rolesWarning"] =
      "You have not selected any role which can receive room-state.";
  }
  return { isValid, invalidFields };
};
