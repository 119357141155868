import React from "react";
import { AppAnalytics } from "src/helpers";
import { CrossIcon, InfoIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, Text } from "@100mslive/react-ui";

// eslint-disable-next-line complexity
function InfoCard({
  open,
  setOpen,
  title = "",
  body,
  fullWidth = false,
  btnText = "Read Guide",
  componentId,
  link,
  hideCross = false,
}) {
  const eventValue = title.replace(/ /g, ".").toLowerCase().replace("?", "");
  if ((!body && !link) || !open) {
    return null;
  }
  return (
    <Flex
      align="center"
      css={{
        visibility: open ? "initial" : "hidden",
        border: "$space$px solid $borderLight",
        bg: "$surfaceDefault",
        p: "$8",
        r: "$1",
        w: "100%",
      }}
    >
      <Flex
        align={fullWidth ? "center" : "start"}
        justify="between"
        direction={fullWidth ? "row" : "column"}
        css={{ w: "100%" }}
      >
        <Box>
          {title !== "" && (
            <Flex css={{ color: "$textMedEmp" }} align="center">
              <InfoIcon width={20} height={20} />
              <Text
                variant="sm"
                css={{ ml: "$4", display: "inline", fontWeight: "$semiBold" }}
              >
                {title}
              </Text>
            </Flex>
          )}
          {body !== "" && (
            <Text
              variant="sm"
              css={{
                color: "$textMedEmp",
                mb: fullWidth ? "0" : "$8",
                mt: title ? "$4" : "0",
                maxWidth: fullWidth ? "unset" : "280px",
              }}
            >
              {body}
            </Text>
          )}
        </Box>

        <a
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            AppAnalytics.track("btn.clicked", {
              componentId: componentId,
              btnId: "read.guide",
              value: eventValue,
            })
          }
          href={link}
        >
          <Button
            variant="standard"
            outlined
            css={{ borderRadius: "$0", mr: fullWidth ? "$md" : "0" }}
          >
            <Text variant="sm">{btnText}</Text>
          </Button>
        </a>
      </Flex>

      {!hideCross ? (
        <Box
          css={{
            c: "$textAccentDisabled",

            "&:hover": { c: "$textAccentMedium", cursor: "pointer" },
          }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <CrossIcon width={20} height={20} />
        </Box>
      ) : null}
    </Flex>
  );
}

export default InfoCard;
