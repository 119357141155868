import React from "react";
import { isEmpty } from "lodash";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { peerAnalyticsSubscribeType } from "src/types/analytics";
import { InfoIcon } from "@100mslive/react-icons";
import { Flex, Text, Tooltip } from "@100mslive/react-ui";

type Props = {
  subscribeData: peerAnalyticsSubscribeType;
};

const getRTTSentiment = (rtt: number) => {
  if (rtt < 100) {
    return { text: "Good", color: "$success" };
  }
  if (rtt < 300) {
    return { text: "Fair", color: "$warning" };
  }
  return { text: "Bad", color: "$error" };
};

function TrackMessage({
  text,
  val,
  tooltip = "",
}: {
  text: string;
  val: string;
  tooltip?: string;
}) {
  const sentiment = getRTTSentiment(Number(val));
  const isDefaultTrack = text === "Threshold RTT";
  return (
    <Flex
      css={{
        p: "$8 $10",
      }}
      justify="start"
      direction="column"
    >
      <Flex align="center" css={{ gap: "$2" }}>
        <Text variant="caption" css={{ c: "$textDisabled" }}>
          {text}
        </Text>
        {tooltip ? (
          <Tooltip
            title={tooltip}
            side="top"
            align="center"
            boxCss={{ r: "$0", w: "$80" }}
          >
            <Flex css={{ c: "$textDisabled" }}>
              <SmallIcon>
                <InfoIcon />
              </SmallIcon>
            </Flex>
          </Tooltip>
        ) : (
          <></>
        )}
      </Flex>
      <Text
        variant="body2"
        css={{
          c: isDefaultTrack ? "$textMedEmp" : sentiment.color,
          mt: "$2",
          fontWeight: "$regular",
        }}
      >
        {isDefaultTrack ? `${val}ms` : `${val}ms - ${sentiment.text}`}
      </Text>
    </Flex>
  );
}
/* eslint-disable-next-line complexity */
export default function SubscriberRoundTripMetrics({ subscribeData }: Props) {
  const calulateAverageRTT = () => {
    if (isEmpty(subscribeData?.rtt)) {
      return 0;
    }
    let totalRTT = 0;
    let totalDuration = 0;
    const data = subscribeData.rtt;
    if (data.length === 0) {
      return 0;
    }
    for (let i = 0; i < data.length - 1; i++) {
      const diff =
        (new Date(data[i + 1].value[0]).getTime() -
          new Date(data[i].value[0]).getTime()) /
        1000;

      if (Number.isFinite(data[i].value[1])) {
        totalRTT += data[i].value[1] * diff;
      }
      totalDuration += diff;
    }
    if (totalDuration === 0) {
      return Number.isFinite(data?.[0]?.value[1]) ? data?.[0]?.value[1] : 0;
    }
    return totalRTT / totalDuration;
  };
  const averagePacketLoss = calulateAverageRTT();

  return (
    <Flex
      css={{
        borderTop: "$space$px solid $borderDefault",
        borderLeft: "$space$px solid $borderDefault",
        w: "30%",
        h: "100%",
      }}
      direction="column"
    >
      <TrackMessage
        text="Average RTT"
        val={averagePacketLoss.toFixed(2).toString()}
      />
      <TrackMessage text="Threshold RTT" val="300" />
    </Flex>
  );
}
