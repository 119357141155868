import { handleActions } from "redux-actions";
import {
  DELETE_PAYMENT_CARD_DONE,
  DELETE_PAYMENT_CARD_FAIL,
  DELETE_PAYMENT_CARD_INIT,
  FETCH_BILLING_DONE,
  FETCH_BILLING_FAIL,
  FETCH_BILLING_INIT,
  FETCH_BILLING_RESET,
  FETCH_CUSTOMER_DETAIL_DONE,
  FETCH_CUSTOMER_DETAIL_FAIL,
  FETCH_CUSTOMER_DETAIL_INIT,
  FETCH_INVOICES_DONE,
  FETCH_INVOICES_FAIL,
  FETCH_INVOICES_INIT,
  FETCH_ORGANISATION_DETAILS_DONE,
  FETCH_ORGANISATION_DETAILS_FAIL,
  FETCH_ORGANISATION_DETAILS_INIT,
  FETCH_PAYMENT_CARDS_DONE,
  FETCH_PAYMENT_CARDS_FAIL,
  FETCH_PAYMENT_CARDS_INIT,
  PATCH_PAYMENT_CARD_DONE,
  PATCH_PAYMENT_CARD_FAIL,
  PATCH_PAYMENT_CARD_INIT,
  UPDATE_CUSTOMER_DETAIL_DONE,
  UPDATE_CUSTOMER_DETAIL_FAIL,
  UPDATE_CUSTOMER_DETAIL_INIT,
  UPDATE_ORGANISATION_DETAILS_DONE,
  UPDATE_ORGANISATION_DETAILS_FAIL,
  UPDATE_ORGANISATION_DETAILS_INIT,
} from "./actions";
import { API_CALL_STATE } from "../../constants";

export const organisation = handleActions(
  {
    [FETCH_ORGANISATION_DETAILS_INIT]: state => {
      state.status.fetchOrganisationInfo = API_CALL_STATE.IN_PROGRESS;
      return state;
    },
    [FETCH_ORGANISATION_DETAILS_DONE]: (state, action) => {
      state.organisation = action.payload;
      state.status.fetchOrganisationInfo = API_CALL_STATE.DONE;
      return state;
    },
    [FETCH_ORGANISATION_DETAILS_FAIL]: state => {
      state.status.fetchOrganisationInfo = API_CALL_STATE.FAILED;
      return state;
    },
    [FETCH_INVOICES_INIT]: state => {
      state.status.fetchInvoicesInfo = API_CALL_STATE.IN_PROGRESS;
      return state;
    },
    [FETCH_INVOICES_DONE]: (state, action) => {
      state.invoices = action.payload;
      state.status.fetchInvoicesInfo = API_CALL_STATE.DONE;
      return state;
    },
    [FETCH_INVOICES_FAIL]: state => {
      state.status.fetchInvoicesInfo = API_CALL_STATE.FAILED;
      return state;
    },
    [FETCH_BILLING_INIT]: state => {
      state.status.fetchBillingInfo = API_CALL_STATE.IN_PROGRESS;
      return { ...state };
    },
    [FETCH_BILLING_DONE]: (state, action) => {
      const { client_secret } = action.payload;
      state.billing.clientSecret = client_secret;
      state.status.fetchBillingInfo = API_CALL_STATE.DONE;
      return { ...state };
    },
    [FETCH_BILLING_FAIL]: state => {
      state.status.fetchBillingInfo = API_CALL_STATE.FAILED;
      return { ...state };
    },
    [FETCH_BILLING_RESET]: state => {
      state.status.fetchBillingInfo = "";
    },
    [FETCH_PAYMENT_CARDS_INIT]: state => {
      state.status.fetchPaymentCards = API_CALL_STATE.IN_PROGRESS;
      return { ...state };
    },
    [FETCH_PAYMENT_CARDS_FAIL]: state => {
      state.status.fetchPaymentCards = API_CALL_STATE.FAILED;
      return { ...state };
    },
    [FETCH_PAYMENT_CARDS_DONE]: (state, action) => {
      state.billing.is_billing_enabled = action.payload?.is_billing_enabled;
      const cards = action.payload?.payment_methods || [];
      const paymentCards = cards
        .map(item => {
          const { details: card } = item;
          let exp_month = `0${card.exp_month}`.slice(-2);
          let exp_year = card.exp_year.toString().slice(-2);
          return {
            id: item.id,
            customer: item.customer,
            last4: card.last4,
            expiry: `${exp_month}/${exp_year}`,
            default: item.is_default,
            brand: item.details.brand,
          };
        })
        .sort(a => (a.default === true ? -1 : 1));
      state.status.fetchPaymentCards = API_CALL_STATE.DONE;
      state.paymentCards = paymentCards;
      return { ...state };
    },
    [PATCH_PAYMENT_CARD_INIT]: state => {
      state.status.patchPaymentCards = API_CALL_STATE.IN_PROGRESS;
      return { ...state };
    },
    [PATCH_PAYMENT_CARD_FAIL]: state => {
      state.status.patchPaymentCards = API_CALL_STATE.FAILED;
      return { ...state };
    },
    [PATCH_PAYMENT_CARD_DONE]: (state, action) => {
      const id = action.payload;
      state.paymentCards = state.paymentCards
        .map(card => {
          card.default = false;
          if (card.id === id) {
            card.default = true;
          }
          return card;
        })
        .sort(a => (a.default === true ? -1 : 1));
      state.status.fetchPaymentCards = API_CALL_STATE.DONE;
      return { ...state };
    },
    [DELETE_PAYMENT_CARD_INIT]: state => {
      state.status.deletePaymentCard = API_CALL_STATE.IN_PROGRESS;
      return { ...state };
    },
    [DELETE_PAYMENT_CARD_FAIL]: state => {
      state.status.deletePaymentCard = API_CALL_STATE.FAILED;
      return { ...state };
    },
    [DELETE_PAYMENT_CARD_DONE]: (state, action) => {
      const id = action.payload;
      state.paymentCards = state.paymentCards
        .filter(card => card.id !== id)
        .sort(a => (a.default === true ? -1 : 1));
      state.status.deletePaymentCard = API_CALL_STATE.DONE;
      return { ...state };
    },
    [UPDATE_ORGANISATION_DETAILS_INIT]: state => {
      state.status.updateOrganisationInfo = API_CALL_STATE.IN_PROGRESS;
      return state;
    },
    [UPDATE_ORGANISATION_DETAILS_DONE]: (state, action) => {
      state.organisation = action.payload;
      state.status.updateOrganisationInfo = API_CALL_STATE.DONE;
      return state;
    },
    [UPDATE_ORGANISATION_DETAILS_FAIL]: state => {
      state.status.updateOrganisationInfo = API_CALL_STATE.FAILED;
      return state;
    },
    [FETCH_CUSTOMER_DETAIL_INIT]: state => {
      state.status.fetchCustomerDetails = API_CALL_STATE.IN_PROGRESS;
      return { ...state };
    },
    [FETCH_CUSTOMER_DETAIL_DONE]: (state, action) => {
      const { address, customer_name } = action.payload;
      state.customerDetails.customer_name = customer_name;
      state.customerDetails.address = address || state.customerDetails.address;
      state.status.fetchCustomerDetails = API_CALL_STATE.DONE;
      return { ...state };
    },
    [FETCH_CUSTOMER_DETAIL_FAIL]: state => {
      state.status.fetchCustomerDetails = API_CALL_STATE.FAILED;
      return { ...state };
    },
    [UPDATE_CUSTOMER_DETAIL_INIT]: state => {
      state.status.updateCustomerDetails = API_CALL_STATE.IN_PROGRESS;
      return { ...state };
    },
    [UPDATE_CUSTOMER_DETAIL_DONE]: (state, action) => {
      const data = action.payload;
      state.customerDetails = data;
      state.status.updateCustomerDetails = API_CALL_STATE.DONE;
      return { ...state };
    },
    [UPDATE_CUSTOMER_DETAIL_FAIL]: state => {
      state.status.updateCustomerDetails = API_CALL_STATE.FAILED;
      return { ...state };
    },
  },
  {
    organisation: {},
    invoices: [],
    billing: {
      is_billing_enabled: false,
    },
    paymentCards: [],
    customerDetails: {
      address: {
        // line1: null,
        // line2: null,
        // city: null,
        // state: null,
        // country: null,
        // zipCode: null,
      },
      customer_name: null,
    },
    status: {
      fetchOrganisationInfo: "",
      fetchInvoicesInfo: "",
      updateOrganisationInfo: "",
      fetchBillingInfo: "",
      fetchPaymentCards: "",
      fetchCustomerDetails: "",
      updateCustomerDetails: "",
      deletePaymentCard: "",
      patchPaymentCard: "",
    },
  }
);
