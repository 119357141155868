import React from "react";
import classNames from "classnames";

export default function LoadingSpinner({ classes = "" }) {
  return (
    <button
      type="button"
      className={classNames(`btn btn-light waves-effect `, classes)}
    >
      <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
      Loading
    </button>
  );
}
