import React, { ReactElement } from "react";
import { API_CALL_STATE } from "src/constants";
import { Flex, Text } from "@100mslive/react-ui";
import PrebuiltCustomisationMenu from "./PrebuiltCustomisationMenu";
import PrebuiltMenuItem from "./PrebuiltMenuItem";
import { AppAnalytics } from "../../helpers/analytics_helper";
import StatusString from "../Common/StatusString";

function DefaultMenu({
  fetchPolicyInfoStatus,
  fetchOrCreateAppLayoutStatus,
  setActiveTab,
  menuItems,
  toggleEditor,
  policyId,
}: {
  toggleEditor: () => void;
  fetchOrCreateAppLayoutStatus: string;
  menuItems: Record<
    string,
    { icon: ReactElement; title: string; description: string; key: string }
  >;
  fetchPolicyInfoStatus: string;
  setActiveTab: (key: string) => void;
  policyId: string;
}) {
  type menuList = keyof typeof menuItems;
  return (
    <PrebuiltCustomisationMenu
      isNewText="Beta"
      title="Prebuilt Customizer"
      subtitle="Build and Customise your use-case"
    >
      <>
        {fetchOrCreateAppLayoutStatus === API_CALL_STATE.FAILED ||
        fetchPolicyInfoStatus === API_CALL_STATE.FAILED ? (
          <Flex css={{ w: "100%" }}>
            <StatusString
              textVariant="body2"
              content="Couldn't fetch layout data"
            />
            <Text css={{ c: "$error", p: "$4", mt: "$10" }} />
          </Flex>
        ) : (
          Object.keys(menuItems).map((item, index: number) => {
            const value = item as menuList;
            if (menuItems[value].key === "editor") {
              return (
                <PrebuiltMenuItem
                  key={index + item}
                  icon={menuItems[value].icon}
                  title={menuItems[value].title}
                  description={menuItems[value].description}
                  onClick={() => {
                    AppAnalytics.track(`${menuItems[value].key}.clicked`, {
                      btnId: `${menuItems[value].key}.sidebar`,
                      page: `/prebuilt`,
                      policyId: policyId,
                    });
                    toggleEditor();
                  }}
                />
              );
            }
            return (
              <PrebuiltMenuItem
                key={index + item}
                icon={menuItems[value].icon}
                title={menuItems[value].title}
                description={menuItems[value].description}
                onClick={() => {
                  setActiveTab(menuItems[value].key);
                  AppAnalytics.track(`btn.clicked`, {
                    btnId: `${menuItems[value].key}.sidebar`,
                    page: `/prebuilt`,
                    policyId: policyId,
                  });
                }}
              />
            );
          })
        )}
      </>
    </PrebuiltCustomisationMenu>
  );
}

export default DefaultMenu;
