import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isFunction } from "lodash";
import { PencilDrawIcon } from "@100mslive/react-icons";
import { Button, Input, Text } from "@100mslive/react-ui";
import MainDialog from "./MainDialog";
import {
  fetchPolicyInfo,
  UPDATE_TEMPLATE_NAME_RESET,
  updateTemplateName,
} from "../../actions/RolesActions";
import { API_CALL_STATE, validationMessagesKeys } from "../../constants";
import { createErrorMessage, Messages } from "../../validations";

const EditTemplateNameModal = ({
  policyId,
  buttonText = <></>,
  shouldFetchPolicy = false,
  hideButton = false,
  trigger = false,
  setTrigger,
  css = {},
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(trigger);
  const { templates, updateTemplateNameStatus } = useSelector(
    state => state.roles
  );
  const policyInfo = templates[policyId] || {};
  const [input, setInput] = useState(policyInfo?.name);
  const [invalidName, setInvalidName] = useState(false);

  useEffect(() => {
    setInput(policyInfo.name);
  }, [open, policyInfo.name]);

  useEffect(() => {
    const data = input?.trim();
    let error;
    for (const index in templates) {
      if (policyId !== index && templates[index].name === data) {
        error = createErrorMessage("Already in use!");
        break;
      }
    }
    setInvalidName(
      Messages(validationMessagesKeys.validTemplateName, data) || error
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  useEffect(() => {
    if (updateTemplateNameStatus === API_CALL_STATE.DONE) {
      setOpen(false);
      if (isFunction(setTrigger)) {
        setTrigger(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTemplateNameStatus]);

  useEffect(() => {
    return () => {
      if (updateTemplateNameStatus === API_CALL_STATE.DONE) {
        dispatch({ type: UPDATE_TEMPLATE_NAME_RESET });
      }
    };
  });

  return (
    <>
      {!hideButton ? (
        !isEmpty(css) ? (
          <Button
            variant="standard"
            css={css}
            onClick={() => {
              setOpen(true);
              if (shouldFetchPolicy && policyId) {
                dispatch(fetchPolicyInfo(policyId));
              }
            }}
          >
            <PencilDrawIcon style={{ height: "20px" }} />
            <> {buttonText}</>
          </Button>
        ) : (
          <button
            onClick={() => {
              setOpen(true);
              if (shouldFetchPolicy && policyId) {
                dispatch(fetchPolicyInfo(policyId));
              }
            }}
            style={{ display: "flex", flex: "row" }}
          >
            <PencilDrawIcon
              className="text-textIcon-disable"
              style={{ height: "20px" }}
            />
            <>{buttonText}</>
          </button>
        )
      ) : null}
      <MainDialog
        open={open || trigger}
        disableButton={invalidName || input?.trim() === policyInfo?.name}
        loading={updateTemplateNameStatus === "processing"}
        onOpenChange={() => {
          setOpen(false);
          if (isFunction(setTrigger)) {
            setTrigger(false);
          }
        }}
        title="Edit Template Name"
        Icon={<PencilDrawIcon className="text-textIcon-medEmp" />}
        subtitle={
          <Text css={{ color: "$textMedEmp" }}>
            Change the name of this template.
          </Text>
        }
        content={
          <div className="my-8">
            <Input
              className="w-full"
              value={input}
              placeholder="e.g. MyTemplate"
              onChange={e => setInput(e.target.value?.trimStart())}
            />
            <Text
              variant="caption"
              css={{
                fontWeight: "$regular",
                marginTop: "$4",
                c: "$error",
              }}
            >
              {invalidName}
            </Text>
          </div>
        }
        buttonText="Save"
        mainFunction={() => {
          dispatch(
            updateTemplateName(
              {
                policyId: policyId,
                templateName: input?.trim(),
              },
              [
                "template.rename",
                {
                  templateId: policyId,
                  initialTemplateName: policyInfo.name,
                  changedTemplateName: input?.trim(),
                },
                {
                  templateId: policyId,
                  initialTemplateName: policyInfo.name,
                  attemptedTemplateName: input?.trim(),
                },
              ]
            )
          );
        }}
      />
    </>
  );
};

export default EditTemplateNameModal;
