import { useEffect, useState } from "react";
import { middleTruncateString } from "src/utils";

const useDynamicTruncate = (id: string) => {
  const [length, setLength] = useState(4);
  const [maxWidth, setMaxWidth] = useState(Infinity);

  useEffect(() => {
    const handleResize = () => {
      // Get the box element by its ID
      const box = document.getElementById(`${id}`);

      // Check if the box element exists
      if (box) {
        // Get the length of the box ID
        const length = box.id.length;

        // Get the maximum width of the box element
        const maxSize = box.getBoundingClientRect().width;

        // Create a temporary span element
        const spanElement = document.createElement("span");

        // Set the style of the span to match the box element
        spanElement.style.fontSize = window.getComputedStyle(box).fontSize;
        spanElement.style.fontFamily = window.getComputedStyle(box).fontFamily;
        spanElement.style.fontWeight = window.getComputedStyle(box).fontWeight;
        spanElement.style.fontSize = window.getComputedStyle(box).fontSize;

        // Wait for the style to be applied at the span for correctly calculating the width
        setTimeout(() => {
          for (let i = length; i > 0; i--) {
            const turncatedString = middleTruncateString(box.id, i);
            spanElement.textContent = turncatedString;

            // Append the span element to the body for measurement
            document.body.appendChild(spanElement);

            //Get the width of the span element
            const spanWidth = spanElement.getBoundingClientRect().width;

            // Remove the temporary span element from the body
            document.body.removeChild(spanElement);

            // Check if the span fits within the maximum width
            if (spanWidth <= maxSize) {
              // Update the length and maximum width
              setLength(i);
              setMaxWidth(spanWidth);
              break;
            }
          }
        }, 10);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [id]);

  return {
    prefixLength: length,
    maxWidth,
  };
};

export default useDynamicTruncate;
