import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { apiError, loginSuccess } from "./actions";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { AppAnalytics } from "../../../helpers/analytics_helper";
//Include Both Helper File with needed methods
import { postLogin } from "../../../helpers/backend_helper";
import { removeCurrentUser, saveCurrentUser } from "../../../utils";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      postLogin,
      "login",
      {
        email: user.email,
        password: user.password,
      },
      history
    );
    if (response.success) {
      console.error(response);
      saveCurrentUser(response);
      //console.error(response)
      history.push("/dashboard");
      yield put(loginSuccess(response));
    } else {
      yield put(apiError(response.msg));
    }
  } catch (error) {
    AppAnalytics.track("login.request.failure", {
      email: user.email,
      msg: JSON.stringify(error),
    });
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    AppAnalytics.track("logout");
    removeCurrentUser();
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
