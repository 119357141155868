import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHasUnsavedSettings, updatePlugins } from "src/actions/RolesActions";
import TemplateConfigCard from "src/components/Cards/TemplateConfigCard";
import SwitchWithTitle from "src/pages/Developer/SwitchWithTitle";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { RootState } from "src/store/reducers";
import { slugify } from "src/utils";
import { Flex, Text } from "@100mslive/react-ui";

const NoiseCancellation = () => {
  const dispatch = useDispatch();

  const policy = useSelector((state: RootState) => state.roles.policyInfo);
  const isNoiseCancellationEnabled =
    policy?.plugins?.noiseCancellation?.enabled || false;

  const toggleNoiseCancellation = useCallback(() => {
    const tempPolicy = { ...policy };
    tempPolicy.plugins = {
      ...tempPolicy.plugins,
      noiseCancellation: { enabled: !isNoiseCancellationEnabled },
    };
    dispatch(updatePlugins(tempPolicy?.plugins || {}));
    dispatch(setHasUnsavedSettings(true));
  }, [dispatch, isNoiseCancellationEnabled, policy]);

  return (
    <TemplateConfigCard
      leftComponent={
        <Text
          variant="body2"
          css={{ c: "$textDisabled", fontWeight: "$regular" }}
        >
          Enable and configure Noise Cancellation.
        </Text>
      }
      text="Noise Cancellation"
      id={slugify("Noise Cancellation")}
      classNameForText="config-subheading"
      rightComponent={
        <Flex direction="column" css={{ minWidth: "100%" }}>
          <Flex
            direction="column"
            css={{
              minWidth: "100%",
            }}
          >
            <SettingsTitle
              hideTooltip
              title=""
              key="noiseCancellationState"
              text="Noise Cancellation"
            />

            <SwitchWithTitle
              hideTitle
              tooltipMessage="Enable whiteboard recording state"
              checkedValue={isNoiseCancellationEnabled}
              onCheckedChange={() => toggleNoiseCancellation()}
            />
          </Flex>
        </Flex>
      }
    />
  );
};

export default NoiseCancellation;
