import React, { ComponentProps, PropsWithoutRef } from "react";
import { CheckCircleIcon } from "@100mslive/react-icons";
import { Box, CSS, Flex, Text } from "@100mslive/react-ui";

const TabTile: React.FC<
  PropsWithoutRef<
    ComponentProps<typeof Flex> & { active: boolean; text: string; css?: CSS }
  >
> = ({ active = false, text = "", css, ...props }) => (
  <Flex
    css={{
      bg: active ? "$surfaceLight" : "$surfaceDefault",
      p: "$8",
      r: "$0",
      cursor: "pointer",
      border: "$space$1 solid",
      borderColor: active ? "$borderAccent" : "$borderDefault",
      "&:hover": {
        bg: active ? "$surfaceDefault" : "$surfaceDark",
        borderColor: active ? "$primaryLight" : "$borderLight",
      },
      ...css,
    }}
    align="center"
    justify="between"
    {...props}
  >
    <Text
      variant="body2"
      css={{
        c: "$textHighEmp",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {text}
    </Text>
    {active ? (
      <Box css={{ c: "$borderAccent" }}>
        <CheckCircleIcon width={20} height={20} />
      </Box>
    ) : null}
  </Flex>
);

export default TabTile;
