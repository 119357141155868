/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce, isEmpty, set } from "lodash";
import { checkSubdomain, fetchTemplatesData } from "src/actions/RolesActions";
import toastr from "src/components/Common/toastr";
import { API_CALL_STATE, validationMessagesKeys } from "src/constants";
import { AppAnalytics, toTitleCase } from "src/helpers";
import {
  checkHipaaCompliantTemplate,
  createTemplateName,
  getImportedTemplate,
} from "src/services/cmsModels/Policy/helpers";
import { HipaaValidationError } from "src/services/cmsModels/Policy/helpers/errors";
import {
  checkTemplateName,
  createTemplateFromStoreV2,
  setAppDetailsInStore,
  setIsRoomNameValid,
  setIsTemplateConfigValid,
  setLargeRoomBoolInStore,
  setRoleLayoutsInStore,
  setRoomNameInStore,
  setStepAnswers,
  setSubdomainInStore,
  setTemplateIdAndPTypeStore,
  setTemplateIdInStore,
  setTemplateInStore,
  updateTemplateInStore,
} from "src/store/createTemplate/actions";
import { RootState } from "src/store/reducers";
import { fetchGeoInfo } from "src/store/userInfo/actions";
import { policyType } from "src/types/policyTypes";
import { RoleLayouts } from "src/types/prebuilt";
import {
  currentUser,
  currentWorkspaceHipaa,
  getCurrentWorkspaceID,
  saveCurrentUser,
} from "src/utils";
import Messages from "src/validations/Messages";
import { fetchPublicTemplates } from "../../../actions/CreateAppActions";
import SetTemplateSteps from "../components/layout/SetTemplateSteps";
import useOnboarding from "../hooks/useOnboarding";
// Constants
export const steps = {
  CONFIGURE_ROOM: { key: "CONFIGURE_ROOM", name: "Pick your use-case" },
  CONFIGURE_USE_CASE: { key: "CONFIGURE_USE_CASE", name: "Configure" },
  SELECT_ADD_ON: { key: "SELECT_ADD_ON", name: "Select Add-ons" },
  DEPLOY: { key: "DEPLOY", name: "Deploy" },
  JOIN_ROOM: { key: "JOIN_ROOM", name: "Experience" },
};

const SetTemplateFlowWithLayout = () => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState<string>(
    steps.CONFIGURE_ROOM.key
  );

  const publicTemplates = useSelector(
    (state: RootState) => state.createApp.publicTemplates
  );
  const all_templates = useSelector(
    (state: RootState) => state.roles.all_templates
  );
  const workspaces = useSelector(
    (state: RootState) => state.workspace.workspaces
  );
  const checkTemplateNameStatus = useSelector(
    (state: RootState) => state.createTemplate.checkTemplateNameStatus
  );
  const largeRoom = useSelector(
    (state: RootState) => state.createTemplate.largeRoom
  );

  const checkTemplateNameError = useSelector(
    (state: RootState) => state.createTemplate.search.template.error
  );
  const checkedSubdomainError = useSelector(
    (state: RootState) => state.roles.checkSubdomain.error
  );
  const checkSubdomainStatus = useSelector(
    (state: RootState) => state.roles.checkSubdomainStatus
  );
  const isTemplateConfigValid = useSelector(
    (state: RootState) => state.createTemplate.isTemplateConfigValid
  );
  const policyType = useSelector(
    (state: RootState) => state.createTemplate.policyType
  );
  const id = useSelector((state: RootState) => state.createTemplate.id);
  const isRoomNameValid = useSelector(
    (state: RootState) => state.createTemplate.isRoomNameValid
  );
  const stepAnswers = useSelector(
    (state: RootState) => state.createTemplate.stepAnswers
  );
  const createdTemplate = useSelector(
    (state: RootState) => state.createTemplate.createdTemplate
  );
  const subdomain = useSelector(
    (state: RootState) => state.createTemplate.subdomain
  );
  const roomId = useSelector((state: RootState) => state.createTemplate.roomId);

  const roomName = useSelector(
    (state: RootState) => state.createTemplate.roomName
  );

  const fetchAllWorkspaces = useSelector(
    (state: RootState) => state.workspace.status.fetchAllWorkspaces
  );

  const [isImported, setIsImported] = useState(false);
  const policy = useSelector((state: RootState) => state.createTemplate.policy);
  const [templateNameError, setTemplateNameError] = useState("");
  const [subdomainError, setSubdomainError] = useState("");
  const [subdomainChecking, setSubdomainChecking] = useState(false);
  const setTemplateFlow = (templateId: null | number) => {
    dispatch(setTemplateIdAndPTypeStore(templateId));
  };

  const {
    config,
    roleLayout: cmsRoleLayouts,
    policy: template,
    addOns,
  } = useOnboarding();

  useEffect(() => {
    const user = currentUser();
    dispatch(fetchGeoInfo());
    dispatch(fetchTemplatesData());
    setActiveStep(Object.keys(steps)[0]);
    const firstName =
      toTitleCase(user?.first_name?.split(" ")?.[0]) || crypto.randomUUID();
    dispatch(setRoomNameInStore(`${firstName}'s Room`));
    dispatch(setIsRoomNameValid(true));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fetchAllWorkspaces === API_CALL_STATE.DONE) {
      if (isEmpty(publicTemplates)) {
        dispatch(fetchPublicTemplates());
      }
    }
  }, [publicTemplates, fetchAllWorkspaces]);

  useEffect(() => {
    if (
      all_templates.length >= 1 &&
      workspaces[getCurrentWorkspaceID()]?.is_admin
    ) {
      const user = currentUser();
      const tempUser = {
        ...user,
        show_onboarding: false,
      };
      saveCurrentUser(tempUser);
    }
  }, [all_templates]);

  useEffect(() => {
    if (typeof id === "number" && !isImported) {
      dispatch(setTemplateInStore({ policy: template, checkPolicyName: true }));
      dispatch(setRoleLayoutsInStore(cmsRoleLayouts));
    }
    // eslint-disable-next-line
  }, [id, template, isImported]);

  useEffect(() => {
    dispatch(
      setIsTemplateConfigValid(
        !(subdomainError || templateNameError) &&
          checkSubdomainStatus === API_CALL_STATE.DONE &&
          checkTemplateNameStatus === API_CALL_STATE.DONE
      )
    );
  }, [
    subdomainError,
    templateNameError,
    dispatch,
    checkSubdomainStatus,
    checkTemplateNameStatus,
  ]);

  useEffect(
    () => {
      handleSubdomainNameChange(subdomain);
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    []
  );

  useEffect(() => {
    if (checkTemplateNameStatus === API_CALL_STATE.FAILED) {
      setTemplateNameError(checkTemplateNameError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkTemplateNameStatus]);

  useEffect(() => {
    if (checkSubdomainStatus === API_CALL_STATE.FAILED) {
      setSubdomainError(checkedSubdomainError);
    }
    setSubdomainChecking(checkSubdomainStatus === API_CALL_STATE.IN_PROGRESS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkSubdomainStatus]);
  const debounceSaveTemplateName = useCallback(
    debounce(name => {
      dispatch(checkTemplateName(name, false));
    }, 500),
    []
  );

  const handleTemplateNameChange = (name: string) => {
    const error = Messages(
      validationMessagesKeys.validTemplateName,
      name,
      true,
      []
    );
    if (!error) {
      debounceSaveTemplateName(name);
      AppAnalytics.track("btn.clicked", {
        btnId: "template.rename.success",
        componentId: "configure.your.template.step",
      });
    }
    setTemplateNameError(error);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSaveSubdomain = useCallback(
    debounce(subdomain => {
      dispatch(checkSubdomain(subdomain));
    }, 500),
    []
  );
  const handleSubdomainNameChange = (subdomain: string) => {
    const error = Messages(
      validationMessagesKeys.validSubdomain,
      subdomain,
      true,
      []
    );
    if (!error) {
      setSubdomainChecking(true);
      debounceSaveSubdomain(subdomain);
      AppAnalytics.track("btn.clicked", {
        btnId: "subdomain.edit.success",
        componentId: "configure.your.template.step",
      });
    }
    setSubdomainError(error);
  };

  const updatePolicyDetails = useCallback(
    (path: string, value: ReactNode) => {
      let updatedPolicy = { ...policy };
      updatedPolicy = set(updatedPolicy, path, value);
      dispatch(updateTemplateInStore(updatedPolicy as policyType));
    },
    [policy]
  );
  const autoGenerateSubdomain = useCallback(() => {
    const subdomainArray = subdomain.split("-");
    subdomainArray[2] = Math.floor(Math.random() * 10000).toString();
    const newSubdomain = subdomainArray.reduce((acc, curr, currIndex) => {
      if (currIndex === 0) {
        return `${curr}`;
      }
      return `${acc}-${curr}`;
    }, "");
    dispatch(setSubdomainInStore(newSubdomain));
    handleSubdomainNameChange(newSubdomain);
  }, [subdomain]);

  const autoGenerateTemplate = useCallback(() => {
    const name = policy?.name || "";
    const templateNameArray = name.split("-");
    const abbr = templateNameArray[0];
    const templateName = createTemplateName(abbr, true, true);
    updatePolicyDetails("name", templateName);
    handleTemplateNameChange(templateName);
  }, [policy.name]);

  const importTemplate = useCallback(() => {
    const input = document.createElement("input") as HTMLInputElement;
    input.type = "file";
    input.accept = ".json";
    function inputChange(this: GlobalEventHandlers, ev: Event) {
      const file = (ev.target as HTMLInputElement)?.files?.[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = function (e) {
          try {
            const jsonPolicy = JSON.parse(e?.target?.result as string);
            if (jsonPolicy && jsonPolicy?.roles && !isEmpty(jsonPolicy.roles)) {
              const importedTemplate = getImportedTemplate({
                jsonPolicy: jsonPolicy,
                publicTemplates: publicTemplates,
              });
              if (currentWorkspaceHipaa()) {
                checkHipaaCompliantTemplate(jsonPolicy);
              }
              dispatch(
                setTemplateInStore({
                  policy: importedTemplate,
                  checkPolicyName: true,
                  isImported: true,
                })
              );
              dispatch(setRoleLayoutsInStore(importedTemplate?.role_layouts));
              setIsImported(true);
              setTemplateFlow(importedTemplate?.ts_template_id || null);
              setActiveStep(steps.DEPLOY.key);
              AppAnalytics.track("import.template.successful", {
                data: e?.target?.result as string,
                componentId: "guided.onboarding",
              });
            } else {
              throw new Error("Invalid JSON");
            }
          } catch (error) {
            if (error instanceof HipaaValidationError) {
              toastr.error(`Invalid template JSON: ${error.message}`); // Invalid data
            } else {
              toastr.error("Error parsing JSON!");
            }
            console.error("Error parsing JSON:", error);
            AppAnalytics.track("import.template.successful", {
              data: e?.target?.result as string,
              componentId: "guided.onboarding",
            });
            setIsImported(false);
          }
        };

        reader.readAsText(file);
      }
    }
    input.onchange = inputChange;
    input.click();
  }, [publicTemplates]);

  const setLargeRoom = (bool: boolean) => {
    dispatch(setLargeRoomBoolInStore(bool));
  };

  return (
    <>
      <SetTemplateSteps
        setLargeRoom={setLargeRoom}
        largeRoom={largeRoom}
        publicTemplates={publicTemplates}
        setTemplateFlow={setTemplateFlow}
        isImportedTemplate={isImported}
        id={id}
        setTemplateName={name => {
          updatePolicyDetails("name", name);
          handleTemplateNameChange(name);
        }}
        importTemplate={importTemplate}
        isRoomNameValid={isRoomNameValid}
        policyType={policyType}
        config={config}
        addOns={addOns}
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        stepAnswers={stepAnswers}
        setStepAnswers={(stepAns: {}): void => {
          dispatch(setStepAnswers({ ...stepAns }));
        }}
        setSubdomain={function (subdomain: string): void {
          dispatch(setSubdomainInStore(subdomain));
          handleSubdomainNameChange(subdomain);
        }}
        roomName={roomName}
        setTemplateIdInStore={function (id: number | null): void {
          dispatch(setTemplateIdInStore(id));
        }}
        isTemplateConfigValid={isTemplateConfigValid}
        setTemplateInStore={function ({
          policy,
          checkPolicyName,
        }: {
          policy?: policyType | {};
          checkPolicyName?: boolean;
        }): void {
          dispatch(setTemplateInStore({ policy, checkPolicyName }));
        }}
        policy={policy}
        subdomain={subdomain}
        cmsRoleLayouts={cmsRoleLayouts}
        setRoleLayouts={function (roleLayouts: RoleLayouts): void {
          dispatch(setRoleLayoutsInStore(roleLayouts));
        }}
        setAppDetails={function (
          appDetails:
            | {}
            | { tile_shape?: string | undefined; metadata?: string | undefined }
        ): void {
          dispatch(setAppDetailsInStore(appDetails));
        }}
        createTemplateFromStore={function (isOnboarding: boolean): void {
          dispatch(createTemplateFromStoreV2(isOnboarding));
        }}
        createdTemplate={createdTemplate}
        roomId={roomId}
        checkTemplateNameStatus={checkTemplateNameStatus}
        subdomainChecking={subdomainChecking}
        subdomainError={subdomainError}
        templateNameError={templateNameError}
        autoGenerateSubdomain={autoGenerateSubdomain}
        autoGenerateTemplate={autoGenerateTemplate}
      />
    </>
  );
};

export default SetTemplateFlowWithLayout;
