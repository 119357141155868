import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterSearch from "src/components/Common/FilterSearch";
import TabGroup from "src/components/Common/TabGroup";
import LoadingSpinner from "src/components/LoadingSpinner";
import {
  API_CALL_STATE,
  RECORDING_LOG_COMPONENT_ID,
  SESSION_DETAILS_TABS,
} from "src/constants";
import RecordingLogs from "src/pages/Sessions/RecordingLogs";
import { RootState } from "src/store/reducers";
import {
  applySessionFilter,
  resetSessionDetailsPage,
  setFilteredPeers,
  setSessionDetailsActiveTab,
} from "src/store/sessions/actions";
import { Peer } from "src/types/sessions";
import { Box, Flex } from "@100mslive/react-ui";
import SessionPeersTable from "../components/SessionPeersTable";

interface Tab {
  name: string;
  id: number;
  icon: JSX.Element;
  title: string;
}

const tabs: Tab[] = [
  {
    name: "Participants",
    id: 1,
    icon: <></>,
    title: SESSION_DETAILS_TABS.PARTICIPANTS,
  },
  {
    name: "Recording Log",
    id: 0,
    icon: <></>,
    title: SESSION_DETAILS_TABS.RECORDING_LOG,
  },
];

interface RoomSessionFilter {
  label: string;
  placeholder: string;
  _key: string;
  type?: string;
  options?: Record<string, string>;
}

const roomSessionFilters: RoomSessionFilter[] = [
  {
    label: "Peer ID",
    placeholder: "Optional",
    _key: "id",
  },
  {
    label: "Role",
    placeholder: "Optional",
    _key: "role",
  },
  {
    label: "Issues",
    placeholder: "Optional",
    _key: "issue",
    type: "dropdown",
    options: {
      "Publish Failure": "publish_failure",
      "Quality Issues": "quality_issues",
      "Subscribe Failure": "subscribe_failure",
      "Join Failure": "join_failure",
      Disconnections: "disconnections",
    },
  },
];

function SessionDetailsTables() {
  const dispatch = useDispatch();

  const activeTab = useSelector(
    (state: RootState) => state.sessions.derivedStates.sessionDetailsActiveTab
  );
  const [loading, setLoading] = useState(false);

  const fetchSessionDetailsStatus = useSelector(
    (state: RootState) => state.sessions.fetchSessionDetailsStatus
  );

  const filter = useSelector(
    (state: RootState) => state.sessions.sessionFilter
  );

  const isSearch = useSelector(
    (state: RootState) => state.sessions.derivedStates.isSearch
  );

  const isValid = useSelector(
    (state: RootState) => state.sessions.derivedStates.isValid
  );

  const isSessionLive = useSelector(
    (state: RootState) => state.sessions.derivedStates.isSessionLive
  );

  const sessionIssues = useSelector(
    (state: RootState) => state.sessions.derivedStates.sessionPeersWithIssues
  );

  const filteredPeers = useSelector(
    (state: RootState) => state.sessions.derivedStates.filteredPeers
  );

  const peers = useSelector(
    (state: RootState) => state.sessions.derivedStates.peers
  );

  const recording_logs = useSelector(
    (state: RootState) => state.sessions.derivedStates.recording_logs
  );

  const sortPeersBy = useSelector(
    (state: RootState) => state.sessions.sortPeersBy
  );

  const getFilteredArray = useCallback(
    (filter: Partial<Peer>) => {
      const isSearch = Boolean(
        filter.id || filter.name || filter.role || filter.issue
      );
      setLoading(true);
      if (isSearch) {
        let tempObj: Record<string, Peer> = {};
        for (const [key, value] of Object.entries(filter)) {
          if (key === "applyFilter") {
            continue;
          }
          if (value) {
            const filteredTempObj: Record<string, Peer> = {};
            Object.keys(peers).forEach(peerKey => {
              const peer = peers[peerKey];
              peer.issue = sessionIssues[peerKey]?.join("_");
              if (
                peer &&
                peer[key as keyof Peer] &&
                ((peer[key as keyof Peer] as string) ?? "")
                  .toLowerCase()
                  .includes((value as string).toLowerCase())
              ) {
                filteredTempObj[peerKey] = peers[peerKey];
              }
            });

            tempObj = { ...tempObj, ...filteredTempObj };
          }
        }
        dispatch(
          setFilteredPeers(tempObj, sortPeersBy.by, sortPeersBy.direction)
        );
        setLoading(false);
      } else {
        dispatch(
          setFilteredPeers(peers, sortPeersBy.by, sortPeersBy.direction)
        );
        setLoading(false);
      }
    },
    [dispatch, peers, sessionIssues, sortPeersBy]
  );

  useEffect(() => {
    if (filter.applyFilter) {
      getFilteredArray(filter);
      dispatch(applySessionFilter("applyFilter", false));
    }
  }, [filter, dispatch, getFilteredArray]);

  useEffect(() => {
    return () => {
      dispatch(resetSessionDetailsPage());
    };
  }, [dispatch]);

  return (
    <>
      {fetchSessionDetailsStatus === API_CALL_STATE.DONE ? (
        <>
          <Flex
            css={{
              gap: "$8",
              marginBottom: "$9",
              mt: "$14",
              w: "100%",
              justifyContent: "space-between",
            }}
          >
            {activeTab === SESSION_DETAILS_TABS.PARTICIPANTS ? (
              <FilterSearch
                placeholder="Search with Peer Name"
                componentId="sessions.details.page"
                filters={roomSessionFilters}
                resetDisabled={!isValid}
                alignValue="start"
                maxWidth="450px"
                isIDInput={false}
                error={
                  fetchSessionDetailsStatus === API_CALL_STATE.DONE &&
                  Object.keys(filteredPeers).length === 0 &&
                  isSearch
                    ? "error"
                    : ""
                }
                applyDisabled={!isValid}
                inputValue={filter.name}
                filterValues={filter}
                onFilterChange={({
                  key,
                  value,
                }: {
                  key: string;
                  value: string;
                }) => {
                  dispatch(applySessionFilter(key, value));
                }}
                onFilterRemove={(key: string) => {
                  dispatch(applySessionFilter(key, ""));
                  dispatch(applySessionFilter("applyFilter", true));
                }}
                onInputChange={(val: string) => {
                  if (val) {
                    dispatch(applySessionFilter("name", val));
                    getFilteredArray(filter);
                  } else {
                    dispatch(applySessionFilter("name", val));
                    dispatch(
                      setFilteredPeers(
                        peers,
                        sortPeersBy.by,
                        sortPeersBy.direction
                      )
                    );
                  }
                }}
                applyFunction={() => getFilteredArray(filter)}
                resetFunction={() => {
                  dispatch(applySessionFilter("name", ""));
                  dispatch(applySessionFilter("role", ""));
                  dispatch(applySessionFilter("id", ""));
                  dispatch(applySessionFilter("issue", ""));
                  dispatch(applySessionFilter("applyFilter", true));
                }}
                loading={loading}
                noFilters={false}
              />
            ) : null}
            <Box css={{ ml: "auto" }} id={RECORDING_LOG_COMPONENT_ID}>
              <TabGroup
                activeTab={activeTab}
                setActiveTab={(value: string) => {
                  dispatch(setSessionDetailsActiveTab(value));
                }}
                tabs={tabs}
                btnId={
                  activeTab === SESSION_DETAILS_TABS.PARTICIPANTS
                    ? "participants"
                    : "recording.logs"
                }
                componentId="sessions.details.page"
              />
            </Box>
          </Flex>
          {activeTab === SESSION_DETAILS_TABS.PARTICIPANTS ? (
            <SessionPeersTable
              isSearch={isSearch}
              loading={loading}
              peers={peers}
              sessionIssues={sessionIssues}
              filteredPeers={filteredPeers}
              isSessionLive={
                typeof isSessionLive === "boolean" && isSessionLive
              }
            />
          ) : (
            <RecordingLogs logs={recording_logs} />
          )}
        </>
      ) : null}
      {loading ? (
        <div style={{ height: "100px", margin: "30px", textAlign: "center" }}>
          <LoadingSpinner />
        </div>
      ) : null}
    </>
  );
}

export default SessionDetailsTables;
