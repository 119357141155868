import { Text } from "@100mslive/react-ui";
import smiley from "../../assets/images/smiley.png";

const NoErrors = ({ disableErrorEventsOnly }) => (
  <>
    <img src={smiley} alt="100ms" width="40px" height="40px" />
    <Text
      css={{
        w: "min(375px, 100%)",
        textAlign: "center",
        mt: "$md",
      }}
      variant="body1"
    >
      No errors found
    </Text>
    <Text
      css={{
        w: "min(375px, 100%)",
        textAlign: "center",
        mt: "$xs",
        fontWeight: "$regular",
        c: "$textMedEmp",
      }}
      variant="body2"
    >
      Nothing to debug here.
    </Text>
    <Text
      onClick={disableErrorEventsOnly}
      css={{
        mt: "$md",
        fontWeight: "$semiBold",
        color: "$primaryLight",
        textAlign: "center",
        cursor: "pointer",
      }}
    >
      View all events
    </Text>
  </>
);

export default NoErrors;
