import React, { useEffect, useState } from "react";
import { paginationPages } from "src/constants";
import { Flex, Text } from "@100mslive/react-ui";
import FilterInput from "../Common/FilterSearch/FilterInput";

interface TablePaginationProps {
  pageSize: number;
  pageCount: number;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  totalSize: number;
  dataCategory: string;
}

function generatePaginationArray(
  pageCount: number,
  pageNumber: number
): (number | string)[] {
  let pages: (number | string)[] = [];
  if (pageCount <= 7) {
    pages = Array.from({ length: pageCount }, (_, index) => index + 1);
  } else {
    pages.push(1);
    switch (pageNumber) {
      case 1:
        pages.push(2);
        pages.push("...");
        break;
      case 2:
        pages.push(2);
        pages.push(3);
        pages.push("...");
        break;
      case pageCount - 1:
        pages.push("...");
        pages.push(pageCount - 1);
        break;
      case pageCount:
        pages.push("...");
        pages.push(pageCount - 1);
        break;
      default:
        pages.push("...");
        pages.push(pageNumber);
        pages.push("...");
        break;
    }
    pages.push(pageCount);
  }
  return pages;
}

const TablePagination: React.FC<TablePaginationProps> = ({
  pageSize,
  pageCount,
  pageNumber,
  setPageNumber,
  setPageSize,
  totalSize,
  dataCategory,
}) => {
  const [pages, setPages] = useState<any[]>([]);
  useEffect(() => {
    setPages([]);
    const newPages = generatePaginationArray(pageCount, pageNumber);
    setPages([...newPages]);
  }, [pageNumber, pageCount]);

  const startIndex = (pageNumber - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalSize);

  return (
    <Flex css={{ mt: "$8" }} align="center" justify="between">
      <Flex css={{ maxWidth: "200px", zIndex: 100000 }}>
        <FilterInput
          key="pagesize"
          text="Peers per page:"
          value={pageSize}
          options={paginationPages}
          type="dropdown"
          onChange={(val: number) => {
            setPageSize(val);
            setPageNumber(1);
          }}
          disabled={false}
          onEnterKeyPress={() => {}}
        />
      </Flex>
      <Text className="text-textIcon-medEmp">
        {Math.min(startIndex + 1, totalSize)}-{endIndex} of {totalSize}{" "}
        {dataCategory}
      </Text>
      <Flex gap="4">
        <Flex
          key="<"
          align="center"
          onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
          className="bg-surface-default border-2 h-fit border-surface-default p-2 rounded-md cursor-pointer text-textIcon-medEmp"
        >
          <Flex
            justify="center"
            align="center"
            style={{ width: "24px", height: "24px" }}
          >
            {"<"}
          </Flex>
        </Flex>
        {pages.map((page, index) => (
          <Flex
            key={index}
            align="center"
            onClick={() => {
              // check if page is a valid number
              if (!isNaN(parseInt(page))) {
                setPageNumber(page);
              }
            }}
            className={`border-2 h-fit p-2 rounded-md cursor-pointer  ${
              pageNumber === page
                ? "bg-surface-light border-2 border-secondary-dark text-textIcon-highEmp"
                : "bg-surface-default border-surface-default text-textIcon-medEmp"
            }`}
          >
            <Flex
              justify="center"
              align="center"
              style={{ width: "24px", height: "24px" }}
            >
              {page}
            </Flex>
          </Flex>
        ))}
        <Flex
          key=">"
          align="center"
          onClick={() => setPageNumber(Math.min(pageNumber + 1, pageCount))}
          className="bg-surface-default border-2 h-fit border-surface-default p-2 rounded-md cursor-pointer text-textIcon-medEmp"
        >
          <Flex
            justify="center"
            align="center"
            style={{ width: "24px", height: "24px" }}
          >
            {">"}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TablePagination;
