import { brbConfig } from "./components/PrebuiltBRBConfig";
import { prebuiltChatConfig } from "./components/PrebuiltChatConfig";
import { conferencingHeaderConfig } from "./components/PrebuiltConferencingHeaderConfig";
import { emojiReactionsConfig } from "./components/PrebuiltEmojiReactionsConfig";
import { handRaisesConfig } from "./components/PrebuiltHandRaiseConfig";
import { joinFormConfig } from "./components/PrebuiltJoinFormConfig";
import { onStageExperience } from "./components/PrebuiltOnStageExperience";
import { participantListConfig } from "./components/PrebuiltParticipantListConfig";
import { previewHeaderConfig } from "./components/PrebuiltPreviewHeaderConfig";
import { videoTileLayout } from "./components/PrebuiltVideoTileLayoutConfig";
import { virtualBackground } from "./components/PrebuiltVirtualBackground";

const ScreensConfiguration = {
  preview: {
    preview_header: previewHeaderConfig,
    join_form: joinFormConfig,
    virtual_background: virtualBackground,
  },
  conferencing: {
    header: conferencingHeaderConfig,
    chat: prebuiltChatConfig,
    participant_list: participantListConfig,
    video_tile_layout: videoTileLayout,
    emoji_reactions: emojiReactionsConfig,
    brb: brbConfig,
    hand_raise: handRaisesConfig,
    on_stage_exp: onStageExperience,
    virtual_background: virtualBackground,
  },
} as {
  preview?: Record<string, any>;
  conferencing?: Record<string, any>;
  leave?: Record<string, any>;
};

export default ScreensConfiguration;

export type ScreenConfig = {
  ui: {
    title: string;
    hasSwitch: boolean;
    tooltipText: string;
  };
  path: string;
  Component: React.JSX.Element;
  defaultData: {};
  validator: () => void;
};
export type previewElementEnums = "preview_header" | "join_form";
