import { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { Box, Text } from "@100mslive/react-ui";
import { fetchStrapiStarterKits } from "../../actions/CreateAppActions";
import { history } from "../../helpers";
import ZeroState from "../Rooms/ZeroState";

const TemplateZeroState = props => {
  if (props.templates.length > 0) {
    history.push(`/templates/${props.templates[0].id}`);
  }

  useEffect(() => {
    props.fetchStrapiStarterKits();
  }, [props]);

  return (
    <Box css={{ px: "$8" }}>
      <Text variant="h5" css={{ mb: "$10" }}>
        Get started
      </Text>
      <ZeroState
        subtitle={<></>}
        componentId="templates"
        hasTemplates={props?.templates?.length !== 0}
      />
    </Box>
  );
};

const mapStatetoProps = state => {
  return {
    strapiStarterKits: state.createApp.strapiStarterKits,
    templates: state.roles.all_templates,
  };
};

export default connect(mapStatetoProps, {
  fetchStrapiStarterKits,
})(withNamespaces()(TemplateZeroState));
