import React from "react";
import { NoEntryIcon } from "@100mslive/react-icons";
import { Flex, Text } from "@100mslive/react-ui";

type Props = { title: string; subtitle: string; css?: {}; subtitleCSS?: {} };

export default function EmptyStateForPeerCharts({
  title,
  subtitle,
  css = {},
  subtitleCSS = {},
}: Props) {
  return (
    <Flex
      direction="column"
      css={{
        rowGap: "$md",
        minHeight: "240px",
        w: "100%",
        textAlign: "center",
        ...css,
      }}
      justify="center"
      align="center"
    >
      <Flex css={{ c: "$textDisabled" }}>
        <NoEntryIcon width={40} height={40} />
      </Flex>
      <Flex direction="column" align="center">
        <Text variant="sub2" css={{ mb: "$2" }}>
          {title}
        </Text>
        <Text
          variant="caption"
          css={{
            fontWeight: "$regular",
            c: "$textMedEmp",
            w: "20rem",
            ...subtitleCSS,
          }}
        >
          {subtitle}
        </Text>
      </Flex>
    </Flex>
  );
}
