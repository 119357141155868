import React, { ReactNode } from "react";
import TimeInput from "src/components/TimeInput";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { RecordingConfigType } from "src/types/policyTypes";
import { recordingsConfigInvalidFieldsType } from "src/types/validations/destinations";
import { Flex, Text } from "@100mslive/react-ui";

const CommonRecordingConfiguration = ({
  recordingsConfig,
  setRecordingsConfig,
  invalidFields,
}: {
  recordingsConfig: RecordingConfigType;
  setRecordingsConfig: (key: string, value: ReactNode) => void;
  invalidFields?: { recordings: recordingsConfigInvalidFieldsType };
}) => (
  <Flex direction="column" css={{ minWidth: "100%" }}>
    <>
      <Flex
        direction="column"
        css={{
          py: "$10",
          minWidth: "100%",
          borderBottom: "$space$px solid $borderDefault",
        }}
      >
        <SettingsTitle
          key="presignDuration"
          text="Validity of Download URLs (hours)"
          title="Recordings saved to 100ms storage need to be downloaded using pre-signed download URLs sent via webhook. Alternatively, you can use your own storage."
        />
        <Flex css={{ mt: "$4", position: "relative" }}>
          <TimeInput
            css={{ r: "$0", w: "100%" }}
            onChange={setRecordingsConfig}
            timeFactor={3600}
            _key="presignDuration"
            value={recordingsConfig.presignDuration}
          />
          {invalidFields?.recordings?.presignDuration ? (
            <Text
              variant="caption"
              css={{
                c: "$error",
                position: "absolute",
                bottom: "-$9",
              }}
            >
              {invalidFields?.recordings?.presignDuration}
            </Text>
          ) : null}
        </Flex>
      </Flex>
      <Flex
        direction="column"
        css={{
          pt: "$10",
          minWidth: "100%",
        }}
      >
        <SettingsTitle
          key="maxDuration"
          text="Max recording duration (mins)"
          title="Max limit for recording duration. The recording can be ended before max duration using the API/SDK methods"
        />
        <Flex css={{ mt: "$4", position: "relative" }}>
          <TimeInput
            css={{ r: "$0", w: "100%" }}
            timeFactor={60}
            _key="maxDuration"
            onChange={setRecordingsConfig}
            value={recordingsConfig.maxDuration}
          />

          {invalidFields?.recordings?.maxDuration ? (
            <Text
              variant="caption"
              css={{
                c: "$error",
                position: "absolute",
                bottom: "-$9",
              }}
            >
              {invalidFields?.recordings?.maxDuration}
            </Text>
          ) : null}
        </Flex>
      </Flex>
    </>
  </Flex>
);

export default CommonRecordingConfiguration;
