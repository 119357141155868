export const WORKSPACE_INSIGHTS_COLOR_CODES = [
  "#2672ED",
  "#26C5C5",
  "#14B881",
  "#7E47EB",
  "#EDC602",
  "#FF8000",
  "#FF4949",
  "#FF69B4",
  "#143466",
  "#800E75",
];

export const WORKSPACE_ISSUE_BUCKETS = {
  subscribe_failure: {
    name: "Subscribe Failures",
    key: "subscribe_failure",
  },
  publish_failure: {
    name: "Publish Failures",
    key: "publish_failure",
  },
  disconnections: {
    name: "Disconnections",
    key: "disconnections",
  },
  join_failure: {
    name: "Join Failures",
    key: "join_failure",
  },
  quality_issue: {
    name: "Quality Issues",
    key: "quality_issue",
  },
};

export const WORKSPACE_RECORDINGS_TYPE_BUCKETS = {
  all: { name: "All Recordings", key: "all", rname: null },
  sfu: { name: "SFU Recordings", key: "sfu", rname: "sfu" },
  hls: { name: "HLS Recordings", key: "hls", rname: "hls" },
  beam: { name: "Composite Recordings", key: "beam", rname: "beam" },
};

export const WORKSPACE_RECORDINGS_STATUS_BUCKETS = {
  all: { name: "All Recordings", key: "all", rname: null },
  success: { name: "Completed Recordings", key: "success", rname: "success" },
  fail: { name: "Failed Recordings", key: "fail", rname: "fail" },
};

export const WORKSPACE_PEER_PLATFORM_LIST = {
  web: {
    name: "Web",
    key: "web",
  },
  android: {
    name: "Android",
    key: "android",
  },
  ios: {
    name: "iOS",
    key: "ios",
  },
  mobile_web: {
    name: "Mobile Web",
    key: "mobile_web",
  },
};

export const peerGroupByOptions: Record<string, { rname: string }> = {
  role: {
    rname: "role",
  },
  platform: {
    rname: "device",
  },
};
