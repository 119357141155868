export const checkValidPresignedUrl = ({
  url,
  isDownloadable,
}: {
  url: string;
  isDownloadable: boolean;
}) => {
  if (isDownloadable) {
    return url;
  } else {
    if (url.match(/\.100ms\.live.*\.m3u8/g)) {
      return `${process.env.REACT_APP_HOST_URL}play-vod/${btoa(url)}`;
    } else if (url.match(/^(?!.*\.100ms\.live).*\.m3u8.*$/)) {
      throw new Error("Recording from external bucket can not be accessed");
    } else {
      return url;
    }
  }
};
