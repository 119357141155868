import React, { Component } from "react";
import PrebuiltHeaderTitle from "src/pages/Prebuilt/PrebuiltHeaderTitle";
import PrebuiltSidebar from "src/pages/Prebuilt/PrebuiltSidebar";
import FocusHeader from "./FocusHeader";

export default class PrebuiltLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    };
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {
    // Scroll Top to 0
    window.scrollTo(0, 0);
    let currentPage = this.capitalizeFirstLetter(window.location.pathname);

    document.title = `${currentPage} | 100ms`;
  }

  render() {
    return (
      <>
        <div id="layout-wrapper" className="font-inter">
          <PrebuiltSidebar
            theme={this.props.leftSideBarTheme}
            type={this.props.leftSideBarType}
            isMobile={this.state.isMobile}
          />
          <div
            className="main-content font-inter bg-background-default"
            id="main-content-dashboard"
            style={{ marginLeft: "20rem" }}
          >
            <div className="sticky top-0 z-1050">
              <FocusHeader titleComponent={<PrebuiltHeaderTitle />} />
            </div>
            <div className="h-[calc(100vh-57px)]">{this.props.children}</div>
          </div>
        </div>
      </>
    );
  }
}
