import React from "react";
import Label from "src/modules/sessionDetails/components/IssueLabel";
import { Flex, Text, Tooltip } from "@100mslive/react-ui";

interface IssueNames {
  [key: string]: string;
}

const issueNames: IssueNames = {
  publish_failure: "Publish Failure",
  quality_issues: "Quality Issues",
  subscribe_failure: "Subscribe Failure",
  join_failure: "Join Failure",
  disconnections: "Disconnections",
};

interface LabelProps {
  issues: Array<string>;
  peerId: string;
}

export const SessionIssueColumn: React.FC<LabelProps> = ({ issues }) => {
  return (
    <Flex gap="2" className="relative">
      <Label text={issueNames[issues[0]]} />
      {issues.length > 1 ? (
        <Tooltip
          title={
            <Flex direction="column" className="gap-2 py-1">
              {issues.map((issue, index) => (
                <div key={index}>
                  <Label text={issueNames[issue]} />
                </div>
              ))}
            </Flex>
          }
          boxCss={{ zIndex: 1, background: "$surfaceDefault" }}
        >
          <Text
            variant="xs"
            css={{
              fontWeight: "$semiBold",
              color: "$textMedEmp",
              backgroundColor: "$surfaceLighter",
              p: "$4",
              borderRadius: "$0",
            }}
          >
            {`+${issues.length - 1}`}
          </Text>
        </Tooltip>
      ) : null}
    </Flex>
  );
};
