import React, { ReactNode, useRef, useState } from "react";
import HipaaComplianceNotice from "src/components/HipaaComplianceNotice/HipaaComplianceNotice";
import { VIDEO_QUALITY_FOR_POLICY, VideoQuality } from "src/constants";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { StreamRecordingType } from "src/types/policyTypes";
import { currentWorkspaceHipaa } from "src/utils";
import { ChevronDownIcon } from "@100mslive/react-icons";
import { Dropdown, Flex, Switch, Text } from "@100mslive/react-ui";

export const StreamRecordingSubtitle = () => (
  <Text
    variant="body2"
    css={{ c: "$textDisabled", fontWeight: "$regular", mt: "$4" }}
  >
    Composes the webcam and audio tracks together in one single file and their
    screen share as a separate file for each peer&nbsp;
    <a
      href={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/features/recordings/recording-modes/stream-recording`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Text
        variant="body2"
        css={{ c: "$primaryLight", fontWeight: "$regular" }}
        as="span"
      >
        Learn more
      </Text>
    </a>
  </Text>
);

export const StreamRecording = ({
  toggleStreamRecording,
  streamRecording,
  setRecordingsConfig,
}: {
  toggleStreamRecording: (bool?: boolean) => void;
  streamRecording: StreamRecordingType;
  setRecordingsConfig: (key: string, value: ReactNode) => void;
}) => {
  const isHipaa = currentWorkspaceHipaa();
  const [
    streamRecordingResolutionDropdown,
    setStreamRecordingResolutionDropdown,
  ] = useState(false);

  const isStreamRecordingEnabled =
    (!isHipaa && streamRecording?.enabled) || false;
  const toggleStreamRecordingResolutionDropDown = () =>
    setStreamRecordingResolutionDropdown(!streamRecordingResolutionDropdown);

  const layers = Object.keys(VIDEO_QUALITY_FOR_POLICY)
    .filter(r => parseInt(r) >= 480)
    .map(t => VideoQuality[`_${t}p`]["16:9"]);

  const cardRef = useRef<HTMLDivElement>(null);
  return (
    <Flex direction="column" css={{ minWidth: "100%" }}>
      <Flex
        direction="column"
        css={{
          pb: "$10",
          minWidth: "100%",
          borderBottom: isStreamRecordingEnabled
            ? "$space$px solid $borderDefault"
            : "none",
        }}
      >
        <SettingsTitle
          key="Enable Stream Recording"
          title="Enable stream recordings for this template and rooms created after enabling the same."
          text="Enable Stream Recording"
        />
        <Flex css={{ mt: "$4", position: "relative" }}>
          <Switch
            checked={isStreamRecordingEnabled}
            onCheckedChange={toggleStreamRecording}
            disabled={isHipaa}
          />
        </Flex>
        <HipaaComplianceNotice />
      </Flex>
      {isStreamRecordingEnabled ? (
        <Flex
          direction="column"
          css={{
            pt: "$10",
            minWidth: "100%",
          }}
        >
          <SettingsTitle
            key="videoResolution"
            title="Select recording resolution to select width and height for your recordings."
            text="Video Resolution"
          />
          <Flex css={{ mt: "$4", position: "relative" }}>
            <Dropdown.Root
              open={streamRecordingResolutionDropdown}
              onOpenChange={toggleStreamRecordingResolutionDropDown}
              css={{ w: "50%" }}
              modal={false}
            >
              <Dropdown.Trigger css={{ w: "min(100%,232px)" }}>
                <Flex
                  css={{
                    p: "$5 $6",
                    bg: "$surfaceLight",
                    r: "$0",
                    border: "solid $space$px $borderLight",
                  }}
                  ref={cardRef}
                  justify="between"
                >
                  <Text variant="body2">
                    {`${streamRecording?.width}x${streamRecording?.height}`}
                  </Text>

                  <ChevronDownIcon
                    width={20}
                    height={20}
                    style={{
                      transition: "transform 0.3s ease",
                      transform: streamRecordingResolutionDropdown
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                  />
                </Flex>
              </Dropdown.Trigger>
              <Dropdown.Portal>
                <Dropdown.Content
                  sideOffset={10}
                  css={{
                    w: `${
                      cardRef?.current
                        ? Math.floor(cardRef?.current?.offsetWidth)
                        : 0
                    }px`,
                    height: "auto",
                    maxHeight: "$96",
                    overflowY: "hidden",
                    textAlign: "center",
                  }}
                >
                  {layers.map((type, i) => {
                    return (
                      <Dropdown.Item
                        key={i}
                        css={{ p: "$4 $8" }}
                        onClick={() => {
                          setRecordingsConfig(
                            "streamRecording.width",
                            type.width
                          );
                          setRecordingsConfig(
                            "streamRecording.height",
                            type.height
                          );
                        }}
                      >
                        <Text variant="caption">
                          {`${type?.width}x${type?.height}`}
                        </Text>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Content>
              </Dropdown.Portal>
            </Dropdown.Root>
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
};
