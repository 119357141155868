import React, { useMemo } from "react";
import * as echarts from "echarts";
import { default as EchartsWrapper } from "src/components/Echarts";
import { peerAnalyticsPublishType } from "src/types/analytics";
import { getPublisherPacketLossChartConfiguration } from "../../helpers/getPublishConfigurations";
type echartsOptions = echarts.EChartsOption;

export const PublisherPacketLossChart = ({
  publishData,
  loading,
}: {
  publishData: peerAnalyticsPublishType;
  loading: boolean;
}) => {
  const peerPublisherPacketLossChartoptions: echartsOptions = useMemo(
    () => getPublisherPacketLossChartConfiguration(publishData),
    [publishData]
  );

  return (
    <>
      {/* @ts-ignore */}
      <EchartsWrapper
        options={{ ...peerPublisherPacketLossChartoptions }}
        loading={loading}
        style={{ height: "240px", width: "auto" }}
      />
    </>
  );
};
