import axios, { AxiosError } from "axios";
import { Dispatch } from "redux";
import api from "src/api";
import toastr from "src/components/Common/toastr";
import { API_CALL_STATE } from "src/constants";
import { DashboardBackendAxiosError } from "src/types/customTypes";
import { validateFields } from "src/validations/createRoom";
import { AppAnalytics, toTitleCase } from "../../helpers";
import { currentUser, getAPIURL } from "../../utils";
import { SET_REGION } from "../userInfo/actions";
import { AppDispatch } from "..";

export const CREATE_ROOM_INIT = "CREATE_ROOM_INIT";
export const CREATE_ROOM_DONE = "CREATE_ROOM_DONE";
export const CREATE_ROOM_FAIL = "CREATE_ROOM_FAIL";
export const SET_ROOM_TEMPLATE_ID = "SET_ROOM_TEMPLATE_ID";
export const SET_ROOM_RECORDING = "SET_ROOM_RECORDING";
export const SET_CREATE_ROOM_NAME = "SET_CREATE_ROOM_NAME";
export const SET_IS_ROOM_CONFIG_VALID = "SET_IS_ROOM_CONFIG_VALID";
export const RESET_CREATE_ROOM_IN_STORE = "RESET_CREATE_ROOM_IN_STORE";
export const MOUNT_CREATE_ROOM_CONFIG_IN_STORE =
  "MOUNT_CREATE_ROOM_CONFIG_IN_STORE";
export const SET_LARGE_ROOM_IN_CREATE_ROOM = "SET_LARGE_ROOM_IN_CREATE_ROOM";
export function createRoomFromStore() {
  return async (dispatch: Dispatch, getState: any) => {
    const createRoomStatus = getState().createRoom.createRoomStatus;
    // const region = getState().userInfo.region;
    const largeRoom = getState().createRoom.largeRoom;
    const roomName = getState().createRoom.roomName;
    const templateId = getState().createRoom.templateId;
    const recording = getState().createRoom.recording;
    if (createRoomStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    const roomRecording = () => {
      if (recording === "disabled") {
        return { recording: { enabled: false } };
      }
      if (recording === "enabled") {
        return { recording: { enabled: true } };
      }
      return {};
    };
    const recordingParam = roomRecording();
    const params = {
      name: roomName.trim(),
      description: "This is a dummy description",
      large_room: largeRoom,
      policy_id: templateId,
      ...recordingParam,
    };
    dispatch({ type: CREATE_ROOM_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .post("v2/policies/create-room", params);
      if (resp.data.success) {
        dispatch({
          type: CREATE_ROOM_DONE,
          payload: {
            ...resp.data.data,
            room_id: resp.data.room_id,
            subdomain: resp.data.subdomain,
          },
        });

        AppAnalytics.track("room.name.configured", {
          roomName: params.name,
          componentId: "create.room.flow",
        });

        AppAnalytics.track("template.selection.dropdown", {
          templateId: templateId,
          componentId: "create.room.flow",
        });

        AppAnalytics.track("sfu.recording.selected", {
          recording: recording,
          componentId: "create.room.flow",
        });

        // AppAnalytics.track("server.region.selected", {
        //   region: region,
        //   componentId: "create.room.flow",
        // });
      } else {
        throw new Error(resp.data.message);
      }
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      dispatch({ type: CREATE_ROOM_FAIL });
      toastr.error(message, "", { timeOut: 5000 });
    }
  };
}

export function setRoomRegion(region: string) {
  return (dispatch: AppDispatch) => {
    dispatch({ type: SET_REGION, payload: region });
    //@ts-ignore
    dispatch(setIsRoomConfigValid());
  };
}

export function setRecordingInStore(recording: string) {
  return (dispatch: AppDispatch) => {
    dispatch({ type: SET_ROOM_RECORDING, payload: recording });
    //@ts-ignore
    dispatch(setIsRoomConfigValid());
  };
}

export function setRoomNameInStore(roomName: string) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SET_CREATE_ROOM_NAME,
      payload: roomName,
    });
    //@ts-ignore
    dispatch(setIsRoomConfigValid());
  };
}

export function setLargeRoomInStore(bool: boolean) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SET_LARGE_ROOM_IN_CREATE_ROOM,
      payload: bool,
    });
  };
}

export function setTemplateIDinStore(templateId: string) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SET_ROOM_TEMPLATE_ID,
      payload: templateId,
    });
    //@ts-ignore
    dispatch(setIsRoomConfigValid());
  };
}

export function setIsRoomConfigValid() {
  return (dispatch: Dispatch, getState: any) => {
    const roomName = getState().createRoom.roomName;
    const regionInStore = getState().userInfo.region;
    const recording = getState().createRoom.recording;
    const templateId = getState().createRoom.templateId;
    const isRoomNameValid = validateFields(
      roomName,
      () => {
        return;
      },
      (err: Record<string, unknown>) => {
        return { ...err };
      },
      true
    );
    const isValid = Boolean(
      isRoomNameValid && regionInStore && recording && templateId
    );
    dispatch({
      type: SET_IS_ROOM_CONFIG_VALID,
      payload: isValid,
    });
  };
}

export function resetCreateRoomStore() {
  return (dispatch: AppDispatch) => {
    dispatch({ type: RESET_CREATE_ROOM_IN_STORE });
    //@ts-ignore
    dispatch(setIsRoomConfigValid());
  };
}

export function mountCreateRoomConfigForStore(id: string) {
  return (dispatch: AppDispatch, getState: any) => {
    const firstTemplate = getState().roles.templates[id];
    // @ts-ignore
    dispatch(setTemplateIDinStore(firstTemplate.id));
    // @ts-ignore
    dispatch(setRecordingInStore("auto"));
    const user = currentUser();

    const date = new Date();
    const mins = `0${date.getMinutes()}`.slice(-2);
    const hours = date.getHours();
    const firstName = toTitleCase(user?.first_name?.split(" ")[0] || "User");
    const generatedName = `${firstName}'s Room ${hours}${mins}`;
    // @ts-ignore
    dispatch(setRoomNameInStore(`${generatedName}`));
    //@ts-ignore
    dispatch(setIsRoomConfigValid());
  };
}

export const SEARCH_ROOM_NAME_INIT = "SEARCH_ROOM_NAME_INIT";
export const SEARCH_ROOM_NAME_FAIL = "SEARCH_ROOM_NAME_FAIL";
export const SEARCH_ROOM_NAME_DONE = "SEARCH_ROOM_NAME_DONE";
const cancelToken = axios.CancelToken;
let source = cancelToken.source();
export function checkRoomName(room_name: string, fetchAvailable: boolean) {
  return async (dispatch: Dispatch) => {
    source.cancel("");
    source = cancelToken.source();

    dispatch({ type: SEARCH_ROOM_NAME_INIT });
    try {
      const resp = await api.service("dashboard").get(
        getAPIURL("rooms/check-name-availability"),
        {
          name: room_name,
          fetch_available: fetchAvailable,
        },
        { cancelToken: source.token }
      );

      if (resp?.data?.data?.is_available) {
        dispatch({
          type: SEARCH_ROOM_NAME_DONE,
          payload: {
            error: "",
            name: resp.data.data.available_name,
          },
        });
      } else {
        throw new Error(resp.data.msg);
      }
    } catch (e) {
      const result = e as AxiosError;
      console.error(e);
      dispatch({
        type: SEARCH_ROOM_NAME_FAIL,
        payload: {
          error: result.message,
          name: room_name,
        },
      });
    }
  };
}
