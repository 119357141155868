import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, Flex, Input, Text } from "@100mslive/react-ui";
import { Chip } from "../../components";
import { API_CALL_STATE, MODAL_Z_INDEX } from "../../constants";
import { validEmail } from "../../helpers";
import { AppAnalytics } from "../../helpers/analytics_helper";
import { inviteToWorkspace } from "../../store/workspace/actions";

const errorMsg = {
  inValidEmail: "Invalid email format",
  existingMember: "Member already exists",
};

const InviteModal = ({ setDialogOpen, dialogOpen }) => {
  const dispatch = useDispatch();
  const { current: workspace, status } = useSelector(
    state => state.workspace,
    () => false
  );
  const members = workspace.members;
  const [emails, setEmails] = useState(new Set());
  const [input, setInput] = useState("");
  const [error, setError] = useState("");

  const removeEmail = value => {
    if (emails.delete(value)) {
      setEmails(new Set(emails));
    }
  };

  const handleEmailCheck = value => {
    if (value === "") {
      return true;
    }
    return validEmail(value);
  };

  const checkEmailExist = value =>
    emails.has(value) || members.find(item => item.email === value);

  useEffect(() => {
    setInput("");
    setEmails(new Set());
    setError("");
  }, [dialogOpen]);
  useEffect(() => {
    if (status.inviteEmails === API_CALL_STATE.DONE) {
      setDialogOpen(false);
    } else if (status.inviteEmails === API_CALL_STATE.FAILED) {
      setError("Invite Failed. Try Again!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.inviteEmails]);

  return (
    <Dialog.Root onOpenChange={setDialogOpen} open={dialogOpen}>
      <Dialog.Portal>
        <Dialog.Overlay css={{ zIndex: MODAL_Z_INDEX }} />
        <Dialog.Content
          css={{
            width: "min(480px,100%)",
            p: "$12",
            bg: "$surfaceDefault",
            zIndex: MODAL_Z_INDEX,
          }}
        >
          <Dialog.DefaultClose
            css={{ position: "absolute", top: "$8", right: "$8" }}
          />
          <Dialog.Title>
            <Text variant="h5">Invite Members</Text>
          </Dialog.Title>
          <Flex direction="column" css={{ mt: "$12" }}>
            <Text
              variant="body2"
              css={{
                c: "$textHighEmp",
                marginBottom: "$6",
                fontWeight: "$regular",
              }}
            >
              Invite members by email
            </Text>
            <div className="p-3 max-h-72 overflow-y-auto bg-surface-light rounded-lg w-full border-border-light border">
              {emails.size > 0 && (
                <div className="flex flex-col mb-2 gap-4">
                  {[...emails].map((value, index) => (
                    <Chip
                      onClick={() => removeEmail(value)}
                      text={value}
                      key={index}
                      className="flex bg-surface-lighter max-w-full w-min py-2 px-4 rounded-3xl text-white border border-border-light"
                    />
                  ))}
                </div>
              )}
              <form
                onSubmit={e => {
                  if (handleEmailCheck(input)) {
                    if (!checkEmailExist(input)) {
                      emails.add(input);
                      setEmails(new Set(emails));
                      setInput("");
                    } else {
                      setError(errorMsg.existingMember);
                    }
                  } else {
                    setError(errorMsg.inValidEmail);
                  }
                  e.preventDefault();
                }}
              >
                <Input
                  type="email"
                  autoFocus
                  placeholder="Enter emails"
                  value={input}
                  onChange={e => {
                    const { value } = e.target;
                    if (handleEmailCheck(value)) {
                      checkEmailExist(value)
                        ? setError(errorMsg.existingMember)
                        : setError("");
                    } else {
                      setError(errorMsg.inValidEmail);
                    }
                    setInput(value);
                    e.preventDefault();
                  }}
                  css={{
                    w: "100%",
                    "&:focus": { border: "none", boxShadow: "none" },
                    border: "none",
                    boxShadow: "none",
                  }}
                  required
                />
              </form>
            </div>
            <Text
              className="mt-2"
              variant="caption"
              css={{ color: "$textMedEmp" }}
            >
              Press Return to add another email address
            </Text>
            {error && (
              <Text css={{ c: "$error", marginTop: "$4" }}>{error}</Text>
            )}
          </Flex>
          <Flex direction="row" className="gap-4 mt-14">
            <Button
              variant="standard"
              outlined
              css={{
                height: "$16",
                width: "100%",
                fontSize: "$sm",
              }}
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={error || (emails.size === 0 && !input)}
              variant="primary"
              css={{
                height: "$16",
                width: "100%",
                fontSize: "$sm",
              }}
              loading={status.inviteEmails === API_CALL_STATE.IN_PROGRESS}
              onClick={() => {
                const emailsArray = [...emails];
                if (input) {
                  emailsArray.push(input);
                }
                if (emailsArray.length) {
                  AppAnalytics.track("btn.clicked", {
                    btnId: "member.invited.workspace",
                    invitedEmails: emailsArray,
                    componentId: "modal",
                  });
                  dispatch(inviteToWorkspace(emailsArray));
                }
              }}
            >
              Invite Members
            </Button>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default InviteModal;
