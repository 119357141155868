import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import toastr from "src/components/Common/toastr";
import api from "../../api";
import { createGetURL } from "../../helpers/utils";
import { currentUser, removeCurrentUser } from "../../utils";
import Unauthorized from "../Authentication/Unauthorized";

const createTemplatePath = appDetails => {
  return `/templates/${appDetails.policy_id}`;
};

const getTemplatePath = (integrationName, metaKey, metaValue) => {
  return api
    .service("dashboard", { raw: true })
    .get(`/integrations/${integrationName}/${metaKey}/${metaValue}`)
    .then(response => {
      const appDetails = response.data["app_details"];
      const templatePath = createTemplatePath(appDetails);
      return templatePath;
    });
};

const VercelTemplateConfig = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUnauthorizedUser, setIsUnauthorizedUser] = useState(false);
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const history = useHistory();

  const integrationName = "VERCEL";
  const metaKey = "configurationId";
  const metaValue = new URLSearchParams(useLocation().search).get(metaKey);

  const redirectToLogin = useCallback(() => {
    history.push({
      pathname: "/login",
      search: `?redirect_url=${encodeURIComponent(
        createGetURL(window.location.href, {
          stepsShown: true,
        })
      )}`,
    });
  }, [history]);

  const unauthUserClickHandler = () => {
    removeCurrentUser();
    redirectToLogin();
  };

  useEffect(() => {
    // If user is not logged in, then login first.
    const user = currentUser();

    if (!user) {
      redirectToLogin();
      return;
    }

    // Save user email in state
    setCurrentUserEmail(user.email);

    // If user is logged in, then fetch template URL from backend.
    // And, then redirect user to the template URL.
    getTemplatePath(integrationName, metaKey, metaValue)
      .then(templatePath => {
        setIsLoading(false);
        history.push(templatePath);
      })
      .catch(err => {
        setIsLoading(false);
        console.error("Error in getting template path:", err.response);

        if (err.response.data?.data?.reason === "USERS_DONT_MATCH") {
          // If user is logged in with a different account, show error
          // and give action button to login with the correct account.
          setIsUnauthorizedUser(true);
        } else if (err.response.data?.data?.reason === "APP_NOT_FOUND") {
          // If configurationId does not exist in our db, show error.
          toastr.error(
            `${metaKey}: "${metaValue}" for integration: ${integrationName} ` +
              `not found in our records.`
          );
          history.push("/dashboard");
        } else {
          history.push("/dashboard");
        }
      });
  }, [integrationName, metaKey, metaValue, history, redirectToLogin]);

  return (
    <>
      {isLoading && (
        <div className="h-screen w-screen flex justify-center items-center">
          <Spinner style={{ width: "4rem", height: "4rem" }} color="primary" />
        </div>
      )}
      {isUnauthorizedUser && (
        <Unauthorized
          unauthUserClickHandler={unauthUserClickHandler}
          unauthUserEmail={currentUserEmail}
        />
      )}
    </>
  );
};

export default VercelTemplateConfig;
