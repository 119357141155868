import React from "react";
import Echarts from "src/components/Echarts";
import { Flex, Text } from "@100mslive/react-ui";
type echartsOptions = echarts.EChartsOption;
type Props = { score: number; loading?: boolean };

// eslint-disable-next-line complexity
export default function sessionCQSChart({ score, loading, ...props }: Props) {
  const eventLogChartoptions: echartsOptions = {
    grid: {
      top: "20%",
      left: "8%",
      right: "5%",
    },
    series: [
      {
        type: "gauge",
        radius: "178%",

        center: ["50%", "100%"],
        startAngle: 180,
        endAngle: 360,
        min: 0,
        max: 5,
        splitNumber: 5,
        itemStyle: {
          color: score > 3.5 ? "#36B580" : score > 1 ? "#EDC602" : "#CB525E",
        },

        progress: {
          show: true,
          width: 20,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            width: 10,
            color: [[1, "#1E232A"]],
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        anchor: {
          show: false,
        },
        title: {
          show: false,
        },
        detail: {
          valueAnimation: true,
          width: "60%",
          lineHeight: 10,
          borderRadius: 8,
          offsetCenter: [0, "-15%"],
          fontSize: 20,
          fontWeight: "bolder",
          formatter: function (value) {
            return value.toFixed(2);
          },
          color: score > 3.5 ? "#36B580" : score > 1 ? "#EDC602" : "#CB525E",
        },

        data: [
          {
            value: score,
          },
        ],

        animationDuration: 1000,
        animation: true,
        animationEasing: "quadraticIn",
      },
      {
        type: "gauge",
        radius: "178%",
        center: ["50%", "100%"],
        startAngle: 180,
        endAngle: 360,
        min: 0,
        max: 5,
        itemStyle: {
          color: score > 3.5 ? "#36B580" : score > 1 ? "#EDC602" : "#CB525E",
        },
        progress: {
          show: true,
          width: 8,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        detail: {
          show: false,
        },
        data: [
          {
            value: score,
          },
        ],

        animationDuration: 1000,
        animation: true,
        animationEasing: "quadraticIn",
      },
    ],
  };
  return (
    <Flex
      direction="column"
      css={{
        w: "100%",
        p: "$10",
        borderBottomLeftRadius: "$1",
      }}
    >
      {/* @ts-ignore */}
      <Echarts
        options={{ ...eventLogChartoptions }}
        loading={loading || false}
        style={{ height: "90px", width: "100%" }}
        {...props}
      />
      <Flex direction="column" css={{ mt: "$md" }}>
        <Text
          variant="caption"
          css={{ fontWeight: "$regular", c: "$textDisabled", mb: "$2" }}
        >
          Calculated based on quality metrics.
        </Text>

        {/* <Text
          variant="caption"
          css={{
            fontWeight: "$regular",
            c: "$primaryLight",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          Learn more about CQS &rarr;
        </Text> */}
      </Flex>
    </Flex>
  );
}
