import React, { Component } from "react";
// ReCAPTCHA
import ReCAPTCHA from "react-google-recaptcha";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";
// action
import queryString from "query-string";
import { fetchGeoInfo } from "src/store/userInfo/actions";
import AuthForm from "../../components/AuthForm";
import CenteredSpinner from "../../components/Common/CenteredSpinner";
import GithubLoginButton from "../../components/GithubLoginButton";
import GoogleLoginButton from "../../components/GoogleLoginButton";
import PasswordInput from "../../components/PasswordInput/index";
import { postRegister } from "../../helpers/backend_helper";
import history from "../../helpers/history";
import { checkPasswordPolicy, isAllowedEmail } from "../../helpers/utils";
import {
  apiError,
  registerUser,
  registerUserFailed,
} from "../../store/actions";
import { removeCurrentUser } from "../../utils";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validPassword: false,
      captchaToken: null,
      firstName: "",
      password: "",
      success: false,
      fields: false,
      social: true,
      viewSpinner: false,
      region: "",
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.inputFirstName = this.inputFirstName.bind(this);
    this.setValidPassword = this.setValidPassword.bind(this);
    this.handleShowFields = this.handleShowFields.bind(this);
    // Ref for ReCAPTCHA object
    this.recaptchaRef = React.createRef();
  }

  captchaChangeHandler(captchaToken) {
    this.setState({
      captchaToken,
    });
  }

  inputFirstName(e) {
    const firstName = e.target.value.trim();
    this.setState({ ...this.state, firstName });
  }

  setValidPassword(state = false) {
    this.setState({ ...this.state, validPassword: state });
  }

  handleShowFields() {
    const url = new URL(window.location.href);
    url.searchParams.set("signup", "mail");
    const searchString = url.search;

    history.push({
      search: searchString,
    });
  }

  handleSetViewSpinner = () => {
    this.setState({ viewSpinner: true });
  };

  // handleValidSubmit
  handleValidSubmit(event, values) {
    const firstName = values["first-name"];
    values["password"] = this.state.password;
    const { isPasswordValid } = checkPasswordPolicy(values.password, firstName);
    if (!isPasswordValid) {
      this.props.registerUserFailed("Password does not follow policy");
      return false;
    }
    const isEmailAllowed = isAllowedEmail(values.email, ["@gmail.com"]);

    if (!isEmailAllowed) {
      this.props.registerUserFailed("Password does not follow policy");
      return false;
    }
    if (this.state.captchaToken) {
      this.props.apiError("");
      this.props.registerUserFailed("");
      console.debug("Captcha Token generated.");
      values["captcha-token"] = this.state.captchaToken;
      values["currentRegion"] = this.state.region;
      postRegister("register", values, this.props, window.location.search);
      this.setState({ captchaToken: null });
    } else {
      console.debug("Captcha Token not generated.");
      this.props.registerUserFailed("Please solve the CAPTCHA.");
    }
  }

  componentDidMount() {
    this.props.fetchGeoInfo();
    removeCurrentUser();
    this.props.apiError("");
    this.props.registerUserFailed("");
    const searchParams = queryString.parse(window.location.search);
    if (searchParams?.signup === "mail") {
      this.setState({ ...this.state, fields: true, social: false });
    }
  }

  componentDidUpdate() {
    // clear captcha in case of error response from form submission
    if (this.props.registrationError && !this.state.captchaToken) {
      this.recaptchaRef.current.reset();
    }
    if (
      ["in", "us", "eu"].includes(this.props.region) &&
      this.state.region === ""
    ) {
      this.setState({
        region: this.props.region,
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.viewSpinner === true ? (
          <CenteredSpinner className="absolute bg-gray-cool1" color="primary" />
        ) : (
          <AuthForm
            title="Build your live video app in 5 minutes"
            subtitle="Get 10,000 FREE minutes every month"
            footer={
              <p>
                Already have an account ?{" "}
                <Link
                  to={{
                    pathname: "/login",
                    search: window.location.search,
                  }}
                  className="font-weight-medium text-primary"
                >
                  Login
                </Link>
              </p>
            }
            className={`mt-8  mb-8 justify-center flex ${
              this.state.fields ? "login-page-text-left-method2" : ""
            }`}
            routesClassName=""
            disclaimer
            free
            isSocialButton={
              this.state.social && (
                <button
                  style={{ marginBottom: 120 }}
                  className="is-social-button bottom-20"
                  onClick={this.handleShowFields}
                >
                  Signup with email instead-&gt;
                </button>
              )
            }
          >
            {this.state.social && (
              <>
                <div className="form-group h-16">
                  <GoogleLoginButton
                    handleSetViewSpinner={this.handleSetViewSpinner}
                  />
                </div>
                <div className="form-group h-16">
                  <GithubLoginButton
                    buttonText="Continue with Github"
                    handleSetViewSpinner={this.handleSetViewSpinner}
                  />
                </div>
              </>
            )}

            {this.state.fields && (
              <AvForm
                className="form-horizontal mt-0"
                onValidSubmit={this.handleValidSubmit}
              >
                {this.props.registrationError &&
                this.props.registrationError ? (
                  <Alert color="danger">{this.props.registrationError}</Alert>
                ) : null}

                <div className="flex w-full justify-end mb-1">
                  <div className="form-group  h-12 w-full">
                    <AvField
                      name="first-name"
                      className="form-control bg-gray-cool2 w-full focus:ring-2 ring-blue-400 border-none"
                      placeholder="Enter name"
                      type="text"
                      required
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter name",
                        },
                        maxLength: {
                          value: 50,
                          errorMessage:
                            "Name must be between 1 and 50 characters",
                        },
                        minLength: {
                          value: 1,
                          errorMessage:
                            "Name must be between 1 and 50 characters",
                        },
                      }}
                      onChange={this.inputFirstName}
                    />
                  </div>
                </div>
                <div className="form-group h-12">
                  <AvField
                    name="email"
                    className="form-control bg-gray-cool2 focus:ring-2 ring-blue-400 border-none"
                    placeholder="Enter email"
                    type="email"
                    required
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter an email",
                      },
                      maxLength: {
                        value: 100,
                        errorMessage: "Email must be under 100 characters",
                      },
                    }}
                  />
                </div>
                <div className="form-group h-12 pt-1">
                  <PasswordInput
                    placeholder="Enter password"
                    firstName={this.state.firstName}
                    setValidPassword={this.setValidPassword}
                    mainFunction={val => {
                      this.setState({ password: val.target.value });
                    }}
                  />
                </div>
                <div className="flex justify-center mb-2 mt-6">
                  <ReCAPTCHA
                    ref={this.recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    size="normal"
                    onChange={this.captchaChangeHandler.bind(this)}
                  />
                </div>
                <div className="h-14">
                  <button
                    className={`mt-2 btn btn-primary btn-block waves-effect waves-light${
                      this.state.validPassword ? "" : " cursor-default"
                    }`}
                    type="submit"
                    disabled={!this.state.validPassword && this.state.region}
                  >
                    Sign Up
                  </button>
                </div>
              </AvForm>
            )}
          </AuthForm>
        )}
      </div>
    );
  }
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading, region: state.userInfo.region };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
  fetchGeoInfo,
})(Register);
