import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "reactstrap";
import { isEmpty } from "lodash";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { API_CALL_STATE } from "src/constants";
import { openDevrevWidget } from "src/helpers";
import { fetchPeersAnalyticsPeerWebrtcStats } from "src/store/analytics/actions";
import { RootState } from "src/store/reducers";
import {
  AlertTriangleIcon,
  InfoIcon,
  NoEntryIcon,
  SupportIcon,
} from "@100mslive/react-icons";
import { Button, Flex, Text } from "@100mslive/react-ui";
import { MosChart } from "../components/charts/MosChart";
import "src/components/Common/tooltipReactstrap.css";
type Props = {
  peerId: string;
};

const infoFlexCss = {
  borderTopLeftRadius: "$1",
  borderBottomLeftRadius: "$1",
  flex: "1 1 0",
  flexWrap: "wrap",
  gap: "$10",
  w: "100%",
  rowGap: "$4",
  border: "$space$px solid $borderDefault",
};
// eslint-disable-next-line complexity
const MosScore = ({ peerId }: Props) => {
  const dispatch = useDispatch();
  const fetchPeerAnalyticsPeerWebrtcStatsStatus = useSelector(
    (state: RootState) =>
      state.roomDebugger.fetchPeerAnalyticsPeerWebrtcStatsStatus
  );

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const peerMosScore = useSelector(
    (state: RootState) => state.roomDebugger.peerMosScore
  );

  useEffect(() => {
    if (peerId) {
      dispatch(fetchPeersAnalyticsPeerWebrtcStats(peerId));
    }
  }, [dispatch, peerId]);
  return (
    <>
      {fetchPeerAnalyticsPeerWebrtcStatsStatus !== API_CALL_STATE.FAILED ? (
        <Flex css={{ w: "100%", py: "$10" }}>
          <Flex css={{ ...infoFlexCss, py: "$8", position: "relative" }}>
            <Flex align="center">
              <Text variant="sub2" css={{ fontWeight: 600, ml: "$10" }}>
                Connection Quality Score
              </Text>
              <Flex css={{ ml: "$4", c: "$textMedEmp" }}>
                <SmallIcon>
                  <InfoIcon id="mos-tooltip" />
                </SmallIcon>
              </Flex>
              <Tooltip
                isOpen={tooltipOpen}
                toggle={toggle}
                placement="top"
                target="mos-tooltip"
                align="center"
                className="mytooltip-xl"
              >
                <Text variant="caption">
                  This score is available only for video calls currently. This
                  is calculated using the peer's download network parameters
                  namely - packet loss, jitter and latency. A value of 5
                  represents excellent connection and 1 represents very bad
                  connection
                </Text>
              </Tooltip>
            </Flex>
            {fetchPeerAnalyticsPeerWebrtcStatsStatus ===
            API_CALL_STATE.IN_PROGRESS ? (
              <Flex
                justify="center"
                align="center"
                css={{ w: "100%", px: "$md" }}
              >
                <Skeleton containerClassName="flex-1" height={240} />
              </Flex>
            ) : !isEmpty(peerMosScore) ? (
              <MosChart
                mosScoreData={peerMosScore}
                loading={
                  fetchPeerAnalyticsPeerWebrtcStatsStatus ===
                  API_CALL_STATE.IN_PROGRESS
                }
              />
            ) : (
              <Flex
                direction="column"
                justify="center"
                align="center"
                css={{ rowGap: "$md", minHeight: "240px", w: "100%" }}
              >
                <Flex css={{ c: "$textDisabled" }}>
                  <NoEntryIcon width={40} height={40} />
                </Flex>
                <Flex direction="column" css={{ textAlign: "center" }}>
                  <Text variant="sub2" css={{ mb: "$2" }}>
                    No Connection Quality Score
                  </Text>

                  <Text
                    variant="caption"
                    css={{
                      fontWeight: "$regular",
                      c: "$textMedEmp",
                      w: "20rem",
                    }}
                  >
                    Connection Quality Scores reflecting the call quality will
                    populate this chart during active sessions.
                  </Text>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      ) : (
        <Flex css={{ w: "100%", py: "$10", textAlign: "center" }}>
          <Flex css={{ ...infoFlexCss, py: "$8", position: "relative" }}>
            <Flex align="center">
              <Text variant="sub2" css={{ fontWeight: 600, ml: "$10" }}>
                Connection Quality Score
              </Text>
              <Flex css={{ ml: "$4", c: "$textMedEmp" }}>
                <SmallIcon>
                  <InfoIcon id="mos-tooltip-2" />
                </SmallIcon>
              </Flex>
              <Tooltip
                isOpen={tooltipOpen}
                toggle={toggle}
                placement="top"
                target="mos-tooltip-2"
                align="center"
                className="mytooltip-xl"
              >
                <Text variant="caption">
                  This score is available only for video calls currently. This
                  is calculated using the peer's download network parameters
                  namely - packet loss, jitter and latency. A value of 5
                  represents excellent connection and 1 represents very bad
                  connection
                </Text>
              </Tooltip>
            </Flex>

            <Flex
              css={{ rowGap: "$md", minHeight: "240px", w: "100%" }}
              direction="column"
              justify="center"
              align="center"
            >
              <Flex css={{ c: "$error" }}>
                <AlertTriangleIcon width={40} height={40} />
              </Flex>
              <Flex direction="column" css={{ textAlign: "center" }}>
                <Text variant="sub2" css={{ mb: "$2" }}>
                  Something went wrong
                </Text>
                <Text
                  variant="caption"
                  css={{ fontWeight: "$regular", c: "$textMedEmp", w: "20rem" }}
                >
                  We couldn&apos;t load details for this chart. This feature is
                  currently in alpha and there are known issues we&apos;re
                  working on.
                </Text>
              </Flex>
              <Button
                icon
                variant="standard"
                css={{ p: "$3 $4", r: "$0" }}
                onClick={() => openDevrevWidget(true)}
              >
                <SupportIcon />
                Report Issue
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default MosScore;
