import React from "react";
import { capitalize, isEmpty } from "lodash";
import Line from "src/components/Common/Line";
import { Flex, Text } from "@100mslive/react-ui";
import { WORKSPACE_INSIGHTS_COLOR_CODES } from "../../constants";

type Props = {
  data: Record<string, Record<string, number>>;
  getTitle: (str: string) => string;
  groupBy?: string;
};

function PeerBreakdownList({ data, getTitle, groupBy }: Props) {
  const isPlatform = groupBy === "platform";
  const getDataConfig = (data: Record<string, Record<string, number>>) => {
    if (isEmpty(data)) {
      return {};
    }
    const data_map = {} as Record<string, { count: number; color: string }>;
    const objKeyArr = Object.keys(data).sort();
    for (let index = 0; index < objKeyArr.length; index++) {
      const item = objKeyArr[index];
      const obj = data[item];
      const rem = index % 10;
      const lineColorHEX = WORKSPACE_INSIGHTS_COLOR_CODES[rem];
      for (const key in obj) {
        if (!isEmpty(data_map[item])) {
          data_map[item].count += obj[key as keyof typeof obj];
        } else {
          data_map[item] = {
            count: obj[key as keyof typeof obj],
            color: lineColorHEX,
          };
        }
      }
    }
    return data_map;
  };
  const finalData = getDataConfig(data);
  const getPercentConfig = () => {
    const dataMap = {} as Record<string, number>;
    const objKeyArr = Object.keys(finalData);
    let total = 0;
    for (let index = 0; index < objKeyArr.length; index++) {
      const item = objKeyArr[index];
      const obj = finalData[item];

      total += obj.count;
    }
    for (let index = 0; index < objKeyArr.length; index++) {
      const item = objKeyArr[index];
      const obj = finalData[item];
      dataMap[item] = (obj.count / total) * 100;
    }
    return dataMap;
  };

  const finalDataPercent = getPercentConfig();
  return (
    <Flex
      direction="column"
      css={{
        overflowY: "scroll",
        maxHeight: "188px",
        minHeight: "188px",
        px: "$10",
        mt: "$10",
      }}
    >
      {Object.keys(finalData).map((item, index) => {
        const title = getTitle(item);
        return (
          <>
            <Card
              isPlatform={isPlatform}
              title={title}
              count={finalData[item].count}
              color={finalData[item].color}
              percent={finalDataPercent[item]}
            />
            {Object.keys(finalData).length - 1 - index ? (
              <Line css={{ my: "$8" }} />
            ) : (
              <></>
            )}
          </>
        );
      })}
    </Flex>
  );
}

const Card = ({
  count,
  color,
  title,
  percent,
  isPlatform,
}: {
  title: string;
  count: number;
  color: string;
  percent: number;
  isPlatform: boolean;
}) => {
  return (
    <Flex align="center" justify="between">
      <Flex align="center">
        <Flex css={{ r: "100%", w: "10px", h: "10px", bg: color, mr: "$4" }} />
        <Text variant="sub2" css={{ fontWeight: "$regular" }}>
          {isPlatform ? title : capitalize(title)}
        </Text>
      </Flex>
      <Flex align="center">
        <Text
          variant="sub2"
          css={{ c: "$textMedEmp", fontWeight: "$regular", mr: "$4" }}
        >{`${percent.toFixed(2)}%`}</Text>
        <Text variant="sub2">
          {count}
          <Text
            as="span"
            css={{ c: "$textDisabled", fontWeight: "$regular", ml: "$4" }}
            variant="sub2"
          >
            peers
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
};

export default PeerBreakdownList;
