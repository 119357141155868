import React, { useState } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import TabGroup from "src/components/Common/TabGroup";
import { RootState } from "src/store/reducers";
import { peerInsightsPageDerivedStatesType } from "src/types/analytics";
import { ArrowRightIcon } from "@100mslive/react-icons";
import { Flex } from "@100mslive/react-ui";
import EmptyStateForPeerCharts from "./components/EmptyStateForPeerCharts";
import MosScore from "./containers/mosScore";
import PeerInsights from "./containers/peerInsights";
import PeerIssues from "./containers/peerIssues";
import PeerLogs from "./containers/peerLogs";
import PeerQualityIssues from "./containers/peerQualityIssues";
import PublishScore from "./containers/publishScore";
import SubscribeScore from "./containers/subscribeScore";

const tabs = [
  {
    name: "Outgoing Media Quality (Sent)",
    id: 0,
    icon: (
      <Flex
        css={{
          transform: "rotate(-45deg)",
        }}
      >
        <ArrowRightIcon />
      </Flex>
    ),
    title: "upload",
  },
  {
    name: "Incoming Media Quality (Received)",
    id: 1,
    icon: (
      <Flex
        css={{
          transform: "rotate(45deg)",
        }}
      >
        <ArrowRightIcon />
      </Flex>
    ),
    title: "download",
  },
];
const Index = () => {
  const { peerInsightsDerivedStates } = useSelector(
    (state: RootState) => state.roomDebugger
  );
  const [activeTab, setActiveTab] = useState(
    (window.location.search &&
      new URLSearchParams(window.location.search).get("tab")) ||
      tabs[0]?.title
  );
  const { peerId = "" } = useParams<{ peerId: string }>();
  const derivedStates =
    peerInsightsDerivedStates as peerInsightsPageDerivedStatesType;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <SkeletonTheme baseColor="#11131A" highlightColor="#1C2027">
            <PeerInsights peerId={peerId} />
            <Flex>
              <Flex css={{ w: "70%" }}>
                <PeerLogs peerId={peerId} />
              </Flex>
              <Flex css={{ w: "30%" }}>
                <PeerIssues peerId={peerId} />
              </Flex>
            </Flex>

            {derivedStates?.publish || derivedStates?.subscribe ? (
              <>
                <Flex>
                  <Flex css={{ w: "70%" }}>
                    <MosScore peerId={peerId} />
                  </Flex>
                  <Flex css={{ w: "30%" }}>
                    <PeerQualityIssues peerId={peerId} />
                  </Flex>
                </Flex>
                <TabGroup
                  tabs={tabs}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  btnId={`${activeTab}.tab`}
                  componentId="peer.insights"
                />
                {activeTab === tabs[0].title ? (
                  <PublishScore peerId={peerId} />
                ) : (
                  <></>
                )}
                {activeTab === tabs[1].title ? <SubscribeScore /> : <></>}
              </>
            ) : (
              <EmptyStateForPeerCharts
                title="No metrics avaialable"
                subtitle="None of the peers publish any audio/video during the session, hence no metrics could be calculated."
              />
            )}
          </SkeletonTheme>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;
