import ReactHlsPlayer from "react-hls-player";
import { useParams } from "react-router-dom";

export default function VODPlayback() {
  const { src } = useParams();
  if (!src) {
    alert("Please provide manifest for playback");
  }
  return (
    <ReactHlsPlayer
      src={atob(src)}
      autoPlay={true}
      controls={true}
      style={{
        height: "100vh",
        width: "100%",
      }}
    />
  );
}
