import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import StatusString from "src/components/Common/StatusString";
import toastr from "src/components/Common/toastr";
import { getSubsetLayers, isEqualHLSLayer } from "src/helpers";
import { AddCircleIcon } from "@100mslive/react-icons";
import { Box, Flex, Text } from "@100mslive/react-ui";
import Tile from "../../components/Common/Tile";
import { LayerModal } from "../../components/Modal/LayerModal";
import { HLS_DEFAULT_VIDEO_LAYER_CONFIG, TILE_SHAPE } from "../../constants";
import {
  calculateAspectRatio,
  createLayerObject,
  sortLayers,
} from "../../utils";

const isCustom = res => {
  const defResolutions = Object.values(TILE_SHAPE).map(tile => tile.type);
  return defResolutions.includes(res) < 0;
};

const getTransformedLayers = hlsDestinations => {
  if (isEmpty(hlsDestinations.layers)) {
    return [];
  }
  return (hlsDestinations?.layers || []).map(layer => {
    const res = calculateAspectRatio({
      width: layer.width,
      height: layer.height,
    });
    const result = res.split(":");
    const value = `${result[0]}-${result[1]}`;
    return { type: res, value: value };
  });
};

const showCustomTransformedLayers = hlsDestinations =>
  getTransformedLayers(hlsDestinations).filter(layer => isCustom(layer.type));

const genLayerKey = layer =>
  `${layer.width}::${layer.height}::${layer.videoBitrate}`;

// merge n arrays assuming each array contain unique elements
const mergeLayers = (arr, keyGen = genLayerKey) => {
  let i = 0;
  const mergedLayerKeys = [];
  const mergedLayers = [];
  while (i < arr.length) {
    const layers = arr[i];
    layers?.forEach(layer => {
      const layerKey = keyGen(layer);
      if (!mergedLayerKeys.includes(layerKey)) {
        mergedLayers.push(layer);
        mergedLayerKeys.push(layerKey);
      }
    });
    i += 1;
  }
  return mergedLayers;
};

function Resolutions({
  hlsDestinations = {},
  setHLSDestinations,
  // invalidFields,
  layerTitle,
}) {
  const [openLayerModal, setOpenLayerModal] = useState(false);
  const customResLayers = getTransformedLayers(hlsDestinations);
  const [firstCustomResLayer = {}] = customResLayers;
  const [aspectRatio, setAspectRatio] = useState(
    firstCustomResLayer.type || "16:9"
  );

  const invalidFields = useSelector(
    state => state.roles.invalidFields.hlsDestinations
  );

  const { layers = [] } = hlsDestinations;
  const defaultLayers = HLS_DEFAULT_VIDEO_LAYER_CONFIG.map(layer =>
    createLayerObject(layer, aspectRatio)
  );
  const layerKeys = layers?.map(layer => genLayerKey(layer));
  const resolutionLayersToBeShown = sortLayers(
    mergeLayers([layers, defaultLayers])
  );

  const setLayer = useCallback(
    ({ videoBitrate, videoLayer }) => {
      const recordingLayers = hlsDestinations?.recording?.layers || [];
      //index undefined is basically clicking on add new layer
      const [tempLayer] = HLS_DEFAULT_VIDEO_LAYER_CONFIG.filter(
        layer => layer.value === videoLayer
      );
      const layer = createLayerObject(
        { ...tempLayer, videoBitrate },
        aspectRatio
      );
      const updatedLayers = sortLayers([...layers, layer]);
      setHLSDestinations("layers", updatedLayers);
      // Include in recording layers
      setHLSDestinations("recording.layers", [...recordingLayers, layer]);
    },
    // eslint-disable-next-line
    [aspectRatio, layers, setHLSDestinations]
  );

  const selectLayer = useCallback(
    (index, active, layer) => {
      let updatedLayers = [...layers];
      if (!active) {
        updatedLayers.push(layer);
      } else if (updatedLayers.length > 1) {
        updatedLayers = layers.filter(
          hlsLayer =>
            !isEqualHLSLayer(
              layer,
              hlsLayer.width,
              hlsLayer.height,
              hlsLayer.videoBitrate
            )
        );

        const validLayers = getSubsetLayers(
          updatedLayers || [],
          hlsDestinations?.recording?.layers || []
        );
        setHLSDestinations("recording", {
          ...hlsDestinations.recording,
          layers: sortLayers(validLayers),
        });
      } else {
        toastr.error("At least one layer must be selected");
      }
      updatedLayers = sortLayers(updatedLayers);
      setHLSDestinations("layers", updatedLayers);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layers, setHLSDestinations]
  );

  const changeAspectRatio = useCallback(
    aspectRatio => {
      const defaultLayers = sortLayers(
        HLS_DEFAULT_VIDEO_LAYER_CONFIG.filter(e => e.default).map(layer =>
          createLayerObject(layer, aspectRatio)
        )
      );
      setAspectRatio(aspectRatio);
      setHLSDestinations("layers", defaultLayers);
      if (
        hlsDestinations?.recording?.layers?.length > 0 &&
        !isEmpty(hlsDestinations?.recording)
      ) {
        setHLSDestinations("recording.layers", defaultLayers);
      }
    },
    [hlsDestinations?.recording, setHLSDestinations]
  );

  if (isEmpty(hlsDestinations)) {
    return <></>;
  }

  return (
    <>
      <div>
        <Flex css={{ flexWrap: "wrap", gap: "$md", mt: "$2", w: "100%" }}>
          {[...TILE_SHAPE, ...showCustomTransformedLayers(hlsDestinations)].map(
            (shape, i) => {
              const callback = () => changeAspectRatio(shape.type);
              return (
                <Tile
                  key={i.toString() + shape.type}
                  subText={shape.type}
                  val={shape.value}
                  isActive={aspectRatio === shape.type}
                  onClick={
                    isCustom(shape.type)
                      ? () => {
                          return;
                        }
                      : callback
                  }
                  css={{
                    m: "0",
                    cursor: isCustom(shape.type) ? "not-allowed" : "pointer",
                  }}
                />
              );
            }
          )}
        </Flex>
      </div>
      <div>
        {layerTitle}

        <Flex css={{ W: "100%", flexWrap: "wrap", gap: "$md", mt: "$4" }}>
          {resolutionLayersToBeShown.map((layer, i) => {
            const resolution = `${layer.width} x ${layer.height}`;
            const isActive = layerKeys?.includes(genLayerKey(layer));
            const tempLayer = {
              defaultLayer: layer,
              title: layer.title,
              resolution: resolution,
              bitrate: `${
                layer?.videoBitrate
                  ? layer.videoBitrate
                  : layer.defaultVideoBitrate
              } Kbps video bitrate`,
            };
            return (
              <Tile
                isLayer
                css={{ m: "0" }}
                key={layer.key + i.toString()}
                onClick={() =>
                  selectLayer(
                    tempLayer.defaultLayer.i,
                    isActive,
                    tempLayer.defaultLayer
                  )
                }
                subText={tempLayer.bitrate}
                text={tempLayer.resolution}
                isActive={isActive}
              />
            );
          })}
          <Tile
            isActive={false}
            onClick={() => {
              setOpenLayerModal(!openLayerModal);
            }}
            isLayer
            css={{ m: "0" }}
            customContent={
              <Box css={{ color: "$textDisabled", w: "100%", pt: "$2" }}>
                <Text css={{ color: "inherit", textAlign: "center" }}>
                  <AddCircleIcon height={24} width={24} />
                </Text>
                <Text
                  variant="overline"
                  css={{
                    textAlign: "center",
                    fontWeight: "$semiBold",
                    color: "inherit",
                  }}
                >
                  NEW LAYER
                </Text>
              </Box>
            }
          />
        </Flex>
        <StatusString content={invalidFields?.layers || ""} />

        <LayerModal
          open={openLayerModal}
          aspectRatio={aspectRatio}
          onOpenChange={setOpenLayerModal}
          setVideoLayer
          videoLayer
          setVideoBitrate
          newLayer={true}
          setLayer={setLayer}
        />
      </div>
    </>
  );
}

export default Resolutions;
