import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteDialog from "./DeleteDialog";
import { API_CALL_STATE, paymentsCardImages } from "../../constants";
import { deletePaymentCard } from "../../store/actions";

const DeletePaymentCardModal = ({ state = {}, card = {} }) => {
  const { open, setOpen } = state;
  const dispatch = useDispatch();
  const { status } = useSelector(state => state.organisation);
  useEffect(() => {
    if (status.deletePaymentCard === API_CALL_STATE.DONE) {
      setOpen(false);
    }
  }, [setOpen, status.deletePaymentCard]);
  return (
    <DeleteDialog
      title="Delete card"
      subtitle="Are you sure you want to delete this card?"
      data={{
        title: `Ending in ${card.last4}`,
        subtitle: `expires on ${card.expiry}`,
        image: (
          <img
            className="w-12 h-8"
            src={
              paymentsCardImages[card.brand] || paymentsCardImages["unknown"]
            }
            alt={card.brand || "unknown"}
          />
        ),
      }}
      open={open}
      loading={status.deletePaymentCard === API_CALL_STATE.IN_PROGRESS}
      deleteFunction={() => dispatch(deletePaymentCard(card.id))}
      toggleDeleteDialog={() => setOpen(state => !state)}
      cancelButtonText="Don't delete"
    />
  );
};

export default DeletePaymentCardModal;
