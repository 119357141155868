import React, { Component } from "react";
// ReCAPTCHA
import ReCAPTCHA from "react-google-recaptcha";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Alert } from "reactstrap";
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";
import queryString from "query-string";
import { isAllowedEmail } from "src/helpers";
import { fetchGeoInfo } from "src/store/userInfo/actions";
// actions
import AuthForm from "../../components/AuthForm";
import CenteredSpinner from "../../components/Common/CenteredSpinner";
import GithubLoginButton from "../../components/GithubLoginButton";
import GoogleLoginButton from "../../components/GoogleLoginButton";
import { postLogin } from "../../helpers/backend_helper";
import history from "../../helpers/history";
import { apiError, loginUser } from "../../store/actions";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      captchaToken: null,
      fields: false,
      social: true,
      viewSpinner: false,
      region: "",
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleShowFields = this.handleShowFields.bind(this);

    // Ref for ReCAPTCHA object
    this.recaptchaRef = React.createRef();
  }

  captchaChangeHandler(captchaToken) {
    this.setState({
      captchaToken,
    });
  }

  handleShowFields() {
    const searchParams = queryString.parse(window.location.search);
    searchParams.login = "mail";
    history.push({
      search: queryString.stringify(searchParams),
    });
  }

  handleSetViewSpinner = () => {
    this.setState({ viewSpinner: true });
  };

  // handleValidSubmit
  handleValidSubmit(event, values) {
    if (this.state.captchaToken) {
      this.props.apiError("");
      console.debug("Captcha Token generated.");
      values["captcha-token"] = this.state.captchaToken;
      const isEmailAllowed = isAllowedEmail(values.email);
      if (!isEmailAllowed) {
        this.props.apiError("Please solve the CAPTCHA.");
        return false;
      }
      postLogin("login", values, this.props, window.location.search);
      this.setState({ captchaToken: null });
    } else {
      console.debug("Captcha Token not generated.");
      this.props.apiError("Please solve the CAPTCHA.");
    }
  }

  componentDidMount() {
    this.props.fetchGeoInfo();
    const searchParams = queryString.parse(window.location.search);
    if (searchParams?.login === "mail") {
      this.setState({ ...this.state, fields: true, social: false });
    }
  }

  componentDidUpdate() {
    // clear captcha in case of error response from form submission
    if (this.props.error && !this.state.captchaToken) {
      this.recaptchaRef?.current?.reset();
    }
    if (
      ["in", "us", "eu"].includes(this.props.region) &&
      this.state.region === ""
    ) {
      this.setState({
        region: this.props.region,
      });
    }
  }

  // componentWillUnmount() {
  //   // this.props.apiError("");
  // }

  render() {
    return (
      <div>
        {this.state.viewSpinner === true ? (
          <CenteredSpinner className="absolute bg-gray-cool1" color="primary" />
        ) : (
          <AuthForm
            title="Login to Dashboard"
            subtitle="Sign in to continue to 100ms."
            className="mt-8 flex justify-center login-page-text-left-method2 "
            footer={
              <p>
                Don't have an account ?{" "}
                <Link
                  to={{
                    pathname: "/register",
                    search: window.location.search,
                  }}
                  className="font-weight-medium text-primary"
                >
                  Register now
                </Link>
              </p>
            }
            isSocialButton={
              this.state.social && (
                <button
                  style={{ marginBottom: 120 }}
                  className="is-social-button bottom-20"
                  onClick={this.handleShowFields}
                >
                  {"Login with email instead->"}
                </button>
              )
            }
          >
            {this.state.social && (
              <>
                <div className="form-group h-16">
                  <GoogleLoginButton
                    buttonText="Continue with Google"
                    handleSetViewSpinner={this.handleSetViewSpinner}
                    region={this.state.region}
                  />
                </div>
                <div className="form-group h-16">
                  <GithubLoginButton
                    buttonText="Continue with Github"
                    handleSetViewSpinner={this.handleSetViewSpinner}
                    region={this.state.region}
                  />
                </div>
              </>
            )}
            {this.state.fields && (
              <AvForm
                className="form-horizontal w-full"
                onValidSubmit={this.handleValidSubmit}
              >
                {this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}

                <div className="form-group w-full h-14">
                  <AvField
                    name="email"
                    value=""
                    className="form-control bg-gray-cool2 focus:ring-2 ring-blue-400 border-none"
                    placeholder="Enter email"
                    type="email"
                    required
                  />
                </div>

                <div className="form-group h-9">
                  <AvField
                    name="password"
                    value=""
                    type="password"
                    required
                    placeholder="Enter password"
                    className="bg-gray-cool2 focus:ring-2 ring-blue-400 border-none"
                  />
                </div>
                <div className="mt-0 text-right">
                  <Link
                    to={{
                      pathname: "/forgot-password",
                    }}
                    className="text-muted"
                  >
                    <i className="mdi  mdi-lock mr-1"></i> Forgot your password?
                  </Link>
                </div>
                <div className="flex justify-center my-3">
                  <ReCAPTCHA
                    ref={this.recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    size="normal"
                    onChange={this.captchaChangeHandler.bind(this)}
                  />
                </div>
                <div className="mt-2 h-14">
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    Log In
                  </button>
                </div>
              </AvForm>
            )}
          </AuthForm>
        )}
      </div>
    );
  }
}

const mapStatetoProps = state => {
  const { error } = state.Login;
  return { error, region: state.userInfo.region };
};

export default withRouter(
  connect(mapStatetoProps, { loginUser, apiError, fetchGeoInfo })(Login)
);
