import { Chat } from "@100mslive/types-prebuilt/elements/chat";
export const validateChat = (data: Chat) => {
  const invalidFields = {} as {
    chat_title?: string;
  };
  let isValid = true;
  if (Object.keys(data || {}).length && !data?.chat_title) {
    invalidFields["chat_title"] = "Panel name cannot be empty";
    isValid = false;
  }

  return { isValid, invalidFields };
};
