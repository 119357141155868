import { AnyAction, Dispatch } from "redux";

export const SET_DEVREV_READY_STATE = "SET_DEVREV_READY_STATE";
export const SET_DEVREV_MESSAGE_COUNT = "SET_DEVREV_MESSAGE_COUNT";
export const SET_DEVREV_TOGGLE_STATE = "SET_DEVREV_TOGGLE_STATE";

export const setDevRevReadyState = (readyState: boolean) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: SET_DEVREV_READY_STATE, payload: readyState });
  };
};
export const setDevRevToggleState = (toggleState: boolean) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: SET_DEVREV_TOGGLE_STATE, payload: toggleState });
  };
};

export const setDevRevMessageCount = (messageCount: number) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: SET_DEVREV_MESSAGE_COUNT, payload: messageCount });
  };
};
