import React from "react";
import moment from "moment";
import { ChevronRightIcon } from "@100mslive/react-icons";
import { Flex, Text } from "@100mslive/react-ui";

const EventCard = (
  { title, time, onClick, active, style = {}, error },
  ...props
) => {
  const date = new Date(time);
  return (
    <Flex
      css={{
        ...style,
        mb: "$sm",
        w: "98%",
        cursor: "pointer",
        bg: error ? " rgba(204, 82, 95, 0.2)" : "$surfaceLight",
        "&:hover": {
          bg: error ? "rgba(179, 71, 82, 0.15)" : "$surfaceDark",
        },
        p: "$md",
        paddingRight: "$xs",
        borderRadius: "$0",
        border: !active
          ? error
            ? "$space$px solid $errorDisabled"
            : "$space$px solid $borderLight"
          : error
          ? "$space$px solid $errorDefault"
          : "$space$px solid $brandDefault",
      }}
      justify="between"
      align="center"
      onClick={onClick}
      {...props}
    >
      <Text
        variant="sm"
        css={{
          c: "$textHighEmp",
          fontWeight: "$semiBold",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {title}
      </Text>
      <Flex justify="between" align="center">
        <Text
          variant="xs"
          css={{ c: "$textMedEmp", w: "100%", whiteSpace: "nowrap" }}
        >
          {moment(date).format("MMM D YYYY, HH:mm:ss")}
        </Text>
        <Flex
          css={{ w: "$8", h: "$8", color: "$textMedEmp" }}
          justify="center"
          align="center"
        >
          <ChevronRightIcon width={12} height={12} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EventCard;
