import React from "react";

export const AppCard = ({
  onAppChange,
  templateImage,
  templateName,
  app,
  selectedApp,
}) => {
  return (
    <div
      onClick={() => onAppChange(app)}
      className={`w-620 m-4 p-2 h-140 cursor-pointer flex flex-row rounded-xl bg-gray-cool${
        selectedApp === app.id ? "1 app-card" : ""
      } border border-gray-cool2 hover:border-gray-cool4 hover:bg-gray-cool2`}
    >
      {templateImage && (
        <div
          className={` border border-gray-cool4 rounded-xl items-center m-3`}
          style={{
            width: "100px !important",
            height: "100px !important",
            padding: "25px",
          }}
        >
          <img
            className="h-12 w-12"
            src={templateImage}
            alt={`${app.app_type} logo`}
          />
        </div>
      )}
      <div className="flex flex-col justify-center">
        <div className="font-size-20 pl-2 text-gray-cool9">{templateName}</div>
        {app.subdomain_name && (
          <div className="font-size-14 text-gray-cool3">
            https://{app.subdomain_name}
          </div>
        )}
      </div>
    </div>
  );
};
