import classNames from "classnames";

const Table = ({
  children,
  tableHeaders,
  hideBottomBorders = false,
  centerFinalColumn = false,
  compact = false,
  className = "",
}) => {
  return (
    <table className={classNames("w-full", className)}>
      <thead className="sticky z-10 top-0 bg-background-default border-0">
        <tr className="w-full border-0">
          {tableHeaders.map(tableHeader => (
            <th key={tableHeader} className="font-semibold text-xs t-head">
              {tableHeader}
            </th>
          ))}
        </tr>
        {/* Making a sticky row's border sticky requires a hack*/}
        <tr className="bg-border-default h-px min-w-full">
          {tableHeaders.map(tableHeader => (
            <th key={`${tableHeader}-dummy`} className="max-h-px p-0" />
          ))}
        </tr>
      </thead>
      <tbody
        className={`border-border-default tbody-container ${
          compact ? "compact" : ""
        } ${hideBottomBorders ? "hide-bottom-borders" : ""} ${
          centerFinalColumn ? "center-final-col" : ""
        }`}
      >
        {children}
      </tbody>
    </table>
  );
};

export default Table;
