import React from "react";
import { Loading } from "@100mslive/react-ui";
import NoErrors from "../../components/EventInspector/NoErrors";
import NoResults from "../../components/EventInspector/NoResults";
import ZeroState from "../../components/EventInspector/ZeroState";

function EventInspectorState({
  hasSearched,
  isErrorOnly,
  loading,
  hasEvents,
  disableErrorEventsOnly,
}) {
  const showLoading = !hasEvents && loading;

  if (hasSearched) {
    if (showLoading) {
      return <Loading />;
    }
    if (!isErrorOnly) {
      return <NoResults />;
    } else {
      return <NoErrors disableErrorEventsOnly={disableErrorEventsOnly} />;
    }
  }
  return <ZeroState />;
}

export default EventInspectorState;
