import React, { ComponentProps, FC, useRef, useState } from "react";
import {
  argbFromHex,
  hexFromArgb,
  TonalPalette,
} from "@material/material-color-utilities";
import { toTitleCase } from "src/helpers";
import { baseTheme } from "src/themeConfig";
import { Dropdown, Flex, Input, Text, Tooltip } from "@100mslive/react-ui";
import PrebuiltHexPicker from "./PrebuiltHexPicker";
import { ColorPicker } from ".";

const ColorPickerWithPalette: FC<
  ComponentProps<typeof Input & typeof ColorPicker> & {
    color: string;
    setColor: (color: string) => void;
    paletteColors: { key: string; tone: number }[];
  }
> = ({ color, setColor, paletteColors, ...props }) => {
  const [openPanel, setOpenPanel] = useState(false);

  const aRGBofColor = argbFromHex(color);
  const tonalPaletteOfArgb = TonalPalette.fromInt(aRGBofColor);
  const ref = useRef<HTMLDivElement>({} as HTMLDivElement);
  const palette = paletteColors.map(c => {
    return {
      key: c.key,
      color: hexFromArgb(tonalPaletteOfArgb.getHct(c.tone).toInt()),
    };
  });

  return (
    <>
      {color ? (
        <Flex direction="column" css={{ w: "100%" }} {...props}>
          <Flex align="center" ref={ref}>
            <Dropdown.Root
              open={openPanel}
              onOpenChange={() => {
                setOpenPanel(!openPanel);
              }}
              modal={false}
            >
              <Dropdown.Trigger
                css={{
                  cursor: "pointer",
                  border: "1px solid $borderDefault",
                  backgroundColor: "$surfaceDefault",
                  borderRadius: "$0",

                  w: "100%",
                }}
              >
                <Flex align="center" css={{ p: "$5 $6" }}>
                  <Flex
                    align="center"
                    css={{
                      width: "$md",
                      height: "$md",
                      borderRadius: "50%",
                      border: "1px solid $borderLight",
                      backgroundColor: color,
                      mr: "$6",
                    }}
                  />
                  <Text variant="body2" css={{ fontWeight: "$regular" }}>
                    {color.toUpperCase()}
                  </Text>
                </Flex>
              </Dropdown.Trigger>
              <Dropdown.Content
                align="center"
                css={{
                  w: `${
                    ref?.current ? Math.floor(ref?.current?.offsetWidth) : 0
                  }px`,
                  backgroundColor: "$surfaceLight",
                  maxHeight: "max-content",
                }}
                sideOffset={10}
                onClick={e => {
                  e.preventDefault();
                }}
              >
                <Flex
                  css={{
                    maxHeight: openPanel ? "1000px" : "0",
                    transition: "max-height 1s ease-in-out",
                    position: "relative",
                    width: "100%",
                    p: "$4",
                    backgroundColor: "$surfaceLight",
                    overflowX: "hidden",
                    height: "280px",
                  }}
                  direction="column"
                >
                  <PrebuiltHexPicker
                    color={color}
                    setColor={setColor}
                    reference={ref}
                  />
                </Flex>
              </Dropdown.Content>
            </Dropdown.Root>
          </Flex>
          <Flex justify="between" align="center">
            <Text
              variant="caption"
              css={{ c: "$textDisabled", fontWeight: "$regular" }}
            >
              Palette
            </Text>
            <Flex css={{ p: "$2" }}>
              {palette.map((c, i) => (
                <Tooltip
                  title={`${toTitleCase(c.key)}: ${c.color.toUpperCase()}`}
                  key={i}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    strokeWidth={1}
                    key={i}
                    fill="none"
                    style={{ marginLeft: "4px" }}
                    stroke={baseTheme.colors.borderLight}
                  >
                    <circle cx="10" cy="9.99999" r="6.66667" fill={c.color} />
                  </svg>
                </Tooltip>
              ))}
            </Flex>
          </Flex>
        </Flex>
      ) : null}
    </>
  );
};

export default ColorPickerWithPalette;
