import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import { API_CALL_STATE } from "src/constants";
import { roomDetailsType } from "src/types/roomTypes";
import {
  CREATE_ROOM_DONE,
  CREATE_ROOM_FAIL,
  CREATE_ROOM_INIT,
  RESET_CREATE_ROOM_IN_STORE,
  SEARCH_ROOM_NAME_DONE,
  SEARCH_ROOM_NAME_FAIL,
  SEARCH_ROOM_NAME_INIT,
  SET_CREATE_ROOM_NAME,
  SET_IS_ROOM_CONFIG_VALID,
  SET_LARGE_ROOM_IN_CREATE_ROOM,
  SET_ROOM_RECORDING,
  SET_ROOM_TEMPLATE_ID,
} from "./actions";

const initialCreateRoomState = {
  recording: "",
  subdomain: "",
  isRoomConfigValid: true,
  templateId: "",
  createdRoom: undefined as roomDetailsType | undefined,
  roomName: "",
  createRoomStatus: "",
  checkRoomNameStatus: "",
  roomNameError: "",
  largeRoom: false,
};

export const createRoom = handleActions<typeof initialCreateRoomState, any>(
  {
    [SET_CREATE_ROOM_NAME]: (state, action: AnyAction) => {
      return {
        ...state,
        roomName: action.payload,
      };
    },
    [SET_ROOM_RECORDING]: (state, action: AnyAction) => {
      return {
        ...state,
        recording: action.payload,
      };
    },
    [SET_ROOM_TEMPLATE_ID]: (state, action: AnyAction) => {
      return {
        ...state,
        templateId: action.payload,
      };
    },
    [SET_IS_ROOM_CONFIG_VALID]: (state, action: AnyAction) => {
      return {
        ...state,
        roomNameError: state.roomName ? "" : state.roomNameError,
        isRoomConfigValid: action.payload,
      };
    },
    [CREATE_ROOM_INIT]: state => {
      return {
        ...state,
        createdRoom: undefined,
        createRoomStatus: API_CALL_STATE.IN_PROGRESS,
      };
    },
    [CREATE_ROOM_DONE]: (state, action: AnyAction) => {
      return {
        ...state,
        createdRoom: action.payload,
        createRoomStatus: API_CALL_STATE.DONE,
      };
    },
    [CREATE_ROOM_FAIL]: state => {
      return {
        ...state,
        createdRoom: undefined,
        createRoomStatus: API_CALL_STATE.FAILED,
      };
    },
    [SET_LARGE_ROOM_IN_CREATE_ROOM]: (state, action: AnyAction) => ({
      ...state,
      largeRoom: action.payload,
    }),
    [RESET_CREATE_ROOM_IN_STORE]: state => {
      return {
        ...state,
        recording: "",
        subdomain: "",
        isRoomConfigValid: true,
        templateId: "",
        createdRoom: undefined,
        roomName: "",
        createRoomStatus: "",
        roomNameError: "",
        largeRoom: false,
      };
    },
    [SEARCH_ROOM_NAME_INIT]: state => {
      const newState = { ...state };
      newState.checkRoomNameStatus = API_CALL_STATE.IN_PROGRESS;
      return { ...newState };
    },
    [SEARCH_ROOM_NAME_FAIL]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.checkRoomNameStatus = API_CALL_STATE.FAILED;
      newState.roomNameError = action.payload.error;
      newState.roomName = action.payload.name;
      return { ...newState };
    },
    [SEARCH_ROOM_NAME_DONE]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.checkRoomNameStatus = API_CALL_STATE.DONE;
      newState.roomName = action.payload.name;
      newState.roomNameError = action.payload.error;
      return { ...newState };
    },
  },
  initialCreateRoomState
);
