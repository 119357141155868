import React from "react";
import { Flex } from "@100mslive/react-ui";
import { SessionTimelineChart } from "./charts/sessionTimelineChart";

export const SessionEventLogs = ({
  eventLogs,
}: {
  eventLogs:
    | {
        peer: { message: string; timestamp: string; type: string }[];
        recording: { message: string; timestamp: string; type: string }[];
        streaming: { message: string; timestamp: string; type: string }[];
        errors: { message: string; timestamp: string; type: string }[];
      }
    | {};
}) => {
  return (
    <Flex direction="column" css={{ w: "100%" }}>
      <SessionTimelineChart eventLogs={eventLogs} />
    </Flex>
  );
};
