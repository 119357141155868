/* eslint-disable complexity */
import React, { ReactElement, ReactNode } from "react";
import { isFunction } from "lodash";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { Flex, Switch } from "@100mslive/react-ui";
import { PREBUILT_COMPONENT_TITLES } from "./helpers/constants";

function PrebuiltConfigAccordion({
  title,
  hasSwitch = false,
  tooltipText = "",
  enabled = false,
  setEnabled,
  children,
}: {
  title: string;
  hasSwitch?: boolean;
  tooltipText?: ReactNode;
  enabled?: boolean;
  setEnabled?: (value: boolean) => void;
  children: ReactElement[] | ReactElement;
}) {
  return (
    <Flex
      direction="column"
      css={{
        borderTop:
          title === PREBUILT_COMPONENT_TITLES.VIRTUAL_BACKGROUND
            ? "$space$px solid $borderDefault"
            : "",
        borderBottom:
          title !== PREBUILT_COMPONENT_TITLES.HAND_RAISE
            ? "$space$px solid $borderDefault"
            : "",
        py: "$10",
        pt:
          title === PREBUILT_COMPONENT_TITLES.ON_STAGE_EXP
            ? "$0 !important"
            : "",
      }}
    >
      <Flex align="center" justify="between">
        <SettingsTitle
          text={title}
          titleVariant="sub1"
          title={tooltipText}
          disabled={!tooltipText}
          hideTooltip={!tooltipText}
          textCSS={
            title !== PREBUILT_COMPONENT_TITLES.ON_STAGE_EXP
              ? { c: "$textHighEmp" }
              : { fontWeight: "$regular" }
          }
        />
        {hasSwitch ? (
          <Switch
            checked={enabled}
            onCheckedChange={() => {
              if (isFunction(setEnabled)) {
                setEnabled(!enabled);
              }
            }}
          />
        ) : null}
      </Flex>
      <Flex
        css={{
          //change height to 0 to hide the content
          maxHeight: hasSwitch ? (enabled ? "5000px" : "0") : "5000px",
          transition: "max-height ease 0.3s",
          overflow: "hidden",
        }}
        direction="column"
      >
        {hasSwitch ? (enabled ? children : null) : children}
      </Flex>
    </Flex>
  );
}

export default PrebuiltConfigAccordion;
