import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import queryString from "query-string";
import toastr from "src/components/Common/toastr";
import GoogleLogo from "../assets/images/brands/google.svg";
import ThreeDots from "../assets/images/threedots.svg";
import { AppAnalytics } from "../helpers/analytics_helper";
import { verifyGoogleAuth } from "../helpers/backend_helper";
import history from "../helpers/history";
import {
  createGetURL,
  isAllowedEmail,
  isIntegrationFlow,
} from "../helpers/utils";
import { apiError } from "../store/actions";
import { getUtmParams } from "../utils";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function randomString(length) {
  var charset =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/";
  let result = "";

  while (length > 0) {
    var bytes = new Uint8Array(16);
    var random = window.crypto.getRandomValues(bytes);

    // eslint-disable-next-line no-loop-func
    random.forEach(c => {
      if (length === 0) {
        return;
      }
      if (c < charset.length) {
        result += charset[c];
        length--;
      }
    });
  }
  return result;
}

class GoogleLoginButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOption: false,
      region: "",
    };
    this.handleOptions = this.handleOptions.bind(this);
  }

  componentDidMount() {
    if (
      window.location.href.includes(process.env.REACT_APP_GOOGLE_CLIENT_ID) &&
      this.props.handleSetViewSpinner
    ) {
      this.props.handleSetViewSpinner();
    }

    const { apiError, loginUser, verifyGoogleAuth } = this.props;
    const hashParams = queryString.parse(window.location.hash);
    if (hashParams.id_token) {
      try {
        const payload = jwtDecode(hashParams.id_token);
        const stateParams = JSON.parse(decodeURIComponent(hashParams.state));

        const apiRedirect = () => {
          if (stateParams["redirect_url"]) {
            setTimeout(() => {
              window.location.href = stateParams["redirect_url"];
            }, 100);
          } else if (isIntegrationFlow()) {
            const redirectURI = createGetURL(
              window.location.origin + window.location.pathname,
              { ...stateParams }
            );
            setTimeout(() => {
              window.location.href = redirectURI;
            }, 350);
          } else {
            history.push({
              pathname: "/dashboard",
            });
          }
        };
        if (!isAllowedEmail(payload.email)) {
          apiError("Please solve the CAPTCHA.");
          return false;
        }

        verifyGoogleAuth(
          {
            id_token: hashParams.id_token,
            email: payload.email,
            name: payload.name,
            first_name: payload.given_name,
            last_name: payload.family_name,
            currentRegion: stateParams?.region || "in",
          },
          { loginUser, apiError }
        )
          .then(() => {
            apiRedirect();
          })
          .catch(e => {
            toastr.error(e?.response?.data?.msg || e?.message);
            apiRedirect();
          });
      } catch (error) {
        console.error("Google Authentication Failure!", error.message);
        AppAnalytics.track("login.oauth.request.failure", {
          id_token: hashParams.id_token,
          msg: JSON.stringify(error.message),
        });
        apiError("Google Authentication Failed! Please try again");
      }
    }
  }

  DummyView = () => {
    const region = useSelector(state => state.userInfo.region);
    useEffect(() => {
      this.setState({
        region: region,
      });
    }, [region]);
    return <></>;
  };

  handleGoogleLogin() {
    const baseURL = "https://accounts.google.com/o/oauth2/v2/auth";
    const params = {
      response_type: "id_token permission",
      client_id: clientId,
      scope: "openid email profile",
      redirect_uri: window.location.origin + window.location.pathname,
      nonce: randomString(10),
      state: encodeURIComponent(
        JSON.stringify({
          ...getUtmParams(),
          ...queryString.parse(window.location?.search),
          region: this.state.region,
        })
      ),
    };

    window.location.href = createGetURL(baseURL, params);
  }
  handleOptions() {
    this.setState({ showOption: !this.state.showOption });
  }

  render() {
    const { buttonText } = this.props;
    const { options } = this.props;
    return (
      <div
        className={`flex flex-row text-gray-900 font-medium text-1.0625 hover:text-gray-900 focus:text-gray-900 focus:ring-2 w-full rounded-md items-center relative ${
          this.state.region !== ""
            ? " google-button"
            : " google-button-disabled"
        }`}
        onBlur={event => {
          if (!event.currentTarget.contains(event.relatedTarget)) {
            this.setState({ ...this.state, showOption: false });
          }
        }}
      >
        <this.DummyView />
        <button
          disabled={this.state.region === ""}
          className={`btn  text-gray-900 justify-center font-medium text-1.0625 hover:text-gray-900 focus:text-gray-900  flex-row flex items-center ${
            options ? `w-11/12 ` : `w-full`
          }`}
          onClick={() => {
            this.handleGoogleLogin();
          }}
        >
          <img
            className={`h-6 w-6 ${options ? `mr-2` : `mr-2`}`}
            alt="google logo"
            src={GoogleLogo}
          />
          <p className="mb-0">{buttonText || "Continue with Google"}</p>
        </button>
        {options && (
          <button
            className={`items-center ${options ? `w-1/12` : `w-0`}`}
            onClick={() => {
              this.handleOptions();
            }}
          >
            <img className=" h-6 w-6" alt="google logo" src={ThreeDots} />
          </button>
        )}
        {this.state.showOption && (
          <div className="z-20 bg-gray-cool2 top-16 right-0  w-max rounded absolute text-center text-white px-4 py-2 shadow">
            {options.map((o, i) => (
              <button
                className="flex text-center pt-1"
                onClick={o.handleShowFields}
                key={`${i}${i * i}`}
              >
                {o.message}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const mapStatetoProps = () => {
  return {};
};

export default connect(mapStatetoProps, {
  apiError,
  verifyGoogleAuth,
})(GoogleLoginButton);
