export const validateFields = (
  roomName = "",
  setRoomNameErr,
  setErrors,
  onlyRoomNameCheck = false
) => {
  // check room name
  let isRoomName = false;

  const roomNameRegex = [
    {
      regex: /[\S\s]+/,
      msg: "Name cannot be empty",
    },
    {
      regex: /^[a-zA-Z0-9-.:_' ]+$/g,
      msg: (
        <>
          Invalid character(s) being used. Accepted special <br />
          characters are . _ : - '
        </>
      ),
    },
    {
      regex: /^[\S\s]{3,}$/,
      msg: "Name requires minimum 3 characters.",
    },
    {
      regex: /^[\S\s]{3,80}$/,
      msg: "Name can have maximum 80 characters.",
    },
  ];
  let room_name_err = "";
  for (let i = 0; i < roomNameRegex.length; i++) {
    const validRoomPattern = new RegExp(roomNameRegex[i].regex);
    if (validRoomPattern.test(roomName)) {
      room_name_err = "";
    } else {
      room_name_err = roomNameRegex[i].msg;
      break;
    }
  }
  if (room_name_err !== "") {
    isRoomName = false;
    setRoomNameErr(room_name_err);
  } else {
    isRoomName = true;
    setRoomNameErr("");
  }

  if (onlyRoomNameCheck === false) {
    setErrors(prevErrors => {
      return {
        ...prevErrors,
        general: !isRoomName,
        role: false,
        roomName: !isRoomName,
      };
    });
  }

  return isRoomName;
};
