import React, { RefObject, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import * as echarts from "echarts";
import { echartsEventsConfig } from "./EchartsEventsConfig";

function ECharts(props: {
  options: echarts.EChartsOption;
  style: {};
  className: string;
  loading: boolean;
  message: any;
  chartRefFromComp: RefObject<HTMLDivElement>;
}) {
  const { options, style, className, loading, message, chartRefFromComp } =
    props;
  const [chart, setChart] = useState<echarts.ECharts>();
  const chartRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const chart = echarts.init(
      chartRefFromComp?.current ? chartRefFromComp.current : chartRef.current,
      "100ms-echarts"
    ); // echarts theme

    chart.on("highlight", function (params: any) {
      echartsEventsConfig({ params: { name: className, ...params } });
    });

    chart.setOption({ ...options, resizeObserver }, true);
    // second param is for 'noMerge'

    setChart(chart);
    if (resizeObserver) {
      resizeObserver.observe(
        chartRefFromComp
          ? (chartRefFromComp.current as HTMLDivElement)
          : (chartRef.current as HTMLDivElement)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (!chart) {
      return;
    }
    if (loading) {
      chart.showLoading();
      return;
    }

    chart.hideLoading();
  }, [chart, loading]);

  useEffect(() => {
    if (chart && options && message) {
      chart.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const newStyle = {
    height: 350,
    ...style,
  };

  return (
    <div className="echarts-parent w-full relative">
      <div
        ref={
          chartRefFromComp
            ? chartRefFromComp
            : (chartRef as React.RefObject<HTMLDivElement>)
        }
        style={newStyle}
        className={classnames("echarts-react", className)}
      />
      {message ? <div className="no-data">{message}</div> : null}
    </div>
  );
}

const resizeObserver = new window.ResizeObserver(entries => {
  entries.forEach(({ target }) => {
    const instance = echarts.getInstanceByDom(target as HTMLDivElement);
    if (instance) {
      instance.resize();
    }
  });
});

export default React.memo(ECharts);
