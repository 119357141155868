//React
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isEmpty, isEqual } from "lodash";
import Tag from "src/components/Tag/Tag";
import { v4 as uuid } from "uuid";
import { InfoIcon } from "@100mslive/react-icons";
//UI Lib
import {
  Button,
  Flex,
  Label,
  Switch,
  Text,
  Tooltip,
} from "@100mslive/react-ui";
import EventDetails from "./event";
import EventCard from "./eventCard";
import EventInspectorState from "./eventInspectorState";
//Utility
import FilterSearch from "../../components/Common/FilterSearch";
import { API_CALL_STATE } from "../../constants";
import { AppAnalytics } from "../../helpers/analytics_helper";
//Store
import {
  clearRoomDebuggerDetails,
  clearRoomDebuggerFilter,
  fetchRoomDebuggerDetails,
  mergeAndFetchHistoricalEvents,
  mergeNewEventsFetched,
  toggleLiveEvents,
  updateRoomDebuggerFilter,
} from "../../store/analytics/actions";

const analyticsInputFields = [
  {
    label: "Peer Name",
    placeholder: "Optional",
    _key: "user_name",
  },
  {
    label: "Event Name",
    placeholder: "Optional",
    _key: "event_name",
  },
  {
    label: "User ID",
    placeholder: "Optional",
    _key: "user_id",
  },
];

const LiveEventsToggle = ({ checked, onCheckedChange, toggle, disabled }) => {
  const liveSwitch = (
    <Flex align="center" gap="2">
      <Text
        variant="caption"
        className="flex items-center ml-8"
        css={{ c: "$textHighEmp" }}
      >
        Live Event Updates
      </Text>
      <Switch
        checked={checked}
        onCheckedChange={onCheckedChange}
        disabled={disabled}
      />
    </Flex>
  );
  return toggle ? (
    liveSwitch
  ) : (
    <>
      <Tooltip
        title="Live events are disabled for ended rooms"
        side="top"
        align="center"
      >
        {liveSwitch}
      </Tooltip>
    </>
  );
};

function Analytics() {
  const {
    roomDebuggerDetails,
    roomDebuggerDetailsStatus,
    analyticsDerivedStates,
    roomDebuggerFilter,
  } = useSelector(state => state.roomDebugger);
  const dispatch = useDispatch();
  const [event, setEvent] = useState({});
  const [eventId, setEventId] = useState("");
  const [events, setEvents] = useState([]);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const validFilter = !!roomDebuggerFilter?.room_or_session_id;
  useEffect(() => {
    return () => {
      dispatch(clearRoomDebuggerDetails());
      dispatch(clearRoomDebuggerFilter());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      !isEmpty(roomDebuggerDetails.event) &&
      !isEqual(events, roomDebuggerDetails.event)
    ) {
      const tempEvents = roomDebuggerDetails.event.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setEvents([...tempEvents]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomDebuggerDetails.event]);

  const Loader = () => (
    <Text css={{ textAlign: "center", py: "$4" }}>Loading events...</Text>
  );

  const updateFilter = useCallback(
    ({ key, value }) => {
      dispatch(
        updateRoomDebuggerFilter({
          key,
          value,
        })
      );
      if (key === "error_events" && validFilter) {
        dispatch(fetchRoomDebuggerDetails());
      }
    },
    [dispatch, validFilter]
  );

  const mergeNewEvents = useCallback(
    async () => dispatch(mergeNewEventsFetched()),
    [dispatch]
  );
  const resetEvents = () => {
    dispatch(clearRoomDebuggerFilter());
    setEvent({});
    setEvents([]);
    dispatch({ type: "FETCH_ROOM_DEBUGGER_DETAILS_RESET" });
  };

  const searchEvents = validFilter => {
    dispatch(clearRoomDebuggerDetails());
    setEvents([]);
    if (validFilter) {
      dispatch(fetchRoomDebuggerDetails());
    }
    AppAnalytics.track("btn.clicked", {
      btnId: "eventInspector.search",
      platform: "hms-dashboard",
      searchId: uuid(),
      ...roomDebuggerFilter,
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const queryId = params.get("id");
    if (queryId) {
      updateFilter({ key: "room_or_session_id", value: queryId });
      searchEvents(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Flex
      direction="column"
      css={{ height: "100%", flexFlow: "column", display: "flex" }}
    >
      <Flex justify="start" align="center" gap="2" css={{ flex: " 0 1 auto" }}>
        <Text variant="h5" css={{ c: "$textHighEmp" }}>
          Events Inspector
        </Text>
        <Tag
          css={{
            fontSize: "$tiny",
            bg: "$primaryDefault",
            r: "$0",
            py: "$2",
            px: "$4",
          }}
          variant="overline"
          label="BETA"
        />
      </Flex>
      <Flex css={{ flex: " 0 1 auto" }}>
        <Text variant="caption" css={{ mt: "$2", c: "$textMedEmp" }}>
          Efficiently troubleshoot and debug errors.{" "}
          <a
            href={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/how--to-guides/configure-webhooks/webhook`}
            className="text-primary-light"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More.
          </a>
        </Text>
      </Flex>
      <Flex direction="column" css={{ mt: "$12", flex: " 0 1 auto" }}>
        <Flex align="center" css={{ mb: "$4" }}>
          <Text variant="caption">Room or Session ID</Text>
          <Tooltip
            side="bottom"
            title={
              <Text variant="caption" css={{ w: "$60", p: "$2" }}>
                It is mandatory to input either Room ID or Session ID to search
                for events. You can find the respective identifiers in{" "}
                <Link
                  to="/rooms"
                  className="text-blue-tint hover:text-blue-tint"
                >
                  {" "}
                  rooms{" "}
                </Link>
                and
                <Link
                  to="/sessions"
                  className="text-blue-tint hover:text-blue-tint"
                >
                  {" "}
                  sessions
                </Link>
                .
              </Text>
            }
          >
            <span className="relative -top-0.5 left-1">
              <InfoIcon width={16} height={16} />
            </span>
          </Tooltip>
        </Flex>
        <Flex>
          <FilterSearch
            placeholder="Search with Room/Session ID"
            componentId="eventInspector"
            filters={analyticsInputFields}
            resetDisabled={!validFilter}
            applyDisabled={!validFilter}
            invalidInputErrorString="Please check the room/session ID entered"
            inputValue={roomDebuggerFilter?.room_or_session_id}
            filterValues={roomDebuggerFilter}
            onFilterChange={({ key, value }) => {
              if (roomDebuggerDetailsStatus !== API_CALL_STATE.IN_PROGRESS) {
                updateFilter({ key: key, value: value });
              }
            }}
            onFilterRemove={key => updateFilter({ key: key, value: "" })}
            onInputChange={val => {
              if (roomDebuggerDetailsStatus !== API_CALL_STATE.IN_PROGRESS) {
                updateFilter({ key: "room_or_session_id", value: val });
              }
            }}
            applyFunction={() => {
              if (roomDebuggerDetailsStatus !== API_CALL_STATE.IN_PROGRESS) {
                searchEvents(validFilter);
              }
            }}
            resetFunction={() => {
              const filterDetails = { ...roomDebuggerFilter };
              resetEvents();
              dispatch(
                updateRoomDebuggerFilter({
                  key: "room_or_session_id",
                  value: filterDetails.room_or_session_id,
                })
              );
              dispatch(
                updateRoomDebuggerFilter({
                  key: "error_events",
                  value: filterDetails.room_or_session_id,
                })
              );
            }}
            loading={roomDebuggerDetailsStatus === API_CALL_STATE.IN_PROGRESS}
          />
          <Button
            css={{ ml: "$8", h: "42px", r: "$0" }}
            loading={roomDebuggerDetailsStatus === API_CALL_STATE.IN_PROGRESS}
            disabled={!validFilter}
            onClick={() => searchEvents(validFilter)}
          >
            <Text variant="body2">Show Events</Text>
          </Button>
        </Flex>
      </Flex>
      <Flex
        css={{ w: "100%", py: "$8", flex: "1 1 auto", mt: "$12" }}
        direction="column"
      >
        <Flex css={{ h: "100%", w: "100% " }} justify="between" align="center">
          <Flex css={{ gap: "$2", mb: "$2" }} align="center">
            <Text variant="h6" className="flex items-center">
              Events Activity
            </Text>
            <Flex
              css={{
                w: "$px",
                bg: "$surfaceLight",
                minHeight: "100%",
                h: "$10",
                mx: "$6",
              }}
            />
            <Flex align="center">
              <Label htmlFor="errorSwitch">
                <Text
                  variant="caption"
                  css={{ mr: "$8", color: "$textHighEmp" }}
                >
                  Show Error Events Only
                </Text>
              </Label>
              <Switch
                checked={roomDebuggerFilter?.error_events}
                onCheckedChange={() => {
                  updateFilter({
                    key: "error_events",
                    value: !roomDebuggerFilter?.error_events,
                  });
                  searchEvents(validFilter);
                }}
                disabled={
                  roomDebuggerDetailsStatus === API_CALL_STATE.IN_PROGRESS
                }
              />
            </Flex>
          </Flex>

          <LiveEventsToggle
            checked={analyticsDerivedStates?.liveEventsEnabled}
            onCheckedChange={() => {
              dispatch(
                toggleLiveEvents(!analyticsDerivedStates.liveEventsEnabled)
              );
            }}
            disabled={
              !validFilter ||
              (!events.length && !roomDebuggerFilter.error_events) ||
              !roomDebuggerDetails?.isLive
            }
            toggle={roomDebuggerDetails?.isLive}
          />
        </Flex>
        <Flex
          css={{
            overflow: "scroll-y",
            scroll: "hidden",
            w: "100%",
            minHeight: "600px",
            r: "$2",
            p: "$6 $8",
            mt: "$xs",
            border: "$space$px solid $borderLight",
          }}
        >
          {!events?.length ? (
            <Flex
              css={{ w: "100%", p: "$40" }}
              direction="column"
              justify="center"
              align="center"
            >
              <EventInspectorState
                isErrorOnly={roomDebuggerFilter.error_events}
                hasSearched={roomDebuggerDetailsStatus}
                hasEvents={events.length}
                loading={
                  roomDebuggerDetailsStatus === API_CALL_STATE.IN_PROGRESS
                }
                disableErrorEventsOnly={() =>
                  updateFilter({ key: "error_events", value: false })
                }
              />
            </Flex>
          ) : (
            <>
              <Flex
                css={{
                  overflow: "scroll-y",
                  scroll: "hidden",
                  p: "$2 $0",
                  minWidth: "440px",
                  gap: "$4",
                  minHeight: "600px",
                }}
                direction="column"
                id="scrollableDiv"
              >
                {!!roomDebuggerDetails?.newEvent?.length && (
                  <Flex
                    justify="center"
                    alignt="center"
                    css={{ w: "100%", pt: "auto" }}
                  >
                    <Flex
                      css={{
                        r: "$2",
                        gap: "$2",
                      }}
                      justify="center"
                      align="center"
                    >
                      <Button
                        variant="standard"
                        css={{ py: "$1", h: "$10", px: "$2" }}
                        onClick={() => {
                          mergeNewEvents();
                        }}
                      >
                        <Text variant="sub1" css={{ w: "max-content" }}>
                          {roomDebuggerDetails?.newEvent?.length} new events
                        </Text>
                      </Button>
                    </Flex>
                  </Flex>
                )}

                {!!events?.length && (
                  <InfiniteScroll
                    dataLength={events?.length}
                    scrollableTarget="scrollableDiv"
                    height={roomDebuggerDetails?.newEvent?.length ? 550 : 600}
                    next={() => {
                      dispatch(mergeAndFetchHistoricalEvents());
                    }}
                    hasMore={
                      analyticsDerivedStates?.liveEventsEnabled
                        ? false
                        : !!roomDebuggerDetails.next_page
                    }
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>No more events to display</b>
                      </p>
                    }
                    loader={<Loader />}
                  >
                    {events.map((event, index) => {
                      const error =
                        !!event?.data?.sdk_payload?.error_message ||
                        !!event?.data?.sdkPayload?.error_message ||
                        !!event.data?.error?.message;
                      return (
                        <EventCard
                          title={event.type}
                          time={event.timestamp}
                          key={index}
                          index={index}
                          active={eventId === event._id}
                          onClick={() => {
                            setEventId(event._id);
                            setEvent(event);
                            AppAnalytics.track("btn.clicked", {
                              btnId: "eventInspector.event",
                              platform: "hms-dashboard",
                              eventName: event.type,
                              error: error,
                              searchId: uuid(),
                            });
                          }}
                          error={error}
                        />
                      );
                    })}
                  </InfiniteScroll>
                )}
              </Flex>
              <Flex
                css={{
                  height: "100%",
                  borderRight: "$space$2 solid $borderLight",
                }}
              />
              <Flex css={{ w: "calc(100% - 440px)" }}>
                <EventDetails
                  event={event}
                  showDisclaimer={showDisclaimer}
                  setShowDisclaimer={setShowDisclaimer}
                />
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default connect(state => state, {
  fetchRoomDebuggerDetails,
})(Analytics);
