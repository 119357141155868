import React, { ComponentProps, MutableRefObject } from "react";
import { ColorResult, CustomPicker, SketchPicker } from "react-color";
import { Flex, Input } from "@100mslive/react-ui";
type Props = {
  reference: MutableRefObject<HTMLDivElement>;
  color: string;
  setColor: (color: string) => void;
};
export const colorInput = (props: ComponentProps<typeof Input>) => (
  <Input {...props} />
);
export const ColorInput = CustomPicker(colorInput);

function PrebuiltHuePicker({ color, setColor, reference }: Props) {
  return (
    <>
      <Flex
        css={{
          w: `${
            reference?.current
              ? Math.floor(reference?.current?.offsetWidth) - 16
              : 0
          }px`,
          position: "relative",
          overflow: "hidden",
          height: "280px",
        }}
        direction="column"
      >
        <SketchPicker
          onChange={(e: ColorResult) => {
            const hexColor = e.hex;
            setColor(hexColor);
          }}
          disableAlpha={true}
          color={color}
          width={`${
            reference?.current
              ? Math.floor(reference?.current?.offsetWidth) - 36
              : 0
          }px`}
          presetColors={[]}
          styles={{
            default: {
              picker: {
                color: "black",
              },
            },
          }}
        />
      </Flex>
    </>
  );
}

export default CustomPicker(PrebuiltHuePicker);
