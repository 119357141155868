import { get, has, set } from "lodash";
import {
  WorkspacePeerInsightDataStruct,
  WorkspaceRecordingsInsightsEvent,
  WorkspaceRecordingsTATInsightsEvent,
} from "src/types/insightsTypes";
import {
  peerGroupByOptions,
  WORKSPACE_RECORDINGS_TYPE_BUCKETS,
} from "../constants";

export const groupByActivePeers = ({
  workspacePeerInsightsDetails,
  groupBy,
}: {
  workspacePeerInsightsDetails: WorkspacePeerInsightDataStruct[];
  groupBy: string;
}) => {
  let data_map = {} as Record<string, Record<string, number>>;
  const rocksetKey =
    peerGroupByOptions?.[groupBy as keyof typeof peerGroupByOptions]?.rname;
  for (let i = 0; i < workspacePeerInsightsDetails?.length; i++) {
    const time = workspacePeerInsightsDetails[i].received_at;
    const groupByValue =
      workspacePeerInsightsDetails[i][
        rocksetKey as keyof WorkspacePeerInsightDataStruct
      ];
    if (!has(data_map?.[groupByValue], time)) {
      data_map = {
        ...data_map,
        [groupByValue]: {
          ...data_map?.[groupByValue],
          [time]: workspacePeerInsightsDetails[i].peer_cnt,
        },
      };
    } else {
      const val = data_map?.[groupByValue]?.[time];
      data_map = {
        ...data_map,
        [groupByValue]: {
          ...data_map[groupByValue],
          [time]: val + workspacePeerInsightsDetails[i].peer_cnt,
        },
      };
    }
  }
  return data_map;
};

export const groupByActivePeersDuration = ({
  workspacePeerInsightsDetails,
  groupBy,
}: {
  workspacePeerInsightsDetails: WorkspacePeerInsightDataStruct[];
  groupBy: string;
}) => {
  let data_map = {} as Record<string, Record<string, number>>;
  const rocksetKey =
    peerGroupByOptions?.[groupBy as keyof typeof peerGroupByOptions]?.rname;
  for (let i = 0; i < workspacePeerInsightsDetails?.length; i++) {
    const time = workspacePeerInsightsDetails[i].received_at;
    const groupByValue =
      workspacePeerInsightsDetails[i][
        rocksetKey as keyof WorkspacePeerInsightDataStruct
      ];
    if (!has(data_map?.[groupByValue], time)) {
      const duration = workspacePeerInsightsDetails[i].avg_duration_min;
      const cnt = workspacePeerInsightsDetails[i].peer_cnt;
      const totalDuration = duration * cnt;
      data_map = {
        ...data_map,
        [groupByValue]: {
          ...data_map?.[groupByValue],
          [time]: totalDuration,
        },
      };
    } else {
      const val = data_map?.[groupByValue]?.[time];

      const duration = workspacePeerInsightsDetails[i].avg_duration_min;
      const cnt = workspacePeerInsightsDetails[i].peer_cnt;
      const totalDuration = duration * cnt;
      data_map = {
        ...data_map,
        [groupByValue]: {
          ...data_map[groupByValue],
          [time]: val + totalDuration,
        },
      };
    }
  }
  return data_map;
};

// eslint-disable-next-line complexity
export const getGroupByRecordingsTypeArray = ({
  groupByRecordingsType,
  workspaceRecordingsInsightsDetails,
  workspaceRecordingsTATInsightsDetails,
}: {
  groupByRecordingsType: string;
  workspaceRecordingsInsightsDetails: WorkspaceRecordingsInsightsEvent[];
  workspaceRecordingsTATInsightsDetails: WorkspaceRecordingsTATInsightsEvent[];
}) => {
  let data_map = {
    recordings: {} as Record<string, WorkspaceRecordingsInsightsEvent[]>,
    TATBreakdown: {} as Record<string, number>,
  };
  const rocksetKey =
    WORKSPACE_RECORDINGS_TYPE_BUCKETS?.[
      groupByRecordingsType as keyof typeof WORKSPACE_RECORDINGS_TYPE_BUCKETS
    ]?.rname;

  for (let i = 0; i < workspaceRecordingsInsightsDetails?.length; i++) {
    const time = workspaceRecordingsInsightsDetails[i].received_at;
    const recordings = data_map?.recordings;
    // sending all recordings if rocksetKey is null
    if (rocksetKey === null) {
      if (!has(recordings, time)) {
        data_map = set(
          data_map,
          ["recordings", time],
          [workspaceRecordingsInsightsDetails[i]]
        );
      } else {
        const val = recordings?.[time];
        val.push(workspaceRecordingsInsightsDetails[i]);
        data_map = set(data_map, ["recordings", time], val);
      }
    } else {
      // sending only recordings of type rocksetKey
      if (workspaceRecordingsInsightsDetails[i].recording_type === rocksetKey) {
        if (!has(recordings, time)) {
          data_map = set(
            data_map,
            ["recordings", time],
            [workspaceRecordingsInsightsDetails[i]]
          );
        } else {
          const val = recordings?.[time];
          val.push(workspaceRecordingsInsightsDetails[i]);
          data_map = set(data_map, ["recordings", time], val);
        }
      }
    }
  }
  for (let i = 0; i < workspaceRecordingsTATInsightsDetails?.length; i++) {
    const time = workspaceRecordingsTATInsightsDetails[i].tat_min;
    const TATBreakdown = data_map?.TATBreakdown;
    const val = workspaceRecordingsTATInsightsDetails[i].cnt;
    // sending all recordings if rocksetKey is null
    if (rocksetKey === null) {
      if (!has(TATBreakdown, time)) {
        data_map = set(data_map, ["TATBreakdown", time], val);
      } else {
        const prevVal = get(data_map, ["TATBreakdown", time]);
        data_map = set(data_map, ["TATBreakdown", time], prevVal + val);
      }
    } else {
      // sending only recordings of type rocksetKey
      if (
        workspaceRecordingsTATInsightsDetails[i].recording_type === rocksetKey
      ) {
        if (!has(TATBreakdown, time)) {
          data_map = set(data_map, ["TATBreakdown", time], val);
        } else {
          const prevVal = get(data_map, ["TATBreakdown", time]);
          data_map = set(data_map, ["TATBreakdown", time], prevVal + val);
        }
      }
    }
  }
  return data_map;
};
