import React from "react";
import s from "./GridPerspectiveFloor.module.css";
function GridPerspectiveFloor() {
  return (
    <div className={s["grid-wrapper"]}>
      <div className={s["grid"]}>
        <div className={s["vertical"]}>
          {[...Array(11)].map(() => (
            <div className={s["line"]}></div>
          ))}
        </div>
        <div className={s["dots"]}>
          {[...Array(9)].map(() => (
            <div className={s["dot"]}></div>
          ))}
        </div>
        <div className={s["horizontal"]}>
          {[...Array(11)].map(() => (
            <div className={s["line"]}></div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GridPerspectiveFloor;
