import React from "react";
import { Box, Button, Flex, Text } from "@100mslive/react-ui";
import { ContentProps } from "../../interface";

const FormContent: React.FC<ContentProps> = ({
  header,
  innerContent,
  footer,
}) => (
  <Box
    css={{
      w: "min(65vw, 800px)",
      border: "1px solid $borderDefault",
      borderRadius: "$1",
      background:
        "linear-gradient(117deg, rgba(19, 22, 27, 0.42) 0.83%, rgba(19, 22, 27, 0.28) 72.15%)",
      boxShadow: "0px 20px 32px 0px rgba(0, 0, 0, 0.05)",
      backdropFilter: "blur(8px)",
      zIndex: 1,
    }}
  >
    {/* Header */}
    <Flex
      justify="between"
      align="center"
      css={{
        p: "$10 $11",
        pr: "$10",
        borderBottom: "1px solid $borderDefault",
      }}
    >
      <Flex align="center" css={{ gap: "$10", color: "$primaryLight" }}>
        {header.icon}
        <Box>
          <Text variant="h6" css={{ color: "$textHighEmp" }}>
            {header.title}
          </Text>
          <Text variant="sm" css={{ c: "$textMedEmp", mt: "$2" }}>
            {header.subtitle}
          </Text>
        </Box>
      </Flex>

      {header?.topRightComp ? header?.topRightComp : null}
    </Flex>

    {/* Inner Content */}
    <Flex justify="between" align="center">
      {innerContent}
    </Flex>

    {/* Footer */}
    {footer ? (
      <Flex
        justify={footer?.text ? "between" : "end"}
        align="center"
        css={{
          gap: "$md",
          borderTop: "1px solid $borderDefault",
          p: "$md $10",
        }}
      >
        {footer?.text ? (
          <Text variant="caption" css={{ c: "$textDisabled" }}>
            {footer?.text}
          </Text>
        ) : null}
        <Flex
          justify="center"
          css={{
            gap: "$md",
          }}
        >
          {footer?.secondaryCTA ? (
            <Button
              variant="standard"
              outlined
              disabled={footer?.disableSecondary}
              onClick={() => footer.secondaryCTA?.onClick()}
              css={{ r: "$0" }}
            >
              {footer.secondaryCTA.text}
            </Button>
          ) : (
            ""
          )}
          {footer?.primaryCTA ? (
            <Button
              disabled={footer?.disablePrimary}
              onClick={() => footer.primaryCTA?.onClick()}
              css={{ r: "$0" }}
            >
              {footer.primaryCTA.text}
            </Button>
          ) : (
            ""
          )}
        </Flex>
      </Flex>
    ) : null}
  </Box>
);

export default FormContent;
