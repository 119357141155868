import { isEmpty } from "lodash";
import moment from "moment";
import { echartsDataZoomConfig } from "src/components/Echarts/EchartsConfigs";
import {
  peerAnalyticsPublishType,
  peerPublishValueType,
} from "src/types/analytics";
type echartsOptions = echarts.EChartsOption;

const getSimulcastEnabled = (data: peerAnalyticsPublishType) => {
  let hasSimulcastVideoEnabled = false;
  if (
    isEmpty(data?.video?.q) &&
    isEmpty(data?.video?.h) &&
    isEmpty(data?.video?.f)
  ) {
    hasSimulcastVideoEnabled = false;
  } else {
    hasSimulcastVideoEnabled = true;
  }
  return hasSimulcastVideoEnabled;
};

const publishTrackKeys = {
  video: {
    q: "Low",
    h: "Medium",
    f: "High",
    null: "",
  },
  audio: "audio",
  screen: "screen",
};

const videoTrackDetails = {
  q: { name: "Low", color: "#FF8000" },
  h: { name: "Medium", color: "#EDC602" },
  f: { name: "High", color: "#14B881" },
  null: { name: "", color: "#14B881" },
};

//bitrate config
export const getPublisherBitrateChartConfiguration = (
  publishBitrateData: peerAnalyticsPublishType
) => {
  if (isEmpty(publishBitrateData)) {
    return {};
  }
  const arrObj = {
    type: "line",
    data: [],

    lineStyle: {
      type: "solid",
    },
    zlevel: 1,
    symbol: "circle",
    markLine: {
      zlevel: 0,
      label: {
        show: false,
      },
      silent: true,
      symbol: ["none", "none"],
      lineStyle: {
        color: "#2C333F",
        type: "solid",
      },
    },
  } as echartsOptions["series"];
  const hasSimulcastVideoEnabled = getSimulcastEnabled(publishBitrateData);

  const getAudioChartConfiguration = () => {
    if (isEmpty(publishBitrateData?.audio)) {
      return [];
    }

    return [
      {
        ...arrObj,
        name: "Audio",
        color: "#CB525E",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#CB525E",
        },

        data: publishBitrateData?.audio?.map((r: peerPublishValueType) => {
          const val = r?.bitrate || 0;
          return {
            value: [r.timestamp, Number(val / 1024).toFixed(2)],
          };
        }),
      },
    ];
  };

  const getScreenChartConfiguration = () => {
    if (isEmpty(publishBitrateData?.screen)) {
      return [];
    }

    return [
      {
        ...arrObj,
        name: "Screen Share",
        color: "#913FFC",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#913FFC",
        },
        data: publishBitrateData?.screen?.map((r: peerPublishValueType) => {
          const val = r?.bitrate || 0;
          return {
            value: [r.timestamp, Number(val / 1024).toFixed(2)],
          };
        }),
      },
    ];
  };

  const getVideoChartConfiguration = () => {
    if (isEmpty(publishBitrateData?.video)) {
      return [];
    }

    if (hasSimulcastVideoEnabled) {
      return Object.keys(publishTrackKeys?.video).map(key => {
        const abbr =
          videoTrackDetails[key as keyof typeof videoTrackDetails].name;
        const name = abbr ? `Video (${abbr})` : "Video";
        if (key === "null") {
          // eslint-disable-next-line array-callback-return
          return;
        }

        return {
          ...arrObj,
          name: name,
          color: videoTrackDetails[key as keyof typeof videoTrackDetails].color,
          lineStyle: {
            //@ts-ignore
            ...arrObj?.lineStyle,
            color:
              videoTrackDetails[key as keyof typeof videoTrackDetails].color,
          },
          data: publishBitrateData?.video?.[key]?.map(
            (r: peerPublishValueType) => {
              const val = r?.bitrate || 0;
              return {
                value: [r.timestamp, Number(val / 1024).toFixed(2)],
              };
            }
          ),
        };
      });
    } else {
      return Object.keys(publishBitrateData?.video).map(key => {
        return {
          ...arrObj,
          name: "Video",
          color: "#00BE78",
          lineStyle: {
            //@ts-ignore
            ...arrObj?.lineStyle,
            color: "#00BE78",
          },
          data: publishBitrateData?.video?.[key]?.map(
            (r: peerPublishValueType) => {
              const val = r?.bitrate || 0;
              return {
                value: [r.timestamp, Number(val / 1024).toFixed(2)],
              };
            }
          ),
        };
      });
    }
  };
  const videoSeries = getVideoChartConfiguration();
  const audioSeries = getAudioChartConfiguration();
  const screenSeries = getScreenChartConfiguration();
  const data = [...videoSeries, ...audioSeries, ...screenSeries];
  let legendData = [];

  if (hasSimulcastVideoEnabled) {
    legendData = [
      { name: "Video (High)", itemStyle: { color: "#14B881" } },
      { name: "Video (Medium)", itemStyle: { color: "#EDC602" } },
      { name: "Video (Low)", itemStyle: { color: "#FF8000" } },

      { name: "Audio", itemStyle: { color: "#CB525E" } },
      { name: "Screen Share", itemStyle: { color: "#913FFC" } },
    ];
  } else {
    legendData = [
      { name: "Video", itemStyle: { color: "#00BE78" } },
      { name: "Audio", itemStyle: { color: "#CB525E" } },
      { name: "Screen Share", itemStyle: { color: "#913FFC" } },
    ];
  }

  let peerPublishBitrateChartoptions: echartsOptions;
  if (data.length === 0) {
    peerPublishBitrateChartoptions = {};
  } else {
    peerPublishBitrateChartoptions = {
      legend: {
        left: "3%",
        z: 2,
        type: "plain",
        orient: "horizontal",
        show: true,
        icon: "path://M 0.0059092,12.043317 -4.5139121e-5,-0.00425602 12.004559,0.00271404 12.003432,11.995198 Z M 11.002035,0.95440869 1.0091504,0.96081353 1.1075267,10.984948 11.124326,11.022966 Z M 4.2153024,9.9284753 9.9624907,2.6594679 9.3041655,2.0786005 4.1297492,8.8221042 2.4751969,7.4195436 1.9393024,8.0391743",
        textStyle: { color: "#FFFFFF" },
        data: legendData,
      },
      axisPointer: {
        triggerOn: "mousemove",
      },
      tooltip: {
        axisPointer: {
          triggerEmphasis: true,
          link: [
            {
              xAxisIndex: "all",
            },
          ],
        },
        trigger: "axis", // Trigger tooltip on item (icon) hover
        backgroundColor: "#1E232A",
        borderRadius: 4,
        confine: true,
        padding: 4,
        borderColor: "#1E232A",
        textStyle: {
          color: "#F5F9FF",
        },

        // formatter: function (params) {
        //   return `${(params as []).map(param => {
        //     // @ts-ignore
        //     const msg = param?.data?.value?.[1] || "";
        //     // @ts-ignore
        //     return `<div style={{width:"10px", height:"10px", color:"${param?.color}"}}></div>&nbsp;${param?.seriesName}&nbsp;${msg}<br/>`;
        //   })}`;
        // },
      },
      xAxis: {
        type: "time",
        triggerEvent: true,
        splitLine: {
          show: true,
          interval: "auto",
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      toolbox: {
        right: "5%",
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            icon: {
              zoom: `M 5.77783,7.44444 H 9.11116 M 7.44446,5.77777 V 9.1111 M 13,13 10.5834,10.5833 m -3.13896,1.3056 c 2.45459,0 4.44446,-1.98987 4.44446,-4.44446 C 11.8889,4.98984 9.89903,3 7.44444,3 4.98984,3 3,4.98984 3,7.44444 3,9.89903 4.98984,11.8889 7.44444,11.8889 Z`,
              back: `path://m 4,6.70833 h 5.6875 c 0.3912,0 0.7786,0.07706 1.1401,0.22678 0.3614,0.14972 0.6898,0.36916 0.9665,0.6458 0.2766,0.27664 0.4961,0.60506 0.6458,0.96651 0.1497,0.36145 0.2268,0.74885 0.2268,1.14008 0,0.3912 -0.0771,0.7786 -0.2268,1.1401 -0.1497,0.3614 -0.3692,0.6898 -0.6458,0.9665 -0.2767,0.2766 -0.6051,0.4961 -0.9665,0.6458 -0.3615,0.1497 -0.7489,0.2268 -1.1401,0.2268 H 7.79167 M 6.70833,9.41667 4,6.70833 6.70833,4`,
            },
          },
          restore: {
            icon: `M 5.77778,10.2222 H 3 V 13 M 13,8 c 0,1.32608 -0.5268,2.5979 -1.4645,3.5355 C 10.5979,12.4732 9.32608,13 8,13 6.6022,12.9947 5.26054,12.4493 4.25556,11.4778 L 3,10.2222 M 12.9999,3 V 5.77778 H 10.2222 M 3,8 C 3,6.67392 3.52678,5.40215 4.46447,4.46447 5.40215,3.52678 6.67392,3 8,3 9.3978,3.00526 10.7395,3.55068 11.7444,4.52222 L 13,5.77778`,
          },
          saveAsImage: {
            icon: `path://M 8.00012,9.77777 V 2.66666 M 5.03709,6.81481 8.00005,9.77777 10.963,6.81481 m 2.3703,2.96296 v 2.37033 c 0,0.3144 -0.1249,0.6158 -0.3471,0.8381 -0.2223,0.2223 -0.5238,0.3471 -0.8381,0.3471 H 3.85181 c -0.31433,0 -0.61579,-0.1248 -0.83805,-0.3471 C 2.79149,12.7639 2.66663,12.4625 2.66663,12.1481 V 9.77777`,
          },
        },
      },
      grid: {
        top: "20%",
        left: "8%",
        right: "5%",
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          filterMode: "none",
          end: 100,
          xAxisIndex: [0, 1],

          labelFormatter: (_: any, valueStr: string | number | Date) => {
            const val = moment(new Date(valueStr)).format("HH:mm:ss");
            return val;
          },
          textStyle: { width: 70, overflow: "break" },
          ...echartsDataZoomConfig,
        },
        {
          type: "inside",
          realtime: true,
          start: 0,
          end: 100,
          filterMode: "none",
          xAxisIndex: [0, 1],
        },
      ],
      yAxis: {
        type: "value",
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },

        axisLabel: {
          formatter: (value: any) => {
            return value / 1000 >= 1
              ? `${(value / 1000).toFixed(1)} mbps`
              : `${value} kbps`;
          },
        },
      },
      //@ts-ignore
      series: [...data],
    };
  }
  return peerPublishBitrateChartoptions;
};

//rtt config
export const getPublisherRTTChartConfiguration = (
  publishRTTData: peerAnalyticsPublishType
) => {
  if (isEmpty(publishRTTData)) {
    return {};
  }
  const arrObj = {
    type: "line",
    data: [],
    symbol: "circle",
    lineStyle: {
      type: "solid",
    },
    markLine: {
      label: {
        show: false,
        position: "start",
        formatter: `{c} ms`,
        color: "#2C333F",
      },
      show: true,
      data: [{ yAxis: 300 }],

      symbol: ["none", "none"],
      lineStyle: {
        color: "#66A1FF",
        type: "dashed",
      },
    },
    zlevel: 1,
  } as echartsOptions["series"];
  const hasSimulcastVideoEnabled = getSimulcastEnabled(publishRTTData);

  const getAudioChartConfiguration = () => {
    if (isEmpty(publishRTTData?.audio)) {
      return [];
    }
    return [
      {
        ...arrObj,
        name: "Audio",
        color: "#CB525E",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#CB525E",
        },
        data: publishRTTData?.audio?.map((r: peerPublishValueType) => {
          const val = r?.rtt || 0;
          return {
            value: [r.timestamp, Number(val).toFixed(2)],
          };
        }),
      },
    ];
  };

  const getScreenChartConfiguration = () => {
    if (isEmpty(publishRTTData?.screen)) {
      return [];
    }
    return [
      {
        ...arrObj,
        name: "Screen Share",
        color: "#913FFC",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#913FFC",
        },
        data: publishRTTData?.screen?.map((r: peerPublishValueType) => {
          const val = r?.rtt || 0;
          return {
            value: [r.timestamp, Number(val).toFixed(2)],
          };
        }),
      },
    ];
  };

  const getVideoChartConfiguration = () => {
    if (isEmpty(publishRTTData?.video)) {
      return [];
    }

    if (hasSimulcastVideoEnabled) {
      return Object.keys(publishTrackKeys.video).map(key => {
        const abbr =
          videoTrackDetails[key as keyof typeof videoTrackDetails].name;
        const name = abbr ? `Video (${abbr})` : "Video";
        if (key === "null") {
          // eslint-disable-next-line array-callback-return
          return;
        }
        return {
          ...arrObj,
          name: name,
          color: videoTrackDetails[key as keyof typeof videoTrackDetails].color,
          lineStyle: {
            //@ts-ignore
            ...arrObj?.lineStyle,
            color:
              videoTrackDetails[key as keyof typeof videoTrackDetails].color,
          },
          data: publishRTTData?.video?.[key]?.map((r: peerPublishValueType) => {
            const val = r?.rtt || 0;
            return {
              value: [r.timestamp, Number(val).toFixed(2)],
            };
          }),
        };
      });
    } else {
      return Object.keys(publishRTTData?.video).map(key => {
        return {
          ...arrObj,
          name: "Video",
          color: "#00BE78",
          lineStyle: {
            //@ts-ignore
            ...arrObj?.lineStyle,
            color: "#00BE78",
          },
          data: publishRTTData?.video?.[key]?.map((r: peerPublishValueType) => {
            const val = r?.rtt || 0;
            return {
              value: [r.timestamp, Number(val).toFixed(2)],
            };
          }),
        };
      });
    }
  };
  const videoSeries = getVideoChartConfiguration();
  const audioSeries = getAudioChartConfiguration();
  const screenSeries = getScreenChartConfiguration();
  const data = [...videoSeries, ...audioSeries, ...screenSeries];
  let peerPublishRTTChartoptions: echartsOptions;

  let legendData = [];

  if (hasSimulcastVideoEnabled) {
    legendData = [
      { name: "Video (High)", itemStyle: { color: "#14B881" } },
      { name: "Video (Medium)", itemStyle: { color: "#EDC602" } },
      { name: "Video (Low)", itemStyle: { color: "#FF8000" } },

      { name: "Audio", itemStyle: { color: "#CB525E" } },
      { name: "Screen Share", itemStyle: { color: "#913FFC" } },
    ];
  } else {
    legendData = [
      { name: "Video", itemStyle: { color: "#00BE78" } },
      { name: "Audio", itemStyle: { color: "#CB525E" } },
      { name: "Screen Share", itemStyle: { color: "#913FFC" } },
    ];
  }
  if (data.length === 0) {
    peerPublishRTTChartoptions = {};
  } else {
    peerPublishRTTChartoptions = {
      legend: {
        z: 2,
        left: "3%",
        type: "plain",
        orient: "horizontal",
        show: true,
        icon: "path://M 0.0059092,12.043317 -4.5139121e-5,-0.00425602 12.004559,0.00271404 12.003432,11.995198 Z M 11.002035,0.95440869 1.0091504,0.96081353 1.1075267,10.984948 11.124326,11.022966 Z M 4.2153024,9.9284753 9.9624907,2.6594679 9.3041655,2.0786005 4.1297492,8.8221042 2.4751969,7.4195436 1.9393024,8.0391743",
        textStyle: { color: "#FFFFFF" },
        data: legendData,
      },
      axisPointer: {
        link: [
          {
            xAxisIndex: "all",
          },
        ],
      },

      tooltip: {
        axisPointer: {
          animation: false,
        },
        trigger: "axis", // Trigger tooltip on item (icon) hover
        backgroundColor: "#1E232A",
        borderRadius: 4,
        padding: 4,
        borderColor: "#1E232A",
        textStyle: {
          color: "#F5F9FF",
        },
      },
      xAxis: {
        type: "time",
        splitLine: {
          show: true,
          interval: "auto",
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      toolbox: {
        right: "5%",
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            icon: {
              zoom: `M 5.77783,7.44444 H 9.11116 M 7.44446,5.77777 V 9.1111 M 13,13 10.5834,10.5833 m -3.13896,1.3056 c 2.45459,0 4.44446,-1.98987 4.44446,-4.44446 C 11.8889,4.98984 9.89903,3 7.44444,3 4.98984,3 3,4.98984 3,7.44444 3,9.89903 4.98984,11.8889 7.44444,11.8889 Z`,
              back: `path://m 4,6.70833 h 5.6875 c 0.3912,0 0.7786,0.07706 1.1401,0.22678 0.3614,0.14972 0.6898,0.36916 0.9665,0.6458 0.2766,0.27664 0.4961,0.60506 0.6458,0.96651 0.1497,0.36145 0.2268,0.74885 0.2268,1.14008 0,0.3912 -0.0771,0.7786 -0.2268,1.1401 -0.1497,0.3614 -0.3692,0.6898 -0.6458,0.9665 -0.2767,0.2766 -0.6051,0.4961 -0.9665,0.6458 -0.3615,0.1497 -0.7489,0.2268 -1.1401,0.2268 H 7.79167 M 6.70833,9.41667 4,6.70833 6.70833,4`,
            },
          },
          restore: {
            icon: `M 5.77778,10.2222 H 3 V 13 M 13,8 c 0,1.32608 -0.5268,2.5979 -1.4645,3.5355 C 10.5979,12.4732 9.32608,13 8,13 6.6022,12.9947 5.26054,12.4493 4.25556,11.4778 L 3,10.2222 M 12.9999,3 V 5.77778 H 10.2222 M 3,8 C 3,6.67392 3.52678,5.40215 4.46447,4.46447 5.40215,3.52678 6.67392,3 8,3 9.3978,3.00526 10.7395,3.55068 11.7444,4.52222 L 13,5.77778`,
          },
          saveAsImage: {
            icon: `path://M 8.00012,9.77777 V 2.66666 M 5.03709,6.81481 8.00005,9.77777 10.963,6.81481 m 2.3703,2.96296 v 2.37033 c 0,0.3144 -0.1249,0.6158 -0.3471,0.8381 -0.2223,0.2223 -0.5238,0.3471 -0.8381,0.3471 H 3.85181 c -0.31433,0 -0.61579,-0.1248 -0.83805,-0.3471 C 2.79149,12.7639 2.66663,12.4625 2.66663,12.1481 V 9.77777`,
          },
        },
      },
      grid: {
        top: "20%",
        left: "8%",
        right: "5%",
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          filterMode: "none",
          end: 100,
          xAxisIndex: [0, 1],

          labelFormatter: (_: any, valueStr: string | number | Date) => {
            const val = moment(new Date(valueStr)).format("HH:mm:ss");
            return val;
          },
          textStyle: { width: 70, overflow: "break" },
          ...echartsDataZoomConfig,
        },
        {
          type: "inside",
          realtime: true,
          start: 0,
          end: 100,
          filterMode: "none",
          xAxisIndex: [0, 1],
        },
      ],
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value} ms",
        },
        splitLine: {
          show: true,
          interval: "auto",
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },

      //@ts-ignore
      series: [...data],
    };
  }
  return peerPublishRTTChartoptions;
};

//packetLoss config
export const getPublisherPacketLossChartConfiguration = (
  publishPacketLossData: peerAnalyticsPublishType
) => {
  if (isEmpty(publishPacketLossData)) {
    return {};
  }
  const arrObj = {
    type: "line",
    data: [],
    lineStyle: {
      type: "solid",
    },
    zlevel: 1,
    symbol: "circle",
    markLine: {
      label: {
        show: false,
        position: "start",
        formatter: `{c} %`,
        color: "#2C333F",
      },
      show: true,
      data: [{ yAxis: 5 }],

      symbol: ["none", "none"],
      lineStyle: {
        color: "#66A1FF",
        type: "dashed",
      },
    },
  } as echartsOptions["series"];
  const hasSimulcastVideoEnabled = getSimulcastEnabled(publishPacketLossData);

  const getAudioChartConfiguration = () => {
    if (isEmpty(publishPacketLossData?.audio)) {
      return [];
    }
    return [
      {
        ...arrObj,
        name: "Audio",
        color: "#CB525E",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#CB525E",
        },
        data: publishPacketLossData?.audio?.map((r: peerPublishValueType) => {
          const val = r?.packet_loss || 0;
          return {
            value: [r.timestamp, Number(val).toFixed(2)],
          };
        }),
      },
    ];
  };

  const getScreenChartConfiguration = () => {
    if (isEmpty(publishPacketLossData?.screen)) {
      return [];
    }
    return [
      {
        ...arrObj,
        color: "#913FFC",
        name: "Screen Share",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#913FFC",
        },
        data: publishPacketLossData?.screen?.map((r: peerPublishValueType) => {
          const val = r?.packet_loss || 0;
          return {
            value: [r.timestamp, Number(val).toFixed(2)],
          };
        }),
      },
    ];
  };

  const getVideoChartConfiguration = () => {
    if (isEmpty(publishPacketLossData?.video)) {
      return [];
    }

    if (hasSimulcastVideoEnabled) {
      return Object.keys(publishTrackKeys.video).map(key => {
        const abbr =
          videoTrackDetails[key as keyof typeof videoTrackDetails].name;
        const name = abbr ? `Video (${abbr})` : "Video";
        if (key === "null") {
          // eslint-disable-next-line array-callback-return
          return;
        }
        return {
          ...arrObj,
          name: name,
          color: videoTrackDetails[key as keyof typeof videoTrackDetails].color,
          lineStyle: {
            //@ts-ignore
            ...arrObj?.lineStyle,
            color:
              videoTrackDetails[key as keyof typeof videoTrackDetails].color,
          },
          data: publishPacketLossData?.video?.[key]?.map(
            (r: peerPublishValueType) => {
              const val = r?.packet_loss || 0;
              return {
                value: [r.timestamp, Number(val).toFixed(2)],
              };
            }
          ),
        };
      });
    } else {
      return Object.keys(publishPacketLossData?.video).map(key => {
        return {
          ...arrObj,
          name: "Video",
          color: "#00BE78",
          lineStyle: {
            //@ts-ignore
            ...arrObj?.lineStyle,
            color: "#00BE78",
          },
          data: publishPacketLossData?.video?.[key]?.map(
            (r: peerPublishValueType) => {
              const val = r?.packet_loss || 0;
              return {
                value: [r.timestamp, Number(val).toFixed(2)],
              };
            }
          ),
        };
      });
    }
  };
  const videoSeries = getVideoChartConfiguration();
  const audioSeries = getAudioChartConfiguration();
  const screenSeries = getScreenChartConfiguration();
  const data = [...videoSeries, ...audioSeries, ...screenSeries];
  let peerPublishPacketLossChartoptions: echartsOptions;
  let legendData = [];

  if (hasSimulcastVideoEnabled) {
    legendData = [
      { name: "Video (High)", itemStyle: { color: "#14B881" } },
      { name: "Video (Medium)", itemStyle: { color: "#EDC602" } },
      { name: "Video (Low)", itemStyle: { color: "#FF8000" } },

      { name: "Audio", itemStyle: { color: "#CB525E" } },
      { name: "Screen Share", itemStyle: { color: "#913FFC" } },
    ];
  } else {
    legendData = [
      { name: "Video", itemStyle: { color: "#00BE78" } },
      { name: "Audio", itemStyle: { color: "#CB525E" } },
      { name: "Screen Share", itemStyle: { color: "#913FFC" } },
    ];
  }
  if (data.length === 0) {
    peerPublishPacketLossChartoptions = {};
  } else {
    peerPublishPacketLossChartoptions = {
      legend: {
        left: "3%",
        z: 2,
        type: "plain",
        orient: "horizontal",
        show: true,
        icon: "path://M 0.0059092,12.043317 -4.5139121e-5,-0.00425602 12.004559,0.00271404 12.003432,11.995198 Z M 11.002035,0.95440869 1.0091504,0.96081353 1.1075267,10.984948 11.124326,11.022966 Z M 4.2153024,9.9284753 9.9624907,2.6594679 9.3041655,2.0786005 4.1297492,8.8221042 2.4751969,7.4195436 1.9393024,8.0391743",
        textStyle: { color: "#FFFFFF" },
        data: legendData,
      },
      tooltip: {
        axisPointer: {
          animation: false,
        },
        trigger: "axis", // Trigger tooltip on item (icon) hover
        backgroundColor: "#1E232A",
        borderRadius: 4,
        padding: 4,
        borderColor: "#1E232A",
        textStyle: {
          color: "#F5F9FF",
        },
      },
      xAxis: {
        type: "time",
        splitLine: {
          show: true,
          interval: "auto",
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      toolbox: {
        right: "5%",
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            icon: {
              zoom: `M 5.77783,7.44444 H 9.11116 M 7.44446,5.77777 V 9.1111 M 13,13 10.5834,10.5833 m -3.13896,1.3056 c 2.45459,0 4.44446,-1.98987 4.44446,-4.44446 C 11.8889,4.98984 9.89903,3 7.44444,3 4.98984,3 3,4.98984 3,7.44444 3,9.89903 4.98984,11.8889 7.44444,11.8889 Z`,
              back: `path://m 4,6.70833 h 5.6875 c 0.3912,0 0.7786,0.07706 1.1401,0.22678 0.3614,0.14972 0.6898,0.36916 0.9665,0.6458 0.2766,0.27664 0.4961,0.60506 0.6458,0.96651 0.1497,0.36145 0.2268,0.74885 0.2268,1.14008 0,0.3912 -0.0771,0.7786 -0.2268,1.1401 -0.1497,0.3614 -0.3692,0.6898 -0.6458,0.9665 -0.2767,0.2766 -0.6051,0.4961 -0.9665,0.6458 -0.3615,0.1497 -0.7489,0.2268 -1.1401,0.2268 H 7.79167 M 6.70833,9.41667 4,6.70833 6.70833,4`,
            },
          },
          restore: {
            icon: `M 5.77778,10.2222 H 3 V 13 M 13,8 c 0,1.32608 -0.5268,2.5979 -1.4645,3.5355 C 10.5979,12.4732 9.32608,13 8,13 6.6022,12.9947 5.26054,12.4493 4.25556,11.4778 L 3,10.2222 M 12.9999,3 V 5.77778 H 10.2222 M 3,8 C 3,6.67392 3.52678,5.40215 4.46447,4.46447 5.40215,3.52678 6.67392,3 8,3 9.3978,3.00526 10.7395,3.55068 11.7444,4.52222 L 13,5.77778`,
          },
          saveAsImage: {
            icon: `path://M 8.00012,9.77777 V 2.66666 M 5.03709,6.81481 8.00005,9.77777 10.963,6.81481 m 2.3703,2.96296 v 2.37033 c 0,0.3144 -0.1249,0.6158 -0.3471,0.8381 -0.2223,0.2223 -0.5238,0.3471 -0.8381,0.3471 H 3.85181 c -0.31433,0 -0.61579,-0.1248 -0.83805,-0.3471 C 2.79149,12.7639 2.66663,12.4625 2.66663,12.1481 V 9.77777`,
          },
        },
      },
      grid: {
        top: "20%",
        left: "8%",
        right: "5%",
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          filterMode: "none",
          end: 100,
          xAxisIndex: [0, 1],

          labelFormatter: (_: any, valueStr: string | number | Date) => {
            const val = moment(new Date(valueStr)).format("HH:mm:ss");
            return val;
          },
          textStyle: { width: 70, overflow: "break" },
          ...echartsDataZoomConfig,
        },
        {
          type: "inside",
          realtime: true,
          start: 0,
          end: 100,
          filterMode: "none",
          xAxisIndex: [0, 1],
        },
      ],
      yAxis: {
        interval: 20,
        type: "value",
        axisLabel: {
          formatter: "{value} %",
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      //@ts-ignore
      series: [...data],
    };
  }
  return peerPublishPacketLossChartoptions;
};

//FPS config
export const getPublisherFPSChartConfiguration = (
  publishFPSData: peerAnalyticsPublishType
) => {
  if (isEmpty(publishFPSData)) {
    return {};
  }
  const arrObj = {
    type: "line",
    data: [],
    lineStyle: {
      type: "solid",
    },
    zlevel: 1,
    symbol: "circle",
    markLine: {
      zlevel: 0,
      label: {
        show: false,
      },
      silent: true,
      symbol: ["none", "none"],
      lineStyle: {
        color: "#2C333F",
        type: "solid",
      },
    },
  } as echartsOptions["series"];
  const hasSimulcastVideoEnabled = getSimulcastEnabled(publishFPSData);

  const getScreenChartConfiguration = () => {
    if (isEmpty(publishFPSData?.screen)) {
      return [];
    }
    return [
      {
        ...arrObj,
        name: "Screen Share",
        color: "#913FFC",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#913FFC",
        },
        data: publishFPSData?.screen?.map((r: peerPublishValueType) => {
          const val = r?.fps || 0;
          return {
            value: [r.timestamp, val],
          };
        }),
      },
    ];
  };

  const getVideoChartConfiguration = () => {
    if (isEmpty(publishFPSData?.video)) {
      return [];
    }

    if (hasSimulcastVideoEnabled) {
      return Object.keys(publishTrackKeys?.video).map(key => {
        const abbr =
          videoTrackDetails[key as keyof typeof videoTrackDetails].name;
        const name = abbr ? `Video (${abbr})` : "Video";
        if (key === "null") {
          // eslint-disable-next-line array-callback-return
          return;
        }
        return {
          ...arrObj,
          name: name,
          color: videoTrackDetails[key as keyof typeof videoTrackDetails].color,

          lineStyle: {
            //@ts-ignore
            ...arrObj?.lineStyle,
            color:
              videoTrackDetails[key as keyof typeof videoTrackDetails].color,
          },
          data: publishFPSData?.video?.[key]?.map((r: peerPublishValueType) => {
            const val = r?.fps || 0;
            return {
              value: [r.timestamp, val],
            };
          }),
        };
      });
    } else {
      return Object.keys(publishFPSData?.video).map(key => {
        return {
          ...arrObj,
          name: "Video",
          color: "#00BE78",
          lineStyle: {
            //@ts-ignore
            ...arrObj?.lineStyle,
            color: "#00BE78",
          },
          data: publishFPSData?.video?.[key]?.map((r: peerPublishValueType) => {
            const val = r?.fps || 0;
            return {
              value: [r.timestamp, val],
            };
          }),
        };
      });
    }
  };
  const videoSeries = getVideoChartConfiguration();

  const screenSeries = getScreenChartConfiguration();
  const data = [...videoSeries, ...screenSeries];
  let peerPublishFPSChartoptions: echartsOptions;
  let legendData = [];

  if (hasSimulcastVideoEnabled) {
    legendData = [
      { name: "Video (High)", itemStyle: { color: "#14B881" } },
      { name: "Video (Medium)", itemStyle: { color: "#EDC602" } },
      { name: "Video (Low)", itemStyle: { color: "#FF8000" } },

      { name: "Audio", itemStyle: { color: "#CB525E" } },
      { name: "Screen Share", itemStyle: { color: "#913FFC" } },
    ];
  } else {
    legendData = [
      { name: "Video", itemStyle: { color: "#00BE78" } },
      { name: "Audio", itemStyle: { color: "#CB525E" } },
      { name: "Screen Share", itemStyle: { color: "#913FFC" } },
    ];
  }
  if (data.length === 0) {
    peerPublishFPSChartoptions = {};
  } else {
    peerPublishFPSChartoptions = {
      legend: {
        left: "3%",
        z: 2,
        type: "plain",
        orient: "horizontal",
        show: true,
        icon: "path://M 0.0059092,12.043317 -4.5139121e-5,-0.00425602 12.004559,0.00271404 12.003432,11.995198 Z M 11.002035,0.95440869 1.0091504,0.96081353 1.1075267,10.984948 11.124326,11.022966 Z M 4.2153024,9.9284753 9.9624907,2.6594679 9.3041655,2.0786005 4.1297492,8.8221042 2.4751969,7.4195436 1.9393024,8.0391743",
        textStyle: { color: "#FFFFFF" },
        data: legendData,
      },
      tooltip: {
        axisPointer: {
          animation: false,
        },
        trigger: "axis", // Trigger tooltip on item (icon) hover
        backgroundColor: "#1E232A",
        borderRadius: 4,
        padding: 4,
        borderColor: "#1E232A",
        textStyle: {
          color: "#F5F9FF",
        },
      },
      xAxis: {
        type: "time",
        splitLine: {
          show: true,
          interval: "auto",
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      toolbox: {
        right: "5%",
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            icon: {
              zoom: `M 5.77783,7.44444 H 9.11116 M 7.44446,5.77777 V 9.1111 M 13,13 10.5834,10.5833 m -3.13896,1.3056 c 2.45459,0 4.44446,-1.98987 4.44446,-4.44446 C 11.8889,4.98984 9.89903,3 7.44444,3 4.98984,3 3,4.98984 3,7.44444 3,9.89903 4.98984,11.8889 7.44444,11.8889 Z`,
              back: `path://m 4,6.70833 h 5.6875 c 0.3912,0 0.7786,0.07706 1.1401,0.22678 0.3614,0.14972 0.6898,0.36916 0.9665,0.6458 0.2766,0.27664 0.4961,0.60506 0.6458,0.96651 0.1497,0.36145 0.2268,0.74885 0.2268,1.14008 0,0.3912 -0.0771,0.7786 -0.2268,1.1401 -0.1497,0.3614 -0.3692,0.6898 -0.6458,0.9665 -0.2767,0.2766 -0.6051,0.4961 -0.9665,0.6458 -0.3615,0.1497 -0.7489,0.2268 -1.1401,0.2268 H 7.79167 M 6.70833,9.41667 4,6.70833 6.70833,4`,
            },
          },
          restore: {
            icon: `M 5.77778,10.2222 H 3 V 13 M 13,8 c 0,1.32608 -0.5268,2.5979 -1.4645,3.5355 C 10.5979,12.4732 9.32608,13 8,13 6.6022,12.9947 5.26054,12.4493 4.25556,11.4778 L 3,10.2222 M 12.9999,3 V 5.77778 H 10.2222 M 3,8 C 3,6.67392 3.52678,5.40215 4.46447,4.46447 5.40215,3.52678 6.67392,3 8,3 9.3978,3.00526 10.7395,3.55068 11.7444,4.52222 L 13,5.77778`,
          },
          saveAsImage: {
            icon: `path://M 8.00012,9.77777 V 2.66666 M 5.03709,6.81481 8.00005,9.77777 10.963,6.81481 m 2.3703,2.96296 v 2.37033 c 0,0.3144 -0.1249,0.6158 -0.3471,0.8381 -0.2223,0.2223 -0.5238,0.3471 -0.8381,0.3471 H 3.85181 c -0.31433,0 -0.61579,-0.1248 -0.83805,-0.3471 C 2.79149,12.7639 2.66663,12.4625 2.66663,12.1481 V 9.77777`,
          },
        },
      },
      grid: {
        top: "20%",
        left: "8%",
        right: "5%",
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          filterMode: "none",
          end: 100,
          xAxisIndex: [0, 1],

          labelFormatter: (_: any, valueStr: string | number | Date) => {
            const val = moment(new Date(valueStr)).format("HH:mm:ss");
            return val;
          },
          textStyle: { width: 70, overflow: "break" },
          ...echartsDataZoomConfig,
        },
        {
          type: "inside",
          realtime: true,
          start: 0,
          end: 100,
          filterMode: "none",
          xAxisIndex: [0, 1],
        },
      ],
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value}",
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      //@ts-ignore
      series: [...data],
    };
  }
  return peerPublishFPSChartoptions;
};

//Quality Limitation config
export const getPublisherQualityLimitationChartConfiguration = (
  publishQualityLimitationData: peerAnalyticsPublishType
) => {
  if (isEmpty(publishQualityLimitationData)) {
    return {};
  }
  const arrObj = {
    type: "line",
    data: [],
    lineStyle: {
      type: "solid",
    },
    zlevel: 1,
    symbol: "circle",
    markLine: {
      zlevel: 0,
      label: {
        show: false,
      },
      silent: true,
      symbol: ["none", "none"],
      lineStyle: {
        color: "#2C333F",
        type: "solid",
      },
    },
  } as echartsOptions["series"];
  const hasSimulcastVideoEnabled = getSimulcastEnabled(
    publishQualityLimitationData
  );

  const getScreenChartConfiguration = () => {
    if (isEmpty(publishQualityLimitationData?.screen)) {
      return [];
    }
    return [
      {
        ...arrObj,
        color: "#913FFC",
        name: "Screen Share CPU",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#913FFC",
        },
        data: publishQualityLimitationData?.screen?.map(
          (r: peerPublishValueType) => {
            const val = r?.quality_limitation?.cpu || 0;
            return {
              value: [r.timestamp, val],
            };
          }
        ),
      },
      {
        ...arrObj,
        name: "Screen Share Network",
        color: "#913FFC",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#913FFC",
        },
        data: publishQualityLimitationData?.screen?.map(
          (r: peerPublishValueType) => {
            const val = r?.quality_limitation?.network || 0;
            return {
              value: [r.timestamp, val],
            };
          }
        ),
      },
    ];
  };

  const getVideoChartConfiguration = () => {
    if (isEmpty(publishQualityLimitationData?.video)) {
      return [];
    }

    if (hasSimulcastVideoEnabled) {
      const cpuArray = Object.keys(publishTrackKeys.video).map(key => {
        const abbr =
          videoTrackDetails[key as keyof typeof videoTrackDetails].name;
        const name = abbr ? `Video (${abbr})` : "Video";
        if (key === "null") {
          // eslint-disable-next-line array-callback-return
          return;
        }
        return {
          ...arrObj,
          name: `${name} CPU`,
          color: videoTrackDetails[key as keyof typeof videoTrackDetails].color,
          lineStyle: {
            //@ts-ignore
            ...arrObj?.lineStyle,
            color:
              videoTrackDetails[key as keyof typeof videoTrackDetails].color,
          },
          data: publishQualityLimitationData?.video?.[key]?.map(
            (r: peerPublishValueType) => {
              const val = r?.quality_limitation?.cpu || 0;
              return {
                value: [r.timestamp, val],
              };
            }
          ),
        };
      });
      const networkArray = Object.keys(publishTrackKeys.video).map(key => {
        if (key === "null") {
          // eslint-disable-next-line array-callback-return
          return;
        }
        const abbr =
          videoTrackDetails[key as keyof typeof videoTrackDetails].name;
        const name = abbr ? `Video (${abbr})` : "Video";
        return {
          ...arrObj,
          name: `${name} Network`,
          color: videoTrackDetails[key as keyof typeof videoTrackDetails].color,
          lineStyle: {
            //@ts-ignore
            ...arrObj?.lineStyle,
            color:
              videoTrackDetails[key as keyof typeof videoTrackDetails].color,
          },
          data: publishQualityLimitationData?.video?.[key]?.map(
            (r: peerPublishValueType) => {
              const val = r?.quality_limitation?.network || 0;
              return {
                value: [r.timestamp, val],
              };
            }
          ),
        };
      });
      return [...cpuArray, ...networkArray];
    } else {
      const cpuArray = Object.keys(publishQualityLimitationData?.video).map(
        key => {
          return {
            ...arrObj,
            name: "Video CPU",
            color: "#00BE78",
            lineStyle: {
              //@ts-ignore
              ...arrObj?.lineStyle,
              color: "#00BE78",
            },
            data: publishQualityLimitationData?.video?.[key]?.map(
              (r: peerPublishValueType) => {
                const val = r?.quality_limitation?.cpu || 0;
                return {
                  value: [r.timestamp, val],
                };
              }
            ),
          };
        }
      );
      const networkArray = Object.keys(publishQualityLimitationData?.video).map(
        key => {
          return {
            ...arrObj,
            name: "Video Network",
            color: "#00BE78",
            lineStyle: {
              //@ts-ignore
              ...arrObj?.lineStyle,
              color: "#00BE78",
            },
            data: publishQualityLimitationData?.video?.[key]?.map(
              (r: peerPublishValueType) => {
                const val = r?.quality_limitation?.network || 0;
                return {
                  value: [r.timestamp, val],
                };
              }
            ),
          };
        }
      );
      return [...cpuArray, ...networkArray];
    }
  };
  const videoSeries = getVideoChartConfiguration();

  const screenSeries = getScreenChartConfiguration();
  const data = [...videoSeries, ...screenSeries];
  let peerPublishQualityLimitationsChartoptions: echartsOptions;
  let legendData = [];

  if (hasSimulcastVideoEnabled) {
    legendData = [
      { name: "Video (High) CPU", itemStyle: { color: "#14B881" } },
      { name: "Video (Medium) CPU", itemStyle: { color: "#EDC602" } },
      { name: "Video (Low) CPU", itemStyle: { color: "#FF8000" } },
      { name: "Video (High) Network", itemStyle: { color: "#14B881" } },
      { name: "Video (Medium) Network", itemStyle: { color: "#EDC602" } },
      { name: "Video (Low) Network", itemStyle: { color: "#FF8000" } },
      { name: "Audio", itemStyle: { color: "#CB525E" } },
      { name: "Screen Share CPU", itemStyle: { color: "#913FFC" } },
      { name: "Screen Share Network", itemStyle: { color: "#913FFC" } },
    ];
  } else {
    legendData = [
      { name: "Video CPU", itemStyle: { color: "#00BE78" } },
      { name: "Video Network", itemStyle: { color: "#00BE78" } },
      { name: "Audio", itemStyle: { color: "#CB525E" } },
      { name: "Screen Share CPU", itemStyle: { color: "#913FFC" } },
      { name: "Screen Share Network", itemStyle: { color: "#913FFC" } },
    ];
  }
  if (data.length === 0) {
    peerPublishQualityLimitationsChartoptions = {};
  } else {
    peerPublishQualityLimitationsChartoptions = {
      legend: {
        left: "3%",
        right: "30%",
        width: "60%",
        height: "60px",
        z: 2,
        type: "plain",
        orient: "horizontal",
        show: true,
        icon: "path://M 0.0059092,12.043317 -4.5139121e-5,-0.00425602 12.004559,0.00271404 12.003432,11.995198 Z M 11.002035,0.95440869 1.0091504,0.96081353 1.1075267,10.984948 11.124326,11.022966 Z M 4.2153024,9.9284753 9.9624907,2.6594679 9.3041655,2.0786005 4.1297492,8.8221042 2.4751969,7.4195436 1.9393024,8.0391743",

        textStyle: { color: "#FFFFFF" },
        data: legendData,
      },
      tooltip: {
        axisPointer: {
          animation: false,
        },
        trigger: "axis", // Trigger tooltip on item (icon) hover
        backgroundColor: "#1E232A",
        borderRadius: 4,
        padding: 4,
        borderColor: "#1E232A",
        textStyle: {
          color: "#F5F9FF",
        },
      },
      xAxis: {
        type: "time",
        splitLine: {
          show: true,
          interval: "auto",
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      toolbox: {
        right: "5%",
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            icon: {
              zoom: `M 5.77783,7.44444 H 9.11116 M 7.44446,5.77777 V 9.1111 M 13,13 10.5834,10.5833 m -3.13896,1.3056 c 2.45459,0 4.44446,-1.98987 4.44446,-4.44446 C 11.8889,4.98984 9.89903,3 7.44444,3 4.98984,3 3,4.98984 3,7.44444 3,9.89903 4.98984,11.8889 7.44444,11.8889 Z`,
              back: `path://m 4,6.70833 h 5.6875 c 0.3912,0 0.7786,0.07706 1.1401,0.22678 0.3614,0.14972 0.6898,0.36916 0.9665,0.6458 0.2766,0.27664 0.4961,0.60506 0.6458,0.96651 0.1497,0.36145 0.2268,0.74885 0.2268,1.14008 0,0.3912 -0.0771,0.7786 -0.2268,1.1401 -0.1497,0.3614 -0.3692,0.6898 -0.6458,0.9665 -0.2767,0.2766 -0.6051,0.4961 -0.9665,0.6458 -0.3615,0.1497 -0.7489,0.2268 -1.1401,0.2268 H 7.79167 M 6.70833,9.41667 4,6.70833 6.70833,4`,
            },
          },
          restore: {
            icon: `M 5.77778,10.2222 H 3 V 13 M 13,8 c 0,1.32608 -0.5268,2.5979 -1.4645,3.5355 C 10.5979,12.4732 9.32608,13 8,13 6.6022,12.9947 5.26054,12.4493 4.25556,11.4778 L 3,10.2222 M 12.9999,3 V 5.77778 H 10.2222 M 3,8 C 3,6.67392 3.52678,5.40215 4.46447,4.46447 5.40215,3.52678 6.67392,3 8,3 9.3978,3.00526 10.7395,3.55068 11.7444,4.52222 L 13,5.77778`,
          },
          saveAsImage: {
            icon: `path://M 8.00012,9.77777 V 2.66666 M 5.03709,6.81481 8.00005,9.77777 10.963,6.81481 m 2.3703,2.96296 v 2.37033 c 0,0.3144 -0.1249,0.6158 -0.3471,0.8381 -0.2223,0.2223 -0.5238,0.3471 -0.8381,0.3471 H 3.85181 c -0.31433,0 -0.61579,-0.1248 -0.83805,-0.3471 C 2.79149,12.7639 2.66663,12.4625 2.66663,12.1481 V 9.77777`,
          },
        },
      },
      grid: {
        top: "28%",
        left: "8%",
        right: "5%",
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          filterMode: "none",
          end: 100,
          xAxisIndex: [0, 1],

          labelFormatter: (_: any, valueStr: string | number | Date) => {
            const val = moment(new Date(valueStr)).format("HH:mm:ss");
            return val;
          },
          textStyle: { width: 70, overflow: "break" },
          ...echartsDataZoomConfig,
        },
        {
          type: "inside",
          realtime: true,
          start: 0,
          end: 100,
          filterMode: "none",
          xAxisIndex: [0, 1],
        },
      ],
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value}%",
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      //@ts-ignore
      series: [...data],
    };
  }
  return peerPublishQualityLimitationsChartoptions;
};

//jitter buffer config
export const getPublisherJitterBufferChartConfiguration = (
  publishJitterBufferData: peerAnalyticsPublishType
) => {
  if (isEmpty(publishJitterBufferData)) {
    return {};
  }
  const arrObj = {
    type: "line",
    data: [],
    lineStyle: {
      type: "solid",
    },
    zlevel: 1,
    symbol: "circle",
    markLine: {
      zlevel: 0,
      label: {
        show: false,
      },
      silent: true,
      symbol: ["none", "none"],
      lineStyle: {
        color: "#2C333F",
        type: "solid",
      },
    },
  } as echartsOptions["series"];
  const hasSimulcastVideoEnabled = getSimulcastEnabled(publishJitterBufferData);

  const getAudioChartConfiguration = () => {
    if (isEmpty(publishJitterBufferData?.audio)) {
      return [];
    }
    return [
      {
        ...arrObj,
        name: "Audio",
        color: "#CB525E",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#CB525E",
        },

        data: publishJitterBufferData?.audio?.map((r: peerPublishValueType) => {
          const val = r?.jitter || 0;
          return {
            value: [r.timestamp, Number(val).toFixed(2)],
          };
        }),
      },
    ];
  };

  const getScreenChartConfiguration = () => {
    if (isEmpty(publishJitterBufferData?.screen)) {
      return [];
    }
    return [
      {
        ...arrObj,
        name: "Screen Share",
        color: "#913FFC",
        lineStyle: {
          //@ts-ignore
          ...arrObj?.lineStyle,
          color: "#913FFC",
        },
        data: publishJitterBufferData?.screen?.map(
          (r: peerPublishValueType) => {
            const val = r?.jitter || 0;
            return {
              value: [r.timestamp, Number(val).toFixed(2)],
            };
          }
        ),
      },
    ];
  };

  const getVideoChartConfiguration = () => {
    if (isEmpty(publishJitterBufferData?.video)) {
      return [];
    }

    if (hasSimulcastVideoEnabled) {
      return Object.keys(publishTrackKeys.video).map(key => {
        const abbr =
          videoTrackDetails[key as keyof typeof videoTrackDetails].name;
        const name = abbr ? `Video (${abbr})` : "Video";
        if (key === "null") {
          // eslint-disable-next-line array-callback-return
          return;
        }

        return {
          ...arrObj,
          name: name,
          color: videoTrackDetails[key as keyof typeof videoTrackDetails].color,
          lineStyle: {
            //@ts-ignore
            ...arrObj?.lineStyle,
            color:
              videoTrackDetails[key as keyof typeof videoTrackDetails].color,
          },
          data: publishJitterBufferData?.video?.[key]?.map(
            (r: peerPublishValueType) => {
              const val = r?.jitter || 0;
              return {
                value: [r.timestamp, Number(val).toFixed(2)],
              };
            }
          ),
        };
      });
    } else {
      return Object.keys(publishJitterBufferData?.video).map(key => {
        return {
          ...arrObj,
          name: "Video",
          color: "#00BE78",
          lineStyle: {
            //@ts-ignore
            ...arrObj?.lineStyle,
            color: "#00BE78",
          },
          data: publishJitterBufferData?.video?.[key]?.map(
            (r: peerPublishValueType) => {
              const val = r?.jitter || 0;
              return {
                value: [r.timestamp, Number(val).toFixed(2)],
              };
            }
          ),
        };
      });
    }
  };
  const videoSeries = getVideoChartConfiguration();
  const audioSeries = getAudioChartConfiguration();
  const screenSeries = getScreenChartConfiguration();
  const data = [...videoSeries, ...audioSeries, ...screenSeries];
  let legendData = [];

  if (hasSimulcastVideoEnabled) {
    legendData = [
      { name: "Video (High)", itemStyle: { color: "#14B881" } },
      { name: "Video (Medium)", itemStyle: { color: "#EDC602" } },
      { name: "Video (Low)", itemStyle: { color: "#FF8000" } },

      { name: "Audio", itemStyle: { color: "#CB525E" } },
      { name: "Screen Share", itemStyle: { color: "#913FFC" } },
    ];
  } else {
    legendData = [
      { name: "Video", itemStyle: { color: "#00BE78" } },
      { name: "Audio", itemStyle: { color: "#CB525E" } },
      { name: "Screen Share", itemStyle: { color: "#913FFC" } },
    ];
  }

  let peerPublishBitrateChartoptions: echartsOptions;
  if (data.length === 0) {
    peerPublishBitrateChartoptions = {};
  } else {
    peerPublishBitrateChartoptions = {
      legend: {
        left: "3%",
        z: 2,
        type: "plain",
        orient: "horizontal",
        show: true,
        icon: "path://M 0.0059092,12.043317 -4.5139121e-5,-0.00425602 12.004559,0.00271404 12.003432,11.995198 Z M 11.002035,0.95440869 1.0091504,0.96081353 1.1075267,10.984948 11.124326,11.022966 Z M 4.2153024,9.9284753 9.9624907,2.6594679 9.3041655,2.0786005 4.1297492,8.8221042 2.4751969,7.4195436 1.9393024,8.0391743",
        textStyle: { color: "#FFFFFF" },
        data: legendData,
      },
      axisPointer: {
        link: [
          {
            xAxisIndex: "all",
          },
        ],
      },
      tooltip: {
        axisPointer: {
          animation: false,
        },
        trigger: "axis", // Trigger tooltip on item (icon) hover
        backgroundColor: "#1E232A",
        borderRadius: 4,
        padding: 4,
        borderColor: "#1E232A",
        textStyle: {
          color: "#F5F9FF",
        },
      },
      xAxis: {
        type: "time",

        splitLine: {
          show: true,
          interval: "auto",
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },
      },
      toolbox: {
        right: "5%",
        feature: {
          dataZoom: {
            yAxisIndex: "none",
            icon: {
              zoom: `M 5.77783,7.44444 H 9.11116 M 7.44446,5.77777 V 9.1111 M 13,13 10.5834,10.5833 m -3.13896,1.3056 c 2.45459,0 4.44446,-1.98987 4.44446,-4.44446 C 11.8889,4.98984 9.89903,3 7.44444,3 4.98984,3 3,4.98984 3,7.44444 3,9.89903 4.98984,11.8889 7.44444,11.8889 Z`,
              back: `path://m 4,6.70833 h 5.6875 c 0.3912,0 0.7786,0.07706 1.1401,0.22678 0.3614,0.14972 0.6898,0.36916 0.9665,0.6458 0.2766,0.27664 0.4961,0.60506 0.6458,0.96651 0.1497,0.36145 0.2268,0.74885 0.2268,1.14008 0,0.3912 -0.0771,0.7786 -0.2268,1.1401 -0.1497,0.3614 -0.3692,0.6898 -0.6458,0.9665 -0.2767,0.2766 -0.6051,0.4961 -0.9665,0.6458 -0.3615,0.1497 -0.7489,0.2268 -1.1401,0.2268 H 7.79167 M 6.70833,9.41667 4,6.70833 6.70833,4`,
            },
          },
          restore: {
            icon: `M 5.77778,10.2222 H 3 V 13 M 13,8 c 0,1.32608 -0.5268,2.5979 -1.4645,3.5355 C 10.5979,12.4732 9.32608,13 8,13 6.6022,12.9947 5.26054,12.4493 4.25556,11.4778 L 3,10.2222 M 12.9999,3 V 5.77778 H 10.2222 M 3,8 C 3,6.67392 3.52678,5.40215 4.46447,4.46447 5.40215,3.52678 6.67392,3 8,3 9.3978,3.00526 10.7395,3.55068 11.7444,4.52222 L 13,5.77778`,
          },
          saveAsImage: {
            icon: `path://M 8.00012,9.77777 V 2.66666 M 5.03709,6.81481 8.00005,9.77777 10.963,6.81481 m 2.3703,2.96296 v 2.37033 c 0,0.3144 -0.1249,0.6158 -0.3471,0.8381 -0.2223,0.2223 -0.5238,0.3471 -0.8381,0.3471 H 3.85181 c -0.31433,0 -0.61579,-0.1248 -0.83805,-0.3471 C 2.79149,12.7639 2.66663,12.4625 2.66663,12.1481 V 9.77777`,
          },
        },
      },
      grid: {
        top: "20%",
        left: "8%",
        right: "5%",
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: 0,
          filterMode: "none",
          end: 100,
          xAxisIndex: [0, 1],

          labelFormatter: (_: any, valueStr: string | number | Date) => {
            const val = moment(new Date(valueStr)).format("HH:mm:ss");
            return val;
          },
          textStyle: { width: 70, overflow: "break" },
          ...echartsDataZoomConfig,
        },
        {
          type: "inside",
          realtime: true,
          start: 0,
          end: 100,
          filterMode: "none",
          xAxisIndex: [0, 1],
        },
      ],
      yAxis: {
        type: "value",
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2C333F",
            type: "dashed",
          },
        },

        axisLabel: {
          formatter: `{value} ms`,
        },
      },
      //@ts-ignore
      series: [...data],
    };
  }
  return peerPublishBitrateChartoptions;
};
