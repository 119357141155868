import React from "react";
import { defaultConferencing } from "src/data/defaultLayout";

function PrebuiltBRBConfig() {
  return <></>;
}

export default PrebuiltBRBConfig;

const defaultData = defaultConferencing?.["default"]?.["elements"]?.["brb"];

export const brbConfig = {
  ui: {
    title: "BRB",
    hasSwitch: true,
    tooltipText: "",
  },
  path: "screens.conferencing.default.elements.brb",
  Component: PrebuiltBRBConfig,
  defaultData: defaultData,
};
