import React, {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { set } from "lodash";
import {
  patchPolicyInfoToStore,
  patchRoleInStore,
  setHasUnsavedSettings,
} from "src/actions/RolesActions";
import TemplateConfigCard from "src/components/Cards/TemplateConfigCard";
import SwitchWithTitle from "src/pages/Developer/SwitchWithTitle";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { RootState } from "src/store/reducers";
import { roleType, RoomStateType } from "src/types/policyTypes";
import { slugify } from "src/utils";
import {
  AddIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CrossIcon,
  MinusIcon,
} from "@100mslive/react-icons";
import {
  Box,
  Checkbox,
  Dropdown,
  Flex,
  Input,
  Switch,
  Text,
} from "@100mslive/react-ui";
import { getPriorityRoles, isVisibleRole } from "../../helpers";

interface GeneralAdvancedSettingsProps {
  invalidFields: Record<string, unknown>;
  validateRoomStateSettings: (
    roomStateInfo: RoomStateType | undefined,
    roles: roleType[]
  ) => {
    isValid: boolean;
    invalidFields: Record<string, unknown>;
  };
}

const GeneralAdvancedSettings: FC<
  ComponentProps<typeof Flex> & GeneralAdvancedSettingsProps
> = ({ validateRoomStateSettings, invalidFields }) => {
  const dispatch = useDispatch();
  const roles = useSelector((state: RootState) => state.roles.roles);
  const policy = useSelector((state: RootState) => state.roles.policyInfo);
  const cardRef = useRef<HTMLDivElement>(null);
  const patchRoomState = useCallback(
    (key: string, value: unknown) => {
      const tempPolicy = { ...policy };
      set(tempPolicy, `settings.roomState.${key}`, value);

      dispatch(patchPolicyInfoToStore(tempPolicy));
      dispatch(setHasUnsavedSettings(true));
      validateRoomStateSettings(tempPolicy.settings.roomState, roles);
    },
    [dispatch, policy, roles, validateRoomStateSettings]
  );
  const [open, setOpen] = useState(false);

  const visibleRoles = roles
    .filter((obj: roleType) => isVisibleRole(obj.name))
    .filter((obj: roleType) => getPriorityRoles(obj.name));

  const getValidMessageInterval = (messageInterval: number) => {
    let validMessageInterval = messageInterval;

    // Get nearest multiple of 5
    validMessageInterval = Math.round(messageInterval / 5) * 5;

    // Fix, in case, the above operation gives out of bounds result
    if (validMessageInterval < 5) {
      validMessageInterval = 5;
    }
    if (validMessageInterval > 3600) {
      validMessageInterval = 3600;
    }
    return validMessageInterval;
  };

  const handleMessageIntervalChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value ? +e.target.value : "";
    validateRoomStateSettings(policy.settings.roomState, roles);
    patchRoomState("messageInterval", value);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      const roomStateInfo = policy?.settings?.roomState;
      if (
        roomStateInfo?.enabled &&
        roomStateInfo?.messageInterval &&
        (roomStateInfo.messageInterval < 5 ||
          roomStateInfo.messageInterval > 3600 ||
          roomStateInfo.messageInterval % 5 !== 0)
      ) {
        const updatedMessageInterval = getValidMessageInterval(
          roomStateInfo?.messageInterval || 10
        );
        patchRoomState("messageInterval", updatedMessageInterval);
      }
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [
    patchRoomState,
    policy?.settings?.roomState,
    policy?.settings?.roomState?.messageInterval,
  ]);

  return (
    <TemplateConfigCard
      text="Preview Room State"
      id={slugify("Preview Room State")}
      classNameForText="config-subheading"
      rightComponent={
        <Flex direction="column" css={{ minWidth: "100%" }}>
          <Flex
            direction="column"
            css={{
              pb: "$10",
              minWidth: "100%",
              borderBottom: "$space$px solid $borderDefault",
            }}
          >
            <SettingsTitle
              key="roomState"
              title="Enabling/Disable Room-State"
              text="Room-state"
            />

            <SwitchWithTitle
              hideTitle
              tooltipMessage="Toggle room state"
              checkedValue={policy?.settings?.roomState?.enabled}
              onCheckedChange={() => {
                patchRoomState(
                  "enabled",
                  !policy?.settings?.roomState?.enabled
                );
              }}
            />
          </Flex>
          <Flex
            direction="column"
            css={{
              py: "$10",
              minWidth: "100%",
              borderBottom: "$space$px solid $borderDefault",
            }}
          >
            <SettingsTitle
              key="sendPeerList"
              title="Enabling this will send peer-list data of the room. If disabled, only the peer count is sent."
              text="Send Peer List in Room-state"
            />
            <Flex css={{ mt: "$4", position: "relative" }}>
              <Switch
                disabled={!policy?.settings?.roomState?.enabled}
                checked={
                  policy?.settings?.roomState?.enabled &&
                  policy?.settings?.roomState?.sendPeerList
                }
                onCheckedChange={e => {
                  patchRoomState("sendPeerList", e);
                }}
              />
            </Flex>
          </Flex>

          <Flex
            direction="column"
            css={{
              py: "$10",
              minWidth: "100%",
              borderBottom: "$space$px solid $borderDefault",
            }}
          >
            <SettingsTitle
              key="messageInterval"
              title="Room-state data will be sent over regular intervals of these many seconds. This value must be a multiple of 5, between 5 and 3600 seconds, both inclusive."
              disabled={true}
              hideTooltip={true}
              text="Room-state Message Interval (in seconds)"
            />
            <Flex css={{ mt: "$4", position: "relative" }}>
              <Input
                type="number"
                min="5"
                max="3600"
                step="5"
                css={{ r: "$0", width: "100%" }}
                onChange={e => handleMessageIntervalChange(e)}
                value={policy?.settings?.roomState?.messageInterval}
                maxLength={100}
              />
              <button
                className={classNames("ml-2", {
                  "cursor-not-allowed":
                    (policy?.settings?.roomState?.messageInterval || 0) <= 5,
                })}
                onClick={() => {
                  handleMessageIntervalChange({
                    target: {
                      value: policy?.settings?.roomState?.messageInterval
                        ? policy?.settings?.roomState?.messageInterval - 5
                        : 5,
                    },
                  } as unknown as React.ChangeEvent<HTMLInputElement>);
                }}
              >
                <Box>
                  <MinusIcon />
                </Box>
              </button>
              <button
                className={classNames("ml-2", {
                  "cursor-not-allowed":
                    (policy?.settings?.roomState?.messageInterval || 0) >= 3600,
                })}
                onClick={() => {
                  handleMessageIntervalChange({
                    target: {
                      value: policy?.settings?.roomState?.messageInterval
                        ? policy?.settings?.roomState?.messageInterval + 5
                        : 5,
                    },
                  } as unknown as React.ChangeEvent<HTMLInputElement>);
                }}
              >
                <Box>
                  <AddIcon />
                </Box>
              </button>
              {invalidFields?.["messageInterval"] ? (
                <Text
                  variant="caption"
                  css={{
                    fontWeight: "$regular",
                    position: "absolute",
                    top: "110%",
                    c: "$error",
                  }}
                >
                  {invalidFields["messageInterval"] as string}
                </Text>
              ) : null}
            </Flex>
          </Flex>
          <Flex
            direction="column"
            css={{
              pt: "$10",
              minWidth: "100%",
            }}
            ref={cardRef}
          >
            <SettingsTitle
              key="messageInterval"
              title="Room-state data will be sent over regular intervals of these many seconds. This value must be a multiple of 5, between 5 and 3600 seconds, both inclusive."
              disabled={true}
              hideTooltip={true}
              text="Roles with Room-state Permission"
            />
            <Flex
              direction="column"
              css={{
                minWidth: "100%",
                position: "relative",
                mt: "$2",
                mb: invalidFields?.["rolesWarning"] ? "$4" : "0",
              }}
            >
              <Dropdown.Root modal={false} open={open} onOpenChange={setOpen}>
                <Dropdown.Trigger
                  asChild
                  css={{ r: "$1", w: "100%", bg: "$surfaceLighter" }}
                >
                  <Flex
                    justify="between"
                    align="center"
                    css={{ bg: "$surfaceLighter !important", p: "$6" }}
                  >
                    <Text variant="body2" css={{ c: "$textAccentDisabled" }}>
                      Please select roles
                    </Text>
                    <Box className="flex align-middle">
                      {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    </Box>
                  </Flex>
                </Dropdown.Trigger>

                <Dropdown.Content
                  css={{
                    w: `${
                      cardRef?.current
                        ? Math.floor(cardRef?.current?.offsetWidth)
                        : 0
                    }px`,
                  }}
                  align="end"
                  className="bg-surface-light shadow-2xl px-0"
                  sideOffset={10}
                  onInteractOutside={() => setOpen(false)}
                >
                  {visibleRoles.map((role: roleType) => {
                    const checked = Boolean(role.permissions.sendRoomState);
                    const onCheckedChange = () => {
                      validateRoomStateSettings(
                        policy?.settings?.roomState,
                        roles
                      );
                      dispatch(setHasUnsavedSettings(true));
                      dispatch(
                        patchRoleInStore({
                          ...role,
                          permissions: {
                            ...role.permissions,
                            sendRoomState: !checked,
                          },
                        })
                      );
                    };
                    return (
                      <Dropdown.CheckboxItem
                        key={role.name}
                        onSelect={e => e.preventDefault()}
                        checked={checked}
                        onCheckedChange={onCheckedChange}
                        css={{ p: "$6" }}
                      >
                        <Flex align="center">
                          <Checkbox.Root
                            id={role.name}
                            checked={checked}
                            css={{
                              bg: "$textMedEmp",
                              borderColor: "$textMedEmp",
                              '&[data-state="checked"]': {
                                bg: "$textMedEmp",
                              },
                            }}
                          >
                            <Checkbox.Indicator css={{ c: "$secondaryDark" }}>
                              <CheckIcon width={16} height={16} />
                            </Checkbox.Indicator>
                          </Checkbox.Root>
                          <Text
                            variant="caption"
                            css={{ ml: "$6", c: "$textMedEmp" }}
                          >
                            {role.name}
                          </Text>
                        </Flex>
                      </Dropdown.CheckboxItem>
                    );
                  })}
                </Dropdown.Content>
              </Dropdown.Root>
              <Flex css={{ flexWrap: "wrap" }}>
                {(roles?.length
                  ? roles.filter(
                      (role: roleType) =>
                        role?.permissions?.sendRoomState &&
                        isVisibleRole(role.name)
                    )
                  : []
                ).map((role: roleType) => (
                  <Flex
                    key={role.name}
                    css={{
                      c: "$textMedEmp",
                      bg: "$secondaryDark",
                      r: "$0",
                      p: "$2 $4",
                      mr: "$2",
                      minWidth: "max-content",
                      mt: "$4",
                    }}
                  >
                    <Text variant="xs" css={{ mr: "$2", color: "$textMedEmp" }}>
                      {role.name}
                    </Text>
                    <CrossIcon
                      width={12}
                      height={16}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        validateRoomStateSettings(
                          policy?.settings?.roomState,
                          roles
                        );
                        dispatch(setHasUnsavedSettings(true));
                        dispatch(
                          patchRoleInStore({
                            ...role,
                            permissions: {
                              ...role.permissions,
                              sendRoomState: false,
                            },
                          })
                        );
                      }}
                    />
                  </Flex>
                ))}
              </Flex>
              {invalidFields?.["rolesWarning"] ? (
                <Flex css={{ position: "absolute", bottom: "-24px" }}>
                  <Text css={{ c: "$error" }} variant="caption">
                    {invalidFields["rolesWarning"] as string}
                  </Text>
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
      }
    />
  );
};

export default GeneralAdvancedSettings;
