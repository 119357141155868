import React from "react";
import { MODAL_Z_INDEX } from "src/constants";
import { CheckIcon, InfoIcon } from "@100mslive/react-icons";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Flex,
  Label,
  Text,
} from "@100mslive/react-ui";

function DeleteDialog({
  deleteFunction,
  toggleDeleteDialog,
  open,
  data,
  title,
  subtitle,
  CheckboxText,
  CheckBoxSectionCSS = {},
  checked,
  toggleCheckBox,
  disableButton = false,
  loading = false,
  compactButtons = false,
  cancelButtonText = "Go Back",
}) {
  const buttonCSS = {
    height: !compactButtons ? "$16" : "auto",
    width: !compactButtons ? "100%" : "auto",
    borderRadius: !compactButtons ? "$1" : "$0",
    fontSize: "$sm",
  };
  return (
    <Dialog.Root open={open} onOpenChange={toggleDeleteDialog}>
      <Dialog.Overlay
        css={{
          backgroundColor: "rgba(0, 0, 0, 0.8);",
          inset: 0,
          zIndex: MODAL_Z_INDEX,
        }}
      />
      <Dialog.Content
        css={{
          width: "min(480px,100%)",
          border: "1px solid $surfaceLight",
          bg: "$surfaceDefault",
          zIndex: MODAL_Z_INDEX,
        }}
      >
        {/*Make it a wrapper style ui component*/}
        <Dialog.DefaultClose
          css={{ position: "absolute", top: "$8", right: "$8" }}
        />
        <Dialog.Title>
          <Flex direction="row" className="mb-2 gap-2 items-center">
            <InfoIcon className="w-8 h-8" />
            <Text variant="h5">{title}</Text>
          </Flex>
        </Dialog.Title>
        <Flex direction="column">
          <Text
            variant="body1"
            css={{ c: "$textMedEmp", fontWeight: "$regular" }}
          >
            {subtitle}
          </Text>
          {data && (
            <Box
              css={{
                border: "1px solid $borderLight",
                borderRadius: "$1",
                p: "$md",
                my: "$12",
                overflowWrap: "break-word",
                display: "flex",
                gap: "$10",
                alignItems: "center",
              }}
            >
              {data.image}
              <Box css={{ w: "100%" }}>
                <Text
                  variant="sub1"
                  className="text-textIcon-highEmp mb-1 truncate w-3/4"
                >
                  {data?.title}
                </Text>
                {data?.subtitle ? (
                  <Text
                    variant="body2"
                    css={{ color: "$textMedEmp", fontWeight: "$regular" }}
                  >
                    {data?.subtitle}
                  </Text>
                ) : null}
              </Box>
            </Box>
          )}
          {toggleCheckBox && (
            <Flex gap="2" css={CheckBoxSectionCSS}>
              <Box css={{ w: "$8", h: "$8", m: "$1 $6 0 0" }}>
                <Checkbox.Root
                  checked={checked}
                  onCheckedChange={toggleCheckBox}
                  css={{
                    borderColor: "$white",
                    '&[data-state="unchecked"]': {
                      backgroundColor: "$transparent",
                    },
                    '&[data-state="checked"]': {
                      backgroundColor: "$white",
                    },
                  }}
                  id="deleteCheckBoxText"
                >
                  <Checkbox.Indicator>
                    <CheckIcon
                      width={16}
                      height={16}
                      style={{ color: "black" }}
                    />
                  </Checkbox.Indicator>
                </Checkbox.Root>
              </Box>
              <Flex direction="column">
                <Label htmlFor="deleteCheckBoxText">
                  {CheckboxText && <CheckboxText />}
                </Label>
              </Flex>
            </Flex>
          )}
          <Flex
            direction="row"
            className="gap-4 mt-4"
            css={{ justifyContent: compactButtons ? "flex-end" : "" }}
          >
            <Button
              variant="standard"
              outlined
              css={buttonCSS}
              onClick={toggleDeleteDialog}
            >
              {cancelButtonText}
            </Button>
            <Button
              variant="danger"
              loading={loading}
              css={buttonCSS}
              disabled={disableButton}
              onClick={() => {
                deleteFunction();
              }}
            >
              {title}
            </Button>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

export default DeleteDialog;
