import React, { FC, ReactNode, useState } from "react";
import { AppAnalytics } from "src/helpers";
import JoinRoomModal from "src/pages/Dashboard/JoinRoomModal";
import { getJoinRoomTooltipMessage } from "src/utils";
import {
  DoorIcon,
  LayoutIcon,
  SettingsIcon,
  VerticalMenuIcon,
} from "@100mslive/react-icons";
import { Flex, Text, Tooltip } from "@100mslive/react-ui";
import OptionItem from "../Common/OptionItem";
import Options from "../Common/Options";

type RecentRoomCardProps = {
  room: Record<string, ReactNode>;
  template: Record<string, ReactNode>;
};

const truncationCSS = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

const iconStyle = {
  height: "20px",
  width: "20px",
  color: "inherit",
};
// eslint-disable-next-line complexity
const RecentRoomCards: FC<RecentRoomCardProps> = ({ room, template }) => {
  const [open, setOpen] = useState<boolean>(false);

  const enableJoinRoom = Boolean(
    template?.["id"] &&
      room?.["id"] &&
      template?.["subdomain"] &&
      room?.["active"]
  );

  return (
    <Flex
      justify="between"
      css={{
        r: "$0",
        w: "100%",
        py: "$10",
      }}
    >
      <Flex direction="column" css={{ flexGrow: "1", w: "75%" }}>
        <Text
          title={room?.["name"]?.toString()}
          variant="sub2"
          css={{
            c: "$textHighEmp",
            ...truncationCSS,
          }}
        >
          {room?.["name"]}
        </Text>
        <Flex align="center" css={{ mt: "$6", w: "100%" }}>
          <Flex css={{ c: "$textDisabled", h: "100%", mr: "$4" }}>
            <DoorIcon width={16} height={16} />
          </Flex>
          <Text
            title={room?.["id"]?.toString()}
            variant="caption"
            css={{
              c: "$textMedEmp",
              maxWidth: "47%",
              mr: "$4",
              fontFamily: "var(--user-font-roboto)",
              fontWeight: "$regular",
              ...truncationCSS,
            }}
          >
            {room?.["id"]}
          </Text>
          <Flex
            css={{ c: "$textDisabled", h: "100%", mr: "$4", w: "max-content" }}
          >
            <LayoutIcon width={16} height={16} />
          </Flex>
          <Text
            title={room?.["template"]?.toString()}
            variant="caption"
            css={{
              c: "$textMedEmp",
              maxWidth: "47%",
              ...truncationCSS,
            }}
          >
            {room?.["template"]}
          </Text>
        </Flex>
      </Flex>
      <Flex align="center" justify="end" css={{ flexShrink: "1", w: "25%" }}>
        <Text
          variant="caption"
          css={{
            bg: "$errorBg",
            c: "$errorDefault",
            p: "$2 $4",
            r: "$0",
            opacity: room?.["is_live"] ? "1" : "0",
            pointerEvents: room?.["is_live"] ? "all" : "none",
          }}
        >
          Live
        </Text>

        <Flex
          align="center"
          onClick={() => {
            if (enableJoinRoom) {
              setOpen(!open);
              AppAnalytics.track("btn.clicked", {
                btnId: "join.room",
                componentId: "dashboardHome",
              });
            }
          }}
        >
          <Tooltip
            disabled={enableJoinRoom}
            title={getJoinRoomTooltipMessage(
              template,
              (template?.subdomain || "").toString(),
              Boolean(room?.active)
            )}
          >
            <Text
              variant="sub2"
              css={{
                cursor:
                  room?.["active"] && room?.["id"] ? "pointer" : "not-allowed",
                c:
                  room?.["active"] && room?.["id"]
                    ? "$primaryLight"
                    : "$textDisabled",
                mx: "$5",
                "&:hover": {
                  c:
                    room?.["active"] && room?.["id"]
                      ? "$primaryDisabled"
                      : "$textDisabled",
                },
              }}
            >
              Join
            </Text>
          </Tooltip>
        </Flex>

        <Flex>
          <Options
            Icon={<VerticalMenuIcon width={20} height={20} />}
            content={[
              {
                data: (
                  <OptionItem
                    onClick={() =>
                      AppAnalytics.track("btn.clicked", {
                        btnId: "view.room.settings",
                        componentId: "dashboardcard",
                      })
                    }
                    link={`/room-details/${room?.id}`}
                    icon={<DoorIcon style={iconStyle} />}
                    title="View Room Details"
                  />
                ),
              },
              template?.["id"] && {
                data: (
                  <OptionItem
                    onClick={() =>
                      AppAnalytics.track("btn.clicked", {
                        btnId: "configure.template.home",
                        componentId: "dashboardHome",
                      })
                    }
                    link={`/templates/${room?.template_id}`}
                    icon={<SettingsIcon style={iconStyle} />}
                    title="Configure Template"
                  />
                ),
              },
            ]}
          />
        </Flex>
      </Flex>
      {open && enableJoinRoom && (
        <JoinRoomModal
          open={open}
          setOpen={setOpen}
          roomId={room?.["id"]?.toString() || ""}
          componentId="home"
          subdomain={template?.["subdomain"]?.toString() || ""}
          templateId={template?.["id"]?.toString() || ""}
        />
      )}
    </Flex>
  );
};

export default RecentRoomCards;
