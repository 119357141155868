import React, { Component } from "react";
import Lottie from "react-lottie";
import { connect } from "react-redux";
import queryString from "query-string";
import { fetchTemplatesData } from "src/actions/RolesActions";
import { API_CALL_STATE } from "src/constants";
import { AppCard } from "./AppCard";
import { AppsPage } from "./AppsPage";
import { fetchPublicTemplates } from "../../actions/CreateAppActions.js";
import api from "../../api";
import logo from "../../assets/images/100ms-logo.png";
import { nearestRegion } from "../../helpers/region_helper";
import { getAppInfo, getRolesFromPublicTemplate } from "../../helpers/utils";
import animationData from "../../lotties/loader.json";
import VercelLogin from "../Authentication/VercelLogin/index";

class VercelTemplateIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      signedIn: false,
      error: false,
      code: "",
      vercel_access_token: "",
      vercel_projects: [],
      configurationId: "",
      starterKitId: "",
      templateId: "",
      vercel_redirect: "",
      currentProjectId: "",
      roles: [],
      event_type: "",
      showAllApps: false,
      showVercelApps: false,
      selectedApp: "",
      selectedVercelApp: "",
      loadingHeader: "",
      loadingSubHeader: "",
    };
    this.onAppChange = this.onAppChange.bind(this);
    this.onVercelAppChange = this.onVercelAppChange.bind(this);
    this.vercelAppIntegration = this.vercelAppIntegration.bind(this);
    this.returnToVercel = this.returnToVercel.bind(this);
  }

  onAppChange(app) {
    this.setState({
      ...this.state,
      selectedApp: app.id,
      event_type: app.app_type,
    });
  }

  onVercelAppChange(app) {
    this.setState({
      ...this.state,
      selectedVercelApp: app.id,
    });
  }

  returnToVercel() {
    window.location.href = this.state.vercel_redirect;
  }

  async fetchStarterKitFromTemplate(starterKitId) {
    await api
      .service("cms")
      .get(
        `api/starter-kits/${starterKitId}`,
        {
          populate:
            "template.roles.subscribeTo,template.roles.videoPermission,template.roles.screensharePermission",
        },
        {
          headers: null,
        }
      )
      .then(response => {
        const templateId = response?.data?.data?.attributes?.template?.data?.id;
        const templateData = response?.data?.data?.attributes?.template?.data;
        const roles = getRolesFromPublicTemplate(templateData);
        const event_type = templateData
          ? templateData.attributes.type
          : "CREATEOWN";
        this.setState({
          ...this.state,
          roles,
          templateId,
          starterKitId,
          event_type,
        });
      })
      .catch(error => {
        const error_message =
          "Something went wrong...!!!. We are looking into it.";
        console.error(error);
        this.setState({
          ...this.state,
          error: error_message,
        });
      });
  }

  async getVercelProjects() {
    const body = {
      vercel_code: this.state.code,
    };
    api
      .service("dashboard")
      .post("integrations/all-vercel-projects", body)
      .then(res => {
        this.setState({
          ...this.state,
          vercel_projects: res.data.vercel_projects,
          vercel_access_token: res.data.access_token,
          showVercelApps: true,
        });
      })
      .catch(error => {
        const error_message = error?.response?.data?.msg;
        this.setState({
          ...this.state,
          error: error_message,
        });
      });
  }

  async vercelAppIntegration() {
    this.setState({
      ...this.state,
      showVercelApps: false,
    });
    const region = (await nearestRegion()).toLowerCase();
    const body = {
      configuration_id: this.state.configurationId,
      vercel_access_token: this.state.vercel_access_token,
      vercel_projects: [this.state.selectedVercelApp],
      region,
      roles: this.state.roles,
      event_type: this.state.event_type,
      app_id: this.state.selectedApp,
    };
    api
      .service("dashboard")
      .post("integrations/vercel-app-integration", body)
      .then(() => {
        this.returnToVercel();
      })
      .catch(error => {
        const error_message = error?.response?.data?.msg;
        this.setState({
          ...this.state,
          error: error_message,
        });
      });
  }

  async vercelIntegration() {
    if (this.state.code) {
      const region = (await nearestRegion()).toLowerCase();
      const body = {
        configuration_id: this.state.configurationId,
        vercel_code: this.state.code,
        region,
        roles: this.state.roles,
        event_type: this.state.event_type,
        starter_kit_id: this.state.starterKitId,
        ts_template_id: this.state.templateId,
      };
      api
        .service("dashboard")
        .post("integrations/vercel-integration", body)
        .then(() => {
          this.returnToVercel();
        })
        .catch(error => {
          const error_message = error?.response?.data?.msg;
          this.setState({
            ...this.state,
            error: error_message,
          });
        });
    }
  }

  async componentDidMount() {
    await api
      .service("dashboard")
      .get("user-details")
      .then(() =>
        this.setState({
          ...this.state,
          signedIn: true,
          loading: false,
        })
      )
      .catch(() =>
        this.setState({
          ...this.state,
          signedIn: false,
          loading: false,
        })
      );

    const searchParams = queryString.parse(window.location.search);
    if (searchParams.code && this.state.signedIn) {
      const { code, configurationId, currentProjectId, next } = searchParams;
      this.setState({
        ...this.state,
        code,
        configurationId,
        currentProjectId,
        vercel_redirect: next,
      });
      const externalId = searchParams["external-id"];
      if (externalId === undefined) {
        this.props.fetchPublicTemplates();
        this.getVercelProjects();
        this.setState({
          ...this.state,
          loadingHeader: "Integrating with Vercel",
          loadingSubHeader: "Adding core dependencies...",
        });
        this.props.fetchTemplatesData();
      } else {
        await this.fetchStarterKitFromTemplate(externalId);
        this.vercelIntegration();
      }
    }
  }
  render() {
    let loading;
    let error;
    if (this.state.error) {
      error = (
        <div className="text-center m-auto">
          <h4
            className="font-bold leading-10 w-full m-15"
            style={{ fontSize: "24px", lineHeight: "30px" }}
          >
            Vercel Integration failed
          </h4>
          <div className="text-center mt-2 text-gray-cool4">
            {this.state.error}
          </div>
          <button
            className="btn btn-primary mt-4"
            onClick={() => this.returnToVercel()}
          >
            Return to Vercel -&gt;
          </button>
        </div>
      );
    }

    if (!this.state.signedIn && !this.state.loading) {
      loading = (
        <div className="m-auto">
          <VercelLogin />
        </div>
      );
    } else if (
      this.state.showVercelApps &&
      this.props.fetchTemplateStatus === API_CALL_STATE.DONE
    ) {
      if (!this.state.showAllApps) {
        loading = (
          <AppsPage
            key="vercelApps"
            listHeader="Integrate with 100ms"
            listSubHeader="Pick the vercel project you want to integrate with 100ms."
            appsCard={this.state.vercel_projects.map(app => {
              return (
                <AppCard
                  key={app.id}
                  onAppChange={this.onVercelAppChange}
                  templateName={app.name}
                  app={app}
                  selectedApp={this.state.selectedVercelApp}
                />
              );
            })}
            buttonText="Integrate with 100ms ->"
            buttonOnClick={() =>
              this.setState({
                ...this.state,
                showAllApps: true,
              })
            }
            isButtonDisabled={this.state.selectedVercelApp === ""}
            showApps={this.state.vercel_projects.length > 0}
            emptyScreenHeader="No projects to integrate"
            emptyScreenSubHeader="You haven’t created a Vercel project yet. To inegrate with 100ms, get started by creating a new project through Vercel website, then close this window and retry."
          />
        );
      } else {
        loading = (
          <AppsPage
            key="100msApps"
            listHeader="Integrate with vercel"
            listSubHeader="Pick the 100ms project you want to integrate with vercel."
            appsCard={this.props.all_templates.map(app => {
              const { image: templateImage, name: templateName } = getAppInfo(
                app,
                this.props.defaultTemplates,
                this.props.publicTemplates
              );
              return (
                <AppCard
                  key={app.app_id}
                  onAppChange={this.onAppChange}
                  templateImage={templateImage}
                  templateName={templateName}
                  app={app}
                  selectedApp={this.state.selectedApp}
                />
              );
            })}
            buttonText="Integrate with Vercel ->"
            buttonOnClick={() => this.vercelAppIntegration()}
            isButtonDisabled={this.state.selectedApp === ""}
            showApps={this.props.all_templates.length > 0}
            emptyScreenHeader="No projects to integrate"
            emptyScreenSubHeader="You haven’t created a 100ms project yet. To inegrate with Vercel, get started by creating a new project through 100ms website, then close this window and retry."
          />
        );
      }
    } else {
      loading = (
        <div className="mx-auto flex flex-col justify-between">
          <div className="my-32">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={150}
              width={150}
            />
            <div className="text-center">
              <h4
                className="font-bold w-full"
                style={{ fontSize: "34px", lineHeight: "40px" }}
              >
                {this.state.loadingHeader || "Deploying to vercel"}
              </h4>
              <div className="text-center mt-2 text-gray-cool4">
                {this.state.loadingSubHeader || "Fetching variables..."}
              </div>
            </div>
          </div>
          <div className="flex justify-center item-center m-3">
            <img
              className="grayscale"
              src={logo}
              alt="100ms logo"
              width={100}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="flex h-screen">{this.state.error ? error : loading}</div>
    );
  }
}

export default connect(
  state => ({
    publicTemplates: state.createApp.publicTemplates,
    all_templates: state.roles.all_templates,
    fetchTemplateStatus: state.createApp.fetchTemplateStatus,
  }),
  {
    fetchTemplatesData,
    fetchPublicTemplates,
  }
)(VercelTemplateIntegration);
