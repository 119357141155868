import { CrossIcon } from "@100mslive/react-icons";
import { Text } from "@100mslive/react-ui";

const Chip = ({ text, className, onClick }) => {
  return (
    <div className={className}>
      <Text
        className="truncate"
        variant="body1"
        css={{ fontWeight: "$regular", pr: "$4" }}
      >
        {text}
      </Text>
      <button onClick={onClick}>
        <CrossIcon className="h-4" />
      </button>
    </div>
  );
};

export default Chip;
