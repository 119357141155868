import { Box, Flex, Text } from "@100mslive/react-ui";
import hippaLogo from "../../assets/icons/HIPAALogo.svg";
import shieldCheck from "../../assets/icons/shieldCheck.svg";

const EXPANDED_SIDEBAR_WIDTH = "14rem";
const COLLAPSED_SIDEBAR_WIDTH = "4rem";

const HippaCompliantOption = ({ text, collapsed, isHipaa }) => {
  return isHipaa ? (
    <Box
      css={{
        color: "$textMedEmp",
        w: collapsed ? COLLAPSED_SIDEBAR_WIDTH : EXPANDED_SIDEBAR_WIDTH,
        m: collapsed ? "$8 0" : "$8",
        border: collapsed ? "none" : "1px solid $primaryLight",
        background: collapsed ? "transparent" : "$primaryDefault",
        borderRadius: "$1",
        padding: "$3 $4",
      }}
    >
      <Flex
        align="center"
        justify="center"
        css={{ gap: "$sm", c: "$textHighEmp" }}
      >
        <img
          src={hippaLogo}
          alt="Hippa"
          style={{ height: "40px", width: "30px" }}
        />
        {!collapsed ? (
          <>
            <Text
              variant="sm"
              css={{
                color: "inherit",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {text}
            </Text>{" "}
            <img
              src={shieldCheck}
              alt="Shield Check"
              style={{ height: "24px", width: "24px" }}
            />
          </>
        ) : (
          ""
        )}
      </Flex>
    </Box>
  ) : null;
};

export default HippaCompliantOption;
