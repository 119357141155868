import React from "react";
import { Card, CardBody } from "reactstrap";
import { getURL } from "../helpers/utils";

export default function AuthForm({
  title,
  subtitle,
  footer,
  className,

  children,
  vercelEmailClassName,
  disclaimer = false,
  free = false,

  isSocialButton,
}) {
  return (
    <div>
      <div className={className}>
        <React.Fragment>
          <Card
            className="overflow-hidden rounded-lg"
            style={{ backgroundColor: "transparent", maxWidth: "450px" }}
          >
            <div className="text-center">
              <div
                className={
                  vercelEmailClassName == null
                    ? "mt-3 p-4"
                    : vercelEmailClassName
                }
              >
                <h4
                  className="font-bold leading-10 w-full"
                  style={{ fontSize: "40px", lineHeight: "48px" }}
                >
                  {title}
                </h4>
                <div className="flex flex-row items-center justify-around">
                  {free && (
                    <p className=" rounded-sm rounded` text-sm bg-blue-standard standard p-1 px-1.5 mx-0.375">
                      Free!
                    </p>
                  )}
                  {subtitle && (
                    <p className="text-gray-cool4 text-xl tracking-tight font-normal my-3 ">
                      {subtitle}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <CardBody className="pt-0 pb-0">
              <div className="">{children}</div>
            </CardBody>
            {disclaimer && (
              <div className="ml-4" style={{ width: "90%" }}>
                <p
                  className="mb-8 text-gray-cool3"
                  style={{ fontSize: 10, textAlign: "center" }}
                >
                  By registering you agree to the 100ms'{" "}
                  <a
                    href={getURL("https://100ms.live/terms-of-service")}
                    target="_blank"
                    className="text-gray-cool5 text-decoration-underline"
                    rel="noreferrer"
                  >
                    <span className="text-decoration-underline">
                      {" "}
                      Terms of Service
                    </span>
                  </a>{" "}
                  and{" "}
                  <a
                    href={getURL("https://100ms.live/privacy-policy")}
                    target="_blank"
                    className="text-gray-cool5"
                    rel="noreferrer"
                  >
                    <span className="text-decoration-underline">
                      Privacy Policy
                    </span>
                  </a>
                </p>
              </div>
            )}
            <div>
              {isSocialButton}

              <div className="text-center mt-0 text-gray-cool4">{footer}</div>
            </div>
          </Card>
        </React.Fragment>
      </div>
    </div>
  );
}

export const GoogleIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "10px" }}
  >
    <path
      d="M20.7987 12.157C20.7987 11.4604 20.7421 10.9521 20.6198 10.4249H12.6655V13.569H17.3345C17.2404 14.3503 16.7321 15.527 15.6025 16.3176L15.5866 16.4229L18.1016 18.3712L18.2759 18.3886C19.8761 16.9107 20.7987 14.7362 20.7987 12.157Z"
      fill="#4285F4"
    />
    <path
      d="M12.6671 20.4412C14.9545 20.4412 16.8748 19.6881 18.2775 18.3891L15.604 16.318C14.8886 16.817 13.9284 17.1653 12.6671 17.1653C10.4267 17.1653 8.52518 15.6874 7.84734 13.6447L7.74799 13.6531L5.13283 15.677L5.09863 15.7721C6.49181 18.5396 9.3535 20.4412 12.6671 20.4412Z"
      fill="#34A853"
    />
    <path
      d="M7.84668 13.6447C7.66783 13.1176 7.56433 12.5527 7.56433 11.9691C7.56433 11.3855 7.66783 10.8207 7.83727 10.2935L7.83254 10.1813L5.18463 8.12488L5.098 8.16609C4.52381 9.31453 4.19434 10.6042 4.19434 11.9691C4.19434 13.3341 4.52381 14.6236 5.098 15.7721L7.84668 13.6447Z"
      fill="#FBBC05"
    />
    <path
      d="M12.667 6.77284C14.2578 6.77284 15.3309 7.46001 15.9428 8.03426L18.3338 5.69974C16.8654 4.3348 14.9544 3.49701 12.667 3.49701C9.35346 3.49701 6.4918 5.39849 5.09863 8.166L7.83791 10.2934C8.52515 8.25075 10.4266 6.77284 12.667 6.77284Z"
      fill="#EB4335"
    />
  </svg>
);
