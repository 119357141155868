import React, { FC, useRef, useState } from "react";
import { CheckIcon, ChevronDownIcon } from "@100mslive/react-icons";
import { Checkbox, CSS, Dropdown, Flex, Text } from "@100mslive/react-ui";

interface ConfigMultiSelectProps {
  inputText: string;
  onCheckedChange: (arg: string) => void;
  optionsArray: any[];
  isCheckedCondition: (arg: any) => boolean;
  disabled?: boolean;
  rootCSS?: CSS;
  triggerCSS?: CSS;
  contentCSS?: CSS;
  inputTextCss?: CSS;
}

const ConfigMultiSelect: FC<ConfigMultiSelectProps> = ({
  inputText,
  onCheckedChange,
  optionsArray,
  isCheckedCondition,
  disabled = false,
  rootCSS = {},
  triggerCSS = {},
  inputTextCss = {},
  contentCSS = {},
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <Dropdown.Root
      open={open}
      onOpenChange={!disabled ? () => setOpen(!open) : () => {}}
      modal={false}
      css={{ ...rootCSS }}
    >
      <Dropdown.Trigger
        asChild
        css={{
          r: "$1",
          w: "100%",
          mt: "$4",
          bg: "$surfaceLight",
          borderRadius: "$0",
          ...triggerCSS,
        }}
      >
        <Flex
          justify="between"
          align="center"
          css={{
            bg: "$surfaceLight !important",
            p: "$5 $6",
            border: "1px solid $borderLight",
            borderRadius: "$0",
          }}
          ref={ref}
        >
          <Text
            variant="body2"
            css={{ c: "$textAccentDisabled", ...inputTextCss }}
          >
            {inputText}
          </Text>
          <Flex
            css={{
              color: disabled ? "$textDisabled" : "$textMedEmp",
            }}
          >
            <ChevronDownIcon
              style={{
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform ease 0.3s",
              }}
            />
          </Flex>
        </Flex>
      </Dropdown.Trigger>
      <Dropdown.Content
        css={{
          width: `${
            ref?.current ? Math.floor(ref?.current?.offsetWidth) : "100"
          }px`,
          ...contentCSS,
        }}
        align="end"
        className="bg-surface-light shadow-2xl px-0"
        sideOffset={10}
        onInteractOutside={() => setOpen(false)}
      >
        {optionsArray.map((item: any) => {
          const isChecked = isCheckedCondition(item);
          return (
            <Dropdown.CheckboxItem
              key={item}
              onSelect={e => e.preventDefault()}
              checked={isChecked}
              onCheckedChange={() => onCheckedChange(item)}
              css={{ p: "$6" }}
            >
              <Flex align="center">
                <Checkbox.Root
                  id={item}
                  checked={isChecked}
                  css={{
                    bg: "$textMedEmp",
                    borderColor: "$textMedEmp",
                    "&[data-state='checked']": {
                      bg: "$textMedEmp",
                    },
                  }}
                >
                  <Checkbox.Indicator css={{ c: "$secondaryDark" }}>
                    <CheckIcon width={16} height={16} />
                  </Checkbox.Indicator>
                </Checkbox.Root>
                <Text variant="caption" css={{ ml: "$6", c: "$textMedEmp" }}>
                  {item}
                </Text>
              </Flex>
            </Dropdown.CheckboxItem>
          );
        })}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

export default ConfigMultiSelect;
