import React, { FC } from "react";
import { CrossIcon } from "@100mslive/react-icons";
import { Flex, Text } from "@100mslive/react-ui";

interface ValuePillProps {
  content: string;
  onClick: () => void;
}

const ValuePill: FC<ValuePillProps> = ({ content, onClick }) => {
  return (
    <Flex
      key={content}
      css={{
        c: "$textMedEmp",
        bg: "$secondaryDark",
        r: "$0",
        p: "$2 $4",
        mr: "$2",
        minWidth: "max-content",
        mt: "$4",
      }}
    >
      <Text variant="xs" css={{ mr: "$2", color: "$textMedEmp" }}>
        {content}
      </Text>

      <CrossIcon
        width={12}
        height={16}
        style={{ cursor: "pointer" }}
        onClick={onClick}
      />
    </Flex>
  );
};

export default ValuePill;
