import React from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import TitleValue from "src/components/Common/TitleValue";
import { peerAnalyticsType } from "src/types/analytics";
import { formattedDate, getLocalZone } from "src/utils";
import { Flex, Text, Tooltip } from "@100mslive/react-ui";

type Props = { peerData: peerAnalyticsType };

const participantSessionInfoObject = {
  "Session Id": "session_id",
  "Room Id": "room_id",
  "Joined At": "joined_at",
  "Left At": "left_at",
  Duration: "duration",
  "End Reason": "reason",
};

const publishedTracks = {
  audio: { key: "audio_published", val: "Audio" },
  video: { key: "video_published", val: "Video" },
  screen: { key: "screen_published", val: "Screen" },
};

const getPublishedTracks = (peerData: peerAnalyticsType) => {
  const listOfPublishedTracks: string[] = [];
  Object.keys(publishedTracks).forEach(key => {
    if (
      peerData[
        publishedTracks[key as keyof typeof publishedTracks]
          .key as keyof peerAnalyticsType
      ]
    ) {
      listOfPublishedTracks.push(
        publishedTracks[key as keyof typeof publishedTracks].val
      );
    }
  });
  return listOfPublishedTracks;
};

const ParticipantSessionInformation = ({ peerData }: Props) => {
  const listOfPublishedTracks = getPublishedTracks(peerData);
  const listOfInfo = Object.keys(participantSessionInfoObject);
  if (isEmpty(peerData)) {
    return <></>;
  }
  const zoneAbbr = getLocalZone();
  return (
    <>
      {/*eslint-disable-next-line complexity */}
      {listOfInfo.map((key: string) => {
        let modifiedKey = "";

        let val =
          peerData?.[
            participantSessionInfoObject[
              key as keyof typeof participantSessionInfoObject
            ] as keyof peerAnalyticsType
          ];
        if (!val) {
          return <> </>;
        }
        if (key === "Joined At" || key === "Left At") {
          const date = new Date(val as string);
          val = formattedDate(date);
          modifiedKey = `${key} (${zoneAbbr})`;
        }
        if (key === "Duration") {
          const duration = moment.duration(val as number, "seconds");
          val = moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
        }
        let link;
        if (key === "Room Id") {
          link = `/room-details/${val}`;
        }
        if (key === "Session Id") {
          link = `/session-details/${val}`;
        }
        const isID = listOfInfo[0] === key || listOfInfo[1] === key;
        return (
          <TitleValue
            title={modifiedKey ? modifiedKey : key}
            value={val.toString()}
            link={link ? link : undefined}
            btnId={
              participantSessionInfoObject[
                key as keyof typeof participantSessionInfoObject
              ]
            }
            componentId={
              participantSessionInfoObject[
                key as keyof typeof participantSessionInfoObject
              ]
            }
            copyButton={false}
            textCss={{
              c: isID ? "$primaryLight" : "$textHightEmp",
              fontFamily: isID ? "var(--user-font-roboto) !important" : "",
              "&:hover": { textDecoration: isID ? "underline" : "none" },
              fontWeight: 700,
            }}
          />
        );
      })}
      <Flex direction="column">
        <Text
          variant="tiny"
          css={{
            color: "$textMedEmp",
            fontWeight: "$semiBold",
            textTransform: "uppercase",
            mb: "$4",
            letterSpacing: "1.5px",
          }}
        >
          Published
        </Text>
        <Flex justify="start" align="center">
          <Tooltip
            outlined
            align="start"
            boxCss={{ bg: "$surfaceLight", r: "$0", p: "$4" }}
            title={
              <Flex direction="column">
                <Text
                  variant="caption"
                  css={{ fontWeight: "$regular", c: "$textDisabled" }}
                >
                  Tracks Published
                </Text>
                <Text
                  variant="caption"
                  css={{ fontWeight: "$semiBold", mt: "$2" }}
                >
                  {listOfPublishedTracks.join(", ")}
                </Text>
              </Flex>
            }
          >
            <Text
              variant="body2"
              css={{
                cursor: "pointer",
                c: "$primaryLight",
                "&:hover": { textDecoration: "underline" },
                fontWeight: 700,
              }}
            >
              {`${(!isEmpty(listOfPublishedTracks)
                ? listOfPublishedTracks.length
                : 0
              ).toString()} Tracks`}
            </Text>
          </Tooltip>
        </Flex>
      </Flex>
    </>
  );
};

export default ParticipantSessionInformation;
