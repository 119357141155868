const hubspotPageView = () => {
  const path = window.location.pathname;
  const _hsq = (window._hsq = window._hsq || []);
  if (_hsq) {
    _hsq.push(["setPath", path]);
    _hsq.push(["trackPageView"]);
  }
};

const hubspotIdentify = async ({ properties }: { properties: {} }) => {
  const _hsq = (window._hsq = window._hsq || []);
  if (_hsq) {
    _hsq.push(["identify", { properties }]);
  }
};

export const hubspotAnalytics = {
  pageView: hubspotPageView,
  identify: hubspotIdentify,
};
