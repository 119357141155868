export const echartsDataZoomConfig = {
  borderColor: "#1C2027",
  fillerColor: "rgba(20, 52, 102, 0.24)",
  dataBackground: {
    lineStyle: {
      color: "#1C2027",
    },
    areaStyle: {
      color: "#13161B",
    },
  },
  handleStyle: {
    color: "#1E232A",

    borderColor: "#2672ED",
  },
  moveHandleStyle: {
    color: "#1E232A",
    borderColor: "rgba(194, 208, 229, 0.5)",
  },
  selectedDataBackground: {
    areaStyle: {
      color: "#13161B",
    },
    lineStyle: {
      color: "#1C2027",
    },
  },
  emphasis: {
    moveHandleStyle: {
      color: "#282F39",
    },
    handleStyle: { color: "#282F39", borderColor: "#2672ED" },
  },
};
