import { merge } from "lodash";

export const mergeCustomiser = (objValue: any, srcValue: any) => {
  const mergedValue = merge(objValue, srcValue);

  if (Object.keys(mergedValue?.elements?.chat || {}).length > 0) {
    mergedValue.elements.chat.disable_autofocus = true;
  }
  if (Object.keys(mergedValue?.elements?.participant_list || {}).length > 0) {
    mergedValue.elements.participant_list.disable_autofocus = true;
  }
  return mergedValue;
};
