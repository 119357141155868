type appRegion = { name: string; key: string };

export const appRegions: appRegion[] = [
  {
    name: "United States",
    key: "us",
  },
  {
    name: "Europe",
    key: "eu",
  },
  {
    name: "India",
    key: "in",
  },
];
//change params to except filter as on of the key
function useAppRegionList(regionKey?: string) {
  let regions = appRegions;
  if (regionKey) {
    regions = regions.filter(region => region.key === regionKey);
  }
  return [regions];
}

export default useAppRegionList;
