import { Link } from "react-router-dom";
import { AppAnalytics } from "src/helpers";
import { Flex } from "@100mslive/react-ui";
import LeftBorderBox from "./LeftBorderBox";

const SidebarOption = ({
  link,
  text,
  icon,
  activeOption,
  collapsed,
  btnId,
  onClick = () => {},
}) => {
  const isActiveOption =
    activeOption === text.toLowerCase().replace(" ", "-") ||
    activeOption === btnId.toLowerCase().replace(" ", "-");

  return (
    <li
      style={{
        margin: "0",
        borderColor: "inherit",
      }}
    >
      <Flex
        onClick={() => {
          AppAnalytics.track("btn.clicked", {
            btnId: `sidebar.${btnId}`,
            componentId: "sidebar",
          });
          onClick();
        }}
        css={{ position: "relative" }}
      >
        <LeftBorderBox show={isActiveOption && !collapsed} />
        <Link to={link} className="text-inherit">
          <Flex
            className="option-container"
            css={{
              "&:hover": {
                backgroundColor: "$surfaceLighter",
                color: "$textHighEmp",
              },
              padding: collapsed ? "$4" : "$4 $8",
              width: collapsed ? "auto" : "224px",
              borderRadius: collapsed ? "$1" : "$0",
              margin: "0 auto",
              transition: "all 0.2s",
              ...(isActiveOption
                ? {
                    color: "$textHighEmp",
                    fontWeight: "$semiBold",
                    backgroundColor: "$surfaceLight",
                    fontSize: "$sm",
                  }
                : { color: "$textMedEmp" }),
            }}
          >
            {icon}
            <span
              className="text-inherit"
              style={{
                display: collapsed ? "none" : "inline-block",
                marginLeft: "1rem",
                whiteSpace: "nowrap",
              }}
            >
              {text}
            </span>
          </Flex>
        </Link>
      </Flex>
    </li>
  );
};

export default SidebarOption;
