import { Redirect } from "react-router-dom";
import OnboardingFlowWithUseCase from "src/modules/onboarding/index";
import SetTemplateFlowWithUseCase from "src/modules/setTemplate";
import VODPlayback from "src/pages/VODPlayback";
// Insights
import Insights from "../modules/insights/index";
//Peer Insights
import PeerInsights from "../modules/peerInsights/index";
//Room Details
import RoomDetails from "../modules/roomDetails/index";
//Session Details
import SessionDetails from "../modules/sessionDetails/index";
// Admin
import AdminHome from "../pages/Admin/index";
import Analytics from "../pages/Analytics/index";
//Activate
import Activate from "../pages/Authentication/Activate";
import AdminLogin from "../pages/Authentication/AdminLogin";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ResetPwd from "../pages/Authentication/ResetPassword";
import WebsiteGithubRegistration from "../pages/Authentication/websiteGithubRegistration";
import WebsiteGoogleRegistration from "../pages/Authentication/websiteGoogleRegistration";
//Welcome
import Welcome from "../pages/Authentication/Welcome";
// New App
// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Developer from "../pages/Developer/index";
//prebuilt
import Prebuilt from "../pages/Prebuilt";
//Rooms
import Rooms from "../pages/Rooms/index";
//Sessions
import Sessions from "../pages/Sessions/index";
//Settings
import Organisation from "../pages/Settings/organisation";
import Workspace from "../pages/Settings/workspace";
//Template
import Template from "../pages/Template";
import TemplateZeroState from "../pages/Template/ZeroState";
import Upgrade from "../pages/Upgrade/index";
// vercel
import Vercel from "../pages/Vercel";
import VercelTemplateConfig from "../pages/Vercel/VercelTemplateConfig";
import VercelTemplateIntegration from "../pages/Vercel/VercelTemplateIntegration";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  {
    path: "/onboarding",
    component: OnboardingFlowWithUseCase,
    fullscreen: true,
  },
  {
    path: "/create-template",
    component: SetTemplateFlowWithUseCase,
    fullscreen: true,
  },
  { path: "/play-vod/:src", component: VODPlayback },

  { path: "/rooms", component: Rooms },
  {
    path: "/templates/:policyId/prebuilt",
    component: Prebuilt,
  },
  { path: "/templates/:policyId", component: Template },
  { path: "/templates/", component: TemplateZeroState },
  { path: "/sessions", component: Sessions },
  { path: "/developer", component: Developer },
  //settings
  { path: "/settings/workspace", component: Workspace },
  { path: "/settings/organization", component: Organisation },
  { path: "/upgrade", component: Upgrade },
  { path: "/room-details/:roomId", component: RoomDetails },
  { path: "/session-details/peer/:peerId", component: PeerInsights },
  { path: "/session-details/:sessionId", component: SessionDetails },
  { path: "/admin", component: AdminHome },
  //insights
  { path: "/insights", component: Insights },
  //room debugger
  { path: "/events-inspector", component: Analytics },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/handleGoogleOauth", component: WebsiteGoogleRegistration },
  { path: "/handleGithubOauth", component: WebsiteGithubRegistration },
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/vercel-integration", component: VercelTemplateIntegration },
  { path: "/vercel-template-integration/cms-datocms", component: Vercel },
  { path: "/integration/configure", component: VercelTemplateConfig },
  { path: "/admin/login", component: AdminLogin },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/welcome", component: Welcome },
  { path: "/activate", component: Activate },
  { path: "/reset-password", component: ResetPwd },
  { path: "/accept-invite", component: <></> },
];

export { authProtectedRoutes, publicRoutes };
