import React from "react";

const backdrop = props =>
  props.show ? (
    <div
      className="w-full h-full z-40 fixed top-0 left-0 bg-transparent-backdrop"
      onClick={props.onClick}
    >
      {props.children}
    </div>
  ) : null;

export default backdrop;
