import { useEffect, useState } from "react";
import {
  defaultConferencing,
  defaultLiveStreamingConferencing,
  defaultPreview,
} from "src/data/defaultLayout";
import ScreensConfiguration from "./ScreensConfiguration";

export const usePrebuiltComponentList = ({
  screen,
  screenType,
}: {
  screen: keyof typeof ScreensConfiguration;
  screenType: string;
}) => {
  const [screenElements, setScreenElements] = useState([]);
  useEffect(() => {
    switch (screen) {
      case "preview":
        if (screenType === "default") {
          setScreenElements(
            //@ts-ignore
            Object.keys(defaultPreview?.default?.elements || {}) as Array<
              keyof (typeof ScreensConfiguration)["preview"]
            >
          );
        } else {
          setScreenElements(
            //@ts-ignore
            Object.keys(defaultPreview?.default?.elements || {}) as Array<
              keyof (typeof ScreensConfiguration)["preview"]
            >
          );
        }

        break;

      case "conferencing":
        if (screenType === "default") {
          setScreenElements(
            //@ts-ignore
            Object.keys(defaultConferencing.default?.elements || {}) as Array<
              keyof (typeof ScreensConfiguration)["conferencing"]
            >
          );
        } else {
          setScreenElements(
            //@ts-ignore
            Object.keys(
              defaultLiveStreamingConferencing.hls_live_streaming?.elements ||
                {}
            ) as Array<keyof (typeof ScreensConfiguration)["conferencing"]>
          );
        }
        break;
    }
  }, [screen, screenType]);

  return screenElements;
};
