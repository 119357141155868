//do not change the order of the colors

export const defaultLightColorPalette = [
  {
    name: "Primary",
    key: "primary",
    value: "#2572ED",
    colorPalette: [
      { key: "default", tone: 50, toneDelta: 0 },
      { key: "bright", tone: 60, toneDelta: 10 },
      { key: "dim", tone: 20, toneDelta: -30 },
      { key: "disabled", tone: 30, toneDelta: -20 },
    ],
    textColorPalette: [
      { key: "high", tone: 100 },
      { key: "medium", tone: 87 },
      { key: "low", tone: 70 },
    ],
  },
  {
    name: "Secondary",
    key: "secondary",
    value: "#444954",
    colorPalette: [
      { key: "default", tone: 40, toneDelta: 0 },
      { key: "bright", tone: 50, toneDelta: 10 },
      { key: "dim", tone: 20, toneDelta: -20 },
      { key: "disabled", tone: 30, toneDelta: -10 },
    ],
    textColorPalette: [
      { key: "high", tone: 100 },
      { key: "medium", tone: 87 },
      { key: "low", tone: 70 },
    ],
  },
  {
    name: "Surface",
    key: "surface",
    value: "#EFF0FA",
    colorPalette: [
      { key: "default", tone: 95, toneDelta: 0 },
      { key: "bright", tone: 87, toneDelta: -8 },
      { key: "brighter", tone: 92, toneDelta: -3 },
      { key: "dim", tone: 98, toneDelta: 3 },
    ],
    textColorPalette: [
      { key: "high", tone: 10 },
      { key: "medium", tone: 20 },
      { key: "low", tone: 40 },
    ],
  },

  {
    name: "Background",
    key: "background",
    onlyHue: false,
    value: "#FEFBFF",
    colorPalette: [
      { key: "default", tone: 97, toneDelta: 0 },
      { key: "dim", tone: 99, toneDelta: 2 },
    ],
  },

  {
    name: "Border",
    key: "border",
    value: "#ECEDF7",
    onlyHue: true,
    colorPalette: [
      { key: "default", tone: 94, toneDelta: 0 },
      { key: "bright", tone: 87, toneDelta: -7 },
    ],
  },
];

export const defaultDarkColorPalette = [
  {
    name: "Primary",
    key: "primary",
    value: "#2572ED",
    colorPalette: [
      { key: "default", tone: 50, toneDelta: 0 },
      { key: "bright", tone: 60, toneDelta: 10 },
      { key: "dim", tone: 20, toneDelta: -30 },
      { key: "disabled", tone: 30, toneDelta: -20 },
    ],
    textColorPalette: [
      { key: "high", tone: 100 },
      { key: "medium", tone: 87 },
      { key: "low", tone: 70 },
    ],
  },
  {
    name: "Secondary",
    key: "secondary",
    value: "#444954",
    colorPalette: [
      { key: "default", tone: 40, toneDelta: 0 },
      { key: "bright", tone: 50, toneDelta: 10 },
      { key: "dim", tone: 20, toneDelta: -20 },
      { key: "disabled", tone: 30, toneDelta: -10 },
    ],
    textColorPalette: [
      { key: "high", tone: 100 },
      { key: "medium", tone: 87 },
      { key: "low", tone: 70 },
    ],
  },
  {
    name: "Surface",
    key: "surface",
    value: "#191B23",
    colorPalette: [
      { key: "default", tone: 10, toneDelta: 0 },
      { key: "brighter", tone: 20, toneDelta: 10 },
      { key: "bright", tone: 17, toneDelta: 7 },
      { key: "dim", tone: 6, toneDelta: -4 },
    ],
    textColorPalette: [
      { key: "high", tone: 95 },
      { key: "medium", tone: 80 },
      { key: "low", tone: 60 },
    ],
  },

  {
    name: "Background",
    key: "background",
    value: "#0B0E15",
    onlyHue: false,
    colorPalette: [
      { key: "default", tone: 4, toneDelta: 0 },
      { key: "dim", tone: 2, toneDelta: -2 },
    ],
  },
  {
    name: "Border",
    key: "border",
    onlyHue: true,
    value: "#1D1F27",
    colorPalette: [
      { key: "default", tone: 12, toneDelta: 0 },
      { key: "bright", tone: 17, toneDelta: 5 },
    ],
  },
];
