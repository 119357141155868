import { isEmpty } from "lodash";
import {
  HLS_VIEWER_ROLE,
  INTERNAL_RECORDER_ROLE,
  NEW_HLS_VIEWER_ROLE,
} from "src/helpers";
import { getHLSViewerRolesFromRoleLayouts } from "src/helpers/appLayoutHelper";
import { policyType } from "src/types/policyTypes";
import { RoleLayouts } from "src/types/prebuilt";

// eslint-disable-next-line complexity
export const isHLSDestinationsEnabled = ({
  policy,
  roleLayouts,
}: {
  policy: policyType;
  roleLayouts: RoleLayouts;
}) => {
  if (isEmpty(policy)) {
    return false;
  }
  const isOldTemplate = isEmpty(roleLayouts);
  if (!isOldTemplate) {
    const hlsDestinationsIds = Object.keys(
      policy?.destinations?.hlsDestinations || {}
    );
    const hlsViewerRolePresent =
      !isEmpty(policy?.roles?.[HLS_VIEWER_ROLE]) ||
      !isEmpty(policy?.roles?.[NEW_HLS_VIEWER_ROLE]);
    const hlsViewersRoleLayouts =
      getHLSViewerRolesFromRoleLayouts({
        roleLayouts: roleLayouts,
      }) || [];
    if (
      !isEmpty(policy?.roles?.[INTERNAL_RECORDER_ROLE]) &&
      hlsViewerRolePresent &&
      hlsDestinationsIds.length > 0 &&
      hlsViewersRoleLayouts.length > 0 &&
      !isEmpty(policy?.destinations?.hlsDestinations?.[hlsDestinationsIds[0]])
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    const hlsDestinationsIds = Object.keys(
      policy?.destinations?.hlsDestinations || {}
    );
    if (
      !isEmpty(policy?.roles?.[INTERNAL_RECORDER_ROLE]) &&
      !isEmpty(policy?.roles?.[HLS_VIEWER_ROLE]) &&
      hlsDestinationsIds.length > 0 &&
      !isEmpty(policy?.destinations?.hlsDestinations?.[hlsDestinationsIds[0]])
    ) {
      return true;
    } else {
      return false;
    }
  }
};
