import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPolicyInfo, fetchTemplatesData } from "src/actions/RolesActions";
import { API_CALL_STATE, templateTypes } from "src/constants";
import {
  AppAnalytics,
  getPriorityRolesArray,
  getVisibleRoles,
  history,
} from "src/helpers";
import StreamDetailsForm from "src/modules/rooms/components/Forms/StreamDetailsForm";
import { isHLSDestinationsEnabled } from "src/services/policy/destinations";
import { fetchAppLayout } from "src/store/appLayout/actions";
import type { RootState } from "src/store/reducers";
import {
  createRoomRTMPStreamKeys,
  fetchRoomRTMPStreamKeys,
  fetchRoomToken,
  fetchShortCodeForAll,
} from "src/store/rooms/actions";
import { currentUser } from "src/utils";
import {
  BrushDesignIcon,
  CheckCircleIcon,
  CodeIcon,
  ComputerIcon,
  InfoIcon,
  LinkIcon,
} from "@100mslive/react-icons";
import { Box, Button, Flex, Loading, Text, Tooltip } from "@100mslive/react-ui";
import DashboardButton from "./Common/Button";
import Credential from "./Common/Credential";
import TabTile from "./Common/TabTile";
import Tag from "./Tag/Tag";
import RoomCodesDisabledState from "./RoomCodesDisabledState";
import question from "../assets/images/question.png";
import { RolesTooltip } from "../pages/Roles/RolesTooltip";

interface Props {
  roomId?: string;
  componentId: string;
  subdomain?: string;
  templateId?: string;
  openWithActiveRole?: string;
  isOnboarding?: boolean;
  roomCodesDisabled?: boolean;
  fetchTemplates?: boolean;
}

// eslint-disable-next-line complexity
const JoinRoomForm: React.FC<Props> = ({
  componentId,
  roomId,
  subdomain,
  templateId,
  openWithActiveRole = "",
  isOnboarding = false,
  roomCodesDisabled = false,
  fetchTemplates = false,
}) => {
  const dispatch = useDispatch();

  const credentialInputCSS = {
    border: "1px solid $borderLight",
    backgroundColor: isOnboarding ? "$surfaceLight" : "$surfaceLighter",
    pl: "$6",
    borderRadius: "$0",
  };
  const addNewAppStatus = useSelector(
    (state: RootState) => state.createTemplate.addNewAppStatus
  );
  const fetchPolicyInfoStatus = useSelector(
    (state: RootState) => state.roles.fetchPolicyInfoStatus
  );
  const createRTMPStreamKeysStatus = useSelector(
    (state: RootState) => state.rooms.createRTMPStreamKeysStatus
  );
  const fetchRTMPStreamKeysStatus = useSelector(
    (state: RootState) => state.rooms.fetchRTMPStreamKeysStatus
  );
  const policyInfo = useSelector((state: RootState) => state.roles.policyInfo);
  const roleLayouts = useSelector(
    (state: RootState) => state.appLayouts.roleLayouts
  );
  const roomRoleTokens = useSelector(
    (state: RootState) => state.rooms.roomRoleTokens
  );
  const roomShortCodeForRoles = useSelector(
    (state: RootState) => state.rooms.roomShortCodeForRoles
  );
  const fetchRoomTokenStatus = useSelector(
    (state: RootState) => state.rooms.fetchRoomTokenStatus
  );
  const fetchRoomShortCodeStatus = useSelector(
    (state: RootState) => state.rooms.fetchRoomShortCodeStatus
  );
  const fetchAllRoomCodesStatus = useSelector(
    (state: RootState) => state.rooms.fetchAllRoomCodesStatus
  );
  const createRoomStatus = useSelector(
    (state: RootState) => state.createRoom.createRoomStatus
  );
  const all_templates = useSelector(
    (state: RootState) => state.roles.all_templates
  );
  const roomShortCodeErrorMessage = useSelector(
    (state: RootState) => state.rooms.roomShortCodeErrorMessage
  );
  const publicTemplates = useSelector(
    (state: RootState) => state.createApp.publicTemplates
  );
  const roomStreamKeys = useSelector(
    (state: RootState) => state.rooms.roomStreamKeys
  );

  useEffect(() => {
    if (addNewAppStatus === API_CALL_STATE.DONE && fetchTemplates) {
      dispatch(fetchTemplatesData());
    }
  }, [addNewAppStatus, dispatch, fetchTemplates]);

  const getDeepLinkPathPrefix = () => {
    const template = all_templates.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (policy: any) => templateId === policy.id
    );
    const cmsTemplate = publicTemplates.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (publicTemplate: any) => publicTemplate.id === template?.ts_template_id
    );
    return cmsTemplate?.attributes?.type === templateTypes.LIVE_STREAM
      ? "/streaming"
      : "";
  };

  const [activeRole, setActiveRole] = useState<string>("");
  const [copiedLink, setCopiedLink] = useState<boolean>(false);
  const user = currentUser();
  useEffect(() => {
    if (roomId) {
      dispatch(fetchShortCodeForAll(roomId));
    }
  }, [dispatch, roomId]);

  useEffect(() => {
    if (templateId) {
      dispatch(fetchPolicyInfo(templateId));
      dispatch(fetchAppLayout({ templateId: templateId }));
    }
  }, [dispatch, templateId]);

  useEffect(() => {
    if (Object.keys(roomShortCodeForRoles).length) {
      const firstRole =
        openWithActiveRole ||
        getPriorityRolesArray(
          getVisibleRoles(Object.keys(roomShortCodeForRoles))
        )[0].toString();
      setActiveRole(firstRole);
      dispatch(
        fetchRoomToken(roomShortCodeForRoles?.[firstRole]?.value, firstRole)
      );
      try {
        AppAnalytics.track("btn.clicked", {
          btnId: "join.room",
          componentId: componentId,
          roles: Object.keys(roomShortCodeForRoles).length,
          account_id: user.customer_id,
          room_id: roomId,
          hms_link_present: !!subdomain,
        });
      } catch (e) {
        console.error(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomShortCodeForRoles, roomId]);

  useEffect(() => setCopiedLink(false), [activeRole]);

  if (roomCodesDisabled) {
    return <RoomCodesDisabledState />;
  }
  const deepLink = getDeepLinkPathPrefix();

  // Conditionally render loading state while waiting for room codes, room creation, or template data fetching to complete.
  return (
    <>
      {fetchAllRoomCodesStatus === API_CALL_STATE.IN_PROGRESS ||
      createRoomStatus === API_CALL_STATE.IN_PROGRESS ||
      addNewAppStatus === API_CALL_STATE.IN_PROGRESS ? (
        <Flex css={{ p: "$10", w: "100%" }} justify="center" align="center">
          <Loading size={48} />
        </Flex>
      ) : fetchAllRoomCodesStatus === API_CALL_STATE.FAILED ||
        addNewAppStatus === API_CALL_STATE.FAILED ? (
        <Flex
          css={{ p: "$14", w: "100%" }}
          justify="center"
          align="center"
          direction="column"
        >
          <Flex css={{ mt: "$14", w: "100%" }} justify="center">
            <img src={question} alt="100ms" width="80px" height="80px" />
          </Flex>
          <Text variant="body1" css={{ c: "$textMedEmp", mt: "$10" }}>
            Something went wrong
          </Text>
          {
            // eslint-disable-next-line no-extra-boolean-cast
            !!roomShortCodeErrorMessage ? (
              <Text
                variant="sub2"
                css={{ c: "$textDisabled", mt: "$4", mb: "$20" }}
              >
                {roomShortCodeErrorMessage}
              </Text>
            ) : null
          }
        </Flex>
      ) : fetchAllRoomCodesStatus === API_CALL_STATE.DONE ? (
        <Flex css={{ p: "$10" }} direction={isOnboarding ? "column" : "row"}>
          <Flex
            css={{
              w: isOnboarding ? "100%" : "25%",
              mr: "$10",
              mb: isOnboarding ? "$8" : "0",
              overflow: "auto",
              maxHeight: "540px",
            }}
            align={!isOnboarding ? "start" : "center"}
            direction={!isOnboarding ? "column" : "row"}
          >
            <Text
              variant="sm"
              css={{
                color: "$textMedEmp",

                mb: isOnboarding ? "0" : "$3",
                minWidth: "max-content",
                fontWeight: "$semiBold",
              }}
            >
              Select a role to join
            </Text>

            {getPriorityRolesArray(
              getVisibleRoles(Object.keys(roomShortCodeForRoles))
            ).map((role: string) => (
              <TabTile
                key={role}
                text={role}
                active={role === activeRole}
                css={{
                  mt: isOnboarding ? 0 : "$8",
                  w: "100%",
                  ml: isOnboarding ? "$4" : "0",
                }}
                onClick={() => {
                  setActiveRole(role);
                  dispatch(
                    fetchRoomToken(roomShortCodeForRoles?.[role]?.value, role)
                  );
                  AppAnalytics.track("btn.clicked", {
                    platform: "hms-dashboard",
                    roleName: role,
                    componentId: `join.your.room.${componentId}`,
                    btnId: "role.switched",
                  });
                }}
              />
            ))}
            <Flex
              css={{ bg: "$borderLight", h: "1px", w: "100%", my: "$md" }}
            />
            <Text
              variant="sm"
              css={{
                color: "$textMedEmp",
                mb: isOnboarding ? "0" : "$3",
                minWidth: "max-content",
                fontWeight: "$semiBold",
              }}
            >
              Go live using RTMP-Ingest
            </Text>
            <TabTile
              css={{
                w: "100%",
                ml: "0",
                mt: "$8",
              }}
              key="GoLiveRTMPIngest"
              text="Stream Key Details"
              active={"GoLiveRTMPIngest" === activeRole}
              onClick={() => {
                if (templateId) {
                  setActiveRole("GoLiveRTMPIngest");
                  dispatch(fetchPolicyInfo(templateId));
                  dispatch(fetchRoomRTMPStreamKeys(roomId || ""));
                }
              }}
            />
          </Flex>
          <Flex
            direction="column"
            css={{
              p: "$12",
              backgroundColor: isOnboarding
                ? "$surfaceDefault"
                : "$surfaceLight",
              borderRadius: "$2",
              w: isOnboarding ? "100%" : "75%",
              flex: "1 1 0",
              minWidth: 0,
            }}
          >
            {activeRole !== "GoLiveRTMPIngest" ? (
              <>
                <Flex align="center" css={{ color: "$primaryLight" }}>
                  <ComputerIcon
                    height={40}
                    width={40}
                    style={{ color: "inherit" }}
                  />
                  <Box css={{ ml: "$8" }}>
                    <Flex align="center">
                      <Text
                        variant="lg"
                        css={{ color: "$textHighEmp", fontWeight: "$semiBold" }}
                      >
                        Join via Prebuilt{" "}
                      </Text>

                      <Tag
                        variant="overline"
                        label="NEW!"
                        css={{ ml: "$4", p: "$1 $4", r: "$0" }}
                      />
                    </Flex>
                    <Text variant="sm" css={{ color: "$textMedEmp", mt: "$2" }}>
                      Prebuilt Link is a hosted URL, running on an open source
                      100ms web app
                    </Text>
                  </Box>
                </Flex>
                <Flex
                  align="center"
                  css={{
                    mt: "$8",
                    width: "100%",
                    minWidth: "0",
                    flex: "1 1 0",
                    "@xl": {
                      flexDirection: "column",
                    },
                  }}
                >
                  <Flex align="center" css={{ width: "100%" }}>
                    <Tooltip
                      title="This room does not have an attached 100ms subdomain to it."
                      disabled={!!subdomain}
                    >
                      <DashboardButton
                        //@ts-ignore
                        variant="highlight"
                        css={{
                          w: "100%",
                          "@xl": {
                            w: "100%",
                          },
                          "& div": { w: "80%" },
                          borderRadius: "$0",
                          border: "0 !important",
                          height: "$14",
                          transition: "all 0.4s ease",
                        }}
                        loading={
                          fetchRoomShortCodeStatus ===
                            API_CALL_STATE.IN_PROGRESS ||
                          fetchAllRoomCodesStatus === API_CALL_STATE.IN_PROGRESS
                        }
                        disabled={
                          !subdomain ||
                          fetchRoomShortCodeStatus ===
                            API_CALL_STATE.IN_PROGRESS
                        }
                        onClick={() => {
                          AppAnalytics.track("btn.clicked", {
                            platform: "hms-dashboard",
                            componentId: `join.your.room.${componentId}`,
                            btnId: "join.role.clicked",
                          });
                          window.open(
                            `https://${subdomain}${deepLink}/meeting/${roomShortCodeForRoles?.[activeRole]?.value}`,
                            "_blank"
                          );
                        }}
                      >
                        <Text
                          variant="sm"
                          css={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            fontWeight: "$semiBold",
                            color: "$textHighEmp",
                          }}
                        >
                          Join as {activeRole}
                        </Text>
                      </DashboardButton>
                    </Tooltip>
                  </Flex>
                  <Flex
                    css={{
                      w: "50%",
                      ml: "$6",
                      "@xl": {
                        w: "100%",
                        mt: "$8",
                        ml: "0px",
                      },
                    }}
                  >
                    <Button
                      icon
                      variant="standard"
                      outlined
                      disabled={!subdomain}
                      css={{
                        w: "50%",
                        fontSize: "$sm",
                        maxHeight: "$14",
                        borderRadius: "$0",
                      }}
                      onClick={() => {
                        AppAnalytics.track("btn.clicked", {
                          platform: "hms-dashboard",
                          componentId: `join.your.room.${componentId}`,
                          btnId: "copy.invite.link",
                        });
                        window.navigator.clipboard.writeText(
                          `https://${subdomain}${deepLink}/meeting/${roomShortCodeForRoles?.[activeRole]?.value}`
                        );
                        setCopiedLink(true);
                        setTimeout(() => setCopiedLink(false), 2000);
                      }}
                    >
                      {copiedLink ? (
                        <Text
                          variant="sm"
                          css={{
                            fontWeight: "$semiBold",
                            color: "$textHighEmp",
                          }}
                        >
                          <CheckCircleIcon /> Link Copied
                        </Text>
                      ) : (
                        <Text
                          variant="sm"
                          css={{
                            fontWeight: "$semiBold",
                            color: "$textHighEmp",
                          }}
                        >
                          <LinkIcon /> Copy Invite Link
                        </Text>
                      )}
                    </Button>

                    <Button
                      icon
                      variant="standard"
                      outlined
                      disabled={!subdomain}
                      onClick={() => {
                        AppAnalytics.track("btn.clicked", {
                          platform: "hms-dashboard",
                          componentId: `customise.your.prebuilt.${componentId}`,
                          btnId: "customise.prebuilt.clicked",
                        });
                        history.push(`/templates/${templateId}/prebuilt`);
                      }}
                      css={{
                        w: "50%",
                        ml: "$6",
                        fontSize: "$sm",
                        maxHeight: "$14",
                        borderRadius: "$0",
                      }}
                    >
                      <BrushDesignIcon />
                      <Text
                        variant="sm"
                        css={{ fontWeight: "$semiBold", color: "$textHighEmp" }}
                      >
                        Customise
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
                <Flex
                  align="center"
                  justify="between"
                  gap="2"
                  css={{ my: "$14" }}
                >
                  <Box
                    css={{
                      backgroundColor: "$borderLight",
                      w: "100%",
                      h: "1px",
                    }}
                  />
                  <Text
                    variant="overline"
                    css={{ color: "$textDisabled", fontWeight: "$semiBold" }}
                  >
                    OR
                  </Text>
                  <Box
                    css={{
                      backgroundColor: "$borderLight",
                      w: "100%",
                      h: "1px",
                    }}
                  />
                </Flex>
                <Flex align="center" gap="4" css={{ color: "$primaryLight" }}>
                  <CodeIcon
                    height={40}
                    width={40}
                    style={{ color: "inherit" }}
                  />
                  <Box>
                    <Text
                      variant="lg"
                      css={{ color: "$textHighEmp", fontWeight: "$semiBold" }}
                    >
                      Join with SDK
                    </Text>
                    <Text variant="sm" css={{ color: "$textMedEmp", mt: "$2" }}>
                      Use 100ms android, iOS or Web SDKs to join
                    </Text>
                  </Box>
                </Flex>
                <Flex css={{ gap: "$10" }}>
                  <Flex direction="column" css={{ w: "50%" }}>
                    <Flex
                      align="center"
                      css={{ color: "$textDisabled", mt: "$10", mb: "$xs" }}
                    >
                      <Text
                        variant="xs"
                        css={{
                          color: "$textMedEmp",

                          fontWeight: "$semiBold",
                        }}
                      >
                        Room Code for {activeRole}
                      </Text>
                      <Flex
                        align="center"
                        css={{ w: "14px", marginLeft: "4px" }}
                      >
                        <InfoIcon
                          data-tip
                          data-for="nameOfRoomCode"
                          height={14}
                          width={14}
                        />
                      </Flex>
                      {/* eslint-disable-next-line */}
                      {/* @ts-ignore */}
                      <RolesTooltip
                        id="nameOfRoomCode"
                        clickable
                        delayHide={300}
                      >
                        <Box css={{ maxWidth: "$40" }}>
                          Room codes allow you to create unique room links and
                          authentication mechanism for every role.
                          <Text
                            variant="sm"
                            css={{ color: "$primaryLight", display: "inline" }}
                          >
                            <a
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                color: "inherit",
                                cursor: "pointer",
                                fontSize: "13px",
                              }}
                              href={`${process.env.REACT_APP_WEBSITE_URL}docs/concepts/v2/concepts/rooms/room-codes/room-codes`}
                            >
                              &nbsp;Learn more.
                            </a>
                          </Text>
                        </Box>
                      </RolesTooltip>
                    </Flex>
                    <Credential
                      readOnly
                      showClipboard
                      hideLabel
                      clickEventName="btn.clicked"
                      clickEventProps={{
                        platform: "hms-dashboard",
                        componentId: `join.your.room.${componentId}`,
                        btnId: "room.code.copied",
                        roleName: activeRole,
                      }}
                      label="App Access Key"
                      inputCss={credentialInputCSS}
                      value={roomShortCodeForRoles?.[activeRole]?.value}
                      getIsSecret={() => {
                        return false;
                      }}
                    />
                  </Flex>
                  <Flex direction="column" css={{ w: "50%" }}>
                    <Flex
                      align="center"
                      css={{ color: "$textDisabled", mt: "$10", mb: "$xs" }}
                    >
                      <Text
                        variant="xs"
                        css={{
                          color: "$textMedEmp",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          fontWeight: "$semiBold",
                        }}
                      >
                        Auth Token for {activeRole}
                      </Text>
                      <Flex
                        align="center"
                        css={{ w: "14px", marginLeft: "4px" }}
                      >
                        <InfoIcon
                          data-tip
                          data-for="nameOfRole"
                          height={14}
                          width={14}
                        />
                      </Flex>
                      {/* eslint-disable-next-line */}
                      {/* @ts-ignore */}
                      <RolesTooltip id="nameOfRole" clickable delayHide={300}>
                        <Box css={{ maxWidth: "$40" }}>
                          Auth tokens enable you to join rooms with 100ms client
                          SDKs.{" "}
                          <Text
                            variant="sm"
                            css={{ color: "$primaryLight", display: "inline" }}
                          >
                            <a
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                color: "inherit",
                                cursor: "pointer",
                                fontSize: "13px",
                              }}
                              href={`${process.env.REACT_APP_WEBSITE_URL}docs/concepts/v2/concepts/security-and-tokens`}
                            >
                              Learn more
                            </a>
                          </Text>
                        </Box>
                      </RolesTooltip>
                    </Flex>
                    {fetchRoomTokenStatus === API_CALL_STATE.IN_PROGRESS ? (
                      <Flex
                        css={{ w: "100%", h: "42px" }}
                        justify="center"
                        align="center"
                      >
                        <Loading />
                      </Flex>
                    ) : (
                      <Credential
                        readOnly
                        showClipboard
                        hideLabel
                        clickEventName="btn.clicked"
                        clickEventProps={{
                          platform: "hms-dashboard",
                          componentId: `join.your.room.${componentId}`,
                          btnId: "auth.token.copied",
                          roleName: activeRole,
                        }}
                        label="App Access Key"
                        inputCss={credentialInputCSS}
                        value={roomRoleTokens?.[activeRole]?.token}
                        getIsSecret={() => {
                          return false;
                        }}
                      />
                    )}
                  </Flex>
                </Flex>
                <Button
                  css={{
                    width: "100%",
                    mt: "$10",
                    fontSize: "$sm",
                    fontWeight: "$semiBold",
                    color: "$textHighEmp",
                    maxHeight: "$14",
                    borderRadius: "$0",
                  }}
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_WEBSITE_URL}docs/javascript/v2/quickstart/react-sample-app/quickstart`,
                      "_blank"
                    );
                    AppAnalytics.track("btn.clicked", {
                      platform: "hms-dashboard",
                      componentId: `join.your.room.${componentId}`,
                      btnId: "view.guide",
                    });
                  }}
                >
                  View Guide to Build Your Room
                </Button>
              </>
            ) : (
              <StreamDetailsForm
                roomId={roomId || ""}
                templateStatus={fetchPolicyInfoStatus}
                isOnboarding={isOnboarding}
                policyId={templateId || ""}
                createRTMPStreamStatus={createRTMPStreamKeysStatus}
                fetchRTMPStatus={fetchRTMPStreamKeysStatus}
                isHLSEnabled={isHLSDestinationsEnabled({
                  policy: policyInfo,
                  roleLayouts: roleLayouts,
                })}
                roomStreamDetails={roomStreamKeys}
                createRTMPStream={async function (
                  roomId: string
                ): Promise<void> {
                  await dispatch(createRoomRTMPStreamKeys(roomId));
                  await dispatch(fetchRoomRTMPStreamKeys(roomId));
                }}
              />
            )}
          </Flex>
        </Flex>
      ) : null}
    </>
  );
};

export default JoinRoomForm;
