import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isArray } from "lodash";
import { API_CALL_STATE } from "src/constants";
import { fetchWorkspacePeerDetails } from "src/store/insights/actions";
import { RootState } from "src/store/reducers";
import { Box, Flex } from "@100mslive/react-ui";
import { ActivePeersChart } from "../components/charts/activePeersChart";
import { ActivePeersChartBreakdown } from "../components/charts/activePeersChartBreakdown";
import ActivePeersChartHeader from "../components/charts/headers/activePeersChartHeader";
import TitleWithTrend from "../components/common/TitleWithTrend";
import PeerBreakdownList from "../components/lists/peerBreakdownList";
import {
  peerGroupByOptions as groupByOptions,
  WORKSPACE_PEER_PLATFORM_LIST,
} from "../constants";
import { convertDateToDiffDays } from "../helpers";
import { groupByActivePeers } from "../helpers/convert";
import ChartLoaderLayout from "../layouts/ChartLoaderLayout";

export const WorkspacePeersInsights = () => {
  const dispatch = useDispatch();
  const {
    startDate,
    endDate,
    filter,
    fetchWorkspacePeerInsightsStatus,
    workspacePeerInsightsDetails,
    workspacePeerInsightsTrends,
  } = useSelector((state: RootState) => state.insights);
  const range = convertDateToDiffDays({
    startDate: startDate || (new Date() as Date),
    endDate: endDate || (new Date() as Date),
  });
  const [groupByForActivePeers, setGroupByForActivePeers] =
    useState("platform");

  const [groupByForPeerBreakdown, setGroupByForPeerBreakdown] =
    useState("platform");

  useEffect(() => {
    if (startDate && endDate && filter) {
      dispatch(fetchWorkspacePeerDetails());
    }
  }, [dispatch, endDate, filter, startDate]);

  return (
    <Flex direction="column" css={{ w: "100%" }}>
      <Box
        css={{
          display: "grid",
          w: "100%",
          gridTemplateColumns: "repeat(2, 1fr)",
          columnGap: "$10",
        }}
      >
        <Flex direction="column" css={{ pt: "0px", pb: "$10" }}>
          <ChartLoaderLayout
            status={fetchWorkspacePeerInsightsStatus}
            css={{ pb: "$10" }}
            header={
              <ActivePeersChartHeader
                groupBy={groupByForActivePeers}
                setGroupBy={(groupBy: string) => {
                  setGroupByForActivePeers(groupBy);
                }}
                groupByOptions={Object.keys(groupByOptions)}
                total={workspacePeerInsightsTrends.curr_total}
                trend={workspacePeerInsightsTrends.percent_change}
                makeTotalOpaque={
                  fetchWorkspacePeerInsightsStatus === API_CALL_STATE.DONE
                }
                tooltip={`The following number shows the total number of peers that joined any session that happened in the last ${range}.`}
                title="Total Peers"
              />
            }
            isDataEmpty={
              !isArray(workspacePeerInsightsDetails) ||
              workspacePeerInsightsDetails.length === 0
            }
            title="Total Peers"
          >
            <ActivePeersChart
              filter={filter}
              data={groupByActivePeers({
                workspacePeerInsightsDetails: workspacePeerInsightsDetails,
                groupBy: groupByForActivePeers,
              })}
              groupBy={groupByForActivePeers}
              loading={
                fetchWorkspacePeerInsightsStatus === API_CALL_STATE.IN_PROGRESS
              }
            />
          </ChartLoaderLayout>
          <Flex
            css={{
              border: "$borderDefault solid $space$px",
              p: "$10",
              r: "$1",
            }}
          >
            <TitleWithTrend
              title="Average Peer Duration"
              tooltip={`This is the average of duration of all the peers that joined the sessions that happened in the last ${range}.`}
              total={workspacePeerInsightsTrends.curr_avg}
              trend={workspacePeerInsightsTrends.avg_change}
              totalLabel="mins"
              makeTotalOpaque={
                fetchWorkspacePeerInsightsStatus === API_CALL_STATE.DONE
              }
            />
          </Flex>
        </Flex>
        <ChartLoaderLayout
          status={fetchWorkspacePeerInsightsStatus}
          childWrapperCSS={{
            h: "100%",
          }}
          header={
            <ActivePeersChartHeader
              makeTotalOpaque={
                fetchWorkspacePeerInsightsStatus === API_CALL_STATE.DONE
              }
              groupBy={groupByForPeerBreakdown}
              setGroupBy={(groupBy: string) => {
                setGroupByForPeerBreakdown(groupBy);
              }}
              groupByOptions={Object.keys(groupByOptions)}
              total={0}
              trend={0}
              showTotal={false}
              tooltip="This chart shows the distribution of peers by platform or role. Hover over each section to see the percentage and number of peers. Use the `Group By` to show other breakdowns."
              title="Peer Breakdown"
            />
          }
          isDataEmpty={
            !isArray(workspacePeerInsightsDetails) ||
            workspacePeerInsightsDetails.length === 0
          }
          title="Daily Active Peers"
        >
          <Flex direction="column">
            <ActivePeersChartBreakdown
              isGroupByRole={groupByForPeerBreakdown === "role"}
              data={groupByActivePeers({
                workspacePeerInsightsDetails: workspacePeerInsightsDetails,
                groupBy: groupByForPeerBreakdown,
              })}
              loading={
                fetchWorkspacePeerInsightsStatus === API_CALL_STATE.IN_PROGRESS
              }
            />
            <PeerBreakdownList
              data={groupByActivePeers({
                workspacePeerInsightsDetails: workspacePeerInsightsDetails,
                groupBy: groupByForPeerBreakdown,
              })}
              groupBy={groupByForPeerBreakdown}
              getTitle={(title: string) => {
                if (groupByForPeerBreakdown === "role") {
                  return title;
                }
                return WORKSPACE_PEER_PLATFORM_LIST[
                  title as keyof typeof WORKSPACE_PEER_PLATFORM_LIST
                ].name;
              }}
            />
          </Flex>
        </ChartLoaderLayout>
      </Box>
    </Flex>
  );
};

export default WorkspacePeersInsights;
