import React from "react";
import logo from "../../assets/images/100ms-logo.png";

export const AppsPage = ({
  listHeader,
  listSubHeader,
  appsCard,
  buttonText,
  buttonOnClick,
  isButtonDisabled,
  showApps,
  emptyScreenHeader,
  emptyScreenSubHeader,
}) => {
  return (
    <div className="w-full app-card-container">
      <div
        className="w-full bg-[#0f141d]"
        style={{ position: "fixed", top: 0 }}
      >
        <div className="ml-4">
          <img className="mt-3" src={logo} alt="100ms logo" width={150} />
        </div>
        <div className="w-full h-0 border border-black bg-[#23272C] mt-4"></div>
      </div>
      {showApps && (
        <div className="mb-28">
          <div className="h-full mt-28 ml-4">
            <div className="font-bold m-1 text-20 leading-6">{listHeader}</div>
            <div className="m-1 text-gray-cool4">{listSubHeader}</div>
          </div>
          {appsCard}
          <div
            className="w-full bg-[#0f141d]"
            style={{ position: "fixed", bottom: 0 }}
          >
            <div className="w-full h-0 border border-black bg-[#23272C]"></div>
            <div className="flex flex-row-reverse">
              <button
                className="btn btn-primary btn-block waves-effect waves-light mt-4 mb-4 mr-3 pl-2 pr-2"
                style={{ width: "224px" }}
                onClick={buttonOnClick}
                disabled={isButtonDisabled}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      )}
      {!showApps && (
        <div className="mb-15 text-center">
          <h4
            className="font-bold leading-10"
            style={{ fontSize: "20px", lineHeight: "24px" }}
          >
            {emptyScreenHeader}
          </h4>
          <div className="text-center mt-2 text-gray-cool4">
            {emptyScreenSubHeader}
          </div>
        </div>
      )}
    </div>
  );
};
