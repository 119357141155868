import { Peer } from "src/types/sessions";

export const sortSessionPeerTableColumn = (
  filteredPeers: { [key: string]: Peer },
  sortBy: string,
  direction: string
) => {
  const dataArray = Object.values(filteredPeers);
  dataArray.sort((a, b) => {
    const valueA = a[sortBy as keyof Peer];

    let nameA;
    if (typeof valueA === "string") {
      nameA = valueA.toLowerCase();
    } else {
      nameA = valueA;
    }

    const valueB = b[sortBy as keyof Peer];

    let nameB;
    if (typeof valueB === "string") {
      nameB = valueB.toLowerCase();
    } else {
      nameB = valueB;
    }

    if (nameA === undefined && nameB === undefined) {
      return 0;
    }
    if (nameA === undefined) {
      return -1;
    }
    if (nameB === undefined) {
      return 1;
    }
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  const sortedDataObject: { [key: string]: Peer } = {};
  if (direction === "desc") {
    dataArray.reverse();
  }
  dataArray.forEach(item => {
    sortedDataObject[item.id] = item;
  });
  return sortedDataObject;
};
