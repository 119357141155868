export function validateWebhookURL(url: string) {
  let urlInstance;
  try {
    urlInstance = new URL(url);
  } catch (e) {
    console.error("webhook url validation failed", e);
    return false;
  }
  return ["http:", "https:"].includes(urlInstance.protocol);
}
