import React, { useMemo } from "react";
import * as echarts from "echarts";
import { default as EchartsWrapper } from "src/components/Echarts";
import { getSubscribeRTTChartConfiguration } from "../../helpers/getSubscribeConfigurations";
type echartsOptions = echarts.EChartsOption;

export const SubscriberRTTChart = ({
  publishData,
  loading,
}: {
  publishData: any;
  loading: boolean;
}) => {
  const peerSubscriberRTTChartOptions: echartsOptions = useMemo(
    () => getSubscribeRTTChartConfiguration(publishData),
    [publishData]
  );

  return (
    <>
      {/* @ts-ignore */}
      <EchartsWrapper
        options={{ ...peerSubscriberRTTChartOptions }}
        loading={loading}
        style={{ height: "240px", width: "auto" }}
      />
    </>
  );
};
