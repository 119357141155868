import api from "../api";
import { getAPIURL } from "../utils";

export const FETCH_COACHMARKS_DONE = "FETCH_COACHMARKS_DONE";
export const FETCH_COACHMARKS_FAIL = "FETCH_COACHMARKS_FAIL";

export const fetchCoachMarks = () => {
  return dispatch => {
    api
      .service("dashboard")
      .get(getAPIURL("coachmarks"))
      .then(res => {
        dispatch({
          type: FETCH_COACHMARKS_DONE,
          payload: { coachmarks: res.data.coachmarks },
        });
      })
      .catch(err => {
        console.error(err);
        dispatch({ type: FETCH_COACHMARKS_FAIL });
      });
  };
};

export const updateCoachmarks = page => {
  return (dispatch, getState) => {
    const { coachmarks } = getState().coachmarks;
    if (coachmarks.indexOf(page) === -1) {
      dispatch({
        type: FETCH_COACHMARKS_DONE,
        payload: { coachmarks: [...coachmarks, page] },
      });
    }

    api
      .service("dashboard")
      .post(getAPIURL("coachmarks"), {
        page: page,
      })
      .then(res => {
        if (!res.data.success) {
          throw Error("Error saving coachMarks");
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
};
