import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import LargeIcon from "src/components/Common/Icons/LargeIcon";
import Line from "src/components/Common/Line";
import Tag from "src/components/Tag/Tag";
import { API_CALL_STATE } from "src/constants";
import { history, openDevrevWidget } from "src/helpers";
import { fetchPeersAnalyticsDetails } from "src/store/analytics/actions";
import { RootState } from "src/store/reducers";
import { peerAnalyticsType } from "src/types/analytics";
import {
  AlertTriangleIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  NoEntryIcon,
  SupportIcon,
} from "@100mslive/react-icons";
import { Box, Button, Flex, Text } from "@100mslive/react-ui";
import ParticipantInformation from "../components/ParticipantInformation";
import ParticipantSessionInformation from "../components/ParticipantSessionInformation";
import PeerPlatformDetails from "../components/PeerPlatformDetails";
import "react-loading-skeleton/dist/skeleton.css";
type Props = {
  peerId: string;
};

const infoFlexCss = {
  r: "$1",
  flex: "1 1 0",
  flexWrap: "wrap",
  gap: "$10",
  w: "100%",
  rowGap: "$10",
};
// eslint-disable-next-line complexity
const PeerInsights = ({ peerId }: Props) => {
  const dispatch = useDispatch();
  const fetchPeerAnalyticsDetailsStatus = useSelector(
    (state: RootState) => state.roomDebugger.fetchPeerAnalyticsDetailsStatus
  );
  const peerAnalyticsDetails = useSelector(
    (state: RootState) => state.roomDebugger.peerAnalyticsDetails
  );

  const [showParticipantInfo, setShowParticipantInfo] = useState(true);
  useEffect(() => {
    if (peerId) {
      dispatch(fetchPeersAnalyticsDetails(peerId));
    }
  }, [dispatch, peerId]);
  return (
    <>
      <Flex className="d-flex justify-content-between align-items-center">
        <Flex className="d-flex justify-content-space-between align-items-center">
          <Box
            css={{
              w: "$10",
              h: "$10",
              color: "$textHighEmp",
              cursor: "pointer",
              "&:hover": {
                c: "$textDisabled",
              },
            }}
            onClick={() => history.goBack()}
          >
            <LargeIcon>
              <ChevronLeftIcon />
            </LargeIcon>
          </Box>
          <Flex
            align="center"
            css={{
              ml: "$4",
            }}
          >
            <Text
              variant="h5"
              css={{
                fontWeight: "$semiBold",
                color: "$textHighEmp",
              }}
            >
              Peer Insights
            </Text>
            <Tag
              type="highlight"
              css={{
                fontSize: "$tiny",
                r: "$0",
                py: "$2",
                px: "$4",
                ml: "$4",
              }}
              variant="overline"
              label="BETA"
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex css={{ w: "100%", py: "$10" }}>
        <Flex
          direction="column"
          css={{
            w: "100%",
            border: "$space$px solid $borderDefault",
            r: "$1",
            px: "$10",
            py: "$9",
          }}
        >
          <Flex
            justify="between"
            align="center"
            onClick={() => setShowParticipantInfo(!showParticipantInfo)}
            css={{
              cursor: "pointer",
            }}
          >
            {fetchPeerAnalyticsDetailsStatus === API_CALL_STATE.IN_PROGRESS ? (
              <Skeleton width={160} height="100%" />
            ) : (
              <Text
                variant="sub2"
                css={{
                  fontWeight: "$semiBold",
                }}
              >
                Peer Information
              </Text>
            )}
            <Flex>
              <LargeIcon>
                <ChevronDownIcon
                  style={{
                    transform: showParticipantInfo ? "rotate(180deg)" : "none",
                    transition: "transform 0.2s ease-in-out",
                  }}
                />
              </LargeIcon>
            </Flex>
          </Flex>

          <Flex
            css={{
              overflow: "hidden",
              visibility: showParticipantInfo ? "visible" : "hidden",
              transition:
                "max-height 0.4s ease-in-out, visibility 0.3s ease-in-out", // Adjust the duration and easing as needed
              maxHeight: showParticipantInfo ? "700px" : "0",
            }}
            direction="column"
          >
            {fetchPeerAnalyticsDetailsStatus === API_CALL_STATE.FAILED ? (
              <Flex
                css={{ rowGap: "$md", minHeight: "240px", w: "100%" }}
                direction="column"
                justify="center"
                align="center"
              >
                <Flex css={{ c: "$error" }}>
                  <AlertTriangleIcon width={40} height={40} />
                </Flex>
                <Flex direction="column" css={{ textAlign: "center" }}>
                  <Text variant="sub2" css={{ mb: "$2" }}>
                    Something went wrong
                  </Text>
                  <Text
                    variant="caption"
                    css={{
                      fontWeight: "$regular",
                      c: "$textMedEmp",
                      w: "20rem",
                    }}
                  >
                    We couldn&apos;t load details. This feature is currently in
                    alpha and there are known issues we&apos;re working on.
                  </Text>
                </Flex>
                <Button
                  icon
                  variant="standard"
                  css={{ p: "$3 $4", r: "$0" }}
                  onClick={() => openDevrevWidget(true)}
                >
                  <SupportIcon />
                  Report Issue
                </Button>
              </Flex>
            ) : fetchPeerAnalyticsDetailsStatus ? (
              <>
                <Flex
                  css={{
                    ...infoFlexCss,
                    mt: "$8",
                  }}
                >
                  {fetchPeerAnalyticsDetailsStatus === API_CALL_STATE.DONE ? (
                    <ParticipantInformation
                      peerData={
                        (peerAnalyticsDetails || {}) as peerAnalyticsType
                      }
                    />
                  ) : (
                    <>
                      {Array(3)
                        .fill(1)
                        .map(_ => {
                          return <Skeleton width={160} height={44} />;
                        })}
                    </>
                  )}
                </Flex>
                <Line />
                {fetchPeerAnalyticsDetailsStatus ===
                API_CALL_STATE.IN_PROGRESS ? (
                  <Skeleton width={160} height="16px" />
                ) : (
                  <Text
                    variant="caption"
                    css={{ fontWeight: "$semiBold", mb: "$8" }}
                  >
                    Session Information
                  </Text>
                )}
                <Flex
                  css={{
                    ...infoFlexCss,
                  }}
                >
                  {fetchPeerAnalyticsDetailsStatus === API_CALL_STATE.DONE ? (
                    <ParticipantSessionInformation
                      peerData={
                        (peerAnalyticsDetails || {}) as peerAnalyticsType
                      }
                    />
                  ) : (
                    <>
                      {Array(5)
                        .fill(1)
                        .map(_ => {
                          return <Skeleton width={160} height={44} />;
                        })}
                    </>
                  )}
                </Flex>
                <Line />
                <Flex
                  css={{
                    ...infoFlexCss,
                    gap: "$16",
                  }}
                >
                  {" "}
                  {fetchPeerAnalyticsDetailsStatus === API_CALL_STATE.DONE ? (
                    <PeerPlatformDetails
                      peerPlatformData={
                        (peerAnalyticsDetails || {}) as peerAnalyticsType
                      }
                    />
                  ) : (
                    <>
                      {Array(4)
                        .fill(1)
                        .map(_ => {
                          return <Skeleton width={160} height={44} />;
                        })}
                    </>
                  )}
                </Flex>
              </>
            ) : isEmpty(peerAnalyticsDetails) ? (
              <>
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  css={{ rowGap: "$md", minHeight: "240px", w: "100%" }}
                >
                  <Flex css={{ c: "$textDisabled" }}>
                    <NoEntryIcon width={40} height={40} />
                  </Flex>
                  <Flex direction="column" css={{ textAlign: "center" }}>
                    <Text variant="sub2" css={{ mb: "$2" }}>
                      No Peer information
                    </Text>

                    <Text
                      variant="caption"
                      css={{
                        fontWeight: "$regular",
                        c: "$textMedEmp",
                        w: "20rem",
                      }}
                    >
                      Peer information is not available for this, Please contact
                      support.
                    </Text>
                  </Flex>
                </Flex>
              </>
            ) : (
              <></>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default PeerInsights;
