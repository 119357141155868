import { InfoIcon } from "@100mslive/react-icons";
import { Box, Flex } from "@100mslive/react-ui";

export const createErrorMessage = msg => {
  return (
    msg && (
      <Flex gap="1" className="items-center">
        <Box>
          <InfoIcon />
        </Box>
        <Box>{msg}</Box>
      </Flex>
    )
  );
};

const test = {
  validWorkspaceName: [
    {
      regex: /[\S\s]+/,
      msg: "Workspace name cannot be empty",
    },
    {
      regex: /^[a-zA-Z0-9-_., ]*$/,
      msg: (
        <>
          Invalid special character being used. Accepted special <br />
          characters are " . - _ "
        </>
      ),
    },
    {
      validateFunc: (s, arr) => !arr.includes(s),
      msg: "Workspace already exists",
    },
    {
      regex: /^[\S\s]{1,50}$/,
      msg: "Workspace name cannot be more than 50 characters",
    },
  ],
  validOrganisationName: [
    {
      regex: /[\S\s]+/,
      msg: "Organization name cannot be empty",
    },
    {
      regex: /^[a-zA-Z0-9-_., ]*$/,
      msg: (
        <>
          Invalid special character being used. Accepted special <br />
          characters are (, . - _)
        </>
      ),
    },
    {
      regex: /^[\S\s]{1,50}$/,
      msg: "Organization name cannot be more than 50 characters",
    },
  ],
  validTemplateName: [
    {
      regex: /[\S\s]+/,
      msg: "Name cannot be empty",
    },
    {
      regex: /^[a-zA-Z0-9-_. ]*$/,
      msg: (
        <>
          Invalid special character being used. Accepted special <br />
          characters are " . - _ "
        </>
      ),
    },
    {
      regex: /^[\S\s]{3,}$/,
      msg: "Name requires minimum 3 characters.",
    },
    {
      regex: /^[\S\s]{3,80}$/,
      msg: "Name can have maximum 80 characters.",
    },
  ],
  validSubdomain: [
    {
      regex: /[\S\s]+/,
      msg: "Subdomain cannot be empty",
    },
    {
      regex: /^[\S\s]{3,50}$/,
      msg: "subdomain requires 3 to 50 characters",
    },
    {
      //eslint-disable-next-line
      validateFunc: (s, arr) => !(s.startsWith("-") || s.endsWith("-")),
      msg: "Hyphens are not allowed at start/end of a domain",
    },
    {
      regex: /\b(?!default\b)\w+/,
      msg: "You cannot use this reserved keyword",
    },
    {
      regex: /^[a-z0-9][-a-z0-9]+[a-z0-9]$/,
      msg: "Subdomain can only have letters, numbers and '-'",
    },
  ],
  validRoleName: [
    {
      regex: /^[a-z0-9-]*$/,
      msg: "Accepted characters are a-z, 0-9 and '-'",
    },
    {
      regex: /[\S\s]+/,
      msg: "Role name cannot be empty",
    },

    {
      //eslint-disable-next-line
      validateFunc: (s, arr) => !arr.includes(s),
      msg: "Role name cannnot be same as other role",
    },
    {
      regex: /^[\S\s]{3,}$/,
      msg: "Name requires minimum 3 characters.",
    },
    {
      regex: /^[\S\s]{3,25}$/,
      msg: "Name can have maximum 25 characters.",
    },
  ],
  validRoomName: [
    {
      regex: /[\S\s]+/,
      msg: "Name cannot be empty",
    },
    {
      regex: /^[a-zA-Z0-9-.:_' ]+$/,
      msg: "Invalid character(s) being used. Accepted special characters are . _ : - '",
    },
    {
      regex: /^[\S\s]{3,}$/,
      msg: "Name requires minimum 3 characters.",
    },
    {
      regex: /^[\S\s]{3,80}$/,
      msg: "Name can have maximum 80 characters.",
    },
  ],
};

const Messages = (testName, payload, textOnly = false, validityArray) => {
  const result = test[testName].find(index => {
    if (index?.regex) {
      return !index.regex.test(payload) ? index.msg : undefined;
    }
    return !index.validateFunc(payload, validityArray);
  });
  if (textOnly) {
    return result?.msg;
  }
  return createErrorMessage(result?.msg);
};

export default Messages;
