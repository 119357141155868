import api from "../api";

export default async function storeCoachmarks(page) {
  const params = { page };
  await api
    .service("dashboard")
    .post("coachmarks", params)
    .then(res => {
      if (!res.data.success) {
        throw Error("Error saving coachmarks!");
      }
    })
    .catch(err => console.error(err));
}
