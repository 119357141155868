import { useSelector } from "react-redux";
import { Text } from "@100mslive/react-ui";
import DeleteDialog from "./DeleteDialog";
import { API_CALL_STATE } from "../../constants";

const DeleteTemplateModal = ({
  checked,
  toggleCheckBox,
  disableButton,
  data,
  open,
  deleteFunction,
  toggleDeleteDialog,
}) => {
  const { deletePolicyStatus } = useSelector(state => state.roles);
  return (
    <DeleteDialog
      title="Delete Template"
      subtitle="This template deletion can’t be undone. Are you sure you want to delete this template?"
      CheckboxText={() => (
        <Text
          variant="body2"
          css={{ c: "$textAccentMedium", fontWeight: "$regular" }}
        >
          I acknowledge that the template will be deleted, and connected clients
          and rooms to this template will be non-functional.
        </Text>
      )}
      checked={checked}
      toggleCheckBox={toggleCheckBox}
      disableButton={disableButton}
      data={data}
      open={open}
      loading={deletePolicyStatus === API_CALL_STATE.IN_PROGRESS}
      deleteFunction={deleteFunction}
      toggleDeleteDialog={toggleDeleteDialog}
    />
  );
};

export default DeleteTemplateModal;
