import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import { SET_REGION } from "./actions";

const initialState = {
  region: "",
};

export const userInfo = handleActions<typeof initialState, any>(
  {
    [SET_REGION]: (state, action: AnyAction) => ({
      ...state,
      region: action.payload,
    }),
  },
  initialState
);
