import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMeasure } from "react-use";
import ConfigSingleSelect from "src/components/Common/ConfigSingleSelect";
import { MEMBER_TYPES, WORKSPACE_ROLES } from "src/constants";
import { RootState } from "src/store/reducers";
import { workspaceMemberType } from "src/types/workspaces";
import { PersonIcon, TrashIcon } from "@100mslive/react-icons";
import { Dropdown, Flex, Text } from "@100mslive/react-ui";
import Options from "../../components/Common/Options";
import Table from "../../components/Common/Table";
import MainDialog from "../../components/Modal/MainDialog";
import { AppAnalytics } from "../../helpers/analytics_helper";
import {
  deleteWorkspaceMember,
  updateWorkspaceMemberRole,
} from "../../store/workspace/actions";
import { currentUser } from "../../utils";

export default function Members() {
  const dispatch = useDispatch();
  const user = currentUser();
  const { current: currentWorkspace } = useSelector(
    (state: RootState) => state.workspace,
    () => false
  );
  const workspaceMembers = currentWorkspace.members;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [changeRoleDialogOpen, setChangeRoleDialogOpen] = useState(false);
  const deleteMember = useCallback(
    (id: string) => {
      dispatch(deleteWorkspaceMember(id));
    },
    [dispatch]
  );
  const [selectedMember, setSelectedMember] = useState<workspaceMemberType>({
    id: "",
  });
  const [newRole, setNewRole] = useState<(typeof WORKSPACE_ROLES)["admin"]>(
    {} as (typeof WORKSPACE_ROLES)["admin"]
  );
  const currentMember = workspaceMembers.find(
    member => member.email === currentUser()?.email
  ) as workspaceMemberType;
  const tableHeaders = ["Email", "Type", ""];
  const handleMemberRoleChange = useCallback((role: string) => {
    const roleType = Object.values(WORKSPACE_ROLES).filter(
      genRoles => genRoles.id === role
    )?.[0];
    setNewRole(roleType);
  }, []);
  const handleMemberRoleSave = useCallback(() => {
    dispatch(updateWorkspaceMemberRole(selectedMember.id, newRole.id));
  }, [dispatch, newRole.id, selectedMember.id]);

  const changeRoleMemberListOption = useCallback((row: workspaceMemberType) => {
    return {
      data: <ChangeRoleUI />,
      func: () => {
        setSelectedMember(row);
        setChangeRoleDialogOpen(state => !state);
      },
    };
  }, []);

  const deleteMemberListOption = useCallback((row: workspaceMemberType) => {
    return {
      data: <DeleteMemberUI />,
      func: () => {
        setSelectedMember(row);
        setDialogOpen(state => !state);
      },
    };
  }, []);

  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const getRoleOfMember = useCallback((member: workspaceMemberType) => {
    if (member?.isOrgAdmin) {
      return WORKSPACE_ROLES[MEMBER_TYPES.OWNER];
    }
    if (member?.isAdmin) {
      return WORKSPACE_ROLES[MEMBER_TYPES.ADMIN];
    }
    if (member?.role === WORKSPACE_ROLES[MEMBER_TYPES.READ_ONLY].id) {
      return WORKSPACE_ROLES[MEMBER_TYPES.READ_ONLY];
    }
    return WORKSPACE_ROLES[MEMBER_TYPES.MEMBER];
  }, []);

  return (
    <div className="pt-6">
      <Table tableHeaders={tableHeaders}>
        {/* eslint-disable-next-line complexity*/}
        {workspaceMembers?.map((row, key) => {
          const memberRole = getRoleOfMember(row);
          const optionToDelete = deleteMemberListOption(row);
          const optionToChangeRole = changeRoleMemberListOption(row);
          const currentUserRole = getRoleOfMember(currentMember);

          const options: string | any[] = [];
          if (
            currentUserRole.id === WORKSPACE_ROLES[MEMBER_TYPES.ADMIN].id &&
            memberRole.id !== WORKSPACE_ROLES[MEMBER_TYPES.OWNER].id
          ) {
            if (memberRole.id === WORKSPACE_ROLES[MEMBER_TYPES.ADMIN].id) {
              options.push(optionToDelete);
            } else {
              options.push(optionToChangeRole);
              options.push(optionToDelete);
            }
          }
          if (
            currentUserRole.id === WORKSPACE_ROLES[MEMBER_TYPES.OWNER].id &&
            memberRole.id !== WORKSPACE_ROLES[MEMBER_TYPES.OWNER].id
          ) {
            options.push(optionToChangeRole);
            options.push(optionToDelete);
          }

          return (
            <tr key={`row-${key}`}>
              <td>
                <Text variant="sm">{row.email}</Text>
              </td>
              <td>
                <Text
                  variant="sm"
                  css={
                    row.isOrgAdmin
                      ? {
                          bg: "$primaryDark",
                          borderRadius: "$0",
                          width: "fit-content",
                          padding: "4px 8px 4px 8px",
                        }
                      : {}
                  }
                >
                  {memberRole.name}
                </Text>
              </td>
              <td className="text-right pr-8 relative">
                <Flex direction="row">
                  {!(
                    getRoleOfMember(row).id ===
                    WORKSPACE_ROLES[MEMBER_TYPES.OWNER].id
                  ) &&
                  user.user_id !== row.id &&
                  options.length ? (
                    <Options content={options as any[]} />
                  ) : null}
                </Flex>
              </td>
            </tr>
          );
        })}
      </Table>
      <MainDialog
        title="Remove Member"
        variant="danger"
        subtitle={
          <Text
            variant="body2"
            css={{ fontWeight: "$regular", c: "$textMedEmp", mb: "$6" }}
          >
            Are you sure you want to remove{" "}
            <span className="font-semibold text-textIcon-highEmp">
              {selectedMember?.email}
            </span>{" "}
            from{" "}
            <span className="font-semibold text-textIcon-highEmp">
              {currentWorkspace?.name} workspace?
            </span>
          </Text>
        }
        open={dialogOpen}
        buttonText="Yes, Remove"
        mainFunction={() => {
          AppAnalytics.track("btn.clicked", {
            btnId: "removed.member.workspace",
            removedMemberEmail: selectedMember?.email,
            componentId: "modal",
          });
          deleteMember(selectedMember?.id);
        }}
        onOpenChange={() => setDialogOpen(prev => !prev)}
      />
      <MainDialog
        title="Change Role"
        subtitle={
          <Text
            variant="body2"
            css={{ fontWeight: "$regular", c: "$textMedEmp", mb: "$6" }}
          >
            Change
            <span className="font-semibold text-textIcon-highEmp">
              {` ${selectedMember?.email}`}
            </span>
            's role from{" "}
            <span className="font-semibold text-textIcon-highEmp">
              {selectedMember?.isAdmin ? "Admin" : "Member"}
            </span>{" "}
            to
          </Text>
        }
        content={
          <Flex direction="column" ref={ref}>
            <ConfigSingleSelect
              rootCSS={{ width: "100%" }}
              triggerCSS={{ width: `min(100%,${width}px)` }}
              inputText={newRole?.name || "Select role to assign"}
              contentCSS={{
                width: `${width ? Math.floor(width) * 0.75 : 0}px`,
                height: "auto",
                maxHeight: "$96",
                overflowY: "hidden",
                textAlign: "center",
                zIndex: "1200",
              }}
              content={
                <>
                  {Object.values(WORKSPACE_ROLES)
                    .filter(
                      r =>
                        r.id !== WORKSPACE_ROLES.owner.id &&
                        r.id !== getRoleOfMember(selectedMember).id
                    )
                    .map(role => {
                      return (
                        <Dropdown.Item
                          key={role.id}
                          css={{ p: "$4 $8" }}
                          onClick={() => {
                            handleMemberRoleChange(role.id);
                          }}
                        >
                          <Text variant="caption">{role.name}</Text>
                        </Dropdown.Item>
                      );
                    })}
                </>
              }
            />
          </Flex>
        }
        open={changeRoleDialogOpen}
        disableButton={!newRole?.name}
        buttonText="Change Role"
        mainFunction={() => {
          AppAnalytics.track("btn.clicked", {
            btnId: "workspace.role.change",
            removedMemberEmail: selectedMember?.email,
            componentId: "modal",
          });
          handleMemberRoleSave();
        }}
        onOpenChange={() => {
          setChangeRoleDialogOpen(prev => !prev);
          setNewRole({} as (typeof WORKSPACE_ROLES)["member"]);
        }}
      />
    </div>
  );
}

const DeleteMemberUI = () => {
  return (
    <Text
      variant="caption"
      css={{
        fontWeight: "$regular",
        c: "$error",
        p: "$4 $md",
        display: "flex",
        alignItems: "center",
      }}
    >
      <TrashIcon className="mr-2" />
      Remove Member
    </Text>
  );
};

const ChangeRoleUI = () => {
  return (
    <Text
      variant="caption"
      css={{
        fontWeight: "$regular",
        p: "$4 $md",
        display: "flex",
        alignItems: "center",
      }}
    >
      <PersonIcon className="mr-2" />
      Change Role
    </Text>
  );
};
