export const defaultLayouts = [
  "https://img-vb.100ms.live/layouts/defaults/vb-1.jpg",
  "https://img-vb.100ms.live/layouts/defaults/vb-2.jpg",
  "https://img-vb.100ms.live/layouts/defaults/vb-3.png",
  "https://img-vb.100ms.live/layouts/defaults/vb-4.jpg",
  "https://img-vb.100ms.live/layouts/defaults/vb-5.jpg",
  "https://img-vb.100ms.live/layouts/defaults/vb-6.jpg",
  "https://img-vb.100ms.live/layouts/defaults/vb-7.jpg",
  "https://img-vb.100ms.live/layouts/defaults/vb-8.jpg",
  "https://img-vb.100ms.live/layouts/defaults/vb-9.jpg",
  "https://img-vb.100ms.live/layouts/defaults/vb-10.jpg",
  "https://img-vb.100ms.live/layouts/defaults/vb-11.jpg",
  "https://img-vb.100ms.live/layouts/defaults/vb-12.jpg",
];

export const MAX_FILE_SIZE_IN_KB = 2048;

// Prebuilt components constants
export const PREBUILT_COMPONENT_TITLES = Object.freeze({
  HAND_RAISE: "Hand Raise",
  VIRTUAL_BACKGROUND: "Virtual Background",
  ON_STAGE_EXP: "Bring others on Stage",
});
