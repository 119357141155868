import React, { PureComponent } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { API_CALL_STATE } from "src/constants";
import { AppAnalytics } from "src/helpers";
import { Svg100MsLogoIcon } from "@100mslive/react-icons";
import { Box, Flex, Text } from "@100mslive/react-ui";
import SidebarContent from "./SidebarContent";
import { fetchPublicTemplates } from "../../actions/CreateAppActions";
import { fetchTemplatesData } from "../../actions/RolesActions";
import logo from "../../assets/images/logo-blue-dark.svg";
import collapse from "../../assets/images/SidebarCollapse.svg";
import jwt_verify from "../../helpers/token_expiry";
import { currentUser, currentWorkspaceHipaa, isAdmin } from "../../utils";
import HippaCompliantOption from "../Sidebar/HippaCompliantOption";

const EXPANDED_SIDEBAR_WIDTH = "16rem";
const COLLAPSED_SIDEBAR_WIDTH = "4rem";

class Sidebar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: !JSON.parse(window.localStorage.getItem("sticky") || false),
      sticky: JSON.parse(window.localStorage.getItem("sticky") || true),
      isHipaa: currentWorkspaceHipaa(),
    };
  }

  componentDidMount() {
    const verified = jwt_verify();
    const user = currentUser();
    if (typeof window !== "undefined") {
      const value = JSON.parse(window.localStorage.getItem("sticky") || true);
      this.setState({ sticky: value, collapsed: !value });
      const elem = document.getElementsByClassName("main-content")[0];

      if (this.state.collapsed === true) {
        elem.style.marginLeft = COLLAPSED_SIDEBAR_WIDTH;
      } else if (this.state.collapsed === false) {
        elem.style.marginLeft = EXPANDED_SIDEBAR_WIDTH;
      }
    }
    if (verified) {
      if (!isAdmin()) {
        this.props.fetchTemplatesData();
      } else {
        if (!this.props.all_templates.length && !!user?.customer?.customer_id) {
          this.props.fetchTemplatesData();
        }
      }
    }
    this.props.fetchPublicTemplates();
  }

  componentDidUpdate(prevProps, prevState) {
    const elem = document.getElementsByClassName("main-content")[0];
    if (this.state.collapsed === true && prevState.collapsed !== true) {
      elem.style.marginLeft = COLLAPSED_SIDEBAR_WIDTH;
    } else if (this.state.collapsed === false && prevState.collapsed === true) {
      elem.style.marginLeft = EXPANDED_SIDEBAR_WIDTH;
    }
    window.localStorage.setItem("sticky", this.state.sticky);
    if (
      this.props.workspaceInfo === API_CALL_STATE.DONE &&
      prevProps.workspaceInfo === API_CALL_STATE.IN_PROGRESS
    ) {
      this.setState({
        ...prevState,
        isHipaa: currentWorkspaceHipaa(),
      });
    }
  }

  render() {
    return (
      <Box
        onMouseLeave={() => {
          if (!this.state.sticky) {
            this.setState({ collapsed: true });
          }
        }}
        onMouseEnter={() => {
          this.setState({ collapsed: false });
        }}
        css={{
          backgroundColor: "$surfaceDark",
          borderRight: "1px solid $borderDefault",
          width: this.state.collapsed
            ? COLLAPSED_SIDEBAR_WIDTH
            : EXPANDED_SIDEBAR_WIDTH,
          transition: "width 0.3s ease",
        }}
        className="vertical-menu"
        id="left-vertical-menu"
      >
        <Flex
          align="center"
          css={{
            borderBottom: "1px solid $borderDefault",
            w: "100%",
            padding: this.state.collapsed ? "16.85px" : "16.85px $md",
            overflow: "clip",
          }}
        >
          <Link to="/">
            {this.state.collapsed ? (
              <img
                alt="100ms Logo"
                src={logo}
                style={{ height: "21px", width: "32px" }}
              />
            ) : (
              <Svg100MsLogoIcon height={20} width={75} />
            )}
          </Link>
        </Flex>
        <div data-simplebar className="h-100">
          {this.props.type !== "condensed" ? (
            <SimpleBar style={{ maxHeight: "100%" }}>
              <SidebarContent
                collapsed={this.state.collapsed}
                sticky={this.state.sticky}
                showIconOnlyMenuBar={this.checkForAppBuilder}
                resetLeftMenuBar={this.resetLeftMenuBar}
                removeMenuBar={this.removeMenuBar}
                publicTemplates={this.props.publicTemplates}
                fetchTemplateStatus={this.props.fetchTemplateStatus}
              />
            </SimpleBar>
          ) : (
            <SidebarContent
              publicTemplates={this.props.publicTemplates}
              fetchTemplateStatus={this.props.fetchTemplateStatus}
            />
          )}
        </div>
        <Flex
          direction="column"
          css={{ position: "fixed", left: "0", bottom: "0" }}
        >
          <HippaCompliantOption
            link=""
            text="HIPAA Compliant"
            icon=""
            activeOption=""
            collapsed={this.state.collapsed}
            btnId=""
            align="center"
            justify="center"
            isHipaa={this.state.isHipaa}
          />
          <Flex
            onClick={() => {
              let finalValue = false;
              if (this.state.sticky) {
                this.setState({ sticky: false, collapsed: true });
              } else {
                this.setState({ collapsed: false, sticky: true });
                finalValue = true;
              }
              AppAnalytics.track("btn.clicked", {
                btnId: "sidebar.sticky",
                componentId: "sidebar",
                value: finalValue ? "sticky.enabled" : "sticky.disabled",
              });
            }}
            css={{
              color: "$textMedEmp",
              w: this.state.collapsed
                ? COLLAPSED_SIDEBAR_WIDTH
                : EXPANDED_SIDEBAR_WIDTH,
              cursor: "pointer",
              gap: "$md",
              transition: "all 0.15s ease",
              borderTop: "1px solid $borderDefault",
              backgroundColor: "$surfaceDark",
              "&:hover": {
                color: "$textHighEmp",
              },
              p: "20px",
              pl: this.state.collapsed ? "20px" : "2rem",
            }}
          >
            <img
              alt="Collapse"
              className="hover:opacity-80"
              src={collapse}
              height={20}
              width={20}
              style={{
                transform: this.state.sticky ? "none" : "rotate(180deg)",
                transition: "all 0.15s ease",
              }}
            />
            {!this.state.collapsed ? (
              <Text
                variant="sm"
                css={{
                  color: "inherit",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {this.state.sticky ? "Hide" : "Lock"} Sidebar
              </Text>
            ) : (
              ""
            )}
          </Flex>
        </Flex>
      </Box>
    );
  }
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
    fetchTemplateStatus: state.roles.fetchTemplateStatus,
    all_templates: state.roles.all_templates,
    publicTemplates: state.createApp.publicTemplates,
    workspaceInfo: state.workspace.status.fetchWorkspaceInfo,
  };
};

export default connect(mapStatetoProps, {
  fetchTemplatesData,
  fetchPublicTemplates,
})(withRouter(withNamespaces()(Sidebar)));
