import { Redirect, Route } from "react-router-dom";
import toastr from "src/components/Common/toastr";
import FullscreenMode from "src/components/VerticalLayout/fullscreenMode";
import VercelIntegration from "../pages/Vercel";
import { WorkspaceInvite } from "../pages/Workspace";
import { currentUser, isAdmin } from "../utils";
//eslint-disable-next-line complexity
const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={
      //eslint-disable-next-line complexity
      props => {
        if (isAuthProtected) {
          if (!currentUser()) {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                  search: window.location.search,
                }}
              />
            );
          }

          if (rest.isFullScreen) {
            return (
              <FullscreenMode>
                <Component {...props} />
              </FullscreenMode>
            );
          }

          if (rest.path === "/admin" && currentUser() && !isAdmin()) {
            return (
              <Redirect
                to={{ pathname: "/dashboard", state: { from: props.location } }}
              />
            );
          }
          if (rest.path === "/roles") {
            return (
              <Redirect
                to={{ pathname: "/templates", state: { from: props.location } }}
              />
            );
          }

          if (
            isAdmin() &&
            !(
              currentUser().customer &&
              currentUser().customer.email &&
              currentUser().customer.customer_id
            ) &&
            rest.path !== "/admin"
          ) {
            toastr.info("Please select a customer.");
            return (
              <Redirect
                to={{ pathname: "/admin", state: { from: props.location } }}
              />
            );
          }
        }
        if (rest.path === "/accept-invite") {
          return <WorkspaceInvite />;
        }
        if (
          (rest.path === "/login" || rest.path === "admin/login") &&
          currentUser()
        ) {
          return (
            <Redirect
              to={{ pathname: "/dashboard", state: { from: props.location } }}
            />
          );
        }
        if (rest.path === "/roles") {
          return (
            <Redirect
              to={{ pathname: "/templates", state: { from: props.location } }}
            />
          );
        }

        if (rest.path === "/vercel-integration") {
          return <Component />;
        }

        if (rest.path === "/vercel-template-integration/cms-datocms") {
          return <VercelIntegration />;
        }

        if (rest.path === "/integration/configure") {
          return <Component />;
        }
        if (rest.path === "/templates/:policyId/prebuilt") {
          return <Component />;
        }
        if (rest.path.includes("/play-vod")) {
          return <Component />;
        }
        return (
          <Layout>
            <Component
              key={props.location.pathname + props.location.search}
              {...props}
            />
          </Layout>
        );
      }
    }
  />
);

export default AppRoute;
