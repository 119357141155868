/* eslint-disable complexity */
import React, { ComponentProps, FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "@100mslive/types-prebuilt";
import { isEmpty } from "lodash";
import { setHasUnsavedSettings, updatePlugins } from "src/actions/RolesActions";
import TemplateConfigCard from "src/components/Cards/TemplateConfigCard";
import StatusString from "src/components/Common/StatusString";
import { PrebuiltSidebarMultiSelect } from "src/components/Prebuilt/screens/components/PrebuiltSidebarMultiSelect";
import SwitchWithTitle from "src/pages/Developer/SwitchWithTitle";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { getRolesWithoutHLSViewerSettings } from "src/services/policy/roles";
import { RootState } from "src/store/reducers";
import {
  roleTemplatePolicy,
  whiteboardPermissionsType,
} from "src/types/policyTypes";
import { slugify } from "src/utils";
import { Flex, Text } from "@100mslive/react-ui";
import { INTERNAL_RECORDER_ROLE, isVisibleRole } from "../../helpers";

interface WhiteboardProps {
  invalidFields: Record<string, unknown>;
}

const Whiteboard: FC<ComponentProps<typeof Flex> & WhiteboardProps> = ({
  invalidFields,
}) => {
  const dispatch = useDispatch();
  const roleLayouts = useSelector(
    (state: RootState) => state.appLayouts.roleLayouts
  );
  const policy = useSelector((state: RootState) => state.roles.policyInfo);
  const isWhiteboardEnabled = !isEmpty(
    policy?.plugins?.whiteboard?.permissions
  );
  const visibleRoleObject = useMemo(() => {
    const tempRoleLayouts = Object.keys(roleLayouts).map(role => {
      if (!roleLayouts[role].role) {
        roleLayouts[role].role = role;
      }
      return { ...roleLayouts[role] } as Layout;
    });
    return getRolesWithoutHLSViewerSettings({
      roles: Object.values(policy?.roles) || ([] as roleTemplatePolicy[]),
      roleLayouts: tempRoleLayouts || ([] as Layout[]),
    });
  }, [policy, roleLayouts]);
  const visibleRoles = (visibleRoleObject?.roles || []).map(role => role?.name);
  const isWhiteboardRecordingEnabled =
    policy?.plugins?.whiteboard?.permissions?.reader?.includes(
      INTERNAL_RECORDER_ROLE
    );
  const toggleWhiteboard = useCallback(() => {
    const tempPolicy = { ...policy };
    if (!isWhiteboardEnabled) {
      const firstRole = visibleRoles?.[0] || "";
      const internalRecorderExists = Object.keys(tempPolicy.roles).some(
        r => r === INTERNAL_RECORDER_ROLE
      );
      if (firstRole) {
        tempPolicy.plugins = {
          ...tempPolicy.plugins,
          whiteboard: {
            permissions: {
              admin: [firstRole],
              writer: [firstRole],
              reader: internalRecorderExists ? [INTERNAL_RECORDER_ROLE] : [],
            },
          },
        };
      }
    } else {
      tempPolicy.plugins = { ...tempPolicy.plugins, whiteboard: {} };
    }
    dispatch(updatePlugins(tempPolicy?.plugins || {}));
    dispatch(setHasUnsavedSettings(true));
  }, [dispatch, isWhiteboardEnabled, policy, visibleRoles]);
  const toggleRecordingWhiteboard = useCallback(() => {
    const tempPolicy = { ...policy };

    if (!isWhiteboardRecordingEnabled) {
      tempPolicy.plugins = {
        ...tempPolicy.plugins,
        whiteboard: {
          ...tempPolicy?.plugins?.whiteboard,
          permissions: {
            ...tempPolicy?.plugins?.whiteboard?.permissions,
            reader: [
              ...(tempPolicy?.plugins?.whiteboard?.permissions?.reader || []),
              INTERNAL_RECORDER_ROLE,
            ],
          },
        },
      };
    } else {
      const arr = (
        tempPolicy?.plugins?.whiteboard?.permissions?.reader || []
      ).filter((r: string) => r !== INTERNAL_RECORDER_ROLE);
      tempPolicy.plugins = {
        ...tempPolicy.plugins,
        whiteboard: {
          ...tempPolicy?.plugins?.whiteboard,
          permissions: {
            ...tempPolicy?.plugins?.whiteboard?.permissions,
            reader: [...arr],
          },
        },
      };
    }
    dispatch(updatePlugins(tempPolicy?.plugins || {}));
    dispatch(setHasUnsavedSettings(true));
  }, [dispatch, isWhiteboardRecordingEnabled, policy]);
  const toggleRoleSelect = useCallback(
    (role: string, key: keyof whiteboardPermissionsType) => {
      const policyInfo = { ...policy };

      if (isEmpty(policyInfo?.plugins?.whiteboard?.permissions?.[key])) {
        policyInfo.plugins = {
          ...policyInfo?.plugins?.whiteboard,
          whiteboard: {
            ...policyInfo?.plugins?.whiteboard,
            permissions: {
              ...policyInfo?.plugins?.whiteboard?.permissions,
              [key]: [role],
            },
          },
        };
      } else {
        if (
          !policyInfo?.plugins?.whiteboard?.permissions?.[key]?.includes(role)
        ) {
          const arr = policyInfo?.plugins?.whiteboard?.permissions?.[key] || [];
          arr.push(role);
          policyInfo.plugins = {
            ...policyInfo?.plugins,
            whiteboard: {
              ...policyInfo?.plugins?.whiteboard,
              permissions: {
                ...policyInfo?.plugins?.whiteboard?.permissions,
                [key]: [...arr],
              },
            },
          };
        } else {
          const arr = (
            policyInfo?.plugins?.whiteboard?.permissions?.[key] || []
          ).filter((r: string) => r !== role);
          policyInfo.plugins = {
            ...policyInfo?.plugins,
            whiteboard: {
              ...policyInfo?.plugins?.whiteboard,
              permissions: {
                ...policyInfo?.plugins?.whiteboard?.permissions,
                [key]: [...arr],
              },
            },
          };
        }
      }
      dispatch(updatePlugins(policyInfo.plugins));

      dispatch(setHasUnsavedSettings(true));
    },
    [dispatch, policy]
  );

  return (
    <TemplateConfigCard
      leftComponent={
        <Text
          variant="body2"
          css={{ c: "$textDisabled", fontWeight: "$regular" }}
        >
          Enable Whiteboard and define its access configuration.&nbsp;
          <Text
            variant="sm"
            css={{ color: "$primaryLight", display: "inline" }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              style={{
                color: "inherit",
                cursor: "pointer",
                fontSize: "13px",
              }}
              href={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/features/whiteboard`}
            >
              Learn more
            </a>
          </Text>
        </Text>
      }
      text="Whiteboard"
      id={slugify("Whiteboard")}
      classNameForText="config-subheading"
      rightComponent={
        <Flex direction="column" css={{ minWidth: "100%" }}>
          <Flex
            direction="column"
            css={{
              pb: isWhiteboardEnabled ? "$10" : 0,
              minWidth: "100%",
              borderBottom: isWhiteboardEnabled
                ? "$space$px solid $borderDefault"
                : "",
            }}
          >
            <SettingsTitle
              key="whiteboardState"
              title="Enable whiteboard and configure its accessibility settings."
              text="Whiteboard"
            />

            <SwitchWithTitle
              hideTitle
              tooltipMessage="Enable whiteboard recording state"
              checkedValue={isWhiteboardEnabled}
              onCheckedChange={() => toggleWhiteboard()}
            />
          </Flex>
          {isWhiteboardEnabled ? (
            <>
              <Flex
                direction="column"
                css={{
                  py: "$10",
                  minWidth: "100%",
                  borderBottom: "$space$px solid $borderDefault",
                }}
              >
                <SettingsTitle
                  key="whiteboardRecordingState"
                  title="Enable this toggle if you want to record or live stream the whiteboard you are working on. If this is disabled, the whiteboard won’t be visible in room composite recording or the live stream"
                  text="Record or Live Stream the Whiteboard"
                />

                <SwitchWithTitle
                  hideTitle
                  tooltipMessage="Toggle whiteboard state"
                  checkedValue={isWhiteboardRecordingEnabled}
                  disabled={isEmpty(
                    policy.roles[
                      INTERNAL_RECORDER_ROLE as keyof typeof policy.roles
                    ]
                  )}
                  onCheckedChange={() => toggleRecordingWhiteboard()}
                />
                {isEmpty(
                  policy.roles[
                    INTERNAL_RECORDER_ROLE as keyof typeof policy.roles
                  ]
                ) ? (
                  <StatusString
                    type="warning"
                    content={
                      "Warning: can't enable if live streaming or recording is not enabled"
                    }
                  />
                ) : null}
              </Flex>
              <Flex
                direction="column"
                css={{
                  py: "$10",
                  minWidth: "100%",
                  borderBottom: "$space$px solid $borderDefault",
                }}
              >
                <SettingsTitle
                  key="whiteboardAdmin"
                  title="Select roles (at least 1) which will have the permission to launch or close the whiteboard. Users with these roles can view but cannot write on the whiteboard."
                  text="Launch, Close and View Whiteboard"
                />
                <PrebuiltSidebarMultiSelect
                  inputText="Select roles which can control whiteboard toggle"
                  onCheckedChange={role => {
                    toggleRoleSelect(role, "admin");
                  }}
                  optionsArray={visibleRoles || []}
                  isCheckedCondition={role => {
                    return (
                      policy?.plugins?.whiteboard?.permissions?.admin?.includes(
                        role
                      ) || false
                    );
                  }}
                  selectedOptions={(
                    policy?.plugins?.whiteboard?.permissions?.admin || []
                  ).filter((role: string) => isVisibleRole(role))}
                />

                <StatusString
                  content={(invalidFields?.whiteboardAdmin as string) || ""}
                />
              </Flex>
              <Flex
                direction="column"
                css={{
                  py: "$10",
                  minWidth: "100%",
                  borderBottom: "$space$px solid $borderDefault",
                }}
              >
                <SettingsTitle
                  key="whiteboardWriter"
                  title="Select roles (at least 1) which can draw and collaborate on the whiteboard. Users with these roles can write and view, but they cannot launch or close it."
                  text="Collaborate and View Whiteboard"
                />
                <PrebuiltSidebarMultiSelect
                  inputText="Select Roles"
                  onCheckedChange={role => {
                    toggleRoleSelect(role, "writer");
                  }}
                  optionsArray={visibleRoles || []}
                  isCheckedCondition={role => {
                    return (
                      policy?.plugins?.whiteboard?.permissions?.writer?.includes(
                        role
                      ) || false
                    );
                  }}
                  selectedOptions={(
                    policy?.plugins?.whiteboard?.permissions?.writer || []
                  ).filter(role => isVisibleRole(role))}
                />

                <StatusString
                  content={(invalidFields?.whiteboardWriter as string) || ""}
                />
              </Flex>
              <Flex
                direction="column"
                css={{
                  pt: "$10",
                  minWidth: "100%",
                }}
              >
                <SettingsTitle
                  key="whiteboardReader"
                  title="Select roles which can only view the whiteboard. They will not be able to launch, close or draw on it."
                  text="Only View Whiteboard"
                />
                <PrebuiltSidebarMultiSelect
                  inputText="Select Roles"
                  onCheckedChange={role => {
                    toggleRoleSelect(role, "reader");
                  }}
                  optionsArray={visibleRoles || []}
                  isCheckedCondition={role => {
                    return (
                      policy?.plugins?.whiteboard?.permissions?.reader?.includes(
                        role
                      ) || false
                    );
                  }}
                  selectedOptions={(
                    policy?.plugins?.whiteboard?.permissions?.reader || []
                  ).filter(role => isVisibleRole(role))}
                />

                <StatusString
                  content={(invalidFields?.whiteboardReader as string) || ""}
                />
              </Flex>
            </>
          ) : null}
        </Flex>
      }
    />
  );
};

export default Whiteboard;
