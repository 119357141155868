import React from "react";
import { Flex } from "@100mslive/react-ui";

function ResponsiveLayout({
  width,
  height,
  children,
}: {
  width: number | string;
  height: number | string;
  children: React.ReactNode;
}) {
  return (
    <Flex
      css={{
        w: width,
        h: height,
        border: "$space$px solid $borderDefault",
        r: "$0",
      }}
    >
      {children}
    </Flex>
  );
}

export default ResponsiveLayout;
