import { InfoIcon } from "@100mslive/react-icons";
import { Box, Flex, Switch, Text, Tooltip } from "@100mslive/react-ui";

const SwitchWithTitle = ({
  title = "",
  titleCSS = {},
  tooltipMessage,
  checkedValue,
  onCheckedChange,
  disabled = false,
  hideTitle = false,
}) => (
  <Box>
    <Flex align="center" css={{ color: "$textDisabled", gap: "$3" }}>
      {!hideTitle ? (
        <>
          <Text
            variant="xs"
            css={{
              color: "$textMedEmp",
              fontWeight: "$semiBold",
              m: "$0",
              ...titleCSS,
            }}
          >
            {title}
          </Text>
          <Tooltip title={tooltipMessage} side="right" align="center">
            <Flex>
              <InfoIcon height={14} width={14} />
            </Flex>
          </Tooltip>
        </>
      ) : null}
    </Flex>
    <Flex align="center" css={{ mt: "$6" }}>
      <Switch
        disabled={disabled}
        checked={checkedValue}
        onCheckedChange={value => onCheckedChange(value)}
      />
      <Text
        variant="xs"
        css={{
          color: "$textDisabled",
          marginLeft: "$md",
        }}
      >
        {checkedValue ? "Enabled" : "Disabled"}
      </Text>
    </Flex>
  </Box>
);

export default SwitchWithTitle;
