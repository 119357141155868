import {
  DefaultConferencingScreen_Elements,
  DefaultPreviewScreen_Elements,
  HLSLiveStreamingScreen_Elements,
  Layout,
  Screens,
} from "@100mslive/types-prebuilt";
import { OnStageExperience } from "@100mslive/types-prebuilt/elements/on_stage_experience";
import { VirtualBackground } from "@100mslive/types-prebuilt/elements/virtual_background";
import { omit } from "lodash";
import { RoleLayouts } from "src/types/prebuilt";

function getPrebuiltComponentDetails({
  roleLayouts,
  role,
  screen,
  element,
  setRoleLayouts,
  screenType = "default",
}: {
  roleLayouts: RoleLayouts;
  role: string;
  screen: keyof Screens;
  element: string;
  screenType: string;
  setRoleLayouts: ({ roleLayouts }: { roleLayouts: RoleLayouts }) => void;
}) {
  let data;
  let setData;
  let removeElement;
  const layout = roleLayouts[role];
  switch (screen) {
    case "preview":
      if (screenType === "default") {
        data =
          layout?.screens?.preview?.default?.elements?.[
            element as keyof DefaultPreviewScreen_Elements
          ];
        setData = (
          val: DefaultPreviewScreen_Elements[keyof DefaultPreviewScreen_Elements]
        ) => {
          const tempLayout: Layout = {
            ...roleLayouts[role],
            screens: {
              ...layout.screens,
              preview: {
                ...layout?.screens?.preview,
                default: {
                  ...layout?.screens?.preview?.default,
                  elements: {
                    ...(layout?.screens?.preview?.default
                      ?.elements as DefaultPreviewScreen_Elements),
                    [element]: val,
                  },
                },
              },
            },
          };
          if (element === "virtual_background") {
            tempLayout["screens"] = {
              ...tempLayout.screens,
              conferencing: {
                ...tempLayout?.screens?.conferencing,
                default: {
                  ...tempLayout?.screens?.conferencing?.default,
                  elements: {
                    ...(tempLayout?.screens?.conferencing?.default
                      ?.elements as DefaultConferencingScreen_Elements),
                    virtual_background: val as VirtualBackground,
                  },
                },
              },
            };
          }
          setRoleLayouts({
            roleLayouts: {
              ...roleLayouts,
              [role]: { ...tempLayout },
            },
          });
        };
        removeElement = () => {
          const tempLayout: Layout = omit(roleLayouts[role], [
            `screens.preview.default.elements.${element}`,
          ]);
          if (element === "virtual_background") {
            tempLayout["screens"] = omit(tempLayout["screens"], [
              `conferencing.default.elements.virtual_background`,
            ]);
          }
          setRoleLayouts({
            roleLayouts: {
              ...roleLayouts,
              [role]: { ...tempLayout },
            },
          });
        };
      } else {
        data = "";
      }
      break;
    case "conferencing":
      if (screenType === "default") {
        data =
          layout?.screens?.conferencing?.default?.elements?.[
            element as keyof DefaultConferencingScreen_Elements
          ];
        setData = (
          val: DefaultConferencingScreen_Elements[keyof DefaultConferencingScreen_Elements]
        ) => {
          const tempLayout: Layout = {
            ...roleLayouts[role],
            screens: {
              ...layout.screens,
              conferencing: {
                ...layout?.screens?.conferencing,
                default: {
                  ...layout?.screens?.conferencing?.default,
                  elements: {
                    ...(layout?.screens?.conferencing?.default
                      ?.elements as DefaultConferencingScreen_Elements),
                    [element]: val,
                  },
                },
              },
            },
          };
          if (element === "virtual_background") {
            tempLayout["screens"] = {
              ...tempLayout.screens,
              preview: {
                ...tempLayout?.screens?.preview,
                default: {
                  ...tempLayout?.screens?.preview?.default,
                  elements: {
                    ...(tempLayout?.screens?.preview?.default
                      ?.elements as DefaultPreviewScreen_Elements),
                    virtual_background: val as VirtualBackground,
                  },
                },
              },
            };
          }
          if (element === "hand_raise") {
            tempLayout["screens"] = {
              ...tempLayout.screens,
              conferencing: {
                ...tempLayout?.screens?.conferencing,
                default: {
                  ...tempLayout?.screens?.conferencing?.default,
                  elements: {
                    ...(tempLayout?.screens?.conferencing?.default
                      ?.elements as DefaultConferencingScreen_Elements),
                    on_stage_exp: {} as OnStageExperience,
                  },
                },
              },
            };
          }
          setRoleLayouts({
            roleLayouts: {
              ...roleLayouts,
              [role]: { ...tempLayout },
            },
          });
        };
        removeElement = () => {
          const tempLayout: Layout = omit(roleLayouts[role], [
            `screens.conferencing.default.elements.${element}`,
          ]);
          if (element === "virtual_background") {
            tempLayout["screens"] = omit(tempLayout["screens"], [
              `preview.default.elements.virtual_background`,
            ]);
          }
          if (element === "hand_raise") {
            tempLayout["screens"] = omit(tempLayout["screens"], [
              `screens.conferencing.default.elements.on_stage_exp`,
            ]);
          }
          setRoleLayouts({
            roleLayouts: {
              ...roleLayouts,
              [role]: { ...tempLayout },
            },
          });
        };
      } else {
        data =
          layout?.screens?.conferencing?.hls_live_streaming?.elements?.[
            element as keyof HLSLiveStreamingScreen_Elements
          ];
        setData = (
          val: HLSLiveStreamingScreen_Elements[keyof HLSLiveStreamingScreen_Elements]
        ) => {
          const tempLayout: Layout = {
            ...roleLayouts[role],
            screens: {
              ...layout.screens,
              conferencing: {
                ...layout?.screens?.conferencing,
                hls_live_streaming: {
                  ...layout?.screens?.conferencing?.hls_live_streaming,
                  elements: {
                    ...(layout?.screens?.conferencing?.hls_live_streaming
                      ?.elements as HLSLiveStreamingScreen_Elements),
                    [element]: val,
                  },
                },
              },
            },
          };
          setRoleLayouts({
            roleLayouts: {
              ...roleLayouts,
              [role]: { ...tempLayout },
            },
          });
        };
        removeElement = () => {
          const tempLayout: Layout = omit(roleLayouts[role], [
            `screens.conferencing.hls_live_streaming.elements.${element}`,
          ]);
          setRoleLayouts({
            roleLayouts: {
              ...roleLayouts,
              [role]: { ...tempLayout },
            },
          });
        };
      }
      break;
    default:
      break;
  }

  return { data, setData, removeElement };
}

export default getPrebuiltComponentDetails;
