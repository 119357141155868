import React, { ComponentProps, FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { patchRoleInStore, setHasUnsavedRoles } from "src/actions/RolesActions";
import TemplateConfigCard from "src/components/Cards/TemplateConfigCard";
import StatusString from "src/components/Common/StatusString";
import { MaxPeerCount } from "src/constants";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { roleTemplatePolicy, roleType } from "src/types/policyTypes";
import { ChevronDownIcon } from "@100mslive/react-icons";
import { Dropdown, Flex, Input, Switch, Text } from "@100mslive/react-ui";
import { permissionsToggleFieldsData } from "./permissionsconfig";
const Permissions: FC<
  ComponentProps<typeof Flex> & {
    setPermissions: (key: string, value: unknown) => void;
    permissions: roleTemplatePolicy["permissions"];
    roles: Record<string, roleType>;
    roleName: string;
  }
> = ({ setPermissions, permissions, roles, roleName }) => {
  const [noOfPeersOpen, setNoOfPeersOpen] = useState<boolean>(false);
  const [maxPeersSetting, setMaxPeersSetting] = useState(
    MaxPeerCount[
      roles?.[roleName]?.maxPeerCount as keyof typeof MaxPeerCount
    ] || MaxPeerCount[100]
  );
  useEffect(() => {
    if (roles && roleName) {
      setMaxPeersSetting(
        MaxPeerCount[
          roles?.[roleName]?.maxPeerCount as keyof typeof MaxPeerCount
        ] || MaxPeerCount[100]
      );
    }
  }, [roleName, roles]);
  const dispatch = useDispatch();
  const maxPeersRef = useRef<HTMLInputElement>(null);
  return (
    <TemplateConfigCard
      id="Permissions"
      classNameForText="config-subheading"
      text="Permissions"
      subtitle="A list of additional privileges that this role will have."
      rightComponent={
        <Flex direction="column" css={{ minWidth: "100%" }}>
          {permissionsToggleFieldsData.map(
            (
              fields: {
                path: string;
                tooltip: string;
                title: string;
                error?: string;
              },
              index: number
            ) => {
              const path =
                fields.path as keyof roleTemplatePolicy["permissions"];
              return (
                <Flex
                  key={fields.title}
                  direction="column"
                  css={{
                    mt: index === 0 ? "0" : "$10",
                    pb: "$10",
                    minWidth: "100%",
                    borderBottom: "$space$px solid $borderDefault",
                    position: "relative",
                  }}
                >
                  <SettingsTitle
                    key={index.toString().concat(fields?.tooltip as string)}
                    title={fields.tooltip}
                    text={fields.title}
                  />
                  <Flex css={{ mt: "$6" }} align="center">
                    <Switch
                      onCheckedChange={e => {
                        setPermissions(fields?.path, e);
                      }}
                      checked={Boolean(
                        (permissions as roleType["permissions"])?.[path]
                      )}
                      disabled={false}
                    />
                    <Text
                      variant="caption"
                      css={{ c: "$textDisabled", ml: "$8" }}
                    >
                      {permissions?.[path] ? "Enabled" : "Disabled"}
                    </Text>
                  </Flex>
                  {!(permissions as roleType["permissions"])?.[path] &&
                  path === "sendRoomState" ? (
                    <Text variant="caption" css={{ c: "$error" }}>
                      {fields.error}
                    </Text>
                  ) : null}
                </Flex>
              );
            }
          )}

          <Flex
            direction="column"
            css={{
              mt: "$10",
              minWidth: "100%",
              borderBottom: "$space$px solid $borderDefault",
            }}
            ref={maxPeersRef}
          >
            <SettingsTitle
              key="MaxPeers"
              title="Configure the maximum number of peers allowed to join a room with this role"
              text="Maximum number of peers in a room"
            />
            <Flex
              justify="between"
              align="center"
              css={{ mt: "$4", minWidth: "100%" }}
            >
              <Dropdown.Root
                modal={false}
                open={noOfPeersOpen}
                onOpenChange={setNoOfPeersOpen}
                css={{ w: "100%" }}
              >
                <Dropdown.Trigger css={{ w: "100%" }}>
                  <Flex
                    css={{
                      p: "$5 $6",
                      bg: "$surfaceLight",
                      r: "$0",
                      border: "solid $space$px $borderLight",
                    }}
                    justify="between"
                  >
                    <Text variant="body2">{maxPeersSetting}</Text>

                    <ChevronDownIcon
                      width={20}
                      height={20}
                      style={{
                        transition: "transform 0.3s ease",
                        transform: noOfPeersOpen
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                  </Flex>
                </Dropdown.Trigger>
                <Dropdown.Portal>
                  <Dropdown.Content
                    align="end"
                    sideOffset={10}
                    css={{
                      height: "auto",
                      maxHeight: "$96",
                      overflowY: "hidden",
                      textAlign: "center",
                      w: `${
                        maxPeersRef?.current
                          ? Math.floor(maxPeersRef?.current?.offsetWidth)
                          : 0
                      }px`,
                    }}
                  >
                    {Object.keys(MaxPeerCount).map(value => {
                      return (
                        <Dropdown.Item
                          key={value}
                          css={{ p: "$4 $8" }}
                          onClick={() => {
                            const role = roles?.[roleName];
                            role.maxPeerCount = parseInt(value);
                            setMaxPeersSetting(
                              MaxPeerCount[
                                parseInt(value) as keyof typeof MaxPeerCount
                              ] || MaxPeerCount[100]
                            );
                            dispatch(patchRoleInStore(role));
                            dispatch(setHasUnsavedRoles(true));
                          }}
                        >
                          <Text variant="caption">
                            {MaxPeerCount[value as keyof typeof MaxPeerCount]}
                          </Text>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Content>
                </Dropdown.Portal>
              </Dropdown.Root>
            </Flex>
            {maxPeersSetting === "Custom" ? (
              <>
                <Input
                  type="number"
                  css={{ r: "$0", mt: "$4", width: "100%" }}
                  onChange={e => {
                    dispatch(
                      patchRoleInStore({
                        ...roles?.[roleName],
                        maxPeerCount: parseInt(e.target.value),
                      })
                    );
                    dispatch(setHasUnsavedRoles(true));
                  }}
                  value={parseInt(roles?.[roleName]?.maxPeerCount.toString())}
                />
                <StatusString
                  content={
                    roles?.[roleName]?.maxPeerCount > 0
                      ? ""
                      : "Please provide a positive value. Otherwise, no restrictions will be imposed on the role."
                  }
                />
              </>
            ) : null}
          </Flex>
        </Flex>
      }
    />
  );
};

export default Permissions;
