import React from "react";
import { Flex, Text } from "@100mslive/roomkit-react";
import { MainDialog } from "src/components";
import LargeIcon from "src/components/Common/Icons/LargeIcon";
import { AlertTriangleIcon } from "@100mslive/react-icons";

type Props = {
  largeRoom: boolean;
  trigger: boolean;
  setTrigger: (bool: boolean) => void;
  setLargeRoom: (bool: boolean) => void;
};

function LargeRoomDialog({
  trigger,
  setTrigger,
  largeRoom,
  setLargeRoom,
}: Props) {
  return (
    <MainDialog
      width="540px"
      title={largeRoom ? "Switch to a Normal Room?" : "Switch to a Large Room?"}
      variant="primary"
      subtitle={
        largeRoom ? (
          <></>
        ) : (
          <Text
            variant="body2"
            css={{ fontWeight: "$regular", c: "$textMedEmp" }}
          >
            Once switched, this room can support upto 20,000 viewers.{" "}
            <Text as="span" variant="sm" css={{ color: "$primaryLight" }}>
              <a
                target="_blank"
                rel="noreferrer"
                style={{
                  cursor: "pointer",
                  fontSize: "inherit",
                }}
                href={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/how-to-guides/create-large-rooms`}
              >
                Learn more.
              </a>
            </Text>
          </Text>
        )
      }
      content={
        largeRoom ? (
          <Flex
            css={{
              p: "$8",
              mt: "$4",
              border: "$space$px solid $warning",
              r: "$1",
            }}
            direction="column"
          >
            <Flex css={{ c: "$warning" }}>
              <LargeIcon>
                <AlertTriangleIcon />
              </LargeIcon>
            </Flex>
            <Text variant="body2" css={{ mt: "$4", mb: "$8" }}>
              Once switched, this room can only support upto 2,500 viewers.
            </Text>
            {/* No relevant documentation available yet */}
            {/* <a href="/" target="__blank" rel="noopener">
              <Text
                variant="sub2"
                css={{
                  c: "$primaryLight",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                Learn More &#8594;
              </Text>
            </a> */}
          </Flex>
        ) : (
          <></>
        )
      }
      open={trigger}
      buttonText="Confirm"
      mainFunction={() => {
        setLargeRoom(!largeRoom);
        setTrigger(false);
      }}
      onOpenChange={() => setTrigger(!trigger)}
    />
  );
}

export default LargeRoomDialog;
