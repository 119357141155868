import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { isEmpty, isEqual } from "lodash";
import { patchRoleInStore, setHasUnsavedRoles } from "src/actions/RolesActions";
import ConfigSingleSelect from "src/components/Common/ConfigSingleSelect";
import {
  DEFAULT_FRAMERATE,
  ScreenShareQuality,
  VIDEO_QUALITY_FOR_POLICY,
  VideoQuality,
} from "src/constants";
import { AppAnalytics } from "src/helpers";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import {
  getSimulcastConfigForVideoQuality,
  getSimulcastLayersForConfig,
} from "src/store/roleTemplate";
import {
  calculateAspectRatio,
  calculateLayerDimensions,
  isCustomAspectRatio,
  isCustomVideoQuality,
} from "src/utils";
import {
  ChevronDownIcon,
  MicOnIcon,
  ShareScreenIcon,
  VideoOnIcon,
} from "@100mslive/react-icons";
import { Box, Dropdown, Flex, Text } from "@100mslive/react-ui";
import TemplateConfigCard from "../../components/Cards/TemplateConfigCard";
import AspectRatioConfig from "../../components/Common/AspectRatioConfig";
import TextAndSubtext from "../../components/Common/TextAndSubtext";
import { TileWrapper } from "../../components/Common/Tile";
import SimulcastConfig from "../../components/RoleConfig/SimulcastConfig";
import { policyType, roleType } from "../../types/policyTypes";

interface Props {
  roleName: string;
  updateRole(roleName: string, key: string, value: unknown[]): unknown;
  policy: policyType;
  isOnboarding: boolean;
  templateConfig: boolean;
}

const rolePublishParams = [
  { text: "Audio", Icon: <MicOnIcon width={20} height={20} />, key: "audio" },
  {
    text: "Video",
    Icon: <VideoOnIcon width={20} height={20} />,
    key: "video",
  },
  {
    text: "Screen",
    Icon: <ShareScreenIcon width={20} height={20} />,
    key: "screen",
  },
];

// eslint-disable-next-line complexity
const PublishStrategy: React.FC<Props> = ({
  roleName,
  updateRole,
  policy,
  isOnboarding,
  templateConfig,
}) => {
  const dispatch = useDispatch();
  const cardRef = useRef<HTMLDivElement>(null);
  const screenRef = useRef<HTMLDivElement>(null);
  const isActiveParams = (key: string, role: roleType) =>
    role?.publishParams?.allowed?.findIndex((param: string) => param === key) >=
    0
      ? true
      : false;
  const [showVideoSettings, setShowVideoSettings] = useState(
    isActiveParams("video", policy?.roles?.[roleName] as roleType)
  );
  const [showScreenShareSettings, setShowScreenShareSettings] = useState(
    isActiveParams("screen", policy?.roles?.[roleName] as roleType)
  );
  const simulcastEnabled = !(
    isEmpty(
      (policy?.roles?.[roleName] as roleType)?.publishParams?.simulcast?.video
    ) &&
    isEmpty(
      (policy?.roles?.[roleName] as roleType)?.publishParams?.simulcast?.screen
    )
  );
  const [screenShareQualityOpen, setScreenShareQualityOpen] =
    React.useState(false);
  const videoSettings = (policy?.roles?.[roleName] as roleType)?.publishParams
    ?.video;
  const screenShareSettings = (policy?.roles?.[roleName] as roleType)
    ?.publishParams?.screen;

  //write a function to get screenshare config key which matches the present config
  //and then use that key to get the screenshare config
  const getScreenShareKey = useCallback(() => {
    const key = Object.keys(ScreenShareQuality).find(
      key =>
        isEqual(
          ScreenShareQuality[key as keyof typeof ScreenShareQuality]?.width,
          screenShareSettings?.width
        ) &&
        isEqual(
          ScreenShareQuality[key as keyof typeof ScreenShareQuality]?.height,
          screenShareSettings?.height
        )
    );
    if (key === undefined) {
      return "Custom";
    }
    return parseInt(key?.replaceAll("_", "")?.replaceAll("p", ""));
  }, [screenShareSettings]);

  const screenShareKey = getScreenShareKey();
  const aspectRatio = calculateAspectRatio({
    width: videoSettings?.width,
    height: videoSettings?.height,
  });
  const handleVideoQualityChange = (quality: number) => {
    let res = { width: 0, height: 0 };
    let config;
    const prevCodec = videoSettings.codec;

    const role: roleType = policy?.roles?.[roleName] as roleType;
    if (isCustomAspectRatio(aspectRatio)) {
      config =
        VIDEO_QUALITY_FOR_POLICY[
          quality as keyof typeof VIDEO_QUALITY_FOR_POLICY
        ]["4:3"];

      res = {
        ...calculateLayerDimensions({
          aspectRatio: "4:3",
          layerRes: quality,
        }),
      };
    } else {
      config =
        VideoQuality[`_${quality}p` as unknown as keyof typeof VideoQuality][
          aspectRatio as unknown as keyof (typeof VideoQuality)["_720p"]
        ];

      const simulcastConfig = getSimulcastConfigForVideoQuality({
        aspectRatio: aspectRatio,
        videoQuality: `_${quality}p`,
      });
      const { video = {}, screen = {} } = simulcastConfig;
      if (video.layers.length > 1) {
        role.publishParams.simulcast = {
          video,
          screen,
        };
      } else {
        role.publishParams.simulcast = {
          video: {},
          screen: {},
        };
      }
    }

    role.publishParams.video = {
      ...res,
      ...config,
      frameRate: DEFAULT_FRAMERATE,
      codec: prevCodec,
    };
    dispatch(patchRoleInStore(role));

    dispatch(setHasUnsavedRoles(true));
  };
  const handleAspectRatioChange = (aspectRatio: string) => {
    let { width, height } = videoSettings;

    const quality = Math.min(width, height) as number;

    if (Object.keys(VideoQuality).includes(`_${quality}p`)) {
      const config =
        VideoQuality[`_${quality}p` as unknown as keyof typeof VideoQuality][
          aspectRatio as unknown as keyof (typeof VideoQuality)["_720p"]
        ];
      width = config.width;
      height = config.height;
    } else {
      const config = calculateLayerDimensions({
        aspectRatio: aspectRatio,
        layerRes: quality,
      });
      width = config.width;
      height = config.height;
    }

    const isCustomVideo = isCustomVideoQuality({
      width: width,
      height: height,
    });
    const prevCodec = videoSettings.codec;
    let videoConfig = {
      width: 0,
      height: 0,
      frameRate: DEFAULT_FRAMERATE,
      codec: prevCodec,
      bitRate: 0,
    };
    // pseudo code
    // 1. check if custom video quality or not
    // 2. if yes then set the width and height as it is adjusting to change of aspect ratio
    // 3 . if no then set the width and height as per the quality and aspect ratio from the video quality config
    // 4. if simulcastEnabled and custom video quality dont change the simulcast box
    // 5. if simulcastEnabled and not custom video quality then change the simulcast box

    const role = policy?.roles?.[roleName] as roleType;

    if (isCustomVideo) {
      videoConfig = {
        ...videoSettings,
        width: width,
        height: height,
      };
    } else {
      videoConfig = {
        ...videoSettings,
        ...VideoQuality[`_${quality}p` as unknown as keyof typeof VideoQuality][
          aspectRatio as unknown as keyof (typeof VideoQuality)["_720p"]
        ],
      };
      if (simulcastEnabled) {
        const simulcastConfig = getSimulcastConfigForVideoQuality({
          videoQuality: `_${quality}p`,
          aspectRatio: aspectRatio,
        });
        const simulcastLayers = getSimulcastLayersForConfig({
          layers: simulcastConfig?.video?.layers,
          videoQuality: `_${quality}p`,
          aspectRatio: aspectRatio,
        });
        const selectedSimulcastVideoQualities = getSimulcastLayersForConfig({
          layers: (policy?.roles?.[roleName] as roleType)?.publishParams
            ?.simulcast?.video?.layers,
          videoQuality: `_${quality}p`,
          aspectRatio: aspectRatio,
        });
        const layers = selectedSimulcastVideoQualities.simulcastQualities
          .map(quality => simulcastLayers.qualityLayerMap[quality])
          .filter(layer => !!layer);
        role.publishParams.simulcast.video.layers = layers;
      }
    }

    const newVideoSettings: (typeof VideoQuality)["_720p"]["4:3"] = {
      ...videoConfig,
    };
    role.publishParams.video = newVideoSettings;

    dispatch(patchRoleInStore(role));
    dispatch(setHasUnsavedRoles(true));
  };
  const handleScreenshareQualityChange = (quality: string) => {
    const role = policy?.roles?.[roleName] as roleType;
    const prevCodec = (policy?.roles?.[roleName] as roleType)?.publishParams
      ?.screen?.codec;
    const config =
      ScreenShareQuality[`_${quality}p` as keyof typeof ScreenShareQuality];
    role.publishParams.screen = config as roleType["publishParams"]["screen"];
    role.publishParams.screen.codec = prevCodec;
    dispatch(patchRoleInStore(role));
    dispatch(setHasUnsavedRoles(true));
  };

  useEffect(() => {
    if (roleName) {
      setShowScreenShareSettings(
        isActiveParams("screen", policy?.roles?.[roleName] as roleType)
      );
      setShowVideoSettings(
        isActiveParams("video", policy?.roles?.[roleName] as roleType)
      );
    }
  }, [policy?.roles, roleName]);

  return (
    <Component
      cardRef={cardRef}
      templateConfig={templateConfig}
      isOnboarding={isOnboarding}
    >
      {!templateConfig ? (
        <Text variant="caption" css={{ c: "$textMedEmp", mt: "$10", mb: "$6" }}>
          Role can share
        </Text>
      ) : (
        <SettingsTitle
          title="Select whether the role will be able to publish the following tracks or not"
          hideTooltip={!templateConfig}
          text="Role can share"
          css={{ mb: "$4" }}
        />
      )}
      <Flex
        className="gap-x-4"
        css={{
          display: "grid",
          w: "100%",

          pb:
            templateConfig &&
            (policy?.roles?.[roleName] as roleType)?.publishParams?.allowed
              ?.length > 0
              ? "$10"
              : "0",
          borderBottom:
            templateConfig &&
            (policy?.roles?.[roleName] as roleType)?.publishParams?.allowed
              ?.length > 0
              ? "$space$px solid $borderDefault"
              : "none",
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        }}
      >
        {rolePublishParams.map(params => {
          const selected = isActiveParams(
            params.key,
            policy?.roles?.[roleName] as roleType
          );
          // toggle video and screen settings if the role has the same settings

          return (
            <TileWrapper
              isActive={selected}
              css={{
                p: "$6",
                r: "$0",
                cursor: "pointer",
              }}
              justify="center"
              align="center"
              key={params.key}
              onClick={() => {
                if (isOnboarding) {
                  AppAnalytics.track("btn.clicked", {
                    btnId: "pub.clicked",
                    componentId: "guided.onboarding.customize",
                    value: params.key,
                  });
                }
                if (selected) {
                  const allowed = (
                    policy?.roles?.[roleName] as roleType
                  )?.publishParams?.allowed.filter(
                    // eslint-disable-next-line
                    (param: any) => param !== params.key
                  );

                  if (params.key === "screen") {
                    setShowScreenShareSettings(false);
                  }
                  if (params.key === "video") {
                    setShowVideoSettings(false);
                  }

                  updateRole(roleName, "publishParams.allowed", allowed);
                } else {
                  const allowed = (policy?.roles?.[roleName] as roleType)
                    ?.publishParams?.allowed;
                  allowed.push(params.key);
                  if (params.key === "screen") {
                    setShowScreenShareSettings(true);
                  }
                  if (params.key === "video") {
                    setShowVideoSettings(true);
                  }
                  updateRole(roleName, "publishParams.allowed", allowed);
                }
              }}
            >
              <Box css={{ mr: "$4" }}>{params.Icon}</Box>
              <Text variant="body2">{params.text}</Text>
            </TileWrapper>
          );
        })}
      </Flex>
      {templateConfig ? (
        <>
          {showVideoSettings ? (
            <>
              <Flex
                direction="column"
                css={{
                  mt: "$10",
                  pb: "$10",
                  minWidth: "100%",
                  borderBottom: "$space$px solid $borderDefault",
                }}
              >
                <SettingsTitle
                  key="videoQualitySetting"
                  text="Video Quality"
                  title="This setting determines the video publishing quality of the role. In rooms with more than 5 participants, you might experience enhanced performance selecting 360p or lower."
                />
                <Flex
                  justify="between"
                  align="center"
                  css={{ mt: "$4", gap: "$4" }}
                >
                  <ConfigSingleSelect
                    rootCSS={{ width: "50%" }}
                    triggerCSS={{ width: "min(100%,232px)" }}
                    inputText={
                      !isCustomVideoQuality(videoSettings)
                        ? `${Math.min(
                            videoSettings?.width,
                            videoSettings?.height
                          )}p`
                        : "Custom"
                    }
                    contentCSS={{
                      textAlign: "center",
                    }}
                    content={
                      <>
                        {Object.keys(VIDEO_QUALITY_FOR_POLICY)
                          .reverse()
                          .map(quality => (
                            <Dropdown.Item
                              key={quality}
                              css={{ p: "$4 $8" }}
                              onClick={() => {
                                handleVideoQualityChange(parseInt(quality));
                              }}
                            >
                              <Text variant="caption">{`${quality}p`}</Text>
                            </Dropdown.Item>
                          ))}
                      </>
                    }
                  />
                  <Text
                    variant="body2"
                    css={{ w: "max-content", c: "$textDisabled" }}
                  >
                    {`${videoSettings?.bitRate}kbps`} &bull;{" "}
                    {`${videoSettings?.frameRate} FPS`} &bull;{" "}
                    {`${videoSettings?.codec?.toUpperCase()} CODEC`}
                  </Text>
                </Flex>
              </Flex>
              <Flex
                direction="column"
                css={{
                  mt: "$10",
                  pb: "$10",
                  minWidth: "100%",
                  borderBottom: "$space$px solid $borderDefault",
                }}
              >
                <SettingsTitle
                  key="videoAspectRatio"
                  text="Video Aspect Ratio"
                  title="Aspect ratio of the video published. Only applies for camera feed, does not apply for screenshare."
                />
                <AspectRatioConfig
                  aspectRatio={calculateAspectRatio({
                    width: (policy?.roles?.[roleName] as roleType)
                      ?.publishParams?.video?.width,
                    height: (policy?.roles?.[roleName] as roleType)
                      ?.publishParams?.video?.height,
                  })}
                  onAspectRatioChange={(aspectRatio: string) =>
                    handleAspectRatioChange(aspectRatio)
                  }
                />
              </Flex>
              <SimulcastConfig policy={policy} roleName={roleName} />
            </>
          ) : null}
          {showScreenShareSettings ? (
            <>
              <Flex
                direction="column"
                css={{
                  mt: "$10",
                  pb: "$10",
                  minWidth: "100%",
                }}
              >
                <SettingsTitle
                  key="screenShareQuality"
                  text="Screen Share Quality"
                  title="Quality of the screen to be shared by the role. Currently, 2 video qualities 1080p and 720p are predefined and the user can select one of these values. This option will be visible only if the Can share screen is enabled."
                />
                <Flex justify="between" align="center" css={{ mt: "$4" }}>
                  <Dropdown.Root
                    modal={false}
                    open={screenShareQualityOpen}
                    onOpenChange={setScreenShareQualityOpen}
                  >
                    <Dropdown.Trigger css={{ w: "min(100%,232px)" }}>
                      <Flex
                        ref={screenRef}
                        css={{
                          p: "$5 $6",
                          bg: "$surfaceLight",
                          r: "$0",
                          border: "solid $space$px $borderLight",
                        }}
                        justify="between"
                      >
                        <Text variant="body2">
                          {typeof screenShareKey === "number"
                            ? `${screenShareKey}p`
                            : screenShareKey}
                        </Text>

                        <ChevronDownIcon
                          width={20}
                          height={20}
                          style={{
                            transition: "transform 0.3s ease",
                            transform: screenShareQualityOpen
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          }}
                        />
                      </Flex>
                    </Dropdown.Trigger>

                    <Dropdown.Content
                      sideOffset={10}
                      align="end"
                      css={{
                        w: `${
                          screenRef?.current
                            ? Math.floor(screenRef?.current?.offsetWidth / 1)
                            : 0
                        }px`,
                        height: "auto",
                        maxHeight: "$96",
                        overflowY: "hidden",
                        textAlign: "center",
                      }}
                    >
                      {Object.keys(ScreenShareQuality).map(quality => {
                        return (
                          <Dropdown.Item
                            key={quality}
                            css={{ p: "$4 $8" }}
                            onClick={() => {
                              const screenShareQuality = quality
                                .replaceAll("p", "")
                                .replaceAll("_", "");
                              handleScreenshareQualityChange(
                                screenShareQuality
                              );
                            }}
                          >
                            <Text variant="caption">
                              {quality.replaceAll("_", "")}
                            </Text>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Content>
                  </Dropdown.Root>
                  <Text
                    variant="body2"
                    css={{ w: "max-content", c: "$textDisabled" }}
                  >
                    {screenShareSettings?.bitRate
                      ? `${screenShareSettings?.bitRate}kbps `
                      : "Max Bitrate "}
                    &bull; {`${screenShareSettings?.frameRate} FPS`} &bull;{" "}
                    {`${screenShareSettings?.codec?.toUpperCase()} CODEC`}
                  </Text>
                </Flex>
              </Flex>
            </>
          ) : null}
        </>
      ) : null}
    </Component>
  );
};

export default PublishStrategy;

export const Component = ({
  templateConfig,
  children,
  isOnboarding,
  cardRef,
}: {
  templateConfig: boolean;
  children: ReactNode[] | ReactNode | undefined;
  isOnboarding: boolean;
  cardRef?: React.RefObject<HTMLDivElement>;
}) => {
  if (!templateConfig) {
    return (
      <Flex
        direction="column"
        css={{ minWidth: "100%", mt: "$12" }}
        ref={cardRef}
        id="PublishStrategies"
      >
        <TextAndSubtext
          text="Publish Strategy"
          classNameForText="config-subheading"
          textVariant={isOnboarding ? "md" : "h6"}
          subText="Used to determine the permissions for publishing tracks and their quality for this role."
          subTextVariant="sm"
          flexGap="$4"
        />
        {children}
      </Flex>
    );
  }
  return (
    <TemplateConfigCard
      id="PublishStrategies"
      text="Publish Strategy"
      classNameForText="config-subheading"
      subtitle=" Used to determine the permissions for publishing tracks and their quality for this role."
      rightComponent={children as unknown as JSX.Element}
      cardRef={cardRef}
    />
  );
};
