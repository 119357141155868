import {
  ASSET_STATUS,
  ASSET_TYPES,
  STREAM_TYPES,
  TRACK_TYPES,
} from "src/constants";
import { recordingAsset, resolution } from "../types";

const sortOrder = [
  ASSET_TYPES.ROOM_COMPOSITE,
  ASSET_TYPES.ROOM_COMPOSITE_LEGACY,
  ASSET_TYPES.CHAT,
  ASSET_TYPES.SUMMARY,
  ASSET_TYPES.TRANSCRIPT,
];

export const customComparatorForRecordingMediaTypes = (
  a: { type: string },
  b: { type: string }
) => {
  const indexA = sortOrder.indexOf(a?.type);
  const indexB = sortOrder.indexOf(b?.type);

  if (indexA === -1 && indexB === -1) {
    return 0; // Preserve the original order if both elements are not in the sortOrder
  } else if (indexA === -1) {
    return 1; // Move elements not in the sortOrder to the end of the array
  } else if (indexB === -1) {
    return -1; // Move elements not in the sortOrder to the end of the array
  } else {
    return indexA - indexB; // Sort based on the index in the sortOrder array
  }
};

// eslint-disable-next-line complexity
export const getAssetMediaType = ({
  asset,
}: {
  asset: recordingAsset;
}): string => {
  const assetType = asset?.type || "";

  if (!assetType) {
    return "";
  }

  // titlecase the asset type (stream -> Stream, track -> Track)
  let mediaType = "";
  switch (assetType) {
    case ASSET_TYPES.CHAT:
      mediaType = "Chat";
      break;
    case ASSET_TYPES.ROOM_COMPOSITE:
      mediaType = "Room Composite";
      break;
    case ASSET_TYPES.ROOM_COMPOSITE_LEGACY:
      mediaType = "Room Composite Legacy";
      break;
    case ASSET_TYPES.STREAM:
      mediaType = "Stream";
      break;
    case ASSET_TYPES.TRACK:
      mediaType = "Track";
      break;
    case ASSET_TYPES.TRANSCRIPT:
      mediaType = "Transcript";
      break;
    case ASSET_TYPES.ROOM_VOD:
      mediaType = "Room VOD";
      break;
    case ASSET_TYPES.SUMMARY:
      mediaType = "Summary";
      break;
  }

  if (assetType === ASSET_TYPES.TRACK) {
    const trackType = asset?.metadata?.track_type || "";
    if (trackType === TRACK_TYPES.AUDIO) {
      mediaType += " - Audio";
    } else if (trackType === TRACK_TYPES.VIDEO) {
      mediaType += " - Video";
    }
  } else if (assetType === ASSET_TYPES.STREAM) {
    const streamType = asset?.metadata?.stream_type || "";
    if (streamType === STREAM_TYPES.REGULAR) {
      mediaType += " - Regular";
    } else if (streamType === STREAM_TYPES.SCREENSHARE) {
      mediaType += " - Screenshare";
    }
  }

  const resolution = asset?.metadata?.resolution || ({} as resolution);
  if (resolution) {
    const width = resolution?.width || 0;
    const height = resolution?.height || 0;
    if (width && height) {
      mediaType += ` (${width}x${height})`;
    }
  }

  const outputMode = asset?.metadata?.output_mode || "";
  if (outputMode) {
    mediaType += ` (.${outputMode})`;
  }

  return mediaType;
};

// eslint-disable-next-line complexity
export const getAssetMetaInformation = ({
  assetObject,
}: {
  assetObject: recordingAsset;
}) => {
  // Get the asset type from the assetObject, or default to an empty string if undefined
  const assetType = assetObject?.type || "";

  // Check if the asset type includes "room-composite"
  if (assetType.includes(ASSET_TYPES.ROOM_COMPOSITE)) {
    // If metadata resolution exists, return the width and height
    if (assetObject?.metadata?.resolution) {
      return `${assetObject?.metadata?.resolution?.width} X ${assetObject?.metadata?.resolution?.height}`;
    } else {
      return ""; // Return an empty string if metadata resolution is undefined
    }
  }
  // Check if the asset type is "transcript" or "summary"
  else if ([ASSET_TYPES.TRANSCRIPT, ASSET_TYPES.SUMMARY].includes(assetType)) {
    // Return the output mode from the metadata, or an empty string if undefined
    return assetObject?.metadata?.output_mode || "";
  }

  return ""; // Default return value if asset type does not match any conditions
};

/**
 * Returns the appropriate button label for the given asset object.
 *
 * @param {Object} assetObject - The asset object.
 * @returns {string} The button label.
 */
export const getAssetButtonLabel = (assetObject: {
  type: string;
  status: any;
}) => {
  // Get the asset type from the asset object, or an empty string if not available
  const assetType = assetObject?.type || "";
  if (assetObject.status === ASSET_STATUS.FAILED) {
    return "Failed";
  }
  // Check the asset type and return the corresponding button label
  if ([ASSET_TYPES.TRANSCRIPT, ASSET_TYPES.SUMMARY].includes(assetType)) {
    return assetObject.status === ASSET_STATUS.COMPLETED
      ? "View File"
      : "Processing..."; // Return "Download File" for transcript and summary assets
  } else if ([ASSET_TYPES.CHAT].includes(assetType)) {
    return "Download Chat"; // Return "Download Chat" for chat assets
  } else if (assetObject.status === ASSET_STATUS.FAILED) {
    return "Failed";
  }
  return "View Recording"; // Default button label for other asset types
};
