import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import {
  roomCodeTokenType,
  roomCodeType,
  roomRTMPStreamKeyType,
} from "src/types/roomCodeTypes";
import { roomDetailsType } from "src/types/roomTypes";
import { Session } from "src/types/sessions";
import {
  CREATE_ROOM_RTMP_STREAM_KEYS_DONE,
  CREATE_ROOM_RTMP_STREAM_KEYS_FAIL,
  CREATE_ROOM_RTMP_STREAM_KEYS_INIT,
  DISABLE_ROOM_RTMP_STREAM_KEYS_DONE,
  DISABLE_ROOM_RTMP_STREAM_KEYS_FAIL,
  DISABLE_ROOM_RTMP_STREAM_KEYS_INIT,
  FETCH_RECENT_ROOMS_DONE,
  FETCH_RECENT_ROOMS_FAIL,
  FETCH_RECENT_ROOMS_INIT,
  FETCH_ROOM_CODES_FOR_ALLROLES_DONE,
  FETCH_ROOM_CODES_FOR_ALLROLES_FAIL,
  FETCH_ROOM_CODES_FOR_ALLROLES_INIT,
  FETCH_ROOM_DETAILS_DONE,
  FETCH_ROOM_DETAILS_FAIL,
  FETCH_ROOM_DETAILS_INIT,
  FETCH_ROOM_RTMP_STREAM_KEYS_DONE,
  FETCH_ROOM_RTMP_STREAM_KEYS_FAIL,
  FETCH_ROOM_RTMP_STREAM_KEYS_INIT,
  FETCH_ROOM_SESSION_DETAILS_DONE,
  FETCH_ROOM_SESSION_DETAILS_FAIL,
  FETCH_ROOM_SESSION_DETAILS_INIT,
  FETCH_ROOM_SHORT_CODE_FOR_ROLE_DONE,
  FETCH_ROOM_SHORT_CODE_FOR_ROLE_FAIL,
  FETCH_ROOM_SHORT_CODE_FOR_ROLE_INIT,
  FETCH_ROOM_TOKEN_DETAILS_DONE,
  FETCH_ROOM_TOKEN_DETAILS_FAIL,
  FETCH_ROOM_TOKEN_DETAILS_INIT,
  RESET_ROOM_DETAILS,
  RESET_ROOM_SESSION_DETAILS,
  SET_ROOM_SESSION_DERIVED_STATE,
  UPDATE_ROOM_DETAILS_DONE,
  UPDATE_ROOM_DETAILS_FAIL,
  UPDATE_ROOM_DETAILS_INIT,
} from "./actions";
import { API_CALL_STATE } from "../../constants";

const initialState = {
  room: {
    roles: [] as string[],
  } as roomDetailsType,
  recentRooms: [],
  roomRoleTokens: {} as Record<string, roomCodeTokenType>,
  fetchRoomTokenStatus: "",
  roomShortCodeForRoles: {} as Record<string, roomCodeType>,
  fetchAllRoomCodesStatus: "",
  fetchRoomShortCodeStatus: "",
  roomShortCodeErrorMessage: "",
  fetchRTMPStreamKeysStatus: "",
  createRTMPStreamKeysStatus: "",
  updateRoomStatus: "",
  disableRTMPStreamKeysStatus: "",
  fetchRecentRoomsStatus: "",
  fetchRoomDetailsStatus: "",
  derivedRoomSessionsState: {
    last: "",
    id: "",
  },
  fetchRoomSessionsStatus: "",
  roomSessionDetails: [] as Partial<Session>[],
  roomStreamKeys: {} as Record<string, roomRTMPStreamKeyType>,
};

export const rooms = handleActions<typeof initialState, any>(
  {
    [FETCH_ROOM_TOKEN_DETAILS_INIT]: state => ({
      ...state,
      fetchRoomTokenStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [FETCH_ROOM_TOKEN_DETAILS_FAIL]: state => ({
      ...state,
      roomRoleTokens: {},
      fetchRoomTokenStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_ROOM_TOKEN_DETAILS_DONE]: (state, action: AnyAction) => ({
      ...state,
      fetchRoomTokenStatus: API_CALL_STATE.DONE,
      roomRoleTokens: {
        ...state.roomRoleTokens,
        [action.payload.role]: action.payload,
      },
    }),
    [FETCH_ROOM_SHORT_CODE_FOR_ROLE_INIT]: state => ({
      ...state,
      fetchRoomShortCodeStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [FETCH_ROOM_SHORT_CODE_FOR_ROLE_DONE]: (state, action: AnyAction) => ({
      ...state,
      fetchRoomShortCodeStatus: API_CALL_STATE.DONE,
      roomShortCodeForRoles: {
        ...state.roomShortCodeForRoles,
        [action.payload.role]: action.payload,
      },
    }),
    [FETCH_ROOM_SHORT_CODE_FOR_ROLE_FAIL]: state => ({
      ...state,
      roomShortCodeForRoles: {},
      fetchRoomShortCodeStatus: API_CALL_STATE.FAILED,
    }),

    [FETCH_ROOM_CODES_FOR_ALLROLES_INIT]: state => ({
      ...state,
      fetchAllRoomCodesStatus: API_CALL_STATE.IN_PROGRESS,
      roomShortCodeForRoles: {},
    }),
    [FETCH_ROOM_CODES_FOR_ALLROLES_DONE]: (state, action: AnyAction) => {
      return {
        ...state,
        fetchAllRoomCodesStatus: API_CALL_STATE.DONE,
        roomRoleTokens: {},
        roomShortCodeForRoles: { ...action.payload },
      };
    },
    [FETCH_ROOM_CODES_FOR_ALLROLES_FAIL]: (state, action: AnyAction) => ({
      ...state,
      fetchAllRoomCodesStatus: API_CALL_STATE.FAILED,
      roomShortCodeErrorMessage: action.payload.msg,
    }),
    [FETCH_ROOM_DETAILS_INIT]: state => ({
      ...state,
      fetchRoomDetailsStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [FETCH_ROOM_DETAILS_DONE]: (state, action: AnyAction) => ({
      ...state,
      fetchRoomDetailsStatus: API_CALL_STATE.DONE,
      room: action.payload,
    }),
    [FETCH_ROOM_DETAILS_FAIL]: state => ({
      ...state,
      room: {
        roles: [] as string[],
      } as roomDetailsType,
      fetchRoomDetailsStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_ROOM_SESSION_DETAILS_FAIL]: state => ({
      ...state,
      fetchRoomSessionsStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_ROOM_SESSION_DETAILS_DONE]: (state, action: AnyAction) => ({
      ...state,
      roomSessionDetails: action.payload,
      fetchRoomSessionsStatus: API_CALL_STATE.DONE,
    }),
    [FETCH_ROOM_SESSION_DETAILS_INIT]: state => ({
      ...state,
      fetchRoomSessionsStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [SET_ROOM_SESSION_DERIVED_STATE]: (state, action: AnyAction) => ({
      ...state,
      derivedRoomSessionsState: {
        ...state.derivedRoomSessionsState,
        ...action.payload,
      },
    }),
    [RESET_ROOM_DETAILS]: state => ({
      ...state,
      room: {
        roles: [] as string[],
      } as roomDetailsType,
      fetchRoomDetailsStatus: "",
    }),
    [RESET_ROOM_SESSION_DETAILS]: state => ({
      ...state,
      roomSessionDetails: [],
      fetchRoomSessionsStatus: "",
      derivedRoomSessionsState: {
        last: "",
        id: "",
      },
    }),

    [UPDATE_ROOM_DETAILS_DONE]: state => ({
      ...state,
      updateRoomStatus: API_CALL_STATE.DONE,
    }),
    [UPDATE_ROOM_DETAILS_FAIL]: state => ({
      ...state,
      updateRoomStatus: API_CALL_STATE.FAILED,
    }),
    [UPDATE_ROOM_DETAILS_INIT]: state => ({
      ...state,
      updateRoomStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [FETCH_RECENT_ROOMS_INIT]: state => ({
      ...state,
      fetchRecentRoomsStatus: API_CALL_STATE.IN_PROGRESS,
      recentRooms: [],
    }),
    [FETCH_RECENT_ROOMS_DONE]: (state, action: AnyAction) => ({
      ...state,
      fetchRecentRoomsStatus: API_CALL_STATE.DONE,
      recentRooms: action.payload,
    }),
    [FETCH_RECENT_ROOMS_FAIL]: state => ({
      ...state,
      recentRooms: [],
      fetchRecentRoomsStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_ROOM_RTMP_STREAM_KEYS_INIT]: state => ({
      ...state,
      fetchRTMPStreamKeysStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [FETCH_ROOM_RTMP_STREAM_KEYS_DONE]: (state, action: AnyAction) => ({
      ...state,
      fetchRTMPStreamKeysStatus: API_CALL_STATE.DONE,
      roomStreamKeys: {
        ...state.roomStreamKeys,
        [action.payload.roomId]: action.payload,
      },
    }),
    [FETCH_ROOM_RTMP_STREAM_KEYS_FAIL]: state => ({
      ...state,
      fetchRTMPStreamKeysStatus: API_CALL_STATE.FAILED,
    }),
    [CREATE_ROOM_RTMP_STREAM_KEYS_INIT]: state => ({
      ...state,
      createRTMPStreamKeysStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [CREATE_ROOM_RTMP_STREAM_KEYS_DONE]: (state, action: AnyAction) => ({
      ...state,
      createRTMPStreamKeysStatus: API_CALL_STATE.DONE,
      roomStreamKeys: {
        ...state.roomStreamKeys,
        [action.payload.roomId]: action.payload,
      },
    }),
    [CREATE_ROOM_RTMP_STREAM_KEYS_FAIL]: state => ({
      ...state,
      createRTMPStreamKeysStatus: API_CALL_STATE.FAILED,
    }),
    [DISABLE_ROOM_RTMP_STREAM_KEYS_INIT]: state => ({
      ...state,
      disableRTMPStreamKeysStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [DISABLE_ROOM_RTMP_STREAM_KEYS_DONE]: (state, action: AnyAction) => {
      const tempStreamKeys = { ...state.roomStreamKeys };
      delete tempStreamKeys[action.payload.roomId];
      return {
        ...state,
        disableRTMPStreamKeysStatus: API_CALL_STATE.DONE,
        roomStreamKeys: {
          ...tempStreamKeys,
        },
      };
    },
    [DISABLE_ROOM_RTMP_STREAM_KEYS_FAIL]: state => ({
      ...state,
      disableRTMPStreamKeysStatus: API_CALL_STATE.FAILED,
    }),
  },

  initialState
);
