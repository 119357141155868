import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHasUnsavedLiveStreamingChanges,
  toggleHLSRecording,
} from "src/actions/RolesActions";
import TemplateConfigCard from "src/components/Cards/TemplateConfigCard";
import ConfigSingleSelect from "src/components/Common/ConfigSingleSelect";
import Line from "src/components/Common/Line";
import SettingsAccordion from "src/components/Common/SettingsAccordion";
import StatusString from "src/components/Common/StatusString";
import TabGroup from "src/components/Common/TabGroup";
import Tile from "src/components/Common/Tile";
import ValuePill from "src/components/Common/ValuePill";
import TimeInput from "src/components/TimeInput";
import { getSubsetLayers, isEqualHLSLayer } from "src/helpers";
import SwitchWithTitle from "src/pages/Developer/SwitchWithTitle";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { RootState } from "src/store/reducers";
import { hlsDestinationsLayerType } from "src/types/policyTypes";
import { isCustomHLSVideoLayer, slugify, sortLayers } from "src/utils";
import { CheckIcon, ChevronDownIcon } from "@100mslive/react-icons";
import {
  Box,
  Checkbox,
  Dropdown,
  Flex,
  Input,
  Switch,
  Text,
} from "@100mslive/react-ui";
import { ConfigInputCSS } from "./HLS";

interface StreamRecordingProps {
  changeHLSDestination: (
    destinationId: string,
    path: string,
    value: string | boolean | number[] | number | hlsDestinationsLayerType[]
  ) => void;
}

const VODFormatTabs = [
  {
    name: "Unzipped",
    id: 0,
    icon: <></>,
    title: "Unzipped",
    value: false,
  },
  {
    name: "Zipped",
    id: 1,
    icon: <></>,
    title: "Zipped",
    value: true,
  },
];

// eslint-disable-next-line complexity
const StreamRecording: FC<StreamRecordingProps> = ({
  changeHLSDestination,
}) => {
  const cardTitle = "Stream Recording";
  const [open, setOpen] = useState(false);
  const [syncedLayers, setSyncedLayers] = useState(false);
  const dispatch = useDispatch();

  const recordingStreamType = [
    { key: "VOD (On-demand playback)", value: "hlsVod", default: true },
    { key: "Video files per layer", value: "singleFilePerLayer" },
  ];

  const recordingStorage = useSelector(
    (state: RootState) => state.roles.policyInfo?.settings?.recording || {}
  );
  const storageIsAdded = (recordingStorage?.upload?.type || "") !== "";
  const hlsDestinations = useSelector(
    (state: RootState) =>
      state.roles.policyInfo?.destinations?.hlsDestinations || {}
  );

  const hlsDestinationIds = Object.keys(hlsDestinations) || [];
  const recordingIsEnabled =
    hlsDestinations?.[hlsDestinationIds?.[0]]?.recording?.hlsVod ||
    hlsDestinations?.[hlsDestinationIds?.[0]]?.recording?.singleFilePerLayer;

  const hlsObj = hlsDestinations?.[hlsDestinationIds?.[0]];

  const activeVODFormat = useMemo(() => {
    return VODFormatTabs.find(
      tab => tab.value === (hlsObj?.recording?.enableZipUpload || false)
    );
  }, [hlsObj]);
  const isVODDisabled = storageIsAdded
    ? false
    : activeVODFormat?.value === false;

  const setHLSDestinations = (
    path: string,
    value: string | boolean | number[] | number | hlsDestinationsLayerType[]
  ) => changeHLSDestination(hlsDestinationIds[0], path, value);

  const setRecordingType = (
    clickedRecordingTypeValue: "hlsVod" | "singleFilePerLayer"
  ) => {
    const recObj = hlsObj?.recording;
    // Don't update the state if HLS recording is enabled and the user deselects the only selected recording type
    if (
      !(
        recObj?.[clickedRecordingTypeValue] &&
        Object.values(recObj || {})?.filter((value: unknown) => value === true)
          .length === 1
      )
    ) {
      setHLSDestinations(
        `recording.${clickedRecordingTypeValue}`,
        !recObj?.[clickedRecordingTypeValue]
      );
    }
  };

  const invalidFields = useSelector(
    (state: RootState) => state.roles.invalidFields.hlsDestinations
  );

  const handleLayerClick = (clickedLayer: hlsDestinationsLayerType) => {
    const layers = hlsObj?.recording?.layers || [];
    const parentArray = hlsObj?.layers || [];
    if (
      !layers.some(layer =>
        isEqualHLSLayer(
          clickedLayer,
          layer.width,
          layer.height,
          layer.videoBitrate
        )
      )
    ) {
      setHLSDestinations(
        "recording.layers",
        sortLayers([...layers, clickedLayer])
      );
    } else if (hlsObj?.recording?.thumbnails) {
      const { width, height } = hlsObj.recording.thumbnails;

      const updatedLayers = [
        ...layers.filter(
          (layer: hlsDestinationsLayerType) =>
            !isEqualHLSLayer(
              clickedLayer,
              layer.width,
              layer.height,
              layer.videoBitrate
            )
        ),
      ];
      let validLayers = getSubsetLayers(parentArray, updatedLayers);
      if (validLayers.length === 0) {
        validLayers = [...parentArray];
      }

      let updatedThumbnailRes = {};
      if (isEqualHLSLayer(clickedLayer, width, height)) {
        updatedThumbnailRes = {
          thumbnails: {
            ...hlsObj?.recording?.thumbnails,
            width: validLayers[0]?.width,
            height: validLayers[0]?.height,
          },
        };
      }
      setHLSDestinations("recording", {
        ...hlsObj.recording,
        // @ts-ignore
        layers: sortLayers(validLayers),
        ...updatedThumbnailRes,
      });
      if (isCustomHLSVideoLayer(clickedLayer)) {
        // @ts-ignore
        setHLSDestinations("layers", sortLayers(validLayers));
      }
    }
  };

  // If no recording layers are selected, enable all
  useEffect(() => {
    if (
      hlsObj?.recording &&
      (hlsObj?.recording?.layers || []).length === 0 &&
      (hlsObj?.layers || []).length
    ) {
      setHLSDestinations("recording.layers", hlsObj?.layers || []);
    }

    // eslint-disable-next-line
  }, [hlsObj?.recording]);

  // Thumbnail mgmt needed here - also affected by HLSResolutions in StreamVideoOutput
  useEffect(() => {
    if (hlsObj?.recording?.layers && hlsObj?.layers) {
      setSyncedLayers(hlsObj.recording.layers.length === hlsObj.layers.length);
    }
    if (hlsObj?.recording?.thumbnails) {
      const {
        width = hlsObj?.recording?.layers?.[0]?.width,
        height = hlsObj?.recording?.layers?.[0]?.height,
      } = hlsObj.recording.thumbnails;

      // Post update, layers contain a layer corresponding to the thumbnail dimensions
      if (
        hlsObj?.recording?.layers?.some((layer: hlsDestinationsLayerType) =>
          isEqualHLSLayer(layer, width, height)
        ) &&
        hlsObj?.layers?.some((layer: hlsDestinationsLayerType) =>
          isEqualHLSLayer(layer, width, height)
        )
      ) {
        return;
      }
      // If there are no matches, use the topmost recording layer

      setHLSDestinations("recording", {
        ...hlsObj?.recording,
        // @ts-ignore
        thumbnails: {
          ...hlsObj?.recording?.thumbnails,
          width: hlsObj?.recording?.layers?.[0]?.width,
          height: hlsObj?.recording?.layers?.[0]?.height,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hlsObj?.recording?.layers, hlsObj?.layers]);

  return (
    <>
      <Line />
      <TemplateConfigCard
        css={{ padding: "0", paddingTop: "$4", width: "100%" }}
        text={cardTitle}
        id={slugify(cardTitle)}
        classNameForText="config-subheading"
        subtitle=""
        rightComponent={
          <Flex direction="column" css={{ w: "100%" }}>
            <SettingsAccordion
              defaultOpen={true}
              children={
                <Box css={{ padding: "0 $px" }}>
                  <SettingsTitle
                    key="streamRecording"
                    title="Enable/disable stream recording"
                    text="Stream Recording"
                  />
                  <SwitchWithTitle
                    hideTitle
                    tooltipMessage=""
                    checkedValue={recordingIsEnabled}
                    onCheckedChange={() => {
                      dispatch(setHasUnsavedLiveStreamingChanges(true));
                      dispatch(toggleHLSRecording(!recordingIsEnabled));
                    }}
                  />
                  {recordingIsEnabled ? (
                    <>
                      <Line />
                      <SettingsTitle
                        key="resLayers"
                        title="Override resolution layers for the recordings"
                        text="Resolution Layers"
                      />
                      <Flex align="center" css={{ my: "$8", gap: "$4" }}>
                        <Switch
                          checked={syncedLayers}
                          disabled={syncedLayers}
                          onCheckedChange={() =>
                            setHLSDestinations(
                              "recording.layers",
                              hlsObj?.layers || []
                            )
                          }
                        />
                        <Text variant="caption" css={{ color: "$textMedEmp" }}>
                          Same as above
                        </Text>
                      </Flex>
                      <Flex css={{ mt: "$4", gap: "$md", flexWrap: "wrap" }}>
                        {hlsDestinations?.[hlsDestinationIds?.[0]]?.layers?.map(
                          (layer: any, i: number) => {
                            const resolution = `${layer.width} x ${layer.height}`;
                            const isActive = hlsObj?.recording?.layers?.some(
                              Objlayer =>
                                isEqualHLSLayer(
                                  layer,
                                  Objlayer.width,
                                  Objlayer.height,
                                  Objlayer.videoBitrate
                                )
                            );

                            const tempLayer = {
                              defaultLayer: layer,
                              title: layer.title,
                              resolution: resolution,
                              bitrate: `${
                                layer?.videoBitrate
                                  ? layer.videoBitrate
                                  : layer.defaultVideoBitrate
                              } Kbps video bitrate`,
                            };
                            return (
                              <Tile
                                isLayer
                                css={{ m: "0" }}
                                key={layer.key + i.toString()}
                                onClick={() => handleLayerClick(layer)}
                                subText={tempLayer.bitrate}
                                text={tempLayer.resolution}
                                isActive={isActive}
                              />
                            );
                          }
                        )}
                      </Flex>
                      {(hlsObj?.recording?.layers || []).length === 0 ? (
                        <Text
                          variant="caption"
                          css={{ mt: "$8", color: "$textMedEmp" }}
                        >
                          If no layer is selected, recordings will be available
                          for all layers
                        </Text>
                      ) : null}
                      <Line />

                      <SettingsTitle
                        key="typeOfRecording"
                        title="Recording for on-demand playback gives you HLS-compatible (m3u8) chunks for playback. Video files per layer give you mp4 files per HLS layer."
                        text="Type of Recording"
                      />

                      <Dropdown.Root
                        open={open}
                        modal={false}
                        onOpenChange={setOpen}
                      >
                        <Dropdown.Trigger asChild css={ConfigInputCSS}>
                          <Flex
                            justify="between"
                            align="center"
                            css={{
                              bg: "$surfaceLight !important",
                              p: "$5 $6",
                              border: "1px solid $borderLight",
                              borderRadius: "$0",
                            }}
                          >
                            <Text
                              variant="body2"
                              css={{ c: "$textAccentDisabled" }}
                            >
                              Select types
                            </Text>
                            <Flex>
                              <ChevronDownIcon
                                style={{
                                  transform: open
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                  transition: "transform ease 0.3s",
                                }}
                              />
                            </Flex>
                          </Flex>
                        </Dropdown.Trigger>
                        <Dropdown.Content
                          css={{
                            w: "100%",
                          }}
                          align="end"
                          className="bg-surface-light shadow-2xl px-0"
                          sideOffset={10}
                          onInteractOutside={() => setOpen(false)}
                        >
                          {recordingStreamType.map(option => (
                            <Dropdown.CheckboxItem
                              key={option.key}
                              onSelect={e => e.preventDefault()}
                              checked={
                                // @ts-ignore
                                hlsObj?.recording?.[option.value] || false
                              }
                              onCheckedChange={() =>
                                // @ts-ignore
                                setRecordingType(option.value)
                              }
                              css={{ p: "$6" }}
                            >
                              <Flex align="center">
                                <Checkbox.Root
                                  id={option.key}
                                  checked={
                                    // @ts-ignore
                                    hlsObj?.recording?.[option.value] || false
                                  }
                                  css={{
                                    bg: "$textMedEmp",
                                    borderColor: "$textMedEmp",
                                    '&[data-state="checked"]': {
                                      bg: "$textMedEmp",
                                    },
                                  }}
                                >
                                  <Checkbox.Indicator
                                    css={{ c: "$secondaryDark" }}
                                  >
                                    <CheckIcon width={16} height={16} />
                                  </Checkbox.Indicator>
                                </Checkbox.Root>
                                <Text
                                  variant="caption"
                                  css={{ ml: "$6", c: "$textMedEmp" }}
                                >
                                  {option.key}
                                </Text>
                              </Flex>
                            </Dropdown.CheckboxItem>
                          ))}
                        </Dropdown.Content>
                      </Dropdown.Root>
                      <Flex>
                        {recordingStreamType.map(item => {
                          // @ts-ignore
                          if (hlsObj?.recording?.[item.value]) {
                            return (
                              <ValuePill
                                content={item.key}
                                // @ts-ignore
                                onClick={() => setRecordingType(item.value)}
                              />
                            );
                          }
                          return null;
                        })}
                      </Flex>
                      {hlsObj?.recording?.hlsVod ? (
                        <>
                          <SettingsTitle
                            key="vodUploadFormat"
                            title={
                              <Text variant="caption">
                                Unzipped is immediately playable, while zipped
                                is optimised for storage.
                                <a
                                  href={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/features/recordings/live-stream-recording`}
                                  rel="noreferrer noopener"
                                  target="_blank"
                                >
                                  <Text
                                    variant="caption"
                                    css={{
                                      c: "$primaryLight",
                                      fontWeight: "$regular",
                                    }}
                                    as="span"
                                  >
                                    &nbsp;Learn more &rarr;
                                  </Text>
                                </a>
                              </Text>
                            }
                            text="VOD Upload Format"
                            css={{ marginTop: "$12" }}
                          />
                          <TabGroup
                            activeTab={activeVODFormat?.name}
                            setActiveTab={(value: string) => {
                              const selectedTab = VODFormatTabs.find(
                                tab => tab.name === value
                              );
                              setHLSDestinations(
                                "recording.enableZipUpload",
                                selectedTab?.value || false
                              );
                            }}
                            useParams={false}
                            btnId={`vod.format.${activeVODFormat?.name}`}
                            componentId={`templates.config.live.streaming.vod.format.${activeVODFormat?.name}`}
                            tabs={VODFormatTabs}
                            css={{ mt: "$4" }}
                            disabled={isVODDisabled}
                          />
                          {isVODDisabled ? (
                            <Text
                              variant="caption"
                              css={{ color: "$textDisabled", marginTop: "$4" }}
                            >
                              Upload type is supported only on custom storage.
                              <a
                                href={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/features/recordings/live-stream-recording`}
                                rel="noreferrer noopener"
                                target="_blank"
                              >
                                <Text
                                  variant="caption"
                                  css={{
                                    c: "$primaryLight",
                                    fontWeight: "$regular",
                                  }}
                                  as="span"
                                >
                                  &nbsp;Learn more &rarr;
                                </Text>
                              </a>
                            </Text>
                          ) : null}
                        </>
                      ) : null}
                      <Line />
                      <SettingsTitle
                        key="urlValidity"
                        title="Recordings saved to 100ms storage need to be downloaded using pre-signed download URLs sent via webhook. Alternatively, you can use your own storage."
                        text="Validity of Download URLs (hours)"
                      />
                      <TimeInput
                        css={ConfigInputCSS}
                        onChange={setHLSDestinations}
                        timeFactor={3600}
                        _key="recording.presignDuration"
                        value={
                          hlsDestinations?.[hlsDestinationIds[0]]?.recording
                            ?.presignDuration
                        }
                      />

                      <StatusString
                        content={invalidFields?.presignDuration || ""}
                      />
                      <Line />
                      <SettingsTitle
                        key="recordingThumbnails"
                        title="Enable to configure thumbnails for the recording"
                        text="Recording Thumbnails"
                      />
                      <SwitchWithTitle
                        hideTitle
                        tooltipMessage=""
                        checkedValue={Boolean(
                          hlsObj?.recording?.thumbnails?.enabled || ""
                        )}
                        onCheckedChange={(e: boolean) => {
                          setHLSDestinations("recording.thumbnails.enabled", e);
                          if (e) {
                            setHLSDestinations("recording.thumbnails", {
                              // @ts-ignore
                              width: hlsObj?.recording?.layers[0]?.width,
                              height: hlsObj?.recording?.layers[0]?.height,
                              enabled: e,
                              offsets: [2],
                            });
                          }
                        }}
                      />
                      {hlsObj?.recording?.thumbnails?.enabled ? (
                        <Box css={{ pb: "$1" }}>
                          <Line />
                          <SettingsTitle
                            key="resolutions"
                            title="Select resolution for the recording thumbnail"
                            text="Thumbnail resolution"
                          />

                          <ConfigSingleSelect
                            inputText={`${hlsObj?.recording?.thumbnails?.width}x${hlsObj?.recording?.thumbnails?.height}`}
                            rootCSS={{ width: "100%" }}
                            triggerCSS={{ width: "100%", marginTop: "$4" }}
                            contentCSS={{
                              height: "auto",
                              maxHeight: "$96",
                              overflowY: "hidden",
                              textAlign: "center",
                            }}
                            content={
                              <>
                                {hlsObj?.recording?.layers
                                  ?.sort(
                                    (
                                      a: hlsDestinationsLayerType,
                                      b: hlsDestinationsLayerType
                                    ) => Number(a?.height > b?.height)
                                  )
                                  .map((layer: hlsDestinationsLayerType) => {
                                    return (
                                      <Dropdown.Item
                                        key={`${layer?.width}x${layer?.height}`}
                                        css={{ p: "$4 $8" }}
                                        onClick={() => {
                                          setHLSDestinations(
                                            "recording.thumbnails.width",
                                            layer?.width
                                          );
                                          setHLSDestinations(
                                            "recording.thumbnails.height",
                                            layer?.height
                                          );
                                        }}
                                      >
                                        <Text variant="caption">
                                          {layer?.width}x{layer?.height}
                                        </Text>
                                      </Dropdown.Item>
                                    );
                                  })}
                              </>
                            }
                          />
                          <Line />
                          <SettingsTitle
                            key="thumbnailOffset"
                            title="Specify the duration offset (from beginning) where the thumbnail frame is captured. Must be at least 2 seconds"
                            text="Thumbnail Offset (seconds)"
                          />

                          <Input
                            type="number"
                            onChange={e => {
                              const val = e.target.value || 0;
                              setHLSDestinations(
                                "recording.thumbnails.offsets",
                                [Number(val)]
                              );
                            }}
                            css={ConfigInputCSS}
                            value={
                              hlsObj?.recording?.thumbnails?.offsets?.[0] ?? ""
                            }
                          />
                          <StatusString
                            content={invalidFields?.thumbnailOffset || ""}
                          />
                        </Box>
                      ) : null}
                    </>
                  ) : null}
                </Box>
              }
            />
          </Flex>
        }
      />
    </>
  );
};

export default StreamRecording;
