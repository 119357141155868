import React, { FC, ReactElement, useRef, useState } from "react";
import { ChevronDownIcon } from "@100mslive/react-icons";
import { CSS, Dropdown, Flex, Text } from "@100mslive/react-ui";

interface ConfigSingleSelectProps {
  rootCSS?: CSS;
  triggerCSS?: CSS;
  contentCSS?: CSS;
  inputText: string;
  content: ReactElement;
  contentSideOffset?: number;
  inputIcon?: ReactElement;
  inputTextCss?: CSS;
}

const ConfigSingleSelect: FC<ConfigSingleSelectProps> = ({
  rootCSS = {},
  triggerCSS = {},
  inputText,
  contentCSS = {},
  content,
  contentSideOffset = 10,
  inputIcon = <></>,
  inputTextCss = {},
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <Dropdown.Root
      open={open}
      modal={false}
      onOpenChange={setOpen}
      css={{ ...rootCSS }}
    >
      <Dropdown.Trigger css={{ ...triggerCSS }}>
        <Flex
          css={{
            p: "$5 $6",
            bg: "$surfaceLight",
            r: "$0",
            border: "solid $space$px $borderLight",
          }}
          justify="between"
          ref={ref}
        >
          <Flex align="center">
            {inputIcon ? inputIcon : <></>}
            <Text variant="body2" css={{ ...inputTextCss }}>
              {inputText}
            </Text>
          </Flex>

          <ChevronDownIcon
            width={20}
            height={20}
            style={{
              transition: "transform 0.3s ease",
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </Flex>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content
          align="end"
          sideOffset={contentSideOffset}
          css={{
            width: `${
              ref?.current ? Math.floor(ref?.current?.offsetWidth) : "100"
            }px`,
            borderRadius: "$0",
            ...contentCSS,
          }}
        >
          {content}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

export default ConfigSingleSelect;
