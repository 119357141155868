import React from "react";
import { TILE_SHAPE } from "src/constants";
import { isCustomAspectRatio } from "src/utils";
import { Flex } from "@100mslive/react-ui";
import Tile from "./Tile";

function AspectRatioConfig({
  onAspectRatioChange,
  aspectRatio,
}: {
  onAspectRatioChange: (aspectRatio: string) => void;
  aspectRatio: string;
}) {
  return (
    <Flex css={{ mt: "$6", flexWrap: "wrap" }}>
      {TILE_SHAPE.map((tile, index) => {
        const selected: boolean = aspectRatio === tile.type;
        return (
          <Tile
            key={index + tile.type}
            subText={tile.type}
            val={tile.value}
            isActive={selected}
            text={tile.text}
            onClick={() => {
              onAspectRatioChange(tile.type);
            }}
          />
        );
      })}
      {isCustomAspectRatio(aspectRatio) ? (
        <Tile
          key="custom"
          subText={aspectRatio}
          val={aspectRatio}
          isActive={true}
          css={{ px: "$4" }}
          text="Custom"
          onClick={() => {
            return;
          }}
        />
      ) : null}
    </Flex>
  );
}

export default AspectRatioConfig;
