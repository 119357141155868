// import history from "./history";
// import { currentUser } from "../utils";

// function parseJwt(token) {
//   var base64Url = token.split(".")[1];
//   var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
//   var jsonPayload = decodeURIComponent(
//     atob(base64)
//       .split("")
//       .map(function (c) {
//         return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
//       })
//       .join("")
//   );

//   return JSON.parse(jsonPayload);
// }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const jwt_verify = (redirect = true) => {
  // let user = currentUser();
  // if (!user) {
  //   //push to logout
  //   if (redirect) {
  //     history.push("/logout");
  //   }
  //   return false;
  // }

  // let jwt = user.token;
  // jwt = parseJwt(jwt);
  // var current_time = Date.now() / 1000;
  // if (jwt.exp < current_time || (!user.admin && !jwt?.app_id)) {
  //   //push logout
  //   if (redirect) {
  //     window.location.pathname = "logout";
  //   }
  //   return false;
  // }

  return true;
};

export default jwt_verify;
