import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Flex, Text } from "@100mslive/react-ui";

interface DropdownItemContentProps {
  link: string;
  text: string;
  icon: SVGRectElement;
}

const DropdownItemContent: FC<DropdownItemContentProps> = ({
  link,
  text,
  icon,
}) => (
  <Link style={{ width: "100%" }} to={link}>
    <Flex
      align="center"
      css={{ color: "$textHighEmp", gap: "$md", p: "$6 $3", w: "100%" }}
    >
      <>{icon}</>
      <Text
        variant="sm"
        css={{ color: "$textHighEmp", fontWeight: "$semiBold" }}
      >
        {text}
      </Text>
    </Flex>
  </Link>
);

export default DropdownItemContent;
