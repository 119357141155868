import { Link } from "react-router-dom";
import { Text } from "@100mslive/react-ui";
import menu from "../../assets/images/menu.png";

const ZeroState = () => (
  <>
    <img src={menu} alt="100ms" width="40px" height="40px"></img>
    <Text
      css={{
        w: "min(300px, 100%)",
        textAlign: "center",
        mt: "$md",
      }}
      variant="body1"
    >
      Enter Room ID/Session ID to keep track of all the events and debug them
    </Text>
    <Text
      css={{
        w: "min(375px, 100%)",
        textAlign: "center",
        mt: "$md",
        c: "$textMedEmp",
      }}
      variant="sm"
    >
      Go to
      <Link
        to="/sessions"
        className="text-blue-tint hover:text-blue-tint font-semibold"
      >
        {" "}
        Sessions{" "}
      </Link>
      or
      <Link
        to="/rooms"
        className="text-blue-tint hover:text-blue-tint font-semibold"
      >
        {" "}
        Rooms{" "}
      </Link>
      to copy Session or Room ID.
    </Text>
    <Text
      css={{
        w: "min(375px, 100%)",
        textAlign: "center",
        c: "$textMedEmp",
      }}
      variant="sm"
    >
      Event data is available only for the last 30 days.
    </Text>
  </>
);

export default ZeroState;
