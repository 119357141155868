import { argbFromHex, Hct } from "@material/material-color-utilities";
import { ColorPalette } from "src/types/prebuilt";

// eslint-disable-next-line complexity
export function analyseColorPalette(colorPalette: ColorPalette) {
  // Find the max and min positive toneDelta values
  let maxToneIncrement = 0;
  let maxToneDecrement = 0;

  const defaultHex = colorPalette.value;
  const defaultARGB = argbFromHex(defaultHex);
  const defaultHCT = Hct.fromInt(defaultARGB);
  const defaultTone = defaultHCT.tone;

  for (const color of colorPalette.colorPalette) {
    if (color.toneDelta > maxToneIncrement) {
      maxToneIncrement = color.toneDelta;
    }
    if (color.toneDelta < 0 && color.toneDelta < maxToneDecrement) {
      maxToneDecrement = color.toneDelta;
    }
  }

  const decrementPossible = defaultTone + maxToneDecrement >= 0;
  const incrementPossible = defaultTone + maxToneIncrement <= 100;

  if (!incrementPossible) {
    const rangeAvailable = 100 - defaultTone;
    let increment = 0;

    if (rangeAvailable === 0) {
      increment = 0;
    } else {
      increment = rangeAvailable / Math.abs(maxToneIncrement / 10);
    }

    for (const color of colorPalette.colorPalette) {
      if (color.toneDelta > 0) {
        color.tone = increment * (color.toneDelta / 10);
        color.tone = defaultTone + color.tone;
      }
      if (color.toneDelta < 0) {
        color.tone = defaultTone + color.toneDelta;
      }
      if (color.toneDelta === 0) {
        color.tone = defaultTone + color.toneDelta;
      }
    }
  }

  if (!decrementPossible) {
    const rangeAvailable = defaultTone;
    const decrement = rangeAvailable / Math.abs(maxToneDecrement / 10);

    for (const color of colorPalette.colorPalette) {
      if (color.toneDelta < 0) {
        color.tone = decrement * (color.toneDelta / 10);
        color.tone = defaultTone + color.tone;
      }
      if (color.toneDelta > 0) {
        color.tone = defaultTone + color.toneDelta;
      }
      if (color.toneDelta === 0) {
        color.tone = Hct.fromInt(argbFromHex(colorPalette.value)).tone;
      }
    }
  }
  if (incrementPossible && decrementPossible) {
    for (const color of colorPalette.colorPalette) {
      if (color.toneDelta) {
        color.tone = defaultTone + color.toneDelta;
      } else {
        color.tone = Hct.fromInt(argbFromHex(colorPalette.value)).tone;
      }
    }
  }

  return colorPalette;
}

export const getDefaultPrebuiltProps = (
  screenMode: string,
  screenType: string
) => {
  if (screenMode === "conferencing") {
    return {
      [screenType]: {
        elements: {
          brb: null,
          chat: null,
          participant_list: null,
          video_tile_layout: null,
          emoji_reactions: null,
          hand_raise: null,
          on_stage_exp: null,
          virtual_background: null,
        },
      },
    };
  }
  if (screenMode === "preview") {
    return {
      [screenType]: {
        elements: {
          virtual_background: null,
        },
      },
    };
  }
  return {};
};
