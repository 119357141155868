import React, { ComponentProps, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { Layout } from "@100mslive/types-prebuilt";
import { cloneDeep, isEmpty, set } from "lodash";
import {
  addRole,
  deleteRole,
  fetchPolicyInfo,
  patchRoleInStore,
  patchRolesInStore,
  setActiveRole,
  setHasUnsavedRoles,
  updateRole,
} from "src/actions/RolesActions";
import SectionHeader from "src/components/Common/SectionHeader";
import { SideList, SideListItem } from "src/components/Common/SideList";
import toastr from "src/components/Common/toastr";
import TemplateLayout from "src/components/layout/templateLayout";
import { API_CALL_STATE, UNSAVED_CHANGES_TEXT } from "src/constants";
import PublishStrategy from "src/containers/templateConfigRoles/PublishStrategy";
import SubscribeStrategy from "src/containers/templateConfigRoles/SubscribeStrategy";
import { getPriorityRoles, getVisibleRoles, isVisibleRole } from "src/helpers";
import useActiveLinks from "src/hooks/useActiveLink";
import {
  patchAppLayout,
  updateAppLayoutInStore,
} from "src/store/appLayout/actions";
import { roleTemplateForPolicy } from "src/store/policyTemplate";
import { RootState } from "src/store/reducers";
import { roleTemplatePolicy, roleType } from "src/types/policyTypes";
import { slugify } from "src/utils";
import { InviteIcon } from "@100mslive/react-icons";
import { Flex, Loading, Text } from "@100mslive/react-ui";
import DeleteItem from "./DeleteItem";
import GeneralRoleSettings from "./GeneralRoleSettings";
import Permissions from "./Permissions";

const roleConfigList = [
  "General",
  "Publish Strategy",
  "Subscribe Strategy",
  "Permissions",
];

const NewRoles: FC<ComponentProps<typeof Flex> & { policyId: string }> = ({
  policyId,
}) => {
  const dispatch = useDispatch();
  const roles = useSelector((state: RootState) => state.roles.roles);
  const [originalRole, setOriginalRole] = useState<roleType>();
  const roleLayouts = useSelector(
    (state: RootState) => state.appLayouts.roleLayouts
  );
  const hasUnsavedRoles = useSelector(
    (state: RootState) => state.roles.hasUnsavedRoles
  );
  const fetchPolicyInfoStatus = useSelector(
    (state: RootState) => state.roles.fetchPolicyInfoStatus
  );

  const policyInfo = useSelector((state: RootState) => state.roles.policyInfo);
  const addRoleStatus = useSelector(
    (state: RootState) => state.roles.addRoleStatus
  );
  const activeRole = useSelector((state: RootState) => state.roles.activeRole);
  const selectRole = (role: string) => dispatch(setActiveRole(role));

  const deleteRoleStatus = useSelector(
    (state: RootState) => state.roles.deleteRoleStatus
  );

  const loadingAddRole =
    fetchPolicyInfoStatus === API_CALL_STATE.IN_PROGRESS ||
    addRoleStatus === API_CALL_STATE.IN_PROGRESS;

  const loadingDeleteRole = deleteRoleStatus === API_CALL_STATE.IN_PROGRESS;

  const canDelete = getVisibleRoles(Object.keys(policyInfo?.roles)).length > 1;
  const deleteClicked = async () => {
    const newRoles = cloneDeep(roles).filter(
      (el: roleType) => el.name !== activeRole
    );

    if (roles.length === 1) {
      toastr.warning("There must be atleast 1 role!");
      return;
    }
    dispatch(deleteRole(policyId, newRoles, activeRole));
    dispatch(setHasUnsavedRoles(false));
  };

  const addRoleClicked = async () => {
    if (hasUnsavedRoles) {
      if (!window.confirm(UNSAVED_CHANGES_TEXT)) {
        return;
      } else {
        dispatch(patchRoleInStore(originalRole as roleType));
      }
    }
    const rolesList = cloneDeep(roles);
    const newRole = { ...roleTemplateForPolicy };
    do {
      const randomNumber = Math.floor(1000 + Math.random() * 9000);
      newRole.name = `new-role-${randomNumber}`;
    } while (rolesList.some((role: roleType) => role.name === newRole.name));
    newRole.subscribeParams.subscribeToRoles.push(newRole.name);
    dispatch(addRole(policyId, newRole));
    dispatch(setHasUnsavedRoles(false));
  };
  const activeSubheading = useActiveLinks("config-subheading");

  useEffect(() => {
    if (!policyId) {
      return;
    }
    dispatch(fetchPolicyInfo(policyId));
    dispatch(setHasUnsavedRoles(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyId]);

  useEffect(() => {
    if (
      !activeRole &&
      !isEmpty(roles) &&
      fetchPolicyInfoStatus === API_CALL_STATE.DONE &&
      fetchPolicyInfoStatus
    ) {
      selectRole(getVisibleRoles(roles.map((role: roleType) => role.name))[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRole, roles, fetchPolicyInfoStatus, policyId]);

  useEffect(() => {
    if (activeRole && policyId) {
      setOriginalRole(policyInfo.roles[activeRole]);
    }
  }, [activeRole, policyId, policyInfo?.roles]);

  return (
    <TemplateLayout
      unsaved={hasUnsavedRoles}
      onSaveClick={function (): void {
        dispatch(
          updateRole(
            policyId,
            policyInfo?.roles?.[activeRole],
            Object.values(policyInfo?.roles)
          )
        );
        dispatch(patchAppLayout({ roleLayouts: roleLayouts }));
        dispatch(setHasUnsavedRoles(false));
      }}
    >
      <Flex
        direction="column"
        css={{
          minWidth: "210px",
          position: "sticky",
          overflow: "clip auto",
          top: "$20",
          h: "100%",
        }}
      >
        {roles?.length
          ? roles
              .filter((role: roleTemplatePolicy) => isVisibleRole(role.name))
              .sort((a: roleTemplatePolicy, b: roleTemplatePolicy) =>
                getPriorityRoles(a?.name, b?.name)
              )
              .map((role: roleTemplatePolicy) => (
                <SideList
                  showChevron={true}
                  title={role.name}
                  subtitle=""
                  key={role.name}
                  active={activeRole === role.name}
                  onClick={() => {
                    if (hasUnsavedRoles) {
                      if (!window.confirm(UNSAVED_CHANGES_TEXT)) {
                        return;
                      } else {
                        dispatch(patchRoleInStore(originalRole as roleType));
                      }
                    }
                    if (activeRole !== role.name) {
                      selectRole(role.name);
                    }

                    // dispatch(fetchPolicyInfo(policyId));

                    dispatch(setHasUnsavedRoles(false));
                  }}
                >
                  {activeRole === role.name
                    ? roleConfigList.map(roleConfig => {
                        const url = slugify(roleConfig);
                        return (
                          <HashLink
                            to={`?tab=roles#${url}`}
                            style={{ width: "100%" }}
                            key={roleConfig}
                            onClick={e => e.stopPropagation()}
                          >
                            <SideListItem
                              title={roleConfig}
                              active={activeSubheading === url}
                              onClick={() => null}
                            />
                          </HashLink>
                        );
                      })
                    : null}
                </SideList>
              ))
          : null}

        <Flex
          onClick={addRoleClicked}
          align="center"
          css={{
            p: "$6 $8",
            c: "$textMedEmp",
            cursor: "pointer",
            borderTop: "1px solid $borderDefault",
            pt: "$md",
            mt: "$6",
            "&:hover": { color: "$textHighEmp" },
          }}
          justify={loadingAddRole ? "center" : "start"}
        >
          {loadingAddRole ? (
            <Loading size={16} />
          ) : (
            <>
              <InviteIcon />
              <Text variant="sm" css={{ c: "inherit", ml: "$5" }}>
                Add a role
              </Text>
            </>
          )}
        </Flex>
      </Flex>

      <Flex
        direction="column"
        css={{ rowGap: "$12", w: "100%", left: "240px", flexGrow: 1 }}
      >
        <SectionHeader
          title="Roles"
          body="A collection of permissions that allows you to perform certain set of operations while being part of the room."
          buttonText="Read Guide"
          link={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/concepts/templates-and-roles`}
        />
        {activeRole ? (
          <>
            <GeneralRoleSettings
              roleConfig={policyInfo?.roles?.[activeRole]}
              roles={roles}
              activeRole={activeRole}
              setActiveRole={selectRole}
            />
            <PublishStrategy
              roleName={activeRole}
              updateRole={(roleName, key, value) => {
                const role = { ...policyInfo?.roles?.[roleName] };
                set(role, key, value);
                dispatch(patchRoleInStore(role));
                dispatch(setHasUnsavedRoles(true));
              }}
              policy={policyInfo}
              isOnboarding={false}
              templateConfig={true}
            />
            <SubscribeStrategy
              updateLayout={({ layout }: { layout: Layout }) => {
                dispatch(
                  updateAppLayoutInStore({
                    roleLayouts: {
                      ...roleLayouts,
                      [activeRole]: layout,
                    },
                  })
                );
              }}
              layout={roleLayouts[activeRole]}
              roleName={activeRole}
              updateRole={(roleName, key, value) => {
                const tempPolicy = { ...policyInfo };
                set(tempPolicy, `roles.${roleName}.${key}`, value);
                dispatch(patchRolesInStore(Object.values(tempPolicy.roles)));
                dispatch(setHasUnsavedRoles(true));
              }}
              policy={policyInfo}
              isOnboarding={false}
              templateConfig={true}
            />
            <Permissions
              setPermissions={(key, value) => {
                const role = { ...policyInfo?.roles?.[activeRole] };
                set(role, `permissions.${key}`, value);
                dispatch(patchRoleInStore(role));
                dispatch(setHasUnsavedRoles(true));
              }}
              roles={policyInfo?.roles}
              roleName={activeRole}
              permissions={policyInfo?.roles?.[activeRole]?.permissions}
            />
            <DeleteItem
              itemType="Role"
              title={
                !canDelete ? (
                  <>
                    Role cannot be deleted.
                    <br />
                    At least one role must be present!
                  </>
                ) : (
                  <>
                    Delete "
                    <span
                      style={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {activeRole}
                    </span>
                    " role?
                  </>
                )
              }
              onClick={deleteClicked} // Function to delete role and remove it from lists of other roles
              hidePrimaryCTA={!canDelete}
              hideInnerContent={!canDelete}
              loading={loadingDeleteRole}
            />
          </>
        ) : null}
      </Flex>
    </TemplateLayout>
  );
};

export default NewRoles;
