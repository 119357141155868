import React from "react";
import { Flex } from "@100mslive/react-ui";
import ResponsiveLayout from "./ResponsiveLayout";

export const tabs = {
  Desktop: {
    name: "Desktop",
    id: 1,
    icon: <></>,
    title: "Desktop",
    value: "desktop",
    width: "100%",
    height: "calc(100vh - (121px))",
  },
  Tablet: {
    name: "Tablet",
    id: 2,
    icon: <></>,
    title: "Tablet",
    value: "tablet",
    width: "100%",
    height: "100%",
  },
  Mobile: {
    name: "Mobile",
    id: 3,
    icon: <></>,
    title: "Mobile",
    value: "mobile",
    width: "100%",
    height: "100%",
  },
};

function PrebuiltResponsiveViewProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Flex
      direction="column"
      css={{ p: "0 $10 $10 $10", h: "calc(100vh - (121px))" }}
    >
      <ResponsiveLayout
        width={tabs["Desktop"].width}
        height={tabs["Desktop"].height}
      >
        {children}
      </ResponsiveLayout>
    </Flex>
  );
}

export default PrebuiltResponsiveViewProvider;
