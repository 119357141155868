import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import TemplateConfigCard from "src/components/Cards/TemplateConfigCard";
import ConfigSingleSelect from "src/components/Common/ConfigSingleSelect";
import Line from "src/components/Common/Line";
import SettingsAccordion from "src/components/Common/SettingsAccordion";
import StatusString from "src/components/Common/StatusString";
import TimeInput from "src/components/TimeInput";
import { HLS_PLAYLIST_TYPE } from "src/constants";
import SwitchWithTitle from "src/pages/Developer/SwitchWithTitle";
import Resolutions from "src/pages/Template/HLSResolutions";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { RootState } from "src/store/reducers";
import { hlsDestinationsLayerType } from "src/types/policyTypes";
import { slugify } from "src/utils";
import { Box, Dropdown, Flex, Input, Text } from "@100mslive/react-ui";
import { ConfigInputCSS } from "./HLS";

interface StreamVideoOutputProps {
  changeHLSDestination: (
    destinationId: string,
    path: string,
    value: string | boolean | number[] | number | hlsDestinationsLayerType[]
  ) => void;
}
// eslint-disable-next-line complexity
const StreamVideoOutput: FC<StreamVideoOutputProps> = ({
  changeHLSDestination,
}) => {
  const cardTitle = "Stream Video Output";

  const [seekErrorMessage, SetseekErrorMessage] = useState("");

  const invalidFields = useSelector(
    (state: RootState) => state.roles.invalidFields.hlsDestinations
  );

  const hlsDestinations = useSelector(
    (state: RootState) =>
      state.roles.policyInfo?.destinations?.hlsDestinations || {}
  );

  const hlsDestinationIds = Object.keys(hlsDestinations) || [];

  const setHLSDestinations = (
    path: string,
    value: string | boolean | number
  ) => {
    if (path === "numPlaylistSegments" && +value < 150) {
      SetseekErrorMessage("Playback window time can not be less than 300");
      return;
    }
    if (path === "playlistType" && value === "noSeek") {
      changeHLSDestination(hlsDestinationIds[0], "playlistType", "live");
      changeHLSDestination(hlsDestinationIds[0], "numPlaylistSegments", 3);
    } else if (path === "playlistType" && value === "live") {
      changeHLSDestination(hlsDestinationIds[0], path, value);
      changeHLSDestination(hlsDestinationIds[0], "numPlaylistSegments", 150);
    } else {
      changeHLSDestination(hlsDestinationIds[0], path, value);
    }
    SetseekErrorMessage("");
  };
  const getPlaylistType = () => {
    const seekValue =
      hlsDestinations?.[hlsDestinationIds[0]]?.numPlaylistSegments || 6;

    return seekValue < 150
      ? HLS_PLAYLIST_TYPE["noSeek"]
      : HLS_PLAYLIST_TYPE?.[
          hlsDestinations?.[hlsDestinationIds[0]]?.playlistType ?? ""
        ];
  };

  return (
    <>
      <Line />
      <TemplateConfigCard
        css={{ padding: "0", paddingTop: "$4" }}
        text={cardTitle}
        id={slugify(cardTitle)}
        classNameForText="config-subheading"
        subtitle=""
        rightComponent={
          <Flex direction="column" css={{ minWidth: "100%" }}>
            <SettingsAccordion
              defaultOpen={true}
              children={
                <Box css={{ padding: "0 $px" }}>
                  <SettingsTitle
                    key="maxStreamDuration"
                    title="Max limit for live stream duration. The stream can be ended before max duration using the API/SDK methods"
                    text="Maximum Stream Duration (minutes)"
                  />
                  <TimeInput
                    css={ConfigInputCSS}
                    onChange={setHLSDestinations}
                    timeFactor={60}
                    _key="maxDuration"
                    value={hlsDestinations?.[hlsDestinationIds[0]]?.maxDuration}
                  />
                  <StatusString content={invalidFields?.maxDuration || ""} />
                  <Line />

                  <SettingsTitle
                    key="playlistType"
                    title="Define whether your users can seek to any point in the past of the stream – or limit them to last few seconds only"
                    text="Playlist Type"
                  />

                  <ConfigSingleSelect
                    inputText={getPlaylistType()}
                    rootCSS={{ width: "100%" }}
                    triggerCSS={{ width: "100%", marginTop: "$4" }}
                    contentCSS={{
                      height: "auto",
                      maxHeight: "$96",
                      overflowY: "hidden",
                      textAlign: "center",
                    }}
                    content={
                      <>
                        {Object.keys(HLS_PLAYLIST_TYPE).map((type: string) => {
                          return (
                            <Dropdown.Item
                              key={type}
                              css={{ p: "$4 $8" }}
                              onClick={() => {
                                setHLSDestinations("playlistType", type);
                              }}
                            >
                              <Text variant="caption">
                                {HLS_PLAYLIST_TYPE?.[type]}
                              </Text>
                            </Dropdown.Item>
                          );
                        })}
                      </>
                    }
                  />

                  {hlsDestinations[hlsDestinationIds[0]]?.playlistType ===
                    "live" &&
                  (hlsDestinations?.[hlsDestinationIds[0]]
                    ?.numPlaylistSegments || 6) >= 150 ? (
                    <>
                      <Line />

                      <SettingsTitle
                        key="numPlaylistSegments"
                        title="Limit the amount of duration that your users can seek back to during live playback"
                        text="Playback Rolling Window (seconds)"
                      />
                      <TimeInput
                        css={ConfigInputCSS}
                        onChange={setHLSDestinations}
                        timeFactor={1 / 2}
                        _key="numPlaylistSegments"
                        value={
                          hlsDestinations?.[hlsDestinationIds[0]]
                            ?.numPlaylistSegments
                        }
                      />

                      <StatusString
                        content={
                          invalidFields?.numPlaylistSegments
                            ? ""
                            : seekErrorMessage || ""
                        }
                      />

                      <StatusString
                        content={invalidFields?.numPlaylistSegments || ""}
                      />
                    </>
                  ) : null}
                  <Line />

                  <SettingsTitle
                    key="videoFrameRate"
                    title="Frame rate for HLS video output. Should be between 10-30"
                    text="Video frame rate (FPS)"
                  />

                  <Input
                    type="number"
                    onChange={e =>
                      setHLSDestinations(
                        "videoFrameRate",
                        Number(e.target.value)
                      )
                    }
                    css={ConfigInputCSS}
                    value={
                      hlsDestinations?.[hlsDestinationIds[0]]?.videoFrameRate
                    }
                  />
                  <StatusString content={invalidFields?.videoFrameRate || ""} />

                  <Line />
                  <SettingsTitle
                    key="enableStaticUrl"
                    title="If enabled, HLS playback URLs for a room are static. If disabled, HLS playback URLs are generated dynamically"
                    text="Static Playback URLs"
                  />
                  <SwitchWithTitle
                    hideTitle
                    tooltipMessage=""
                    checkedValue={
                      hlsDestinations?.[hlsDestinationIds[0]]?.enableStaticUrl
                    }
                    onCheckedChange={(e: boolean) => {
                      setHLSDestinations("enableStaticUrl", e);
                    }}
                  />
                  <Line />
                  <SettingsTitle
                    key="videoAspectRatio"
                    title="Select the aspect ratio of the video tiles used for live streaming."
                    text="Video Aspect Ratio"
                  />
                  <Resolutions
                    hlsDestinations={
                      hlsDestinations?.[hlsDestinationIds[0]] || {}
                    }
                    setHLSDestinations={setHLSDestinations}
                    layerTitle={
                      <>
                        <Line />
                        <SettingsTitle
                          key="resolutionLayers"
                          title="Select live streaming resolution layers available for ABR. The resolution options are based on the selected ‘Video Aspect Ratio’."
                          text="Resolution Layers"
                        />
                      </>
                    }
                  />
                </Box>
              }
            />
          </Flex>
        }
      />
    </>
  );
};

export default StreamVideoOutput;
