import { all } from "redux-saga/effects";
import ForgetSaga from "./auth/forgetpwd/saga";
import AuthSaga from "./auth/login/saga";
//public
import AccountSaga from "./auth/register/saga";
import LayoutSaga from "./layout/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    AuthSaga(),
    ForgetSaga(),
    LayoutSaga(),
  ]);
}
