import {
  JoinForm,
  JoinForm_JoinBtnType,
} from "@100mslive/types-prebuilt/elements/join_form";
import { OnStageExperience } from "@100mslive/types-prebuilt/elements/on_stage_experience";
import { VirtualBackground } from "@100mslive/types-prebuilt/elements/virtual_background";
import { isValidImageUrl } from "src/utils";

export const validateOnStageExperience = (data: OnStageExperience) => {
  const invalidFields = {} as {
    on_stage_role?: string;
    off_stage_roles?: string;
  };
  let isValid = true;
  if (data?.on_stage_role === "") {
    invalidFields["on_stage_role"] = "on stage role is required";
    isValid = false;
  }

  if (!(data?.off_stage_roles?.length && data?.off_stage_roles?.length > 0)) {
    invalidFields["off_stage_roles"] = "off stage roles is required";
    isValid = false;
  }

  return { isValid, invalidFields };
};

export const validateJoinForm = (data: JoinForm) => {
  const invalidFields = {} as Record<keyof typeof data, string>;
  let isValid = true;

  if (
    data.join_btn_type !== JoinForm_JoinBtnType.JOIN_BTN_TYPE_JOIN_ONLY &&
    data.join_btn_type !== JoinForm_JoinBtnType.JOIN_BTN_TYPE_JOIN_AND_GO_LIVE
  ) {
    invalidFields["join_btn_type"] = "This is required";
    isValid = false;
  }
  if (
    data?.join_btn_type ===
      JoinForm_JoinBtnType.JOIN_BTN_TYPE_JOIN_AND_GO_LIVE &&
    !data.go_live_btn_label
  ) {
    invalidFields["go_live_btn_label"] = "Go Live button label is required";
    isValid = false;
  }
  if (!data.join_btn_label) {
    invalidFields.join_btn_label = "Join now button is required";
    isValid = false;
  }
  return { isValid, invalidFields };
};

export const validateVirtualBackground = (data: VirtualBackground) => {
  const invalidFields = {} as Record<keyof typeof data, string>;
  let isValid = true;

  for (
    let i = 0;
    data?.background_media && i < data.background_media.length;
    i++
  ) {
    if (data.background_media[i].url === "") {
      invalidFields.background_media = "";
      isValid = false;
      break;
    }
    if (!isValidImageUrl(data.background_media[i].url)) {
      invalidFields.background_media = "Please enter a valid image URL";
      isValid = false;
      break;
    }
  }

  return { isValid, invalidFields };
};
