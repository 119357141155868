import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import ConfigSingleSelect from "src/components/Common/ConfigSingleSelect";
import MainPopover from "src/components/Modal/MainPopover";
import { API_CALL_STATE } from "src/constants";
import { getPriorityRolesArray, isVisibleRole } from "src/helpers/roles_helper";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { RootState } from "src/store/reducers";
import { ExternalLinkIcon, LinkIcon } from "@100mslive/react-icons";
import { Box, Button, Dropdown, Flex, Text } from "@100mslive/react-ui";

const JoinRoomPopover = () => {
  const roomShortCodeForRoles = useSelector(
    (state: RootState) => state.rooms.roomShortCodeForRoles
  );
  const roomCodeDisabled =
    useSelector(
      (state: RootState) => state.workspace?.current?.room_code_disabled
    ) || false;
  const { policyInfo, fetchPolicyInfoStatus } = useSelector(
    (state: RootState) => state.roles
  );
  const subdomain = (policyInfo as any)?.["subdomain"];
  const roles = useMemo(() => policyInfo?.roles || [], [policyInfo?.roles]);
  const visibleRoles = getPriorityRolesArray(
    Object.keys(roles).filter(role => isVisibleRole(role))
  );
  const [selectedRole, setSelectedRole] = useState(visibleRoles?.[0] || "");
  const isPolicyLoading = fetchPolicyInfoStatus === API_CALL_STATE.IN_PROGRESS;
  const disableTrigger =
    isEmpty(roomShortCodeForRoles) || roomCodeDisabled || isPolicyLoading;

  useEffect(() => {
    if (!selectedRole && visibleRoles.length) {
      setSelectedRole(visibleRoles[0]);
    }
  }, [selectedRole, visibleRoles]);

  return (
    <MainPopover
      trigger={
        <Button
          variant="primary"
          outlined
          disabled={disableTrigger}
          css={{
            borderRadius: "$0",
            height: "32px",
          }}
          loading={isPolicyLoading}
        >
          Join Room
        </Button>
      }
      content={
        <Box
          css={{
            p: "$5",
          }}
        >
          <Text
            variant="body2"
            css={{
              fontWeight: "$semiBold",
              color: "$textAccentHigh",
              fontSize: "16px",
            }}
          >
            Join Room
          </Text>
          <Text
            variant="caption"
            css={{
              color: "$textAccentMedium",
              marginTop: "$2",
            }}
          >
            Join the default room assigned to this template
          </Text>
          <SettingsTitle
            title=""
            text="Select Role"
            hideTooltip
            titleVariant="caption"
            css={{
              mt: "$10",
            }}
          />
          <ConfigSingleSelect
            inputText={selectedRole}
            contentCSS={{ zIndex: 1100 }}
            inputTextCss={{ ml: "$2" }}
            triggerCSS={{ width: "100%", mt: "$4" }}
            content={
              <>
                {visibleRoles.map((role: string) => (
                  <Dropdown.Item
                    key={role}
                    css={{ p: "$4 $8" }}
                    onClick={() => {
                      setSelectedRole(role);
                    }}
                  >
                    <Text variant="caption">{role}</Text>
                  </Dropdown.Item>
                ))}
              </>
            }
          />
          <Flex
            justify="between"
            gap="4"
            css={{
              mt: "$10",
            }}
          >
            <Button
              variant="standard"
              css={{
                borderRadius: "$0",
              }}
              onClick={() => {
                window.navigator.clipboard.writeText(
                  `https://${subdomain}/meeting/${roomShortCodeForRoles?.[selectedRole]?.value}`
                );
              }}
            >
              <LinkIcon height="24" width="24" />
              <Text
                variant="sub2"
                css={{
                  ml: "$4",
                }}
              >
                Copy Link
              </Text>
            </Button>
            <a
              href={`https://${subdomain}/meeting/${roomShortCodeForRoles?.[selectedRole]?.value}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                variant="primary"
                css={{
                  borderRadius: "$0",
                }}
              >
                <Flex
                  justify="center"
                  align="center"
                  css={{
                    h: "$10",
                    w: "$10",
                  }}
                >
                  <ExternalLinkIcon height="16px" width="16px" />
                </Flex>
                <Text
                  variant="sub2"
                  css={{
                    ml: "$4",
                  }}
                >
                  Join Room
                </Text>
              </Button>
            </a>
          </Flex>
        </Box>
      }
    />
  );
};

export default JoinRoomPopover;
