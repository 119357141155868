import React from "react";
import NoResults from "src/components/Common/NoResults";
import LoadingSpinner from "src/components/LoadingSpinner";
import { Box, Flex, Text } from "@100mslive/react-ui";

export const LoadingState = () => (
  <Flex justify="center" css={{ w: "100%", mt: "$14" }}>
    <LoadingSpinner />
  </Flex>
);

export const NoSessions = () => (
  <Box css={{ mt: "$20" }}>
    <Text variant="h6" css={{ color: "$textHighEmp", textAlign: "center" }}>
      This room does not have sessions yet
    </Text>
  </Box>
);

export const NoSearchResults = () => (
  <NoResults
    title="No results found"
    subtitle="Please ensure that the Session ID is correct"
  />
);
