import React from "react";
import { Layout } from "@100mslive/types-prebuilt";
import { RoleLayouts } from "src/types/prebuilt";
import { PrebuiltSidebarSwitch } from "./PrebuiltSidebarSwitch";

type Props = {
  roleLayouts: RoleLayouts;
  role: string;
  setRoleLayouts: ({ roleLayouts }: { roleLayouts: RoleLayouts }) => void;
};

export const PrebuiltSkipPreviewScreen = ({
  roleLayouts,
  role,
  setRoleLayouts,
}: Props) => {
  const skipPreviewSwitchHandler = () => {
    const layout = roleLayouts[role];
    const tempLayout: Layout = {
      ...roleLayouts[role],
      screens: {
        ...layout.screens,
        preview: {
          ...layout?.screens?.preview,
          skip_preview_screen:
            !roleLayouts[role]?.["screens"]?.preview?.skip_preview_screen,
        },
      },
    };

    setRoleLayouts({
      roleLayouts: {
        ...roleLayouts,
        [role]: { ...tempLayout },
      },
    });
  };

  return (
    <PrebuiltSidebarSwitch
      title="Skip Preview"
      tooltipText="If enabled, peers of this role will skip preview and directly join the room."
      checked={!!roleLayouts[role]?.["screens"]?.preview?.skip_preview_screen}
      disabled={false}
      onCheckedChange={skipPreviewSwitchHandler}
    />
  );
};
