import React from "react";
import { isEmpty } from "lodash";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import {
  convertSecondsToHumanReadable,
  convertToBitratePerSecond,
} from "src/helpers/utils";
import { peerAnalyticsSubscribeType } from "src/types/analytics";
import { InfoIcon } from "@100mslive/react-icons";
import { Flex, Text, Tooltip } from "@100mslive/react-ui";

type Props = {
  subscribeData: peerAnalyticsSubscribeType;
};

function TrackMessage({
  text,
  val,
  tooltip = "",
}: {
  text: string;
  val: string;
  tooltip?: string;
}) {
  return (
    <Flex
      css={{
        p: "$8 $10",
      }}
      justify="start"
      direction="column"
    >
      <Flex align="center" css={{ gap: "$2" }}>
        <Text variant="caption" css={{ c: "$textDisabled" }}>
          {text}
        </Text>
        {tooltip ? (
          <Tooltip
            title={tooltip}
            side="top"
            align="center"
            boxCss={{ r: "$0", w: "$80" }}
          >
            <Flex css={{ c: "$textDisabled" }}>
              <SmallIcon>
                <InfoIcon />
              </SmallIcon>
            </Flex>
          </Tooltip>
        ) : (
          <></>
        )}
      </Flex>
      <Text
        variant="body2"
        css={{ c: "$textMedEmp", mt: "$2", fontWeight: "$regular" }}
      >
        {val}
      </Text>
    </Flex>
  );
}
/* eslint-disable-next-line complexity */
export default function SubscriberBitrateMetrics({ subscribeData }: Props) {
  // eslint-disable-next-line complexity
  const calulateAverageBitrate = () => {
    if (isEmpty(subscribeData?.bitrate)) {
      return {
        expectedBitrate: 0,
        actualBitrate: 0,
        availableBitrate: 0,
      };
    }
    let subscribeDegradationDuration = 0;
    let totalExpected = 0;
    let totalActual = 0;
    let totalAvailable = 0;
    let totalDuration = 0;
    const data = subscribeData.bitrate;
    if (data.length === 0) {
      return {
        expectedBitrate: 0,
        actualBitrate: 0,
        availableBitrate: 0,
        subscribeDegradation: 0,
      };
    }

    for (let i = 0; i < data.length - 1; i++) {
      const diff =
        (new Date(data[i + 1].value[0]).getTime() -
          new Date(data[i].value[0]).getTime()) /
        1000;

      const degradationThreshold = data[i].value[1][0] * 0.9;

      if (
        Number.isFinite(data[i].value[1][0]) &&
        Number.isFinite(data[i].value[1][1]) &&
        degradationThreshold / 1024 > data[i].value[1][1] / 1024
      ) {
        subscribeDegradationDuration += diff;
      }
      if (Number.isFinite(data[i].value[1][0])) {
        totalExpected += data[i].value[1][0] * diff;
      }
      if (Number.isFinite(data[i].value[1][1])) {
        totalActual += data[i].value[1][1] * diff;
      }
      if (Number.isFinite(data[i].value[1][2])) {
        totalAvailable += data[i].value[1][2] * diff;
      }
      totalDuration += diff;
    }
    if (totalDuration === 0) {
      return {
        expectedBitrate: Number.isFinite(data[0].value[1][0])
          ? data?.[0]?.value[1][0]
          : 0,
        actualBitrate: Number.isFinite(data[0].value[1][1])
          ? data?.[0]?.value[1][1]
          : 0,
        availableBitrate: Number.isFinite(data[0].value[1][2])
          ? data?.[0]?.value[1][2]
          : 0,
        subscribeDegradation: subscribeDegradationDuration,
      };
    }
    return {
      expectedBitrate: totalExpected / totalDuration,
      actualBitrate: totalActual / totalDuration,
      availableBitrate: totalAvailable / totalDuration,
      subscribeDegradation: subscribeDegradationDuration,
    };
  };
  const averageBitrate = calulateAverageBitrate();

  return (
    <Flex
      css={{
        borderTop: "$space$px solid $borderDefault",
        borderLeft: "$space$px solid $borderDefault",
        w: "30%",
        h: "100%",
      }}
      direction="column"
    >
      <TrackMessage
        text="Average Expected Bitrate"
        val={convertToBitratePerSecond(
          averageBitrate.expectedBitrate / 1000,
          2
        )}
      />
      <TrackMessage
        text="Average Actual Bitrate"
        val={convertToBitratePerSecond(averageBitrate.actualBitrate / 1000, 2)}
      />
      <TrackMessage
        text="Average Available Bitrate"
        val={convertToBitratePerSecond(
          averageBitrate.availableBitrate / 1000,
          2
        )}
      />
      {averageBitrate?.subscribeDegradation ? (
        <TrackMessage
          text="Subscribe Degradation for"
          val={convertSecondsToHumanReadable(
            averageBitrate.subscribeDegradation
          )}
        />
      ) : (
        <></>
      )}
    </Flex>
  );
}
