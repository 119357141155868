import { isFunction } from "lodash";
import {
  DESTINATIONS_BROADCASTER_ASPECT_RATIO,
  LIVE_STREAM_RTMP_INGEST_OPTIONS,
  VIEWER_ON_STAGE_OPTIONS,
} from "src/services/cmsModels/Policy/helpers";
import { policyType } from "src/types/policyTypes";
import { RoleLayouts } from "src/types/prebuilt";
import {
  externalRTMPIngestForLiveStreamingUseCase,
  selectAspectRatioForLiveStreamingUseCase,
  setCompositeRecordingForWebinarTemplates,
  setLargeRoomForTemplate,
  setLiveStreamingRecordingAutoStart,
  setLiveStreamingRecordingType,
  toggleLiveTranscription,
  toggleViewerOnStage,
} from ".";
import { steps } from "../containers/OnboardingFlowWithLayout";

type Props = {
  stepAnswers: Record<string, any>;
  policy: policyType;
  roleLayouts: RoleLayouts;
  setPolicy: (policy: policyType) => void;
  setRoleLayouts: (roleLayouts: RoleLayouts) => void;
  setAppDetails: ({
    tile_shape,
    metadata,
  }: {
    tile_shape?: string;
    metadata?: string;
  }) => void;
  setLargeRoom?: (bool: boolean) => void;
};

export const masterTemplateToAnswerConfig = {
  LIVE_STREAM: {
    function: ({
      stepAnswers,
      policy,
      roleLayouts,
      setPolicy,
      setRoleLayouts,
      setAppDetails,
      setLargeRoom,
    }: Props) => {
      setLiveStreamingRecordingAutoStart(
        stepAnswers[steps.SELECT_ADD_ON.key]["recording"],
        policy,
        setPolicy
      );
      if (isFunction(setLargeRoom)) {
        setLargeRoomForTemplate({
          answer: stepAnswers[steps.SELECT_ADD_ON.key]["largeRoom"],
          setLargeRoom,
        });
      }
      setLiveStreamingRecordingType({
        streamType: stepAnswers[steps.SELECT_ADD_ON.key]["recordingOnDemand"],
        policy: policy,
        setPolicy: setPolicy,
      });
      toggleLiveTranscription({
        val: stepAnswers[steps.SELECT_ADD_ON.key]["liveTranscription"],
        policy,
        setPolicy,
      });
      if (
        stepAnswers[steps.CONFIGURE_USE_CASE.key]["streamType"] ===
        LIVE_STREAM_RTMP_INGEST_OPTIONS.external
      ) {
        selectAspectRatioForLiveStreamingUseCase(
          DESTINATIONS_BROADCASTER_ASPECT_RATIO.desktop["16:9"],
          policy,
          roleLayouts,
          setPolicy,
          setRoleLayouts,
          setAppDetails
        );
        toggleViewerOnStage({
          str: VIEWER_ON_STAGE_OPTIONS.No,
          policy,
          setPolicy,
          roleLayouts,
          setRoleLayouts,
        });
        externalRTMPIngestForLiveStreamingUseCase({
          policy,
          setPolicy,
          roleLayouts,
          setRoleLayouts,
        });
      } else {
        selectAspectRatioForLiveStreamingUseCase(
          stepAnswers[steps.CONFIGURE_USE_CASE.key]["broadcasterStream"],
          policy,
          roleLayouts,
          setPolicy,
          setRoleLayouts,
          setAppDetails
        );

        toggleViewerOnStage({
          str: stepAnswers[steps.CONFIGURE_USE_CASE.key]["viewerOnStage"],
          policy,
          setPolicy,
          roleLayouts,
          setRoleLayouts,
        });
      }
    },
  },
  WEBINAR: {
    function: ({
      stepAnswers,
      policy,
      roleLayouts,
      setPolicy,
      setRoleLayouts,
      setAppDetails,
      setLargeRoom,
    }: Props) => {
      selectAspectRatioForLiveStreamingUseCase(
        stepAnswers[steps.CONFIGURE_USE_CASE.key]["broadcasterStream"],
        policy,
        roleLayouts,
        setPolicy,
        setRoleLayouts,
        setAppDetails
      );

      toggleViewerOnStage({
        str: stepAnswers[steps.CONFIGURE_USE_CASE.key]["viewerOnStage"],
        policy: policy,
        setPolicy: setPolicy,
        roleLayouts: roleLayouts,
        setRoleLayouts: setRoleLayouts,
      });

      setCompositeRecordingForWebinarTemplates({
        str: stepAnswers[steps.SELECT_ADD_ON.key]["recording"],
        policy,
        setPolicy,
      });
      if (isFunction(setLargeRoom)) {
        setLargeRoomForTemplate({
          answer: stepAnswers[steps.SELECT_ADD_ON.key]["largeRoom"],
          setLargeRoom,
        });
      }
    },
  },
};

export default masterTemplateToAnswerConfig;
