import React, { ChangeEvent } from "react";
import { PreviewHeader } from "@100mslive/types-prebuilt/elements/preview_header";
import StatusString from "src/components/Common/StatusString";
import TextArea from "src/components/Common/TextArea";
import { defaultLayout } from "src/data/defaultLayout";
import PrebuiltSidebarItemWrapper from "src/pages/Prebuilt/PrebuiltSidebarItemWrapper";
import { Input } from "@100mslive/react-ui";

type Props = {
  data: PreviewHeader;
  setData: (data: PreviewHeader) => void;
  invalidFields: { [key in keyof PreviewHeader]?: string } | undefined;
};

function PrebuiltPreviewHeaderConfig({ data, setData, invalidFields }: Props) {
  return (
    <>
      <PrebuiltSidebarItemWrapper title="Title" hideTooltip>
        <Input
          css={{ r: "$0", ml: "$space$px", w: "99%" }}
          value={data.title}
          onChange={e => {
            const tempData = { ...data, title: e.target.value };
            setData(tempData);
          }}
        />
        <StatusString content={invalidFields?.title || ""} />
      </PrebuiltSidebarItemWrapper>
      <PrebuiltSidebarItemWrapper title="Subtitle" css={{ mt: 0 }} hideTooltip>
        <TextArea
          rows={3}
          value={data?.sub_title}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            const tempData = { ...data, sub_title: e.target.value };
            setData(tempData);
          }}
        />

        <StatusString content={invalidFields?.sub_title || ""} />
      </PrebuiltSidebarItemWrapper>
    </>
  );
}

export default PrebuiltPreviewHeaderConfig;

const defaultData =
  defaultLayout?.["screens"]?.["preview"]?.["default"]?.["elements"]?.[
    "preview_header"
  ];

export const previewHeaderConfig = {
  ui: {
    title: "Header",
    hasSwitch: false,
    tooltipText: "",
  },
  path: "screens.preview.default.elements.preview_header",
  Component: PrebuiltPreviewHeaderConfig,
  defaultData: defaultData,
  validator: () => {},
};
