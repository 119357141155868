import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { get, isArray, isEmpty, set } from "lodash";
import ConfigSingleSelect from "src/components/Common/ConfigSingleSelect";
import { API_CALL_STATE } from "src/constants";
import { RootState } from "src/store/reducers";
import { Dropdown, Flex, Text } from "@100mslive/react-ui";
import ActivePeersChartHeader from "../components/charts/headers/activePeersChartHeader";
import { TotalPeersUsageChart } from "../components/charts/totalPeersUsageChart";
import { TotalRecordingsUsageChart } from "../components/charts/totalRecordingsUsageChart";
import TitleWithTrend from "../components/common/TitleWithTrend";
import {
  peerGroupByOptions,
  WORKSPACE_RECORDINGS_STATUS_BUCKETS,
  WORKSPACE_RECORDINGS_TYPE_BUCKETS,
} from "../constants";
import { convertDateToDiffDays } from "../helpers";
import {
  getGroupByRecordingsTypeArray,
  groupByActivePeersDuration,
} from "../helpers/convert";
import ChartLoaderLayout from "../layouts/ChartLoaderLayout";

export const WorkspaceUsageInsights = () => {
  const {
    startDate,
    endDate,
    filter,
    fetchWorkspaceRecordingsInsightsStatus,
    workspaceRecordingsInsightsDetails,
    fetchWorkspacePeerInsightsStatus,
    workspacePeerInsightsTrends,
    workspacePeerInsightsDetails,
    workspaceRecordingsTATInsightsDetails,
    workspaceRecordingsInsightsTrends,
  } = useSelector((state: RootState) => state.insights);
  const range = convertDateToDiffDays({
    startDate: startDate || (new Date() as Date),
    endDate: endDate || (new Date() as Date),
  });
  const [groupByForPeers, setGroupByForPeers] = useState("platform");
  const [groupByRecordingsType, setGroupByRecordingsType] = useState(
    WORKSPACE_RECORDINGS_TYPE_BUCKETS.all.key
  );
  const peerData = groupByActivePeersDuration({
    workspacePeerInsightsDetails: workspacePeerInsightsDetails,
    groupBy: groupByForPeers,
  });
  const recordingTypeDataMap = getGroupByRecordingsTypeArray({
    groupByRecordingsType: groupByRecordingsType,
    workspaceRecordingsInsightsDetails: workspaceRecordingsInsightsDetails,
    workspaceRecordingsTATInsightsDetails:
      workspaceRecordingsTATInsightsDetails,
  });

  const getGroupByRecordingsStatusUsageArray = useCallback(() => {
    let data = {} as Record<string, Record<string, number>>;
    const recordings = recordingTypeDataMap.recordings;
    const timeKeys = Object.keys(recordings);
    for (let i = 0; i < timeKeys.length; i++) {
      const arr = get(recordings, timeKeys[i]);
      const successArr = arr.filter(
        val =>
          val.event_type === WORKSPACE_RECORDINGS_STATUS_BUCKETS.success.key
      );
      const failArr = arr.filter(
        val => val.event_type === WORKSPACE_RECORDINGS_STATUS_BUCKETS.fail.key
      );

      const all = arr.reduce((acc, val) => {
        const totalCount = val?.total_cnt || 0;
        const duration = val?.avg_duration_min || 0;
        const totalDuration = totalCount * duration;
        return acc + totalDuration;
      }, 0);
      const success = successArr.reduce((acc, val) => {
        const totalCount = val?.total_cnt || 0;
        const duration = val?.avg_duration_min || 0;
        const totalDuration = totalCount * duration;
        return acc + totalDuration;
      }, 0);
      const fail = failArr.reduce((acc, val) => {
        const totalCount = val?.total_cnt || 0;
        const duration = val?.avg_duration_min || 0;
        const totalDuration = totalCount * duration;
        return acc + totalDuration;
      }, 0);
      data = set(data, ["all", timeKeys[i]], all);
      data = set(data, ["success", timeKeys[i]], success);
      data = set(data, ["fail", timeKeys[i]], fail);
    }

    return data;
  }, [recordingTypeDataMap.recordings]);
  return (
    <Flex direction="column" css={{ w: "100%" }}>
      <Flex
        css={{
          w: "100%",
          gap: "$10",
        }}
        direction="column"
      >
        <Flex direction="column">
          <ChartLoaderLayout
            status={fetchWorkspacePeerInsightsStatus}
            header={
              <ActivePeersChartHeader
                totalLabel="mins"
                groupBy={groupByForPeers}
                setGroupBy={(groupBy: string) => {
                  setGroupByForPeers(groupBy);
                }}
                groupByOptions={Object.keys(peerGroupByOptions)}
                total={workspacePeerInsightsTrends.curr_usage_total}
                trend={workspacePeerInsightsTrends.usage_change}
                makeTotalOpaque={
                  fetchWorkspacePeerInsightsStatus === API_CALL_STATE.DONE
                }
                tooltip={`The following number shows the total usage minutes by participants in the last ${range}, along with a comparison across platforms used to join sessions. The graph displays the ${filter} usage distribution and trend, split by platform or role.`}
                title="Total Participant Minutes"
              />
            }
            isDataEmpty={isEmpty(peerData)}
            title="Total Participant Minutes"
          >
            <TotalPeersUsageChart
              data={peerData}
              loading={
                fetchWorkspacePeerInsightsStatus === API_CALL_STATE.IN_PROGRESS
              }
              filter={filter}
              groupBy={groupByForPeers}
            />
          </ChartLoaderLayout>
        </Flex>
        <ChartLoaderLayout
          status={fetchWorkspaceRecordingsInsightsStatus}
          header={
            <Flex align="center" justify="between" css={{ w: "100%" }}>
              <TitleWithTrend
                totalLabel="mins"
                title="Total Recording Minutes"
                tooltip={`The following number shows the total recording minutes in the last ${range}, along with a percentage comparison to the previous period. The graph displays the ${filter} distribution of recording minutes, split between completed and failed recordings.`}
                total={workspaceRecordingsInsightsTrends?.curr_usage || 0}
                trend={
                  workspaceRecordingsInsightsTrends?.usage_percent_change || 0
                }
                makeTotalOpaque={
                  fetchWorkspaceRecordingsInsightsStatus === API_CALL_STATE.DONE
                }
              />
              <Flex align="center">
                <Text
                  variant="caption"
                  css={{ mr: "$4", fontWeight: "$regular", c: "$textDisabled" }}
                >
                  Group By
                </Text>
                <ConfigSingleSelect
                  inputText={
                    WORKSPACE_RECORDINGS_TYPE_BUCKETS[
                      groupByRecordingsType as keyof typeof WORKSPACE_RECORDINGS_TYPE_BUCKETS
                    ].name || "Please select Group By"
                  }
                  inputTextCss={{
                    fontWeight: "$regular",
                    fontSize: "$xs",
                    width: "180px",
                    textAlign: "left",
                    mr: "$2",
                  }}
                  content={
                    <>
                      {Object.keys(WORKSPACE_RECORDINGS_TYPE_BUCKETS).map(
                        (option: string) => (
                          <Dropdown.Item
                            key={option}
                            css={{ p: "$4 $8" }}
                            onClick={() => {
                              setGroupByRecordingsType(option);
                            }}
                          >
                            <Text variant="caption">
                              {
                                WORKSPACE_RECORDINGS_TYPE_BUCKETS[
                                  option as keyof typeof WORKSPACE_RECORDINGS_TYPE_BUCKETS
                                ].name
                              }
                            </Text>
                          </Dropdown.Item>
                        )
                      )}
                    </>
                  }
                />
              </Flex>
            </Flex>
          }
          isDataEmpty={
            !isArray(workspaceRecordingsInsightsDetails) ||
            workspaceRecordingsInsightsDetails.length === 0
          }
          title="Total Recording Minutes"
        >
          <Flex direction="column">
            <TotalRecordingsUsageChart
              data={getGroupByRecordingsStatusUsageArray()}
              loading={
                fetchWorkspaceRecordingsInsightsStatus ===
                API_CALL_STATE.IN_PROGRESS
              }
              filter={filter}
            />
          </Flex>
        </ChartLoaderLayout>
      </Flex>
    </Flex>
  );
};

export default WorkspaceUsageInsights;
