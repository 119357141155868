import React, { useEffect } from "react";
import { useGoogleLogout } from "react-google-login";
import { useDispatch } from "react-redux";
import { createAction } from "redux-actions";
import { AppAnalytics } from "../../helpers/analytics_helper";
import { postLogout } from "../../helpers/backend_helper";
import { currentUser } from "../../utils";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const logoutAction = createAction("LOGOUT");

function Logout() {
  const user = currentUser();
  const dispatch = useDispatch();
  if (window.devrev_widget) {
    window.devrev_widget.destroy();
    window.devrev_widget = null;
  }

  useEffect(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      AppAnalytics.track("logout");
    }
  }, [user]);
  return user && user.admin ? <AdminLogout /> : <DashboardLogout />;
}

function DashboardLogout() {
  useEffect(() => {
    postLogout();
  }, []);
  return null;
}

function AdminLogout() {
  const { signOut, loaded } = useGoogleLogout({
    clientId: clientId,
    onLogoutSuccess: () => {
      postLogout();
    },
    onFailure: () => {
      console.error("Google Logout Failure");
    },
    onLoadFailure: () => {
      postLogout();
    },
  });

  useEffect(() => {
    if (loaded) {
      signOut();
    }
  }, [signOut, loaded]);

  return null;
}

export default Logout;
