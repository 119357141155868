// availity-reactstrap-validation
import React, { Component } from "react";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Alert, Col, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import axios from "axios";
// action
import classNames from "classnames";
import { Box, Input, Text } from "@100mslive/react-ui";
import AuthForm from "../../components/AuthForm";
import PasswordInput from "../../components/PasswordInput/index";
import { AppAnalytics } from "../../helpers/analytics_helper";
import { checkPasswordPolicy } from "../../helpers/utils";
import { userForgetPassword } from "../../store/actions";

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      validPassword: false,
      msg: "",
      passwordResetDone: false,
      invalidUrl: false,
      firstInput: "",
      secondInput: "",
      error: false,
    };

    this.setValidPassword = this.setValidPassword.bind(this);
    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  setValidPassword(state = false) {
    this.setState({ ...this.state, validPassword: state });
  }

  // handleValidSubmit
  handleValidSubmit() {
    // perform all neccassary validations
    const { isPasswordValid } = checkPasswordPolicy(this.state.firstInput, "");
    if (!isPasswordValid) {
      return false;
    }
    if (this.state.secondInput === this.state.firstInput) {
      const code = new URLSearchParams(this.props.location.search).get("code");
      const email = new URLSearchParams(this.props.location.search).get(
        "email"
      );
      if (email && this.state.secondInput && code) {
        axios.create({
          baseURL: process.env.REACT_APP_BACKEND_API,
          timeout: 2000,
        });
        var params = {
          email: email,
          code: code,
          password: this.state.secondInput,
        };
        const url = `${process.env.REACT_APP_BACKEND_API}reset-password`;
        const headers = {
          "Content-Type": "application/json",
          Cache: "no-cache",
        };
        axios
          .post(url, params, headers)
          .then(response => {
            if (response.status === 200) {
              AppAnalytics.track("forget_password.success", {
                email: response.data.email,
                data_account_id: response.data.customer_id,
                internal_user_id: response.data.id,
                api_version: response.data.api_version,
              });
              this.setState({
                success: response.data.success,
                msg: "Your password has been reset successfully. You can now log in with new password",
                passwordResetDone: true,
              });
            } else {
              AppAnalytics.track("forget_password.failure", { email });
              this.setState({
                success: false,
                msg: "something went wrong",
              });
            }
          })
          .catch(err => {
            this.setState({
              success: false,
              msg: err,
            });
          });
      }
    }
  }

  componentWillMount() {
    const code = new URLSearchParams(this.props.location.search).get("code");
    const email = new URLSearchParams(this.props.location.search).get("email");
    if (email && code) {
      axios.create({
        baseURL: process.env.REACT_APP_BACKEND_API,
        timeout: 2000,
      });
      var params = {
        email: email,
        code: code,
      };
      const url = `${process.env.REACT_APP_BACKEND_API}verify-code`;
      const headers = { "Content-Type": "application/json", Cache: "no-cache" };
      axios
        .post(url, params, headers)
        .then(response => {
          if (response.status === 200) {
            if (response.data.success) {
              this.setState({
                success: response.data.success,
                msg: response.data.msg,
                email: response.data.email,
              });
            } else {
              if (response.data.msg === "Invalid activation code") {
                this.setState({
                  success: false,
                  invalidUrl: true,
                  msg: "Invalid Code",
                });
              } else {
                this.setState({
                  success: response.data.success,
                  msg: response.data.msg,
                  email: response.data.email,
                });
              }
            }
          } else {
            this.setState({
              success: false,
              msg: "Something went wrong",
              email: email,
            });
          }
        })
        .catch(err => {
          this.setState({
            success: false,
            msg: err,
            email: email,
          });
        });
    } else {
      this.setState({
        success: false,
        invalidUrl: true,
        msg: "Invalid URL",
      });
    }
  }
  // eslint-disable-next-line complexity
  render() {
    const { success, msg } = this.state;
    return (
      <AuthForm
        title={
          this.state.passwordResetDone
            ? "Password reset successfully!"
            : "Welcome back!"
        }
        subtitle={
          this.state.passwordResetDone
            ? "Sign in to continue to 100ms."
            : "Reset the password for your 100ms account."
        }
        footer={
          !this.state.passwordResetDone && (
            <Link to="login" className="font-weight-medium text-primary">
              Back to Login
            </Link>
          )
        }
        className="mt-8 flex justify-center"
      >
        {!this.state.passwordResetDone ? (
          <Box>
            {msg ? (
              success ? (
                <Alert color="success" style={{ marginTop: "13px" }}>
                  {msg}
                </Alert>
              ) : (
                <Alert color="danger" style={{ marginTop: "13px" }}>
                  {msg}
                </Alert>
              )
            ) : null}

            {!this.state.invalidUrl ? (
              <AvForm
                className="form-horizontal mt-4"
                onValidSubmit={this.handleValidSubmit}
              >
                <Box className="form-group">
                  <PasswordInput
                    placeholder="Enter new password"
                    firstName=""
                    mainFunction={e =>
                      this.setState({
                        firstInput: e.target.value,
                      })
                    }
                    setValidPassword={this.setValidPassword}
                  />
                </Box>
                <Box className="form-group">
                  <Input
                    className="form-control"
                    css={{
                      backgroundColor: "$secondaryDark !important",
                      border: "none !important",
                      "&:focus": {
                        outline: "2px solid $primaryLight !important",
                      },
                    }}
                    placeholder="Confirm new password"
                    type="password"
                    value={this.state.secondInput}
                    onChange={e => {
                      this.setState({
                        secondInput: e.target.value,
                      });
                      if (e.target.value !== this.state.firstInput) {
                        this.setState({ error: true });
                      } else {
                        this.setState({ error: false });
                      }
                    }}
                  />

                  <Text
                    variant="caption"
                    css={{
                      color: "$error",
                      marginTop: "$3",
                      opacity: this.state.error ? "1" : "0",
                    }}
                  >
                    The passwords do not match
                  </Text>
                </Box>
                <Row className="form-group">
                  <Col className="text-right mt-2">
                    <button
                      className={classNames({
                        "btn btn-primary btn-block waves-effect waves-light": true,
                        "cursor-default": !this.state.validPassword,
                      })}
                      type="submit"
                      disabled={
                        !(
                          this.state.firstInput &&
                          this.state.firstInput === this.state.secondInput &&
                          this.state.validPassword
                        )
                      }
                    >
                      Reset password
                    </button>
                  </Col>
                </Row>
              </AvForm>
            ) : null}
          </Box>
        ) : (
          <Box css={{ padding: "$2" }}>
            <p>{msg}</p>
            <Box css={{ marginTop: "$8" }}>
              <Link
                style={{ width: "100%" }}
                className="btn btn-primary waves-effect waves-light"
                to="login"
                role="button"
              >
                Log In
              </Link>
            </Box>
          </Box>
        )}
      </AuthForm>
    );
  }
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ResetPasswordPage)
);
