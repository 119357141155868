import React, { Component } from "react";
import queryString from "query-string";
import toastr from "src/components/Common/toastr";
import { Button, Text } from "@100mslive/react-ui";
import api from "../../api";
import verifyImg from "../../assets/images/verify-email.gif";
import verifyImgMin from "../../assets/images/verify-email-min.gif";
import AuthForm from "../../components/AuthForm";
import { AppAnalytics } from "../../helpers/analytics_helper";
import { postResendEmail } from "../../helpers/backend_helper";
import history from "../../helpers/history";
import { getAPIURL, getRegisteredUser } from "../../utils";
class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterLink: null,
    };
  }

  async componentDidMount() {
    const email = getRegisteredUser()?.email;
    const domain = email?.split("@")?.[1];
    let emailProvider;
    if (domain) {
      try {
        emailProvider = (
          await api
            .service("dashboard")
            .get(getAPIURL("mail-provider"), { domain })
        ).data;
      } catch {}
    }
    this.setState({
      filterLink:
        emailProvider?.filterLink ||
        "https://mail.google.com/mail/#search/from%3A(%40100ms.live)+in%3Aanywhere+newer_than%3A1h",
    });
  }

  sendEmail() {
    const email = getRegisteredUser()?.email;
    if (!email) {
      toastr.warning("Please register with email ID to trigger email resend.");
      return;
    }
    AppAnalytics.track("activation.email.resend.clicked", {
      email,
    });
    postResendEmail({
      email,
    })
      .then(responseData => {
        toastr.success(responseData.msg);
      })
      .catch(err => {
        console.error(`Failed to resend email: ${err}`);
        toastr.error("Failed to resend email.");
      });
  }

  render() {
    return (
      <AuthForm
        title="Thanks for signing up on 100ms"
        footer={
          <>
            <Button
              onClick={() => {
                window.location.href = this.state.filterLink;
              }}
              loading={!this.state.filterLink}
              className="btn bg-transparent border-solid border-2 border-border-light btn-block waves-light font-weight-medium"
            >
              Open Mail
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                const searchParams = queryString.parse(window.location.search);
                if (searchParams["redirect_url"]) {
                  window.location.href = searchParams["redirect_url"];
                } else {
                  history.push(`/login${window.location?.search || ""}`);
                }
              }}
              className="btn btn-primary btn-block waves-light font-weight-medium"
            >
              Continue to Login
            </Button>
            <Text
              variant="body1"
              css={{
                marginTop: "1rem",
                fontWeight: "$regular",
                color: "$textHighEmp",
              }}
            >
              Didn't receive a mail from us?{" "}
              <Text
                inline="true"
                css={{
                  color: "$primaryLight",
                  cursor: "pointer",
                  fontWeight: "$semiBold",
                }}
                onClick={this.sendEmail}
              >
                Resend email
              </Text>
            </Text>
            <Text
              variant="body2"
              css={{
                marginTop: "4rem",
              }}
            >
              If you still haven't received an email,{" "}
              <a
                href={`${process.env.REACT_APP_WEBSITE_URL}/contact`}
                rel="noreferrer"
                target="_blank"
              >
                <Text
                  variant="body2"
                  inline="true"
                  css={{
                    color: "$primaryLight",
                    fontWeight: "$semiBold",
                  }}
                >
                  Contact Us
                </Text>
              </a>
            </Text>
          </>
        }
      >
        <div className="text-center">
          <img
            src={verifyImg}
            alt="Verify Email"
            onError={event => {
              event.target.error = null;
              event.target.src = verifyImgMin;
            }}
          />
          <Text
            variant="body1"
            css={{
              textAlign: "center",
              marginTop: "1rem",
              marginBottom: "2rem",
              borderRadius: "5px",
              fontWeight: "$regular",
              color: "$textMedEmp",
            }}
          >
            We sent a link to your email, please verify your account. Once done,
            click the button below
          </Text>
        </div>
      </AuthForm>
    );
  }
}

export default Welcome;
