import { Layout } from "@100mslive/types-prebuilt";
import { isEmpty } from "lodash";
import {
  getPriorityRoles,
  HLS_VIEWER_ROLE,
  isVisibleRole,
  NEW_HLS_VIEWER_ROLE,
} from "src/helpers";
import { roleTemplatePolicy } from "src/types/policyTypes";

export const getRolesWithoutHLSViewerSettings = ({
  roles,
  roleLayouts,
}: {
  roles: roleTemplatePolicy[];
  roleLayouts: Layout[];
}) => {
  if (isEmpty(roles) || isEmpty(roleLayouts)) {
    return {
      role: [],
      roleLayouts: [],
    };
  }
  const roleLayoutsWithoutHLSViewerSettings = roleLayouts
    .filter(
      role =>
        isVisibleRole(role.role) &&
        isEmpty(role?.screens?.conferencing?.hls_live_streaming) &&
        !isEmpty(role?.screens?.conferencing?.default) &&
        (role.role !== HLS_VIEWER_ROLE || role.role !== NEW_HLS_VIEWER_ROLE)
    )
    .sort((a, b) => getPriorityRoles(a.role, b.role));

  //filter roles using roleLayoutsWithoutHLSViewerSettings to give a list whose role property is same as role.name
  const rolesWithoutHLSViewerSettings = roles
    .filter(
      role =>
        isVisibleRole(role.name) &&
        (role.name !== HLS_VIEWER_ROLE || role.name !== NEW_HLS_VIEWER_ROLE) &&
        roleLayoutsWithoutHLSViewerSettings.some(
          roleLayout => roleLayout.role === role.name
        )
    )
    .sort((a, b) => getPriorityRoles(a.name, b.name));
  return {
    roles: rolesWithoutHLSViewerSettings,
    roleLayouts: roleLayoutsWithoutHLSViewerSettings,
  };
};
