import React from "react";
import { addonConfig } from "src/modules/onboarding/types/form";
import {
  ClosedCaptionIcon,
  CrossCircleIcon,
  CrossIcon,
  DesktopIcon,
  GoLiveIcon,
  LayersIcon,
  MobileIcon,
  MouseClickIcon,
  PeopleAddIcon,
  PeopleIcon,
  RecordIcon,
  RecordOffIcon,
  ShareIcon,
  ViewIcon,
} from "@100mslive/react-icons";
import {
  BINARY_OPTIONS,
  DESTINATIONS_BROADCASTER_ASPECT_RATIO,
  DESTINATIONS_RECORDINGS_START_OPTIONS,
  LIVE_STREAM_DESTINATIONS_VIDEO_ON_DEMAND,
  LIVE_STREAM_RTMP_INGEST_OPTIONS,
  VIEWER_ON_STAGE_OPTIONS,
} from ".";

export const configureLayoutConfig: Record<string, addonConfig[]> = {
  LIVE_STREAM: [
    {
      key: "streamType",
      defaultValue: LIVE_STREAM_RTMP_INGEST_OPTIONS.internal,
      question: "Where will the Broadcaster stream from?",
      filterKeyOfVisibleQuestion: (str: string | number) => {
        if (str === LIVE_STREAM_RTMP_INGEST_OPTIONS.internal) {
          return ["streamType", "broadcasterStream", "viewerOnStage"];
        }
        if (str === LIVE_STREAM_RTMP_INGEST_OPTIONS.external) {
          return ["streamType"];
        }
        return ["streamType", "broadcasterStream", "viewerOnStage"];
      },
      answers: [
        {
          title: LIVE_STREAM_RTMP_INGEST_OPTIONS.internal,
          icon: <GoLiveIcon />,
          infoText:
            "No setup required. Use your existing webcam for an easy way to go live on 100ms through your browser",
          infoLink: "",
        },
        {
          title: LIVE_STREAM_RTMP_INGEST_OPTIONS.external,
          icon: <ShareIcon />,
          infoText:
            "Start sending your video to 100ms from your streaming software (like OBS Studio) to go live",
          infoLink: "",
        },
      ],
    },
    {
      key: "broadcasterStream",
      defaultValue: DESTINATIONS_BROADCASTER_ASPECT_RATIO.desktop["16:9"],
      question: "Where will the Broadcaster stream from?",
      answers: [
        {
          title: DESTINATIONS_BROADCASTER_ASPECT_RATIO.desktop["16:9"],
          icon: <DesktopIcon />,
          infoText: "The UI will be adjusted for a desktop-first experience",
          infoLink: "",
        },
        {
          title: DESTINATIONS_BROADCASTER_ASPECT_RATIO.mobile["9:16"],
          icon: <MobileIcon />,
          infoText: "The UI will be adjusted for a mobile-first experience",
          infoLink: "",
        },
      ],
    },
    {
      key: "viewerOnStage",
      question: "Can the Broadcaster invite Viewers on stage?",
      defaultValue: VIEWER_ON_STAGE_OPTIONS.Yes,
      answers: [
        {
          title: VIEWER_ON_STAGE_OPTIONS.Yes,
          infoText:
            "Broadcaster can temporarily bring viewers on stage to interact",
          infoLink: "",
          icon: <PeopleAddIcon />,
        },
        {
          title: VIEWER_ON_STAGE_OPTIONS.No,
          infoText: "Viewers cannot join the stage to interact",
          infoLink: "",
          icon: <CrossIcon />,
        },
      ],
    },
  ],
  WEBINAR: [
    {
      key: "broadcasterStream",
      defaultValue: DESTINATIONS_BROADCASTER_ASPECT_RATIO.desktop["16:9"],
      question: "Where will the Broadcaster stream from?",
      answers: [
        {
          title: DESTINATIONS_BROADCASTER_ASPECT_RATIO.desktop["16:9"],
          icon: <DesktopIcon />,
          infoText: "The UI will be adjusted for a desktop-first experience",
          infoLink: "",
        },
        {
          title: DESTINATIONS_BROADCASTER_ASPECT_RATIO.mobile["9:16"],
          icon: <MobileIcon />,
          infoText: "The UI will be adjusted for a mobile-first experience",
          infoLink: "",
        },
      ],
    },
    {
      key: "viewerOnStage",
      question: "Can the Broadcaster invite Viewers on stage?",
      defaultValue: VIEWER_ON_STAGE_OPTIONS.Yes,
      answers: [
        {
          title: VIEWER_ON_STAGE_OPTIONS.Yes,
          infoText:
            "Broadcaster can temporarily bring viewers on stage to interact",
          infoLink: "",
          icon: <PeopleAddIcon />,
        },
        {
          title: VIEWER_ON_STAGE_OPTIONS.No,
          infoText: "Viewers cannot join the stage to interact",
          infoLink: "",
          icon: <CrossIcon />,
        },
      ],
    },
  ],
};

export const selectAddOnConfig: Record<string, addonConfig[]> = {
  LIVE_STREAM: [
    {
      key: "liveTranscription",
      question: "Do you require live transcription for your live streams?",
      defaultValue: BINARY_OPTIONS.no,
      answers: [
        {
          title: BINARY_OPTIONS.no,
          icon: <CrossCircleIcon />,
          infoText: "Live transcription will be disabled",
          infoLink: "",
        },
        {
          title: BINARY_OPTIONS.yes,
          icon: <ClosedCaptionIcon />,
          infoText:
            "Live transcription in English will be enabled for all live streams",
          infoLink: "",
        },
      ],
    },
    {
      key: "largeRoom",
      question: "Do you require the template to support large no. of peers?",
      defaultValue: BINARY_OPTIONS.yes,
      answers: [
        {
          title: BINARY_OPTIONS.yes,
          icon: <PeopleIcon />,
          infoText:
            "When enabled, the rooms in this template can support upto 20,000 viewers",

          infoLink: "",
        },
        {
          title: BINARY_OPTIONS.no,
          icon: <CrossIcon />,
          infoLink: "",
          infoText: "",
        },
      ],
    },
    {
      key: "recording",
      question: "What type of Recording do you require?",
      defaultValue: DESTINATIONS_RECORDINGS_START_OPTIONS.AutoStart,
      filterKeyOfVisibleQuestion: (str: string | number) => {
        if (str === DESTINATIONS_RECORDINGS_START_OPTIONS.AutoStart) {
          return ["liveTranscription", "recording", "recordingOnDemand"];
        }
        if (str === DESTINATIONS_RECORDINGS_START_OPTIONS.NoRecording) {
          return ["liveTranscription", "recording"];
        }
        return ["liveTranscription", "recording", "recordingOnDemand"];
      },
      answers: [
        {
          title: DESTINATIONS_RECORDINGS_START_OPTIONS.AutoStart,
          icon: <RecordIcon />,
          val: true,
          infoText:
            "The recording will start automatically when streaming starts. You can configure storage later",
          infoLink: "",
        },
        {
          title: DESTINATIONS_RECORDINGS_START_OPTIONS.NoRecording,
          val: false,
          icon: <RecordOffIcon />,
          infoText: "The stream won’t be recorded",
          infoLink: "",
        },
      ],
    },
    {
      key: "recordingOnDemand",
      question: "How will you be using the Recording?",
      defaultValue: LIVE_STREAM_DESTINATIONS_VIDEO_ON_DEMAND.vod,
      answers: [
        {
          title: LIVE_STREAM_DESTINATIONS_VIDEO_ON_DEMAND.vod,
          icon: <ViewIcon />,
          infoText:
            "The recording will be stored as a .m3u8 which is optimised for re-streaming",
          infoLink: "",
        },
        {
          title: LIVE_STREAM_DESTINATIONS_VIDEO_ON_DEMAND.postProcess,
          icon: <LayersIcon />,
          infoText:
            "The recording will be stored as an mp4 which is optimized for post-processing, editing, and archival",
          infoLink: "",
        },
        {
          title: LIVE_STREAM_DESTINATIONS_VIDEO_ON_DEMAND.both,
          icon: <MobileIcon />,
          infoText: "The recording will be stored as a .m3u8 and mp4",
          infoLink: "",
        },
      ],
    },
  ],
  WEBINAR: [
    {
      key: "recording",
      question: "What type of Recording do you require?",
      defaultValue: DESTINATIONS_RECORDINGS_START_OPTIONS.AutoStart,
      answers: [
        {
          title: DESTINATIONS_RECORDINGS_START_OPTIONS.AutoStart,
          icon: <RecordIcon />,

          val: true,
          infoText:
            "The recording will start automatically when streaming starts. You can configure storage later",
          infoLink: "",
        },
        {
          title: DESTINATIONS_RECORDINGS_START_OPTIONS.Manual,
          val: false,
          icon: <MouseClickIcon />,
          infoText:
            "Recording will have to be started manually when streaming starts",
          infoLink: "",
        },
        {
          title: DESTINATIONS_RECORDINGS_START_OPTIONS.NoRecording,
          val: false,
          icon: <RecordOffIcon />,
          infoText: "The stream won’t be recorded",
          infoLink: "",
        },
      ],
    },
    {
      key: "largeRoom",
      question: "Do you require the template to support large no. of peers?",
      defaultValue: BINARY_OPTIONS.yes,
      answers: [
        {
          title: BINARY_OPTIONS.yes,
          icon: <PeopleIcon />,
          infoText:
            "When enabled, the rooms in this template can support upto 20,000 viewers",
          infoLink: "",
        },
        {
          title: BINARY_OPTIONS.no,
          icon: <CrossIcon />,
          infoLink: "",
          infoText: "",
        },
      ],
    },
  ],
};
