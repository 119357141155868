import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddressElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PersonIcon } from "@100mslive/react-icons";
import { Button } from "@100mslive/react-ui";
import StripeWrapper from "./StripeWrapper";
import { API_CALL_STATE } from "../../constants";
import { updateCustomerDetails } from "../../store/actions";
import { MainDialog } from "..";

function Component({ open, setOpen }) {
  const dispatch = useDispatch();
  const { status, customerDetails } = useSelector(state => state.organisation);
  const { address, customer_name } = customerDetails;
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(true);

  useEffect(() => {
    if (status.updateCustomerDetails === API_CALL_STATE.DONE) {
      setOpen(false);
    }
  }, [setOpen, status.updateCustomerDetails]);

  const getAddressData = useCallback(async () => {
    if (!stripe || !elements) {
      return {};
    }
    return elements.getElement("address").getValue();
  }, [elements, stripe]);

  const validate = useCallback(async () => {
    const { complete } = await getAddressData();
    setError(!complete);
  }, [getAddressData]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      const { value: data } = await getAddressData();
      const { address, name } = data;
      dispatch(
        updateCustomerDetails({
          customer_name: name,
          address: {
            city: address.city,
            line1: address.line1,
            line2: address.line2,
            country: address.country,
            state: address.state,
            zipCode: address.postal_code,
          },
        })
      );
    },
    [dispatch, getAddressData]
  );

  return (
    <MainDialog
      title="Billing Information"
      open={open}
      onOpenChange={() => setOpen(!open)}
      variant="primary"
      Icon={<PersonIcon />}
      disableButton={false}
      subtitle={
        <form onSubmit={handleSubmit}>
          <AddressElement
            onChange={validate}
            options={{
              mode: "billing",
              defaultValues: {
                name: customer_name || "My Company Inc",
                address: {
                  line1: address.line1 || "Address Line 1",
                  line2: address.line2,
                  city: address.city || "City",
                  state: address.state || "CA",
                  postal_code: address.zipCode || "94080",
                  country: address.country || "US",
                },
              },
              display: {
                name: "organization",
              },
            }}
          />
          <Button
            variant="primary"
            disabled={!stripe || !elements || error}
            loading={
              status.updateCustomerDetails === API_CALL_STATE.IN_PROGRESS
            }
            type="submit"
            css={{
              mt: "$8",
              w: "100%",
              "&:not(:hover)": { bg: "$primaryDefault !important" },
            }}
          >
            Save Information
          </Button>
        </form>
      }
      fullWidth={true}
      content={<></>}
      mainFunction={() => {
        return;
      }}
      width="540px"
    />
  );
}

function BillingInfoModal(props) {
  return (
    <StripeWrapper>
      <Component {...props} />
    </StripeWrapper>
  );
}

export default BillingInfoModal;
