import {
  ConferencingScreen,
  Layout,
  LeaveScreen,
  PreviewScreen,
  Screens,
} from "@100mslive/types-prebuilt";
import { Chat } from "@100mslive/types-prebuilt/elements/chat";
import { JoinForm } from "@100mslive/types-prebuilt/elements/join_form";
import { OnStageExperience } from "@100mslive/types-prebuilt/elements/on_stage_experience";
import { PreviewHeader } from "@100mslive/types-prebuilt/elements/preview_header";
import { VirtualBackground } from "@100mslive/types-prebuilt/elements/virtual_background";
import { get, isEmpty, set } from "lodash";
import { isValidImageUrl } from "src/utils";
import {
  validateJoinForm,
  validateOnStageExperience,
  validateVirtualBackground,
} from "./screens/validator/previewScreenValidations";
import { validateChat } from "./screens/validator/roomScreenValidations";

const getLayoutValidationObject = ({
  screenKeys,
}: {
  screenKeys: Record<
    keyof Screens,
    keyof PreviewScreen | keyof ConferencingScreen | keyof LeaveScreen
  >;
}) => {
  let layoutValidationObject = {
    "logo.url": [
      (str: string) => {
        return !str
          ? ""
          : isValidImageUrl(str)
          ? ""
          : "Please enter a valid Image URL";
      },
    ],
    "screens.preview.default.elements.join_form": [
      (join_form: JoinForm) => {
        const validationResult = validateJoinForm(join_form);
        return validationResult.invalidFields;
      },
    ],
    "screens.preview.default.elements.virtual_background": [
      (virtual_background: VirtualBackground) => {
        const validationResult = validateVirtualBackground(virtual_background);
        return validationResult.invalidFields;
      },
    ],
    "screens.conferencing.default.elements.virtual_background": [
      (virtual_background: VirtualBackground) => {
        const validationResult = validateVirtualBackground(virtual_background);
        return validationResult.invalidFields;
      },
    ],
    "screens.conferencing.default.elements.on_stage_exp": [
      (on_stage_exp: OnStageExperience) => {
        if (isEmpty(on_stage_exp)) {
          return {};
        }
        const validationResult = validateOnStageExperience(on_stage_exp);
        return validationResult.invalidFields;
      },
    ],
    "screens.conferencing.default.elements.chat": [
      (chat: Chat) => {
        const validationResult = validateChat(chat);
        return validationResult.invalidFields;
      },
    ],
  } as Record<
    string,
    ((
      val: string | JoinForm | PreviewHeader
    ) => string | Record<string, string>)[]
  >;
  if (screenKeys["conferencing"] === "hls_live_streaming") {
    const tempObject = { ...layoutValidationObject };
    const tempKeys = Object.keys(layoutValidationObject);
    const newTempKeys = tempKeys.map(r => {
      return r.replaceAll(
        "conferencing.default",
        "conferencing.hls_live_streaming"
      );
    });

    for (let i = 0; i < tempKeys.length; i++) {
      if (newTempKeys[i].includes("on_stage_exp")) {
        delete tempObject[tempKeys[i]];
        continue;
      }
      tempObject[newTempKeys[i]] = [...tempObject[tempKeys[i]]];
      delete tempObject[tempKeys[i]];
    }
    layoutValidationObject = {
      ...tempObject,
    };
  }

  return layoutValidationObject;
};

export const prebuiltValidation = ({
  layout,
  screenKeys,
}: {
  layout: Layout;
  screenKeys: Record<
    keyof Screens,
    keyof PreviewScreen | keyof ConferencingScreen | keyof LeaveScreen
  >;
}) => {
  const invalidFields = {} as { [key: string]: string };
  const layoutValidatonObject = getLayoutValidationObject({
    screenKeys: screenKeys,
  });
  for (const path in layoutValidatonObject) {
    const validationFunctions = layoutValidatonObject[path];
    for (const validationFunction of validationFunctions) {
      const val = get(layout, path);
      const error = validationFunction(val);
      if (!isEmpty(error)) {
        set(invalidFields, path, error);
      }
    }
  }
  return invalidFields;
};
