import React, { FC, ReactElement, useState } from "react";
import Modal from "src/components/Modal/Modal";
import { Box, Button, Flex, Text } from "@100mslive/react-ui";

interface DeleteItemProps {
  // eslint-disable-next-line
  title: ReactElement<any, any>;
  itemType: string;
  hidePrimaryCTA?: boolean;
  hideInnerContent?: boolean;
  onClick: () => unknown;
  loading: boolean;
}

const DeleteItem: FC<DeleteItemProps> = ({
  title,
  onClick,
  hideInnerContent = false,
  hidePrimaryCTA = false,
  itemType = "Role",
  loading,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  return (
    <>
      <Modal
        show={showDeleteModal}
        hidePrimaryCTA={hidePrimaryCTA}
        hideInnerContent={hideInnerContent}
        onBackdropClick={() => setShowDeleteModal(false)}
        onDeleteClick={() => {
          onClick();
          setShowDeleteModal(false);
        }}
        title={title}
      />
      <Box
        css={{
          border: "1px solid $error",
          position: "relative",
          borderRadius: "$1",
          w: "100%",
        }}
      >
        <Flex
          justify="between"
          align="center"
          css={{ w: "100%", p: "$12", position: "relative", zIndex: "5" }}
        >
          <Box>
            <Text variant="h6" css={{ color: "$textHighEmp" }}>
              Delete {itemType}
            </Text>
            <Text variant="sm" css={{ color: "$textMedEmp", mt: "$4" }}>
              This action cannot be undone
            </Text>
          </Box>

          <Button
            onClick={() => setShowDeleteModal(true)}
            variant="danger"
            loading={loading}
            css={{
              fontSize: "$sm",
              fontWeight: "$semiBold",
              p: "$3 $md",
              h: "100%",
              r: "$0",
            }}
          >
            <Text variant="sub2">Delete</Text>
          </Button>
        </Flex>
        <Box
          css={{
            position: "absolute",
            top: "0",
            left: "0",
            h: "100%",
            w: "100%",
            zIndex: "0",
            backgroundColor: "$error",
            opacity: "0.1",
          }}
        />
      </Box>
    </>
  );
};

export default DeleteItem;
