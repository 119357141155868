import React, { Dispatch, FC, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHasUnsavedLiveStreamingChanges,
  toggleHLSDestination,
} from "src/actions/RolesActions";
import TemplateConfigCard from "src/components/Cards/TemplateConfigCard";
import ConfigMultiSelect from "src/components/Common/ConfigMultiSelect";
import Line from "src/components/Common/Line";
import StatusString from "src/components/Common/StatusString";
import ValuePill from "src/components/Common/ValuePill";
import HipaaComplianceNotice from "src/components/HipaaComplianceNotice/HipaaComplianceNotice";
import { TEMPLATE_TABS } from "src/constants";
import SwitchWithTitle from "src/pages/Developer/SwitchWithTitle";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { RootState } from "src/store/reducers";
import { hlsDestinationsLayerType } from "src/types/policyTypes";
import { currentWorkspaceHipaa, slugify } from "src/utils";
import { ArrowRightIcon } from "@100mslive/react-icons";
import { Box, Flex, Text } from "@100mslive/react-ui";
import StreamRecording from "./StreamRecording";
import StreamVideoOutput from "./StreamVideoOutput";

export const ConfigInputCSS = {
  r: "$1",
  w: "100%",
  mt: "$4",
  bg: "$surfaceLight",
  borderRadius: "$0",
};

interface HLSProps {
  changeHLSDestination: (
    destinationId: string,
    path: string,
    value: string | boolean | number[] | number | hlsDestinationsLayerType[]
  ) => void;
  subscribeToClicked: (roleName: string) => void;
  enabledAutoStartRecording: boolean;
  internalRecorderSubscriptionList: string[];
  internalRecorderRolesForSubscription: string[];
  setSelected: Dispatch<SetStateAction<string>>;
  isValidInternalRecorderState: boolean;
}

// eslint-disable-next-line complexity
const HLS: FC<HLSProps> = ({
  changeHLSDestination,
  subscribeToClicked,
  enabledAutoStartRecording,
  internalRecorderSubscriptionList,
  internalRecorderRolesForSubscription,
  setSelected,
  isValidInternalRecorderState,
}) => {
  const dispatch = useDispatch();
  const cardTitle = "Live stream (HLS)";

  const isHipaa = currentWorkspaceHipaa();

  const { policyDerivedStates } = useSelector(
    (state: RootState) => state.roles
  );

  const hlsDestinations = useSelector(
    (state: RootState) =>
      state.roles.policyInfo?.destinations?.hlsDestinations || {}
  );

  const hlsDestinationIds = Object.keys(hlsDestinations) || [];

  return (
    <TemplateConfigCard
      text={cardTitle}
      id={slugify(cardTitle)}
      classNameForText="config-subheading"
      subtitle="Live stream your room over HLS. Define HLS configuration here and start/stop live streaming with the SDK/API."
      bottomButton={
        policyDerivedStates?.hlsDestinationEnabled ? (
          <Box css={{ pb: "$12" }}>
            <StreamVideoOutput changeHLSDestination={changeHLSDestination} />
            <StreamRecording changeHLSDestination={changeHLSDestination} />
          </Box>
        ) : (
          <></>
        )
      }
      rightComponent={
        <Flex direction="column" css={{ minWidth: "100%" }}>
          <SettingsTitle
            key="liveStreaming"
            title="Enable/disable live streaming"
            text="Live Streaming with HLS"
          />
          <SwitchWithTitle
            hideTitle
            disabled={isHipaa || enabledAutoStartRecording}
            tooltipMessage=""
            checkedValue={policyDerivedStates?.hlsDestinationEnabled}
            onCheckedChange={() => {
              dispatch(setHasUnsavedLiveStreamingChanges(true));
              dispatch(
                toggleHLSDestination(!policyDerivedStates.hlsDestinationEnabled)
              );
            }}
          />

          {enabledAutoStartRecording ? (
            <>
              <Text
                variant="caption"
                css={{
                  mt: "$4",
                  color: "$textMedEmp",
                  display: "inline",
                }}
              >
                Live streaming cannot be enabled since auto-start is enabled for
                recordings.
              </Text>
              <span
                onClick={() => setSelected(TEMPLATE_TABS.RECORDING)}
                className="cursor-pointer items-center gap-1 text-xs mt-1 flex text-primary-default hover:text-primary-dark whitespace-nowrap"
              >
                See config <ArrowRightIcon height={14} width={14} />
              </span>
            </>
          ) : null}

          <HipaaComplianceNotice />

          {policyDerivedStates?.hlsDestinationEnabled ? (
            <>
              <Line />
              <SettingsTitle
                key="broadcasterRoles"
                title="Choose roles to be broadcasted over the live stream"
                text="Broadcaster Roles"
              />
              <ConfigMultiSelect
                inputText="Select roles"
                optionsArray={internalRecorderRolesForSubscription}
                onCheckedChange={subscribeToClicked}
                isCheckedCondition={role =>
                  (
                    (internalRecorderSubscriptionList as string[]) || []
                  )?.includes(role)
                }
              />
              <Flex css={{ w: "100%", flexWrap: "wrap" }}>
                {internalRecorderSubscriptionList.map((roleName: string) => (
                  <ValuePill
                    key={roleName}
                    content={roleName}
                    onClick={() => subscribeToClicked(roleName)}
                  />
                ))}
              </Flex>
              <StatusString
                content={
                  isValidInternalRecorderState
                    ? ""
                    : "At least one role must be selected"
                }
              />
              <Line />

              <SettingsTitle
                key="timedMetadata"
                title="Allow attaching metadata to a specific timestamp in the HLS live stream"
                text="Timed Metadata"
              />
              <SwitchWithTitle
                hideTitle
                tooltipMessage=""
                checkedValue={
                  hlsDestinations?.[hlsDestinationIds?.[0]]
                    ?.enableMetadataInsertion
                }
                onCheckedChange={() =>
                  changeHLSDestination(
                    hlsDestinationIds?.[0],
                    "enableMetadataInsertion",
                    !hlsDestinations?.[hlsDestinationIds?.[0]]
                      ?.enableMetadataInsertion
                  )
                }
              />
            </>
          ) : null}
        </Flex>
      }
    />
  );
};

export default HLS;
