import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { userForgetPasswordError, userForgetPasswordSuccess } from "./actions";
// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { AppAnalytics } from "../../../helpers/analytics_helper";
//Include Both Helper File with needed methods
import { postForgetPwd } from "../../../helpers/backend_helper";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user } }) {
  try {
    const response = yield call(postForgetPwd, "forget-pwd", {
      email: user.email,
      captcha_token: user["captcha-token"],
    });
    if (response) {
      if (response.success) {
        yield put(userForgetPasswordSuccess(response.msg));
        //props.history.push('/test');
        AppAnalytics.track("forgot_password_email.sent", { email: user.email });
      } else {
        yield put(userForgetPasswordError(response.msg));
      }
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
