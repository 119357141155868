/* eslint-disable complexity */
import React, { useCallback, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useDispatch, useSelector } from "react-redux";
import {
  Logo,
  Theme,
  Theme_ThemeType,
  Typography,
} from "@100mslive/types-prebuilt";
import classNames from "classnames";
import { isEmpty } from "lodash";
import DashboardButton from "src/components/Common/Button";
import { ComposableDialog } from "src/components/Modal/ComposableDialog";
import Appearance from "src/components/Prebuilt/Appearance";
import Components from "src/components/Prebuilt/Components";
import DefaultMenu from "src/components/Prebuilt/DefaultMenu";
import {
  API_CALL_STATE,
  PREBUILT_SIDEBAR_WIDTH,
  prebuiltFonts,
} from "src/constants";
import { AppAnalytics } from "src/helpers";
import {
  patchAppLayout,
  setHasUnsavedPrebuiltChanges,
  setLayoutScreenMode,
  setLayoutScreenType,
  setLogo,
  setRoleForLayout,
  setTypography,
  updateAppLayoutInStore,
  updateColors,
  updateLayoutThemeType,
} from "src/store/appLayout/actions";
import { RootState } from "src/store/reducers";
import { getPaletteArray, isValidImageUrl } from "src/utils";
import {
  // CodeIcon,
  LayoutIcon,
  PaletteIcon,
  ZapIcon,
} from "@100mslive/react-icons";
import { Box, Flex, Loading, Text } from "@100mslive/react-ui";
import { ColorPalette, RoleLayouts } from "../../types/prebuilt";
import s from "../../pages/Dashboard/scroll.module.scss";

export const menuItems = {
  appearance: {
    title: "Appearance",
    description: "Customise colors and logo",
    icon: <PaletteIcon width={20} height={20} />,
    key: "appearance",
  },
  components: {
    title: "Screens and Components",
    description: "Customise layout, text and more",
    icon: <ZapIcon width={20} height={20} />,
    key: "components",
  },
  // editor: {
  //   title: "JSON Editor",
  //   description: "Make direct edits to the layout",
  //   icon: <CodeIcon width={20} height={20} />,
  //   key: "editor",
  // },
};

const PrebuiltSidebarContent = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = React.useState("default");
  const all_templates = useSelector(
    (state: RootState) => state.roles.all_templates
  );
  const policyInfo = useSelector((state: RootState) => state.roles?.policyInfo);
  const patchAppLayoutStatus = useSelector(
    (state: RootState) => state.appLayouts.patchAppLayoutStatus
  );
  const hasUnsavedPrebuiltChanges = useSelector(
    (state: RootState) => state.appLayouts.hasUnsavedPrebuiltChanges
  );
  const fetchOrCreateAppLayoutStatus = useSelector(
    (state: RootState) => state.appLayouts.fetchAppLayoutStatus
  );

  const fetchPolicyInfoStatus = useSelector(
    (state: RootState) => state.roles.fetchPolicyInfoStatus
  );
  const roleLayouts = useSelector(
    (state: RootState) => state.appLayouts.roleLayouts
  );

  const invalidFields = useSelector(
    (state: RootState) => state.appLayouts.invalidFields
  );
  const roleForLayout = useSelector(
    (state: RootState) => state.appLayouts.roleForLayout
  );
  const screenMode = useSelector(
    (state: RootState) => state.appLayouts.screenMode
  );

  const screenType = useSelector(
    (state: RootState) => state.appLayouts.screenType
  );

  const logoUrl = roleLayouts[roleForLayout]?.logo?.url;
  const themeType = roleLayouts[roleForLayout]?.themes?.[0]?.theme_type;
  const colorPalette = getPaletteArray(
    (roleLayouts[roleForLayout]?.themes as Theme[])?.[0]?.palette,
    themeType
  );
  const fontFamily = roleLayouts[roleForLayout]?.typography?.font_family;
  const [open, setOpen] = useState(false);
  const [layoutJSON, setLayoutJSON] = useState({});
  useEffect(() => {
    if (isEmpty(layoutJSON) && !isEmpty(roleLayouts)) {
      setLayoutJSON(roleLayouts);
    }
  }, [layoutJSON, roleLayouts]);

  const onEditJSON = useCallback(
    (layoutsData: RoleLayouts, fieldName: string) => {
      const invalidFieldNames = ["app_id", "role_id", "template_id", "id"];
      let isNotWritableField = false;
      for (let i = 0; i < invalidFieldNames.length; i++) {
        if (fieldName.includes(invalidFieldNames[i])) {
          isNotWritableField = true;
          break;
        }
      }
      if (!isNotWritableField) {
        dispatch(
          updateAppLayoutInStore({
            roleLayouts: layoutsData,
          })
        );
        setLayoutJSON(layoutsData);
      }
      return;
    },
    [dispatch]
  );

  const setScreenType = (
    screenType: string,
    shouldUpdateConferencingDefaults = false
  ) => {
    dispatch(
      setLayoutScreenType({ screenType, shouldUpdateConferencingDefaults })
    );
  };
  const setScreenMode = (screenMode: string) => {
    dispatch(setLayoutScreenMode({ mode: screenMode }));
  };

  return (
    <>
      <div
        id="sidebar-menu"
        className={classNames(s["scroll"], "mb-32 overflow-y-hidden")}
      >
        <ul className="metismenu list-unstyled " id="side-menu">
          {fetchOrCreateAppLayoutStatus === API_CALL_STATE.IN_PROGRESS ||
          fetchPolicyInfoStatus === API_CALL_STATE.IN_PROGRESS ? (
            <Flex justify="center" align="center" css={{ w: "100%" }}>
              <Loading size={30} />
            </Flex>
          ) : (
            <li
              style={{
                width: PREBUILT_SIDEBAR_WIDTH,
                padding: "0 24px",
              }}
            >
              {activeTab === "default" ? (
                <DefaultMenu
                  toggleEditor={() => setOpen(!open)}
                  fetchPolicyInfoStatus={fetchPolicyInfoStatus}
                  setActiveTab={setActiveTab}
                  menuItems={menuItems}
                  fetchOrCreateAppLayoutStatus={fetchOrCreateAppLayoutStatus}
                  policyId={policyInfo.id}
                />
              ) : null}
              {activeTab === "appearance" ? (
                <Appearance
                  themeType={themeType as Theme_ThemeType}
                  setThemeType={(themeType: Theme_ThemeType) =>
                    dispatch(updateLayoutThemeType(themeType, colorPalette))
                  }
                  invalidFields={invalidFields}
                  policyInfo={policyInfo}
                  fetchPolicyInfoStatus={fetchPolicyInfoStatus}
                  all_templates={all_templates}
                  setActiveTab={setActiveTab}
                  logo={logoUrl || ""}
                  font={fontFamily || prebuiltFonts[0]}
                  colorPalette={colorPalette}
                  setColorPalette={(colorPalette: ColorPalette) => {
                    dispatch(updateColors(colorPalette));
                  }}
                  setFont={(font: string): void => {
                    const typography: Typography = {
                      font_family: font,
                    };
                    dispatch(setTypography(typography));
                  }}
                  setLogo={(logoUrl: string): void => {
                    const logo: Logo = {
                      url: logoUrl,
                    };
                    dispatch(setLogo(logo));
                    if (!isValidImageUrl(logoUrl)) {
                      dispatch(setHasUnsavedPrebuiltChanges(false));
                    }
                  }}
                  title="Appearance"
                  subtitle="Customise the appearance of the UI"
                />
              ) : null}

              {activeTab === "components" ? (
                <Components
                  title="Components"
                  subtitle="Customise the components of screens"
                  role={roleForLayout}
                  setActiveTab={setActiveTab}
                  policyInfo={policyInfo}
                  roleLayouts={roleLayouts}
                  screenMode={screenMode}
                  screenType={screenType}
                  setScreenType={setScreenType}
                  setScreenMode={setScreenMode}
                  setRoleLayouts={({
                    roleLayouts,
                  }: {
                    roleLayouts: RoleLayouts;
                  }) =>
                    dispatch(
                      updateAppLayoutInStore({
                        roleLayouts: roleLayouts,
                      })
                    )
                  }
                  setRole={(role: string) => {
                    dispatch(setRoleForLayout(role));
                  }}
                  invalidFields={invalidFields}
                />
              ) : null}

              {!isEmpty(layoutJSON) ? (
                <ComposableDialog.Root
                  open={open}
                  onOpenChange={val => {
                    setOpen(val);
                  }}
                >
                  <ComposableDialog.Content width="640px">
                    <ComposableDialog.Header
                      title="Edit Layout JSON"
                      Icon={
                        <Box css={{ w: "$12", h: "$12", c: "$primaryLight" }}>
                          <LayoutIcon width={32} height={32} />
                        </Box>
                      }
                      subtitle="Edit your layout settings"
                    />
                    <Flex
                      direction="column"
                      css={{
                        overflowY: "scroll",
                        height: "500px",
                        py: "$4",
                        px: "$10",
                      }}
                    >
                      <ReactJson
                        name="Layout JSON"
                        theme={{
                          base00: "transparent",
                          base01: "#ddd",
                          base02: "#ddd",
                          base03: "#444",
                          base04: "#66A1FF",
                          base05: "#444",
                          base06: "#444",
                          base07: "#F5F9FF",
                          base08: "#444",
                          base09: "#66A1FF",
                          base0A: "#66A1FF",
                          base0B: "#66A1FF",
                          base0C: "#66A1FF",
                          base0D: "#66A1FF",
                          base0E: "#66A1FF",
                          base0F: "#66A1FF",
                        }}
                        style={{
                          width: "100%",
                        }}
                        collapsed={3}
                        src={layoutJSON}
                        collapseStringsAfterLength={80}
                        displayDataTypes={true}
                        displayObjectSize={true}
                        enableClipboard={true}
                        onEdit={(e: any) => {
                          const tempRoleLayouts = e?.updated_src as RoleLayouts;
                          const objPath = (e?.namespace as string[]).reduce(
                            (accumulator, currentValue) => {
                              //path accumulator
                              const number = parseInt(currentValue);
                              //intitialise
                              if (accumulator === "") {
                                if (isNaN(number)) {
                                  //if string make it object append
                                  return `${currentValue}`;
                                } else {
                                  //if number make it array append
                                  return `[${number}]`;
                                }
                              }

                              if (isNaN(number)) {
                                //make object path
                                return `${accumulator}.${currentValue}`;
                              } else {
                                //make array path
                                return `${accumulator}.[${number}]`;
                              }
                            },
                            ""
                          );
                          onEditJSON(tempRoleLayouts, `${objPath}.${e?.name}`);
                        }}
                      />
                    </Flex>
                  </ComposableDialog.Content>
                </ComposableDialog.Root>
              ) : null}
            </li>
          )}
        </ul>
      </div>
      {activeTab !== "default" ? (
        <Flex
          align="center"
          justify={hasUnsavedPrebuiltChanges ? "between" : "end"}
          css={{
            position: "fixed",
            bottom: "0",
            w: PREBUILT_SIDEBAR_WIDTH,
            border: "1px solid $borderDefault",
            gap: "$md",
            py: "$6",
            px: "$10",
            bg: "$surfaceDark",
            zIndex: 20,
          }}
        >
          {hasUnsavedPrebuiltChanges ? (
            <Text variant="xs" css={{ color: "$textMedEmp" }}>
              Unsaved changes
            </Text>
          ) : null}
          <DashboardButton
            //@ts-ignore
            variant="highlight"
            disabled={!hasUnsavedPrebuiltChanges}
            css={{
              borderRadius: "$0",
              p: "$3 $4",
              fontSize: "$sm",
            }}
            loading={patchAppLayoutStatus === API_CALL_STATE.IN_PROGRESS}
            onClick={() => {
              AppAnalytics.track("btn.clicked", {
                btnId: `prebuilt.${activeTab}.button.save`,
                page: "/prebuilt",
                policyId: policyInfo.id,
              });
              dispatch(patchAppLayout({ roleLayouts }));
              dispatch(setHasUnsavedPrebuiltChanges(false));
            }}
          >
            <Text
              variant="sub2"
              css={{ w: "$14", mx: "$4", color: "$textHighEmp" }}
            >
              Save
            </Text>
          </DashboardButton>
        </Flex>
      ) : null}
    </>
  );
};

export default PrebuiltSidebarContent;
