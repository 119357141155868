import React from "react";
import { defaultConferencing } from "src/data/defaultLayout";

function PrebuiltParticipantListConfig() {
  return <></>;
}

export default PrebuiltParticipantListConfig;

const defaultData =
  defaultConferencing?.["default"]?.["elements"]?.["participant_list"];

export const participantListConfig = {
  ui: {
    title: "Participant List",
    hasSwitch: true,
    tooltipText: "",
  },
  path: "screens.conferencing.default.elements.participant_list",
  Component: PrebuiltParticipantListConfig,
  defaultData: defaultData,
};
