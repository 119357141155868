import React, { FC, useState } from "react";
import PresetCard from "src/components/Cards/PresetCard";
import { Box } from "@100mslive/react-ui";

interface UseCaseFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  publicTemplates: any[];
  setTemplateFlow: (templateId: number | null) => void;
}

const UseCaseForm: FC<UseCaseFormProps> = ({
  publicTemplates,
  setTemplateFlow,
}) => {
  const [activeSelection, setActiveSelection] = useState("");

  return (
    <Box
      css={{
        p: "$10",
        height: "calc(100vh - 430px)",
        maxHeight: "504px",
        overflowX: "hidden",
        overflowY: "scroll",
      }}
    >
      <Box
        css={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "$10",
          "@media (max-width: 1280px)": {
            gridTemplateColumns: "1fr 1fr",
          },
        }}
      >
        {publicTemplates.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (template: any) => (
            <PresetCard
              key={template.attributes.name}
              title={template.attributes.name}
              subText={template.attributes.subtitle}
              showRoles={false}
              isActive={template.attributes.name === activeSelection}
              img={
                <img
                  height="48px"
                  alt={template.attributes.name}
                  width="48px"
                  src={template.attributes.icon.data.attributes.url}
                />
              }
              onClick={() => {
                setActiveSelection(template.attributes.name);
                setTemplateFlow(template.id);
              }}
            />
          )
        )}
      </Box>
    </Box>
  );
};

export default UseCaseForm;
