import { isArray, isEmpty, isEqual, set } from "lodash";
import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import { getHLSViewerRolesFromRoleLayouts } from "src/helpers/appLayoutHelper";
import { TRANSCRIPTION_OUTPUT_MODES } from "src/services/policy/constants";
import policyTemplate from "src/store/policyTemplate";
import {
  combinedAppDataAndTemplateType,
  hlsDestinationsType,
  policyTemplateType,
  RecordingConfigType,
  roleTemplatePolicy,
  roleType,
  TranscriptionSummaryType,
  TranscriptionType,
} from "src/types/policyTypes";
import { RoleLayouts } from "src/types/prebuilt";
import {
  hlsDestinationsInvalidFieldsType,
  pluginsInvalidFieldsType,
  recordingInvalidFieldsType,
  recordingsConfigInvalidFieldsType,
  rtmpDestinationsInvalidFieldsType,
  transcriptionsInvalidFieldsType,
} from "src/types/validations/destinations";
import { currentWorkspaceHipaa } from "src/utils";
import { v4 as uuid4 } from "uuid";
import {
  ADD_INTERNAL_RECORDER,
  ADD_ROLES_DONE,
  ADD_ROLES_FAIL,
  ADD_ROLES_INIT,
  ADD_SUBDOMAIN_TO_API_TEMPLATE_DONE,
  ADD_SUBDOMAIN_TO_API_TEMPLATE_FAIL,
  ADD_SUBDOMAIN_TO_API_TEMPLATE_INIT,
  CHECK_SUBDOMAIN_DONE,
  CHECK_SUBDOMAIN_FAIL,
  CHECK_SUBDOMAIN_INIT,
  CHECK_SUBDOMAIN_RESET,
  CLEAR_VALIDATIONS_RESULTS,
  DELETE_POLICY_INFO_DONE,
  DELETE_POLICY_INFO_FAIL,
  DELETE_POLICY_INFO_INIT,
  DELETE_POLICY_INFO_RESET,
  DELETE_ROLES_DONE,
  DELETE_ROLES_FAIL,
  DELETE_ROLES_INIT,
  DISABLE_BROWSER_COMPOSITE,
  DISABLE_HLS_DESTINATION,
  DISABLE_STREAM_RECORDING,
  DISABLE_TRACK_RECORDING,
  DISABLE_TRANSCRIPTION,
  ENABLE_BROWSER_COMPOSITE,
  ENABLE_HLS_DESTINATION,
  ENABLE_STREAM_RECORDING,
  ENABLE_TRACK_RECORDING,
  ENABLE_TRANSCRIPTION,
  FETCH_ALL_TEMPLATES_DONE,
  FETCH_ALL_TEMPLATES_FAIL,
  FETCH_ALL_TEMPLATES_INIT,
  FETCH_POLICY_INFO_DONE,
  FETCH_POLICY_INFO_INIT,
  PATCH_POLICY_INFO,
  PATCH_ROLE_IN_STORE,
  PATCH_ROLES_IN_STORE,
  PUT_DESTINATIONS,
  REPLACE_ROLES_ONBOARDING,
  REQUESTS_COMPLETE,
  ROLES_REQUEST_DONE,
  ROLES_REQUEST_FAIL,
  ROLES_REQUEST_INIT,
  SET_ACTIVE_ROLE,
  SET_HLS_DESTINATION_ENABLE_FLAG,
  SET_HLS_RECORDING,
  SET_RTMP_DESTINATIONS,
  SET_TRANSCRIPTION_ENABLE_FLAG,
  UNSAVED_ADVANCED_SETTINGS_FLAG,
  UNSAVED_LIVE_STREAMING_FLAG,
  UNSAVED_RECORDINGS_FLAG,
  UNSAVED_ROLES_FLAG,
  UNSAVED_TRANSCRIPTION_FLAG,
  UPDATE_DESTINATION_DONE,
  UPDATE_DESTINATION_FAIL,
  UPDATE_DESTINATION_INIT,
  UPDATE_HLS_DESTINATIONS,
  UPDATE_PLUGINS_INFO,
  UPDATE_POLICY_INFO_DONE,
  UPDATE_POLICY_INFO_FAIL,
  UPDATE_POLICY_INFO_INIT,
  UPDATE_POLICY_ROLE,
  UPDATE_RECORDING_INFO,
  UPDATE_RECORDINGS_CONFIG,
  UPDATE_ROLES_DONE,
  UPDATE_ROLES_FAIL,
  UPDATE_ROLES_INIT,
  UPDATE_RTMP_DESTINATIONS,
  UPDATE_TEMPLATE_DONE,
  UPDATE_TEMPLATE_FAIL,
  UPDATE_TEMPLATE_INIT,
  UPDATE_TEMPLATE_NAME_DONE,
  UPDATE_TEMPLATE_NAME_FAIL,
  UPDATE_TEMPLATE_NAME_INIT,
  UPDATE_TEMPLATE_NAME_RESET,
  UPDATE_TRANSCRIPTION,
  VALIDATE_HLS_DESTINATIONS,
  VALIDATE_PLUGINS_INFO,
  VALIDATE_RECORDING_INFO,
  VALIDATE_RECORDINGS_CONFIG,
  VALIDATE_RTMP_DESTINATIONS,
  VALIDATE_TRANSCRIPTION,
} from "../actions/RolesActions";
import {
  API_CALL_STATE,
  recordingObjectReceivedWhenDisabled,
  transcriptionModes,
} from "../constants";
import {
  getVisibleRoles,
  HLS_VIEWER_ROLE,
  INTERNAL_RECORDER_ROLE,
  NEW_HLS_VIEWER_ROLE,
} from "../helpers";
import {
  getHlsViewerPolicyInfoRole,
  getInternalRecoderPolicyInfoRole,
} from "../store/roleTemplate";
import {
  getMergedDestinationPolicy,
  getMergedRtmpPolicy,
  getMergedTranscriptionPolicy,
} from "../validations/destinations";

const initialState = {
  policy: "",
  policyId: "",
  activeRole: "",
  fetchRolesStatus: "",
  fetchPolicyInfoStatus: "",
  addSubdomainToApiTemplateStatus: "",
  updateRolesStatus: "",
  addRoleStatus: "",
  deleteRoleStatus: "",
  updateTemplateNameStatus: "",
  roles: [] as roleTemplatePolicy[],
  all_templates: [] as combinedAppDataAndTemplateType[],
  templates: {} as Record<string, combinedAppDataAndTemplateType>,
  policyInfo: {
    roles: {},
    settings: {},
    name: "",
    destinations: {
      hlsDestinations: {},
      rtmpDestinations: {},
    },
    id: "",
    plugins: {},
  } as policyTemplateType,
  updatePolicyStatus: "",
  hasUnsavedRoles: false,
  hasUnsavedRecordingSettings: false,
  hasUnsavedLiveStreamingChanges: false,
  hasUnsavedTranscriptionChanges: false,
  updateDestinationStatus: "",
  deletePolicyStatus: "",
  invalidFields: {
    recordingInfo: {} as recordingInvalidFieldsType,
    hlsDestinations: {} as hlsDestinationsInvalidFieldsType,
    rtmpDestinations: {} as rtmpDestinationsInvalidFieldsType,
    transcriptions: {} as transcriptionsInvalidFieldsType,
    recordings: {} as recordingsConfigInvalidFieldsType,
    plugins: {} as pluginsInvalidFieldsType,
  },
  checkSubdomainStatus: "",
  checkSubdomain: {
    error: "",
    subdomain: "",
  },
  updateTemplateStatus: "",
  fetchTemplateStatus: "",
  hasUnsavedAdvancedSettings: false,
  policyDerivedStates: {
    hlsDestinationEnabled: false,
    rtmpDestinationsEnabled: false,
    transcriptionEnabled: false,
  },
};
type State = typeof initialState;

export const roles = handleActions<State, string>(
  {
    [PATCH_ROLES_IN_STORE]: (state, action: AnyAction) => {
      const { roles } = action.payload;
      //create roles object from array with key as name of role and value as role object
      const roleObject = roles.reduce(
        (acc: { [x: string]: any }, role: roleTemplatePolicy) => {
          acc[role.name] = role;
          return acc;
        },
        {}
      );

      return {
        ...state,
        roles,
        policyInfo: {
          ...state.policyInfo,
          roles: roleObject,
        },
      };
    },
    [SET_ACTIVE_ROLE]: (state, action: AnyAction) => {
      const role = action.payload;
      return {
        ...state,
        activeRole: role,
      };
    },
    [PATCH_ROLE_IN_STORE]: (state, action: AnyAction) => {
      const role = action.payload.role as roleType;
      const roles = state.policyInfo.roles as unknown as Record<
        string,
        roleType
      >;
      roles[role.name] = role;
      const newRoles = Object.values(roles);
      return {
        ...state,
        roles: newRoles,
        policyInfo: {
          ...state.policyInfo,
          roles: roles,
        },
      };
    },
    [ROLES_REQUEST_INIT]: state => {
      return {
        ...state,
        fetchRolesStatus: API_CALL_STATE.IN_PROGRESS,
        roles: [],
        policy: "",
      };
    },

    [ROLES_REQUEST_DONE]: (state, action: AnyAction) => {
      const { roles, policy } = action.payload;

      // create object with role name as key and role object as value
      const rolesObject = roles.reduce(
        (acc: { [x: string]: roleType }, role: roleTemplatePolicy) => {
          acc[role.name] = role;
          return acc;
        },
        {}
      );

      return {
        ...state,
        roles,
        policy,
        fetchRolesStatus: API_CALL_STATE.DONE,
        policyInfo: { ...state.policyInfo, roles: { ...rolesObject } },
      };
    },
    [ROLES_REQUEST_FAIL]: state => {
      return { ...state, fetchRolesStatus: API_CALL_STATE.FAILED };
    },
    [ADD_ROLES_INIT]: state => {
      return { ...state, addRoleStatus: API_CALL_STATE.IN_PROGRESS };
    },
    [ADD_ROLES_FAIL]: state => {
      return { ...state, addRoleStatus: API_CALL_STATE.FAILED };
    },
    [ADD_ROLES_DONE]: (state, action: AnyAction) => {
      const { roles } = action.payload;
      return {
        ...state,
        roles: roles,
        addRoleStatus: API_CALL_STATE.DONE,
      };
    },

    [UPDATE_TEMPLATE_INIT]: state => {
      return {
        ...state,
        updateTemplateStatus: API_CALL_STATE.IN_PROGRESS,
      };
    },

    [UPDATE_TEMPLATE_DONE]: (state, action: AnyAction) => {
      const { id, subdomain_name } = action.payload;
      // eslint-disable-next-line
      //@ts-ignore
      state.templates[id].subdomain_name = subdomain_name;
      return { ...state, updateTemplateStatus: API_CALL_STATE.DONE };
    },

    [UPDATE_TEMPLATE_FAIL]: state => {
      return { ...state, updateTemplateStatus: API_CALL_STATE.FAILED };
    },
    [UPDATE_ROLES_INIT]: state => {
      return { ...state, updateRolesStatus: API_CALL_STATE.IN_PROGRESS };
    },
    [UPDATE_ROLES_DONE]: (state, action: AnyAction) => {
      const { roles } = action.payload;
      return { ...state, roles, updateRolesStatus: API_CALL_STATE.DONE };
    },
    [UPDATE_ROLES_FAIL]: state => {
      return { ...state, updateRolesStatus: API_CALL_STATE.FAILED };
    },
    [DELETE_ROLES_INIT]: state => {
      return { ...state, deleteRoleStatus: API_CALL_STATE.IN_PROGRESS };
    },
    [DELETE_ROLES_FAIL]: state => {
      return { ...state, deleteRoleStatus: API_CALL_STATE.FAILED };
    },
    [DELETE_ROLES_DONE]: (state, action: AnyAction) => {
      const { roles } = action.payload;
      return {
        ...state,
        roles: roles,
        deleteRoleStatus: API_CALL_STATE.DONE,
      };
    },
    [FETCH_ALL_TEMPLATES_DONE]: (state, action: AnyAction) => {
      const { all_templates } = action.payload;
      const templates = {};
      // eslint-disable-next-line
      //@ts-ignore
      all_templates?.forEach(template => (templates[template.id] = template));
      return {
        ...state,
        all_templates,
        templates: templates,
        fetchTemplateStatus: API_CALL_STATE.DONE,
      };
    },
    [FETCH_ALL_TEMPLATES_INIT]: state => {
      return {
        ...state,
        all_templates: [],
        templates: {},
        fetchTemplateStatus: API_CALL_STATE.IN_PROGRESS,
      };
    },
    [FETCH_ALL_TEMPLATES_FAIL]: state => {
      return { ...state, fetchTemplateStatus: API_CALL_STATE.FAILED };
    },
    [REQUESTS_COMPLETE]: state => {
      return {
        ...state,
        fetchRolesStatus: "",
        updateRolesStatus: "",
        deleteRoleStatus: "",
        addRoleStatus: "",
        roles: [],
      };
    },
    [REPLACE_ROLES_ONBOARDING]: (state, action: AnyAction) => {
      const { roles } = action.payload;
      return { ...state, roles };
    },
    [UPDATE_POLICY_INFO_INIT]: state => {
      return {
        ...state,
        updatePolicyStatus: API_CALL_STATE.IN_PROGRESS,
      };
    },
    [UPDATE_POLICY_INFO_DONE]: (state, action: AnyAction) => {
      const template = action.payload;
      // eslint-disable-next-line
      //@ts-ignore
      state.templates[template.id] = template;
      return {
        ...state,
        updatePolicyStatus: API_CALL_STATE.DONE,
        policyInfo: action.payload,
      };
    },
    [UPDATE_POLICY_INFO_FAIL]: state => {
      // Reset the old values
      return {
        ...state,
        updatePolicyStatus: API_CALL_STATE.FAILED,
        policyInfo: { ...state.policyInfo },
      };
    },
    [DELETE_POLICY_INFO_INIT]: state => {
      return {
        ...state,
        deletePolicyStatus: API_CALL_STATE.IN_PROGRESS,
      };
    },
    [DELETE_POLICY_INFO_DONE]: (state, action: AnyAction) => {
      const { payload } = action;
      const { all_templates, templates } = state;
      // eslint-disable-next-line
      //@ts-ignore
      const newTemplates = all_templates.filter(app => app.id !== payload);
      // eslint-disable-next-line
      //@ts-ignore
      delete templates[payload];
      return {
        ...state,
        templates,
        all_templates: newTemplates.length ? newTemplates : [],
        deletePolicyStatus: API_CALL_STATE.DONE,
      };
    },
    [DELETE_POLICY_INFO_FAIL]: state => {
      return {
        ...state,
        deletePolicyStatus: API_CALL_STATE.FAILED,
      };
    },
    [DELETE_POLICY_INFO_RESET]: state => {
      // Reset the old values
      return {
        ...state,
        deletePolicyStatus: "",
      };
    },
    [PATCH_POLICY_INFO]: (state, action: AnyAction) => {
      const { payload } = action;
      const policyInfo = { ...state.policyInfo, ...payload };
      return {
        ...state,
        policyInfo,
      };
    },
    [FETCH_POLICY_INFO_INIT]: (state, action: AnyAction) => {
      // Reset the old values
      let policyInfo = {
        roles: {},
        settings: {},
        name: "",
        destinations: { hlsDestinations: {}, rtmpDestinations: {} },
        id: "",
      } as policyTemplateType;
      if (action.payload.policyId === state.policyInfo?.id) {
        policyInfo = state.policyInfo;
      }

      return {
        ...state,
        fetchPolicyInfoStatus: API_CALL_STATE.IN_PROGRESS,
        policyInfo,
        roles: [],
      };
    },
    [FETCH_POLICY_INFO_DONE]: (state, action: AnyAction) => {
      const policyInfo = action?.payload?.policyInfo as policyTemplateType;
      const newRoles = [
        ...Object.values(policyInfo.roles as Record<string, roleType>),
      ];
      return {
        ...state,
        fetchPolicyInfoStatus: API_CALL_STATE.DONE,
        policyInfo: action.payload.policyInfo,
        roles: newRoles,
        policyId: action.payload.policyInfo.id,
      };
    },

    [UNSAVED_RECORDINGS_FLAG]: (state, action: AnyAction) => {
      return {
        ...state,
        hasUnsavedRecordingSettings: action.payload,
      };
    },
    [UNSAVED_ADVANCED_SETTINGS_FLAG]: (state, action: AnyAction) => {
      return {
        ...state,
        hasUnsavedAdvancedSettings: action.payload,
      };
    },
    [UNSAVED_ROLES_FLAG]: (state, action: AnyAction) => {
      return {
        ...state,
        hasUnsavedRoles: action.payload,
      };
    },
    [UNSAVED_LIVE_STREAMING_FLAG]: (state, action: AnyAction) => {
      return {
        ...state,
        hasUnsavedLiveStreamingChanges: action.payload,
      };
    },
    [UNSAVED_TRANSCRIPTION_FLAG]: (state, action: AnyAction) => {
      return {
        ...state,
        hasUnsavedTranscriptionChanges: action.payload,
      };
    },
    [PUT_DESTINATIONS]: (state, action: AnyAction) => {
      const { payload = {} } = action;

      return {
        ...state,
        policyInfo: {
          ...state.policyInfo,
          // eslint-disable-next-line
          //@ts-ignore
          destinations: { ...state.policyInfo.destinations, ...payload },
        },
      };
    },
    [UPDATE_DESTINATION_INIT]: state => {
      return {
        ...state,
        updateDestinationStatus: API_CALL_STATE.IN_PROGRESS,
      };
    },
    [UPDATE_DESTINATION_DONE]: state => {
      return {
        ...state,
        updateDestinationStatus: API_CALL_STATE.DONE,
      };
    },
    [UPDATE_DESTINATION_FAIL]: state => {
      return {
        ...state,
        updateDestinationStatus: API_CALL_STATE.FAILED,
      };
    },
    [UPDATE_HLS_DESTINATIONS]: (state, action: AnyAction) => {
      const { payload = {} } = action;
      const { name: destinationName } = payload;
      const updatedState = { ...state };
      set(
        updatedState,
        `policyInfo.destinations.hlsDestinations.${destinationName}`,
        payload
      );
      return { ...updatedState };
    },
    [UPDATE_RECORDINGS_CONFIG]: (state, action: AnyAction) => {
      const { payload = {} } = action;
      const updatedState = { ...state };
      set(
        updatedState,
        "policyInfo.destinations.recordings[0]",
        payload.recordingConfig
      );

      return { ...updatedState };
    },
    [UPDATE_TRANSCRIPTION]: (state, action: AnyAction) => {
      const { payload = {} } = action;
      const { name: destinationName } = payload;
      const updatedState = { ...state };
      set(
        updatedState,
        `policyInfo.destinations.transcriptions.${destinationName}`,
        payload.transcription
      );

      return { ...updatedState };
    },
    [SET_TRANSCRIPTION_ENABLE_FLAG]: state => {
      const updatedState = { ...state };
      const { policyInfo } = updatedState;
      // eslint-disable-next-line
      //@ts-ignore
      const transcriptionEnabled = !isEmpty(
        policyInfo?.destinations?.transcriptions
      );

      if (transcriptionEnabled) {
        const transcriptions =
          updatedState?.policyInfo?.destinations?.transcriptions || {};
        const transcriptionIds = Object.keys(transcriptions) || [];
        transcriptionIds.forEach(id => {
          const transcription = transcriptions[
            id
          ] as policyTemplateType["destinations"]["transcriptions"][keyof policyTemplateType["destinations"]["transcriptions"]];
          transcriptions[id] = getMergedTranscriptionPolicy(transcription);
        });
      }
      set(
        updatedState,
        "policyDerivedStates.transcriptionEnabled",
        transcriptionEnabled
      );
      return { ...updatedState };
    },
    [ENABLE_TRACK_RECORDING]: state => {
      const updatedState = { ...state };
      const { policyInfo } = updatedState;

      // setup track recording in recordings
      policyInfo.recordings = (policyInfo.recordings ||
        []) as RecordingConfigType[];

      const recordingConfigName = uuid4();
      if (isEmpty(policyInfo.recordings)) {
        const defaultRecordingsConfigObj: RecordingConfigType = {
          ...policyTemplate.recordings?.[0]!,
        };
        defaultRecordingsConfigObj.name = recordingConfigName;
        defaultRecordingsConfigObj.role = INTERNAL_RECORDER_ROLE;
        policyInfo.recordings.push(defaultRecordingsConfigObj);
      }
      if (!policyInfo.recordings[0].name) {
        policyInfo.recordings[0].name = recordingConfigName;
      }

      policyInfo.recordings[0].trackRecording = {
        enabled: true,
      };

      if (!policyInfo.roles[INTERNAL_RECORDER_ROLE]) {
        policyInfo.roles[INTERNAL_RECORDER_ROLE] =
          getInternalRecoderPolicyInfoRole({
            subscribeToRoles: getVisibleRoles(Object.keys(policyInfo.roles)),
          });
      }
      if (!policyInfo.recordings[0]?.role) {
        policyInfo.recordings[0].role = INTERNAL_RECORDER_ROLE;
      }
      return { ...updatedState };
    },
    [DISABLE_TRACK_RECORDING]: state => {
      const updatedState = { ...state };
      const { policyInfo } = updatedState;

      policyInfo.recordings = (policyInfo.recordings ||
        []) as RecordingConfigType[];

      if (!isEmpty(policyInfo.recordings)) {
        policyInfo.recordings[0].trackRecording = {
          enabled: false,
        };
      }

      return { ...updatedState };
    },
    [ENABLE_STREAM_RECORDING]: state => {
      const updatedState = { ...state };
      const { policyInfo } = updatedState;

      // setup track recording in recordings
      policyInfo.recordings = (policyInfo.recordings ||
        []) as RecordingConfigType[];
      const defaultRecordingsConfigObj: RecordingConfigType = {
        ...policyTemplate.recordings?.[0]!,
      };
      const recordingConfigName = uuid4();
      if (isEmpty(policyInfo.recordings)) {
        defaultRecordingsConfigObj.name = recordingConfigName;
        defaultRecordingsConfigObj.role = INTERNAL_RECORDER_ROLE;
        policyInfo.recordings.push(defaultRecordingsConfigObj);
      }
      if (!policyInfo.recordings[0].name) {
        policyInfo.recordings[0].name = recordingConfigName;
      }
      const streamRecordingObj = {
        ...(defaultRecordingsConfigObj.streamRecording || {}),
        ...(policyInfo.recordings[0].streamRecording || {}),
      };

      policyInfo.recordings[0].streamRecording = {
        ...streamRecordingObj,
        enabled: true,
      };

      if (!policyInfo.roles[INTERNAL_RECORDER_ROLE]) {
        policyInfo.roles[INTERNAL_RECORDER_ROLE] =
          getInternalRecoderPolicyInfoRole({
            subscribeToRoles: getVisibleRoles(Object.keys(policyInfo.roles)),
          });
      }
      if (!policyInfo.recordings[0]?.role) {
        policyInfo.recordings[0].role = INTERNAL_RECORDER_ROLE;
      }
      return { ...updatedState };
    },
    [DISABLE_STREAM_RECORDING]: state => {
      const updatedState = { ...state };
      const { policyInfo } = updatedState;

      policyInfo.recordings = (policyInfo.recordings ||
        []) as RecordingConfigType[];

      if (!isEmpty(policyInfo.recordings)) {
        policyInfo.recordings[0].streamRecording = {
          enabled: false,
        };
      }

      return { ...updatedState };
    },
    // eslint-disable-next-line complexity
    [ENABLE_BROWSER_COMPOSITE]: state => {
      const updatedState = { ...state };
      const { policyInfo } = updatedState;

      // setup track recording in recordings
      policyInfo.recordings = (policyInfo.recordings ||
        []) as RecordingConfigType[];
      const defaultRecordingsConfigObj: RecordingConfigType = {
        ...policyTemplate.recordings?.[0]!,
      };

      const recordingConfigName = uuid4();
      if (isEmpty(policyInfo.recordings)) {
        defaultRecordingsConfigObj.name = recordingConfigName;
        defaultRecordingsConfigObj.role = INTERNAL_RECORDER_ROLE;
        policyInfo.recordings.push(defaultRecordingsConfigObj);
      }
      if (!policyInfo.recordings[0].name) {
        policyInfo.recordings[0].name = recordingConfigName;
      }

      policyInfo.recordings[0].maxDuration =
        policyInfo.recordings[0].maxDuration ||
        defaultRecordingsConfigObj.maxDuration;
      policyInfo.recordings[0].presignDuration =
        policyInfo.recordings[0].presignDuration ||
        defaultRecordingsConfigObj.presignDuration;

      const compositeRecordingObj =
        policyInfo.recordings[0].compositeRecording ||
        defaultRecordingsConfigObj.compositeRecording;
      const browserCompositeObj = {
        ...(defaultRecordingsConfigObj.compositeRecording?.browserComposite ||
          {}),
        ...(compositeRecordingObj?.browserComposite || {}),
      };

      policyInfo.recordings[0].compositeRecording = {
        ...compositeRecordingObj,
        browserComposite: {
          ...browserCompositeObj,
          enabled: true,
        },
      };

      if (!policyInfo.roles[INTERNAL_RECORDER_ROLE]) {
        policyInfo.roles[INTERNAL_RECORDER_ROLE] =
          getInternalRecoderPolicyInfoRole({
            subscribeToRoles: getVisibleRoles(Object.keys(policyInfo.roles)),
          });
      }
      if (!policyInfo.recordings[0]?.role) {
        policyInfo.recordings[0].role = INTERNAL_RECORDER_ROLE;
      }
      return { ...updatedState };
    },
    [DISABLE_BROWSER_COMPOSITE]: state => {
      const updatedState = { ...state };
      const { policyInfo } = updatedState;

      policyInfo.recordings = (policyInfo.recordings ||
        []) as RecordingConfigType[];

      if (!isEmpty(policyInfo.recordings)) {
        policyInfo.recordings[0].compositeRecording = {
          ...policyInfo.recordings[0].compositeRecording,
          browserComposite: {
            enabled: false,
          },
        };
      }

      return { ...updatedState };
    },

    [ENABLE_TRANSCRIPTION]: (state, action: AnyAction) => {
      const updatedState = { ...state };
      const { policyInfo } = updatedState;
      const mode = action.payload;

      // setup transcription object
      policyInfo.destinations.transcriptions = (policyInfo.destinations
        .transcriptions || {}) as Record<string, TranscriptionType>;
      const defaultTranscriptionObj: TranscriptionType = {
        ...(
          policyTemplate.destinations.transcriptions as unknown as Record<
            string,
            TranscriptionType
          >
        ).default,
        modes: [mode],
      };
      let transcriptionId = "";
      const transcriptionIds = Object.keys(
        policyInfo.destinations.transcriptions || {}
      ) as string[];

      if (isEmpty(policyInfo.destinations.transcriptions)) {
        transcriptionId = uuid4(); // autogen transcription id
        defaultTranscriptionObj.name = transcriptionId; // setup name / id of transcription
        defaultTranscriptionObj.role = INTERNAL_RECORDER_ROLE; // setup default role
        policyInfo.destinations.transcriptions[transcriptionId] =
          defaultTranscriptionObj;
      } else {
        transcriptionId = transcriptionIds[0];
        policyInfo.destinations.transcriptions[transcriptionIds[0]].modes = [
          ...policyInfo.destinations.transcriptions[transcriptionIds[0]].modes,
          mode,
        ];
      }

      if (mode === "recorded") {
        policyInfo.destinations.transcriptions[transcriptionId].outputModes = [
          ...Object.keys(TRANSCRIPTION_OUTPUT_MODES),
        ];
      }

      if (!policyInfo.roles[INTERNAL_RECORDER_ROLE]) {
        policyInfo.roles[INTERNAL_RECORDER_ROLE] =
          getInternalRecoderPolicyInfoRole({
            subscribeToRoles: getVisibleRoles(Object.keys(policyInfo.roles)),
          });
      }

      if (currentWorkspaceHipaa() && mode === transcriptionModes.CAPTION) {
        policyInfo.roles[INTERNAL_RECORDER_ROLE].permissions.browserRecording =
          false;
      }

      updatedState.policyInfo = { ...policyInfo };

      // setup derived state data
      updatedState.policyDerivedStates.transcriptionEnabled = true;
      return { ...updatedState };
    },
    [DISABLE_TRANSCRIPTION]: (state, action: AnyAction) => {
      const updatedState = { ...state };
      const mode = action.payload;
      const { policyInfo } = updatedState;
      const ids = Object.keys(policyInfo.destinations.transcriptions || {});

      ids.forEach(id => {
        if (
          policyInfo?.destinations?.transcriptions?.[id]?.modes?.includes(mode)
        ) {
          policyInfo.destinations.transcriptions[id].modes =
            policyInfo?.destinations?.transcriptions?.[id].modes.filter(
              r => r !== mode
            );
          if (mode === "recorded") {
            policyInfo.destinations.transcriptions[id].outputModes = [];
            if (
              !isEmpty(policyInfo?.destinations?.transcriptions?.[id]?.summary)
            ) {
              (
                policyInfo.destinations.transcriptions[id]
                  .summary as TranscriptionSummaryType
              ).enabled = false;
            }
          }
        }

        if (
          policyInfo?.destinations?.transcriptions?.[id]?.modes?.length === 0
        ) {
          delete (
            policyInfo.destinations.transcriptions as Record<
              string,
              TranscriptionType
            >
          )[id];
        }
      });

      if (isEmpty(policyInfo?.destinations?.transcriptions)) {
        updatedState.policyDerivedStates.transcriptionEnabled = false;
      }

      return { ...updatedState, policyInfo: policyInfo };
    },
    // eslint-disable-next-line complexity
    [SET_HLS_DESTINATION_ENABLE_FLAG]: (state, action: AnyAction) => {
      const updatedState = { ...state };
      const { policyInfo } = updatedState;
      const roleLayouts = action.payload;
      let hlsDestinationEnabled =
        !!policyInfo?.roles?.[INTERNAL_RECORDER_ROLE] &&
        !!policyInfo?.roles?.[HLS_VIEWER_ROLE] &&
        !isEmpty(policyInfo?.destinations?.hlsDestinations);

      if (!isEmpty(roleLayouts) && roleLayouts) {
        let hlsLiveStreamingRoomConfigPresent = false;
        for (let i = 0; i < Object.values(roleLayouts).length; i++) {
          if (
            !isEmpty(
              Object.values((roleLayouts || {}) as RoleLayouts)?.[i]?.screens
                ?.conferencing?.hls_live_streaming
            )
          ) {
            hlsLiveStreamingRoomConfigPresent = true;
            break;
          }
        }

        hlsDestinationEnabled =
          hlsLiveStreamingRoomConfigPresent &&
          !!policyInfo?.roles?.[INTERNAL_RECORDER_ROLE] &&
          !isEmpty(policyInfo?.destinations?.hlsDestinations);
      }
      // eslint-disable-next-line
      //@ts-ignore

      if (hlsDestinationEnabled) {
        const hlsDestinations =
          updatedState.policyInfo.destinations.hlsDestinations || {};
        const hlsDestinationIds = Object.keys(hlsDestinations) || [];

        const hlsDestinationName = hlsDestinationIds?.[0] || "";

        // FE receives recordingObjectReceivedWhenDisabled even when recording is disabled
        if (
          hlsDestinationName &&
          policyInfo.destinations?.hlsDestinations?.[hlsDestinationName]
            ?.recording &&
          isEqual(
            policyInfo.destinations?.hlsDestinations?.[hlsDestinationName]
              ?.recording,
            recordingObjectReceivedWhenDisabled
          )
        ) {
          delete policyInfo.destinations.hlsDestinations[hlsDestinationName]
            .recording;
        }

        hlsDestinationIds.forEach(id => {
          const destination = hlsDestinations[
            id
          ] as policyTemplateType["destinations"]["hlsDestinations"][keyof policyTemplateType["destinations"]["hlsDestinations"]];
          hlsDestinations[id] = getMergedDestinationPolicy(destination);
        });
      }
      set(
        updatedState,
        "policyDerivedStates.hlsDestinationEnabled",
        hlsDestinationEnabled
      );
      return { ...updatedState };
    },
    [VALIDATE_TRANSCRIPTION]: (state, action: AnyAction) => {
      const { payload = {} } = action;
      return {
        ...state,
        invalidFields: { ...state.invalidFields, transcriptions: payload },
      };
    },
    [VALIDATE_RTMP_DESTINATIONS]: (state, action: AnyAction) => {
      const { payload = {} } = action;
      return {
        ...state,
        invalidFields: { ...state.invalidFields, rtmpDestinations: payload },
      };
    },

    [VALIDATE_RECORDINGS_CONFIG]: (state, action: AnyAction) => {
      const { payload = {} } = action;
      return {
        ...state,
        invalidFields: { ...state.invalidFields, recordings: payload },
      };
    },

    [VALIDATE_HLS_DESTINATIONS]: (state, action: AnyAction) => {
      const { payload = {} } = action;
      return {
        ...state,
        invalidFields: { ...state.invalidFields, hlsDestinations: payload },
      };
    },
    [UPDATE_RECORDING_INFO]: (state, action: AnyAction) => {
      const { payload = {} } = action;
      const updatedState = set(state, "policyInfo.settings.recording", payload);
      return { ...updatedState };
    },
    [UPDATE_PLUGINS_INFO]: (state, action: AnyAction) => {
      const { payload = {} } = action;
      const updatedState = set(state, "policyInfo.plugins", payload);
      return { ...updatedState };
    },
    [VALIDATE_RECORDING_INFO]: (state, action: AnyAction) => {
      const { payload = {} } = action;
      return {
        ...state,
        invalidFields: {
          ...state.invalidFields,
          recordingInfo: payload,
        },
      };
    },
    [VALIDATE_PLUGINS_INFO]: (state, action: AnyAction) => {
      const { payload = {} } = action;
      return {
        ...state,
        invalidFields: {
          ...state.invalidFields,
          plugins: payload,
        },
      };
    },
    [SET_HLS_RECORDING]: (state, action: AnyAction) => {
      const { payload } = action;
      const { policyInfo } = { ...state };

      const destinationId: string = Object.keys(
        policyInfo?.destinations?.hlsDestinations || {}
      )?.[0];
      if (policyInfo?.destinations?.hlsDestinations?.[destinationId]) {
        if (Object.keys(payload).length > 0) {
          policyInfo.destinations.hlsDestinations[destinationId].recording = {
            ...payload,
          };
        } else {
          delete policyInfo.destinations.hlsDestinations[destinationId][
            "recording"
          ];
        }
      }
      return { ...state, policyInfo };
    },

    [SET_RTMP_DESTINATIONS]: (state, action: AnyAction) => {
      const { payload } = action;
      const updatedState = { ...state };
      if (payload) {
        const rtmpObj =
          updatedState.policyInfo?.destinations?.rtmpDestinations || {};
        const rtmpIds = Object.keys(rtmpObj);
        if (rtmpIds.length === 0) {
          rtmpIds.push("default");
        }
        if (!updatedState.policyInfo.roles[INTERNAL_RECORDER_ROLE]) {
          updatedState.policyInfo.roles[INTERNAL_RECORDER_ROLE] =
            getInternalRecoderPolicyInfoRole({
              subscribeToRoles: getVisibleRoles(
                Object.keys(updatedState.policyInfo.roles)
              ),
            });
        }
        // @ts-ignore
        rtmpIds.forEach(id => (rtmpObj[id] = getMergedRtmpPolicy(rtmpObj[id])));
        if (rtmpObj?.[rtmpIds?.[0]] && !rtmpObj[rtmpIds[0]]?.role) {
          rtmpObj[rtmpIds[0]].role = INTERNAL_RECORDER_ROLE;
        }
      } else {
        updatedState.policyInfo.destinations.rtmpDestinations = {};
      }
      set(updatedState, "policyDerivedStates.rtmpDestinationsEnabled", payload);
      return { ...updatedState };
    },

    [UPDATE_RTMP_DESTINATIONS]: (state, action: AnyAction) => {
      const { payload } = action;
      return { ...state, policyInfo: payload };
    },

    [ENABLE_HLS_DESTINATION]: (state, action: AnyAction) => {
      const updatedState = { ...state };
      const { policyInfo } = updatedState;
      const roleLayouts = action.payload;
      // setup hls destination object
      policyInfo.destinations.hlsDestinations =
        policyInfo.destinations.hlsDestinations || {};
      const defaultHlsDestinationObj: hlsDestinationsType = {
        ...(
          policyTemplate.destinations.hlsDestinations as unknown as Record<
            string,
            hlsDestinationsType
          >
        ).default,
      };
      if (isEmpty(policyInfo.destinations.hlsDestinations)) {
        const hlsDestinationId = uuid4() as string; // autogen hls destination id
        defaultHlsDestinationObj.name = hlsDestinationId; // setup name / id of the hls destination
        defaultHlsDestinationObj.role = INTERNAL_RECORDER_ROLE; // setup role with which beam will join
        policyInfo.destinations.hlsDestinations[hlsDestinationId] =
          defaultHlsDestinationObj;
      }
      // setup internal role
      if (!policyInfo.roles[INTERNAL_RECORDER_ROLE]) {
        policyInfo.roles[INTERNAL_RECORDER_ROLE] =
          getInternalRecoderPolicyInfoRole({
            subscribeToRoles: Object.keys(policyInfo.roles),
          });
      }
      if (isEmpty(roleLayouts)) {
        if (!policyInfo.roles[HLS_VIEWER_ROLE]) {
          // setup viewer role for the hls destination
          policyInfo.roles[HLS_VIEWER_ROLE] = getHlsViewerPolicyInfoRole();
        }
      } else {
        if (!policyInfo.roles[NEW_HLS_VIEWER_ROLE]) {
          // setup viewer role for the hls destination
          policyInfo.roles[NEW_HLS_VIEWER_ROLE] =
            getHlsViewerPolicyInfoRole(NEW_HLS_VIEWER_ROLE);
        }
      }

      // setup derived state data
      updatedState.policyDerivedStates.hlsDestinationEnabled = true;
      return { ...updatedState };
    },
    // eslint-disable-next-line complexity
    [DISABLE_HLS_DESTINATION]: (state, action: AnyAction) => {
      const updatedState = { ...state };
      const { policyInfo } = updatedState;
      const roleLayouts = action.payload;
      const ids = Object.keys(policyInfo.destinations.hlsDestinations || {});
      ids.forEach(id => delete policyInfo.destinations.hlsDestinations?.[id]);
      if (isEmpty(roleLayouts)) {
        delete policyInfo.roles[HLS_VIEWER_ROLE];
      } else {
        const hlsViewerRoles = getHLSViewerRolesFromRoleLayouts({
          roleLayouts: roleLayouts,
        });
        if (hlsViewerRoles && hlsViewerRoles?.length > 0) {
          hlsViewerRoles.forEach(role => {
            if (!isEmpty(policyInfo.roles[role.role as string])) {
              delete policyInfo.roles[role.role as string];
            }
          });
        }
      }
      const isRecordingEmpty = (policyInfo?.recordings as [])?.length === 0;
      if (
        !isArray(policyInfo?.recordings) &&
        isRecordingEmpty &&
        isEmpty(policyInfo?.destinations?.rtmpDestinations) &&
        isEmpty(policyInfo?.destinations?.hlsDestinations)
      ) {
        delete policyInfo.roles[INTERNAL_RECORDER_ROLE];
        if (
          policyInfo.plugins?.whiteboard?.permissions?.reader &&
          policyInfo.plugins?.whiteboard?.permissions?.reader?.findIndex(
            r => r === INTERNAL_RECORDER_ROLE
          ) >= 0
        ) {
          policyInfo.plugins = {
            ...policyInfo?.plugins,
            whiteboard: {
              ...policyInfo?.plugins?.whiteboard,
              permissions: {
                ...policyInfo?.plugins?.whiteboard?.permissions,
                reader:
                  policyInfo?.plugins?.whiteboard?.permissions?.reader?.filter(
                    r => r !== INTERNAL_RECORDER_ROLE
                  ),
              },
            },
          };
        }
      }

      updatedState.policyInfo = { ...policyInfo };
      updatedState.policyDerivedStates.hlsDestinationEnabled = false;

      return { ...updatedState };
    },
    [UPDATE_POLICY_ROLE]: (state, action: AnyAction) => {
      const updatedState = { ...state };
      const { policyInfo } = updatedState;
      const { payload } = action;
      const { roleName, path, value } = payload;
      const tempPolicy = set(policyInfo, `roles.${roleName}.${path}`, value);

      return { ...updatedState, policyInfo: { ...tempPolicy } };
    },
    [UPDATE_TEMPLATE_NAME_INIT]: state => {
      state.updateTemplateNameStatus = API_CALL_STATE.IN_PROGRESS;
      return { ...state };
    },
    [UPDATE_TEMPLATE_NAME_DONE]: (state, action: AnyAction) => {
      state.updateTemplateNameStatus = API_CALL_STATE.DONE;
      state.policyInfo.name = action.payload.name;
      return { ...state };
    },
    [UPDATE_TEMPLATE_NAME_FAIL]: state => {
      state.updateTemplateNameStatus = API_CALL_STATE.FAILED;
      return { ...state };
    },
    [UPDATE_TEMPLATE_NAME_RESET]: state => {
      state.updateTemplateNameStatus = "";
      return { ...state };
    },
    [CLEAR_VALIDATIONS_RESULTS]: state => {
      state.invalidFields = {
        recordingInfo: {},
        hlsDestinations: {},
        rtmpDestinations: {},
        transcriptions: {},
        recordings: {},
        plugins: {},
      };
      return state;
    },
    [CHECK_SUBDOMAIN_INIT]: state => {
      const newState = { ...state };
      newState.checkSubdomainStatus = API_CALL_STATE.IN_PROGRESS;
      return { ...newState };
    },
    [CHECK_SUBDOMAIN_DONE]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.checkSubdomainStatus = API_CALL_STATE.DONE;
      newState.checkSubdomain = {
        error: action.payload.error,
        subdomain: action.payload.subdomain,
      };
      return { ...newState };
    },
    [CHECK_SUBDOMAIN_FAIL]: (state, action: AnyAction) => {
      const newState = { ...state };
      newState.checkSubdomainStatus = API_CALL_STATE.FAILED;
      newState.checkSubdomain = {
        error: action.payload.error,
        subdomain: action.payload.subdomain,
      };
      return { ...newState };
    },

    [ADD_INTERNAL_RECORDER]: state => {
      const updatedState = { ...state };
      const { policyInfo } = updatedState;
      policyInfo.roles[INTERNAL_RECORDER_ROLE] =
        getInternalRecoderPolicyInfoRole({
          subscribeToRoles: getVisibleRoles(Object.keys(policyInfo.roles)),
        });
      return { ...updatedState, policyInfo };
    },
    [CHECK_SUBDOMAIN_RESET]: state => {
      const newState = { ...state };
      newState.checkSubdomainStatus = "";
      newState.checkSubdomain = {
        error: "",
        subdomain: "",
      };
      return { ...newState };
    },
    [ADD_SUBDOMAIN_TO_API_TEMPLATE_DONE]: (state, action: AnyAction) => {
      const policyId = state.policyInfo.id;
      let temp_templates = { ...state.templates };
      const temp_all_templates = [...state.all_templates];
      temp_templates = {
        ...temp_templates,
        [policyId]: {
          ...temp_templates[policyId],
          subdomain: action.payload.subdomain,
        },
      };

      temp_all_templates.forEach((template, index) => {
        if (template.id === policyId) {
          temp_all_templates[index].subdomain = action.payload.subdomain;
        }
      });
      return {
        ...state,
        addSubdomainToApiTemplateStatus: API_CALL_STATE.DONE,
        all_templates: [...temp_all_templates],
        templates: { ...temp_templates },
        policyInfo: {
          ...state.policyInfo,
          room_id: action.payload.room_id,
          subdomain: action.payload.subdomain,
          room_enabled: action.payload.room_enabled,
        },
      };
    },
    [ADD_SUBDOMAIN_TO_API_TEMPLATE_INIT]: state => {
      const newState = { ...state };
      newState.addSubdomainToApiTemplateStatus = API_CALL_STATE.IN_PROGRESS;
      return { ...newState };
    },

    [ADD_SUBDOMAIN_TO_API_TEMPLATE_FAIL]: state => {
      const newState = { ...state };
      newState.addSubdomainToApiTemplateStatus = API_CALL_STATE.FAILED;
      return { ...newState };
    },
  },
  initialState
);
