// availity-reactstrap-validation
import React, { Component } from "react";
// ReCAPTCHA
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Alert, Col, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
// Redux
import queryString from "query-string";
import AuthForm from "../../components/AuthForm";
import { AppAnalytics } from "../../helpers/analytics_helper";
// action
import {
  userForgetPassword,
  userForgetPasswordError,
  userForgetPasswordSuccess,
} from "../../store/actions";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      captchaToken: null,
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    // Ref for ReCAPTCHA object
    this.recaptchaRef = React.createRef();
  }

  captchaChangeHandler(captchaToken) {
    this.setState({
      captchaToken,
    });
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    AppAnalytics.track("forget_password.started", { email: values.email });

    if (this.state.captchaToken) {
      console.debug("Captcha Token generated.");
      values["captcha-token"] = this.state.captchaToken;
      this.props.userForgetPassword(values, this.props);
      this.setState({ captchaToken: null });
    } else {
      console.debug("Captcha Token not generated.");
      this.props.userForgetPasswordSuccess("");
      this.props.userForgetPasswordError("Please Solve the CAPTCHA.");
    }
  }

  componentDidUpdate() {
    // clear captcha after form submission
    if (
      (this.props.forgetError || this.props.forgetSuccessMsg) &&
      !this.state.captchaToken
    ) {
      this.recaptchaRef.current.reset();
    }
  }

  render() {
    return (
      <AuthForm
        title="Forgot password?"
        subtitle="Receive instructions to reset your password."
        footer={
          <Link
            to={() => {
              const { redirect_url } = queryString.parse(
                window.location.search
              );
              if (redirect_url) {
                const url = new URL(redirect_url);
                return {
                  pathname: url.pathname,
                  search: url.search,
                };
              }
              return {
                pathname: "/login",
              };
            }}
            className="font-weight-medium text-primary"
          >
            Back to Login
          </Link>
        }
      >
        {this.props.forgetSuccessMsg || this.props.forgetError ? (
          <Alert color="success" style={{ marginTop: "13px" }}>
            {this.props.forgetSuccessMsg || this.props.forgetError}
          </Alert>
        ) : null}

        <AvForm
          className="form-horizontal mt-4"
          onValidSubmit={this.handleValidSubmit}
        >
          <div className="form-group h-14">
            <AvField
              name="email"
              className="form-control bg-gray-cool2 focus:ring-2 ring-blue-400 border-none"
              placeholder="Enter email"
              type="email"
              required
            />
          </div>
          <div className="flex justify-center mb-4">
            <ReCAPTCHA
              ref={this.recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              size="normal"
              onChange={this.captchaChangeHandler.bind(this)}
            />
          </div>
          <Row className="form-group">
            <Col className="text-right">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
              >
                Submit
              </button>
            </Col>
          </Row>
        </AvForm>
      </AuthForm>
    );
  }
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, {
    userForgetPassword,
    userForgetPasswordError,
    userForgetPasswordSuccess,
  })(ForgetPasswordPage)
);
