import React, { ComponentProps, FC, ReactNode } from "react";
import TemplateMultiConfigCard, {
  TemplateConfigProps,
} from "src/components/Cards/TemplateMultiConfigCard";
import ConfigMultiSelect from "src/components/Common/ConfigMultiSelect";
import Line from "src/components/Common/Line";
import StatusString from "src/components/Common/StatusString";
import ValuePill from "src/components/Common/ValuePill";
import { AppAnalytics } from "src/helpers/analytics_helper";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { TRANSCRIPTION_OUTPUT_MODES } from "src/services/policy/constants";
import { policyTemplateType, TranscriptionType } from "src/types/policyTypes";
import { slugify } from "src/utils";
import { Flex, Switch, Text } from "@100mslive/react-ui";

interface SpeakerLabelledTranscriptionSettingsProps {
  policyInfo?: policyTemplateType;
  transcription: TranscriptionType;
  toggleTranscription: (bool: boolean, mode: string) => void;
  setTranscription: (key: string, value: ReactNode) => void;
  invalidFields?: { transcriptions: Record<string, string | undefined> };
  isTranscriptionEnabled: boolean;
  transcriptionModeList: string[];
  outputModeClicked: (mode: string) => void;
}

const SpeakerLabelledTranscriptionSettings: FC<
  ComponentProps<typeof Flex> & SpeakerLabelledTranscriptionSettingsProps
> = ({
  transcription,
  toggleTranscription,
  setTranscription,
  isTranscriptionEnabled,
  invalidFields,
  transcriptionModeList,
  outputModeClicked,
}) => {
  const SpeakerLabelledTranscriptionSubtitle = () => (
    <Text
      variant="body2"
      css={{ c: "$textDisabled", fontWeight: "$regular", mt: "$4" }}
    >
      This feature requires Room Composite Recording or HLS Recording. The
      generated transcript comes with speaker labels and happens post completion
      of the call recording.
      <a
        href={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/how-to-guides/enable-transcription-and-summary`}
        rel="noreferrer noopener"
        target="_blank"
      >
        <Text
          variant="body2"
          css={{ c: "$primaryLight", fontWeight: "$regular" }}
          as="span"
        >
          &nbsp;Learn more
        </Text>
      </a>
    </Text>
  );

  const SummarySubtitle = () => (
    <Text
      variant="body2"
      css={{ c: "$textDisabled", fontWeight: "$regular", mt: "$4" }}
    >
      This feature requires Post call Transcription to be enabled.
      <a
        href={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/how-to-guides/enable-transcription-and-summary`}
        rel="noreferrer noopener"
        target="_blank"
      >
        <Text
          variant="body2"
          css={{ c: "$primaryLight", fontWeight: "$regular" }}
          as="span"
        >
          &nbsp;Learn more
        </Text>
      </a>
    </Text>
  );

  const PostCallTranscription = () => {
    return (
      <>
        <TranscriptionEnableSwitch />
        {isTranscriptionEnabled ? (
          <Flex
            direction="column"
            css={{
              minWidth: "100%",
            }}
          >
            <SettingsTitle
              key="Output Modes"
              title="Select the output file format of the generated transcript. Multiple output formats can be selected."
              text="Output Modes"
            />
            <Flex css={{ w: "100%" }} direction="column">
              <ConfigMultiSelect
                inputText={
                  isTranscriptionEnabled
                    ? "Select what method of output you want"
                    : "Enable transcription to select output mode"
                }
                disabled={!isTranscriptionEnabled}
                optionsArray={Object.values(TRANSCRIPTION_OUTPUT_MODES)}
                onCheckedChange={mode => {
                  if (!mode || !isTranscriptionEnabled) {
                    return;
                  }
                  outputModeClicked(mode);
                }}
                isCheckedCondition={mode => {
                  const valArr = Object.values(TRANSCRIPTION_OUTPUT_MODES);
                  const keyArr = Object.keys(TRANSCRIPTION_OUTPUT_MODES);
                  const idx = valArr.findIndex(val => val === mode);

                  return ((transcriptionModeList as string[]) || [])?.includes(
                    keyArr[idx]
                  );
                }}
              />
              <Flex css={{ w: "100%", flexWrap: "wrap" }}>
                {transcriptionModeList.map((mode: string) => (
                  <ValuePill
                    key={mode}
                    content={TRANSCRIPTION_OUTPUT_MODES[mode]}
                    onClick={() => {
                      outputModeClicked(TRANSCRIPTION_OUTPUT_MODES[mode]);
                    }}
                  />
                ))}
              </Flex>
              {invalidFields?.transcriptions?.["outputModes"] ? (
                <StatusString
                  textVariant="body2"
                  content={invalidFields?.transcriptions?.["outputModes"] || ""}
                />
              ) : null}
            </Flex>
          </Flex>
        ) : null}
      </>
    );
  };
  const TranscriptionEnableSwitch = () => (
    <Flex
      direction="column"
      css={{
        pb: "$10",
        minWidth: "100%",
      }}
    >
      <SettingsTitle
        key="Transcribe Recordings"
        title="Generate transcripts post completion of the call recording. Please ensure that you start recording."
        text="Transcribe Recordings"
      />
      <Flex css={{ mt: "$4", position: "relative" }}>
        <Switch
          checked={isTranscriptionEnabled}
          onCheckedChange={e => {
            toggleTranscription(e, "recorded");
          }}
        />
      </Flex>
    </Flex>
  );

  const SummaryEnableSwitch = () => (
    <Flex
      direction="column"
      css={{
        pb: "$10",
        minWidth: "100%",
      }}
    >
      <SettingsTitle
        key="Summarise Transcripts"
        title="Generate summary from the recording transcript."
        text="Summarise Transcripts"
      />
      <Flex css={{ mt: "$4", position: "relative" }}>
        <Switch
          checked={transcription?.summary?.enabled}
          onCheckedChange={() => {
            setTranscription(
              "summary.enabled",
              !transcription?.summary?.enabled
            );
            try {
              AppAnalytics.track("toggle.clicked", {
                platform: "hms-dashboard",
                componentId: "template.config.transcription",
                btnId: "toggle.summary",
                value: !transcription?.summary?.enabled,
              });
            } catch (e) {
              console.error(e);
            }
          }}
          disabled={!isTranscriptionEnabled}
        />
      </Flex>
    </Flex>
  );

  const templateConfigs: TemplateConfigProps[] = [
    {
      id: slugify("Post Call Transcription"),
      text: "Post Call Transcription",
      subtitle: <SpeakerLabelledTranscriptionSubtitle />,
      classNameForText: "config-subheading",
      rightComponent: <PostCallTranscription />,
      bottomButton: <Line />,
    },
    {
      id: slugify("AI-Generated Summary"),
      text: "AI-Generated Summary",
      subtitle: <SummarySubtitle />,
      classNameForText: "config-subheading",
      rightComponent: <SummaryEnableSwitch />,
    },
  ];

  return <TemplateMultiConfigCard templateConfigs={templateConfigs} />;
};

export default SpeakerLabelledTranscriptionSettings;
