import { handleActions } from "redux-actions";
import {
  FETCH_COACHMARKS_DONE,
  FETCH_COACHMARKS_FAIL,
} from "../actions/CoachMarksAction";

export const coachmarks = handleActions(
  {
    [FETCH_COACHMARKS_DONE]: (state, action) => {
      const { coachmarks } = action.payload;
      return {
        ...state,
        coachmarks: coachmarks,
        fetchStatus: "success",
      };
    },
    [FETCH_COACHMARKS_FAIL]: state => {
      return { ...state, fetchStatus: "failure" };
    },
  },
  {
    coachmarks: [],
    fetchStatus: "",
  }
);
