// Register Method
import cookies from "js-cookie";
import { getTrackersForSignUp } from "../utils";

async function formRegister({ formId, ip, array, pageName, pageURL }) {
  const trackers = getTrackersForSignUp();
  for (let i in trackers) {
    array.push([i, trackers[i]]);
  }
  let formFields = [];
  let formcontext = {};
  const portalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID;

  if (!portalId) {
    return;
  }

  if (ip) {
    formcontext.ipAddress = ip;
  }

  if (pageName) {
    formcontext.pageName = pageName;
  }

  const cookieValue = cookies.get("hubspotutk");
  if (cookieValue) {
    formcontext.hutk = cookieValue;
  }

  if (pageURL) {
    formcontext.pageUri = pageURL;
  }

  for (let i = 0; i < array.length; i++) {
    let formInputs = {
      value: String,
      name: String,
    };
    formInputs.name = array[i][0];
    formInputs.value = array[i][1] || "";
    formFields.push(formInputs);
  }
  let xhr = new XMLHttpRequest();
  let url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  let form_data = {
    submittedAt: new Date(),
    fields: formFields,
    context: formcontext,
  };

  let final_data = JSON.stringify(form_data);

  xhr.open("POST", url);
  // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
  xhr.setRequestHeader("Content-Type", "application/json");
  let resolveFunc,
    rejectFunc = null;
  const promise = new Promise((resolve, reject) => {
    resolveFunc = resolve;
    rejectFunc = reject;
  });
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      //eslint-disable-next-line
      console.log("success ", 200);
      resolveFunc("Success");
    } else if (xhr.readyState === 4 && xhr.status === 400) {
      console.error("fail: the submission is rejected.", 400); // Returns a 400 error the submission is rejected.
      rejectFunc(Error("fail: the submission is rejected."));
    } else if (xhr.readyState === 4 && xhr.status === 403) {
      console.error("fail: the portal isn't allowed to post submissions.", 403); // Returns a 403 error if the portal isn't allowed to post submissions.
      rejectFunc(Error("fail: the portal isn't allowed to post submissions."));
    } else if (xhr.readyState === 4 && xhr.status === 404) {
      console.error("fail : the formGuid isn't found", 404); //Returns a 404 error if the formGuid isn't found
      rejectFunc(Error("fail : the formGuid isn't found"));
    }
  };

  // Sends the request

  xhr.send(final_data);
  return promise;
}

export { formRegister };
