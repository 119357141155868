import { useEffect, useState } from "react";

const useActiveLinks = (itemClassName: string) => {
  const [activeSubheading, setActiveSubheading] = useState<string | null>(null);

  useEffect(() => {
    const getActiveLinks = () => {
      const subheadingArray = document.getElementsByClassName(itemClassName);
      const activeIndex = Array.from(subheadingArray).findIndex(
        (element: Element) => {
          const rect = element.getBoundingClientRect();
          return rect.top >= 0 && rect.bottom <= window.innerHeight;
        }
      );

      if (activeIndex >= 0) {
        setActiveSubheading(subheadingArray[activeIndex].id);
      }
    };

    getActiveLinks();
    window.addEventListener("scroll", getActiveLinks);

    return () => {
      window.removeEventListener("scroll", getActiveLinks);
    };
  }, [itemClassName]);

  return activeSubheading;
};

export default useActiveLinks;
