import React, { useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import LargeIcon from "src/components/Common/Icons/LargeIcon";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { API_CALL_STATE } from "src/constants";
import { history, openDevrevWidget } from "src/helpers";
import { isHLSDestinationsEnabled } from "src/services/policy/destinations";
import { fetchAppLayout } from "src/store/appLayout/actions";
import { RootState } from "src/store/reducers";
import {
  createRoomRTMPStreamKeys,
  disableRoomRTMPStreamKeys,
  fetchRoomDetails,
  fetchRoomRTMPStreamKeys,
  updateRoomDetails,
} from "src/store/rooms/actions";
import {
  AlertTriangleIcon,
  ChevronLeftIcon,
  SupportIcon,
} from "@100mslive/react-icons";
import { Box, Button, Flex, Text } from "@100mslive/react-ui";
import RoomDetailsInfo from "../components/roomDetailsInfo";
type Props = { roomId: string };

function RoomDetails({ roomId }: Props) {
  const dispatch = useDispatch();
  const [hlsIsEnabled, setHlsIsEnabled] = useState(false);
  const { templates } = useSelector((state: RootState) => state.roles);
  const { roleLayouts } = useSelector((state: RootState) => state.appLayouts);
  const {
    fetchRoomDetailsStatus,
    room,
    fetchRTMPStreamKeysStatus,
    disableRTMPStreamKeysStatus,
    updateRoomStatus,
    createRTMPStreamKeysStatus,
    roomStreamKeys,
  } = useSelector((state: RootState) => state.rooms);
  useEffect(() => {
    if (roomId) {
      dispatch(fetchRoomDetails(roomId));
    }
  }, [dispatch, roomId]);

  const checkTemplateExistsForRoom = (roomTemplateId: string) => {
    //  Will remain false when template id is null (not fetched yet)

    if (roomTemplateId) {
      const result = templates[roomTemplateId];
      return !isEmpty(result);
    }
    return roomTemplateId === "";
  };
  const disableRTMPKeys = (roomId: string) => {
    dispatch(disableRoomRTMPStreamKeys(roomId));
    dispatch(fetchRoomRTMPStreamKeys(roomId));
  };

  const createAndFetchRTMPStreamKeys = (roomId: string) => {
    dispatch(createRoomRTMPStreamKeys(roomId));
    dispatch(fetchRoomRTMPStreamKeys(roomId));
  };

  const refreshRoomRTMPStreamKeys = (roomId: string) => {
    dispatch(disableRoomRTMPStreamKeys(roomId));
    dispatch(createRoomRTMPStreamKeys(roomId));
    dispatch(fetchRoomRTMPStreamKeys(roomId));
  };
  const toggleLargeRoomBool = (val: boolean) => {
    dispatch(
      updateRoomDetails({
        id: roomId,
        enabled: !!room?.enabled,
        largeRoom: val,
      })
    );
  };
  const toggleRoomStatus = () => {
    dispatch(
      updateRoomDetails({
        id: roomId,
        enabled: !room?.enabled || false,
      })
    );
  };

  const setHLSStatus = useCallback(() => {
    setHlsIsEnabled(
      isHLSDestinationsEnabled({
        policy: templates[room.template_id],
        roleLayouts: roleLayouts,
      })
    );
  }, [roleLayouts, room.template_id, templates]);

  useEffect(() => {
    if (roomId) {
      dispatch(fetchRoomDetails(roomId));
    }
  }, [dispatch, roomId]);

  useEffect(() => {
    if (!isEmpty(room)) {
      if (room.template_id !== "" && typeof room.template_id === "string") {
        dispatch(fetchAppLayout({ templateId: room.template_id }));
      }
      if (room.active) {
        dispatch(fetchRoomRTMPStreamKeys(roomId));
      }
    }
  }, [room, dispatch, roomId]);

  useEffect(() => {
    if (!isEmpty(templates[room.template_id]) && !isEmpty(roleLayouts)) {
      setHLSStatus();
    }
  }, [templates, room, roleLayouts, setHLSStatus]);

  useEffect(() => {
    if (updateRoomStatus === API_CALL_STATE.DONE && roomId !== "") {
      dispatch(fetchRoomDetails(roomId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRoomStatus]);

  const templateExists = checkTemplateExistsForRoom(room.template_id);
  return (
    <>
      {fetchRoomDetailsStatus === API_CALL_STATE.IN_PROGRESS ? (
        <Flex direction="column">
          <Flex css={{ w: "100%" }} justify="between" align="center">
            <Flex justify="start" align="center" css={{ gap: "$md" }}>
              <Box
                css={{ cursor: "pointer", c: "$textMedEmp" }}
                onClick={() => history.goBack()}
              >
                <LargeIcon>
                  <ChevronLeftIcon />
                </LargeIcon>
              </Box>
              <Text variant="h5">Room Details</Text>
            </Flex>
          </Flex>

          <Flex
            css={{
              mt: "$10",
              border: "$space$px solid $borderDefault",
              r: "$1",
              w: "100%",
              h: "100%",
              p: "$8",
            }}
            justify="center"
            align="center"
          >
            <Skeleton containerClassName="flex-1" width="100%" height={300} />
          </Flex>
        </Flex>
      ) : fetchRoomDetailsStatus === API_CALL_STATE.DONE ? (
        isEmpty(room) ? (
          <Flex direction="column">
            <Flex css={{ w: "100%" }} justify="between" align="center">
              <Flex justify="start" align="center" css={{ gap: "$md" }}>
                <Box
                  css={{ cursor: "pointer", c: "$textMedEmp" }}
                  onClick={() => history.goBack()}
                >
                  <LargeIcon>
                    <ChevronLeftIcon />
                  </LargeIcon>
                </Box>
                <Text variant="h5">Room Details</Text>
              </Flex>
            </Flex>
            <Flex
              css={{
                mt: "$10",
                border: "$space$px solid $borderDefault",
                r: "$1",
                w: "100%",
                h: "100%",
                p: "$8",
              }}
              justify="center"
              align="center"
            >
              <Text variant="body1">No Room Details Present</Text>
              <Text variant="caption">Contact Support</Text>
            </Flex>
          </Flex>
        ) : (
          <RoomDetailsInfo
            disableRTMPStreamKeysStatus={disableRTMPStreamKeysStatus}
            fetchRoomDetailsStatus={fetchRoomDetailsStatus}
            fetchRTMPStreamKeysStatus={fetchRTMPStreamKeysStatus}
            disableRTMPKeys={disableRTMPKeys}
            createAndFetchRTMPStreamKeys={createAndFetchRTMPStreamKeys}
            refreshRoomRTMPStreamKeys={refreshRoomRTMPStreamKeys}
            createRTMPStreamKeysStatus={createRTMPStreamKeysStatus}
            roomStreamKeys={roomStreamKeys}
            roomId={roomId}
            templateExists={templateExists}
            room={room}
            toggleRoomStatus={toggleRoomStatus}
            toggleLargeRoomBool={toggleLargeRoomBool}
            hlsEnabled={hlsIsEnabled}
          />
        )
      ) : (
        <Flex direction="column">
          <Flex css={{ w: "100%" }} justify="between" align="center">
            <Flex justify="start" align="center" css={{ gap: "$md" }}>
              <Box
                css={{ cursor: "pointer", c: "$textMedEmp" }}
                onClick={() => history.goBack()}
              >
                <LargeIcon>
                  <ChevronLeftIcon />
                </LargeIcon>
              </Box>
              <Text variant="h5">Room Details</Text>
            </Flex>
          </Flex>
          <Flex
            direction="column"
            css={{
              mt: "$10",
              border: "$space$px solid $borderDefault",
              r: "$1",
              w: "100%",
              h: "400px",
              p: "$8",
            }}
            justify="center"
            align="center"
          >
            <Flex css={{ c: "$error" }}>
              <AlertTriangleIcon width={40} height={40} />
            </Flex>

            <Text variant="sub2" css={{ mb: "$2" }}>
              Something went wrong
            </Text>
            <Text
              variant="caption"
              css={{ fontWeight: "$regular", c: "$textMedEmp" }}
            >
              We couldn&apos;t load details for this room.
            </Text>
            <Button
              icon
              variant="standard"
              css={{ p: "$3 $4", r: "$0", mt: "$8" }}
              onClick={() => openDevrevWidget(true)}
            >
              <SmallIcon>
                <SupportIcon />
              </SmallIcon>
              <Text variant="body2">Report Issue</Text>
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  );
}

export default RoomDetails;
