import React from "react";
import { Box, Button, Flex, Text } from "@100mslive/react-ui";
import Backdrop from "../Backdrop/Backdrop";

const Modal = ({
  show,
  title,
  onBackdropClick,
  onDeleteClick,
  hideInnerContent,
  hidePrimaryCTA,
}) => {
  return (
    <Backdrop show={show} onClick={onBackdropClick}>
      <Box
        css={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "$surfaceDefault",
          border: "1px solid $borderLight",
          p: "$10",
          borderRadius: "$0",
        }}
      >
        <Text variant="h6" css={{ color: "$textHighEmp" }}>
          {title}
        </Text>
        {!hideInnerContent ? (
          <Text variant="sm" css={{ mt: "$md", color: "$textMedEmp" }}>
            Are you sure you want to delete? This can’t be undone.
          </Text>
        ) : null}
        <Flex justify="end" css={{ w: "100%", mt: "$14", gap: "$8" }}>
          <Button
            variant="standard"
            outlined
            onClick={onBackdropClick}
            css={{ borderRadius: "$0" }}
          >
            Cancel
          </Button>
          {!hidePrimaryCTA ? (
            <Button
              variant="danger"
              onClick={onDeleteClick}
              css={{ borderRadius: "$0" }}
            >
              Delete
            </Button>
          ) : null}
        </Flex>
      </Box>
    </Backdrop>
  );
};

export default Modal;
