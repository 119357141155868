import React, { ReactNode, useState } from "react";
import { CrossIcon } from "@100mslive/react-icons";
import { CSS, Popover } from "@100mslive/react-ui";

type Props = {
  trigger: ReactNode;
  content: ReactNode;
  contentCss?: CSS;
  triggerCSS?: CSS;
  hideCloseIcon?: boolean;
};

const MainPopover = ({
  trigger,
  triggerCSS,
  content,
  contentCss,
  hideCloseIcon = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popover.Root
      open={isOpen}
      onOpenChange={() => {
        setIsOpen(prev => !prev);
      }}
      modal={true}
    >
      <Popover.Trigger css={{ ...triggerCSS }} asChild>
        {trigger}
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          css={{
            borderRadius: "$0",
            backgroundColor: "$surfaceDefault",
            border: "1px solid $borderLight",
            mt: "$4",
            mr: "$10",
            ...contentCss,
          }}
        >
          {!hideCloseIcon ? (
            <div
              className="absolute right-4 top-4 cursor-pointer"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CrossIcon />
            </div>
          ) : null}
          {content}
          <Popover.Arrow />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default MainPopover;
