import { DefaultConferencingScreen_Elements } from "@100mslive/types-prebuilt";
import { JoinForm_JoinBtnType } from "@100mslive/types-prebuilt/elements/join_form";
import { isEmpty, merge, set } from "lodash";
import { templateTypes } from "src/constants";
import {
  getVisibleRoles,
  INTERNAL_RECORDER_ROLE,
  NEW_HLS_VIEWER_ROLE,
} from "src/helpers";
import { liveStreamRoleNames } from "src/services/cmsModels/Policy/constants";
import {
  BINARY_OPTIONS,
  DESTINATIONS_BROADCASTER_ASPECT_RATIO,
  DESTINATIONS_RECORDINGS_START_OPTIONS,
  LIVE_STREAM_DESTINATIONS_VIDEO_ON_DEMAND,
  VIEWER_ON_STAGE_OPTIONS,
} from "src/services/cmsModels/Policy/helpers";
import policyTemplate from "src/store/policyTemplate";
import { getInternalRecoderPolicyInfoRole } from "src/store/roleTemplate";
import {
  hlsDestinationsType,
  policyType,
  RecordingConfigType,
} from "src/types/policyTypes";
import { RoleLayouts } from "src/types/prebuilt";
import { calculateLayerDimensions } from "src/utils";
import { v4 as uuid4 } from "uuid";

export const externalRTMPIngestForLiveStreamingUseCase = ({
  policy,
  setPolicy,
  roleLayouts,
  setRoleLayouts,
}: {
  policy: policyType;
  setPolicy: (policy: policyType) => void;
  roleLayouts: RoleLayouts;
  setRoleLayouts: (roleLayouts: RoleLayouts) => void;
}) => {
  const tempPolicy = { ...policy };
  const broadcasterRole = {
    ...tempPolicy.roles[liveStreamRoleNames["broadcaster"]],
  };

  let broadcasterRoleLayout = {
    ...roleLayouts[liveStreamRoleNames["broadcaster"]],
  };
  broadcasterRole.publishParams.allowed = [];
  broadcasterRole.subscribeParams.subscribeDegradation = {};
  broadcasterRole.permissions.hlsStreaming = true;
  broadcasterRole.permissions.pollRead = true;
  broadcasterRole.permissions.pollWrite = true;
  broadcasterRoleLayout = set(
    broadcasterRoleLayout,
    "screens.preview.default.elements.join_form.join_btn_type",
    JoinForm_JoinBtnType.JOIN_BTN_TYPE_JOIN_ONLY
  );
  const internalRecorderRole = {
    ...tempPolicy?.roles?.[INTERNAL_RECORDER_ROLE],
  };
  ["participant_list", "on_stage_exp", "brb", "video_tile_layout"].forEach(
    (r: string) => {
      //delete following keys for broadcasterRoleLayout
      delete broadcasterRoleLayout.screens?.conferencing?.default?.elements?.[
        r as keyof DefaultConferencingScreen_Elements
      ];
    }
  );
  const hlsViewerRole = { ...tempPolicy.roles[NEW_HLS_VIEWER_ROLE] };
  const hlsViewerRoleLayout = { ...roleLayouts[NEW_HLS_VIEWER_ROLE] };
  delete hlsViewerRoleLayout.screens?.conferencing?.hls_live_streaming?.elements
    ?.participant_list;
  const roles = {
    [liveStreamRoleNames.broadcaster]: { ...broadcasterRole },
    [NEW_HLS_VIEWER_ROLE]: { ...hlsViewerRole },
    [INTERNAL_RECORDER_ROLE]: { ...internalRecorderRole },
  };
  const roleLayoutsObj = {
    [liveStreamRoleNames.broadcaster]: { ...broadcasterRoleLayout },
    [NEW_HLS_VIEWER_ROLE]: { ...hlsViewerRoleLayout },
  };
  tempPolicy.roles = { ...roles };
  setPolicy(tempPolicy);
  setRoleLayouts(roleLayoutsObj);
};

export const setLiveStreamingRecordingAutoStart = (
  str: string,
  policy: policyType,
  setPolicy: (policy: policyType) => void
) => {
  if (str === DESTINATIONS_RECORDINGS_START_OPTIONS.AutoStart) {
    enableAutoStartLiveStreamingRecording({ policy, setPolicy });
  }
  if (str === DESTINATIONS_RECORDINGS_START_OPTIONS.NoRecording) {
    disableAutoStartLiveStreamingRecording({ policy, setPolicy });
  }
};

export const setCompositeRecordingForWebinarTemplates = ({
  str,
  policy,
  setPolicy,
}: {
  str: string;
  policy: policyType;
  setPolicy: (policy: policyType) => void;
}) => {
  if (str === DESTINATIONS_RECORDINGS_START_OPTIONS.AutoStart) {
    enableCompositeRecording({ policy, setPolicy, autoStart: true });
  }
  if (str === DESTINATIONS_RECORDINGS_START_OPTIONS.Manual) {
    enableCompositeRecording({
      policy,
      setPolicy,
      autoStart: false,
    });
  }
  if (str === DESTINATIONS_RECORDINGS_START_OPTIONS.NoRecording) {
    disableCompositeRecording({ policy, setPolicy });
  }
};

export const toggleViewerOnStage = ({
  str,
  policy,
  setPolicy,
  roleLayouts,
  setRoleLayouts,
}: {
  str: string;
  policy: policyType;
  setPolicy: (policy: policyType) => void;
  roleLayouts: RoleLayouts;
  setRoleLayouts: (roleLayouts: RoleLayouts) => void;
}) => {
  if (str === VIEWER_ON_STAGE_OPTIONS.Yes) {
    addViewerOnStage({ policy, roleLayouts, setPolicy, setRoleLayouts });
  }
  if (str === VIEWER_ON_STAGE_OPTIONS.No) {
    removeViewerOnStage({ policy, roleLayouts, setPolicy, setRoleLayouts });
  }
};

export function selectAspectRatioForLiveStreamingUseCase(
  str: string,
  policy: policyType,
  roleLayouts: RoleLayouts,
  setPolicy: (policy: policyType) => void,
  setRoleLayouts: (roleLayouts: RoleLayouts) => void,
  setAppDetails: ({
    tile_shape,
    metadata,
  }: {
    tile_shape?: string;
    metadata?: string;
  }) => void
) {
  if (str === DESTINATIONS_BROADCASTER_ASPECT_RATIO.desktop["16:9"]) {
    selectAspectRatioForLayoutAndTemplate({
      ratio: "16:9",
      policy,
      roleLayouts,
      setPolicy,
      setRoleLayouts,
      setAppDetails,
    });
  }
  if (str === DESTINATIONS_BROADCASTER_ASPECT_RATIO.mobile["9:16"]) {
    selectAspectRatioForLayoutAndTemplate({
      ratio: "9:16",
      policy,
      roleLayouts,
      setPolicy,
      setRoleLayouts,
      setAppDetails,
    });
  }
}

// eslint-disable-next-line complexity
export function selectAspectRatioForLayoutAndTemplate({
  ratio,
  policy,
  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  roleLayouts,
  setPolicy,
  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setRoleLayouts,
  setAppDetails,
}: {
  ratio: string;
  policy: policyType;
  roleLayouts: RoleLayouts;
  setPolicy: (policy: policyType) => void;
  setRoleLayouts: (roleLayouts: RoleLayouts) => void;
  setAppDetails: ({
    tile_shape,
    metadata,
  }: {
    tile_shape?: string;
    metadata?: string;
  }) => void;
}) {
  const aspect = ratio.replaceAll(":", "-");
  setAppDetails({ tile_shape: aspect || "16-9", metadata: "" });
  const tempPolicy = { ...policy };

  for (const role in policy.roles) {
    const currVideoSettings = tempPolicy.roles[role]["publishParams"]["video"];
    const videoDimensions = calculateLayerDimensions({
      aspectRatio: ratio,
      layerRes:
        currVideoSettings.width >= currVideoSettings.height
          ? currVideoSettings.height
          : currVideoSettings.width,
    });
    tempPolicy.roles[role]["publishParams"]["video"] = {
      ...tempPolicy.roles[role]["publishParams"]["video"],
      width: videoDimensions.width,
      height: videoDimensions.height,
    };
  }
  const newAspectRatioPolicyDestinations = selectAspectRatioForDestinations({
    aspectRatio: ratio,
    policy: tempPolicy,
  });
  for (const destinationKey of Object.keys(policyTemplate.destinations)) {
    const obj = merge(
      tempPolicy?.destinations?.[
        destinationKey as keyof policyType["destinations"]
      ],
      newAspectRatioPolicyDestinations?.destinations?.[
        destinationKey as keyof policyType["destinations"]
      ]
    );
    tempPolicy.destinations = {
      ...tempPolicy.destinations,
      [destinationKey]: { ...obj },
    };
  }

  const newAspectRatioPolicyRecordings = selectAspectRationForRecordingConfig({
    aspectRatio: ratio,
    policy: tempPolicy,
  });
  const recordingObj = merge(
    tempPolicy.recordings?.[0],
    newAspectRatioPolicyRecordings.recordings?.[0]
  );
  if (recordingObj) {
    tempPolicy.recordings = [recordingObj];
  } else {
    tempPolicy.recordings = [];
  }

  setPolicy(tempPolicy);
}

export function selectAspectRationForRecordingConfig({
  aspectRatio,
  policy,
}: {
  aspectRatio: string;
  policy: policyType;
}) {
  const tempPolicy = { ...policy };
  if (
    !isEmpty(tempPolicy?.recordings?.[0]?.compositeRecording?.browserComposite)
  ) {
    const browserComposite =
      tempPolicy.recordings?.[0].compositeRecording?.browserComposite;
    const width = browserComposite?.width || 0;
    const height = browserComposite?.height || 0;
    const layerRes = width >= height ? height : width;
    const videoDimensions = calculateLayerDimensions({
      aspectRatio: aspectRatio,
      layerRes: layerRes,
    });
    const recordingConfig = {
      ...tempPolicy.recordings?.[0],
      compositeRecording: {
        ...tempPolicy?.recordings?.[0]?.compositeRecording,
        browserComposite: {
          ...tempPolicy?.recordings?.[0]?.compositeRecording?.browserComposite,
          width: videoDimensions.width,
          height: videoDimensions.height,
        },
      },
      thumbnails: {
        width: videoDimensions.width,
        height: videoDimensions.height,
      },
    };
    tempPolicy.recordings = [recordingConfig];
  }
  return tempPolicy;
}

// eslint-disable-next-line complexity
export function selectAspectRatioForDestinations({
  aspectRatio,
  policy,
}: {
  aspectRatio: string;
  policy: policyType;
}) {
  const tempPolicy = { ...policy };
  if (!isEmpty(tempPolicy?.destinations?.hlsDestinations)) {
    const destinationIds = Object.keys(tempPolicy.destinations.hlsDestinations);
    for (const id of destinationIds) {
      const tempLayers = [];
      const tempRecordingLayers = [];
      let tempRecordingThumbnails = {
        width: 0,
        height: 0,
      };
      if (
        !isEmpty(
          tempPolicy?.destinations?.hlsDestinations?.[id]?.recording?.layers
        ) &&
        (
          tempPolicy?.destinations?.hlsDestinations?.[id]?.recording
            ?.layers as []
        ).length > 0
      ) {
        const recordingLayers =
          tempPolicy?.destinations?.hlsDestinations?.[id]?.recording?.layers ||
          [];
        for (let j = 0; j < recordingLayers.length; j++) {
          const layerRes =
            recordingLayers[j].width >= recordingLayers[j].height
              ? recordingLayers[j].height
              : recordingLayers[j].width;
          const videoDimensions = calculateLayerDimensions({
            aspectRatio: aspectRatio,
            layerRes: layerRes,
          });
          tempRecordingLayers.push({
            ...recordingLayers[j],
            height: videoDimensions.height,
            width: videoDimensions.width,
          });
        }
        if (
          !isEmpty(
            tempPolicy?.destinations?.hlsDestinations?.[id]?.recording
              ?.thumbnails
          )
        ) {
          tempRecordingThumbnails = {
            width: tempRecordingLayers[0].width,
            height: tempRecordingLayers[0].height,
          };
        }
      }
      if (
        !isEmpty(tempPolicy?.destinations?.hlsDestinations?.[id]?.layers) &&
        (tempPolicy?.destinations?.hlsDestinations?.[id]?.layers as []).length >
          0
      ) {
        const layers =
          tempPolicy?.destinations?.hlsDestinations?.[id]?.recording?.layers ||
          [];
        for (let i = 0; i < layers.length; i++) {
          const layerRes =
            layers[i].width >= layers[i].height
              ? layers[i].height
              : layers[i].width;
          const videoDimensions = calculateLayerDimensions({
            aspectRatio: aspectRatio,
            layerRes: layerRes,
          });
          tempLayers.push({
            ...layers[i],
            height: videoDimensions.height,
            width: videoDimensions.width,
          });
        }
      }
      tempPolicy.destinations.hlsDestinations[id] = {
        ...tempPolicy.destinations.hlsDestinations[id],
        layers: tempLayers,
        recording: {
          ...tempPolicy.destinations.hlsDestinations[id].recording,
          layers: tempRecordingLayers,
          thumbnails: {
            ...tempPolicy?.destinations?.hlsDestinations?.[id]?.recording
              ?.thumbnails,
            width: tempRecordingThumbnails?.width,
            height: tempRecordingThumbnails?.height,
          },
        },
      };
    }
  }
  return tempPolicy;
}

export function addViewerOnStage({
  policy,
  roleLayouts,
  setPolicy,
  setRoleLayouts,
}: {
  policy: policyType;
  roleLayouts: RoleLayouts;
  setPolicy: (policy: policyType) => void;
  setRoleLayouts: (roleLayouts: RoleLayouts) => void;
}) {
  //extract policy to new variable tempPolicy in spread fashion
  // add new role to tempPolicy with  values in herited from broadcaster role and add new role to roleLayouts

  const tempPolicy = { ...policy };
  const tempRoleLayouts = { ...roleLayouts };
  const broadcasterRole = tempPolicy.roles[liveStreamRoleNames["broadcaster"]];
  const viewerRole = {
    ...broadcasterRole,
    name: liveStreamRoleNames["viewer-on-stage"],
    permissions: {
      rtmpStreaming: false,
      hlsStreaming: false,
      browserRecording: false,
      sendRoomState: true,
      endRoom: false,
      removeOthers: false,
      mute: false,
      unmute: false,
      changeRole: false,
    },
  };
  const viewerRoleLayout = {
    ...roleLayouts["broadcaster"],
  };
  if (isEmpty(tempRoleLayouts[liveStreamRoleNames["viewer-on-stage"]])) {
    tempRoleLayouts[liveStreamRoleNames["viewer-on-stage"]] = viewerRoleLayout;
  }
  if (isEmpty(tempPolicy.roles[liveStreamRoleNames["viewer-on-stage"]])) {
    tempPolicy.roles[liveStreamRoleNames["viewer-on-stage"]] = viewerRole;
  }
  setPolicy(tempPolicy);
  setRoleLayouts(tempRoleLayouts);
}

export function removeViewerOnStage({
  policy,
  roleLayouts,
  setPolicy,
  setRoleLayouts,
}: {
  policy: policyType;
  roleLayouts: RoleLayouts;
  setPolicy: (policy: policyType) => void;
  setRoleLayouts: (roleLayouts: RoleLayouts) => void;
}) {
  if (!isEmpty(policy?.roles?.[liveStreamRoleNames["viewer-on-stage"]])) {
    const tempPolicy = { ...policy };
    delete tempPolicy.roles[liveStreamRoleNames["viewer-on-stage"]];
    for (const role in tempPolicy.roles) {
      const broadcasterRoles = [
        ...tempPolicy.roles[role].subscribeParams.subscribeToRoles,
      ];
      tempPolicy.roles[role].subscribeParams.subscribeToRoles = [
        ...broadcasterRoles.filter(
          r => r !== liveStreamRoleNames["viewer-on-stage"]
        ),
      ];
    }
    if (!isEmpty(roleLayouts?.[liveStreamRoleNames["viewer-on-stage"]])) {
      const tempRoleLayouts = { ...roleLayouts };
      for (const roleName in tempRoleLayouts) {
        if (
          tempRoleLayouts[roleName]?.screens?.conferencing?.default?.elements
            ?.on_stage_exp
        ) {
          delete tempRoleLayouts[roleName]?.screens?.conferencing?.default
            ?.elements?.on_stage_exp;
        }
      }
      delete tempRoleLayouts[liveStreamRoleNames["viewer-on-stage"]];
      setRoleLayouts(tempRoleLayouts);
    }
    setPolicy(tempPolicy);
  }
}

export function enableAutoStartLiveStreamingRecording({
  policy,
  setPolicy,
}: {
  policy: policyType;
  setPolicy: (policy: policyType) => void;
}) {
  const tempPolicy = { ...policy };
  // check for the destinations in policy and if it is empty then add the hlsDestinations to destinations
  // add recording to hlsDestinations
  const destinationIds = Object.keys(
    tempPolicy?.destinations?.hlsDestinations || {}
  );
  const hlsDestinations = {} as Record<string, hlsDestinationsType>;
  for (const id of destinationIds) {
    hlsDestinations[id] = {
      ...tempPolicy?.destinations?.hlsDestinations?.[id],
    };
    if (isEmpty(hlsDestinations[id]?.recording)) {
      hlsDestinations[id]["recording"] = {
        ...policyTemplate?.destinations?.hlsDestinations?.default?.recording,
        layers: [
          ...(tempPolicy?.destinations?.hlsDestinations?.[id]?.layers || []),
        ],
      };
    }
  }
  tempPolicy.destinations.hlsDestinations = { ...hlsDestinations };
  setPolicy(tempPolicy);
}

export function disableAutoStartLiveStreamingRecording({
  policy,
  setPolicy,
}: {
  policy: policyType;
  setPolicy: (policy: policyType) => void;
}) {
  const tempPolicy = { ...policy };
  // check for the destinations in policy and if it is empty then add the hlsDestinations to destinations
  // add recording to hlsDestinations
  const destinationIds = Object.keys(
    tempPolicy?.destinations?.hlsDestinations || {}
  );
  const hlsDestinations = {} as Record<string, hlsDestinationsType>;
  for (const id of destinationIds) {
    hlsDestinations[id] = {
      ...tempPolicy?.destinations?.hlsDestinations?.[id],
    };
    delete hlsDestinations[id]["recording"];
  }
  tempPolicy.destinations.hlsDestinations = { ...hlsDestinations };
  setPolicy(tempPolicy);
}

export function setLiveStreamingRecordingType({
  streamType,
  policy,
  setPolicy,
}: {
  policy: policyType;
  streamType: string;
  setPolicy: (policy: policyType) => void;
}) {
  const streamSettings = {
    hlsVod: false,
    singleFilePerLayer: false,
  };
  if (streamType === LIVE_STREAM_DESTINATIONS_VIDEO_ON_DEMAND.vod) {
    streamSettings["hlsVod"] = true;
    streamSettings["singleFilePerLayer"] = false;
  }
  if (streamType === LIVE_STREAM_DESTINATIONS_VIDEO_ON_DEMAND.postProcess) {
    streamSettings["singleFilePerLayer"] = true;
    streamSettings["hlsVod"] = false;
  }
  if (streamType === LIVE_STREAM_DESTINATIONS_VIDEO_ON_DEMAND.both) {
    streamSettings["singleFilePerLayer"] = true;
    streamSettings["hlsVod"] = true;
  }
  const tempPolicy = { ...policy };
  // check for the destinations in policy and if it is empty then add the hlsDestinations to destinations
  // add recording to hlsDestinations
  const destinationIds = Object.keys(
    tempPolicy?.destinations?.hlsDestinations || {}
  );
  for (const id of destinationIds) {
    if (
      tempPolicy?.destinations?.hlsDestinations?.[id]?.recording &&
      !isEmpty(tempPolicy?.destinations?.hlsDestinations?.[id]?.recording)
    ) {
      //@ts-ignore
      tempPolicy.destinations.hlsDestinations[id].recording.hlsVod =
        streamSettings.hlsVod;
      //@ts-ignore
      tempPolicy.destinations.hlsDestinations[id].recording.singleFilePerLayer =
        streamSettings.singleFilePerLayer;
      // = streamSettings["singleFilePerLayerf;
      // hlsDestinations[id]["recording"]["hlsVod"] = streamSettings["hlsVod"];
    }
  }
  setPolicy(tempPolicy);
}

export function isUseCaseCMSTemplate(str: string) {
  return str === templateTypes.LIVE_STREAM || str === "WEBINAR";
}

export const enableCompositeRecording = ({
  policy,
  setPolicy,
  autoStart,
}: {
  policy: policyType;
  autoStart: boolean;
  setPolicy: (policy: policyType) => void;
}) => {
  const tempPolicy = { ...policy };
  const recordings = (tempPolicy.recordings || []) as RecordingConfigType[];
  const defaultRecordingsConfigObj: RecordingConfigType = {
    ...policyTemplate.recordings?.[0]!,
  };
  const recordingConfigName = uuid4();
  if (isEmpty(recordings)) {
    defaultRecordingsConfigObj.name = recordingConfigName;
    defaultRecordingsConfigObj.role = INTERNAL_RECORDER_ROLE;
    recordings.push(defaultRecordingsConfigObj);
  }
  if (!recordings[0].name) {
    recordings[0].name = recordingConfigName;
  }
  const compositeRecordingObj =
    recordings[0].compositeRecording ||
    defaultRecordingsConfigObj.compositeRecording;
  const browserCompositeObj = {
    ...(defaultRecordingsConfigObj.compositeRecording?.browserComposite || {}),
    ...(compositeRecordingObj?.browserComposite || {}),
  };

  recordings[0].compositeRecording = {
    ...compositeRecordingObj,
    browserComposite: {
      ...browserCompositeObj,
      enabled: true,
      autoStart: autoStart,
    },
  };
  tempPolicy.recordings = recordings;
  setPolicy({ ...tempPolicy });
};

export const disableCompositeRecording = ({
  policy,
  setPolicy,
}: {
  policy: policyType;
  setPolicy: (policy: policyType) => void;
}) => {
  const tempPolicy = { ...policy };

  const recordings = (tempPolicy.recordings || []) as RecordingConfigType[];

  if (!isEmpty(recordings)) {
    recordings[0].compositeRecording = {
      ...recordings[0].compositeRecording,
      browserComposite: {
        enabled: false,
      },
    };
  }
  for (const role in tempPolicy.roles) {
    tempPolicy.roles[role].permissions.browserRecording = false;
  }
  setPolicy({ ...tempPolicy });
};

export const toggleLiveTranscription = ({
  val,
  policy,
  setPolicy,
}: {
  val: string;
  policy: policyType;
  setPolicy: (policy: policyType) => void;
}) => {
  if (val === BINARY_OPTIONS.yes) {
    const tempPolicy = { ...policy };
    const transcription = { ...policyTemplate.destinations.transcriptions };
    tempPolicy.destinations.transcriptions = { ...transcription };
    const transcriptionIds = Object.keys(
      tempPolicy.destinations.transcriptions
    );
    tempPolicy.destinations.transcriptions[transcriptionIds[0]].modes = [
      "live",
    ];
    tempPolicy.destinations.transcriptions[transcriptionIds[0]].role =
      INTERNAL_RECORDER_ROLE; // setup default role
    if (!tempPolicy.roles[INTERNAL_RECORDER_ROLE]) {
      tempPolicy.roles[INTERNAL_RECORDER_ROLE] =
        getInternalRecoderPolicyInfoRole({
          subscribeToRoles: getVisibleRoles(Object.keys(tempPolicy.roles)),
        });
    }
    setPolicy(tempPolicy);
  }
  if (val === BINARY_OPTIONS.no) {
    const tempPolicy = { ...policy };
    tempPolicy.destinations.transcriptions = {};
    setPolicy(tempPolicy);
  }
};

export const setLargeRoomForTemplate = ({
  answer,
  setLargeRoom,
}: {
  answer: string;
  setLargeRoom: (answer: boolean) => void;
}) => {
  if (answer === BINARY_OPTIONS.yes) {
    setLargeRoom(true);
  }
  if (answer === BINARY_OPTIONS.no) {
    setLargeRoom(false);
  }
};
