/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { truncateString } from "src/helpers";
import { Input, Text } from "@100mslive/react-ui";
import api from "../../api";
import ScrollTable from "../../components/ScrollTable";
import { fetchAdminWorkspaces } from "../../store/actions";
import {
  currentUser,
  getCurrentWorkspaceID,
  saveCurrentUser,
} from "../../utils";

export default function AdminHome() {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const selectCustomer = customer => {
    const user = currentUser();
    if (getCurrentWorkspaceID()) {
      user.customer.workspace_id = "";
    }
    user.customer = customer;
    saveCurrentUser(user);
    dispatch(fetchAdminWorkspaces());
  };

  const getUsers = async () => {
    try {
      const resp = await api.service("dashboard").get("adminview/users");
      return resp.data.users;
    } catch (error) {
      console.error("Admin users error: ", {
        message: error.response.data.message,
        error,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const users = await getUsers();
      setUsers(users);
      setLoading(false);
    };
    fetchData();
  }, []);

  const [searchText, setSearchText] = useState("");

  return (
    <div className="page-content">
      <Container fluid>
        <Text variant="h5">Registered 100ms Users</Text>
        <Input
          type="search"
          placeholder="Search using email or customer ID"
          css={{
            r: "$1",
            w: "450px",
            my: "$md",
            bg: "$surfaceLight",
            borderRadius: "$0",
          }}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <ScrollTable
          loading={loading}
          loadMore={() => {}}
          isEmpty={!(users && users.length > 0)}
          emptyMessage="No Register Users"
          thead={
            <>
              {users && users.length > 0 && (
                <tr>
                  <th className="text-base">Email</th>
                  <th className="text-base">Customer ID</th>
                </tr>
              )}
            </>
          }
          tbody={
            <>
              {users &&
                users
                  .filter(
                    user =>
                      user.email.includes(searchText) ||
                      user.customer_id.includes(searchText)
                  )
                  .map((user, index) => (
                    <tr key={index}>
                      <th scope="row" title={user.email}>
                        <div
                          onClick={() => selectCustomer(user)}
                          className="hover:text-primary-default cursor-pointer text-sm"
                        >
                          {truncateString(user.email, 40)}
                        </div>
                      </th>
                      <td className="text-sm text-textIcon-medEmp">
                        {user.customer_id}
                      </td>
                    </tr>
                  ))}
            </>
          }
        />
      </Container>
    </div>
  );
}
