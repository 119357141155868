import React from "react";
import classNames from "classnames";
import { InfoIcon } from "@100mslive/react-icons";
import { Flex, Loading, Text, Tooltip } from "@100mslive/react-ui";
import { freeMinsData } from "./freeBillingConfig";
import s from "./table.module.scss";
const tableHeaders = ["Capabilities", "Total Minutes", "Free Minutes Left"];
function UsageTable({ data = {}, loading = false }) {
  return (
    <Flex
      css={{
        minWidth: "100%",
        minHeight: "100%",
        border: "$space$px solid $borderDefault",
        r: "$2",
      }}
    >
      {!loading ? (
        <table className="min-w-full">
          <thead className="min-w-full top-0 bg-surface-default border-0 rounded-lg">
            <tr className="w-full border-0">
              {tableHeaders.map(tableHeader => (
                <th
                  key={tableHeader}
                  className="font-semibold text-xs py-3"
                  style={{
                    width:
                      tableHeader === tableHeaders[0]
                        ? "100%"
                        : tableHeader === tableHeaders[1]
                        ? "160px"
                        : "200px !important",
                    textAlign:
                      tableHeader === tableHeaders[0]
                        ? "start"
                        : tableHeader === tableHeaders[1]
                        ? "right"
                        : "left",
                    minWidth:
                      tableHeader === tableHeaders[0]
                        ? "100%"
                        : tableHeader === tableHeaders[1]
                        ? "160px"
                        : "200px",
                  }}
                >
                  <Text
                    css={{ c: "$textMedEmp", fontWeight: "$regular" }}
                    variant="body2"
                  >
                    {tableHeader}
                  </Text>
                </th>
              ))}
            </tr>
            {/* Making a sticky row's border sticky requires a hack*/}
          </thead>
          <tbody>
            <tr>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <SideHeader text="Real-Time Conferencing" />
                    </tr>
                    <tr>
                      <SideHeader text="Video Conferencing" isInternal />
                    </tr>

                    <tr>
                      <SideHeader text="Audio-Only Conferencing" isInternal />
                    </tr>
                    <tr>
                      <SideHeader
                        text="SIP Audio Dial-in and Dial-out"
                        isInternal
                      />
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <TotalMinutes
                        mins={data?.webrtc_dimension_wise?.video}
                        hidden
                      />
                    </tr>
                    <tr>
                      <TotalMinutes mins={data?.webrtc_dimension_wise?.video} />
                    </tr>
                    <tr>
                      <TotalMinutes mins={data?.webrtc_dimension_wise?.audio} />
                    </tr>
                    <tr>
                      <TotalMinutes mins={data?.webrtc_dimension_wise?.sip} />
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <FreeMinutes hidden />
                    </tr>
                    <tr>
                      <FreeMinutes hidden />
                    </tr>
                    <tr style={{ height: "10px" }}>
                      <FreeMinutes
                        mins={
                          (data?.webrtc_dimension_wise?.audio || 0) +
                          (data?.webrtc_dimension_wise?.video || 0)
                        }
                        freeMins={10000}
                      />
                    </tr>

                    <tr>
                      <FreeMinutes
                        mins={data?.webrtc_dimension_wise?.sip || 0}
                        freeMins={freeMinsData?.sip}
                      />
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <SideHeader text="Interactive Live Streaming" />
                    </tr>
                    <tr>
                      <SideHeader text="Viewer" isInternal />
                    </tr>

                    <tr>
                      <SideHeader text="Encoding" isInternal />
                    </tr>

                    <tr>
                      <SideHeader
                        text="Encoding (HD)"
                        isInternal
                        tooltipText="HD starts from 1080p"
                      />
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <TotalMinutes
                        mins={data?.interactive_live_streaming?.viewer}
                        hidden
                      />
                    </tr>
                    <tr>
                      <TotalMinutes
                        mins={data?.interactive_live_streaming?.viewer}
                      />
                    </tr>
                    <tr>
                      <TotalMinutes
                        mins={data?.interactive_live_streaming?.encoding.sd}
                      />
                    </tr>

                    <tr>
                      <TotalMinutes
                        mins={data?.interactive_live_streaming?.encoding.hd}
                      />
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <FreeMinutes
                        mins={data?.interactive_live_streaming?.viewer}
                        freeMins={10000}
                        hidden
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.interactive_live_streaming?.viewer}
                        freeMins={
                          freeMinsData.interactive_live_streaming.viewer
                        }
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.interactive_live_streaming?.encoding?.sd}
                        freeMins={
                          freeMinsData.interactive_live_streaming.encoding.sd
                        }
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.interactive_live_streaming?.encoding?.hd}
                        freeMins={10000}
                        isFree
                      />
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <SideHeader text="Recording" />
                    </tr>
                    <tr>
                      <SideHeader text="SFU Recording" isInternal />
                    </tr>

                    <tr>
                      <SideHeader text="Browser Recording" isInternal />
                    </tr>

                    <tr>
                      <SideHeader text="HLS Recording" isInternal />
                    </tr>
                    <tr>
                      <SideHeader
                        text="Recording (HD)"
                        tooltipText="HD starts from 720p"
                      />
                    </tr>

                    <tr>
                      <SideHeader text="SFU Recording" isInternal />
                    </tr>

                    <tr>
                      <SideHeader text="Browser Recording" isInternal />
                    </tr>

                    <tr>
                      <SideHeader text="HLS Recording" isInternal />
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <TotalMinutes
                        mins={
                          parseInt(
                            Math.floor(data?.recording?.browser_recording?.sd) +
                              Math.floor(data?.recording?.sfu_recording?.sd) +
                              Math.floor(data?.recording?.hls_recording?.sd)
                          ) || 0
                        }
                      />
                    </tr>

                    <tr>
                      <TotalMinutes mins={data?.recording?.sfu_recording?.sd} />
                    </tr>
                    <tr>
                      <TotalMinutes
                        mins={data?.recording?.browser_recording?.sd}
                      />
                    </tr>

                    <tr>
                      <TotalMinutes mins={data?.recording?.hls_recording?.sd} />
                    </tr>
                    <tr>
                      <TotalMinutes
                        mins={
                          parseInt(
                            Math.floor(data?.recording?.browser_recording?.hd) +
                              Math.floor(data?.recording?.sfu_recording?.hd) +
                              Math.floor(data?.recording?.hls_recording?.hd)
                          ) || 0
                        }
                      />
                    </tr>
                    <tr>
                      <TotalMinutes mins={data?.recording?.sfu_recording?.hd} />
                    </tr>
                    <tr>
                      <TotalMinutes
                        mins={data?.recording?.browser_recording?.hd}
                      />
                    </tr>
                    <tr>
                      <TotalMinutes mins={data?.recording?.hls_recording?.hd} />
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <FreeMinutes
                        mins={
                          parseInt(
                            Math.floor(data?.recording?.browser_recording?.sd) +
                              Math.floor(data?.recording?.sfu_recording?.sd) +
                              Math.floor(data?.recording?.hls_recording?.sd)
                          ) || 0
                        }
                        freeMins={freeMinsData.recording.sd}
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.recording?.sfu_recording?.sd}
                        freeMins={10000}
                        hidden
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.recording?.browser_recording?.sd}
                        freeMins={10000}
                        hidden
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.recording?.hls_recording?.sd}
                        freeMins={10000}
                        isFree
                        hidden
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={
                          parseInt(
                            Math.floor(data?.recording?.browser_recording?.hd) +
                              Math.floor(data?.recording?.sfu_recording?.hd) +
                              Math.floor(data?.recording?.hls_recording?.hd)
                          ) || 0
                        }
                        freeMins={10000}
                        isFree
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.recording?.sfu_recording?.hd}
                        freeMins={10000}
                        hidden
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.recording?.browser_recording?.hd}
                        freeMins={10000}
                        hidden
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.recording?.hls_recording?.hd}
                        freeMins={10000}
                        hidden
                      />
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr
              style={{
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <td
                className="borderedCell"
                style={{
                  borderBottomLeftRadius: "8px",
                }}
              >
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <SideHeader text="External Streaming (RTMP)" />
                    </tr>
                    <tr>
                      <SideHeader
                        text="External Streaming (RTMP - HD)"
                        tooltipText="HD starts from 720p"
                      />
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <TotalMinutes mins={data?.rtmp?.sd} />
                    </tr>

                    <tr>
                      <TotalMinutes mins={data?.rtmp?.hd} />
                    </tr>
                  </tbody>
                </table>
              </td>
              <td
                className="borderedCell"
                style={{
                  borderBottomRightRadius: "8px",
                }}
              >
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <FreeMinutes
                        mins={data?.rtmp?.sd}
                        freeMins={freeMinsData.rtmp.sd}
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.rtmp?.hd}
                        freeMins={10000}
                        isFree
                      />
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <SideHeader text="Transcription" />
                    </tr>
                    <tr>
                      <SideHeader
                        text="Closed Captions (CC) for Conferencing"
                        isInternal
                      />
                    </tr>
                    <tr>
                      <SideHeader text="Live Transcription (HLS)" isInternal />
                    </tr>

                    <tr>
                      <SideHeader text="Post Call Transcription" isInternal />
                    </tr>

                    <tr>
                      <SideHeader text="AI-Generated Summary" isInternal />
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <TotalMinutes hidden />
                    </tr>
                    <tr>
                      <TotalMinutes
                        mins={data?.transcription?.closed_captioning}
                      />
                    </tr>
                    <tr>
                      <TotalMinutes
                        mins={data?.transcription?.live_transcription}
                      />
                    </tr>
                    <tr>
                      <TotalMinutes
                        mins={data?.transcription?.post_call_transcription}
                      />
                    </tr>

                    <tr>
                      <TotalMinutes mins={data?.transcription?.summary} />
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <FreeMinutes hidden />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.transcription?.closed_captioning}
                        freeMins={
                          freeMinsData?.transcription?.closed_captioning
                        }
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.transcription?.live_transcription}
                        freeMins={
                          freeMinsData?.transcription?.live_transcription
                        }
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.transcription?.post_call_transcription}
                        freeMins={
                          freeMinsData?.transcription?.post_call_transcription
                        }
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.transcription?.summary}
                        freeMins={freeMinsData?.transcription?.summary}
                      />
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <SideHeader text="Plugins" />
                    </tr>
                    <tr>
                      <SideHeader text="Noise Cancellation" isInternal />
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <TotalMinutes
                        mins={data?.transcription?.live_transcription}
                        hidden
                      />
                    </tr>
                    <tr>
                      <TotalMinutes
                        mins={data?.plugins?.noise_cancellation_usage}
                      />
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="borderedCell">
                <table
                  className={classNames("min-w-full border-0", s["emptyTable"])}
                >
                  <tbody>
                    <tr>
                      <FreeMinutes
                        mins={data?.transcription?.live_transcription}
                        freeMins={10000}
                        hidden
                      />
                    </tr>
                    <tr>
                      <FreeMinutes
                        mins={data?.plugins?.noise_cancellation_usage}
                        freeMins={freeMinsData?.plugins?.noiseCancellation}
                      />
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
          <style jsx="true">
            {`
              table {
                border-collapse: collapse;
                border-radius: 8px;
                border-style: hidden;
                box-shadow: 0 0 0 1px transparent;
              }

              th {
                padding: 16px 24px;
                border: solid 1px #1c2027;
                border-collapse: collapse;
              }
              td {
                border-collapse: collapse;
                padding-top: 16px;
                padding-bottom: 16px;
                min-width: 100px;
                border: solid 1px #1c2027;
              }

              .borderedCell {
                border: solid 1px #1c2027;
              }

              tr {
                border: solid 1px #1c2027;
              }
              table tr:last-child td:first-child {
                border-bottom-left-radius: 8px;
              }

              table tr:last-child td:last-child {
                border-bottom-right-radius: 8px;
              }
              table tr:first-child th:first-child {
                border-top-left-radius: 8px;
              }

              table tr:first-child th:last-child {
                border-top-right-radius: 8px;
              }
            `}
          </style>
        </table>
      ) : (
        <Flex
          css={{ minWidth: "100%", minHeight: "100%" }}
          justify="center"
          align="center"
        >
          <Loading size={48} />
        </Flex>
      )}
    </Flex>
  );
}

export default UsageTable;

const TotalMinutes = ({ mins = 0, className = "", hidden = false }) => {
  return (
    <td
      className={classNames(className)}
      style={{
        visibility: hidden ? "hidden" : "visible",
        textAlign: "right",
        paddingRight: "24px",
      }}
    >
      <Text variant="body1" css={{ w: "100%" }}>
        {Math.floor(mins).toLocaleString()}
      </Text>
    </td>
  );
};

const FreeMinutes = ({
  mins = 0,
  freeMins = 0,
  isFree = false,
  hidden = false,
  className = "",
}) => {
  const leftMins = freeMins - Math.floor(mins);
  return (
    <td
      className={classNames(" w-full", className)}
      style={{
        visibility: hidden ? "hidden" : "visible",
        textAlign: "left",
        width: "200px !important",
        paddingLeft: "24px",
      }}
    >
      {!isFree ? (
        <Text
          variant="body1"
          css={{
            c: isFree
              ? "$textDisabled"
              : leftMins <= 0
              ? "$textDisabled"
              : "$textHighEmp",
            w: "100%",
            fontWeight: isFree ? "$regular" : "",
          }}
        >
          {leftMins > 0 ? `${leftMins.toLocaleString()} left` : "0 left"}
          <Text
            as="span"
            variant="caption"
            css={{ fontWeight: "$regular", c: "$textDisabled" }}
          >
            /{Math.floor(freeMins).toLocaleString()}
          </Text>
        </Text>
      ) : (
        <Text
          variant="caption"
          css={{
            c: "$textDisabled",
            w: "100%",
            fontWeight: "$regular",
            textAlign: "left",

            alignItems: "center",
            h: "$10",
            lineHeight: "$10",
            py: "3px",
          }}
        >
          (No free mins)
        </Text>
      )}
    </td>
  );
};

const SideHeader = ({
  text,
  isInternal = false,
  tooltipText = "",
  className,
}) => (
  <td
    className={classNames(
      `${isInternal ? "px-12 font-normal" : "px-6 font-medium"}`,
      className
    )}
    style={{ width: "160px !important" }}
  >
    <Flex align="center">
      <Text
        variant="body1"
        css={{
          c: isInternal ? "$textMedEmp" : "$textHighEmp",
          fontWeight: isInternal ? "$regular" : "$semiBold",
        }}
      >
        {text}
      </Text>
      <Tooltip
        disabled={!tooltipText}
        side="top"
        align="center"
        title={tooltipText}
      >
        {tooltipText ? (
          <Flex
            css={{
              c: isInternal ? "$textMedEmp" : "$textHighEmp",
              ml: "$4",
              w: "$10",
              h: "$10",
            }}
          >
            <InfoIcon size="18px" />
          </Flex>
        ) : (
          <></>
        )}
      </Tooltip>
    </Flex>
  </td>
);
