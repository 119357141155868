import api from "src/api";
import { getAPIURL } from "src/utils";

export const getPresignedUrl = async ({
  roomId,
  data,
  assetId,
}: {
  roomId: string;
  data: string;
  assetId: string;
}) => {
  return await api
    .service("dashboard")
    .get(getAPIURL("recordings/presigned-url-v2"), {
      room_id: roomId,
      remote_path: data,
      asset_id: assetId,
    });
};
