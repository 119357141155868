import React from "react";
import {
  GridVideoTileLayout,
  VideoTileLayout,
} from "@100mslive/types-prebuilt/elements/video_tile_layout";
import ConfigMultiSelect from "src/components/Common/ConfigMultiSelect";
import StatusString from "src/components/Common/StatusString";
import ValuePill from "src/components/Common/ValuePill";
import { defaultConferencing } from "src/data/defaultLayout";
import { toTitleCase } from "src/helpers";
import PrebuiltSidebarItemWrapper from "src/pages/Prebuilt/PrebuiltSidebarItemWrapper";
import { RoleLayouts } from "src/types/prebuilt";
import { Flex, Input, Slider, Switch, Text } from "@100mslive/react-ui";
type Props = {
  data: VideoTileLayout;
  setData: (data: VideoTileLayout) => void;
  invalidFields: Record<keyof GridVideoTileLayout, string> | undefined;
  roleLayouts: RoleLayouts;
  role: string;
};

const tileConstraints = { max: 49, min: 1, default: 9 };

function PrebuiltVideoTileLayoutConfig({
  data,
  setData,
  role,
  invalidFields,
  roleLayouts,
}: Props) {
  const prominentRoleClicked = (role: string) => {
    const rolesArray = data?.grid?.prominent_roles || [];
    let newRolesArray = [];
    if (rolesArray.includes(role)) {
      newRolesArray = [...rolesArray.filter(r => r !== role)];
    } else {
      newRolesArray = [...rolesArray, role];
    }

    setData({
      ...data,
      grid: { ...data?.grid, prominent_roles: newRolesArray },
    });
  };

  const setTilesInView = (value: number) => {
    setData({
      ...data,
      grid: { ...data?.grid, tiles_in_view: value },
    });
  };

  return (
    <>
      <Flex justify="between" align="center" css={{ my: "$md" }}>
        <PrebuiltSidebarItemWrapper
          title="Enable local tile inset"
          css={{ my: 0 }}
          isSwitchTitle={true}
          tooltipText={`${toTitleCase(
            role
          )}'s tile will appear as a floating window, instead of inside the grid`}
        >
          <StatusString
            content={invalidFields?.enable_local_tile_inset || ""}
          />
        </PrebuiltSidebarItemWrapper>
        <Switch
          checked={data?.grid?.enable_local_tile_inset}
          onCheckedChange={() => {
            setData({
              ...data,
              grid: {
                ...data?.grid,
                enable_local_tile_inset: !data?.grid?.enable_local_tile_inset,
              },
            });
          }}
        />
      </Flex>
      <PrebuiltSidebarItemWrapper
        title="Prominent roles"
        css={{ mt: 0 }}
        tooltipText={
          <Text variant="caption" css={{ color: "$textHighEmp" }}>
            Selected roles will be given prominence over other roles.{" "}
            <Text variant="caption" css={{ fontWeight: "$semiBold" }}>
              Only available on web, mweb and iOS.
            </Text>
          </Text>
        }
      >
        <ConfigMultiSelect
          inputText="Select prominent roles"
          optionsArray={Object.keys(roleLayouts)}
          onCheckedChange={prominentRoleClicked}
          isCheckedCondition={role =>
            ((data?.grid?.prominent_roles as string[]) || [])?.includes(role)
          }
        />
        <Flex css={{ w: "100%", flexWrap: "wrap" }}>
          {(data?.grid?.prominent_roles || []).map((roleName: string) => (
            <ValuePill
              key={roleName}
              content={roleName}
              onClick={() => prominentRoleClicked(roleName)}
            />
          ))}
        </Flex>
        <StatusString content={invalidFields?.prominent_roles || ""} />
      </PrebuiltSidebarItemWrapper>
      <Flex justify="between" align="center" css={{ my: "$md" }}>
        <PrebuiltSidebarItemWrapper
          isSwitchTitle={true}
          css={{ my: 0 }}
          title="Can spotlight peer"
          tooltipText={
            <Text variant="caption" css={{ color: "$textHighEmp" }}>
              {`${toTitleCase(
                role
              )} can spotlight a participant for everyone to see. `}
              <Text variant="caption" css={{ fontWeight: "$semiBold" }}>
                Only available on web, mweb and iOS.
              </Text>
            </Text>
          }
        >
          <StatusString
            content={invalidFields?.enable_spotlighting_peer || ""}
          />
        </PrebuiltSidebarItemWrapper>
        <Switch
          checked={data?.grid?.enable_spotlighting_peer}
          onCheckedChange={() => {
            setData({
              ...data,
              grid: {
                ...data?.grid,
                enable_spotlighting_peer: !data?.grid?.enable_spotlighting_peer,
              },
            });
          }}
        />
      </Flex>
      <Flex justify="between" align="center" css={{ my: "$sm", width: "100%" }}>
        <PrebuiltSidebarItemWrapper
          isSwitchTitle={true}
          css={{ my: 0, whiteSpace: "nowrap" }}
          title="Tiles in view"
          tooltipText={
            <Text
              variant="caption"
              css={{
                color: "$textHighEmp",
                maxWidth: "12rem",
                whiteSpace: "pre-wrap",
              }}
            >
              Select the default number of video tiles for this role in a room.
              Recommended to keep at 9 (default) for optimal performance. Only
              available on Web.
            </Text>
          }
        />
        <Flex
          align="center"
          justify="end"
          gap="2"
          css={{ width: "85%", pr: "$1" }}
        >
          <Slider
            onValueChange={e => setTilesInView(e[0])}
            css={{ width: "70%" }}
            value={[data.grid?.tiles_in_view || tileConstraints.default]}
            min={tileConstraints.min}
            max={tileConstraints.max}
          />
          <Input
            css={{
              width: "$11",
              p: "$1",
              fontSize: "$sm",
              textAlign: "center",
            }}
            value={data.grid?.tiles_in_view || tileConstraints.default}
            onChange={e =>
              setTilesInView(
                Math.max(
                  tileConstraints.min,
                  Math.min(tileConstraints.max, parseInt(e.target.value))
                )
              )
            }
            min={tileConstraints.min}
            max={tileConstraints.max}
          />
        </Flex>
      </Flex>
    </>
  );
}

export default PrebuiltVideoTileLayoutConfig;

const defaultData =
  defaultConferencing?.["default"]?.["elements"]?.["video_tile_layout"];

export const videoTileLayout = {
  ui: {
    title: "Video Tile Layout",
    hasSwitch: false,
    tooltipText: "",
  },
  path: "screens.conferencing.default.elements.video_tile_layout",
  Component: PrebuiltVideoTileLayoutConfig,
  defaultData: defaultData,
  validator: () => {},
};
