import React, { FC, ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RecentRoomCards from "src/components/Cards/RecentRoomCards";
import { ROLE_TYPES } from "src/constants";
import { AppAnalytics, history } from "src/helpers";
import { RootState } from "src/store/reducers";
import { getEmailfromLocal } from "src/utils";
import { AddCircleIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, Loading, Text } from "@100mslive/react-ui";

type RecentRoomsProps = {
  rooms: Record<string, ReactNode>[];
  templates: Record<string, ReactNode>[];
  isRoomLoading: boolean;
  toggleCreateRoomModal: () => unknown;
};

const RecentRooms: FC<RecentRoomsProps> = ({
  rooms,
  templates,
  toggleCreateRoomModal,
  isRoomLoading = false,
}) => {
  const current = useSelector((state: RootState) => state.workspace.current);
  const [isReadOnlyWorkspaceUser, setIsReadOnlyWorkspaceUser] = useState(true);

  useEffect(() => {
    let isReadOnlyUser = true;
    const currentMembers = current.members || [];
    const currentUserEmail = getEmailfromLocal();
    for (const member of currentMembers) {
      if (
        currentUserEmail === member?.email &&
        member?.role === ROLE_TYPES.READ_ONLY
      ) {
        isReadOnlyUser = true;
        break;
      } else {
        isReadOnlyUser = false;
      }
    }
    setIsReadOnlyWorkspaceUser(isReadOnlyUser);
  }, [current]);
  return (
    <Flex
      css={{
        border: "$space$px solid $borderDefault",
        r: "$1",

        maxHeight: "100%",
        w: "50%",
        p: "$12",

        mr: "$12",
      }}
      direction="column"
    >
      <Flex justify="between" align="center" css={{ pb: "$4" }}>
        <Text variant="h6">Recent Rooms</Text>
        {rooms.length ? (
          <Flex align="center">
            <Link to="/rooms">
              <Text variant="sub2" css={{ c: "$textMedEmp", mr: "$8" }}>
                View all
              </Text>
            </Link>

            <Button
              variant="primary"
              icon
              disabled={isReadOnlyWorkspaceUser}
              css={{ fontSize: "$sm", borderRadius: "$0" }}
              onClick={() => {
                if (rooms.length === 0) {
                  AppAnalytics.track("btn.clicked", {
                    btnId: "create.first.room",
                    componentId: "dashboardHome",
                    platform: "hms-dashboard",
                  });
                } else {
                  AppAnalytics.track("btn.clicked", {
                    btnId: "create.room",
                    componentId: "dashboardHome",
                    platform: "hms-dashboard",
                  });
                }
                toggleCreateRoomModal();
              }}
            >
              <AddCircleIcon />
              Create Room
            </Button>
          </Flex>
        ) : null}
      </Flex>
      {rooms.length ? (
        rooms.map((room: Record<string, ReactNode>, i: number) => {
          const roomTemplateIndex = templates.findIndex(
            (template: Record<string, ReactNode>) =>
              template.id === room?.template_id
          );
          let roomTemplate = {};
          if (roomTemplateIndex >= 0) {
            roomTemplate = templates[roomTemplateIndex];
          }
          return (
            <Box key={room?.["id"]?.toString()}>
              <RecentRoomCards room={room} template={roomTemplate} />
              {rooms.length - i - 1 > 0 ? (
                <Flex css={{ h: "$px", w: "100%", bg: "$borderDefault" }} />
              ) : null}
            </Box>
          );
        })
      ) : (
        <Flex
          css={{ w: "100%", h: "100%", minHeight: "450px" }}
          direction="column"
          justify="center"
          align="center"
        >
          {!isRoomLoading ? (
            <>
              <Text css={{ c: "$textHighEmp", mb: "$4" }} variant="h6">
                No rooms yet
              </Text>
              <Text
                variant="body2"
                css={{
                  w: "50%",
                  c: "$textMedEmp",
                  mb: "$12",
                  textAlign: "center",
                }}
              >
                Rooms help you interact with others in audio or video call.
              </Text>
              <Button
                disabled={isReadOnlyWorkspaceUser}
                variant="primary"
                icon
                css={{ fontSize: "$sm", borderRadius: "$0" }}
                onClick={() => {
                  if (rooms.length === 0) {
                    AppAnalytics.track("btn.clicked", {
                      btnId: "create.first.room",
                      componentId: "dashboardApp",
                      platform: "hms-dashboard",
                    });
                  } else {
                    AppAnalytics.track("btn.clicked", {
                      btnId: "create.room",
                      componentId: "dashboardApp",
                      platform: "hms-dashboard",
                    });
                  }
                  history.push("/create-room");
                }}
              >
                <AddCircleIcon />
                Create Room
              </Button>
            </>
          ) : (
            <Loading size={48} />
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default RecentRooms;
