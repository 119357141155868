import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isArray, isEmpty, set } from "lodash";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { API_CALL_STATE } from "src/constants";
import { fetchWorkspaceRecordingsDetails } from "src/store/insights/actions";
import { RootState } from "src/store/reducers";
import { StatsIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, Tooltip } from "@100mslive/react-ui";
import { ActiveRecordingsChart } from "../components/charts/activeRecordingsChart";
import { RecordingsBreakdownChart } from "../components/charts/recordingsBreakdownChart";
import { RecordingsBreakdownBriefChart } from "../components/charts/recordingsBreakdownChartBrief";
import { RecordingsFailureRateChart } from "../components/charts/recordingsFailureRateChart";
import TitleWithTrend from "../components/common/TitleWithTrend";
import { WORKSPACE_RECORDINGS_STATUS_BUCKETS } from "../constants";
import { convertDateToDiffDays } from "../helpers";
import { getGroupByRecordingsTypeArray } from "../helpers/convert";
import ChartLoaderLayout from "../layouts/ChartLoaderLayout";

export const WorkspaceRecordingsInsights = ({
  groupByRecordingsType,
}: {
  groupByRecordingsType: string;
}) => {
  const dispatch = useDispatch();
  const {
    startDate,
    endDate,
    filter,
    fetchWorkspaceRecordingsInsightsStatus,
    workspaceRecordingsInsightsDetails,
    workspaceRecordingsTATInsightsDetails,
    workspaceRecordingsInsightsTrends,
  } = useSelector((state: RootState) => state.insights);
  const [showDetails, setShowDetails] = useState(false);
  const range = convertDateToDiffDays({
    startDate: startDate || (new Date() as Date),
    endDate: endDate || (new Date() as Date),
  });

  useEffect(() => {
    if (startDate && endDate && filter) {
      dispatch(fetchWorkspaceRecordingsDetails());
    }
  }, [dispatch, endDate, filter, startDate]);

  const recordingTypeDataMap = getGroupByRecordingsTypeArray({
    groupByRecordingsType: groupByRecordingsType,
    workspaceRecordingsInsightsDetails: workspaceRecordingsInsightsDetails,
    workspaceRecordingsTATInsightsDetails:
      workspaceRecordingsTATInsightsDetails,
  });
  const getGroupByRecordingsStatusArray = useCallback(() => {
    let data = {} as Record<string, Record<string, number>>;
    const recordings = recordingTypeDataMap.recordings;
    const timeKeys = Object.keys(recordings);
    for (let i = 0; i < timeKeys.length; i++) {
      const arr = get(recordings, timeKeys[i]);
      const successArr = arr.filter(
        val =>
          val.event_type === WORKSPACE_RECORDINGS_STATUS_BUCKETS.success.key
      );
      const failArr = arr.filter(
        val => val.event_type === WORKSPACE_RECORDINGS_STATUS_BUCKETS.fail.key
      );

      const all = arr.reduce((acc, val) => acc + val.total_cnt, 0);
      const success = successArr.reduce((acc, val) => acc + val.total_cnt, 0);
      const fail = failArr.reduce((acc, val) => acc + val.total_cnt, 0);
      data = set(data, ["all", timeKeys[i]], all);
      data = set(data, ["success", timeKeys[i]], success);
      data = set(data, ["fail", timeKeys[i]], fail);
    }

    return data;
  }, [recordingTypeDataMap.recordings]);
  const recordingsStatusArray = getGroupByRecordingsStatusArray();
  const getFailureRatio = useCallback(() => {
    const recordings = recordingTypeDataMap.recordings;
    const failureRatios = [] as { time: string; ratio: number }[];
    const timeKeys = Object.keys(recordings);
    for (let i = 0; i < timeKeys.length; i++) {
      const ratio =
        recordingsStatusArray.fail[timeKeys[i]] /
        recordingsStatusArray.all[timeKeys[i]];
      failureRatios.push({
        time: timeKeys[i],
        ratio: isNaN(ratio) ? 0 : ratio,
      });
    }
    return failureRatios;
  }, [
    recordingTypeDataMap.recordings,
    recordingsStatusArray.all,
    recordingsStatusArray.fail,
  ]);
  return (
    <Flex direction="column" css={{ w: "100%" }}>
      <Box
        css={{
          display: "grid",
          w: "100%",
          gridTemplateColumns: "2fr 1fr",
          columnGap: "$10",
        }}
      >
        <Flex direction="column">
          <ChartLoaderLayout
            emptyStateMessage="Data unavailable. Begin utilizing 100ms and conduct some recordings in sessions to generate and display data here."
            status={fetchWorkspaceRecordingsInsightsStatus}
            header={
              <TitleWithTrend
                total={workspaceRecordingsInsightsTrends.curr_total}
                trend={workspaceRecordingsInsightsTrends.percent_change}
                tooltip={` The following number represents total recordings for the last ${range}, with a percentage change compared to the previous period. The graph displays the ${filter} distribution of recordings, categorized into total, completed, and failed.`}
                title="Total Recordings"
                makeTotalOpaque={
                  fetchWorkspaceRecordingsInsightsStatus === API_CALL_STATE.DONE
                }
              />
            }
            isDataEmpty={
              !isArray(workspaceRecordingsInsightsDetails) ||
              workspaceRecordingsInsightsDetails.length === 0
            }
            title="Total Recordings"
          >
            <ActiveRecordingsChart
              filter={filter}
              data={recordingsStatusArray}
              loading={
                fetchWorkspaceRecordingsInsightsStatus ===
                API_CALL_STATE.IN_PROGRESS
              }
            />
          </ChartLoaderLayout>
        </Flex>
        <ChartLoaderLayout
          emptyStateMessage="Data unavailable. Begin utilizing 100ms and conduct some recordings in sessions to generate and display data here."
          status={fetchWorkspaceRecordingsInsightsStatus}
          isDataEmpty={isEmpty(recordingTypeDataMap.TATBreakdown)}
          title="Recording TAT Breakdown"
          header={
            <Flex css={{ w: "100%" }} justify="between" align="center">
              <TitleWithTrend
                title="Recording TAT Breakdown"
                tooltip={`Recording Turnaround Time (TAT) is the duration from the successful completion of a recording to its processing, upload, and delivery. The following is a bucketed distribution of duration for all the recording TAT that were successfully processed in the last ${range}`}
                total={0}
                trend={0}
                showTotal={false}
              />
              <Tooltip
                title="Click to see Detailed Breakdown"
                align="center"
                side="top"
                boxCss={{ r: "$0" }}
              >
                <Button
                  variant="standard"
                  outlined={!showDetails}
                  css={{ p: "$1 $2", r: "$0" }}
                  onClick={() => setShowDetails(!showDetails)}
                >
                  <SmallIcon>
                    <StatsIcon />
                  </SmallIcon>
                </Button>
              </Tooltip>
            </Flex>
          }
        >
          <Flex direction="column">
            <RecordingsBreakdownBriefChart
              data={recordingTypeDataMap.TATBreakdown}
              loading={
                fetchWorkspaceRecordingsInsightsStatus ===
                API_CALL_STATE.IN_PROGRESS
              }
            />
          </Flex>
        </ChartLoaderLayout>
      </Box>
      <Flex
        css={{
          w: "100%",
          display: showDetails ? "block" : "none",
          maxHeight: showDetails ? "100%" : "0px",
          transition: "maxHeight 0.5s ease-in-out",
        }}
      >
        <ChartLoaderLayout
          emptyStateMessage="Data unavailable. Begin utilizing 100ms and conduct some recordings in sessions to generate and display data here."
          status={fetchWorkspaceRecordingsInsightsStatus}
          title="Recording Turnaround Time (TAT) Breakdown"
          isDataEmpty={isEmpty(recordingTypeDataMap.TATBreakdown)}
          header={
            <TitleWithTrend
              title="Recording Turnaround Time (TAT) Breakdown"
              tooltip={`Recording Turnaround Time (TAT) is the duration from the successful completion of a recording to its processing, upload, and delivery. The following is a bucketed distribution of duration for all the recording TAT that were successfully processed in the last ${range}`}
              total={0}
              trend={0}
              showTotal={false}
            />
          }
        >
          <RecordingsBreakdownChart
            data={recordingTypeDataMap.TATBreakdown}
            loading={
              fetchWorkspaceRecordingsInsightsStatus ===
              API_CALL_STATE.IN_PROGRESS
            }
          />
        </ChartLoaderLayout>
      </Flex>
      <Flex
        css={{
          w: "100%",
        }}
      >
        <ChartLoaderLayout
          emptyStateMessage="Data unavailable. Begin utilizing 100ms and conduct some recordings in sessions to generate and display data here."
          status={fetchWorkspaceRecordingsInsightsStatus}
          title="Recording Failure Percentage"
          isDataEmpty={
            !isArray(workspaceRecordingsInsightsDetails) ||
            workspaceRecordingsInsightsDetails.length === 0
          }
          header={
            <TitleWithTrend
              title="Recording Failure Percentage"
              tooltip={`Recording Failure rate denotes the failure rate of total recordings which were initiated. The following is a time series distribution of failure percentage for the recordings belonging to last ${range}`}
              total={0}
              trend={0}
              showTotal={false}
            />
          }
        >
          <RecordingsFailureRateChart
            filter={filter}
            data={getFailureRatio()}
            loading={
              fetchWorkspaceRecordingsInsightsStatus ===
              API_CALL_STATE.IN_PROGRESS
            }
          />
        </ChartLoaderLayout>
      </Flex>
    </Flex>
  );
};

export default WorkspaceRecordingsInsights;
