import React, { useMemo } from "react";
import * as echarts from "echarts";
import { default as EchartsWrapper } from "src/components/Echarts";
import { getSubscribePacketLossChartConfiguration } from "../../helpers/getSubscribeConfigurations";
type echartsOptions = echarts.EChartsOption;

export const SubscriberPacketLossChart = ({
  publishData,
  loading,
}: {
  publishData: any;
  loading: boolean;
}) => {
  const peerSubscriberPacketLossChartoptions: echartsOptions = useMemo(
    () => getSubscribePacketLossChartConfiguration(publishData),
    [publishData]
  );

  return (
    <>
      {/* @ts-ignore */}
      <EchartsWrapper
        options={{ ...peerSubscriberPacketLossChartoptions }}
        loading={loading}
        style={{ height: "240px", width: "auto" }}
      />
    </>
  );
};
