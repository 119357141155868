import React, { ComponentProps, FC, ReactNode, useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import TemplateConfigCard from "src/components/Cards/TemplateConfigCard";
import ConfigSingleSelect from "src/components/Common/ConfigSingleSelect";
import Credential from "src/components/Common/Credential";
import { API_CALL_STATE, UPLOAD_TYPES } from "src/constants";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { RecordingType } from "src/types/policyTypes";
import { currentWorkspaceHipaa, slugify } from "src/utils";
import { validateRecordings } from "src/validations/destinations";
import { Button, Dropdown, Flex, Text } from "@100mslive/react-ui";

interface GeneralRecordingStorageProps {
  recordingInfo: RecordingType;
  setRecordingInfo: (key: string, value: ReactNode) => void;
  invalidFields?: {
    recordingInfo: Record<string, string | undefined>;
  };
  onValidateRecordingInfo: (recordingInfo: RecordingType) => void;
  setZipUpload: (value: boolean) => void;
}

const toolTipIfUploadTypeIsNotSet = "Please select upload type";
// eslint-disable-next-line complexity
const GeneralRecordingStorage: FC<
  ComponentProps<typeof Flex> & GeneralRecordingStorageProps
  // eslint-disable-next-line complexity
> = ({
  recordingInfo,
  setRecordingInfo,
  invalidFields,
  onValidateRecordingInfo,
  setZipUpload,
}) => {
  const isHipaa = currentWorkspaceHipaa();
  const getIsSecret = (label: string) =>
    label === "Access Key" || label === "Secret Key" || label === "Account ID";
  const uploadTypes = isHipaa
    ? UPLOAD_TYPES.filter(type => type.name !== "100ms")
    : UPLOAD_TYPES;
  const selectedUploadType =
    uploadTypes.find(type => type.value === recordingInfo?.upload?.type)
      ?.name || uploadTypes[0].name;

  const canValidateRecordings = (recordingInfo: RecordingType) => {
    const validationResult = validateRecordings(recordingInfo);
    if (
      recordingInfo?.upload?.type === "s3" ||
      recordingInfo?.upload?.type === "oss" ||
      recordingInfo?.upload?.type === "gs" ||
      recordingInfo?.upload?.type === "r2"
    ) {
      return validationResult.isValid;
    }
    return false;
  };
  const awsDetailsStatus = useSelector(
    (state: RootStateOrAny) => state.verifyAWSDetails
  );
  const canValidate = canValidateRecordings(recordingInfo);

  useEffect(() => {
    if (isHipaa && isEmpty(recordingInfo)) {
      setRecordingInfo("upload.type", "s3");
    }
    //eslint-disable-next-line
  }, [isHipaa]);

  return (
    <TemplateConfigCard
      text="Storage"
      subtitle={
        <Text
          variant="body2"
          css={{ c: "$textDisabled", fontWeight: "$regular", mt: "$4" }}
        >
          By default, 100ms stores recordings, for up to 15 days. Configure your
          own cloud storage location for more control and longevity.
          <a
            href={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/features/recordings/recording-assets/storage-configuration`}
            rel="noreferrer noopener"
            target="_blank"
          >
            <Text
              variant="body2"
              css={{ c: "$primaryLight", fontWeight: "$regular" }}
              as="span"
            >
              &nbsp;Learn more
            </Text>
          </a>
        </Text>
      }
      id={slugify("Storage")}
      bottomButton={
        <Flex
          align="center"
          justify="end"
          css={{ borderTop: "solid $space$px $borderDefault", p: "$6 0" }}
        >
          <Button
            css={{ r: "$0", p: "$3 $8" }}
            disabled={!canValidate}
            loading={awsDetailsStatus === API_CALL_STATE.IN_PROGRESS}
            onClick={() => onValidateRecordingInfo(recordingInfo)}
          >
            Validate
          </Button>
        </Flex>
      }
      classNameForText="config-subheading"
      leftComponent={
        recordingInfo?.upload?.type ? (
          <Flex
            direction="column"
            css={{
              r: "$0",
              p: "$8 $6",
              bg: "$surfaceLight",
              border: "$space$px solid $borderLight",
            }}
          >
            <Text variant="body2" css={{ c: "$textMedEmp", mb: "$4" }}>
              Disclaimer
            </Text>
            <Text
              variant="body2"
              css={{ c: "$textDisabled", fontWeight: "$regular" }}
            >
              {isHipaa
                ? `Only write permissions are required to configure ${selectedUploadType}. 100ms Storage cannot be used for HIPAA workspaces.`
                : `Both read and write permissions are required to configure ${selectedUploadType}.`}
            </Text>
          </Flex>
        ) : null
      }
      rightComponent={
        <Flex direction="column" css={{ minWidth: "100%" }}>
          <Flex
            direction="column"
            css={{
              pt: "$10",
              pb: recordingInfo?.upload?.type ? "$10" : "0",
              minWidth: "100%",
              borderBottom: recordingInfo?.upload?.type
                ? "$space$px solid $borderDefault"
                : "none",
            }}
          >
            <SettingsTitle
              key="uploadType"
              title="Upload Destination type. Amazon S3, Google Cloud Storage, and Alibaba OSS are currently supported"
              text="Upload Type"
            />
            <Flex css={{ mt: "$4", position: "relative" }}>
              <ConfigSingleSelect
                rootCSS={{ width: "50%" }}
                triggerCSS={{ width: "min(100%,232px)" }}
                inputText={selectedUploadType}
                content={
                  <>
                    {uploadTypes.map(type => {
                      return (
                        <Dropdown.Item
                          key={type.value}
                          css={{ p: "$4 $8" }}
                          onClick={() => {
                            setRecordingInfo("upload.type", type.value);
                            if (type.value) {
                              [
                                "upload.credentials",
                                "upload.location",
                                "upload.options.region",
                                "upload.prefix",
                              ].forEach(key =>
                                setRecordingInfo(key, undefined)
                              );
                            } else {
                              // object set to blank for 100ms bucket
                              setRecordingInfo("upload", null);

                              // set vod upload format to unzipped
                              //
                              // this does not have any effect if hls destinations
                              // are not set.
                              setZipUpload(false);
                            }
                          }}
                        >
                          <Text variant="caption">{type.name}</Text>
                        </Dropdown.Item>
                      );
                    })}
                  </>
                }
              />
            </Flex>
          </Flex>
          {recordingInfo?.upload?.type ? (
            <>
              <Flex
                direction="column"
                css={{
                  py: "$10",
                  minWidth: "100%",
                  borderBottom: "$space$px solid $borderDefault",
                }}
              >
                <SettingsTitle
                  key="accessKey"
                  title="Access Key for the account hosting the storage bucket for storing recordings"
                  text="Access Key"
                />
                <Flex css={{ mt: "$4", position: "relative" }}>
                  <Credential
                    inputCss={{ r: "$0" }}
                    readOnly={false}
                    showClipboard={false}
                    getIsSecret={getIsSecret}
                    label="Access Key"
                    onChangeHandler={val =>
                      setRecordingInfo("upload.credentials.key", val)
                    }
                    hideLabel
                    value={recordingInfo?.upload?.credentials?.key || ""}
                    title={
                      !selectedUploadType ? toolTipIfUploadTypeIsNotSet : ""
                    }
                    disabled={!recordingInfo?.upload?.type}
                    error={
                      (
                        invalidFields?.["recordingInfo"] as Record<
                          string,
                          string | undefined
                        >
                      )?.["accessKey"]
                    }
                  />
                  {invalidFields?.recordingInfo?.accessKey ? (
                    <Text
                      variant="caption"
                      css={{ c: "$error", position: "absolute", bottom: "-$9" }}
                    >
                      {invalidFields?.recordingInfo?.accessKey}
                    </Text>
                  ) : null}
                </Flex>
              </Flex>
              <Flex
                direction="column"
                css={{
                  py: "$10",
                  minWidth: "100%",
                  borderBottom: "$space$px solid $borderDefault",
                }}
              >
                <SettingsTitle
                  key="secretKey"
                  text="Secret Key"
                  title="Secret Key for the account hosting the storage bucket for storing recordings"
                />
                <Flex css={{ mt: "$4", position: "relative" }}>
                  <Credential
                    inputCss={{ r: "$0" }}
                    readOnly={false}
                    showClipboard={false}
                    getIsSecret={getIsSecret}
                    label="Secret Key"
                    onChangeHandler={val =>
                      setRecordingInfo("upload.credentials.secretKey", val)
                    }
                    error={invalidFields?.recordingInfo?.secretKey}
                    hideLabel
                    value={recordingInfo?.upload?.credentials?.secretKey || ""}
                    title={
                      !selectedUploadType ? toolTipIfUploadTypeIsNotSet : ""
                    }
                    disabled={!recordingInfo?.upload?.type}
                  />

                  {invalidFields?.recordingInfo?.secretKey ? (
                    <Text
                      variant="caption"
                      css={{ c: "$error", position: "absolute", bottom: "-$9" }}
                    >
                      {invalidFields?.recordingInfo?.secretKey}
                    </Text>
                  ) : null}
                </Flex>
              </Flex>
              <Flex
                direction="column"
                css={{
                  py: "$10",
                  minWidth: "100%",
                  borderBottom: "$space$px solid $borderDefault",
                }}
              >
                <SettingsTitle
                  key="bucket"
                  title="Name of the storage bucket in which the recordings should be stored"
                  text="Bucket"
                />
                <Flex css={{ mt: "$4", position: "relative" }}>
                  <Credential
                    inputCss={{ r: "$0" }}
                    readOnly={false}
                    showClipboard={false}
                    getIsSecret={getIsSecret}
                    label="Bucket"
                    onChangeHandler={val =>
                      setRecordingInfo("upload.location", val)
                    }
                    error={invalidFields?.recordingInfo?.location}
                    hideLabel
                    value={recordingInfo?.upload?.location || ""}
                    title={
                      !selectedUploadType ? toolTipIfUploadTypeIsNotSet : ""
                    }
                    disabled={!recordingInfo?.upload?.type}
                  />
                  {invalidFields?.recordingInfo?.location ? (
                    <Text
                      variant="caption"
                      css={{ c: "$error", position: "absolute", bottom: "-$9" }}
                    >
                      {invalidFields?.recordingInfo?.location}
                    </Text>
                  ) : null}
                </Flex>
              </Flex>
              <Flex
                direction="column"
                css={{
                  py: "$10",
                  minWidth: "100%",
                  borderBottom: "$space$px solid $borderDefault",
                }}
              >
                <SettingsTitle
                  key="region"
                  title="Region name for your storage. For example: ap-south-1"
                  text="Region"
                />
                <Flex css={{ mt: "$4", position: "relative" }}>
                  <Credential
                    inputCss={{ r: "$0" }}
                    readOnly={false}
                    showClipboard={false}
                    getIsSecret={getIsSecret}
                    label="region"
                    error={invalidFields?.recordingInfo?.region}
                    onChangeHandler={val =>
                      setRecordingInfo("upload.options.region", val)
                    }
                    hideLabel
                    value={recordingInfo?.upload?.options?.region || ""}
                    title={
                      !selectedUploadType ? toolTipIfUploadTypeIsNotSet : ""
                    }
                    disabled={!recordingInfo?.upload?.type}
                  />
                  {invalidFields?.recordingInfo?.region ? (
                    <Text
                      variant="caption"
                      css={{ c: "$error", position: "absolute", bottom: "-$9" }}
                    >
                      {invalidFields?.recordingInfo?.region}
                    </Text>
                  ) : null}
                </Flex>
              </Flex>
              {recordingInfo?.upload?.type === "r2" ? (
                <Flex
                  direction="column"
                  css={{
                    py: "$10",
                    minWidth: "100%",
                    borderBottom: "$space$px solid $borderDefault",
                  }}
                >
                  <SettingsTitle
                    key="accountId"
                    title="Account ID of your Cloudflare R2 storage."
                    text="Account ID"
                  />
                  <Flex css={{ mt: "$4", position: "relative" }}>
                    <Credential
                      inputCss={{ r: "$0" }}
                      readOnly={false}
                      showClipboard={false}
                      getIsSecret={getIsSecret}
                      label="Account ID"
                      error={invalidFields?.recordingInfo?.accountId}
                      onChangeHandler={val =>
                        setRecordingInfo("upload.options.account_id", val)
                      }
                      hideLabel
                      value={recordingInfo?.upload?.options?.account_id || ""}
                      title={
                        !selectedUploadType ? toolTipIfUploadTypeIsNotSet : ""
                      }
                      disabled={!recordingInfo?.upload?.type}
                    />
                    {invalidFields?.recordingInfo?.accountId ? (
                      <Text
                        variant="caption"
                        css={{
                          c: "$error",
                          position: "absolute",
                          bottom: "-$9",
                        }}
                      >
                        {invalidFields?.recordingInfo?.accountId}
                      </Text>
                    ) : null}
                  </Flex>
                </Flex>
              ) : null}
              <Flex
                direction="column"
                css={{
                  pt: "$10",
                  minWidth: "100%",
                }}
              >
                <SettingsTitle
                  key="prefix"
                  title="Set prefix for your upload path. This is optional"
                  text="Prefix for upload path"
                />
                <Flex css={{ mt: "$4", position: "relative" }}>
                  <Credential
                    inputCss={{ r: "$0" }}
                    readOnly={false}
                    showClipboard={false}
                    getIsSecret={getIsSecret}
                    label="Prefix"
                    onChangeHandler={val =>
                      setRecordingInfo("upload.prefix", val)
                    }
                    hideLabel
                    value={recordingInfo?.upload?.prefix || ""}
                    title={
                      !selectedUploadType ? toolTipIfUploadTypeIsNotSet : ""
                    }
                    disabled={!recordingInfo?.upload?.type}
                    error={invalidFields?.recordingInfo?.prefix}
                  />
                  {invalidFields?.recordingInfo?.prefix ? (
                    <Text
                      variant="caption"
                      css={{ c: "$error", position: "absolute", bottom: "-$9" }}
                    >
                      {invalidFields?.recordingInfo?.prefix}
                    </Text>
                  ) : null}
                </Flex>
              </Flex>
            </>
          ) : null}
        </Flex>
      }
    />
  );
};

export default GeneralRecordingStorage;
