import React, {
  ComponentProps,
  FC,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { isEmpty, set } from "lodash";
import {
  addInternalRecorder,
  fetchPolicyInfo,
  setHasUnsavedRecordingSettings,
  toggleBrowserComposite,
  toggleHLSDestination,
  toggleRtmp,
  toggleStreamRecording,
  toggleTrackRecording,
  updateHLSDestinations,
  updatePolicyAndAppLayout,
  updatePolicyInfo,
  updatePolicyRole,
  updateRecordingInfo,
  updateRecordingsConfig,
  validateRecordingInfo,
} from "src/actions/RolesActions";
import SectionHeader from "src/components/Common/SectionHeader";
import { SideList } from "src/components/Common/SideList";
import toastr from "src/components/Common/toastr";
import TemplateLayout from "src/components/layout/templateLayout";
import { API_CALL_STATE } from "src/constants";
import { INTERNAL_RECORDER_ROLE } from "src/helpers";
import useActiveLinks from "src/hooks/useActiveLink";
import { RootState } from "src/store/reducers";
import {
  VERIFY_AWS_RESET,
  verifyAWSDetails,
} from "src/store/validations/verifyAWS/actions";
import { RecordingConfigType, RecordingType } from "src/types/policyTypes";
import { currentWorkspaceHipaa, slugify } from "src/utils";
import {
  validateRecordings,
  validateRecordingsConfigFields,
} from "src/validations/destinations";
import { Flex } from "@100mslive/react-ui";
import CompositeRecordingConfig from "./CompositeRecordingConfig";
import GeneralRecordingStorage from "./GeneralRecordingStorage";

const RecordingConfig: FC<
  ComponentProps<typeof Flex> & {
    policyId: string;
    isValidInternalRecorderState: boolean;
  }
> = ({ policyId, isValidInternalRecorderState }) => {
  const tabs = ["Recording Modes", "Recording Configuration", "Storage"];

  const invalidFields = useSelector(
    (state: RootState) => state.roles.invalidFields
  );
  const dispatch = useDispatch();
  const policyDerivedStates = useSelector(
    (state: RootState) => state.roles.policyDerivedStates
  );
  const roleLayouts = useSelector(
    (state: RootState) => state.appLayouts.roleLayouts
  );
  const isHLSorRTMPEnabled =
    policyDerivedStates?.hlsDestinationEnabled ||
    policyDerivedStates?.rtmpDestinationsEnabled;

  const updatePolicyStatus = useSelector(
    (state: RootState) => state.roles.updatePolicyStatus
  );
  const hasUnsavedRecordingSettings = useSelector(
    (state: RootState) => state.roles.hasUnsavedRecordingSettings
  );
  const policyInfo = useSelector((state: RootState) => state.roles.policyInfo);
  const internalRecorderSubscriptionList = Object.values(
    policyInfo?.roles?.[INTERNAL_RECORDER_ROLE]?.subscribeParams
      ?.subscribeToRoles || {}
  ) as string[];

  const setZipUpload = (value: boolean) => {
    const hlsDestinations = policyInfo?.destinations?.hlsDestinations || {};
    const hlsDestinationIds = Object.keys(hlsDestinations) || [];
    const destinationId = hlsDestinationIds[0];

    // update only if an hls destination is present
    if (destinationId) {
      let updatedHLSDestination = { ...hlsDestinations[destinationId] };
      updatedHLSDestination = set(
        updatedHLSDestination,
        "recording.enableZipUpload",
        value
      );
      dispatch(updateHLSDestinations(updatedHLSDestination));
    }
  };

  const subscribeToClicked = useCallback(
    (roleName: string) => {
      const path = "subscribeParams.subscribeToRoles";

      if (internalRecorderSubscriptionList.includes(roleName)) {
        const i = internalRecorderSubscriptionList.indexOf(roleName);
        internalRecorderSubscriptionList.splice(i, 1);
      } else {
        internalRecorderSubscriptionList.push(roleName);
      }
      dispatch(setHasUnsavedRecordingSettings(true));
      dispatch(
        updatePolicyRole({
          roleName: INTERNAL_RECORDER_ROLE,
          path,
          value: [...internalRecorderSubscriptionList],
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, internalRecorderSubscriptionList]
  );

  useEffect(() => {
    if (!policyId) {
      return;
    }
    dispatch(fetchPolicyInfo(policyId));
    dispatch(setHasUnsavedRecordingSettings(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyId]);

  useEffect(() => {
    if (updatePolicyStatus === API_CALL_STATE.DONE) {
      dispatch(fetchPolicyInfo(policyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePolicyStatus]);

  useEffect(() => {
    if (policyInfo?.settings?.recording) {
      dispatch(validateRecordingInfo());
    }
    return () => {
      dispatch(fetchPolicyInfo(policyId));
      dispatch({ type: VERIFY_AWS_RESET });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line complexity
  const saveHandler = useCallback(() => {
    let recordingsStorageValidationResult = { isValid: true };
    let recordingsDestinationValidationResult = { isValid: true };
    if (!isEmpty(policyInfo?.settings?.recording)) {
      recordingsStorageValidationResult = validateRecordings(
        policyInfo?.settings?.recording
      );
    }
    if (!isEmpty(policyInfo?.recordings?.[0])) {
      const recordingConfig = policyInfo
        ?.recordings?.[0] as RecordingConfigType;
      recordingsDestinationValidationResult = validateRecordingsConfigFields({
        recordingConfig: recordingConfig,
      });
      dispatch(
        updateRecordingsConfig({
          recordingConfig: recordingConfig,
        })
      );
    }

    const validationResult = {
      isValid:
        recordingsDestinationValidationResult.isValid &&
        recordingsStorageValidationResult.isValid &&
        isValidInternalRecorderState,
    };

    if (validationResult.isValid) {
      const recordings = (policyInfo?.recordings?.[0] ||
        {}) as RecordingConfigType;
      const enableAutoStartRecording =
        recordings?.compositeRecording?.browserComposite?.autoStart || false;
      if (enableAutoStartRecording) {
        dispatch(toggleHLSDestination(false));
        dispatch(toggleRtmp(false));
        if (!policyInfo?.roles?.[INTERNAL_RECORDER_ROLE]) {
          dispatch(addInternalRecorder());
        }
      }
      dispatch(setHasUnsavedRecordingSettings(false));
      dispatch(updateRecordingInfo(policyInfo?.settings?.recording));

      if (!isEmpty(roleLayouts)) {
        dispatch(
          updatePolicyAndAppLayout({
            policy: policyInfo,
            roleLayouts: roleLayouts,
          })
        );
      } else {
        dispatch(updatePolicyInfo(policyId, policyInfo));
      }
    } else {
      toastr.error("Please enter valid values to save changes");
    }
  }, [
    dispatch,
    isValidInternalRecorderState,
    policyId,
    policyInfo,
    roleLayouts,
  ]);

  const isHipaa = currentWorkspaceHipaa();
  const activeSubheading = useActiveLinks("config-subheading");
  const recordingsConfig = policyInfo?.recordings?.[0] as RecordingConfigType;
  const browserComposite =
    recordingsConfig?.compositeRecording?.browserComposite || {};
  const isBrowserCompositeEnabled = browserComposite?.enabled || false;
  const customComposite =
    recordingsConfig?.compositeRecording?.customComposite || {};
  const isCustomCompositeEnabled = customComposite?.enabled || false;
  const isCompositeRecordingEnabled =
    isBrowserCompositeEnabled || isCustomCompositeEnabled;

  return (
    <TemplateLayout
      unsaved={hasUnsavedRecordingSettings}
      onSaveClick={saveHandler}
      isValid={Object.keys(invalidFields.recordingInfo).length === 0}
    >
      <Flex
        direction="column"
        css={{
          minWidth: "210px",
          position: "sticky",
          overflow: "clip auto",
          top: "$20",
          h: "100%",
        }}
      >
        {tabs.map((tab: string) => {
          const url = slugify(tab);
          return (
            <HashLink
              to={`?tab=recording#${url}`}
              style={{ width: "100%" }}
              key={tab}
              onClick={e => e.stopPropagation()}
            >
              <SideList
                key={tab}
                title={tab}
                active={activeSubheading === url}
              />
            </HashLink>
          );
        })}
      </Flex>

      <Flex
        direction="column"
        css={{ rowGap: "$12", w: "100%", left: "240px", flexGrow: 1 }}
      >
        <SectionHeader
          title="Recording"
          buttonText="Read Guide"
          body="Used to save audio/video calls for offline viewing."
          link={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/features/recordings/overview`}
        />
        <CompositeRecordingConfig
          isHLSorRTMPEnabled={isHLSorRTMPEnabled}
          isValidInternalRecorderState={isValidInternalRecorderState}
          policyInfo={policyInfo}
          recordingsConfig={policyInfo?.recordings?.[0] as RecordingConfigType}
          setRecordingInfo={(key: string, value: ReactNode) => {
            const tempPolicy = { ...policyInfo };
            set(tempPolicy, `settings.recording.${key}`, value);
            dispatch(setHasUnsavedRecordingSettings(true));
            dispatch(updateRecordingInfo(policyInfo.settings.recording));
          }}
          setRecordingsConfig={(key, value) => {
            const recordingConfig = policyInfo
              ?.recordings?.[0] as RecordingConfigType;
            set(recordingConfig, key, value);
            dispatch(
              updateRecordingsConfig({
                recordingConfig,
              })
            );
            dispatch(setHasUnsavedRecordingSettings(true));
          }}
          toggleTrackRecording={(e?: boolean) => {
            dispatch(toggleTrackRecording(e as boolean));
            dispatch(setHasUnsavedRecordingSettings(true));
          }}
          toggleStreamRecording={(e?: boolean) => {
            dispatch(toggleStreamRecording(e as boolean));
            dispatch(setHasUnsavedRecordingSettings(true));
          }}
          toggleBrowserComposite={(e?: boolean) => {
            dispatch(toggleBrowserComposite(e as boolean));
            dispatch(setHasUnsavedRecordingSettings(true));
          }}
          invalidFields={invalidFields}
          subscribeToClicked={subscribeToClicked}
        />
        {!isHipaa || isCompositeRecordingEnabled ? (
          <GeneralRecordingStorage
            recordingInfo={policyInfo?.settings?.recording as RecordingType}
            setRecordingInfo={(key: string, value: ReactNode) => {
              const tempPolicy = { ...policyInfo };
              set(tempPolicy, `settings.recording.${key}`, value);
              dispatch(setHasUnsavedRecordingSettings(true));
              dispatch(updateRecordingInfo(policyInfo.settings.recording));
            }}
            invalidFields={invalidFields}
            onValidateRecordingInfo={(recordingInfo: RecordingType) => {
              dispatch(validateRecordingInfo());
              dispatch(verifyAWSDetails(recordingInfo, isHipaa));
            }}
            setZipUpload={setZipUpload}
          />
        ) : null}
      </Flex>
    </TemplateLayout>
  );
};

export default RecordingConfig;
