import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchPublicTemplates } from "src/actions";
import { fetchTemplatesData } from "src/actions/RolesActions";
import { history } from "src/helpers";
import { signedUpUser } from "src/utils";
import OnboardingFlowWithLayout from "./containers/OnboardingFlowWithLayout";

function OnboardingFlowWithUseCase() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTemplatesData());
    dispatch(fetchPublicTemplates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //to be added back after create template flow is complete
  if (!signedUpUser()) {
    history.push("/create-template");
  }

  return <OnboardingFlowWithLayout />;
}

export default OnboardingFlowWithUseCase;
