import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchPublicTemplates } from "src/actions";
import { fetchTemplatesData } from "src/actions/RolesActions";
import { history } from "src/helpers";
import { fetchWorkspaces } from "src/store/actions";
import { signedUpUser } from "src/utils";
import SetTemplateFlowWithLayout from "./containers/setTemplateFlowWithLayout";

function SetTemplateFlowWithUseCase() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWorkspaces());
    dispatch(fetchTemplatesData());
    dispatch(fetchPublicTemplates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //to be added back after create template flow is complete
  if (signedUpUser()) {
    history.push("/onboarding");
  }

  return <SetTemplateFlowWithLayout />;
}

export default SetTemplateFlowWithUseCase;
