import { Action, combineReducers, Reducer } from "redux";
import { roomDebugger } from "./analytics/reducer";
import { appLayouts } from "./appLayout/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import { createRoom } from "./createRoom/reducer";
import { createTemplate } from "./createTemplate/reducer";
import { devRevSupportState } from "./devRevSupport/reducer";
import { insights } from "./insights/reducer";
// Front
import Layout from "./layout/reducer";
import { organisation } from "./organisation/reducer";
import { rooms } from "./rooms/reducer";
import { sessions } from "./sessions/reducer";
import { userInfo } from "./userInfo/reducer";
import { verifyAWSDetails } from "./validations/verifyAWS/reducer";
import { workspace } from "./workspace/reducer";
import { coachmarks } from "../reducers/CoachMarksReducer";
import { createApp } from "../reducers/CreateAppReducer";
import { roles } from "../reducers/RolesReducer";

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  createApp,
  roles,
  coachmarks,
  sessions,
  roomDebugger,
  rooms,
  verifyAWSDetails,
  workspace,
  organisation,
  createTemplate,
  createRoom,
  appLayouts,
  devRevSupportState,
  userInfo,
  insights,
});

const rootReducer: Reducer = (state: RootState, action: Action) => {
  // Clear all data in redux store to initial.
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
