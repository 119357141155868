import React from "react";
import GoogleLogin from "react-google-login";
import { Link, useHistory } from "react-router-dom";
import api from "../../api";
import AuthForm from "../../components/AuthForm";
import { saveCurrentUser } from "../../utils";

const verifyAuthToken = async tokenObj => {
  try {
    let res = await api
      .service("dashboard")
      .post("oauth-callback/google/", tokenObj);
    saveCurrentUser({
      admin: true,
      ...res.data,
    });
  } catch (err) {
    console.error("Google Token error", err);
  }
};

const refreshTokenSetup = data => {
  let refreshTiming = (data.tokenObj.expires_in || 3600 - 5 * 60) * 1000;
  const refreshToken = async () => {
    const newAuthRes = await data.reloadAuthResponse();
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
    verifyAuthToken(newAuthRes);
    setTimeout(refreshToken, refreshTiming);
  };
  setTimeout(refreshToken, refreshTiming);
};

export default function AdminLogin() {
  const history = useHistory();

  const handleLogin = async data => {
    await verifyAuthToken(data.tokenObj);
    refreshTokenSetup(data);
    history.push("/admin");
  };

  return (
    <AuthForm title="100ms Admin Login" subtitle="Dashboard for 100ms admins.">
      <div className="text-center">
        <div>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            isSignedIn={false}
            onSuccess={handleLogin}
            onFailure={error => console.error("Google Login Failure", error)}
            cookiePolicy="single_host_origin"
            theme="dark"
          />
        </div>
        <p className="mt-4">
          <Link to="/login" className="font-weight-medium text-primary">
            Customer Login
          </Link>
        </p>
      </div>
    </AuthForm>
  );
}
