import React from "react";
import { Box } from "@100mslive/react-ui";

const LeftBorderBox = ({ show }: { show: boolean }) => (
  <Box
    css={{
      position: "absolute",
      left: "0",
      top: "22px",
      height: "32px",
      width: "4px",
      opacity: show ? "1" : "0",
      transition: "opacity 0.1s ease-in",
      backgroundColor: "$primaryLight",
      borderRadius: "0 $1 $1 0",
    }}
  />
);

export default LeftBorderBox;
