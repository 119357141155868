import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { UNSAVED_CHANGES_TEXT } from "src/constants";
import { RootState } from "src/store/reducers";
import { ExitIcon } from "@100mslive/react-icons";
import { Flex, Text } from "@100mslive/react-ui";

function PrebuiltHeaderButtons() {
  const { policyId } = useParams<any>();
  const hasUnsavedPrebuiltChanges = useSelector(
    (state: RootState) => state.appLayouts.hasUnsavedPrebuiltChanges
  );
  return (
    <Flex>
      <Link to={`/templates/${policyId}`}>
        <Flex
          css={{
            cursor: "pointer",
            alignItems: "center",
            c: "$textMedEmp",
          }}
          onClick={e => {
            if (hasUnsavedPrebuiltChanges) {
              if (!window.confirm(UNSAVED_CHANGES_TEXT)) {
                e.preventDefault();
                return;
              }
            }
          }}
        >
          <ExitIcon
            style={{ transform: "rotate(180deg)" }}
            width={20}
            height={20}
          />
          <Text variant="sub2" css={{ ml: "$4", c: "$textMedEmp" }}>
            Dashboard
          </Text>
        </Flex>
      </Link>
    </Flex>
  );
}

export default PrebuiltHeaderButtons;
