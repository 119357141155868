import { handleActions } from "redux-actions";
import { API_CALL_STATE } from "src/constants";
import { cmsTemplateType } from "src/types/cmsTypes";
import {
  FETCH_PUBLIC_TEMPLATES_DONE,
  FETCH_PUBLIC_TEMPLATES_FAIL,
  FETCH_PUBLIC_TEMPLATES_INIT,
  FETCH_STRAPI_STARTER_KITS_DONE,
  FETCH_STRAPI_STARTER_KITS_FAIL,
  FETCH_STRAPI_STARTER_KITS_INIT,
} from "../actions/CreateAppActions";

const initialState = {
  fetchingTemplateStatus: "",
  templates: [],
  publicTemplates: [] as cmsTemplateType[],
  publicTemplatesStatus: "",
  roles: {},
  strapiStarterKitsStatus: "",
  strapiStarterKits: [],
};

type CreateAppState = typeof initialState;

export const createApp = handleActions<CreateAppState, any>(
  {
    [FETCH_PUBLIC_TEMPLATES_INIT]: state => ({
      ...state,
      fetchingTemplateStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [FETCH_PUBLIC_TEMPLATES_DONE]: (state, action) => ({
      ...state,
      fetchingTemplateStatus: API_CALL_STATE.DONE,
      publicTemplates: action.payload,
    }),
    [FETCH_PUBLIC_TEMPLATES_FAIL]: state => ({
      ...state,
      fetchingTemplateStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_STRAPI_STARTER_KITS_INIT]: state => ({
      ...state,
      strapiStarterKitsStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [FETCH_STRAPI_STARTER_KITS_DONE]: (state, action) => ({
      ...state,
      strapiStarterKitsStatus: API_CALL_STATE.DONE,
      strapiStarterKits: action.payload,
    }),
    [FETCH_STRAPI_STARTER_KITS_FAIL]: state => ({
      ...state,
      strapiStarterKitsStatus: API_CALL_STATE.FAILED,
    }),
  },
  initialState
);
