import React from "react";
import PrebuiltSidebarItemWrapper from "src/pages/Prebuilt/PrebuiltSidebarItemWrapper";
import { Flex, Switch, Text } from "@100mslive/react-ui";

export const PrebuiltSidebarSwitch = ({
  title,
  tooltipText,
  disabled,
  checked,
  onCheckedChange,
}: {
  title: string;
  tooltipText?: string;
  disabled: boolean;
  checked: boolean;
  onCheckedChange: () => void;
}) => (
  <Flex justify="between" align="center" css={{ mt: "$10" }}>
    <PrebuiltSidebarItemWrapper
      css={{ my: 0 }}
      isSwitchTitle={true}
      title={title}
      titleVariant="sm"
      tooltipText={
        <Text variant="caption" css={{ color: "$textHighEmp" }}>
          {tooltipText}
        </Text>
      }
    />
    <Switch
      disabled={disabled}
      checked={checked}
      onCheckedChange={onCheckedChange}
    />
  </Flex>
);
