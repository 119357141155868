export const freeMinsData = {
  webrtc: 10000,
  sip: 100,
  interactive_live_streaming: {
    viewer: 10000,
    encoding: {
      sd: 1000,
    },
  },
  recording: {
    sd: 300,
  },
  rtmp: {
    sd: 300,
  },
  transcription: {
    live_transcription: 300,
    post_call_transcription: 300,
    summary: 300,
    closed_captioning: 300,
  },
  plugins: {
    noiseCancellation: 300,
  },
};
