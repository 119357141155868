import React from "react";
import { OnStageExperience } from "@100mslive/types-prebuilt/elements/on_stage_experience";
import ConfigMultiSelect from "src/components/Common/ConfigMultiSelect";
import ConfigSingleSelect from "src/components/Common/ConfigSingleSelect";
import StatusString from "src/components/Common/StatusString";
import ValuePill from "src/components/Common/ValuePill";
import { defaultConferencing } from "src/data/defaultLayout";
import { toTitleCase } from "src/helpers";
import PrebuiltSidebarItemWrapper from "src/pages/Prebuilt/PrebuiltSidebarItemWrapper";
import { RoleLayouts } from "src/types/prebuilt";
import { Dropdown, Flex, Input, Text } from "@100mslive/react-ui";
import { PrebuiltSidebarSwitch } from "./PrebuiltSidebarSwitch";
import { PREBUILT_COMPONENT_TITLES } from "../../helpers/constants";

type Props = {
  data: OnStageExperience;
  setData: (data: OnStageExperience) => void;
  invalidFields: { [key in keyof OnStageExperience]?: string } | undefined;
  roleLayouts: RoleLayouts;
};

function PrebuiltOnStageExperience({
  data,
  setData,
  invalidFields,
  roleLayouts,
}: Props) {
  const onStageRoleClicked = (role: string) => {
    setData({
      ...data,
      on_stage_role: role,
    });
  };
  const offStageRoleClicked = (role: string) => {
    const rolesArray = data?.off_stage_roles || [];
    let newRolesArray = [];
    if (rolesArray.includes(role)) {
      newRolesArray = [...rolesArray.filter(r => r !== role)];
    } else {
      newRolesArray = [...rolesArray, role];
    }

    setData({
      ...data,
      off_stage_roles: newRolesArray,
    });
  };

  return (
    <>
      <PrebuiltSidebarSwitch
        title="Skip Preview"
        tooltipText="If enabled, peers will skip audio/video preview before joining the stage."
        disabled={false}
        checked={!!data?.skip_preview_for_role_change}
        onCheckedChange={() =>
          setData({
            ...data,
            skip_preview_for_role_change: !data?.skip_preview_for_role_change,
          })
        }
      />

      <PrebuiltSidebarItemWrapper
        title="Bring on Stage Label"
        tooltipText="Label to show in the menu to bring a participant on stage"
      >
        <Input
          css={{ r: "$0", ml: "$space$px", w: "99%" }}
          value={data.bring_to_stage_label}
          onChange={e => {
            const tempData = { ...data, bring_to_stage_label: e.target.value };
            setData(tempData);
          }}
        />
        <StatusString content={invalidFields?.bring_to_stage_label || ""} />
      </PrebuiltSidebarItemWrapper>
      <PrebuiltSidebarItemWrapper
        css={{ my: 0 }}
        title="Remove from Stage Label"
        tooltipText="Label to show in the menu to remove a participant from the stage"
      >
        <Input
          css={{ r: "$0", ml: "$space$px", w: "99%" }}
          value={data.remove_from_stage_label}
          onChange={e => {
            const tempData = {
              ...data,
              remove_from_stage_label: e.target.value,
            };
            setData(tempData);
          }}
        />
        <StatusString content={invalidFields?.remove_from_stage_label || ""} />
      </PrebuiltSidebarItemWrapper>
      <PrebuiltSidebarItemWrapper
        title="On Stage Role"
        tooltipText="Participant will change to the role selected when brought on stage"
      >
        <ConfigSingleSelect
          inputText={data?.on_stage_role || "Please select Role"}
          triggerCSS={{ w: "98%", ml: "$1" }}
          contentCSS={{ zIndex: 1100 }}
          content={
            <>
              {Object.keys(roleLayouts).map(type => (
                <Dropdown.Item
                  key={type}
                  css={{ p: "$4 $8" }}
                  onClick={() => {
                    onStageRoleClicked(type);
                  }}
                >
                  <Text variant="caption">{toTitleCase(type)}</Text>
                </Dropdown.Item>
              ))}
            </>
          }
        />
        <StatusString content={invalidFields?.on_stage_role || ""} />
      </PrebuiltSidebarItemWrapper>
      <PrebuiltSidebarItemWrapper
        title="Off Stage Roles"
        css={{ mt: 0 }}
        tooltipText="Participant brought on stage will change back to one of the the roles selected"
      >
        <ConfigMultiSelect
          inputText="Select off stage roles"
          optionsArray={Object.keys(roleLayouts)}
          onCheckedChange={offStageRoleClicked}
          isCheckedCondition={role =>
            ((data?.off_stage_roles as string[]) || [])?.includes(role)
          }
        />
        <Flex css={{ w: "100%", flexWrap: "wrap" }}>
          {(data?.off_stage_roles || []).map((roleName: string) => (
            <ValuePill
              key={roleName}
              content={roleName}
              onClick={() => offStageRoleClicked(roleName)}
            />
          ))}
        </Flex>

        <StatusString content={invalidFields?.off_stage_roles || ""} />
      </PrebuiltSidebarItemWrapper>
    </>
  );
}

export default PrebuiltOnStageExperience;

const defaultData =
  defaultConferencing?.["default"]?.["elements"]?.["on_stage_exp"];

export const onStageExperience = {
  ui: {
    title: PREBUILT_COMPONENT_TITLES.ON_STAGE_EXP,
    hasSwitch: true,
    tooltipText: "Allow this role to bring others on stage",
  },
  path: "screens.conferencing.default.elements.on_stage_exp",
  Component: PrebuiltOnStageExperience,
  defaultData: defaultData,
  validator: () => {},
};
