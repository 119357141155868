import { AcceptWorkspaceInviteModal } from "../../components";

const Invite = () => {
  return (
    <>
      <AcceptWorkspaceInviteModal />
    </>
  );
};

export default Invite;
