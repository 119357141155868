import React, { useEffect } from "react";
import Credential from "src/components/Common/Credential";
import MediumIcon from "src/components/Common/Icons/MediumIcon";
import StatusString from "src/components/Common/StatusString";
import { TileWrapper } from "src/components/Common/Tile";
import { API_CALL_STATE } from "src/constants";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { BINARY_OPTIONS } from "src/services/cmsModels/Policy/helpers";
import { currentWorkspaceHipaa } from "src/utils";
import { createErrorMessage } from "src/validations/Messages";
import { RefreshIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, Text } from "@100mslive/react-ui";

type Props = {
  templateName: string;
  templateNameError?: string;
  checkTemplateNameStatus: string;
  setTemplateName: (val: string) => void;
  subdomain: string;
  setSubdomain: (val: string) => void;
  subdomainChecking: boolean;
  subdomainError?: string;
  autoGenerateTemplate: () => void;
  autoGenerateSubdomain: () => void;
  largeRoom: boolean;
  setLargeRoom: (bool: boolean) => void;
};

function DeployForm({
  templateName = "",
  autoGenerateTemplate,
  autoGenerateSubdomain,
  setTemplateName,
  checkTemplateNameStatus,
  templateNameError,
  subdomain,
  setSubdomain,
  subdomainChecking,
  subdomainError,
  largeRoom,
  setLargeRoom,
}: Props) {
  const isHipaa = currentWorkspaceHipaa();
  useEffect(
    () => {
      setSubdomain(subdomain);
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    []
  );
  return (
    <Box
      css={{
        w: "100%",
        maxHeight: "366px",
        h: "calc(100vh - 430px)",
        overflowY: "auto",
      }}
    >
      <Box css={{ p: "$10", w: "75%" }}>
        <Flex direction="column" css={{ mb: "$10" }}>
          <SettingsTitle
            text="Room Template Name"
            side="top"
            align="center"
            title="The name of the room template that holds configuration for roles, live streaming, recoding and more"
          />
          <Flex css={{ mt: "$8" }} direction="column">
            <Flex>
              <Credential
                placeholder="Upto 80 characters"
                defaultValue={templateName}
                passEvent={true}
                error={templateNameError}
                css={{ minWidth: "100%", h: "40px" }}
                inputCss={{ r: "$0", bg: "$surfaceDefault" }}
                maxLength={80}
                onChangeHandler={e => {
                  const input = e.target.value.trim();
                  setTemplateName(input);
                }}
                hideLabel={true}
                showClipboard={false}
                success={
                  !(
                    checkTemplateNameStatus === API_CALL_STATE.IN_PROGRESS ||
                    templateNameError
                  )
                }
                label=""
                value={templateName}
              />
              <Button
                outlined
                variant="standard"
                css={{ ml: "$4", r: "$0" }}
                onClick={autoGenerateTemplate}
                loading={checkTemplateNameStatus === API_CALL_STATE.IN_PROGRESS}
              >
                <MediumIcon>
                  <RefreshIcon />
                </MediumIcon>
              </Button>
            </Flex>
            <StatusString
              textVariant="caption"
              content={
                templateNameError
                  ? createErrorMessage(templateNameError)
                  : `${String.fromCharCode(0x2713)} Template name is available`
              }
              type={templateNameError ? "error" : "success"}
            />
          </Flex>
        </Flex>
        <Flex direction="column" css={{ mb: "$10" }}>
          <SettingsTitle
            text="Subdomain"
            side="top"
            align="center"
            title="Prebuilt will be hosted on this subdomain and can be used to join rooms you create"
          />
          <Flex css={{ mt: "$8" }} direction="column">
            <Flex>
              <Credential
                showClipboard={false}
                css={{ minWidth: "100%", h: "40px" }}
                inputCss={{ r: "$0", bg: "$surfaceDefault" }}
                error={subdomainError}
                passEvent={true}
                placeholder="e.g. mysubdomain"
                success={!(subdomainChecking || subdomainError)}
                maxLength={50}
                defaultValue={subdomain}
                label="Template Subdomain"
                onChangeHandler={e => {
                  const input = e.target?.value?.trim()?.toLowerCase();
                  setSubdomain(input);
                }}
                hideLabel
                title=""
                rightComp={
                  <Text
                    css={{
                      c: "$textAccentDisabled",
                    }}
                  >
                    {process.env.REACT_APP_VERCEL_BASE_DOMAIN}
                  </Text>
                }
                value={subdomain}
              />
              <Button
                outlined
                variant="standard"
                css={{ ml: "$4", r: "$0" }}
                loading={subdomainChecking}
                onClick={autoGenerateSubdomain}
              >
                <MediumIcon>
                  <RefreshIcon />
                </MediumIcon>
              </Button>
            </Flex>
            <StatusString
              textVariant="caption"
              content={
                subdomainError
                  ? createErrorMessage(subdomainError)
                  : `${String.fromCharCode(0x2713)} Subdomain is available`
              }
              type={subdomainError ? "error" : "success"}
            />
          </Flex>
        </Flex>
        {!isHipaa && (
          <Flex direction="column" css={{ mb: "$10" }}>
            <SettingsTitle
              text="Large Room"
              side="top"
              align="center"
              title="Should Large Room feature be enabled?"
            />
            <Flex css={{ mt: "$8" }} direction="column">
              <>
                <Text
                  css={{
                    color: "$textHighEmp",
                    fontWeight: "$semiBold",
                  }}
                  variant="body1"
                >
                  Do you require the template to support large no. of peers?{" "}
                  <Text
                    as="span"
                    variant="body1"
                    css={{ color: "$primaryLight", fontWeight: "$semiBold" }}
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        cursor: "pointer",
                        fontSize: "inherit",
                      }}
                      href={`${process.env.REACT_APP_WEBSITE_URL}docs/server-side/v2/how-to-guides/create-large-rooms`}
                    >
                      Learn more.
                    </a>
                  </Text>
                </Text>

                <Flex css={{ flexWrap: "wrap", mt: "$md" }}>
                  {Object.keys(BINARY_OPTIONS).map((answer, index) => {
                    const resAns = largeRoom === true ? "yes" : "no";
                    return (
                      <TileWrapper
                        key={`${index}-${answer}`}
                        css={{
                          border: `$space$px solid ${
                            resAns ===
                            BINARY_OPTIONS[
                              answer as keyof typeof BINARY_OPTIONS
                            ].toLowerCase()
                              ? "$primaryDefault"
                              : "$borderLight"
                          }`,
                          mr: "$md",
                          p: "$6 $9",
                          bg:
                            resAns ===
                            BINARY_OPTIONS[
                              answer as keyof typeof BINARY_OPTIONS
                            ].toLowerCase()
                              ? "$primaryDark"
                              : "",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setLargeRoom(
                            answer.toLowerCase() === "yes" ? true : false
                          );
                        }}
                        isActive={
                          resAns ===
                          BINARY_OPTIONS[
                            answer as keyof typeof BINARY_OPTIONS
                          ].toLowerCase()
                        }
                      >
                        <Text variant="body2">
                          {
                            BINARY_OPTIONS[
                              answer as keyof typeof BINARY_OPTIONS
                            ]
                          }
                        </Text>
                      </TileWrapper>
                    );
                  })}
                </Flex>
              </>
            </Flex>
          </Flex>
        )}
      </Box>
    </Box>
  );
}

export default DeployForm;
