/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { fetchTemplatesData } from "src/actions/RolesActions";
import JoinRoomForm from "src/components/JoinRoomForm";
import { API_CALL_STATE } from "src/constants";
import { AppAnalytics } from "src/helpers";
import {
  createRoomFromStore,
  resetCreateRoomStore,
  setIsRoomConfigValid,
} from "src/store/createRoom/actions";
import type { RootState } from "src/store/reducers";
import { fetchRecentRooms } from "src/store/rooms/actions";
import { fetchGeoInfo } from "src/store/userInfo/actions";
import { DoorIcon, LayoutIcon } from "@100mslive/react-icons";
import { Box, Loading } from "@100mslive/react-ui";
import { ComposableDialog } from "./ComposableDialog";
import CreateYourRoom from "../../containers/createRoomForm/CreateRoomForm";

const configNameMap = {
  create: "create",
  join: "join",
};

interface configMap {
  [name: string]: {
    title: string;
    subtitle: string;
    content: ReactElement;
    titleIcon: ReactElement;
    ctaText: string;
    cancelText: string;
    disableAction: boolean;
    onActionClick: () => unknown;
    onCancelClick: () => unknown;
    InfoMessage?: ReactElement;
    btnId?: string;
    componentId?: string;
  };
}

// eslint-disable-next-line complexity
function CreateRoomModal({
  open,
  openChangeHandler,
}: {
  open: boolean;
  openChangeHandler: () => unknown;
}) {
  const dispatch = useDispatch();

  const roomId = useSelector(
    (state: RootState) => state.createRoom.createdRoom?.id
  );
  const createRoomStatus = useSelector(
    (state: RootState) => state.createRoom.createRoomStatus
  );
  const createdRoom = useSelector(
    (state: RootState) => state.createRoom.createdRoom
  );
  const checkRoomNameStatus = useSelector(
    (state: RootState) => state.createRoom.checkRoomNameStatus
  );
  const checkRoomNameError = useSelector(
    (state: RootState) => state.createRoom.roomNameError
  );

  const all_templates = useSelector(
    (state: RootState) => state.roles.all_templates
  );

  const fetchTemplateStatus = useSelector(
    (state: RootState) => state.roles.fetchTemplateStatus
  );
  const isRoomConfigValid = useSelector(
    (state: RootState) => state.createRoom.isRoomConfigValid
  );

  const region = useSelector((state: RootState) => state.userInfo.region);

  const [stepType, setStepType] = useState<string>("create");

  useEffect(() => {
    dispatch(setIsRoomConfigValid());
  }, [region]);

  useEffect(() => {
    if (isEmpty(all_templates)) {
      dispatch(fetchTemplatesData());
    }

    dispatch(fetchGeoInfo());

    return () => {
      dispatch(resetCreateRoomStore());
      dispatch(fetchGeoInfo());
    };
  }, []);

  useEffect(() => {
    if (createRoomStatus === API_CALL_STATE.DONE) {
      setStepType("join");
    }
  }, [createRoomStatus]);

  const room_code_disabled =
    useSelector(
      (state: RootState) => state.workspace?.current?.room_code_disabled
    ) || false;

  const stepsConfig: configMap = {
    create: {
      title: "Create your room",
      subtitle:
        "Rooms help you interact with others in audio or video call. Each room is associated with a template.",
      titleIcon: (
        <Box css={{ w: "$12", h: "$12", c: "$primaryLight" }}>
          {fetchTemplateStatus !== API_CALL_STATE.IN_PROGRESS ? (
            <LayoutIcon width={32} height={32} />
          ) : (
            <Loading size={32} />
          )}
        </Box>
      ),
      disableAction:
        !isRoomConfigValid ||
        !!checkRoomNameError ||
        createRoomStatus === API_CALL_STATE.IN_PROGRESS ||
        checkRoomNameStatus === API_CALL_STATE.IN_PROGRESS ||
        !region,
      content: <CreateYourRoom />,
      ctaText: "Continue",
      cancelText: "Cancel",
      onActionClick: () => {
        dispatch(createRoomFromStore());
        setStepType("join");
        AppAnalytics.track("btn.clicked", {
          btnId: "continue",
          componentId: "create.room.flow",
        });
      },
      onCancelClick: () => {
        openChangeHandler();
        dispatch(resetCreateRoomStore());
      },
      btnId: "create.template.cross",
      componentId: "create.your.template.step",
    },
    join: {
      title: "Join your room",
      subtitle: "Select a role and choose the way you want to join your room.",
      titleIcon: (
        <Box css={{ w: "$12", h: "$12", c: "$primaryLight" }}>
          <DoorIcon width={32} height={32} />
        </Box>
      ),
      content: (
        <JoinRoomForm
          roomId={roomId}
          componentId="create.room.flow"
          subdomain={createdRoom?.subdomain}
          templateId={createdRoom?.template_id}
          roomCodesDisabled={room_code_disabled}
        />
      ),
      ctaText: "Continue",
      cancelText: "Go Back",
      disableAction: false,
      onActionClick: () => null,
      onCancelClick: () => null,
      btnId: "create.template.cross",
      componentId: "join.room.step",
    },
  };

  return (
    <ComposableDialog.Root
      open={open}
      onOpenChange={() => {
        if (stepType === configNameMap.join) {
          dispatch(fetchTemplatesData());
          dispatch(fetchRecentRooms());
        }
        openChangeHandler();
        setStepType("create");
        dispatch(resetCreateRoomStore());
      }}
    >
      <ComposableDialog.Content
        width={
          stepsConfig[stepType].title === stepsConfig.join.title &&
          room_code_disabled
            ? "640px"
            : "1000px"
        }
      >
        <ComposableDialog.Header
          title={stepsConfig[stepType].title}
          Icon={stepsConfig[stepType].titleIcon}
          subtitle={stepsConfig[stepType].subtitle}
          btnId={stepsConfig[stepType]?.btnId || ""}
          componentId={stepsConfig[stepType]?.componentId || ""}
        />
        {stepsConfig[stepType].content}
        {stepType !== configNameMap.join ? (
          <ComposableDialog.Footer
            ctaText={stepsConfig[stepType].ctaText}
            cancelText={stepsConfig[stepType].cancelText}
            disableAction={stepsConfig[stepType].disableAction}
            onActionClick={stepsConfig[stepType].onActionClick}
            onCancelClick={stepsConfig[stepType].onCancelClick}
            InfoMessage={stepsConfig[stepType]?.InfoMessage}
          />
        ) : null}
      </ComposableDialog.Content>
    </ComposableDialog.Root>
  );
}

export default CreateRoomModal;
