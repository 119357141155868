import React, { isValidElement } from "react";
import { CSS, Flex } from "@100mslive/react-ui";
import TextAndSubtext from "../Common/TextAndSubtext";
import TwoColumnFlex from "../Common/TwoColumnFlex";

export interface TemplateConfigProps {
  id: string;
  text: string;
  subtitle?: string | JSX.Element;
  leftComponent?: JSX.Element | null;
  rightComponent: JSX.Element;
  classNameForText?: string;
  cardRef?: React.RefObject<HTMLDivElement>;
  bottomButton?: JSX.Element | null;
}

interface TemplateMultiConfigCardProps {
  templateConfigs: TemplateConfigProps[];
  css?: CSS;
}

function TemplateMultiConfigCard({
  templateConfigs = [],
  css = {},
}: TemplateMultiConfigCardProps) {
  const lastTemplateConfig = templateConfigs[templateConfigs.length - 1];

  return (
    <Flex
      direction="column"
      css={{
        bg: "$surfaceDefault",
        border: "$solid$px $borderDefault solid",
        r: "$1",
        p: "$12",
        pb: lastTemplateConfig.bottomButton ? 0 : "$12",
        ...css,
      }}
    >
      {templateConfigs.map(
        ({
          text = "",
          subtitle = "",
          leftComponent = null,
          rightComponent,
          id = "",
          classNameForText = "",
          cardRef,
          bottomButton = null,
        }: TemplateConfigProps) => (
          <>
            <TwoColumnFlex
              id={id}
              w={30}
              column1Direction="column"
              column2Direction="column"
              columnGap="$20"
              css={{
                alignItems: "start",
                pb: bottomButton ? 0 : "$12",
              }}
            >
              <Flex direction="column" align="start" css={{ h: "100%" }}>
                <TextAndSubtext
                  flexGap="$4"
                  text={text}
                  textVariant="h6"
                  subText={subtitle}
                  subTextVariant="body2"
                  classNameForText={classNameForText}
                  setID
                  css={{
                    alignItems: "start",
                    mb: isValidElement(leftComponent) ? "$4" : 0,
                  }}
                />
                {leftComponent}
              </Flex>
              <Flex direction="column" align="start" ref={cardRef}>
                {rightComponent}
              </Flex>
            </TwoColumnFlex>
            {bottomButton ? bottomButton : null}
          </>
        )
      )}
    </Flex>
  );
}

export default TemplateMultiConfigCard;
