/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { Table } from "reactstrap";
import LoadingSpinner from "./LoadingSpinner";

export default function TableContainer({
  loading,
  loadMore,
  thead,
  tbody,
  isEmpty,
  emptyMessage,
}) {
  const loadingRef = useRef(null);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entities => {
        if (entities[0].isIntersecting) {
          loadMore();
        }
      },
      {
        root: tableContainerRef.current,
        threshold: [0.5, 1.0],
      }
    );
    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }
  }, [loadingRef, tableContainerRef]);

  return (
    <div
      className="table-container overflow-auto "
      ref={tableContainerRef}
      style={{ height: "75vh" }}
    >
      {!isEmpty ? (
        <div className="table-responsive">
          <Table className="table table-borderless table-hover mb-0">
            <thead>{thead}</thead>
            <tbody>{tbody}</tbody>
          </Table>
        </div>
      ) : (
        !loading && (
          <div className="text-center text-capitalize">{emptyMessage}</div>
        )
      )}
      <div ref={loadingRef} style={{ height: "13px", textAlign: "center" }}>
        {loading && <LoadingSpinner />}
      </div>
    </div>
  );
}
