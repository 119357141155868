import React, {
  ComponentPropsWithoutRef,
  FC,
  PropsWithChildren,
  PropsWithoutRef,
  ReactNode,
} from "react";
import { Flex, Text } from "@100mslive/react-ui";
import Credential from "./Credential";
import TextAndSubtext from "./TextAndSubtext";
import TwoColumnFlex from "./TwoColumnFlex";

interface RoomNameComponentProps {
  direction: "row" | "column";
  children: ReactNode[] | undefined;
}

export const RoomName: FC<PropsWithChildren<RoomNameComponentProps>> = ({
  children,
  direction = "row",
}) => {
  if (direction === "row" && (children as ReactNode[]).length > 1) {
    return (
      <TwoColumnFlex css={{ mb: "$16" }}>
        {children as ReactNode[]}
      </TwoColumnFlex>
    );
  }
  return (
    <Flex css={{ pt: "$8" }} direction={direction}>
      {children}
    </Flex>
  );
};

interface RoomNameLabelComponentProps {
  text: string;
  subtitle: string;
  children: (props: { text: string; subtitle: string }) => JSX.Element;
}

export const RoomNameLabel = ({
  text,
  subtitle,
  children,
}: RoomNameLabelComponentProps) => {
  return children({ text, subtitle });
};

export const DefaultRoomNameLabel = ({
  text,
  subtitle,
}: {
  text: string;
  subtitle: string;
}) => <TextAndSubtext text={text} subText={subtitle} />;

export const RoomNameInputBox: PropsWithoutRef<typeof Credential> = ({
  ...props
}) => {
  return (
    <>
      <Credential {...props} />
      {props.error ? (
        <Text
          variant="caption"
          css={{
            fontWeight: "$regular",
            position: "absolute",
            top: "110%",
            c: "$error",
          }}
        >
          {props?.error}
        </Text>
      ) : null}
      {!props?.error ? (
        <Text
          variant="caption"
          css={{
            fontWeight: "$regular",
            position: "absolute",
            top: "110%",
            c: "$success",
          }}
        >
          &#10003; Room name is available
        </Text>
      ) : null}
    </>
  );
};

export const RoomNameInput: FC<
  PropsWithChildren<
    ComponentPropsWithoutRef<typeof RoomNameInputBox> & {
      error?: string;
      value?: string;
    }
  >
> = ({ children, ...props }) => {
  const childrenWithProps = React.Children.map(children, function (child) {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props });
    }
    return child;
  });
  return (
    <Flex css={{ position: "relative", w: "100%" }}>{childrenWithProps}</Flex>
  );
};
