import {
  ColorPalette as Palette,
  ConferencingScreen,
  Layout,
  Logo,
  Theme,
  Theme_ThemeType,
  Typography,
} from "@100mslive/types-prebuilt";
import axios, { AxiosError } from "axios";
import { cloneDeep, isEmpty } from "lodash";
import { Dispatch } from "redux";
import { setHLSDestination } from "src/actions/RolesActions";
import toastr from "src/components/Common/toastr";
import { prebuiltValidation } from "src/components/Prebuilt/prebuiltValidationClass";
import { API_CALL_STATE } from "src/constants";
import {
  defaultDarkColorPalette,
  defaultLightColorPalette,
} from "src/data/colorPalette";
import { defaultLayout } from "src/data/defaultLayout";
import { isVisibleRole } from "src/helpers";
import {
  getAppLayout,
  setAppLayout,
  updateAppLayout,
  updateConferencingDefaults,
} from "src/helpers/appLayoutHelper";
import { DashboardBackendAxiosError } from "src/types/customTypes";
import { policyTemplateType } from "src/types/policyTypes";
import { ColorPalette, RoleLayouts } from "src/types/prebuilt";
import { getPaletteObject } from "src/utils";

export const FETCH_APP_LAYOUT_INIT = "FETCH_APP_LAYOUT_INIT";
export const FETCH_APP_LAYOUT_FAIL = "FETCH_APP_LAYOUT_FAIL";
export const FETCH_APP_LAYOUT_DONE = "FETCH_APP_LAYOUT_DONE";
export const CREATE_OR_FETCH_APP_LAYOUT_INIT =
  "CREATE_OR_FETCH_APP_LAYOUT_INIT";
export const CREATE_OR_FETCH_APP_LAYOUT_FAIL =
  "CREATE_OR_FETCH_APP_LAYOUT_FAIL";
export const CREATE_OR_FETCH_APP_LAYOUT_DONE =
  "CREATE_OR_FETCH_APP_LAYOUT_DONE";
export const PATCH_APP_LAYOUT_INIT = "PATCH_APP_LAYOUT_INIT";
export const PATCH_APP_LAYOUT_FAIL = "PATCH_APP_LAYOUT_FAIL";
export const PATCH_APP_LAYOUT_DONE = "PATCH_APP_LAYOUT_DONE";
export const CREATE_APP_LAYOUT_INIT = "CREATE_APP_LAYOUT_INIT";
export const CREATE_APP_LAYOUT_FAIL = "CREATE_APP_LAYOUT_FAIL";
export const CREATE_APP_LAYOUT_DONE = "CREATE_APP_LAYOUT_DONE";

export const UPDATE_APP_LAYOUT = "UPDATE_APP_LAYOUT";
export const CLEAR_APP_LAYOUT = "CLEAR_APP_LAYOUT";
export const SET_PREBUILT_LAYOUT_INVALID_FIELDS =
  "SET_PREBUILT_LAYOUT_INVALID_FIELDS";

export const SET_HAS_UNSAVED_PREBUILT_CHANGES =
  "SET_HAS_UNSAVED_PREBUILT_CHANGES";

export const SET_LAYOUT_FOR_ROLE = "SET_LAYOUT_FOR_ROLE";
export const SET_LAYOUT_SCREEN_MODE = "SET_LAYOUT_SCREEN_MODE";
export const SET_LAYOUT_SCREEN_TYPE = "SET_LAYOUT_SCREEN_TYPE";

export function fetchAppLayout({
  templateId,
  role = "",
  roleId = "",
}: {
  templateId: string;
  role?: string;
  roleId?: string;
}) {
  return async (
    dispatch: Dispatch,
    getState: () => {
      appLayouts: { fetchAppLayoutStatus: string };
      roles: { policyInfo: Record<string, any> };
    }
  ) => {
    const fetchAppLayoutStatus = getState().appLayouts.fetchAppLayoutStatus;
    if (fetchAppLayoutStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }

    dispatch({ type: FETCH_APP_LAYOUT_INIT });
    try {
      const res = await getAppLayout({
        templateId: templateId,
        role: role,
        roleId: roleId,
      });

      if (res.data.success) {
        dispatch({ type: FETCH_APP_LAYOUT_DONE, payload: res.data.data });
        dispatch(
          //@ts-ignore
          setRoleForLayout(
            Object.keys(res.data.data).filter(role => isVisibleRole(role))[0]
          )
        );
        dispatch(
          //@ts-ignore
          setHLSDestination()
        );
        //    toastr.success("Layout fetched successfully");
      } else {
        throw new Error();
      }
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      dispatch({ type: FETCH_APP_LAYOUT_FAIL });
    }
  };
}

export function createAppLayout({ layout }: { layout: Layout }) {
  return async (
    dispatch: Dispatch,
    getState: () => {
      appLayouts: { createAppLayoutStatus: string; appLayout: Layout };
    }
  ) => {
    const createAppLayoutStatus = getState().appLayouts.createAppLayoutStatus;
    if (createAppLayoutStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: CREATE_APP_LAYOUT_INIT });
    try {
      const res = await setAppLayout({ layout: layout });
      if (res.data.success) {
        dispatch({ type: CREATE_APP_LAYOUT_DONE, payload: res.data.data });

        toastr.success("Layout created successfully");
      } else {
        throw new Error();
      }
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);

      toastr.error(`Error creating layout :  ${message}`);
      dispatch({ type: CREATE_APP_LAYOUT_FAIL });
    }
  };
}

export function patchAppLayout({ roleLayouts }: { roleLayouts: RoleLayouts }) {
  return async (
    dispatch: Dispatch,
    getState: () => {
      appLayouts: { patchAppLayoutStatus: string };
    }
  ) => {
    const patchAppLayoutStatus = getState().appLayouts.patchAppLayoutStatus;
    if (patchAppLayoutStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: PATCH_APP_LAYOUT_INIT });
    try {
      const res = await updateAppLayout({ roleLayouts });
      if (res.data.success) {
        dispatch({ type: PATCH_APP_LAYOUT_DONE, payload: res.data.data });
        toastr.success("Layout updated successfully");
      } else {
        throw new Error();
      }
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      toastr.error(`Error updating layout :  ${message}`);
      dispatch({ type: PATCH_APP_LAYOUT_FAIL });
    }
  };
}

export function clearAppLayoutStore({
  payload,
}: {
  payload: Record<string, any>;
}) {
  return (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_APP_LAYOUT, payload: payload });
  };
}

export function updateAppLayoutInStore({
  roleLayouts,
}: {
  roleLayouts: RoleLayouts;
}) {
  return async (
    dispatch: Dispatch,
    getState: () => {
      (): any;
      new (): any;
      appLayouts: { (): any; new (): any; roleForLayout: any };
    }
  ) => {
    const roleForLayout = getState().appLayouts.roleForLayout;
    dispatch({ type: UPDATE_APP_LAYOUT, payload: roleLayouts });

    const invalidFields = prebuiltValidation({
      layout: roleLayouts[roleForLayout],
      screenKeys: {
        preview: "default",
        conferencing: (Object.keys(
          roleLayouts?.[roleForLayout]?.screens?.conferencing || {
            default: {},
          }
        )[0] || "default") as keyof ConferencingScreen,
        leave: "default",
      },
    });
    // @ts-ignore
    dispatch(setPrebuiltLayoutInvalidFields(invalidFields));
    // @ts-ignore
    dispatch(setHasUnsavedPrebuiltChanges(true));
    //to block users from sending invalid fields
    if (!isEmpty(invalidFields)) {
      // @ts-ignore
      dispatch(setHasUnsavedPrebuiltChanges(false));
    }
  };
}

export function updateColors(colorPalette: ColorPalette) {
  return async (
    dispatch: Dispatch,
    getState: () => {
      appLayouts: {
        updateAppLayoutStatus: string;
        roleLayouts: RoleLayouts;
        roleForLayout: string;
      };
    }
  ) => {
    const roleForLayout = getState().appLayouts.roleForLayout;

    const roleLayouts = getState().appLayouts.roleLayouts;

    const paletteObject = getPaletteObject(
      // @ts-ignore
      colorPalette,
      (roleLayouts[roleForLayout].themes as Theme[])[0].theme_type
    );
    for (const role in roleLayouts) {
      (roleLayouts[role].themes as Theme[])[0].palette =
        paletteObject as Palette;
      const newThemeName = `light-${Date.now()}`;
      (roleLayouts[role].themes as Theme[])[0].name = newThemeName;
    }
    // @ts-ignore
    dispatch(updateAppLayoutInStore({ roleLayouts }));
    // @ts-ignore
    dispatch(setHasUnsavedPrebuiltChanges(true));
  };
}

export function setHasUnsavedPrebuiltChanges(
  hasUnsavedPrebuiltChanges: boolean
) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_HAS_UNSAVED_PREBUILT_CHANGES,
      payload: hasUnsavedPrebuiltChanges,
    });
  };
}

export function setPrebuiltLayoutInvalidFields(
  invalidFields: Record<string, string>
) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_PREBUILT_LAYOUT_INVALID_FIELDS,
      payload: invalidFields,
    });
  };
}

export function setLogo(logo: Logo) {
  return async (
    dispatch: Dispatch,
    getState: () => {
      appLayouts: { updateAppLayoutStatus: string; roleLayouts: RoleLayouts };
    }
  ) => {
    const roleLayouts = getState().appLayouts.roleLayouts;
    for (const role in roleLayouts) {
      roleLayouts[role].logo = logo;
    }
    // @ts-ignore
    dispatch(updateAppLayoutInStore({ roleLayouts }));
    // @ts-ignore
    dispatch(setHasUnsavedPrebuiltChanges(true));
  };
}

export function setTypography(typography: Typography) {
  return async (
    dispatch: Dispatch,
    getState: () => {
      appLayouts: { updateAppLayoutStatus: string; roleLayouts: RoleLayouts };
    }
  ) => {
    const roleLayouts = getState().appLayouts.roleLayouts;
    for (const role in roleLayouts) {
      roleLayouts[role].typography = typography;
    }
    // @ts-ignore
    dispatch(updateAppLayoutInStore({ roleLayouts }));
    // @ts-ignore
    dispatch(setHasUnsavedPrebuiltChanges(true));
  };
}

export function fetchOrCreateAppLayout({
  templateId,
  roleId = "",
  role = "",
}: {
  templateId: string;
  roleId?: string;
  role?: string;
}) {
  return async (
    dispatch: Dispatch,
    getState: () => {
      appLayouts: { fetchOrCreateAppLayoutStatus: string; appLayout: Layout };
    }
  ) => {
    const fetchOrCreateAppLayoutStatus =
      getState().appLayouts.fetchOrCreateAppLayoutStatus;
    if (fetchOrCreateAppLayoutStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: CREATE_OR_FETCH_APP_LAYOUT_INIT });
    try {
      const res = await getAppLayout({ templateId, roleId, role });
      if (res.data.success && !isEmpty(res.data.data)) {
        dispatch(
          //@ts-ignore
          setRoleForLayout(
            Object.keys(res.data.data).filter(role => isVisibleRole(role))[0]
          )
        );
        dispatch({
          type: CREATE_OR_FETCH_APP_LAYOUT_DONE,
          payload: res.data.data,
        });
      } else {
        throw new Error();
      }
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      try {
        const layout = {
          ...defaultLayout,
          template_id: templateId,
          role_id: roleId,
          role: role,
        };
        const res = await setAppLayout({ layout: layout });
        if (res.data.success) {
          dispatch(
            //@ts-ignore
            setRoleForLayout(
              Object.keys(res.data.data).filter(role => isVisibleRole(role))[0]
            )
          );
          dispatch({
            type: CREATE_OR_FETCH_APP_LAYOUT_DONE,
            payload: res.data.data,
          });
        } else {
          throw new Error();
        }
      } catch (err) {
        const e = err as AxiosError<DashboardBackendAxiosError> | Error;
        let message = "";
        if (axios.isAxiosError(e)) {
          message = e.response?.data?.msg;
        } else {
          message = e.message;
        }
        console.error(message);
        dispatch({
          type: CREATE_OR_FETCH_APP_LAYOUT_FAIL,
        });
      }
    }
  };
}

export function setRoleForLayout(role: string) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_LAYOUT_FOR_ROLE,
      payload: role,
    });
  };
}

export function setLayoutScreenMode({ mode }: { mode: string }) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_LAYOUT_SCREEN_MODE,
      payload: mode,
    });
  };
}

export function setLayoutScreenType({
  screenType,
  shouldUpdateConferencingDefaults,
}: {
  screenType: string;
  shouldUpdateConferencingDefaults: boolean;
}) {
  return async (
    dispatch: Dispatch,
    getState: () => {
      (): any;
      new (): any;
      appLayouts: { roleForLayout: any; screenMode: any; roleLayouts: any };
      roles: { policyInfo: policyTemplateType };
    }
  ) => {
    const { roleForLayout, screenMode, roleLayouts } = getState().appLayouts;
    if (screenMode === "conferencing" && shouldUpdateConferencingDefaults) {
      const tempRoleLayouts = updateConferencingDefaults({
        policyInfo: getState().roles.policyInfo,
        roleLayouts,
        roleForLayout,
        screenType,
      });
      //@ts-ignore
      dispatch(updateAppLayoutInStore({ roleLayouts: tempRoleLayouts }));
    }

    dispatch({
      type: SET_LAYOUT_SCREEN_TYPE,
      payload: screenType,
    });
  };
}

export function updateLayoutThemeType(
  theme: Theme_ThemeType,
  colorPalette: ColorPalette[]
) {
  return async (
    dispatch: Dispatch,
    getState: () => {
      appLayouts: { updateAppLayoutStatus: string; roleLayouts: RoleLayouts };
    }
  ) => {
    const roleLayouts = getState().appLayouts.roleLayouts;
    // @ts-ignore
    for (const role in roleLayouts) {
      const paletteArray =
        theme === Theme_ThemeType.THEME_TYPE_LIGHT
          ? cloneDeep(defaultLightColorPalette)
          : cloneDeep(defaultDarkColorPalette);
      const updatedPalette = colorPalette.map((p, i) => {
        const state = {
          ...p,
          value: paletteArray[i].value,
          colorPalette: [...paletteArray[i].colorPalette],
        };
        if (p?.textColorPalette && p?.textColorPalette?.length > 0) {
          const textColorPalette = paletteArray[i]?.textColorPalette || [];
          state.textColorPalette = [...textColorPalette];
        }
        return { ...state };
      });
      const palette = getPaletteObject(updatedPalette, theme);
      (roleLayouts[role].themes as Theme[])[0].palette = palette as Palette;

      (roleLayouts[role].themes as Theme[])[0].theme_type = theme;
      const newThemeName = `${
        theme === Theme_ThemeType.THEME_TYPE_LIGHT ? "light" : "dark"
      }-${Date.now()}`;
      (roleLayouts[role].themes as Theme[])[0].name = newThemeName;
    }
    // @ts-ignore
    dispatch(updateAppLayoutInStore({ roleLayouts }));
    // @ts-ignore
    dispatch(setHasUnsavedPrebuiltChanges(true));
  };
}
