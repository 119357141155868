import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { API_CALL_STATE } from "src/constants";
import { openDevrevWidget } from "src/helpers";
import { fetchPeersAnalyticsEventLogs } from "src/store/analytics/actions";
import { RootState } from "src/store/reducers";
import {
  AlertTriangleIcon,
  NoEntryIcon,
  SupportIcon,
} from "@100mslive/react-icons";
import { Button, Flex, Text } from "@100mslive/react-ui";
import { EventLogs } from "../components/EventLogs";
import "react-loading-skeleton/dist/skeleton.css";
type Props = {
  peerId: string;
};

const infoFlexCss = {
  borderTopLeftRadius: "$1",
  borderBottomLeftRadius: "$1",
  flex: "1 1 0",
  flexWrap: "wrap",
  w: "100%",
  border: "$space$1 solid $borderDefault",
};
// eslint-disable-next-line complexity
const PeerLogs = ({ peerId }: Props) => {
  const dispatch = useDispatch();
  const fetchPeerAnalyticsEventLogsStatus = useSelector(
    (state: RootState) => state.roomDebugger.fetchPeerAnalyticsEventLogsStatus
  );
  const peerEventLogs = useSelector(
    (state: RootState) => state.roomDebugger.peerEventLogs
  );

  useEffect(() => {
    if (peerId) {
      dispatch(fetchPeersAnalyticsEventLogs(peerId));
    }
  }, [dispatch, peerId]);
  return (
    <>
      {fetchPeerAnalyticsEventLogsStatus !== API_CALL_STATE.FAILED ? (
        <Flex css={{ w: "100%", py: "$10" }}>
          <Flex css={{ ...infoFlexCss, py: "$8" }}>
            <Text variant="sub2" css={{ fontWeight: 600, ml: "$10" }}>
              Event Log
            </Text>

            {fetchPeerAnalyticsEventLogsStatus ===
            API_CALL_STATE.IN_PROGRESS ? (
              <Flex css={{ w: "100%", px: "$md" }}>
                <Skeleton containerClassName="flex-1" height={240} />
              </Flex>
            ) : !isEmpty(peerEventLogs) ? (
              <EventLogs eventLogs={peerEventLogs} />
            ) : (
              <Flex
                direction="column"
                css={{
                  rowGap: "$md",
                  minHeight: "240px",
                  w: "100%",
                  textAlign: "center",
                }}
                justify="center"
                align="center"
              >
                <Flex css={{ c: "$textDisabled" }}>
                  <NoEntryIcon width={40} height={40} />
                </Flex>
                <Flex direction="column">
                  <Text variant="sub2" css={{ mb: "$2" }}>
                    No event logs
                  </Text>
                  <Text
                    variant="caption"
                    css={{
                      fontWeight: "$regular",
                      c: "$textMedEmp",
                      w: "20rem",
                    }}
                  >
                    Logs for session events will appear here, detailing audio,
                    video, and other activities as they occur.
                  </Text>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      ) : (
        <Flex css={{ w: "100%", py: "$10", textAlign: "center" }}>
          <Flex css={{ ...infoFlexCss, py: "$8" }}>
            <Text variant="sub2" css={{ fontWeight: 600, ml: "$10" }}>
              Event Log
            </Text>
            <Flex
              css={{ rowGap: "$md", minHeight: "240px", w: "100%" }}
              justify="center"
              align="center"
              direction="column"
            >
              <Flex css={{ c: "$error" }}>
                <AlertTriangleIcon width={40} height={40} />
              </Flex>
              <Flex direction="column">
                <Text variant="sub2" css={{ mb: "$2" }}>
                  Something went wrong
                </Text>
                <Text
                  variant="caption"
                  css={{ fontWeight: "$regular", c: "$textMedEmp", w: "20rem" }}
                >
                  We couldn&apos;t load details for this chart. This feature is
                  currently in alpha and there are known issues we&apos;re
                  working on.
                </Text>
              </Flex>
              <Button
                icon
                variant="standard"
                css={{ p: "$3 $4", r: "$0" }}
                onClick={() => openDevrevWidget(true)}
              >
                <SupportIcon />
                Report Issue
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default PeerLogs;
