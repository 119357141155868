import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import circleLogo from "../assets/images/companies/circle.svg";
import insiderLogo from "../assets/images/companies/insider.png";
import kutumbLogo from "../assets/images/companies/kutumb.png";
import megaLogo from "../assets/images/companies/mega.png";
import townscriptLogo from "../assets/images/companies/townscript.svg";
import logo from "../assets/images/hms-logo-header.svg";
import { getURL } from "../helpers/utils";

class NonAuthLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.capitalizeFirstLetter.bind(this);
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname);
    document.title = `${currentage} | 100ms Dashboard`;
  }
  render() {
    return (
      <div className="flex bg-gray-cool1">
        <div
          className="pt-24 hidden login-page-text-left-method3  min-h-screen lg:flex flex-col items-center justify-center bg-gray-cool6"
          style={{ width: 615 }}
        >
          <div className="flex justify-center flex-col" style={{ width: 304 }}>
            <div className="w-full flex justify-start">
              <img src={logo} alt="100ms logo" width={175} />
            </div>
            <div className="my-18">
              <div className="login-page-text-left-method flex  mt-8 mb-20">
                <div style={{ width: 300 }}>
                  <p className=" font-semibold text-xl">
                    Try the gold-standard for adding live audio-video to your
                    apps for free.
                  </p>
                </div>
              </div>
              <div className="flex my-8 items-center">
                <CheckIcon />
                <div className="ml-4 w-72">
                  <p className="text-base">10000 minutes free every month</p>
                </div>
              </div>
              <div className="flex my-8 items-center">
                <CheckIcon />
                <div className="ml-4 w-72">
                  <p className="text-base">
                    SDKs that power best-in-class call quality
                  </p>
                </div>
              </div>
              <div className="flex my-8 items-center">
                <CheckIcon />
                <div className="ml-4 w-72">
                  <p className="text-base">
                    Deploy and test without code in 5 minutes
                  </p>
                </div>
              </div>
              <div className="flex my-8 items-center">
                <CheckIcon />
                <div className="ml-4 w-72">
                  <p className="text-base">
                    Pick from fully customizable templates
                  </p>
                </div>
              </div>
              <div className="flex justify-between mt-20 mb-2 login-page-text-left-method2 ">
                <div className="w-96">
                  <p className="text-base">
                    Built for developers, ready for scale
                  </p>
                </div>
              </div>
              <div className="flex mt-6 items-center">
                <img className="w-16 mr-2 h-full" src={circleLogo} alt="" />
                <img className="w-20 mr-2 h-full" src={townscriptLogo} alt="" />
                <img className="w-16 mr-2 h-full" src={insiderLogo} alt="" />
              </div>
              <div className="flex mt-6 mb-24 items-center">
                <img className="w-16 mr-2 h-full" src={megaLogo} alt="" />
                <img className="w-20 mr-2 h-full" src={kutumbLogo} alt="" />
              </div>
            </div>
            <p className="text-gray-cool3 absolute bottom-8">
              © 100ms, Inc. All rights reserved.
            </p>
          </div>
        </div>
        <div className=" min-h-screen w-full  flex flex-col items-center justify-center bg-gray-cool1">
          {this.props.children}

          {window.location.pathname.startsWith("/register") &&
          !(
            decodeURIComponent(window.location.search).includes(
              "vercel-template-integration/cms-datocms"
            ) ||
            decodeURIComponent(window.location.search).includes(
              "/vercel-integration"
            )
          ) ? (
            <div className="absolute bottom-8 justify-center">
              <div className="text-gray-cool4 flex flex-row justify-around">
                <a
                  href={getURL("https://www.100ms.live/pricing")}
                  target="_blank"
                  rel="noreferrer"
                >
                  Pricing
                </a>
                <span className="mx-4">&#8226;</span>
                <a
                  href={getURL("https://www.100ms.live/solutions/events")}
                  target="_blank"
                  rel="noreferrer"
                >
                  Solutions
                </a>
                <span className="mx-4">&#8226;</span>
                <a
                  href={getURL("https://docs.100ms.live/")}
                  target="_blank"
                  rel="noreferrer"
                >
                  Resources
                </a>
                <span className="mx-4">&#8226;</span>
                <a
                  href={getURL("https://www.100ms.live/hms-vs-agora")}
                  target="_blank"
                  rel="noreferrer"
                >
                  Alternative to
                </a>
                <span className="mx-4">&#8226;</span>
                <a
                  href={getURL("https://www.100ms.live/about")}
                  target="_blank"
                  rel="noreferrer"
                >
                  About Us
                </a>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(NonAuthLayout);

export const CheckIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 -3 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.989 26.5527C21.2183 26.5527 26.3599 21.4111 26.3599 15.1929C26.3599 8.97461 21.2073 3.83301 14.978 3.83301C8.75977 3.83301 3.62915 8.97461 3.62915 15.1929C3.62915 21.4111 8.77075 26.5527 14.989 26.5527ZM14.989 24.2896C9.94629 24.2896 5.91431 20.2356 5.91431 15.1929C5.91431 10.1501 9.94629 6.10718 14.978 6.10718C20.0208 6.10718 24.0747 10.1501 24.0857 15.1929C24.0967 20.2356 20.0317 24.2896 14.989 24.2896Z"
      fill="#2F80FF"
    />
    <path
      d="M11.7458 19.7289C12.0329 20.1702 12.3709 20.3762 12.801 20.4068C13.2311 20.4373 13.6191 20.2601 13.8962 19.8817L20.8481 12.0829C21.0243 11.8451 21.1802 11.5718 21.1987 11.3115C21.2413 10.7116 20.7488 10.2785 20.1715 10.2375C19.8207 10.2126 19.4788 10.3817 19.213 10.7609L12.9504 17.8238L10.3908 15.1598C10.0306 14.7998 9.86028 14.6216 9.50942 14.5967C8.92087 14.5549 8.42305 14.9973 8.38125 15.5858C8.36115 15.8688 8.44452 16.1363 8.62976 16.4111L11.7458 19.7289Z"
      fill="#2F80FF"
    />
  </svg>
);
