import {
  ColorPalette,
  ConferencingScreen,
  Layout,
  PreviewScreen,
  Theme,
  Theme_ThemeType,
} from "@100mslive/types-prebuilt";
import { Chat_ChatState } from "@100mslive/types-prebuilt/elements/chat";
import { JoinForm_JoinBtnType } from "@100mslive/types-prebuilt/elements/join_form";
import { DEFAULT_LOGO, prebuiltFonts } from "src/constants";

export const defaultLayoutColorPalette: ColorPalette = {
  primary_default: "#2572ed",
  primary_bright: "#538dff",
  primary_dim: "#002d6d",
  primary_disabled: "#004299",
  on_primary_high: "#FFFFFF",
  on_primary_medium: "#CCDAFF",
  on_primary_low: "#84AAFF",
  secondary_default: "#444954",
  secondary_bright: "#70778B",
  secondary_dim: "#293042",
  secondary_disabled: "#404759",
  on_secondary_high: "#FFFFFF",
  on_secondary_medium: "#D3D9F0",
  on_secondary_low: "#A4ABC0",
  background_default: "#0B0E15",
  background_dim: "#000000",
  surface_default: "#191B23",
  surface_bright: "#272A31",
  surface_brighter: "#2E3038",
  surface_dim: "#11131A",
  on_surface_high: "#EFF0FA",
  on_surface_medium: "#C5C6D0",
  on_surface_low: "#8F9099",
  border_default: "#1D1F27",
  border_bright: "#272A31",
  alert_success: "#36B37E",
  alert_warning: "#FFAB00",
  alert_error_default: "#C74E5B",
  alert_error_bright: "#FFB2B6",
  alert_error_brighter: "#FFEDEC",
  alert_error_dim: "#270005",
};

export const defaultLightLayoutColorPalette: ColorPalette = {
  primary_default: "#2572ed",
  primary_bright: "#538dff",
  primary_dim: "#002d6d",
  primary_disabled: "#004299",
  on_primary_high: "#FFFFFF",
  on_primary_medium: "#CCDAFF",
  on_primary_low: "#84AAFF",
  secondary_default: "#444954",
  secondary_bright: "#70778B",
  secondary_dim: "#293042",
  secondary_disabled: "#404759",
  on_secondary_high: "#FFFFFF",
  on_secondary_medium: "#D3D9F0",
  on_secondary_low: "#A4ABC0",
  background_default: "#FEFBFF",
  background_dim: "#FFFFFF",
  surface_default: "#EFF0FA",
  surface_bright: "#E7E7F2",
  surface_brighter: "#D8D9E3",
  surface_dim: "#FAF8FF",
  on_surface_high: "#191b23",
  on_surface_medium: "#2e3038",
  on_surface_low: "#a9abb4",
  border_default: "#E7E7F2",
  border_bright: "#D8D9E3",
  alert_success: "#36B37E",
  alert_warning: "#FFAB00",
  alert_error_default: "#C74E5B",
  alert_error_bright: "#40000D",
  alert_error_brighter: "#270005",
  alert_error_dim: "#FFB2B6",
};

export const DEFAULT_MESSAGE_PLACEHOLDER = "Send a message...";
export const DEFAULT_CHAT_TITLE = "Chat";

export const defaultConferencing: ConferencingScreen = {
  default: {
    elements: {
      header: {
        title: "",
        description: "",
      },
      chat: {
        initial_state: Chat_ChatState.CHAT_STATE_OPEN,
        is_overlay: false,
        chat_title: DEFAULT_CHAT_TITLE,
        allow_pinning_messages: true,
        message_placeholder: DEFAULT_MESSAGE_PLACEHOLDER,
        public_chat_enabled: true,
        private_chat_enabled: false,
        roles_whitelist: [],
        real_time_controls: {
          can_disable_chat: false,
          can_block_user: false,
          can_hide_message: false,
        },
      },
      participant_list: {},
      video_tile_layout: {
        grid: {
          enable_local_tile_inset: false,
          prominent_roles: [],
          enable_spotlighting_peer: true,
        },
      },
      brb: {},
      emoji_reactions: {},
      hand_raise: {},
      on_stage_exp: {
        bring_to_stage_label: "Bring to stage",
        remove_from_stage_label: "Remove from stage",
        on_stage_role: "",
        off_stage_roles: [],
      },
      virtual_background: {
        background_media: [],
      },
    },
  },
};
export const defaultLiveStreamingConferencing: ConferencingScreen = {
  hls_live_streaming: {
    elements: {
      header: {
        title: "",
        description: "",
      },
      chat: {
        initial_state: Chat_ChatState.CHAT_STATE_OPEN,
        is_overlay: true,
        chat_title: DEFAULT_CHAT_TITLE,
        allow_pinning_messages: false,
        message_placeholder: DEFAULT_MESSAGE_PLACEHOLDER,
        roles_whitelist: [],
        public_chat_enabled: true,
        private_chat_enabled: false,
        real_time_controls: {
          can_disable_chat: false,
          can_block_user: false,
          can_hide_message: false,
        },
      },
      participant_list: {},
      emoji_reactions: {},
      hand_raise: {},
    },
  },
};

export const defaultLayoutThemes: Theme[] = [
  {
    name: "default",
    default: true,
    theme_type: Theme_ThemeType.THEME_TYPE_DARK,
    palette: defaultLayoutColorPalette,
  },
];

export const defaultPreview: PreviewScreen = {
  default: {
    elements: {
      preview_header: {
        title: "Get Started",
        sub_title: "Setup your audio and video before joining",
      },
      join_form: {
        join_btn_type: JoinForm_JoinBtnType.JOIN_BTN_TYPE_JOIN_AND_GO_LIVE,
        join_btn_label: "Join Now",
        go_live_btn_label: "Go Live",
      },
      virtual_background: {
        background_media: [],
      },
    },
  },
};

export const defaultLayout: Layout = {
  id: "",
  app_id: "",
  role_id: "",
  template_id: "",
  themes: defaultLayoutThemes,
  logo: {
    url: DEFAULT_LOGO,
  },
  typography: {
    font_family: prebuiltFonts[0],
  },
  screens: {
    preview: {
      default: {
        elements: {
          preview_header: {
            title: "Get Started",
            sub_title: "Setup your audio and video before joining",
          },
          join_form: {
            join_btn_type: JoinForm_JoinBtnType.JOIN_BTN_TYPE_JOIN_AND_GO_LIVE,
            join_btn_label: "Join Now",
            go_live_btn_label: "Go Live",
          },
          virtual_background: {
            background_media: [],
          },
        },
      },
      skip_preview_screen: false,
    },
    conferencing: defaultConferencing,
  },
  options: {},
};

export const liveStreamingPreview = {
  default: {
    elements: {
      preview_header: {
        title: "Get Started",
        sub_title: "Enter your name before joining",
      },
      join_form: {
        join_btn_type: JoinForm_JoinBtnType.JOIN_BTN_TYPE_JOIN_ONLY,
        join_btn_label: "Join Now",
        go_live_btn_label: "Go Live",
      },
      virtual_background: {
        background_media: [],
      },
    },
  },
};
