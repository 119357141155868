import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { ArrowRightIcon, LayoutIcon } from "@100mslive/react-icons";
import { Text } from "@100mslive/react-ui";
import MainDialog from "./MainDialog";
import {
  addSubdomainToExistingTemplate,
  CHECK_SUBDOMAIN_RESET,
  checkSubdomain,
} from "../../actions/RolesActions";
import { API_CALL_STATE, validationMessagesKeys } from "../../constants";
import { createErrorMessage, Messages } from "../../validations";
import Credential from "../Common/Credential";

const AddSubdomainModal = ({
  policyId,
  appType,
  trigger = false,
  setTrigger,
}) => {
  const dispatch = useDispatch();
  const [subdomainChecking, setSubdomainChecking] = useState(false);
  const [subdomainName, setSubdomainName] = useState("");
  const [subdomainError, setSubdomainError] = useState(
    Messages(validationMessagesKeys.validSubdomain, subdomainName, true)
  );
  const checkedSubdomainError = useSelector(
    state => state.roles.checkSubdomain.error
  );
  const { addSubdomainToApiTemplateStatus } = useSelector(state => state.roles);
  const { checkSubdomainStatus } = useSelector(state => state.roles);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSave = useCallback(
    debounce(subdomain => {
      dispatch(checkSubdomain(subdomain));
    }, 500),
    []
  );

  const handleDomainNameChange = input => {
    const subdomain = input;
    let error = Messages(
      validationMessagesKeys.validSubdomain,
      subdomain,
      true
    );
    if (!error) {
      setSubdomainChecking(true);
      debounceSave(subdomain);
      setSubdomainName(subdomain);
    }
    setSubdomainError(error);
  };

  useEffect(() => {
    if (checkedSubdomainError) {
      setSubdomainError(checkedSubdomainError);
    }
    setSubdomainChecking(checkSubdomainStatus === API_CALL_STATE.IN_PROGRESS);
    return () => dispatch({ type: CHECK_SUBDOMAIN_RESET });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkSubdomainStatus, dispatch]);

  return (
    <MainDialog
      title="Add Prebuilt"
      variant="primary"
      Icon={<LayoutIcon height={32} width={32} />}
      disableButton={subdomainChecking || subdomainError}
      subtitle={
        <Text
          variant="body2"
          css={{
            fontWeight: "$regular",
            c: "$textMedEmp",
            mb: "$14",
          }}
        >
          {`Enter a subdomain where your Prebuilt will be deployed.\n`}
          <a
            href={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/prebuilt/overview`}
            rel="noreferrer noopener"
            target="_blank"
          >
            <Text
              variant="body2"
              css={{ c: "$primaryLight", fontWeight: "$regular", mb: "$14" }}
              as="span"
            >
              Learn what’s possible with prebuilt{" "}
              <ArrowRightIcon height={14} width={14} />
            </Text>
          </a>
        </Text>
      }
      content={
        <>
          <Credential
            showClipboard={false}
            css={{ w: "100%", mb: !subdomainError ? "$14" : "0" }}
            error={subdomainError}
            passEvent={true}
            placeholder="e.g. mysubdomain"
            success={!(subdomainChecking || subdomainError)}
            onChangeHandler={e => {
              const input = e.target.value?.trim()?.toLowerCase();
              handleDomainNameChange(input);
            }}
            loading={addSubdomainToApiTemplateStatus}
            hideLabel
            title=""
            rightComp={
              <Text
                css={{
                  c: "$textAccentDisabled",
                }}
              >
                {process.env.REACT_APP_VERCEL_BASE_DOMAIN}
              </Text>
            }
          />
          <Text
            variant="caption"
            css={{
              fontWeight: "$regular",
              marginTop: "$4",
              c: "$error",
            }}
          >
            {createErrorMessage(subdomainError)}
          </Text>
        </>
      }
      open={trigger}
      buttonText="Add Prebuilt"
      mainFunction={() => {
        dispatch(
          addSubdomainToExistingTemplate(
            policyId,
            subdomainName + process.env.REACT_APP_VERCEL_BASE_DOMAIN,
            appType
          )
        );
        setTrigger(false);
      }}
      onOpenChange={() => setTrigger(prev => !prev)}
    />
  );
};

export default AddSubdomainModal;
