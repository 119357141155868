import React, { useState } from "react";
import { AddCircleIcon, NoEntryIcon } from "@100mslive/react-icons";
import { Box, Flex, Input, Text } from "@100mslive/react-ui";

const inputCSS = {
  color: "$textHighEmp",
  p: "0",
  outlint: "none",
  border: "none",
  fontWeight: "$semiBold",
  fontSize: "$sm",
  minHeight: "unset",
  pr: "$1",
  w: "100%",
  backgroundColor: "inherit",
  "&:focus": {
    boxShadow: "none",
  },
};

const TableRow = ({
  id,
  index,
  headerKey,
  headerValue,
  remove,
  keyChange,
  add,
  numOfRows,
  headerChange,
}) => {
  return (
    <Flex className="row-container group">
      <div className="py-3 h-14 px-3 flex border-l border-b border-border-default outline-none w-full max-w-[450px] left-cell">
        <Input
          value={headerKey}
          onChange={e => {
            keyChange(id, e.target.value.trim());
          }}
          type="text"
          css={inputCSS}
          placeholder="Enter Name"
        />
      </div>
      <div className="py-3 h-14 px-3 flex items-center border-r border-b border-border-default outline-none w-full max-w-[450px] right-cell">
        <Input
          value={headerValue}
          onChange={e => {
            headerChange(id, e.target.value.trim());
          }}
          css={inputCSS}
          type="text"
          placeholder="Enter Value"
        />
        {numOfRows === 1 && headerValue === "" && headerKey === "" ? null : (
          <div className="group-hover:block hidden">
            <Box
              title="Remove field"
              onClick={() => {
                if (numOfRows < 2) {
                  add();
                }
                remove(id);
              }}
              css={{
                color: "$error",
                cursor: "pointer",
              }}
            >
              <NoEntryIcon />
            </Box>
          </div>
        )}
      </div>
      {index === numOfRows - 1 ? (
        <div className="group-hover:block hidden">
          <Box
            title="Add field"
            onClick={add}
            css={{
              position: "relative",
              top: "$8",
              left: "$8",
              color: "$primaryLight",
              cursor: "pointer",
            }}
          >
            <AddCircleIcon />
          </Box>
        </div>
      ) : null}
    </Flex>
  );
};

const CustomTable = ({ data, changeData }) => {
  const [maxValue, setMaxValue] = useState(
    Math.floor(1000 + Math.random() * 9000)
  );
  const remove = rowId => {
    if (data.length <= 0) {
      return;
    }
    const arrayCopy = data.filter(row => row.id !== rowId);
    changeData(arrayCopy);
  };

  const add = () => {
    const tempData = data;
    tempData.push({
      id: maxValue,
      headerKey: "",
      headerValue: "",
    });
    changeData(tempData);
    setMaxValue(Math.floor(1000 + Math.random() * 9000));
  };

  const keyChange = (id, value) => {
    const tempData = data;
    for (let i = 0; i < tempData.length; i++) {
      if (tempData[i].id === id) {
        tempData[i] = {
          ...tempData[i],
          headerKey: value,
        };
        break;
      }
    }
    changeData(tempData);
  };

  const headerChange = (id, value) => {
    const tempData = data;
    for (let i = 0; i < tempData.length; i++) {
      if (tempData[i].id === id) {
        tempData[i] = {
          ...tempData[i],
          headerValue: value,
        };
        break;
      }
    }
    changeData(tempData);
  };

  const headers = [
    {
      title: "Name",
      class: {
        borderRight: "0px solid",
        borderLeft: "1px solid",
        borderTopLeftRadius: "$1",
      },
    },
    {
      title: "Value",
      class: { borderRight: "1px solid", borderTopRightRadius: "$1" },
    },
  ];

  return (
    <div className="flex flex-col relative w-full">
      <Text
        variant="xs"
        css={{ color: "$textMedEmp", fontWeight: "$semiBold", mb: "$4" }}
      >
        Webhook Headers
      </Text>
      <Flex>
        {headers.map(header => (
          <Box
            key={header.title}
            css={{
              padding: "$6",
              border: "1px solid",
              maxWidth: "450px",
              w: "100%",
              ...header.class,
              borderColor: "$borderDefault",
            }}
          >
            <Text
              variant="overline"
              css={{
                color: "$textMedEmp",
                fontWeight: "$semiBold",
                textTransform: "uppercase",
              }}
            >
              {header.title}
            </Text>
          </Box>
        ))}
      </Flex>
      <div className="body">
        {data?.length > 0
          ? data.map((rowData, index) => (
              <TableRow
                key={index}
                index={index}
                keyChange={keyChange}
                headerChange={headerChange}
                add={add}
                remove={remove}
                numOfRows={data.length}
                {...rowData}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default CustomTable;
