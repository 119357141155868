import React, { FC, ReactElement, useState } from "react";
import { isFunction } from "lodash";
import { ChevronDownIcon } from "@100mslive/react-icons";
import { Box, Flex } from "@100mslive/react-ui";

interface SettingsAccordionProps {
  children: ReactElement;
  onToggle?: (isOpen: boolean) => void;
  defaultOpen?: boolean;
}

const SettingsAccordion: FC<SettingsAccordionProps> = ({
  children,
  onToggle,
  defaultOpen = false,
}) => {
  const [showContent, setShowContent] = useState(defaultOpen);
  return (
    <>
      <Flex justify="end" css={{ w: "100%" }}>
        <ChevronDownIcon
          onClick={() => {
            isFunction(onToggle) && onToggle(!showContent);
            setShowContent(prev => !prev);
          }}
          style={{
            transform: showContent ? "rotate(180deg)" : "rotate(0)",
            transition: "transform ease 0.3s",
            cursor: "pointer",
          }}
        />
      </Flex>
      <Box
        css={{
          maxHeight: showContent ? "5000px" : "0",
          transition: "max-height ease 0.3s",
          overflow: "hidden",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default SettingsAccordion;
