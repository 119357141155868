export default function checkValidMOSData(
  mosScore: { value: [string, number | string] }[]
) {
  // return true if any of the value is number >=0 and <=5
  // return false if all of the value is string

  return mosScore.some(item => {
    if (typeof item.value[1] === "number") {
      return item.value[1] >= 0 && item.value[1] <= 5;
    } else {
      return false;
    }
  });
}
