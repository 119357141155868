import toastr from "src/components/Common/toastr";
import { AppAnalytics } from "src/helpers";
import api from "../../api";
import { API_CALL_STATE } from "../../constants";
import { getAPIURL } from "../../utils";

export const FETCH_INVOICES_INIT = "FETCH_INVOICES_INIT";
export const FETCH_INVOICES_DONE = "FETCH_INVOICES_DONE";
export const FETCH_INVOICES_FAIL = "FETCH_INVOICES_FAIL";
export const FETCH_ORGANISATION_DETAILS_INIT =
  "FETCH_ORGANISATION_DETAILS_INIT";
export const FETCH_ORGANISATION_DETAILS_DONE =
  "FETCH_ORGANISATION_DETAILS_DONE";
export const FETCH_ORGANISATION_DETAILS_FAIL =
  "FETCH_ORGANISATION_DETAILS_FAIL";
export const UPDATE_ORGANISATION_DETAILS_INIT =
  "UPDATE_ORGANISATION_DETAILS_INIT";
export const UPDATE_ORGANISATION_DETAILS_DONE =
  "UPDATE_ORGANISATION_DETAILS_DONE";
export const UPDATE_ORGANISATION_DETAILS_FAIL =
  "UPDATE_ORGANISATION_DETAILS_FAIL";

export function fetchOrganisationDetails() {
  return async (dispatch, getState) => {
    const { status } = getState().organisation;
    if (status.fetchOrganisationInfo === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_ORGANISATION_DETAILS_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .get(getAPIURL(`customers/details`));
      if (resp.data.success) {
        dispatch({
          type: FETCH_ORGANISATION_DETAILS_DONE,
          payload: resp.data.data,
        });
      } else {
        throw Error(resp?.data?.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_ORGANISATION_DETAILS_FAIL });
      toastr.error(e.message);
    }
  };
}

export function fetchInvoices() {
  return async (dispatch, getState) => {
    const { status } = getState().organisation;
    if (status.fetchInvoicesInfo === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_INVOICES_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .get(getAPIURL("billings/invoices-v2"));
      if (resp.data.success) {
        dispatch({
          type: FETCH_INVOICES_DONE,
          payload: resp.data.results,
        });
        // toastr.success(resp?.data?.msg);
      } else {
        throw Error(resp?.data?.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_INVOICES_FAIL });
      toastr.error(e.message);
    }
  };
}

export function updateOrganisationDetails(data) {
  return async (dispatch, getState) => {
    const { status } = getState().organisation;
    if (status.updateOrganisationInfo === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: UPDATE_ORGANISATION_DETAILS_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL("customers/update"), {
          customer_id: data.ID,
          name: data.Name,
        });
      if (resp.data.success) {
        dispatch({
          type: UPDATE_ORGANISATION_DETAILS_DONE,
          payload: resp.data.data,
        });
        toastr.success(resp?.data?.msg);
      } else {
        throw Error(resp?.data?.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: UPDATE_ORGANISATION_DETAILS_FAIL });
      toastr.error(e.message);
    }
  };
}

export const FETCH_BILLING_INIT = "FETCH_BILLING_INIT";
export const FETCH_BILLING_DONE = "FETCH_BILLING_DONE";
export const FETCH_BILLING_FAIL = "FETCH_BILLING_FAIL";
export const FETCH_BILLING_RESET = "FETCH_BILLING_RESET";
export function fetchBillingInfo() {
  return async (dispatch, getState) => {
    const { status } = getState().organisation;
    if (status.fetchBillingInfo === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_BILLING_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL("billings/customers/cards/add"));
      if (resp.data.success) {
        dispatch({
          type: FETCH_BILLING_DONE,
          payload: resp.data,
        });
      } else {
        throw Error(resp?.data?.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_BILLING_FAIL });
      toastr.error(e.message);
    }
  };
}

export const FETCH_PAYMENT_CARDS_INIT = "FETCH_PAYMENT_CARDS_INIT";
export const FETCH_PAYMENT_CARDS_DONE = "FETCH_PAYMENT_CARDS_DONE";
export const FETCH_PAYMENT_CARDS_FAIL = "FETCH_PAYMENT_CARDS_FAIL";
export function fetchPaymentCards() {
  return async (dispatch, getState) => {
    const { status } = getState().organisation;
    if (status.fetchPaymentCards === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_PAYMENT_CARDS_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .get(getAPIURL("billings/customers/cards"));
      if (resp.data.success) {
        dispatch({
          type: FETCH_PAYMENT_CARDS_DONE,
          payload: resp.data,
        });
      } else {
        throw Error(resp?.data?.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_PAYMENT_CARDS_FAIL });
      toastr.error(e.message);
    }
  };
}

export const FETCH_CUSTOMER_DETAIL_INIT = "FETCH_CUSTOMER_DETAIL_INIT";
export const FETCH_CUSTOMER_DETAIL_DONE = "FETCH_CUSTOMER_DETAIL_DONE";
export const FETCH_CUSTOMER_DETAIL_FAIL = "FETCH_CUSTOMER_DETAIL_FAIL";
export function fetchCustomerDetails() {
  return async (dispatch, getState) => {
    const { status } = getState().organisation;
    if (status.fetchCustomerDetails === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_CUSTOMER_DETAIL_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .get(getAPIURL("billings/customers"));
      if (resp.data.success) {
        dispatch({
          type: FETCH_CUSTOMER_DETAIL_DONE,
          payload: resp.data,
        });
      } else {
        throw Error(resp?.data?.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: FETCH_CUSTOMER_DETAIL_FAIL });
      toastr.error(e.message);
    }
  };
}

export const UPDATE_CUSTOMER_DETAIL_INIT = "UPDATE_CUSTOMER_DETAIL_INIT";
export const UPDATE_CUSTOMER_DETAIL_DONE = "UPDATE_CUSTOMER_DETAIL_DONE";
export const UPDATE_CUSTOMER_DETAIL_FAIL = "UPDATE_CUSTOMER_DETAIL_FAIL";
export function updateCustomerDetails(data) {
  return async (dispatch, getState) => {
    const { status } = getState().organisation;
    if (status.updateCustomerDetails === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: UPDATE_CUSTOMER_DETAIL_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL("billings/customers/update"), data);
      if (resp.data.success) {
        dispatch({
          type: UPDATE_CUSTOMER_DETAIL_DONE,
          payload: data,
        });
        toastr.success("Address update successful");
        try {
          AppAnalytics.track("save.inforamation.success", {
            componentId: "billing.information.pop-up",
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        throw Error(resp?.data?.msg);
      }
    } catch (e) {
      console.error(e);
      try {
        AppAnalytics.track("save.inforamation.failure", {
          componentId: "billing.information.pop-up",
        });
      } catch (e) {
        console.error(e);
      }
      dispatch({ type: UPDATE_CUSTOMER_DETAIL_FAIL });
      toastr.error(e.message);
    }
  };
}

export const DELETE_PAYMENT_CARD_INIT = "DELETE_PAYMENT_CARD_INIT";
export const DELETE_PAYMENT_CARD_DONE = "DELETE_PAYMENT_CARD_DONE";
export const DELETE_PAYMENT_CARD_FAIL = "DELETE_PAYMENT_CARD_FAIL";
export function deletePaymentCard(id) {
  return async (dispatch, getState) => {
    const { status } = getState().organisation;
    if (status.deletePaymentCard === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: DELETE_PAYMENT_CARD_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL("billings/customers/cards/delete"), {
          payment_method_id: id,
        });
      if (resp.data.success) {
        dispatch({
          type: DELETE_PAYMENT_CARD_DONE,
          payload: id,
        });
        toastr.success("Card removal successful");
      } else {
        throw Error(resp?.data?.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: DELETE_PAYMENT_CARD_FAIL });
      toastr.error(e.message);
    }
  };
}

export const PATCH_PAYMENT_CARD_INIT = "PATCH_PAYMENT_CARD_INIT";
export const PATCH_PAYMENT_CARD_DONE = "PATCH_PAYMENT_CARD_DONE";
export const PATCH_PAYMENT_CARD_FAIL = "PATCH_PAYMENT_CARD_FAIL";
export function patchPaymentCard(id) {
  return async (dispatch, getState) => {
    const { status } = getState().organisation;
    if (status.patchPaymentCard === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: PATCH_PAYMENT_CARD_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL("billings/customers/cards/make-default"), {
          payment_method_id: id,
        });
      if (resp.data.success) {
        dispatch({
          type: PATCH_PAYMENT_CARD_DONE,
          payload: id,
        });
        toastr.success("Primary card change successful");
      } else {
        throw Error(resp?.data?.msg);
      }
    } catch (e) {
      console.error(e);
      dispatch({ type: PATCH_PAYMENT_CARD_FAIL });
      toastr.error(e.message);
    }
  };
}
