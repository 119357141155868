import awsRegions from "aws-regions";
import { get, isEmpty, merge, set } from "lodash";
import { policyTemplate } from "src/store/policyTemplate";
import {
  hlsDestinationsLayerType,
  hlsDestinationsType,
  RecordingConfigType,
  RecordingType,
  roleType,
  rtmpDestinationsType,
  TranscriptionType,
} from "src/types/policyTypes";
import {
  hlsDestinationsInvalidFieldsType,
  layersInvalidFieldsType,
  recordingInvalidFieldsType,
  recordingsConfigInvalidFieldsType,
  transcriptionsInvalidFieldsType,
} from "src/types/validations/destinations";
import { HLS_DEFAULT_VIDEO_LAYER_CONFIG } from "../constants";

export const validateOSSFields = (recordingInfo: RecordingType) => {
  let isValid = true;
  const invalidFields = {} as recordingInvalidFieldsType;

  const accessKeyPattern = /(LTAI)[A-Za-z0-9]{20}/i;
  if (!recordingInfo?.upload?.options?.region) {
    isValid = false;
    invalidFields["region"] = "* This is a required field";
  }
  if (
    !!recordingInfo?.upload?.credentials?.key &&
    !recordingInfo?.upload?.credentials?.key.match(accessKeyPattern)
  ) {
    isValid = false;
    invalidFields["accessKey"] = "Invalid Access key";
  }
  return { isValid, invalidFields };
};

//eslint-disable-next-line
export const validateGCPFields = () => {
  const isValid = true;
  const invalidFields = {} as recordingInvalidFieldsType;
  return { isValid, invalidFields };
};

export const validateR2Fields = (recordingInfo: RecordingType) => {
  let isValid = true;
  const invalidFields = {} as recordingInvalidFieldsType;

  if (!recordingInfo?.upload?.options?.account_id) {
    isValid = false;
    invalidFields["accountId"] = "* This is a required field";
  }

  return { isValid, invalidFields };
};

//eslint-disable-next-line complexity
export const validateS3Fields = (recordingInfo: RecordingType) => {
  let isValid = true;
  const invalidFields = {} as recordingInvalidFieldsType;
  const accessKeyPattern = /((?:ASIA|AKIA|AROA|AIDA)([A-Z0-7]{16}))/;
  const secretKeyPattern = /([a-zA-Z0-9+/]{40})/;
  const locationPattern =
    /(?!(^((2(5[0-5]|[0-4][0-9])|[01]?[0-9]{1,2})\.){3}(2(5[0-5]|[0-4][0-9])|[01]?[0-9]{1,2})$|^xn--|.+-s3alias$))^[a-z0-9][a-z0-9.-]{1,61}[a-z0-9]$/;
  if (!recordingInfo?.upload?.options?.region) {
    isValid = false;
    invalidFields["region"] = "* This is a required field";
  }
  if (
    !!recordingInfo?.upload?.credentials?.key &&
    !recordingInfo?.upload?.credentials?.key.match(accessKeyPattern)
  ) {
    isValid = false;
    invalidFields["accessKey"] = "Invalid Access key";
  }
  if (
    !!recordingInfo?.upload?.credentials?.secretKey &&
    !recordingInfo?.upload?.credentials?.secretKey.match(secretKeyPattern)
  ) {
    isValid = false;
    invalidFields["secretKey"] = "Invalid Secret key";
  }

  if (
    !!recordingInfo?.upload?.options?.region &&
    isEmpty(awsRegions.lookup({ code: recordingInfo?.upload?.options?.region }))
  ) {
    isValid = false;
    invalidFields["region"] = "Invalid AWS server region";
  }

  if (
    !!recordingInfo?.upload?.location &&
    !recordingInfo?.upload?.location.match(locationPattern)
  ) {
    isValid = false;
    invalidFields["location"] = "Invalid Bucket Name";
  }
  const prefix = recordingInfo?.upload?.prefix;
  // eslint-disable-next-line
  const prefixRegex = /^(?!\/)(?!.*\/\/)[a-zA-Z0-9\/\-_.]+$/;
  // disallow leading /, consecutive /
  if (!!prefix && !prefixRegex.test(prefix)) {
    isValid = false;
    invalidFields["prefix"] = "Please enter a valid prefix";
  }

  return { isValid, invalidFields };
};

const validationConfig = {
  s3: validateS3Fields,
  gs: validateGCPFields,
  oss: validateOSSFields,
  r2: validateR2Fields,
};

// eslint-disable-next-line complexity
export const validateRecordings = (recordingInfo: RecordingType) => {
  let isValid = true;
  let invalidFields = {} as recordingInvalidFieldsType;
  const isActive = !!recordingInfo?.upload?.type;
  if (isActive) {
    if (!recordingInfo?.upload?.type) {
      isValid = false;
      invalidFields["uploadType"] = "* This is a required field";
    }

    // check for access key
    if (!recordingInfo?.upload?.credentials?.key) {
      isValid = false;
      invalidFields["accessKey"] = "* This is a required field";
    }

    // check for storage type
    if (!recordingInfo?.upload?.credentials?.secretKey) {
      isValid = false;
      invalidFields["secretKey"] = "* This is a required field";
    }

    // check for bucket Location
    if (!recordingInfo?.upload?.location) {
      isValid = false;
      invalidFields["location"] = "* This is a required field";
    }
    const configResponse =
      validationConfig[
        recordingInfo?.upload?.type as keyof typeof validationConfig
      ](recordingInfo);
    isValid = isValid && configResponse.isValid;
    invalidFields = {
      ...invalidFields,
      ...configResponse.invalidFields,
    };
  }
  return { isValid, invalidFields };
};

//eslint-disable-next-line complexity
export function validateHLSdestinationFields({
  hlsDestinations,
  hlsRole,
}: {
  hlsDestinations: hlsDestinationsType;
  hlsRole: roleType;
}) {
  let isValid = true;
  const invalidFields = {} as hlsDestinationsInvalidFieldsType;
  if (isEmpty(hlsDestinations)) {
    return { isValid: false, invalidFields };
  }
  if (hlsRole?.subscribeParams?.subscribeToRoles?.length === 0) {
    isValid = false;
    invalidFields["hlsBroadcaster"] =
      "Select at least 1 role to subscribe for the live stream";
  }

  const isRecording =
    hlsDestinations.recording?.hlsVod ||
    hlsDestinations.recording?.singleFilePerLayer;
  //check if internal recorder subscribed or not

  if (hlsDestinations?.layers?.length === 0) {
    isValid = false;
    invalidFields["layers"] = "Please select atleast one layer";
  }
  const maxTotalBitrate720p = 5 * 1024; // Maximum allowed total bitrate when highest layer is 720p or lower: 5Mbps
  const maxTotalBitrate1080p = 8 * 1024; // Maximum allowed total bitrate when highest layer is 1080p or above 720p: 8Mbps
  if (Array.isArray(hlsDestinations?.layers)) {
    const layers: Array<hlsDestinationsLayerType> = hlsDestinations?.layers as
      | Array<hlsDestinationsLayerType>
      | [];
    const totalBitrate = layers.reduce((accumulator, layer) => {
      return accumulator + (layer.audioBitrate + layer.videoBitrate);
    }, 0);

    const totalAudioBitrate = layers.reduce((accumulator, layer) => {
      return accumulator + layer.audioBitrate;
    }, 0);

    if (layers[0].width <= 720 || layers[0].height <= 720) {
      if (totalBitrate > maxTotalBitrate720p) {
        invalidFields[
          "layers"
        ] = `Maximum allowed total bitrate when highest layer is 720p or lower: ${
          maxTotalBitrate720p - totalAudioBitrate
        }`;
        isValid = false;
      }
    } else {
      // consider as 1080p
      if (totalBitrate > maxTotalBitrate1080p) {
        invalidFields[
          "layers"
        ] = `Maximum allowed total bitrate when highest layer is 1080p or lower: ${
          maxTotalBitrate1080p - totalAudioBitrate
        }`;
        isValid = false;
      }
    }

    if (hlsDestinations?.layers?.length > 4) {
      isValid = false;
      invalidFields["layers"] = "Upto 4 layers are supported as of now";
    }
  }
  if (
    !hlsDestinations["maxDuration"] ||
    !hlsDestinations["videoFrameRate"] ||
    !hlsDestinations?.["recording"]?.["presignDuration"]
  ) {
    if (!hlsDestinations["maxDuration"]) {
      isValid = false;
      invalidFields["maxDuration"] = "Please enter a value";
    }
    if (
      !hlsDestinations?.numPlaylistSegments &&
      hlsDestinations?.playlistType === "live"
    ) {
      isValid = false;
      invalidFields["numPlaylistSegments"] = "Please enter a value";
    }
    if (!hlsDestinations["videoFrameRate"]) {
      isValid = false;
      invalidFields["videoFrameRate"] = "Please enter a value";
    }
    if (isRecording) {
      if (!hlsDestinations?.["recording"]?.["presignDuration"]) {
        isValid = false;
        invalidFields.presignDuration = "Please enter a value";
      }
    }
  }
  if (
    "number" === typeof hlsDestinations?.["maxDuration"] &&
    (hlsDestinations?.["maxDuration"] < 1800 ||
      hlsDestinations?.["maxDuration"] > 28800)
  ) {
    isValid = false;
    invalidFields["maxDuration"] = "Please enter a value between 30 and 480";
  }
  if (!hlsDestinations["playlistType"]) {
    isValid = false;
    invalidFields["playlistType"] = "Please select a value";
  }
  if (
    "number" === typeof hlsDestinations?.["numPlaylistSegments"] &&
    (hlsDestinations?.["numPlaylistSegments"] < 3 ||
      hlsDestinations?.["numPlaylistSegments"] > 900) &&
    hlsDestinations["playlistType"] === "live"
  ) {
    isValid = false;
    // each segment is of 2 secs. hence playlistSegment value should be even
    invalidFields["numPlaylistSegments"] =
      "Please enter a value between 6 and 1800";
  }

  if (
    hlsDestinations.numPlaylistSegments &&
    hlsDestinations.playlistType === "live" &&
    hlsDestinations.numPlaylistSegments % 1 !== 0
  ) {
    isValid = false;
    invalidFields.numPlaylistSegments = "Please enter an even value.";
  }

  if (
    "number" === typeof hlsDestinations?.["videoFrameRate"] &&
    (hlsDestinations["videoFrameRate"] < 10 ||
      hlsDestinations["videoFrameRate"] > 30)
  ) {
    isValid = false;
    invalidFields["videoFrameRate"] = "Please enter a value between 10 and 30";
  }
  if (isRecording) {
    if (
      !hlsDestinations.recording?.hlsVod &&
      !hlsDestinations.recording?.singleFilePerLayer
    ) {
      isValid = false;
      invalidFields["typeOfRecording"] =
        "Please select at least one type of recording";
    }
    if (
      "number" === typeof hlsDestinations?.["recording"]?.["presignDuration"] &&
      (hlsDestinations.recording?.presignDuration < 3600 ||
        hlsDestinations.recording?.presignDuration > 604800)
    ) {
      isValid = false;
      invalidFields["presignDuration"] =
        "Please enter a value between 1 and 168";
    }
    if (
      hlsDestinations.recording?.thumbnails?.offsets?.[0] &&
      hlsDestinations.recording.thumbnails.offsets[0] < 2
    ) {
      isValid = false;
      invalidFields["thumbnailOffset"] =
        "The value should be at least 2 seconds";
    }
  }

  return { isValid, invalidFields };
}

// eslint-disable-next-line no-empty-pattern
export function validateTranscriptionFields({
  transcription,
}: {
  transcription: TranscriptionType;
}) {
  let isValid = true;

  const invalidFields = {} as transcriptionsInvalidFieldsType;

  const isPostCallTranscription =
    !isEmpty(transcription) && transcription?.modes?.includes("recorded");

  if (isPostCallTranscription) {
    if (
      isEmpty(transcription?.outputModes) ||
      transcription?.outputModes?.length === 0
    ) {
      isValid = false;
      invalidFields["outputModes"] = "Please select at least one output mode";
    }
  }

  return { isValid, invalidFields };
}

// eslint-disable-next-line complexity
export function validateRecordingsConfigFields({
  recordingConfig,
}: {
  recordingConfig: RecordingConfigType;
}) {
  let isValid = true;

  const invalidFields = {} as recordingsConfigInvalidFieldsType;

  if (
    "number" === typeof recordingConfig?.["presignDuration"] &&
    (recordingConfig?.presignDuration < 3600 ||
      recordingConfig?.presignDuration > 604800)
  ) {
    isValid = false;
    invalidFields["presignDuration"] = "Please enter a value between 1 and 168";
  }
  if (
    "number" === typeof recordingConfig?.["maxDuration"] &&
    (recordingConfig?.["maxDuration"] < 1800 ||
      recordingConfig?.["maxDuration"] > 28800)
  ) {
    isValid = false;
    invalidFields["maxDuration"] = "Please enter value a between 30 and 480";
  }
  return { isValid, invalidFields };
}

export function validateLayers({
  videoBitrate,
  videoLayer,
}: {
  videoBitrate: number;
  videoLayer: number;
}) {
  let isValid = true;
  const invalidFields = {} as layersInvalidFieldsType;

  if (!videoLayer) {
    isValid = false;
    invalidFields["videoLayer"] = "Please select a video layer";
  }
  const defaultLayer = HLS_DEFAULT_VIDEO_LAYER_CONFIG.filter(layer => {
    return layer.value === videoLayer;
  })[0];
  if (!videoBitrate && defaultLayer.value !== 0) {
    isValid = false;
    invalidFields["videoLayer"] = "Please select a video layer";
  }

  if (videoLayer && defaultLayer.value !== 0) {
    if (
      videoBitrate < defaultLayer.minVideoBitRate ||
      videoBitrate > defaultLayer.maxVideoBitRate
    ) {
      isValid = false;
      invalidFields[
        "videoBitrate"
      ] = `Please select a video bitrate between ${defaultLayer.minVideoBitRate} and ${defaultLayer.maxVideoBitRate}`;
    }
  }

  return { isValid, invalidFields };
}
//eslint-disable-next-line complexity
export function getMergedDestinationPolicy(destination = {}) {
  const defaultDestinationPolicy = {
    ...(policyTemplate?.["destinations"]?.["hlsDestinations"]?.[
      "default"
    ] as hlsDestinationsType),
  };

  const mergedDestinationPolicy = merge({}, destination);
  const mergeKeys = [
    "maxDuration",
    "playlistType",
    "numPlaylistSegments",
    "videoFrameRate",
    "layers",
  ];
  mergeKeys.forEach(key => {
    const value = get(mergedDestinationPolicy, key);
    const defaultValue = get(defaultDestinationPolicy, key);
    if (!value) {
      set(mergedDestinationPolicy, key, defaultValue);
    }
  });
  return mergedDestinationPolicy;
}

export function validateRTMPdestinationFields(
  rtmpDestinations: rtmpDestinationsType
) {
  const invalidFields: Record<string, unknown> = {};
  // 4 hrs is the max allowed
  if (
    rtmpDestinations?.maxDuration &&
    (rtmpDestinations.maxDuration > 28800 ||
      rtmpDestinations.maxDuration < 1800)
  ) {
    invalidFields["maxDuration"] =
      "The maximum recording duration should be between 30 and 480 minutes";
  }
  return invalidFields;
}

export function getMergedTranscriptionPolicy(transcription = {}) {
  const defaultTranscriptionPolicy = {
    ...policyTemplate?.destinations?.["transcriptions"]?.["default"],
  };
  const mergedTranscription = merge({}, transcription);
  const mergeKeys = [
    "outputModes",
    "language",
    "customVocabulary",
    "summary.enabled",
    "summary.context",
    "summary.temperature",
    "summary.sections",
  ];
  mergeKeys.forEach(key => {
    const value = get(mergedTranscription, key);
    const defaultValue = get(defaultTranscriptionPolicy, key);
    if (!value) {
      set(mergedTranscription, key, defaultValue);
    }
  });
  return mergedTranscription as TranscriptionType;
}

export function getMergedRtmpPolicy(rtmpObj = {}) {
  const rtmpPolicy = {
    ...policyTemplate?.destinations?.["rtmpDestinations"]?.["default"],
  };
  const mergedRtmpDestinations = merge({}, rtmpObj);
  const mergeKeys = [
    "maxDuration",
    "width",
    "height",
    "name",
    "recordingEnabled",
  ];
  mergeKeys.forEach(key => {
    const value = get(mergedRtmpDestinations, key);
    const defaultValue = get(rtmpPolicy, key);
    if (!value) {
      set(mergedRtmpDestinations, key, defaultValue);
    }
  });
  return mergedRtmpDestinations;
}
