import { AnyAction } from "redux";
import { handleActions } from "redux-actions";
import { API_CALL_STATE, WORKSPACE_INSIGHTS_FILTER } from "src/constants";
import {
  SessionMinutesBreakdown,
  WorkspaceIssueDetailTrends,
  WorkspaceIssueInsightsDataStruct,
  WorkspacePeerDetailTrends,
  WorkspacePeerInsightDataStruct,
  WorkspaceRecordingsDetailTrends,
  WorkspaceRecordingsInsightsEvent,
  WorkspaceRecordingsTATInsightsEvent,
  WorkspaceSessionDetails,
  WorkspaceSessionDetailTrends,
} from "src/types/insightsTypes";
import {
  FETCH_WORKSPACE_ISSUES_INSIGHTS_DONE,
  FETCH_WORKSPACE_ISSUES_INSIGHTS_FAIL,
  FETCH_WORKSPACE_ISSUES_INSIGHTS_INIT,
  FETCH_WORKSPACE_PEER_INSIGHTS_DONE,
  FETCH_WORKSPACE_PEER_INSIGHTS_FAIL,
  FETCH_WORKSPACE_PEER_INSIGHTS_INIT,
  FETCH_WORKSPACE_RECORDING_INSIGHTS_DONE,
  FETCH_WORKSPACE_RECORDING_INSIGHTS_FAIL,
  FETCH_WORKSPACE_RECORDING_INSIGHTS_INIT,
  FETCH_WORKSPACE_SESSION_INSIGHTS_DONE,
  FETCH_WORKSPACE_SESSION_INSIGHTS_FAIL,
  FETCH_WORKSPACE_SESSION_INSIGHTS_INIT,
  SET_END_DATE_FOR_INSIGHTS,
  SET_FILTER_FOR_INSIGHTS,
  SET_START_DATE_FOR_INSIGHTS,
} from "./actions";
export const insights = handleActions(
  {
    [FETCH_WORKSPACE_PEER_INSIGHTS_INIT]: state => ({
      ...state,
      fetchWorkspacePeerInsightsStatus: API_CALL_STATE.IN_PROGRESS,
      workspacePeerInsightsDetails: [] as WorkspacePeerInsightDataStruct[],
    }),
    [FETCH_WORKSPACE_PEER_INSIGHTS_FAIL]: state => ({
      ...state,
      fetchWorkspacePeerInsightsStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_WORKSPACE_PEER_INSIGHTS_DONE]: (state, action: AnyAction) => ({
      ...state,
      fetchWorkspacePeerInsightsStatus: API_CALL_STATE.DONE,
      workspacePeerInsightsTrends: action.payload
        ?.trend as WorkspacePeerDetailTrends,
      workspacePeerInsightsDetails: action.payload
        ?.data as WorkspacePeerInsightDataStruct[],
    }),
    [FETCH_WORKSPACE_SESSION_INSIGHTS_INIT]: state => ({
      ...state,
      workspaceSessionInsightsTrends: {} as WorkspaceSessionDetailTrends,
      workspaceSessionInsightsDetails: {} as WorkspaceSessionDetails,
      workspaceSessionInsightsBreakdown: {} as SessionMinutesBreakdown,
      fetchWorkspaceSessionInsightsStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [FETCH_WORKSPACE_SESSION_INSIGHTS_FAIL]: state => ({
      ...state,
      fetchWorkspaceSessionInsightsStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_WORKSPACE_SESSION_INSIGHTS_DONE]: (state, action: AnyAction) => ({
      ...state,
      fetchWorkspaceSessionInsightsStatus: API_CALL_STATE.DONE,
      workspaceSessionInsightsTrends: action.payload
        ?.trend as WorkspaceSessionDetailTrends,
      workspaceSessionInsightsDetails: action.payload
        ?.session_details as WorkspaceSessionDetails,
      workspaceSessionInsightsBreakdown: action.payload
        ?.session_minutes_breakdown as SessionMinutesBreakdown,
    }),

    [FETCH_WORKSPACE_ISSUES_INSIGHTS_DONE]: (state, action: AnyAction) => ({
      ...state,
      fetchWorkspaceIssuesInsightsStatus: API_CALL_STATE.DONE,
      workspaceIssuesInsightsDetails: action.payload
        ?.data as WorkspaceIssueInsightsDataStruct,
      workspaceIssuesInsightsTrends: action.payload
        ?.trend as WorkspaceIssueDetailTrends,
    }),
    [FETCH_WORKSPACE_ISSUES_INSIGHTS_FAIL]: state => ({
      ...state,
      fetchWorkspaceIssuesInsightsStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_WORKSPACE_ISSUES_INSIGHTS_INIT]: state => ({
      ...state,
      fetchWorkspaceIssuesInsightsStatus: API_CALL_STATE.IN_PROGRESS,
      workspaceIssuesInsightsDetails: {} as WorkspaceIssueInsightsDataStruct,
    }),
    [FETCH_WORKSPACE_RECORDING_INSIGHTS_INIT]: state => ({
      ...state,
      fetchWorkspaceRecordingsInsightsStatus: API_CALL_STATE.IN_PROGRESS,
    }),
    [FETCH_WORKSPACE_RECORDING_INSIGHTS_FAIL]: state => ({
      ...state,
      fetchWorkspaceRecordingsInsightsStatus: API_CALL_STATE.FAILED,
    }),
    [FETCH_WORKSPACE_RECORDING_INSIGHTS_DONE]: (state, action: AnyAction) => ({
      ...state,
      fetchWorkspaceRecordingsInsightsStatus: API_CALL_STATE.DONE,
      workspaceRecordingsInsightsDetails: action.payload
        ?.recordings as WorkspaceRecordingsInsightsEvent[],
      workspaceRecordingsInsightsTrends: action.payload
        ?.trend as WorkspaceRecordingsDetailTrends,
      workspaceRecordingsTATInsightsDetails: action.payload
        ?.tat as WorkspaceRecordingsTATInsightsEvent[],
    }),
    [SET_START_DATE_FOR_INSIGHTS]: (state, action: AnyAction) => ({
      ...state,
      startDate: action.payload,
    }),
    [SET_END_DATE_FOR_INSIGHTS]: (state, action: AnyAction) => ({
      ...state,
      endDate: action.payload,
    }),
    [SET_FILTER_FOR_INSIGHTS]: (state, action: AnyAction) => ({
      ...state,
      filter: action.payload,
    }),
  },
  {
    filter: "" as keyof typeof WORKSPACE_INSIGHTS_FILTER,
    startDate: null as Date | null,
    endDate: null as Date | null,
    fetchWorkspaceSessionInsightsStatus: "",
    fetchWorkspacePeerInsightsStatus: "",
    fetchWorkspaceIssuesInsightsStatus: "",
    fetchWorkspaceRecordingsInsightsStatus: "",
    workspaceIssuesInsightsTrends: {} as WorkspaceIssueDetailTrends,
    workspaceSessionInsightsTrends: {} as WorkspaceSessionDetailTrends,
    workspacePeerInsightsTrends: {} as WorkspacePeerDetailTrends,
    workspaceSessionInsightsDetails: {} as WorkspaceSessionDetails,
    workspaceSessionInsightsBreakdown: {} as SessionMinutesBreakdown,
    workspacePeerInsightsDetails: [] as WorkspacePeerInsightDataStruct[],
    workspaceIssuesInsightsDetails: {} as WorkspaceIssueInsightsDataStruct,
    workspaceRecordingsInsightsDetails:
      [] as WorkspaceRecordingsInsightsEvent[],
    workspaceRecordingsInsightsTrends: {} as WorkspaceRecordingsDetailTrends,
    workspaceRecordingsTATInsightsDetails:
      [] as WorkspaceRecordingsTATInsightsEvent[],
  }
);
