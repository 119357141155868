export const permissionsToggleFieldsData: {
  path: string;
  tooltip: string;
  title: string;
  error?: string;
}[] = [
  {
    path: "changeRole",
    tooltip:
      "With this permission, user will be able to change the role of the other participant's who are present in the room.",
    title: "Change any Peer's Role",
  },
  {
    path: "mute",
    tooltip:
      "With this permission, user will be able to mute any participant's audio and/or video.",
    title: "Mute any Peer",
  },
  {
    path: "unmute",
    tooltip:
      "With this permission, user will be able to ask any participant to unmute their audio and/or video",
    title: "Ask Peer to Unmute",
  },
  {
    path: "removeOthers",
    tooltip:
      "With this permission, user will be able to remove any participant from the current session of the room",
    title: "Remove Peer from the Session",
  },
  {
    path: "endRoom",
    tooltip:
      "With this permission, user will be able to end the current session of the room. Additionally, the user can pass a flag to lock the room which will prevent a new session.",
    title: "End current session",
  },
  {
    path: "sendRoomState",
    tooltip:
      "Enabling this would start sending room-specific data for this specific user role.",
    title: "Receive Preview Room State",
    error: "Warning: Role won't receive preview room state",
  },
  {
    path: "rtmpStreaming",
    tooltip:
      "With this permission, you will be able to publish live audio/video livestream externally to social media and custom platforms (e.g Youtube/Facebook/Twitter).",
    title: "Start/Stop External Streaming (RTMP-Out)",
  },
  {
    path: "hlsStreaming",
    tooltip:
      "With this permission, you will be able to publish your audio/video livestream in the HLS format.",
    title: "Start/Stop Live Streaming (HLS)",
  },
  {
    path: "browserRecording",
    tooltip:
      " With this permission, you will be able to record your meeting/livestream via the browser recording approach where a bot will join the room and record the meeting/livestream as is.",
    title: "Start/Stop Room Composite Recording",
  },
  {
    path: "pollWrite",
    tooltip:
      "Peers of this role can create new polls/quizzes, set questions, and start and stop polls.",
    title: "Create polls and quizzes",
  },
  {
    path: "pollRead",
    tooltip:
      "Peers of this role can see available polls/quizzes and answer questions in them.",
    title: "Read polls and quizzes",
  },
];
