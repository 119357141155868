import echarts from "echarts";
import { isArray, isEmpty } from "lodash";

type echartsSeries = echarts.LineSeriesOption & { data: echartsSeriesData[] };
type echartsSeriesData = {
  value: [number, string];
  symbol: () => string | string;
  symbolSize: number;
  message?: string;
  timestamp?: string;
};
type Event = {
  message: string;
  timestamp: string;
  type: string;
};

const getAVSEDtrackSymbol = (type: string) => {
  const AVSEDSymbolMap = {
    VIDEO_ON: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Event_Indicator_3_28e080b430/Event_Indicator_3_28e080b430.svg`,
    VIDEO_OFF: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/video_off_740ec48a28/video_off_740ec48a28.svg`,
    AUDIO_ON: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Event_Indicator_4_f817f3ac66/Event_Indicator_4_f817f3ac66.svg`,
    AUDIO_OFF: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/audio_off_05639be220/audio_off_05639be220.svg`,
    SCREEN_ON: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/Event_Indicator_5_01bfed4a45/Event_Indicator_5_01bfed4a45.svg`,
    SCREEN_OFF: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/screen_off_34c6ffde02/screen_off_34c6ffde02.svg`,
    DEVICE_NOTIF: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/device_ffde6900bc/device_ffde6900bc.svg?updated_at=2024-02-15T13:38:10.399Z`,
    ERROR_NOTIF: `image://${process.env.REACT_APP_CMS_MEDIA_SERVER}cms/error_notification_a6e3e8241c/error_notification_a6e3e8241c.svg`,
  };

  if (AVSEDSymbolMap[type as keyof typeof AVSEDSymbolMap]) {
    return AVSEDSymbolMap[type as keyof typeof AVSEDSymbolMap];
  } else {
    return "";
  }
};

export function convertFormatToEventLogs(
  data:
    | {
        video: { message: string; timestamp: string; type: string }[];
        audio: { message: string; timestamp: string; type: string }[];
        errors: { message: string; timestamp: string; type: string }[];
        device: { message: string; timestamp: string; type: string }[];
        screen: { message: string; timestamp: string; type: string }[];
      }
    | {}
): [] {
  if (isEmpty(data)) {
    return [];
  }
  const series = [] as echartsSeries[];
  let dummyStartTimestamp = "";
  let dummyEndTimestamp = "";
  //Process events
  Object.keys(data)
    .sort((a, b) => sortSeriesbyKey(a, b))
    .forEach(key => {
      let tempData;

      if (isArray(data?.[key as keyof typeof data])) {
        tempData = (data?.[key as keyof typeof data] as Event[])?.map(event => {
          if (["dummy_start", "dummy_end"].includes(event.message)) {
            if (event.message === "dummy_start") {
              dummyStartTimestamp = event.timestamp;
            } else if (event.message === "dummy_end") {
              dummyEndTimestamp = event.timestamp;
            }
            return {
              symbolSize: 0,
              symbol: "none",
              value: [new Date(event.timestamp).getTime(), key],
            };
          }

          const symbol = getAVSEDtrackSymbol(event.type);

          const symbolOffset = [0, 0];

          if (
            Math.floor(
              (Math.abs(
                new Date(dummyStartTimestamp).getTime() -
                  new Date(event.timestamp).getTime()
              ) /
                1000) %
                60
            ) === 0
          ) {
            symbolOffset[0] = 8;
          } else if (
            Math.floor(
              (Math.abs(
                new Date(dummyEndTimestamp).getTime() -
                  new Date(event.timestamp).getTime()
              ) /
                1000) %
                60
            ) === 0
          ) {
            symbolOffset[0] = -8;
          }
          return {
            value: [new Date(event.timestamp).getTime(), key],
            symbol: symbol,
            symbolSize: 16,
            message: event.message,
            timestamp: event.timestamp,
            symbolOffset: symbolOffset,
          };
        });
      }

      series.push({
        //@ts-ignore
        data: tempData,
        lineStyle: {
          width: 16,
          color: key === "errors" ? "rgba(203, 82, 94, 0.2)" : "#1E232A",
        },
        type: "line",
        name: key,
      });
    });

  //@ts-ignore
  return series;
}

const sortSeriesbyKey = (a: string, b: string) => {
  //"audio" < "video" < "screen" < "device" < "errors"
  const order = ["audio", "video", "screen", "device", "errors"];
  return order.indexOf(b) - order.indexOf(a);
};
