import { Layout } from "@100mslive/types-prebuilt";
import { cloneDeep, isEmpty } from "lodash";
import api from "src/api";
import {
  defaultConferencing,
  defaultLayout,
  defaultLiveStreamingConferencing,
  liveStreamingPreview,
} from "src/data/defaultLayout";
import { policyTemplateType } from "src/types/policyTypes";
import { RoleLayouts } from "src/types/prebuilt";
import { getAPIURL } from "src/utils";
import { HLS_VIEWER_ROLE, NEW_HLS_VIEWER_ROLE } from "./roles_helper";

export async function getAppLayout({
  templateId,
  role,
  roleId,
}: {
  templateId: string;
  role: string;
  roleId: string;
}) {
  return await api.service("dashboard").get(getAPIURL("layouts/"), {
    template_id: templateId,
    role: role,
    role_id: roleId,
  });
}

export async function setAppLayout({ layout }: { layout: Layout }) {
  return await api
    .service("dashboard")
    .post(getAPIURL("layouts/create"), { ...layout });
}

export async function updateAppLayout({
  roleLayouts,
}: {
  roleLayouts: RoleLayouts;
}) {
  return await api.service("dashboard").post(getAPIURL("layouts/update"), {
    layouts_data: roleLayouts,
  });
}

export const getHLSViewerRoleLayout = ({
  roleName,
  roleLayouts,
}: {
  roleName: string;
  roleLayouts: RoleLayouts;
}) => {
  const layouts = cloneDeep(roleLayouts);
  const oldLayout = Object.values(layouts)?.[0] || defaultLayout;
  delete oldLayout["id"];
  return {
    ...oldLayout,
    app_id: "",
    role_id: "",
    role: roleName,
    screens: {
      preview: liveStreamingPreview,
      conferencing: defaultLiveStreamingConferencing,
      leave: {},
    },
  };
};

export const getHLSViewerRolesFromRoleLayouts = ({
  roleLayouts,
}: {
  roleLayouts: RoleLayouts;
}) => {
  if (!isEmpty(roleLayouts)) {
    return (
      Object.values(roleLayouts).filter(
        role =>
          !isEmpty(role.screens?.conferencing?.hls_live_streaming) &&
          (role.role === HLS_VIEWER_ROLE || role.role === NEW_HLS_VIEWER_ROLE)
      ) || undefined
    );
  }
  return undefined;
};

export const updateConferencingDefaults = ({
  policyInfo,
  roleLayouts,
  roleForLayout,
  screenType,
}: {
  policyInfo: policyTemplateType;
  roleLayouts: RoleLayouts;
  roleForLayout: string;
  screenType: string;
}) => {
  const roleLayoutsCopy = cloneDeep(roleLayouts);
  const roleLayout = roleLayoutsCopy[roleForLayout];
  let defaultLayout;
  if (screenType === "default") {
    const rolesInPolicy = Object.keys(policyInfo.roles);

    defaultLayout = cloneDeep(defaultConferencing);
    const defaultOnStageExp = defaultLayout?.default?.elements?.on_stage_exp;
    let defaultOnStageRole =
      defaultOnStageExp?.on_stage_role || "viewer-on-stage";
    const defaultOffStageRoles = defaultOnStageExp?.off_stage_roles || [];

    if (!rolesInPolicy.includes(defaultOnStageRole)) {
      defaultOnStageRole = "";
    }
    const newOffStageRoles: string[] = [];
    defaultOffStageRoles?.forEach(offStageRole => {
      if (rolesInPolicy.includes(offStageRole)) {
        newOffStageRoles.push(offStageRole);
      }
    });
    defaultLayout = {
      default: {
        elements: {
          ...defaultLayout.default?.elements,
          on_stage_exp: {
            ...defaultOnStageExp,
            on_stage_role: defaultOnStageRole,
            off_stage_roles: newOffStageRoles,
          },
        },
      },
    };
  } else if (screenType === "hls_live_streaming") {
    defaultLayout = cloneDeep(defaultLiveStreamingConferencing);
  }

  roleLayout.screens = {
    ...roleLayout.screens,
    conferencing: defaultLayout,
  };
  const tempRoleLayouts = {
    ...roleLayoutsCopy,
    [roleForLayout]: { ...roleLayout },
  };
  return tempRoleLayouts;
};
