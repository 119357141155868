export const regions = [
  { name: "IN", host: "http://prod-in2.100ms.live" },
  { name: "US", host: "http://prod-us2.100ms.live" },
  { name: "EU", host: "http://prod-eu2.100ms.live" },
];

const requestImage = url => {
  return new Promise(function (resolve, reject) {
    var img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = function () {
      reject(url);
    };
    img.src = `${url}?cache-buster=${new Date().getTime()}`;
  });
};

const ping = ({ host, name }) => {
  return new Promise(function (resolve, reject) {
    let start = new Date().getTime();
    let response = function () {
      let latency = new Date().getTime() - start;
      resolve({ name, latency });
    };
    requestImage(host).then(response).catch(response);

    // Timeout for ping more than 5s
    setTimeout(function () {
      reject(Error("Timeout"));
    }, 5000);
  });
};

export const nearestRegion = () => {
  return new Promise(function (resolve) {
    let nearestRegion = "IN";
    let lowestLatency = Infinity;
    const regionsRequest = [];
    regions.forEach(region => {
      regionsRequest.push(ping(region));
    });
    Promise.all(regionsRequest)
      .then(res => {
        res.forEach(region => {
          if (region.latency < lowestLatency) {
            nearestRegion = region.name;
            lowestLatency = region.latency;
          }
        });
        resolve(nearestRegion);
      })
      .catch(err => {
        console.error(err);
        resolve(nearestRegion);
      });
  });
};
