import React from "react";
import { isEmpty } from "lodash";
import SmallIcon from "src/components/Common/Icons/SmallIcon";
import { MathStats } from "src/helpers";
import { peerAnalyticsPublishType } from "src/types/analytics";
import { policyTemplateType } from "src/types/policyTypes";
import { InfoIcon } from "@100mslive/react-icons";
import { Flex, Text, Tooltip } from "@100mslive/react-ui";

type Props = {
  policy: policyTemplateType;
  role: string;
  publishData: peerAnalyticsPublishType;
};

const getPacketLossSentiment = (packetLoss: number) => {
  if (packetLoss < 1) {
    return { text: "Good", color: "$success" };
  }
  if (packetLoss < 5) {
    return { text: "Fair", color: "$warning" };
  }
  return { text: "Bad", color: "$error" };
};

function TrackMessage({
  text,
  val,
  tooltip = "",
}: {
  text: string;
  val: string;
  tooltip?: string;
}) {
  const value = parseFloat(val) === 0 ? "0" : val;

  const sentiment = getPacketLossSentiment(Number(val));
  return (
    <Flex
      css={{
        p: "$8 $10",
      }}
      justify="start"
      direction="column"
    >
      <Flex align="center" css={{ gap: "$2" }}>
        <Text variant="caption" css={{ c: "$textDisabled" }}>
          {text}
        </Text>
        {tooltip ? (
          <Tooltip
            title={tooltip}
            side="top"
            align="center"
            boxCss={{ r: "$0", w: "$80" }}
          >
            <Flex css={{ c: "$textDisabled" }}>
              <SmallIcon>
                <InfoIcon />
              </SmallIcon>
            </Flex>
          </Tooltip>
        ) : (
          <></>
        )}
      </Flex>
      <Text
        variant="body2"
        css={{ c: sentiment.color, mt: "$2", fontWeight: "$regular" }}
      >
        {`${value}% - ${sentiment.text}`}
      </Text>
    </Flex>
  );
}
/* eslint-disable-next-line complexity */
export default function PublisherPacketLossMetrics({ publishData }: Props) {
  const mathStats = new MathStats();
  const mergedVideoLayers = (
    videoLayers: { timestamp: string; packet_loss: number }[][]
  ) => {
    if (isEmpty(videoLayers)) {
      return [];
    }
    const result = [];
    for (let i = 0; i < videoLayers[0].length; i++) {
      //calculate max on the same index across all the arrays inside videoLayers
      let maxPacketLoss = 0;
      for (let j = 0; j < videoLayers.length; j++) {
        if (
          videoLayers?.[j]?.[i] !== undefined &&
          videoLayers?.[j]?.[i] !== null
        ) {
          maxPacketLoss = Math.max(
            maxPacketLoss,
            videoLayers[j][i].packet_loss
          );
        }
      }
      result.push({
        timestamp: videoLayers[0][i].timestamp,
        packet_loss: maxPacketLoss,
      });
    }
    return result;
  };
  const videoLayer = mergedVideoLayers(
    (Object.values(publishData?.video || {}) || []) as {
      timestamp: string;
      packet_loss: number;
    }[][]
  );
  const screenLayer = publishData?.screen || [];
  const audioLayer = publishData?.audio || [];
  const calulateP95PacketLossForLayer = (
    data: { timestamp: string; packet_loss: number }[]
  ) => {
    if (isEmpty(data)) {
      return 0;
    }

    let totalDuration = 0;
    const packetLossArray = [];
    for (let i = 0; i < data.length - 1; i++) {
      const duration =
        (new Date(data[i + 1].timestamp).getTime() -
          new Date(data[i].timestamp).getTime()) /
        1000;
      totalDuration += duration;
      if (duration > 0 && Number.isFinite(data?.[i]?.packet_loss)) {
        packetLossArray.push(data?.[i]?.packet_loss);
      }
    }

    // Add the last data point normalized by the average duration
    if (data.length > 1 && totalDuration > 0) {
      packetLossArray.push(data[data.length - 1].packet_loss);
    } else if (data.length === 1) {
      packetLossArray.push(data[0].packet_loss);
    }

    return mathStats.quantile(packetLossArray, 0.95);
  };

  return (
    <Flex
      css={{
        borderTop: "$space$px solid $borderDefault",
        borderLeft: "$space$px solid $borderDefault",
        w: "30%",
        h: "100%",
      }}
      direction="column"
    >
      {isEmpty(videoLayer) ? (
        <></>
      ) : (
        <TrackMessage
          text="P95 Video Packet Loss"
          tooltip="Total packet loss calculated across all the simulcast layers excluding screenshare"
          val={calulateP95PacketLossForLayer(
            videoLayer as { timestamp: string; packet_loss: number }[]
          ).toFixed(2)}
        />
      )}
      {isEmpty(screenLayer) ? (
        <></>
      ) : (
        <TrackMessage
          text="P95 Screen Packet Loss"
          val={calulateP95PacketLossForLayer(
            screenLayer as { timestamp: string; packet_loss: number }[]
          ).toFixed(2)}
        />
      )}
      {isEmpty(audioLayer) ? (
        <></>
      ) : (
        <TrackMessage
          text="P95 Audio Packet Loss"
          val={calulateP95PacketLossForLayer(
            audioLayer as { timestamp: string; packet_loss: number }[]
          ).toFixed(2)}
        />
      )}
    </Flex>
  );
}
