export const baseTheme = {
  colors: {
    white: "hsla(0, 0%, 100%, 1)",
    black: "hsla(0, 0%, 0%, 1)",
    transparent: "#00000000",
    orange: "hsla(30, 100%, 50%, 1)",
    cyan: "hsla(180, 68%, 46%, 1)",
    tamarind: "#F79133",
    yellow: "#FAC919",
    twinYellow: "hsla(50, 98%, 47%, 1)",
    green: "hsla(160, 80%, 40%, 1)",
    purple: "hsla(260, 80%, 60%, 1)",
    success: "hsla(155, 54%, 46%, 1)",
    error: "hsla(354, 54%, 56%, 1)",
    darkgreen: "37F28D",

    borderDefault: "hsla(218, 17%, 13%, 1)",
    borderLight: "hsla(218, 17%, 21%, 1)",
    borderAccent: "hsla(217, 85%, 54%, 1)",
    errorTint: "#ED4C5AE6",
    warning: "hsla(40, 100%, 50%, 1)",
    brandLight: "#74AAFF",
    brandDefault: "#2F80FF",
    brandDark: "#0B326F",

    brandDisabled: "#D8E7FF",
    grayLight: "#B0C3DB",
    grayDefault: "#657080",
    grayDark: "#303740",
    secondaryGray: "#242A33",
    grayDisabled: "#DCE4EF",
    menuBg: "$grayDark",
    textHighEmp: "hsla(216, 100%, 98%, 0.95)",
    textMedEmp: "hsla(217, 100%, 94%, 0.8)",
    textDisabled: "hsla(216, 40%, 83%, 0.5)",

    // text variants when bg is accent (blue)
    // same for both themes
    textAccentHigh: "hsla(216,100%,98%,0.95)",
    textAccentMedium: "hsla(217, 100%, 94%, 0.8)",
    textAccentDisabled: "hsla(216, 40%, 83%, 0.5)",

    surfaceDefault: "hsla(218, 17%, 9%, 1)",
    surfaceLight: "hsla(215, 17%, 14%, 1)",
    surfaceLighter: "hsla(215, 18%, 19%, 1)",
    surfaceDark: "hsla(220, 17%, 7%, 1)",

    backgroundDefault: "hsla(210, 15%, 5%, 1)",
    backgroundDark: "hsla(210, 20%, 4%, 1)",
    backgroundDarker: "hsla(240, 9%, 2%, 1)",
    backgroundError: "hsla(354, 43%, 49%, 0.05)",
    primaryLight: "hsla(217, 100%, 70%, 1)",
    primaryDefault: "hsla(217, 85%, 54%, 1)",
    primaryDark: "hsla(217, 67%, 24%, 1)",
    primaryDisabled: "hsla(217, 53%, 45%, 1)",
    secondaryLight: "hsla(217, 18%, 51%, 1)",
    secondaryDefault: "hsla(217, 18%, 34%, 1)",
    secondaryDark: "hsla(218, 17%, 21%, 1)",
    secondaryDisabled: "hsla(216, 17%, 17%, 1)",
    errorBg: "rgba(204, 82, 95, 0.1)",
    errorDefault: "#CC525F",
    errorLight: "#D87983",
    errorDark: "#9D2F3B",
    errorDisabled: "#621D24",
  },
};
