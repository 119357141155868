import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { fetchTemplatesData } from "src/actions/RolesActions";
import { API_CALL_STATE, templateTypes } from "src/constants";
import {
  AppAnalytics,
  getPriorityRolesArray,
  getVisibleRoles,
  toTitleCase,
} from "src/helpers";
import { steps } from "src/modules/onboarding/containers/OnboardingFlowWithLayout";
import StreamDetailsForm from "src/modules/rooms/components/Forms/StreamDetailsFormV2";
import { liveStreamRoleNames } from "src/services/cmsModels/Policy/constants";
import { LIVE_STREAM_RTMP_INGEST_OPTIONS } from "src/services/cmsModels/Policy/helpers";
import type { RootState } from "src/store/reducers";
import {
  fetchRoomRTMPStreamKeys,
  fetchRoomToken,
  fetchShortCodeForAll,
} from "src/store/rooms/actions";
import { cmsTemplateType } from "src/types/cmsTypes";
import { currentUser } from "src/utils";
import {
  LinkIcon,
  ShareScreenIcon,
  VideoOnIcon,
  WiredMicIcon,
} from "@100mslive/react-icons";
import { Button, Flex, Loading, Text } from "@100mslive/react-ui";
import DashboardButton from "./Common/Button";
import SmallIcon from "./Common/Icons/SmallIcon";
import TabTile from "./Common/TabTile";
import MobileEmbedQRDialog from "./Modal/MobileEmbedQRDialog";
import RoomCodesDisabledState from "./RoomCodesDisabledState";
import question from "../assets/images/question.png";

interface Props {
  roomId?: string;
  componentId: string;
  subdomain?: string;
  templateId?: string;
  openWithActiveRole?: string;
  roomCodesDisabled?: boolean;
  fetchTemplates?: boolean;
}
const publishConfigIcons = {
  audio: <WiredMicIcon />,
  video: <VideoOnIcon />,
  screen: <ShareScreenIcon />,
};

//to be used only for set template flow and onboarding flow
// eslint-disable-next-line complexity
const JoinRoomForm: React.FC<Props> = ({
  componentId,
  roomId,
  subdomain,
  templateId,
  openWithActiveRole = "",
  roomCodesDisabled = false,
  fetchTemplates = false,
}) => {
  const dispatch = useDispatch();

  const addNewAppStatus = useSelector(
    (state: RootState) => state.createTemplate.addNewAppStatus
  );
  const roomShortCodeForRoles = useSelector(
    (state: RootState) => state.rooms.roomShortCodeForRoles
  );
  const fetchRoomShortCodeStatus = useSelector(
    (state: RootState) => state.rooms.fetchRoomShortCodeStatus
  );
  const fetchAllRoomCodesStatus = useSelector(
    (state: RootState) => state.rooms.fetchAllRoomCodesStatus
  );
  const all_templates = useSelector(
    (state: RootState) => state.roles.all_templates
  );
  const roomShortCodeErrorMessage = useSelector(
    (state: RootState) => state.rooms.roomShortCodeErrorMessage
  );
  const publicTemplates = useSelector(
    (state: RootState) => state.createApp.publicTemplates
  );
  const fetchRTMPStreamKeysStatus = useSelector(
    (state: RootState) => state.rooms.fetchRTMPStreamKeysStatus
  );

  const roomStreamKeys = useSelector(
    (state: RootState) => state.rooms.roomStreamKeys
  );
  const policyType = useSelector(
    (state: RootState) => state.createTemplate.policyType
  );
  const stepAnswers = useSelector(
    (state: RootState) => state.createTemplate.stepAnswers
  );

  const [activeRole, setActiveRole] = useState<string>("");
  const [openMobileEmbedDialog, setOpenMobileEmbedDialog] =
    useState<boolean>(false);

  const showRTMPDetails = useMemo(() => {
    const bool =
      policyType === templateTypes.LIVE_STREAM &&
      (stepAnswers as Record<string, { streamType?: string }>)?.[
        steps.CONFIGURE_USE_CASE.key
      ]?.streamType === LIVE_STREAM_RTMP_INGEST_OPTIONS.external;

    return bool;
  }, [policyType, stepAnswers]);

  const user = currentUser();
  useEffect(() => {
    if (addNewAppStatus === API_CALL_STATE.DONE && fetchTemplates) {
      dispatch(fetchTemplatesData());
    }
  }, [addNewAppStatus, dispatch, fetchTemplates]);

  const template = all_templates?.find(template => template.id === templateId);
  const cmsTemplate = useMemo(
    () =>
      template?.ts_template_id
        ? publicTemplates?.find(publicTemplate => {
            return publicTemplate.id === template?.ts_template_id;
          })
        : {},
    [publicTemplates, template?.ts_template_id]
  );
  useEffect(() => {
    if (roomId) {
      dispatch(fetchShortCodeForAll(roomId));
    }
  }, [dispatch, roomId]);

  useEffect(() => {
    if (roomId && showRTMPDetails && addNewAppStatus === API_CALL_STATE.DONE) {
      dispatch(fetchRoomRTMPStreamKeys(roomId));
      setActiveRole("GoLiveRTMPIngest");
    }
  }, [addNewAppStatus, dispatch, roomId, showRTMPDetails]);

  useEffect(() => {
    if (Object.keys(roomShortCodeForRoles).length) {
      const firstRole =
        openWithActiveRole ||
        getPriorityRolesArray(
          getVisibleRoles(Object.keys(roomShortCodeForRoles))
        )[0].toString();
      if (showRTMPDetails) {
        setActiveRole("GoLiveRTMPIngest");
      } else {
        setActiveRole(firstRole);
      }

      dispatch(
        fetchRoomToken(roomShortCodeForRoles?.[firstRole]?.value, firstRole)
      );
      try {
        AppAnalytics.track("btn.clicked", {
          btnId: "join.room",
          componentId: componentId,
          roles: Object.keys(roomShortCodeForRoles).length,
          account_id: user.customer_id,
          room_id: roomId,
          hms_link_present: !!subdomain,
        });
      } catch (e) {
        console.error(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomShortCodeForRoles, roomId]);

  const getRoleDesc = useCallback(
    (role: string) => {
      if (!isEmpty(cmsTemplate)) {
        if (
          (stepAnswers as Record<string, any>)?.[steps.CONFIGURE_USE_CASE.key]
            ?.streamType === LIVE_STREAM_RTMP_INGEST_OPTIONS.external &&
          (cmsTemplate as cmsTemplateType)?.attributes?.type ===
            templateTypes.LIVE_STREAM &&
          role === liveStreamRoleNames.broadcaster
        ) {
          return "The broadcaster can initiate and end the stream, conduct polls, chat, and remove participants, with no ability to publish audio, video, or screen-share. They will also not be able to view the live stream directly. To initiate the live stream, navigate to the 'Stream Key Details' tab for instructions.";
        }
        const roleObj = (
          cmsTemplate as cmsTemplateType
        )?.attributes?.roles.find(cmsRole => cmsRole.name === role);
        return roleObj?.description || "";
      } else {
        return "";
      }
    },
    [cmsTemplate, stepAnswers]
  );
  if (roomCodesDisabled) {
    return <RoomCodesDisabledState />;
  }
  return (
    <>
      {fetchAllRoomCodesStatus === API_CALL_STATE.IN_PROGRESS ||
      addNewAppStatus === API_CALL_STATE.IN_PROGRESS ? (
        <Flex css={{ p: "$10", w: "100%" }} justify="center" align="center">
          <Loading size={48} />
        </Flex>
      ) : fetchAllRoomCodesStatus === API_CALL_STATE.FAILED ||
        addNewAppStatus === API_CALL_STATE.FAILED ? (
        <Flex
          css={{ p: "$14", w: "100%" }}
          justify="center"
          align="center"
          direction="column"
        >
          <Flex css={{ mt: "$14", w: "100%" }} justify="center">
            <img src={question} alt="100ms" width="80px" height="80px" />
          </Flex>
          <Text variant="body1" css={{ c: "$textMedEmp", mt: "$10" }}>
            Something went wrong
          </Text>
          {
            // eslint-disable-next-line no-extra-boolean-cast
            !!roomShortCodeErrorMessage ? (
              <Text
                variant="sub2"
                css={{ c: "$textDisabled", mt: "$4", mb: "$20" }}
              >
                {roomShortCodeErrorMessage}
              </Text>
            ) : null
          }
        </Flex>
      ) : fetchAllRoomCodesStatus === API_CALL_STATE.DONE ? (
        <Flex css={{ p: "$10" }} direction="row">
          <Flex
            css={{
              w: "25%",
              mr: "$10",

              overflow: "auto",
              maxHeight: "540px",
            }}
            direction="column"
          >
            {showRTMPDetails ? (
              <>
                <Text variant="sub2" css={{ c: "$textMedEmp", mb: "$8" }}>
                  Go live using RTMP-In
                </Text>
                <TabTile
                  css={{
                    w: "100%",
                  }}
                  key="GoLiveRTMPIngest"
                  text="Stream Key Details"
                  active={"GoLiveRTMPIngest" === activeRole}
                  onClick={() => {
                    setActiveRole("GoLiveRTMPIngest");
                  }}
                />
              </>
            ) : null}
            <Text
              variant="sub2"
              css={{ c: "$textMedEmp", mt: !showRTMPDetails ? 0 : "$10" }}
            >
              Select a role to join
            </Text>
            {getPriorityRolesArray(
              getVisibleRoles(Object.keys(roomShortCodeForRoles))
            ).map((role: string) => (
              <TabTile
                key={role}
                text={role}
                active={role === activeRole}
                css={{
                  mt: "$8",
                  w: "100%",
                  ml: "0",
                }}
                onClick={() => {
                  setActiveRole(role);
                  dispatch(
                    fetchRoomToken(roomShortCodeForRoles?.[role]?.value, role)
                  );
                  AppAnalytics.track("btn.clicked", {
                    platform: "hms-dashboard",
                    roleName: role,
                    componentId: `join.your.room.${componentId}`,
                    btnId: "role.switched",
                  });
                }}
              />
            ))}
          </Flex>
          <Flex
            css={{
              w: "75%",
              r: "$1",
            }}
            direction="column"
          >
            {activeRole !== "GoLiveRTMPIngest" ? (
              <>
                <Flex
                  direction="column"
                  css={{
                    w: "100%",
                    flex: "1 1 0",
                    minWidth: 0,
                    r: "$0",
                    p: "$10",
                    backgroundColor: "$surfaceDefault",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                >
                  <Flex direction="column">
                    <Flex
                      direction="column"
                      css={{
                        minHeight: "140px",
                      }}
                    >
                      <Text
                        variant="lg"
                        css={{
                          color: "$textHighEmp",
                          fontWeight: "$semiBold",
                        }}
                      >
                        {toTitleCase(activeRole)}
                      </Text>
                      {getRoleDesc(activeRole) ? (
                        <Text
                          variant="body2"
                          css={{
                            color: "$textMedEmp",
                            fontWeight: "$regular",
                            mt: "$2",
                          }}
                        >
                          {getRoleDesc(activeRole)}
                        </Text>
                      ) : null}
                      {template?.roles?.[activeRole] ? (
                        <Flex direction="row" css={{ gap: "$4", mt: "$10" }}>
                          {template?.roles?.[
                            activeRole as keyof typeof template.roles
                          ]?.publishParams?.allowed?.map(param => (
                            <Flex
                              css={{
                                p: "$2 $4",
                                bg: "$surfaceLighter",
                                r: "$0",
                              }}
                              align="center"
                            >
                              <SmallIcon>
                                {
                                  publishConfigIcons[
                                    param as keyof typeof publishConfigIcons
                                  ]
                                }
                              </SmallIcon>
                              <Text
                                variant="caption"
                                css={{
                                  c: "$textHighEmp",
                                  fontWeight: "$regular",
                                  ml: "$2",
                                }}
                              >
                                {param === "screen"
                                  ? "Share Screen"
                                  : toTitleCase(param)}
                              </Text>
                            </Flex>
                          ))}
                        </Flex>
                      ) : null}
                    </Flex>
                    <Flex direction="column" css={{ mt: "$10" }}>
                      <a
                        href={
                          subdomain &&
                          fetchAllRoomCodesStatus === API_CALL_STATE.DONE
                            ? `https://${subdomain}/meeting/${roomShortCodeForRoles?.[activeRole]?.value}`
                            : "#top"
                        }
                        rel="noreferrer noopener"
                        target="_blank"
                        onClick={() => {
                          AppAnalytics.track("btn.clicked", {
                            platform: "hms-dashboard",
                            componentId: `experience.${componentId}`,
                            btnId: "experience.as.role.clicked",
                            role: activeRole,
                          });
                        }}
                      >
                        <Button
                          css={{ w: "100%", r: "$0" }}
                          variant="primary"
                          loading={
                            fetchRoomShortCodeStatus ===
                              API_CALL_STATE.IN_PROGRESS ||
                            fetchAllRoomCodesStatus ===
                              API_CALL_STATE.IN_PROGRESS
                          }
                          disabled={
                            !subdomain ||
                            fetchRoomShortCodeStatus ===
                              API_CALL_STATE.IN_PROGRESS
                          }
                        >
                          Experience as {activeRole}
                        </Button>
                      </a>
                      <Flex css={{ w: "100%", mt: "$md", gap: "$md" }}>
                        {/* <Button
                      variant="standard"
                      css={{ r: "$0", w: "50%" }}
                      outlined
                      icon
                      disabled={!subdomain || isEmpty(roomShortCodeForRoles)}
                      onClick={() =>
                        setOpenMobileEmbedDialog(!openMobileEmbedDialog)
                      }
                    >
                      <MobileIcon />
                      <Text variant="body2">Experience on Mobile</Text>
                    </Button> */}
                        <Button
                          variant="standard"
                          css={{ r: "$0", w: "100%" }}
                          disabled={!subdomain}
                          outlined
                          icon
                          onClick={() => {
                            AppAnalytics.track("btn.clicked", {
                              platform: "hms-dashboard",
                              componentId: `experience.${componentId}`,
                              btnId: "copy.invite.link",
                            });
                            //copy the role to the clipboard
                            window.navigator.clipboard.writeText(
                              `https://${subdomain}/meeting/${roomShortCodeForRoles?.[activeRole]?.value}`
                            );
                          }}
                        >
                          <LinkIcon />
                          <Text variant="body2">Copy Invite Link</Text>
                        </Button>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  justify="between"
                  align="center"
                  css={{
                    r: "$0",
                    backgroundColor: "$surfaceLight",
                    alignSelf: "end",
                    w: "100%",
                    py: "$8",
                    px: "$10",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                  }}
                >
                  <Text variant="caption" css={{ c: "$textDisabled" }}>
                    Use our no-code customizer for prebuilt →
                  </Text>
                  <Link to={`/templates/${templateId}/prebuilt`}>
                    <DashboardButton
                      //@ts-ignore
                      variant="highlight"
                      css={{
                        w: "max-content",
                        "& div": { w: "80%" },
                        borderRadius: "$0",
                        border: "0 !important",
                        height: "$14",
                        transition: "all 0.4s ease",
                      }}
                      disabled={!subdomain}
                      onClick={() => {
                        AppAnalytics.track("btn.clicked", {
                          platform: "hms-dashboard",
                          componentId: `show.prebuilt.clicked.${componentId}`,
                          btnId: "prebuilt.onboarding",
                        });
                      }}
                    >
                      <Text
                        variant="sm"
                        css={{
                          fontWeight: "$semiBold",
                          color: "$textHighEmp",
                        }}
                      >
                        Customise Prebuilt
                      </Text>
                    </DashboardButton>
                  </Link>
                </Flex>
              </>
            ) : (
              <StreamDetailsForm
                roomId={roomId || ""}
                templateStatus={addNewAppStatus}
                fetchRTMPStreamStatus={fetchRTMPStreamKeysStatus}
                roomStreamDetails={roomStreamKeys}
              />
            )}
          </Flex>
          {openMobileEmbedDialog && subdomain ? (
            <MobileEmbedQRDialog
              openDialog={openMobileEmbedDialog}
              openDialogFunction={(bool: boolean) => {
                setOpenMobileEmbedDialog(bool);
              }}
              activeRole={activeRole}
              roles={Object.keys(roomShortCodeForRoles)}
              subdomain={subdomain}
              roomShortCodeForRoles={roomShortCodeForRoles}
              roleDescription={getRoleDesc(activeRole) || ""}
            />
          ) : null}
        </Flex>
      ) : null}
    </>
  );
};

export default JoinRoomForm;
