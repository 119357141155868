/* eslint-disable complexity */
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import LargeIcon from "src/components/Common/Icons/LargeIcon";
import { API_CALL_STATE } from "src/constants";
import { openDevrevWidget } from "src/helpers/utils";
import { fetchPeersAnalyticsPublishScore } from "src/store/analytics/actions";
import { RootState } from "src/store/reducers";
import {
  peerAnalyticsPublishType,
  peerAnalyticsType,
  peerInsightsPageDerivedStatesType,
  peerPublishValueType,
} from "src/types/analytics";
import { policyTemplateType } from "src/types/policyTypes";
import {
  AlertTriangleIcon,
  ChevronDownIcon,
  SupportIcon,
} from "@100mslive/react-icons";
import { Button, Flex, Text } from "@100mslive/react-ui";
import { PublisherBitrateChart } from "../components/charts/PublisherBitrateChart";
import { PublisherFPSChart } from "../components/charts/PublisherFPSChart";
import { PublisherJitterBufferChart } from "../components/charts/PublisherJitterBufferChart";
import { PublisherPacketLossChart } from "../components/charts/PublisherPacketLossChart";
import { PublisherQualityLimitationChart } from "../components/charts/PublisherQualityLimitationChart";
import { PublisherRoundTripChart } from "../components/charts/PublisherRoundTripChart";
import EmptyStateForPeerCharts from "../components/EmptyStateForPeerCharts";
import PublisherBitrateMetrics from "../components/metrics/PublisherBitrateMetrics";
import PublisherFrameRateMetrics from "../components/metrics/PublisherFrameRateMetrics";
import PublisherJitterMetrics from "../components/metrics/PublisherJitterMetrics";
import PublisherPacketLossMetrics from "../components/metrics/PublisherPacketLossMetrics";
import PublisherQualityLimitationMetrics from "../components/metrics/PublisherQualityLimitationMetrics";
import PublisherRoundTripMetrics from "../components/metrics/PublisherRoundTripMetrics";

function PublishScore({ peerId }: { peerId: string }) {
  const [accordionConfig, setAccordionConfig] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const dispatch = useDispatch();

  const { templates, fetchTemplateStatus } = useSelector(
    (state: RootState) => state.roles
  );
  const {
    fetchPeerAnalyticsPublishScoreStatus,
    peerPublishScore,
    peerAnalyticsDetails,
    peerInsightsDerivedStates,
  } = useSelector((state: RootState) => state.roomDebugger);
  const toggleAccordion = (idx: number) => {
    const newAccordionConfig = [...accordionConfig];
    newAccordionConfig[idx] = !newAccordionConfig[idx];
    setAccordionConfig(newAccordionConfig);
  };
  const role =
    (peerAnalyticsDetails as peerAnalyticsType)?.role?.toLowerCase() || "";
  const policy =
    templates?.[(peerAnalyticsDetails as peerAnalyticsType)?.template_id] || {};

  useEffect(() => {
    if (peerId) {
      dispatch(fetchPeersAnalyticsPublishScore(peerId));
    }
  }, [dispatch, peerId]);

  const publishKeys = {
    bitrate: {
      name: "Bitrate",
      key: "bitrate",
      Component: PublisherBitrateChart,
      Metrics: PublisherBitrateMetrics,
    },
    fps: {
      name: "Frame Rate (FPS)",
      key: "fps",
      Component: PublisherFPSChart,
      Metrics: PublisherFrameRateMetrics,
    },
    packet_loss: {
      name: "Packet Loss",
      key: "packet_loss",
      Component: PublisherPacketLossChart,

      Metrics: PublisherPacketLossMetrics,
    },
    rtt: {
      name: "Round Trip",
      key: "rtt",
      Component: PublisherRoundTripChart,

      Metrics: PublisherRoundTripMetrics,
    },

    jitter: {
      name: "Jitter",
      key: "jitter",
      Component: PublisherJitterBufferChart,

      Metrics: PublisherJitterMetrics,
    },
    quality_limitation: {
      name: "Quality Limitation",
      key: "quality_limitation",
      Component: PublisherQualityLimitationChart,

      Metrics: PublisherQualityLimitationMetrics,
    },
  };

  const isVideoEmpty = (key: string) => {
    const data = peerPublishScore as peerAnalyticsPublishType;
    let videoEmpty = true;
    if (isEmpty(data?.video) || Object.values(data?.video || {}).length === 0) {
      return true;
    }
    Object.values(data.video)?.forEach(r => {
      for (let i = 0; i < r.length; i++) {
        if (key === "quality_limitation") {
          const isCpuNotEmpty =
            typeof r?.[i]?.[key]?.cpu == "number" &&
            (r?.[i]?.[key]
              ?.cpu as keyof peerPublishValueType["quality_limitation"]) >= 0;
          const isNetworkNotEmpty =
            typeof r?.[i]?.[key]?.network == "number" &&
            (r?.[i]?.[key]
              ?.network as keyof peerPublishValueType["quality_limitation"]) >=
              0;
          videoEmpty = videoEmpty && !isCpuNotEmpty && !isNetworkNotEmpty;
        } else {
          const isVideoNotEmpty =
            typeof r?.[i]?.[key as keyof peerPublishValueType] == "number" &&
            (r?.[i]?.[key as keyof peerPublishValueType] as number) >= 0;
          videoEmpty = videoEmpty && !isVideoNotEmpty;
        }
      }
    });
    return videoEmpty;
  };
  const isScreenEmpty = (key: string) => {
    const data = peerPublishScore as peerAnalyticsPublishType;
    let screenEmpty = true;
    for (let i = 0; i < (data?.screen || [])?.length; i++) {
      const isScreenNotEmpty =
        typeof data?.screen?.[i]?.[key as keyof peerPublishValueType] ===
          "number" &&
        (data?.screen?.[i]?.[key as keyof peerPublishValueType] as number) >= 0;
      screenEmpty = screenEmpty && !isScreenNotEmpty;
    }

    return screenEmpty;
  };

  const isAudioEmpty = (key: string) => {
    const data = peerPublishScore as peerAnalyticsPublishType;
    let audioEmpty = true;
    for (let i = 0; i < (data?.audio || [])?.length; i++) {
      const isAudioNotEmpty =
        typeof data?.audio?.[i]?.[key as keyof peerPublishValueType] ===
          "number" &&
        (data?.audio?.[i]?.[key as keyof peerPublishValueType] as number) >= 0;
      audioEmpty = audioEmpty && !isAudioNotEmpty;
    }
    return audioEmpty;
  };

  return (peerInsightsDerivedStates as peerInsightsPageDerivedStatesType)
    ?.publish ? (
    <>
      {Object.keys(publishKeys).map((key, index) => {
        const config = { ...publishKeys[key as keyof typeof publishKeys] };

        const videoEmpty = isVideoEmpty(key);
        const screenEmpty = isScreenEmpty(key);
        const audioEmpty = isAudioEmpty(key);
        const isDataEmpty = videoEmpty && screenEmpty && audioEmpty;

        return (
          <Flex css={{ w: "100%", py: "$10" }}>
            <Flex
              direction="column"
              css={{
                w: "100%",
                border: "$space$px solid $borderDefault",
                r: "$1",
              }}
            >
              <Flex
                justify="between"
                align="center"
                onClick={() => toggleAccordion(index)}
                css={{
                  cursor: "pointer",
                  py: "$9",
                  px: "$10",
                }}
              >
                {fetchPeerAnalyticsPublishScoreStatus ===
                  API_CALL_STATE.IN_PROGRESS ||
                fetchTemplateStatus === API_CALL_STATE.IN_PROGRESS ? (
                  <Skeleton width={160} height="100%" />
                ) : (
                  <Text
                    variant="sub2"
                    css={{
                      fontWeight: "$semiBold",
                    }}
                  >
                    {publishKeys[key as keyof typeof publishKeys].name}
                  </Text>
                )}
                <Flex>
                  <LargeIcon>
                    <ChevronDownIcon
                      style={{
                        transform: accordionConfig[index]
                          ? "rotate(180deg)"
                          : "none",
                        transition: "transform 0.2s ease-in-out",
                      }}
                    />
                  </LargeIcon>
                </Flex>
              </Flex>
              <Flex
                css={{
                  overflow: "hidden",
                  visibility: accordionConfig[index] ? "visible" : "hidden",
                  transition:
                    "max-height 0.4s ease-in-out, visibility 0.3s ease-in-out", // Adjust the duration and easing as needed
                  maxHeight: accordionConfig[index] ? "700px" : "0",
                }}
                direction="column"
              >
                {fetchPeerAnalyticsPublishScoreStatus ===
                  API_CALL_STATE.IN_PROGRESS ||
                fetchTemplateStatus === API_CALL_STATE.IN_PROGRESS ? (
                  <Skeleton
                    width="100%"
                    height={key === "quality_limitation" ? 270 : 240}
                  />
                ) : fetchPeerAnalyticsPublishScoreStatus ===
                    API_CALL_STATE.FAILED ||
                  fetchTemplateStatus === API_CALL_STATE.FAILED ? (
                  <Flex
                    css={{ rowGap: "$md", minHeight: "240px", w: "100%" }}
                    justify="center"
                    align="center"
                    direction="column"
                  >
                    <Flex css={{ c: "$error" }}>
                      <AlertTriangleIcon width={40} height={40} />
                    </Flex>
                    <Flex direction="column">
                      <Text variant="sub2" css={{ mb: "$2" }}>
                        Something went wrong
                      </Text>
                      <Text
                        variant="caption"
                        css={{
                          fontWeight: "$regular",
                          c: "$textMedEmp",
                          w: "20rem",
                        }}
                      >
                        We couldn&apos;t load details for this chart. This
                        feature is currently in alpha and there are known issues
                        we&apos;re working on.
                      </Text>
                    </Flex>

                    <Button
                      icon
                      variant="standard"
                      css={{ p: "$3 $4", r: "$0" }}
                      onClick={() => openDevrevWidget(true)}
                    >
                      <SupportIcon />
                      Report Issue
                    </Button>
                  </Flex>
                ) : isDataEmpty ? (
                  <EmptyStateForPeerCharts
                    title={`No ${config.name} Data`}
                    subtitle="Logs for session events will appear here, detailing audio, video, and other activities as they occur."
                  />
                ) : (
                  <Flex>
                    <Flex
                      direction="column"
                      css={{
                        w: "70%",
                        borderTop: "$space$px solid $borderDefault",
                        py: "$8",
                      }}
                    >
                      <config.Component
                        publishData={peerPublishScore}
                        loading={
                          fetchPeerAnalyticsPublishScoreStatus ===
                          API_CALL_STATE.IN_PROGRESS
                        }
                      />
                    </Flex>
                    <config.Metrics
                      policy={policy as policyTemplateType}
                      role={role}
                      publishData={peerPublishScore}
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        );
      })}
    </>
  ) : (
    <Flex css={{ mt: "$10" }}>
      <EmptyStateForPeerCharts
        title="No metrics available"
        subtitle={`This peer ${role} didn't publish any audio/video during the session, hence no publisher (outgoing) metrics can be calculated.`}
      />
    </Flex>
  );
}

export default PublishScore;
