import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import LargeIcon from "src/components/Common/Icons/LargeIcon";
import { API_CALL_STATE } from "src/constants";
import { openDevrevWidget } from "src/helpers/utils";
import { RootState } from "src/store/reducers";
import {
  peerAnalyticsSubscribeType,
  peerAnalyticsType,
  peerInsightsPageDerivedStatesType,
} from "src/types/analytics";
import {
  AlertTriangleIcon,
  ChevronDownIcon,
  SupportIcon,
} from "@100mslive/react-icons";
import { Button, Flex, Text } from "@100mslive/react-ui";
import { SubscriberBitrateChart } from "../components/charts/SubscriberBitrateChart";
import { SubscriberPacketLossChart } from "../components/charts/SubscriberPacketLossChart";
import { SubscriberRTTChart } from "../components/charts/SubscriberRTTChart";
import EmptyStateForPeerCharts from "../components/EmptyStateForPeerCharts";
import SubscriberBitrateMetrics from "../components/metrics/SubscriberBitrateMetrics";
import SubscriberPacketLossMetrics from "../components/metrics/SubscriberPacketLossMetrics";
import SubscriberRoundTripMetrics from "../components/metrics/SubscriberRoundTripMetrics";

function SubscribeScore() {
  const [accordionConfig, setAccordionConfig] = useState([true, true, true]);
  const {
    fetchPeerAnalyticsPeerWebrtcStatsStatus,
    peerSubscribeScore,
    peerInsightsDerivedStates,
    peerAnalyticsDetails,
  } = useSelector((state: RootState) => state.roomDebugger);
  const toggleAccordion = (idx: number) => {
    const newAccordionConfig = [...accordionConfig];
    newAccordionConfig[idx] = !newAccordionConfig[idx];
    setAccordionConfig(newAccordionConfig);
  };
  const role =
    (peerAnalyticsDetails as peerAnalyticsType)?.role?.toLowerCase() || "";
  const subscribeKeys = {
    bitrate: {
      name: "Bitrate",
      key: "bitrate",
      Component: SubscriberBitrateChart,
      Metrics: SubscriberBitrateMetrics,
    },
    rtt: {
      name: "Round Trip Time",
      key: "rtt",
      Component: SubscriberRTTChart,
      Metrics: SubscriberRoundTripMetrics,
    },
    packet_loss: {
      name: "Packet Loss",
      key: "packet_loss",
      Component: SubscriberPacketLossChart,
      Metrics: SubscriberPacketLossMetrics,
    },
  };

  return (peerInsightsDerivedStates as peerInsightsPageDerivedStatesType)
    ?.subscribe ? (
    <>
      {Object.keys(subscribeKeys).map((key, index) => {
        const config = { ...subscribeKeys[key as keyof typeof subscribeKeys] };
        const isKeyDatumEmpty = isEmpty(
          (peerSubscribeScore as peerAnalyticsSubscribeType)?.[
            key as keyof peerAnalyticsSubscribeType
          ]
        );

        return (
          <Flex css={{ w: "100%", py: "$10" }}>
            <Flex
              direction="column"
              css={{
                w: "100%",
                border: "$space$px solid $borderDefault",
                r: "$1",
              }}
            >
              <Flex
                justify="between"
                align="center"
                onClick={() => toggleAccordion(index)}
                css={{
                  cursor: "pointer",
                  px: "$10",
                  py: "$9",
                }}
              >
                {fetchPeerAnalyticsPeerWebrtcStatsStatus ===
                API_CALL_STATE.IN_PROGRESS ? (
                  <Skeleton width={160} height="100%" />
                ) : (
                  <Text
                    variant="sub2"
                    css={{
                      fontWeight: "$semiBold",
                    }}
                  >
                    {subscribeKeys[key as keyof typeof subscribeKeys].name}
                  </Text>
                )}
                <Flex>
                  <LargeIcon>
                    <ChevronDownIcon
                      style={{
                        transform: accordionConfig[index]
                          ? "rotate(180deg)"
                          : "none",
                        transition: "transform 0.2s ease-in-out",
                      }}
                    />
                  </LargeIcon>
                </Flex>
              </Flex>{" "}
              <Flex
                css={{
                  overflow: "hidden",
                  visibility: accordionConfig[index] ? "visible" : "hidden",
                  transition:
                    "max-height 0.4s ease-in-out, visibility 0.3s ease-in-out", // Adjust the duration and easing as needed
                  maxHeight: accordionConfig[index] ? "700px" : "0",
                }}
                direction="column"
              >
                {fetchPeerAnalyticsPeerWebrtcStatsStatus ===
                API_CALL_STATE.IN_PROGRESS ? (
                  <Skeleton width="100%" height={240} />
                ) : fetchPeerAnalyticsPeerWebrtcStatsStatus ===
                  API_CALL_STATE.FAILED ? (
                  <Flex
                    css={{ rowGap: "$md", minHeight: "240px", w: "100%" }}
                    justify="center"
                    align="center"
                    direction="column"
                  >
                    <Flex css={{ c: "$error" }}>
                      <AlertTriangleIcon width={40} height={40} />
                    </Flex>
                    <Flex direction="column">
                      <Text variant="sub2" css={{ mb: "$2" }}>
                        Something went wrong
                      </Text>
                      <Text
                        variant="caption"
                        css={{
                          fontWeight: "$regular",
                          c: "$textMedEmp",
                          w: "20rem",
                        }}
                      >
                        We couldn&apos;t load details for this chart. This
                        feature is currently in alpha and there are known issues
                        we&apos;re working on.
                      </Text>
                    </Flex>

                    <Button
                      icon
                      variant="standard"
                      css={{ p: "$3 $4", r: "$0" }}
                      onClick={() => openDevrevWidget(true)}
                    >
                      <SupportIcon />
                      Report Issue
                    </Button>
                  </Flex>
                ) : isKeyDatumEmpty ? (
                  <EmptyStateForPeerCharts
                    title={`No ${config.name} Data`}
                    subtitle="Logs for session events will appear here, detailing audio, video, and other activities as they occur."
                  />
                ) : (
                  <Flex>
                    <Flex
                      direction="column"
                      css={{
                        w: "70%",
                        borderTop: "$space$px solid $borderDefault",
                        py: "$8",
                      }}
                    >
                      <config.Component
                        publishData={peerSubscribeScore}
                        loading={
                          fetchPeerAnalyticsPeerWebrtcStatsStatus ===
                          API_CALL_STATE.IN_PROGRESS
                        }
                      />
                    </Flex>
                    <config.Metrics
                      subscribeData={
                        peerSubscribeScore as peerAnalyticsSubscribeType
                      }
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        );
      })}
    </>
  ) : (
    <Flex css={{ mt: "$10" }}>
      <EmptyStateForPeerCharts
        title="No metrics available"
        subtitle={`This peer ${role} didn't consume any audio/video during the session, hence no subscriber (incoming) metrics can be calculated.`}
      />
    </Flex>
  );
}

export default SubscribeScore;
