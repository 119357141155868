import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Box, Flex, Loading, Text } from "@100mslive/react-ui";
import InvoiceBanner from "./InvoiceBanner";
import InvoiceRow from "./InvoiceRow";
import logo from "../../assets/icons/invoiceIcon.svg";
import { API_CALL_STATE } from "../../constants";
import { fetchInvoices } from "../../store/organisation/actions";
import Table from "../Common/Table";

const BillingTable = () => {
  const tableHeaders = [
    "Created On",
    "Amount due",
    "Status",
    "Due date",
    "Actions",
  ];
  const dispatch = useDispatch();
  let { invoices } = useSelector(state => state.organisation);
  const [sortedInvoices, setSortedInvoices] = useState([]);

  const status = useSelector(
    state => state.organisation.status.fetchInvoicesInfo
  );

  useEffect(() => {
    if (isEmpty(invoices) && status !== API_CALL_STATE.IN_PROGRESS) {
      dispatch(fetchInvoices());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(invoices)) {
      setSortedInvoices(
        invoices.sort((a, b) => new Date(b.due_date) - new Date(a.due_date))
      );
    }
  }, [invoices]);

  return status === API_CALL_STATE.IN_PROGRESS ? (
    <Flex
      css={{ h: "$40", w: "100%", mt: "$32" }}
      direction="column"
      justify="center"
      align="center"
    >
      <Loading size="32px" />
      <Text
        css={{
          color: "$textHighEmp",
          fontWeight: "$semiBold",
          mt: "24px",
          textAlign: "center",
        }}
      >
        Loading your invoices...
      </Text>
    </Flex>
  ) : (
    <Box css={{ mt: "$10" }}>
      <InvoiceBanner invoices={invoices} />
      <Table
        tableHeaders={tableHeaders}
        hideBottomBorders
        centerFinalColumn
        compact
      >
        {sortedInvoices?.map((item, id) => (
          <InvoiceRow key={id} invoiceObject={item} />
        ))}
      </Table>

      {invoices?.length ? null : (
        <Box css={{ width: "100%", mt: "96px" }}>
          <Text css={{ textAlign: "center" }}>
            <img
              src={logo}
              alt="No invoices"
              style={{ height: "64px", width: "64px" }}
            />
          </Text>
          <Text
            css={{ fontWeight: "$semiBold", mt: "24px", textAlign: "center" }}
          >
            No invoices to show at the moment
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default BillingTable;
