import React from "react";
import { Flex, Text } from "@100mslive/react-ui";

const Tile = ({
  subText,
  isActive,
  val = "0",
  onClick,
  splitStr = "-",
  text,
  isLayer = false,
  css = {},
  customContent = null,
}) => {
  const [multiplier, divider] = isLayer
    ? [136, 76]
    : val.split(splitStr).map(Number);
  return (
    <Flex
      align={isLayer ? "start" : "center"}
      justify={isLayer ? "start" : "center"}
      direction="column"
      css={{
        height: isLayer ? "$20" : "100px",
        aspectRatio: `${multiplier}/${divider}`,
        border: `1px solid ${isActive ? "$borderAccent" : "$secondaryLight"}`,
        borderRadius: "$0",
        m: "$xs",
        p: isLayer ? "$sm" : "0",
        color: isActive ? "$textAccentHigh" : "$textHighEmp",
        backgroundColor: isActive ? "$primaryDark" : "",
        cursor: "pointer",
        ...css,
      }}
      onClick={onClick}
    >
      {customContent ? (
        customContent
      ) : (
        <>
          <Text
            variant="xs"
            css={{ color: "inherit", fontWeight: "$semiBold", mb: "$2" }}
          >
            {text}
          </Text>
          <Text variant="xs" css={{ color: "$textMedEmp" }}>
            {subText}
          </Text>
        </>
      )}
    </Flex>
  );
};

export default Tile;

export const TileWrapper = ({
  children,
  isActive = false,
  css = {},
  ...props
}) => {
  return (
    <Flex
      css={{
        border: `$space$px solid ${
          isActive ? "$brandDefault" : "$secondaryLight"
        }`,
        borderRadius: "$0",
        color: isActive ? "$textAccentHigh" : "$textHighEmp",
        backgroundColor: isActive ? "rgba(38, 114, 237, 0.1)" : "",
        cursor: "pointer",
        ...css,
      }}
      {...props}
    >
      {children}
    </Flex>
  );
};
